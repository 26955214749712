import { Divider, List, ListItem, makeStyles, Typography } from '@material-ui/core'
import React, { useContext, useEffect } from 'react'
import { CartContext } from '../../contexts/Cart'
import { formatedYen } from '../../utils/Common'
import { getInternationalShippingFee, shipping_fees } from '../../utils/Shipping'
import { useSelector } from '../../redux/rootReducer'

const useStyles = makeStyles(() => ({
  list_root: {
    display: "flex",
    flexDirection: "column"
  },
  list_item: {
    display: "flex",
    justifyContent: "space-around",
    color: "grey",
    margin: "8px 0"
  },
  list_title: {
    width: "50%",
    textAlign: "center",
  },
  list_text: {
    width: "50%",
    textAlign: "center",
  },
}))

const CartPriceView = (props: any) => {
  const classes = useStyles()
  const { myCart } = useContext<any>(CartContext)
  const { subTotal, setSubTotal, shippingFeeTotal, setShippingFeeTotal } = props
  const shippingMethod = useSelector((state) => state.shippingMethod)

  // カート内の商品代金の合計と配送料の合計を算出
  useEffect(() => {
    let isMounted = true

    let _subTotal = 0
    let _shippingFee = 0
    myCart.items.forEach((item: any) => {
      const itemQuantity = item.quantity > item.latest_inventory_quantity ? item.latest_inventory_quantity : item.quantity
      _subTotal += (item.variant.price * itemQuantity)
      if (shippingMethod.countryCode === 'JP') {
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        _shippingFee += item.productInfo.shipping_fee_name ? shipping_fees[item.productInfo.shipping_fee_name][shippingMethod.provinceCode] * itemQuantity : 1000 * itemQuantity
      } else {
        _shippingFee += item.variant.weight >= 5 ? getInternationalShippingFee(shippingMethod.countryCode, item.variant.weight) * itemQuantity : (1350 + 6400) * itemQuantity // 5kgの料金が6400円
      }
    })

    if (isMounted) {
      setSubTotal(_subTotal)
      setShippingFeeTotal(_shippingFee)
    }
    return (() => {
      isMounted = false
    })
  }, [myCart])

  return (
    <div>
      <List className={classes.list_root}>
        <ListItem className={classes.list_item}>
          <Typography variant="subtitle2" className={classes.list_title}>商品合計</Typography>
          <Typography variant="subtitle2" className={classes.list_text}>¥{formatedYen(subTotal)}</Typography>
        </ListItem>
        <ListItem className={classes.list_item}>
          <Typography variant="subtitle2" className={classes.list_title}>配送料</Typography>
          <Typography variant="subtitle2" className={classes.list_text}>
            {shippingFeeTotal ?
              <>¥{formatedYen(shippingFeeTotal.toString())}</>
              :
              <>次のステップで計算されます。</>
            }
          </Typography>
        </ListItem>
        <Divider />
        <ListItem className={classes.list_item}>
          <Typography variant="subtitle2" className={classes.list_title}>合計</Typography>
          <Typography variant="subtitle2" className={classes.list_text}>
            {(subTotal && shippingFeeTotal) ?
              <>¥{formatedYen((subTotal + shippingFeeTotal).toString())}</>
              :
              <>次のステップで計算されます。</>
            }
          </Typography>
        </ListItem>
      </List>
    </div>
  )
}

export default CartPriceView
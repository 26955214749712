import React, { useState } from 'react'
import { CircularProgress, makeStyles, Typography, Container, Button } from '@material-ui/core';
import { db } from "../utils/Firebase";
import UserSearchView from '../components/admin/Users/UserSearchView';
import { User } from '../types/user';

const useStyles = makeStyles((theme) => ({
    main: {
        marginTop: theme.spacing(8),
    },
    wrapper: {
        marginTop: '16px',
        marginBottom: '16px',
    },
    loading_container: {
        textAlign: "center",
    }
}))


const AdminUsers = () => {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [users, setUsers] = useState<User[]>([]);
    const [overseaUsers, setOverseaUsers] = useState<User[]>([])


    // 全ユーザー情報を取得する
    const getAllUsers = async () => {
        setIsLoading(true)
        await db.collection('teaser_emails')
            .where('status', "==", 2)
            .get()
            .then(snapshot => {
                setIsLoading(false)
                let _users: any = []
                let _oversea_usres: any = []
                snapshot.forEach(doc => {
                    const _user = doc.data()
                    _users.push(_user)
                    if (_user.country_code && _user.country_code !== 'JP') {
                        _oversea_usres.push(_user)
                        console.log('海外ユーザー', _user.country_code, _user)
                    }
                })
                setUsers(_users)
                setOverseaUsers(_oversea_usres)
            })
        setIsLoading(false)
    }

    // 全ユーザーのEmailをCSVでダウンロード
    const handleUsersInfoDonwload = async () => {
        // CSVのヘッダー
        const data = [
            ['email']
        ]
        // CSVのデータ

        users.forEach((user: any) => {
            data.push([
                user.email,
            ])
        })
        // データをCSV形式の文字列に変換
        const csvContent = data.map(e => e.join(",")).join("\n");
        // Blobを作成
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        // BlobオブジェクトをObject URLとして作成
        const url = URL.createObjectURL(blob);
        // ダウンロード用のリンク要素を作成
        const downloadLink = document.createElement('a');
        downloadLink.href = url;
        downloadLink.download = 'all-users-info.csv';
        // リンクをbodyに追加してクリックしてから削除
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }

    return (
        <>

            <Container className={classes.main} maxWidth="md">

                <Container className={classes.wrapper}>
                    <Typography variant="subtitle2">ユーザー登録者数: {users.length}</Typography>
                    <Typography variant="subtitle2">海外ユーザー数: {overseaUsers.length}</Typography>
                </Container>

                <div>
                    <Button variant='outlined' onClick={getAllUsers}>登録ユーザー数を取得</Button>
                    <Button variant='outlined' onClick={handleUsersInfoDonwload}>エクスポート</Button>
                </div>

                <UserSearchView />
            </Container>


            {isLoading &&
                <Container className={classes.loading_container}>
                    <CircularProgress style={{ color: 'grey', position: "absolute", top: "50%" }} />
                </Container>
            }


        </>
    )
}

export default AdminUsers;
import { useContext, useEffect } from "react"
import { makeStyles, Typography } from "@material-ui/core"
import { CartContext } from "../../contexts/Cart"
import { formatedYen } from "../../utils/Common"

const useStyle = makeStyles(() => ({
  content_wrapper: {
    width: "100%",
    overflow: "hidden",
  },
  cart_items: {
    padding: "8px 0",
    margin: "0 auto",
  },
  item_wrapper: {
    width: "100%",
    display: "flex",
    borderBottom: ".5px solid gray",
    padding: "16px 0",
    "&:last-child": {
      borderBottom: "none",
    }
  },
  item_image_wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "33%",
    boxSizing: "border-box",
    padding: "8px 8px 8px 0",
  },
  item_image: {
    width: "100%",
  },
  item_info_wrapper: {
    boxSizing: "border-box",
    width: "70%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  item_title: {
    fontWeight: "bold",
  },
  quantity_wrapper: {
    // minheight: "30px",
    display: "flex",
    alignItems: "center",
    fontSize: ".9rem",
  },
}))

const CartItemsView = () => {
  const classes = useStyle()
  const { myCart, getLatestInventoryQuantity, isCartLoaded } = useContext<any>(CartContext)

  useEffect(() => {
    // カート内の商品IDの重複を排除
    const _getLatestInventoryQuantity = async () => {
      if (myCart?.items.length > 0) {
        // 最新のカート内アイテムの在庫を取得
        await getLatestInventoryQuantity()
      }
    }
    _getLatestInventoryQuantity()
  }, [isCartLoaded])

  return <>
    <div className={classes.content_wrapper}>
      <div className={classes.cart_items}>
        {myCart?.items.length > 0 ?
          <>
            {myCart.items.map((item: any) => <div key={item.variant.id} className={classes.item_wrapper}>
              <div className={classes.item_image_wrapper}>
                <img className={classes.item_image} src={item.productInfo.images[0]} />
              </div>
              <div className={classes.item_info_wrapper}>
                {/* <Typography variant="subtitle2">購入可能数: {item.latest_inventory_quantity}</Typography> */}
                <Typography variant="subtitle2" className={classes.item_title}>{item.productInfo.title}</Typography>
                {item.variant.title !== 'Default Title' && <Typography variant="subtitle2">{item.variant.title}</Typography>}
                <Typography variant="subtitle2">¥{formatedYen(item.variant.price)} (税込)</Typography>
                {item.latest_inventory_quantity === 0 &&
                  <div className={classes.quantity_wrapper}>
                    <span style={{ color: "#ff0000" }}>数量: {item.quantity} {'->'} {item.latest_inventory_quantity}<br></br>
                      こちらの商品は完売になります。
                    </span>
                  </div>
                }
                {item.latest_inventory_quantity > 0 && item.quantity > item.latest_inventory_quantity &&
                  <div className={classes.quantity_wrapper}>
                    <span style={{ color: "#ff0000" }}>数量: {item.quantity} {'->'} {item.latest_inventory_quantity}<br></br>
                      最大購入可能数の{item.latest_inventory_quantity}個を購入になります。
                    </span>
                  </div>
                }
                {item.latest_inventory_quantity > 0 && item.quantity <= item.latest_inventory_quantity &&
                  <div className={classes.quantity_wrapper}>数量: {item.quantity}</div>
                }
              </div>
            </div>)}
          </>
          : <></>
        }
      </div>
    </div>
  </>;
}

export default CartItemsView
import { Backdrop, Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, makeStyles, TextField, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import React, { useContext, useState } from 'react'
import { useDispatch } from 'react-redux'
import { AuthContext } from '../../contexts/Auth'
import Common from '../../redux/common/commonSlice'
import { formatedYen } from '../../utils/Common'
import { CloudFunctions } from '../../utils/Firebase'
import { notifySlackDroppOrder } from '../../utils/Notify'
import { CartItem } from '../../types/order'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "0",
    marginTop: "160px",
  },
  order_view: {
    border: "0.5px solid gray",
    padding: "0",
    marginBottom: "70px",
  },
  order_header: {
    borderBottom: "0.5px solid gray",
    display: "flex",
    padding: "18px"
  },
  order_info_item: {
    marginRight: "30px",
  },
  order_info: {
  },
  item_wrapper: {
    borderBottom: "0.5px solid gray",
    padding: "28px 24px 12px",
    '&:last-child': {
      borderBottom: "none",
      marginBottom: "0px",
    }
  },
  item_info: {
    width: "100%",
    display: "flex",
    boxSizing: "border-box",
  },
  item_image: {
    width: "30%",
    boxSizing: "border-box",
    padding: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    width: "100%",
    maxWidth: "80px",
    [theme.breakpoints.up('sm')]: {
      maxWidth: "100px",
    }
  },
  item_detail: {
    width: "70%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    position: "relative",
  },
  item: {

  },
  item_buttons: {
    display: "flex",
    justifyContent: "center",
    borderBottom: "0.5px solid gray",
    marginBottom: "10px",
    marginTop: "20px",
    '&:last-child': {
      borderBottom: "none",
      marginBottom: "0px",
    }
  },
  active_button: {
    margin: "6px",
    color: "#ff0000",
    width: "120px",
    fontSize: "12px",
  },
  dialog_action: {
    marginTop: "30px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}))

const CustomTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'gray',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '0',
      '&:hover fieldset': {
        borderColor: 'gray',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'gray',
      }
    },
  }
})(TextField)

type Props = {
  orders: any,
  orderRefresh: any
}

const BuyNowHistoryView = (props: Props) => {
  const { orders, orderRefresh } = props
  const { currentUser } = useContext<any>(AuthContext)
  const classes = useStyles()
  const dispatch = useDispatch()
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false)
  const [cancelItem, setCancelItem] = useState(null)
  const [cancelOrder, setCancelOrder] = useState(null)
  const [contactDialogOpen, setContactDialogOpen] = useState(false)
  const [contactItem, setContactItem] = useState<CartItem | null>(null)
  const [isCancelling, setIsCancelling] = useState(false)

  const formateShippingStatusValue = ["発送準備中", "発送済み", "発送済み", '返品中', 'キャンセル済み', 'キャンセル済み']
  // Buy Nowのフルフィルメント用ステータス
  // const ShippingStatusValue = {
  //   'PENDING': 0,
  //   'PARTIALLY_FULFILLED': 1,
  //   'FULFILLED': 2,
  //   'RETURNING': 3,
  //   'CANCELLED': 4,
  // };

  const handleCancelDialogOpen = (_item: any, _order: any) => {
    setCancelItem(_item)
    setCancelOrder(_order)
    setCancelDialogOpen(true)
  }
  const handleCancelDialogClose = () => {
    setCancelItem(null)
    setCancelOrder(null)
    setCancelDialogOpen(false)
  }

  const handleContactDialogOpen = (_item: any) => {
    setContactItem(_item)
    setContactDialogOpen(true)
  }
  const handleContactDialogClose = () => {
    setContactItem(null)
    setContactDialogOpen(false)
  }

  const handleItemCancel = async () => {
    setCancelDialogOpen(false)
    setIsCancelling(true)

    if (!checkIsCancelable(cancelItem)) {
      dispatch(
        Common.actions.fetchAlert({
          alerts: [{ message: `ご注文はキャンセル可能期間を過ぎているため、キャンセルできません。` }],
        })
      );
      setIsCancelling(false)
      return
    }

    try {
      let itemCancel = CloudFunctions.httpsCallable('cancelItem')
      await itemCancel({ cancelOrder: cancelOrder, cancelItem: cancelItem })
      dispatch(
        Common.actions.fetchAlert({
          alerts: [{ message: `商品のキャンセルが完了いたしました。` }],
        })
      );
    } catch (e) {
      dispatch(
        Common.actions.fetchAlert({
          alerts: [{ message: `商品のキャンセルに失敗しました。時間を空けて再度お試しいただくか info@dropp.jp までご連絡をお願いいたします。` }],
        })
      );
      console.log('error', e)
    }
    await orderRefresh()
    // @ts-ignore
    await notifySlackDroppOrder(`<@U01TJ3U9NHM> \n注文のキャンセルがありました。\n注文ID: ${cancelOrder.id}\nアイテムID: ${cancelItem.variant.id}\nアイテム: ${cancelItem.productInfo.title}\n ${(cancelItem.variant.title !== "Default Title") ? ("オプション:" + cancelItem.variant.title) : ""}\n数量: ${cancelItem.quantity}`)
    setIsCancelling(false)
  }

  const CancelConfirmView = () => {
    return (
      <Dialog
        open={cancelDialogOpen}
      >
        <DialogContent>
          <Typography variant="subtitle2">こちらの商品の購入をキャンセルします。<br></br>よろしいですか？</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={handleCancelDialogClose}>いいえ</Button>
          <Button style={{ color: "#ff0000" }} variant="text" onClick={handleItemCancel}>はい</Button>
        </DialogActions>
      </Dialog>
    )
  }

  const ContactDialogView = () => {
    if (contactItem && !onSendingContact) {
      return (
        <Dialog
          open={contactDialogOpen}
          onClose={handleContactDialogClose}
        >
          <DialogContent>
            <DialogTitle>商品に関するお問い合わせ</DialogTitle>
            <DialogContentText>
              商品名<br />
              <span style={{ fontWeight: "bold" }}>{contactItem.productInfo.title}<br />
                {contactItem.variant.title !== "Default Title" ? contactItem.variant.title : ""}</span><br /><br />
              お問い合わせ内容を入力の上送信してください。2営業日以内にメールにてご返信いたします。
            </DialogContentText>
            <CustomTextField
              autoFocus
              margin="dense"
              id="contact-text"
              label="お問い合わせ内容"
              type="text"
              variant="outlined"
              multiline
              fullWidth
              rows={4}
            />
            <DialogActions className={classes.dialog_action}>
              <Button variant="text" onClick={handleContactDialogClose}>戻る</Button>
              <Button style={{ color: "#ff0000" }} variant="text" onClick={(e) => handleSendContact(e)}>送信する</Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      )
    } else if (onSendingContact) {
      return (
        <Backdrop className={classes.backdrop} open={onSendingContact} >
          <CircularProgress style={{ color: "white" }} />
        </Backdrop>
      )
    } else {
      return (<></>)
    }
  }

  const [onSendingContact, setOnSendingContact] = useState(false)
  const handleSendContact = async (e: any) => {
    e.preventDefault()
    const textElement = document.getElementById('contact-text')
    // @ts-ignore
    let text = textElement.value
    setOnSendingContact(true)
    let func = CloudFunctions.httpsCallable('sendOrderContact')
    await func({ item: contactItem, userEmail: currentUser.email, contactText: text })
    setContactDialogOpen(false)
    setOnSendingContact(false)
    dispatch(
      Common.actions.fetchAlert({
        alerts: [{ message: `お問い合わせが完了いたしました。返信まで今しばらくお待ちください。` }],
      })
    );

  }

  const checkIsCancelable = (_item: any) => {
    const now = new Date().getTime() // ミリセカンド
    // const oneDayTime = 60 * 60 * 24 * 1000
    const oneHourTime = 60 * 60 * 1000
    const itemCreatedTime = new Date(_item.created_at).getTime()
    // 発送済み or 1時間経過はキャンセル不可
    const isCancelable = _item.shippingStatus === 0 && now < itemCreatedTime + oneHourTime
    return isCancelable
  }

  if (isCancelling) {
    return (
      <Backdrop className={classes.backdrop} open={isCancelling} >
        <CircularProgress style={{ color: "white" }} />
      </Backdrop>
    )
  } else if (orders.length > 0) {
    return (
      <Container className={classes.container} maxWidth="sm">
        {orders.map((order: any) => <div className={classes.order_view} key={order.id}>
          <div className={classes.order_header}>
            <div className={classes.order_info_item}>
              <Typography variant="subtitle2">注文日</Typography>
              <Typography variant="subtitle2">  {new Date(order.created_at).getFullYear() + '年' + (new Date(order.created_at).getMonth() + 1) + '月' + new Date(order.created_at).getDate() + '日'}</Typography>
            </div>
            <div className={classes.order_info_item}>
              <Typography variant="subtitle2" >合計</Typography>
              <Typography variant="subtitle2">¥{formatedYen(order.total)}（税込）</Typography>
            </div>
          </div>
          {order.items.map((item: any) => <div className={classes.item_wrapper} key={item.variant.id}>
            <div className={classes.item_info}>
              <div className={classes.item_image}>
                <img src={item.productInfo.images[0]} className={classes.image} />
              </div>
              <div className={classes.item_detail}>
                <Typography variant="subtitle2" className={classes.item} style={{ fontWeight: "bold", marginBottom: "6px" }}>{item.productInfo.title} </Typography>
                {item.variant.title !== 'Default Title' && <Typography variant="caption" className={classes.item}>{item.variant.title}</Typography>}
                <Typography variant="caption" className={classes.item}>数量: {item.quantity}</Typography>
                <Typography variant="caption" className={classes.item}>発送状況: {formateShippingStatusValue[item.shippingStatus]}</Typography>
              </div>
            </div>
            <div className={classes.item_buttons}>
              <Button disabled={!checkIsCancelable(item)} className={classes.active_button} onClick={() => handleCancelDialogOpen(item, order)}>商品をキャンセル</Button>
              <Button className={classes.active_button} onClick={() => handleContactDialogOpen(item)}>お問い合わせ</Button>
            </div>
          </div>)}
        </div>)}
        <CancelConfirmView />
        <ContactDialogView />
      </Container>
    );
  } else {
    return (
      <Typography style={{ marginTop: "200px", textAlign: "center" }} variant="subtitle2">購入履歴はございません。</Typography>
    )
  }
}

export default BuyNowHistoryView

import React, { memo, useEffect, useState } from "react";
import {
  List,
  ListItem,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { TitleView } from "../TitleView";
import { TicketStatusValue } from "../../utils/Firebase";
import { Product } from "../../types/product";

const useStyles = makeStyles(() => ({
  product_info_wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    marginBottom: "50px",
  },
  image_wrapper: {
    marginBottom: "16px",
    position: "relative",
  },
  image: {
    width: "85%",
    maxWidth: '350px',
    margin: "0 auto",
    objectFit: 'contain'
  },
  lists: {
    paddingBottom: "12px",
  },
  list: {
    display: "flex",
    textAlign: "center",
    marginTop: "16px",
  },
  list_title: {
    width: "50%",
    color: "grey",
  },
  list_text: {
    width: "50%",
    color: "grey",
  },
  total_price_list: {
    borderTop: "2px solid darkgray",
    paddingTop: "20px",
  },
}));

type Props = {
  productInfo: Product,
  shippingFee: any,
  myTicket: any,
  optionPrice: any,
  variation: any,
}

const ProductInfoView = memo((props: Props) => {
  const { productInfo, shippingFee, myTicket, optionPrice, variation } = props
  const classes = useStyles();
  const [variationPrice, setVariationPrice] = useState(0)

  const images = productInfo.images ? productInfo.images : [];

  useEffect(() => {
    if (variation) {
      setVariationPrice(Number(variation.price) - Number(productInfo.price))
    }
  }, [variation])

  return <>
    <div className={classes.product_info_wrapper}>
      <div className={classes.image_wrapper}>
        <img className={classes.image} src={images[0]} />
      </div>
      <TitleView style={{ letterSpacing: "1px", fontSize: "1.1rem", textAlign: "center", fontWeight: "bold" }} title={productInfo.title} ></TitleView>
      <List className={classes.lists}>
        {myTicket?.status === TicketStatusValue['ADDITIONAL_PAYMENT'] ?
          <>
            <ListItem className={classes.list}>
              <Typography variant='subtitle2' className={classes.list_title}>オプション費用<br></br> {myTicket.variation['1']}</Typography>
              <Typography variant='subtitle2' className={classes.list_text}>¥ {String(optionPrice).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}</Typography><br></br>
            </ListItem>
          </>
          :
          <>
            <ListItem className={classes.list}>
              <Typography variant='subtitle2' className={classes.list_title}>商品価格</Typography>
              <Typography variant='subtitle2' className={classes.list_text}>¥ {String(productInfo.price).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}</Typography><br></br>
            </ListItem>
            {variation?.title !== 'Default Title' &&
              <ListItem className={classes.list}>
                <Typography variant='subtitle2' className={classes.list_title}>オプション<br></br>{variation.title}</Typography>
                <Typography variant='subtitle2' className={classes.list_text}>¥ {String(variationPrice).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}</Typography><br></br>
              </ListItem>
            }
            <ListItem className={classes.list}>
              <Typography variant='subtitle2' className={classes.list_title}>配送料</Typography>
              <Typography variant='subtitle2' className={classes.list_text}>
                {shippingFee ?
                  <>¥ {String(shippingFee).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}</>
                  :
                  <>次のステップで計算されます....</>
                }
              </Typography>
            </ListItem>
            {shippingFee &&
              <ListItem className={classes.list + ' ' + classes.total_price_list}>
                <Typography variant='subtitle2' className={classes.list_title}>合計</Typography>
                <Typography variant='subtitle2' className={classes.list_text}>¥ {String(Number(shippingFee) + Number(productInfo.price) + Number(variationPrice)).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}</Typography>
              </ListItem>
            }
          </>
        }
      </List>
    </div>
  </>;
});
export default ProductInfoView;

import { Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, FormControl, InputLabel, makeStyles, MenuItem, Select, TextField, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { formatedYen } from '../utils/Common'
import { CloudFunctions, db, PaymentStatusValue, ShippingStatusValue } from '../utils/Firebase'
import { PaymentStatusValueFromNum, ShippingStatusValueFromNum } from '../utils/Orders'
import { shippingProviders } from '../utils/Shipping'
import { CartItem, Order } from '../types/order'

const useStyles = makeStyles(() => ({
  container: {
    marginTop: "50px",
  },
  order: {
    border: "1px solid gray",
    margin: "10px 0 30px 0",
  },
  order_header: {
    display: "flex",
    borderBottom: "1px solid gray",
    padding: "8px",
  },
  order_info: {
    marginRight: "24px",
  },
  downlaod_button: {
    color: "#ff0000",
  },
  item: {
    display: "flex",
    padding: "10px",
  },
  item_image: {
    width: "30%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  image: {
    width: "60%",
  },
  item_info: {
  },
  item_title: {
    margin: "8px 0",
  },
  slide_wrapper: {
    marginTop: "50px",
    backgroundColor: "white",
    height: "100vh",
    overflow: "scroll",
    color: "grey",
    padding: "0 24px",
  },
  input_wrapper: {
    width: "80%",
    maxWidth: "500px",
    margin: "100px auto",
  },
}))

type Params = {
  order_id: string
}
const AdminOrderDetail = () => {
  const classes = useStyles()
  const { order_id } = useParams<Params>()
  const [order, setOrder] = useState<Order | null>(null)
  const [message, setMessage] = useState("")


  useEffect(() => {
    db.collection('orders').doc(order_id).get().then(doc => {
      let _order = doc.data() as Order
      _order['id'] = doc.id
      setOrder(_order)
    })
  }, [order_id])

  const handleDownloadCsv = async () => {
    const func = CloudFunctions.httpsCallable('sendOrdersCsv')
    const res = await func({ orders: [order] })
    console.log('注文CSVの送信', res)
  }

  const [fulfillConfirmOpen, setFulfillConfirmOpen] = useState(false)
  const [fulfillItemIndex, setFulfillItemIndex] = useState(null)
  const [shippingProvider, setShippingProvider] = useState("")
  const [shippingNumber, setShippingNumber] = useState("")

  const handleFulfilledConfirmOpen = (e: any, _itemIndex: any) => {
    setFulfillItemIndex(_itemIndex)
    setFulfillConfirmOpen(true)
  }

  const handleShippingProviderChange = (e: any) => {
    setShippingProvider(e.target.value)
  }

  const handleShippingNumberChange = (e: any) => {
    setShippingNumber(e.target.value)
  }

  const handleOnlyFulfilled = async (_order: Order, _index: any) => {
    // 決済の確定
    const capturePaymentFunc = CloudFunctions.httpsCallable('stripeCaptureAuthorization')
    const res = await capturePaymentFunc({ payment_intent_id: _order.paymentInfo.id })
    console.log('購入決済を確定する', res)

    // データベースの更新
    let isCompletedFulfilled = true
    let udpateItem = _order.items[_index]
    udpateItem['paymentStatus'] = PaymentStatusValue['CAPTURE_PAYMENT']
    udpateItem['shippingStatus'] = ShippingStatusValue['FULFILLED']
    const updatedItems = _order.items.map((_item: any, index: any) => {
      if (index === _index) {
        return udpateItem
      } else {
        if (_item.shippingStatus === ShippingStatusValue['PENDING']) { // 発送準備中のアイテムがあれば「一部配送済み」になる
          isCompletedFulfilled = false
        }
        return _item
      }
    })
    await db.collection('orders').doc(_order.id).update({
      items: updatedItems,
      shippingStatus: isCompletedFulfilled ? ShippingStatusValue['FULFILLED'] : ShippingStatusValue['PARTIALLY_FULFILLED']
    }).then((response) => {
      console.log('データの更新成功:', response)
    }).catch(e => {
      console.log('配送状況, 決済状況の更新に失敗:', e)
    })

    setMessage(`決済確定成功  データ更新成功。決済ID: ${_order.paymentInfo.id}`,)
    if (!order) return
    const updatedOrder = order
    updatedOrder.items = updatedItems
    setOrder(updatedOrder)
  }

  // アイテムの発送完了
  const handleFulfilled = async () => {
    // 発送完了メール
    const sendMailFunc = CloudFunctions.httpsCallable('sendOrderItemFulfilledMail')
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    await sendMailFunc({ email: order.email, item: order.items[fulfillItemIndex], shippingProvider, shippingNumber })

    // 決済の確定
    const capturePaymentFunc = CloudFunctions.httpsCallable('stripeCaptureAuthorization')
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    await capturePaymentFunc({ payment_intent_id: order.paymentInfo.id })

    // データベースの更新
    let isCompletedFulfilled = true
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    let udpateItem = order.items[fulfillItemIndex]
    udpateItem['paymentStatus'] = PaymentStatusValue['CAPTURE_PAYMENT']
    udpateItem['shippingStatus'] = ShippingStatusValue['FULFILLED']
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    const updatedItems = order.items.map((_item: any, index: any) => {
      if (index === fulfillItemIndex) {
        return udpateItem
      } else {
        if (_item.shippingStatus === ShippingStatusValue['PENDING']) { // 発送準備中のアイテムがあれば「一部配送済み」になる
          isCompletedFulfilled = false
        }
        return _item
      }
    })
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    await db.collection('orders').doc(order.id).update({
      items: updatedItems,
      shippingStatus: isCompletedFulfilled ? ShippingStatusValue['FULFILLED'] : ShippingStatusValue['PARTIALLY_FULFILLED']
    })

    const updatedOrder = order
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    updatedOrder.items = updatedItems
    setOrder(updatedOrder)
  }

  const handleFulfilledClose = () => {
    setFulfillItemIndex(null)
    setFulfillConfirmOpen(false)
  }

  // アイテムの注文をキャンセル
  const handleItemCancel = async (_order: any, _index: any) => {
    // 決済のキャンセル
    const capturePaymentFunc = CloudFunctions.httpsCallable('stripeCancelAuthorization')
    const res = await capturePaymentFunc({ payment_intent_id: _order.paymentInfo.id })
    console.log('購入決済をキャンセルする', res)

    // データベースの更新
    let isCompletedFulfilled = true
    let udpateItem = _order.items[_index]
    udpateItem['paymentStatus'] = PaymentStatusValue['CANCELLED']
    udpateItem['shippingStatus'] = ShippingStatusValue['CANCELLED']
    const updatedItems = _order.items.map((_item: any, index: any) => {
      if (index === _index) {
        return udpateItem
      } else {
        if (_item.shippingStatus === ShippingStatusValue['PENDING']) { // 発送準備中のアイテムがあれば「一部配送済み」になる
          isCompletedFulfilled = false
        }
        return _item
      }
    })
    await db.collection('orders').doc(_order.id).update({
      items: updatedItems,
      shippingStatus: isCompletedFulfilled ? ShippingStatusValue['CANCELLED'] : ShippingStatusValue['CANCELLED']
    }).then((response) => {
      console.log('データの更新成功:', response)
    }).catch(e => {
      console.log('配送状況, 決済状況の更新に失敗:', e)
    })

    setMessage(`決済キャンセル,  データ更新成功。決済ID: ${_order.paymentInfo.id}`,)
    const updatedOrder = order
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    updatedOrder.items = updatedItems
    setOrder(updatedOrder)
  }

  const FulfillConfirmDialogView = () => {
    return (
      <Dialog open={fulfillConfirmOpen} style={{ padding: "30px 50px" }}>
        <DialogContent>
          <DialogContentText>
            {/* @ts-expect-error */}
            配送業者: {shippingProvider.name}<br />
            追跡番号: {shippingNumber}
            {/* @ts-expect-error */}
            発送アイテム: {fulfillItemIndex ? order.items[fulfillItemIndex].productInfo.title : ""}<br /><br />

            こちらのアイテムの発送完了通知をいたします。<br />
            よろしいでしょうか？
          </DialogContentText>
          <DialogActions>
            <Button onClick={handleFulfilledClose}>やめる</Button>
            <Button onClick={handleFulfilled} style={{ color: "red" }}>完了する</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    )
  }

  return (
    <Container maxWidth="md" className={classes.container}>
      {order ?
        <div className={classes.order}>
          <div className={classes.order_header}>
            <Typography variant="subtitle2" className={classes.order_info}>注文ID: <br></br>{order.id}</Typography>
            <Typography variant="subtitle2" className={classes.order_info}>注文日: <br></br>{order.items.length}アイテム</Typography>
            <Typography variant="subtitle2" className={classes.order_info}>合計: <br></br>¥{formatedYen(order.total)}アイテム</Typography>
            <Typography variant="subtitle2" className={classes.order_info}>Eamil: <br></br>{order.email}</Typography>
            <Typography variant="subtitle2" className={classes.order_info}>アイテム: <br></br>{order.items.length}アイテム</Typography>
            <Button onClick={handleDownloadCsv} className={classes.downlaod_button}>CSVをダウンロード</Button>
          </div>
          <div>
            {order.items.map((item: CartItem, index: any) => (
              <div className={classes.item} key={index}>
                <div className={classes.item_image}>
                  <img className={classes.image} src={item.productInfo.images[0]} />
                </div>
                <div className={classes.item_info}>
                  <Typography variant="subtitle2" className={classes.item_title}>商品名：{item.productInfo.title}</Typography>
                  {item.variant.title !== "Default Title" && <Typography variant="subtitle2" className={classes.item_title}>オプション: {item.variant.title}</Typography>}
                  <Typography variant="subtitle2" className={classes.item_title}>価格：¥{formatedYen(item.variant.price)}</Typography>
                  <Typography variant="subtitle2" className={classes.item_title}>購入数: {item.quantity}</Typography>
                  <Typography variant="subtitle2" className={classes.item_title}>出品者: {item.productInfo.vendor}</Typography>
                  {/* @ts-expect-error */}
                  <Typography variant="subtitle2" className={classes.item_title}>決済状況: {PaymentStatusValueFromNum[item.paymentStatus]}</Typography>
                  {/* @ts-expect-error */}
                  <Typography variant="subtitle2" className={classes.item_title}>配送状況: {ShippingStatusValueFromNum[item.shippingStatus]}</Typography>
                </div>
                <div>
                  <FormControl>
                    <InputLabel id="shiping-provider-select-label">配送業者</InputLabel>
                    <Select
                      style={{ width: "150px" }}
                      labelId='shiping-provider-select-label'
                      id='shiping-provider-select'
                      value={shippingProvider}
                      onChange={handleShippingProviderChange}
                    >
                      {shippingProviders.map(_shippingProvider => (
                        // @ts-expect-error TS(2769): No overload matches this call.
                        <MenuItem key={_shippingProvider.code} value={_shippingProvider}>{_shippingProvider.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl><br />
                  <TextField
                    style={{ margin: "20px 0" }}
                    value={shippingNumber}
                    onChange={handleShippingNumberChange}
                    label="配送番号"
                  /><br />
                  {/* @ts-ignore */}
                  <Button type="outlined" color="secondary" disabled={!shippingNumber || !shippingProvider} onClick={(e) => handleFulfilledConfirmOpen(e, index)}>発送完了手続きをする</Button>
                  <Button onClick={() => handleOnlyFulfilled(order, index)}>注文完了にする</Button>
                  <Button onClick={() => handleItemCancel(order, index)}>注文キャンセルする</Button>
                  <Typography>{message}</Typography>
                </div>
              </div>
            ))}
          </div>
        </div>
        : <></>}
      <FulfillConfirmDialogView />
    </Container>
  );
}

export default AdminOrderDetail
import { Container, makeStyles } from "@material-ui/core"
import { useForm } from "react-hook-form"

const useStyles = makeStyles(() => ({
    contactWrapper: {
        display: 'flex',
        width: '100%',
    },
    inputWrapper: {
        width: '80%',
    },
    input: {
        width: '100%',
        height: '34px',
    },
    buttonWrapper: {
        width: '20%',
        marginLeft: '16px',
    },
    button: {
        border: 'none',
        color: 'white',
        backgroundColor: '#f00',
        width: '100%',
        height: '40px',
        paddingY: '10px',
        fontWeight: 'bold',
        fontSize: '16px',
    },
    errorMessage: {
        color: '#f00',
        fontSize: '14px',
    }
}))

const MessagesView = ({
    handleSendMessage
}: any) => {
    const classes = useStyles()
    const { register, getValues, handleSubmit, formState: { errors } } = useForm()

    // お問合せ内容を送信
    const sendMessage = async () => {
        const text = getValues('contactText')
        handleSendMessage(text)
    }

    const MessageForm = () => {
        return (
            <Container maxWidth='sm' style={{ padding: '0' }}>
                <form onSubmit={handleSubmit(sendMessage)}>
                    <div className={classes.contactWrapper}>
                        <div className={classes.inputWrapper}>
                            <input
                                required
                                className={classes.input}
                                id="contactText"
                                {...register('contactText', {
                                    required: {
                                        value: true,
                                        message: '入力してください'
                                    }
                                })} />
                            <p className={classes.errorMessage}>{errors.contactText?.message}</p>
                        </div>
                        <div className={classes.buttonWrapper}>
                            <button
                                type="submit"
                                className={classes.button}
                            >送信</button>
                        </div>
                    </div>
                </form>
            </Container >
        )
    }

    return (<MessageForm />)
}

export default MessagesView
import React, { useEffect, useState, useContext } from 'react';
import {
  Button,
  Container,
  List,
  ListItem,
  makeStyles,
  Toolbar,
  Typography,
  CircularProgress,
  Link,
  Backdrop,
  FormControl,
  InputLabel,
  Select,
  DialogContent,
  DialogActions,
  Dialog
} from '@material-ui/core';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { motion, useAnimation } from 'framer-motion';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { db } from "../utils/Firebase";
import { AuthContext } from "../contexts/Auth";
import { CartContext } from "../contexts/Cart";
import { pageEnterVariants } from '../Animations/variants';
import { TitleView } from '../components/TitleView';
import { blue } from '@material-ui/core/colors';
import ProductImagesView from '../components/common/ProductImagesView';
import { Product, ProductVariant } from '../types/product';
import { ProcessionStatus } from '../types/common';

const useStyles = makeStyles((theme) => ({
  images_wrapper: {
    maxWidth: "350px",
    width: "80%",
    margin: "0 auto",
  },
  main_container: {
    marginTop: theme.spacing(9),
    paddingBottom: theme.spacing(8),
  },
  card: {
    marginTop: theme.spacing(2),
    height: "100%",
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  item_info: {
    marginTop: theme.spacing(4),
    display: "flex",
    color: '#ff0000',
    justifyContent: 'center',
    minHeight: '30px',
  },
  list_root: {
    marginTop: theme.spacing(0),
  },
  product_info_list: {
    display: 'flex',
    flexFlow: "column",
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    textAlign: 'center',
  },
  product_info_list_title: {
    color: "gray",
    width: '50%',
  },
  product_info_list_text: {
    width: '50%',
  },
  vendor_url: {
    color: '#585858',
  },
  option_item: {
    border: "1px solid gray",
    boxSizing: "border-box",
    width: "150px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "70px",
    '&:hover': {
      cursor: "pointer",
    }
  },
  selected: {
    border: "1.5px solid black",
    color: "red",
  },
  product_info_list_form: {
    width: '60%',
    justifyContent: "center",
    margin: '16px auto',
  },
  description: {
    width: "100%",
    maxWidth: "1020px",
    position: 'relative',
    overflow: 'hidden',
    fontSize: '0.9rem',
    letterSpacing: '0.1rem',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(3),
  },
  desc_html: {
    lineHeight: "1.65",
    '& p': {
      margin: "0",
      padding: "0",
      '&.pi': {
        padding: "10px",
      },
    },
    '& ul': {
      paddingLeft: "1rem",
    },
    '& ol': {
      marginTop: "4px",
      paddingLeft: "1rem",
    }
  },
  share_icons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: 'center',
  },
  share_icon: {
    margin: "8px",
  },
  entry_button_wrap: {
    position: "fixed",
    width: "100%",
    left: "0",
    bottom: "0",
    textAlign: 'center',
    backgroundColor: '#fff',
    padding: "24px",
    boxSizing: "border-box",
    zIndex: 1300,
    [theme.breakpoints.down('sm')]: {
      padding: "16px",
    }
  },
  entry_button: {
    lineHeight: "30px",
    fontSize: '0.9rem',
    width: "100%",
    maxWidth: "1020px",
    margin: "0 auto",
    color: '#fff',
    backgroundColor: "#ff0000",
    fontWeight: 'bold',
    borderRadius: "0",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  error_message: {
    color: "#ff0000",
  },
}));

const DESCRIPTION_DELIMITER_STR = '###fold_below';

type Paramas = {
  product_id: string;
}

const OnSale = () => {
  const classes = useStyles();
  const { product_id } = useParams<Paramas>();
  const [shopLoading, setShopLoading] = useState(true);
  const [productInfo, setProductInfo] = useState<Product>();
  const { currentUser } = useContext<any>(AuthContext)
  const { addCartItem, setIsCartOpen } = useContext<any>(CartContext)
  const controls = useAnimation();
  const [selectedVariant, setSelectedVariant] = useState<ProductVariant | null>(null)
  const [states, setStates] = useState({
    errorMessage: "",
    onChangeEntry: false,
  })
  const history = useHistory()

  // 基本データの取得
  useEffect(() => {
    let isMounted = true;

    const f = async () => {
      try {
        let query = db.collection("products").doc(product_id);
        const _product = await query.get();
        const product = _product.data();
        if (!product) return
        if (product.product_type !== '先着') {
          history.push('/')
        }
        const checkedDescription = checkDescription(product.description)
        const _productInfo: Product = {
          id: product.id,
          subtitle: checkedDescription.subtitle ? checkedDescription.subtitle : null,
          price: product.variants[0].price,
          images: product.images.map((image: any) => {
            return image.src;
          }),
          deadline_date: product.deadline_date,
          release_date: product.release_date,
          options: product.options,
          description: checkedDescription.product_description,
          updated_at: new Date(product.updated_at),
          created_at: new Date(product.created_at),
          product_type: product.product_type,
          vendor: product.vendor,
          vendor_url: checkedDescription.vendor_url ? checkedDescription.vendor_url : null,
          variants: product.variants,
          // firestoreではjson文字列に変換されているのでjsonに変換
          payment_variants: product.payment_variants,
          shipping_fee_name: product.shipping_fee_name,
          title: product.title,
          payment_product_id: product.payment_product_id,
          level: product.level,
          is_authorization: product.is_authorization,
          showcase_date: new Date(product.showcase_date),
          image: product.image,
          owner: product.owner,
          notion_page_id: product.notion_page_id,
          auction_fee: product.auction_fee,
          insurance_fee_name: product.insurance_fee_name,
          hasWinImage: product.hasWinImage,
          winImageUrl: product.winImageUrl,
          current_bid_amount: product.current_bid_amount,
          bid_history: product.bid_history,
          expected_winning_bid_amount: product.expected_winning_bid_amount,
          authorization_limit: product.authorization_limit,
          is_banner_view: product.is_banner_view,
          draft_status: product.draft_status
        }

        if (isMounted) {
          for (const variant of _productInfo.variants) {
            if (variant.inventory_quantity !== 0) {
              setSelectedVariant(variant)
              break
            }
          }
          setProductInfo(_productInfo);
        }

        if (isMounted) {
          setShopLoading(false);
        }
        controls.start("visible");

      } catch (e) {
        console.error(e);
        if (isMounted) {
          setShopLoading(false);
        }
        controls.start("visible");
      }
    }
    if (product_id) { f(); }

    return () => {
      isMounted = false;
    }

  }, [product_id]);

  // ステータス
  const [processionStatus, setProcessionStatus] = useState<ProcessionStatus>(null);
  useEffect(() => {
    let isMounted = true;
    if (!productInfo) return;
    if (isMounted) {
      if (productInfo.close) {
        setProcessionStatus('soldOut')
      } else if (new Date().getTime() < productInfo.release_date.seconds * 1000) {
        setProcessionStatus('notRelase');
      } else if (new Date().getTime() > productInfo.deadline_date.seconds * 1000) {
        setProcessionStatus('soldOut');
      } else {
        setProcessionStatus('processionable');
      }
    }

    return () => {
      isMounted = false;
    }

  }, [productInfo]);

  // メイン画像
  const ImageSlideView = (props: any) => {
    const images = props.images ? props.images : [];
    if (images.length >= 1) {
      return (
        <>
          <Slider />
          <div className={classes.images_wrapper}>
            {processionStatus === 'soldOut' &&
              <ProductImagesView images={images} ticketValue="sold" />
            }
            {processionStatus === 'notRelease' || processionStatus === 'processionable' &&
              <ProductImagesView images={images} ticketValue="" />
            }
          </div>
        </>
      )
    } else {
      return (<></>)
    }
  };


  // 説明テキスト部分
  const DescriptionView = (props: any) => {
    return (
      <Container className={classes.description}>
        <span className={classes.desc_html} dangerouslySetInnerHTML={{ __html: props.htmlString.replace(DESCRIPTION_DELIMITER_STR, "") }}></span>
        {(processionStatus === 'processionable') &&
          <Toolbar className={classes.entry_button_wrap}>
            <Button disabled={states.onChangeEntry} className={classes.entry_button} onClick={handleAddCart}>
              カートに追加する
            </Button>
          </Toolbar>
        }
      </Container>
    )
  }

  const setMeta = () => {
    const headData = document.head.children;
    const title = productInfo ? `${productInfo.title}購入ページ` : 'Dropp商品購入ページ';
    const description = productInfo ? `${productInfo.title}の購入はこちら` : 'Dropp商品の購入はこちら';
    const keywords = productInfo ? `${productInfo.title} BuyNow 購入 先着 Dropp` : 'BuyNow 購入 先着 Dropp';

    for (let i = 0; i < headData.length; i++) {
      const nameVal = headData[i].getAttribute('name');
      if (nameVal !== null) {
        if (nameVal.indexOf('keywords') != -1) {
          headData[i].setAttribute('content', keywords);
        }
        if (nameVal.indexOf('description') != -1) {
          headData[i].setAttribute('content', description);
        }
        // OGP(twitter)の設定
        if (nameVal.indexOf('twitter:title') != -1) {
          headData[i].setAttribute('content', title);
        }
        if (nameVal.indexOf('twitter:description') != -1) {
          headData[i].setAttribute('content', description);
        }
      }
    }
  }

  const checkDescription = (description: any) => {
    let res = [description];
    let res2;
    let subtitle;
    let vendor_url;
    if (res[0].split('###vendor_url').length >= 2) {
      res2 = res[0].split('###vendor_url');
      vendor_url = res2[1].replace(/(<([^>]+)>)/gi, '').trim();
      res[0] = res2[0]
    }
    if (res[0].split('###subtitle').length >= 2) {
      res2 = res[0].split('###subtitle');
      subtitle = res2[1].replace(/(<([^>]+)>)/gi, '');
      res[0] = res2[0]
    }
    const desc = res[0];
    return {
      product_description: desc,
      subtitle: subtitle,
      vendor_url: vendor_url,
    }
  };

  const TwitterShare = () => {
    const twitterBaseUrl = "https://twitter.com/intent/tweet?url=https://dropp.jp&text=抽選も楽しめる次世代型オークションハウス&hashtags=Dropp";
    window.open(twitterBaseUrl, "_blank")
  }

  const InstagramShare = () => {
    const instagramBaseUrl = "https://www.instagram.com/dropp.jp/?hl=ja";
    window.open(instagramBaseUrl, "_blank")
  }

  setMeta();

  const [signinDialogOpen, setSigninDialogOpen] = useState(false)
  const SigninDialogView = () => {
    return (
      <Dialog open={signinDialogOpen} onClose={() => setSigninDialogOpen(false)}>
        <DialogContent>
          {/* アカウントがない or ログインしていない */}
          {!currentUser &&
            <>
              <Typography variant="subtitle2">商品の購入にはアカウントが必要です。</Typography>
            </>
          }
          {/* ログインしているが、電話番号認証がない */}
          {currentUser && !currentUser.phoneNumber &&
            <>
              <Typography variant="subtitle2">商品の購入には電話番号認証が必要です。</Typography>
            </>
          }
        </DialogContent>
        <DialogActions>
          {!currentUser &&
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button variant="text" onClick={() => setSigninDialogOpen(false)}>戻る</Button>
              <Button variant="text" style={{ color: "#ff0000" }} onClick={() => history.push('/signin')}>ログイン</Button>
              <Button variant="text" style={{ color: "#ff0000" }} onClick={() => history.push('/signup/email')}>新規作成</Button>
            </div>
          }
          {currentUser && !currentUser.phoneNumber &&
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button variant="text" onClick={() => setSigninDialogOpen(false)}>戻る</Button>
              <Button variant="text" style={{ color: "#ff0000" }} onClick={() => history.push('/signup/sms')}>電話番号認証へ</Button>
            </div>
          }
        </DialogActions>
      </Dialog>
    )
  }

  const handleAddCart = async () => {
    if (!currentUser || !currentUser.phoneNumber || !productInfo) {
      setSigninDialogOpen(true)
      return
    }
    if (!selectedVariant) {
      setStates({ ...states, errorMessage: `ご希望の${productInfo.options[0].name}を選択してください。` })
      scrollTo({ top: 500, behavior: "smooth" })
      return
    }
    delete productInfo.updated_at
    // @ts-expect-error TS(2339): Property 'created_at' does not exist on type 'Prod... Remove this comment to see the full error message
    delete selectedVariant.created_at
    // @ts-expect-error TS(2339): Property 'updated_at' does not exist on type 'Prod... Remove this comment to see the full error message
    delete selectedVariant.updated_at
    delete productInfo.created_at
    let _item = {
      productInfo,
      variant: selectedVariant,
      quantity: 1,
    }
    await addCartItem(_item)
    setIsCartOpen(true)
  }

  const handleChangeOption = (e: any) => {
    e.preventDefault();
    const _variant = productInfo?.variants[e.target.value]
    if (_variant) setSelectedVariant(_variant)
  }

  return (
    <React.Fragment key={product_id}>
      {!shopLoading &&
        <>
          <motion.div
            initial={{ opacity: 0, scale: 1.05 }}
            variants={pageEnterVariants}
            animate={controls}
            exit={{ opacity: 0, transition: { duration: 0.8 }, scale: 1.05 }}
          >
            <Container className={classes.main_container}>
              <div className={classes.card}>
                <div className={classes.item_info}>
                </div>
                <ImageSlideView images={productInfo?.images} />
                <TitleView title={productInfo?.title} subtitle={productInfo?.subtitle}></TitleView>
              </div>
              <List className={classes.list_root}>
                <ListItem className={classes.product_info_list}>
                  <Typography variant='subtitle2' className={classes.product_info_list_title}>価格 (税込み)</Typography>
                  <Typography variant='subtitle1' className={classes.product_info_list_text}>¥{String(productInfo?.price).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}</Typography>
                </ListItem>
                <ListItem className={classes.product_info_list}>
                  <Typography variant='subtitle2' className={classes.product_info_list_title}>購入方法</Typography>
                  <Typography variant='subtitle1' className={classes.product_info_list_text}>{productInfo?.product_type}</Typography>
                </ListItem>
                <ListItem className={classes.product_info_list}>
                  <Typography variant='subtitle2' className={classes.product_info_list_title}>販売元</Typography>
                  <Typography variant='subtitle1' className={classes.product_info_list_text}>
                    {productInfo?.vendor_url ?
                      <Link underline="always" className={classes.vendor_url} href={productInfo.vendor_url} target="_blank" rel="noopener">
                        {productInfo?.vendor}
                      </Link>
                      : <>{productInfo?.vendor}</>
                    }
                  </Typography>
                </ListItem>
                {/* オプション選択   */}
                {processionStatus === 'processionable' && productInfo?.options && productInfo.options.length > 0 && productInfo?.options[0].name != 'Title' ?
                  <>
                    <ListItem className={classes.product_info_list_form}>
                      <FormControl variant='outlined'>
                        <InputLabel id="product_type">{productInfo.options[0].name}</InputLabel>
                        <Select
                          native
                          label={productInfo.options[0].name}
                          labelId="product_type"
                          style={{ width: '100%', height: '40px', borderRadius: '0' }}
                          onChange={handleChangeOption}
                        >
                          {productInfo.variants.map((variant: any, index: any) => (
                            <option value={index} key={index} disabled={variant.inventory_quantity === 0}>
                              {variant.title} {variant.inventory_quantity === 0 && "(完売)"}
                            </option>
                          ))}
                        </Select>
                      </FormControl>
                    </ListItem>
                  </>
                  :
                  <></>
                }
                <ListItem className={classes.share_icons}>
                  <Link onClick={TwitterShare}><TwitterIcon style={{ color: blue[500] }} className={classes.share_icon} /></Link>
                  <Link onClick={InstagramShare}><InstagramIcon color="secondary" className={classes.share_icon} /></Link>
                </ListItem>
              </List>
              <DescriptionView htmlString={productInfo?.description} />
            </Container>
            <Backdrop className={classes.backdrop} open={states.onChangeEntry} >
              <CircularProgress color='inherit' />
            </Backdrop>
            <SigninDialogView />
          </motion.div>
        </>
      }
    </React.Fragment >
  );
};

export default withRouter(OnSale);
import React, { memo, useState, useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Checkbox,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  List,
  ListItem,
  makeStyles,
  MuiThemeProvider,
  Radio,
  RadioGroup,
  Typography,
  withStyles,
} from "@material-ui/core";
import { createMuiTheme } from '@material-ui/core';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { CloudFunctions, db } from "../../utils/Firebase";
import { getCountryName } from "../../utils/Shipping";
import { useParams, useHistory } from "react-router-dom";
import { AuthContext } from "../../contexts/Auth";
import Common from "../../redux/common/commonSlice";
import TermOfUseSlideView from "../common/TermOfUseSlideView";
import ProductInfoView from "./ProductInfoView";
import BackdropLoading from "../common/BackdropLoading";
import { formatedYen } from "../../utils/Common";
import { Product } from "../../types/product";
import { ShippingMethod } from "../../types/common";
import { PaymentMethod } from "@stripe/stripe-js";
import { useSelector } from "../../redux/rootReducer";

const cardElementOptions = {
  hidePostalCode: true,
  style: {
    base: {
    },
    invalid: {},
    complete: {},
  },
}

type CheckboxType = {
  checked: boolean,
  onChange: any,
  name?: string,
}
const CustomCheckBox = withStyles({
  root: {
    color: "#ff0000",
    '&$checked': {
      color: "#ff0000",
    },
  },
  checked: {},
})((props: CheckboxType) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
  input: {
    background: theme.palette.secondary.main,
    border: `1px solid white`,
    flex: 1,
    padding: '8px',
    '&[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  main_container: {
    marginTop: theme.spacing(12),
    paddingBottom: theme.spacing(8),
    maxWidth: "450px",
  },
  card: {
    padding: "20px",
    margin: "0 auto",
    marginTop: "10px",
    position: "relative"
  },
  product_info_wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
  },
  image_wrapper: {
    marginBottom: "16px",
  },
  image: {
    maxWidth: '100%',
    objectFit: 'contain'
  },
  product_title: {
    fontSize: "1rem",
    marginTop: theme.spacing(2),
  },
  lists: {
    paddingBottom: "12px",
    marginBottom: "30px",
  },
  list: {
    display: "flex",
    textAlign: "center",
    marginTop: theme.spacing(1),
  },
  list_title: {
    width: "50%",
  },
  list_text: {
    width: "50%",
  },
  bid_input_area: {
    minHeight: "50px",
    marginBottom: "50px",
    color: "grey",
  },
  bid_input_wrapper: {
    margin: "24px 0 10px",
    display: "flex",
    alignItems: "center",
  },
  bid_input_label: {
    textAlign: "center",
    width: "50%",
    lineHeight: "40px",
  },
  select_form_control: {
    width: "100%",
  },
  error_message: {
    fontWeight: "bold",
    color: "#ff0000",
  },
  card_wrapper: {
    minHeight: "100px",
    marginBottom: "50px",
  },
  credit_card: {
    border: "1px solid black",
    width: "100%",
    position: "relative",
    left: "10px",
    marginTop: "12px",
  },
  selected_credit_card: {
    border: "1px solid rgba(0, 0, 0, 0.38)",
    width: "340px",
    position: "relative",
    left: "10px",
    marginTop: "12px",
  },
  label: {
    width: "100%",
    display: "flex",
    alignItems: "justify-content",
  },
  add_button: {
    borderRadius: "20px",
    margin: "16px 0",
    border: "1px solid black",
  },
  card_element: {
    marginTop: "10px",
    marginBottom: "16px",
    border: "1px solid rgba(0, 0, 0, 0.38)",
    borderRadius: "0",
    padding: "15px",
    boxSizing: "border-box",
    minWidth: "100%",
  },
  selected_card_element: {
    marginTop: "10px",
    marginBottom: "16px",
    border: "1px solid black",
    borderRadius: "0",
    padding: "15px",
  },
  shipping_method_view: {
    marginBottom: "60px",
  },
  update_button: {
    color: "#ff0000",
    borderRadius: "0",
    border: "1px solid #ff0000",
  },
  shipping_method_wrap: {
    display: "flex",
    alignItems: "center",
    marginBottom: "0.5rem",
    width: "100%",
  },
  shipping_method_title: {
    color: "grey",
    width: "20%",
    verticalAlign: "middle"
  },
  shipping_method_text: {
    width: "70%",
    color: "grey",
  },
  shipping_method: {
    width: "100%",
    boxSizing: "border-box",
    marginBottom: "16px",
  },
  botton_wrapper: {
    display: "flex",
  },
  next_botton: {
    color: "red",
    marginLeft: "10px",
  },
  list_root: {
    marginBottom: "20px",
  },
  product_info_list: {
    display: "flex",
    textAlign: "center",
  },
  product_info_list_title: {
    width: "50%",
  },
  product_info_list_text: {
    width: "50%",
  },
  terms_wrapper: {
    backgroundColor: "white",
    height: "100vh",
    padding: "30px 30px",
    width: "100%",
    margin: "30px auto",
    overflow: "scroll",
    maxHeight: "100%",
    boxSizing: "border-box",
  },
  terms_wrapper_inner: {
    maxWidth: "800px",
    margin: "0 auto",
  },
}));

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#000',
      main: '#000',
      dark: '#000',
      contrastText: '#fff',
    },
  },
});

type Props = {
  productInfo: Product
  states: any
  setStates: any
  myTicket: any
  setMyTicket: any
  currentTeaserEmail: any
  paymentStates: any
  setPaymentStates: any
}

type Params = {
  product_id: string
}

const BidInput = memo((props: Props) => {
  const { productInfo, states, setStates, myTicket, setMyTicket, currentTeaserEmail, paymentStates, setPaymentStates } = props
  const shippingMethod = useSelector((state) => state.shippingMethod)
  const { currentUser } = useContext<any>(AuthContext);
  const { product_id } = useParams<Params>();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false)
  const [termOfUseOpen, setTermOfUseOpen] = useState(false)
  const [isCheckedTerms, setIsCheckedTerms] = useState(false)
  const [onShowTermsType, setOnShowTermsType] = useState("")

  const elements = useElements();
  const stripe = useStripe();
  const history = useHistory()

  // 支払い用カードの取得
  const [paymentMethods, setPaymentMethods] = useState([])
  useEffect(() => {
    let isMounted = true;
    const getStripePaymentMethods = async () => {
      if (currentTeaserEmail?.stripe_customer_id) {
        // if (currentTeaserEmail?.test_stripe_customer_id) {
        setPaymentStates({ ...paymentStates, onPaymentMethodsLoading: true })
        let stripeGetPaymentMethods = CloudFunctions.httpsCallable("stripeGetPaymentMethods");
        // let stripeGetPaymentMethods = CloudFunctions.httpsCallable("testStripeGetPaymentMethods");
        let customer_id = currentTeaserEmail.stripe_customer_id
        // let customer_id = currentTeaserEmail.test_stripe_customer_id
        try {
          let _payment_methods = await stripeGetPaymentMethods({ customer_id: customer_id })
          let selectedPaymentMethodStr = _payment_methods.data.data.length > 0 ? "0" : "-1"
          if (isMounted) {
            setPaymentMethods(_payment_methods.data.data)
            setPaymentStates({
              ...paymentStates,
              onPaymentMethodsLoading: false,
              selectedPaymentMethodStr: selectedPaymentMethodStr,
              isPaymentMethodSave: selectedPaymentMethodStr === '-1',
            })
          }
        } catch (e) {
          if (isMounted) {
            setPaymentStates({ ...paymentStates, onPaymentMethodsLoading: false, isPaymentMethodSave: true, selectedPaymentMethodStr: "-1" })
            setPaymentMethods([])
          }
        }
      } else {
        if (isMounted) {
          setPaymentStates({ ...paymentStates, onPaymentMethodsLoading: false, isPaymentMethodSave: true, selectedPaymentMethodStr: "-1" })
          setPaymentMethods([])
        }
      }
    }

    getStripePaymentMethods()
    return () => {
      isMounted = false
    }
  }, [])

  const bidProcesFinishAndToAuctionTop = (_message: any) => {
    history.push(`/auction/${productInfo.id}`)
    setStates({ ...states, onBidProcess: false, selectedShippingMethodStr: "", bidProcess: 0 })
    dispatch(
      Common.actions.fetchAlert({
        alerts: [{ message: _message }],
      })
    );
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 100)
  }

  const handleBid = async () => {
    setDialogOpen(false)
    setStates({ ...states, onBidProcess: true })

    // 入札可能時間をチェック
    if (new Date().getTime() > productInfo.deadline_date.seconds * 1000) {
      bidProcesFinishAndToAuctionTop("申し訳ございません。入札可能時間が終了いたしました。")
      return
    }

    // 最高額よりも入札額が小さければオーソリは取らない
    let _paymentFlg = !productInfo.highest_bid_ticket || (paymentStates.inputAmount > productInfo.highest_bid_ticket.bid_amount)
    if (!productInfo.is_authorization) {
      _paymentFlg = false
    }

    // 1.オーソリ枠を確保
    const paymentResult = _paymentFlg ? await stripePayment() : { status: "withoutAuthorization", paymentIntent: { id: null } }

    if (!paymentResult || paymentResult?.status === 'failed') {
      setStates({ ...states, onBidProcess: false })
      // @ts-ignore
      setPaymentStates({ ...paymentStates, paymentErrorMessage: paymentResult.errorMessage })
      scrollTo({ top: 500, behavior: "smooth" })
      return
    }

    // 2.入札を実行
    let res: any = {}
    try {
      res = await createTicket(paymentResult?.paymentIntent?.id);
    } catch (e) {
      res = { status: "failed", ticket: null, message: "入札に失敗しました。時間を空けて再度入札をお願いいたします。" }
      setStates({ ...states, onBidProcess: false })
      return
    }

    // 入札に失敗 + オーソリしていたらオーソリキャンセル

    if (res.status === 'failed' && paymentResult.status === 'success') {
      const stripeCancelAuthorization = CloudFunctions.httpsCallable("stripeCancelAuthorization");
      // const stripeCancelAuthorization = CloudFunctions.httpsCallable("testStripeCancelAuthorization");
      await stripeCancelAuthorization({ payment_intent_id: paymentResult?.paymentIntent?.id })
    }

    // 配送情報保存
    if (states.isShippingMethodSave) {
      currentTeaserEmail["shipping_methods"] = currentTeaserEmail.shipping_methods?.length > 0 ? [...currentTeaserEmail.shipping_methods, res.ticket.shipping_info] : [res.ticket.shipping_info,]
      await db.collection('teaser_emails').doc(currentTeaserEmail.id).update({
        shipping_methods: currentTeaserEmail["shipping_methods"]
      })
    }
    // 入札結果表示
    setMyTicket(res.ticket)
    bidProcesFinishAndToAuctionTop(res.message)
  }

  const stripePayment = async () => {
    try {
      if (!stripe || !elements) { return }

      // 1.決済情報保存時のStripe Customer IDを取得
      let stripe_customer_id = ""
      if (paymentStates.isPaymentMethodSave && !currentTeaserEmail?.stripe_customer_id) {
        // if (paymentStates.isPaymentMethodSave && !currentTeaserEmail?.test_stripe_customer_id) {
        const stripeCreateCustomer = CloudFunctions.httpsCallable("stripeCreateCustomer");
        // const stripeCreateCustomer = CloudFunctions.httpsCallable("testStripeCreateCustomer");
        let customer = await stripeCreateCustomer({ email: currentUser.email })
        stripe_customer_id = customer.data.id
      } else if (!paymentStates.isPaymentMethodSave && paymentStates.selectedPaymentMethodStr === '-1') {
        stripe_customer_id = ""
      } else {
        stripe_customer_id = currentTeaserEmail.stripe_customer_id
        // stripe_customer_id = currentTeaserEmail.test_stripe_customer_id
      }

      // 2.peyment intent(支払い)の作成
      let stripeCreatePaymentIntent = CloudFunctions.httpsCallable("stripeCreatePaymentIntent");
      // let stripeCreatePaymentIntent = CloudFunctions.httpsCallable("testStripeCreatePaymentIntent");
      let bid_amount = paymentStates.inputAmount ? Number(paymentStates.inputAmount) : Number(paymentStates.biddableAmounts[0])
      let dropp_fee = bid_amount * productInfo.auction_fee / 100
      let shipping_fee = paymentStates.shippingFee
      let insurance_fee = paymentStates.insuranceFee ? paymentStates.insuranceFee : 0
      let authorization_amount = bid_amount + dropp_fee + shipping_fee + insurance_fee
      if (productInfo.highest_bid_ticket && productInfo.highest_bid_ticket.uid === myTicket?.uid) {
        authorization_amount = authorization_amount - myTicket.authorization_amount
      }

      // 3. payment_intent作成
      let res = await stripeCreatePaymentIntent({
        amount: (productInfo.authorization_limit > 0 && authorization_amount >= productInfo.authorization_limit) ? productInfo.authorization_limit : authorization_amount, //オーソリ上限
        description: `${productInfo.title}の入札オーソリ`,
        username: currentUser.email,
        customer_id: stripe_customer_id,
      })
      let clientSecret = res.data.client_secret
      if (!clientSecret) return;

      console.log('オーソリ実行')
      // 4.payment_intent(支払い)の確定
      // @ts-expect-error
      let _payment_method = paymentStates.selectedPaymentMethodStr === '-1' ? { card: elements.getElement(CardElement) } : paymentMethods[paymentStates.selectedPaymentMethodStr].id
      const { error: stripeError, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: _payment_method,
      });

      // 5.支払い結果
      if (stripeError) {
        let _error_message
        switch (stripeError.code) {
          case "insufficient_funds":
            _error_message = "カード残高が不足しています。"
            break
          case "expired_card":
            _error_message = "カードの有効期限が切れています。"
            break
          case "incorrect_cvc":
            _error_message = "カードのセキュリティコードが間違っています。"
            break
          case "processing_error":
            _error_message = "カードの処理中にエラーが発生しました。しばらくしてからもう一度お試しください。"
            break
          default:
            _error_message = "カード決済に失敗しました。カードを変更いただくか時間をあけて再度お願い致します。"
            break
        }
        return { status: "failed", paymentIntent: null, errorMessage: _error_message }
      } else {
        if (paymentStates.isPaymentMethodSave && !currentTeaserEmail?.stripe_customer_id) {
          await db.collection('teaser_emails').where('email', "==", currentUser.email).get().then(snapshot => {
            snapshot.forEach(async (doc) => {
              await db.collection('teaser_emails').doc(doc.id).update({
                ...doc.data(),
                stripe_customer_id: stripe_customer_id
                // test_stripe_customer_id: stripe_customer_id
              })
            })
          })
        }
        return { status: "success", paymentIntent, }
      }
    } catch (e) {
      return { status: "failed", paymentIntent: null, errorMessage: "決済に失敗しました。支払い方法を変更いただくか時間をあけて再度お願い致します。" }
    }
  }

  const createTicket = async (_payment_intent_id: any) => {
    // 配送先住所を整形
    let shipping_info: ShippingMethod = {
      address1: "",
      address2: "",
      zipcode: "",
      province: "",
      province_code: "",
      city: "",
      first_name: "",
      second_name: "",
      first_name_kana: "",
      second_name_kana: "",
      country_code: "",
      country: "",
      phone: "",
    }
    try {
      shipping_info["address1"] = shippingMethod.address1
      shipping_info["address2"] = shippingMethod.address2
      shipping_info["zipcode"] = shippingMethod.zipcode
      shipping_info["province"] = shippingMethod.province
      shipping_info["province_code"] = shippingMethod.province_code
      shipping_info["city"] = shippingMethod.city
      shipping_info["first_name"] = shippingMethod.firstName
      shipping_info["second_name"] = shippingMethod.secondName
      shipping_info["first_name_kana"] = shippingMethod.firstNameKana
      shipping_info["second_name_kana"] = shippingMethod.secondNameKana
      shipping_info["country_code"] = shippingMethod.countryCode
      shipping_info["country"] = getCountryName(shippingMethod.countryCode) ?? ''
      shipping_info["phone"] = shippingMethod.phone
    } catch (e) {
      console.error('error', e)
    }

    // オーソリ履歴を整形
    let authorization_history = []
    if (myTicket && _payment_intent_id) {
      authorization_history = [...myTicket.authorization_history, _payment_intent_id]
    } else if (myTicket && !_payment_intent_id) {
      authorization_history = myTicket.authorization_history
    } else if (!myTicket && _payment_intent_id) {
      authorization_history = [_payment_intent_id]
    } else if (!myTicket && !_payment_intent_id) {
      authorization_history = []
    }

    // 入札金額を整形
    let bid_amount = paymentStates.inputAmount ? Number(paymentStates.inputAmount) : paymentStates.biddableAmounts[0]
    let dropp_fee = bid_amount * productInfo.auction_fee / 100
    let shipping_fee = paymentStates.shippingFee
    let insurance_fee = paymentStates.insuranceFee ? paymentStates.insuranceFee : 0
    let total_amount = bid_amount + dropp_fee + shipping_fee + insurance_fee
    let authorization_amount = 0
    if (productInfo.is_authorization) {
      if (total_amount >= productInfo.authorization_limit) {
        authorization_amount = productInfo.authorization_limit // オーソリ上限が設定されている + 入札金額 < オーソリ上限
      } else {
        authorization_amount = total_amount // オーソリ上限が設定されていないまたは、入札金額 > オーソリ上限
      }
    } else {
      authorization_amount = 0 // オーソリが設定されていない
    }

    try {
      const createBidTicket = CloudFunctions.httpsCallable("createBidTicket");
      // const createBidTicket = CloudFunctions.httpsCallable("testCreateBidTicket");
      const res = await createBidTicket({
        product_id,
        bid_amount,
        payment_intent_id: _payment_intent_id,
        shipping_info,
        authorization_history,
        authorization_amount,
        shipping_fee,
        dropp_fee,
        insurance_fee,
      });

      console.log('res', res)
      let bid_successs_message = ""
      switch (res.data.bidStatus) {
        case "self_bid":
          bid_successs_message = "自動入札額の最高金額を更新いたしました。"
          break;
        case "new_highst_bid_with_auto_bid":
          bid_successs_message = "おめでとうございます！ 入札が成功しました。"
          break;
        case "new_highst_bid":
          bid_successs_message = "おめでとうございます！ 入札が成功しました。"
          break;
        case "fail_bid":
          bid_successs_message = "他のお客様の自動入札により入札額が更新されました。"
          break;
        case "lose_bid":
          bid_successs_message = "他のお客様の自動入札により入札額が更新されました。"
          break;
        case "incorrect_bid":
          bid_successs_message = "他のお客様の入札により入札額が更新されました。"
          break;
        default:
          bid_successs_message = "入札に失敗しました。"
          break;
      }
      return { status: res.data.status, ticket: res.data.ticket, product: res.data.product, message: bid_successs_message }
    } catch (e) {
      return { status: "failed", ticket: null, product: null, bidStatus: "fail to create ticket" }
    }
  }

  const CheckDialogView = () => {
    let _inputAmount = paymentStates.inputAmount ? paymentStates.inputAmount : paymentStates.biddableAmounts[0]
    let _dropp_fee = _inputAmount * productInfo.auction_fee / 100
    let _shipping_fee = paymentStates.shippingFee
    let _insuranceFee = paymentStates.insuranceFee ? paymentStates.insuranceFee : 0
    let _totalPrice = Number(_inputAmount) + Number(_shipping_fee) + Number(_dropp_fee) + Number(_insuranceFee)
    return (
      <Dialog
        open={dialogOpen}
      >
        <DialogContent style={{ color: 'black', marginBottom: '0' }}>
          <div style={{ marginBottom: "30px" }}>
            <List>
              <ListItem className={classes.list}>
                <Typography variant='subtitle2' className={classes.list_title}>入札価格</Typography>
                <Typography variant='subtitle2' className={classes.list_text}>¥ {String(_inputAmount).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}</Typography>
              </ListItem>
              <ListItem className={classes.list}>
                <Typography variant='subtitle2' className={classes.list_title}>手数料<br></br>(入札価格の{productInfo.auction_fee}%)</Typography>
                <Typography variant='subtitle2' className={classes.list_text}>¥ {String(paymentStates.inputAmount ? paymentStates.inputAmount * productInfo.auction_fee / 100 : paymentStates.biddableAmounts[0] * productInfo.auction_fee / 100).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}</Typography>
              </ListItem>
              {paymentStates.insuranceFee > 0 &&
                <ListItem className={classes.list}>
                  <Typography variant='subtitle2' className={classes.list_title}>保険料</Typography>
                  <Typography variant='subtitle2' className={classes.list_text}>¥ {String(paymentStates.insuranceFee).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}</Typography>
                </ListItem>
              }
              <ListItem className={classes.list}>
                <Typography variant='subtitle2' className={classes.list_title}>配送料</Typography>
                <Typography variant='subtitle2' className={classes.list_text}>¥ {String(paymentStates.shippingFee).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}</Typography>
              </ListItem>
              <Divider style={{ margin: '12px', height: '2px' }} />
              {productInfo.authorization_limit < _totalPrice && <ListItem className={classes.list}>
                <Typography variant='subtitle2' className={classes.list_title}>成約手付金</Typography>
                <Typography variant='subtitle2' className={classes.list_text}>（¥ {String(productInfo.authorization_limit).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}）</Typography>
              </ListItem>}
              <ListItem className={classes.list}>
                <Typography variant='subtitle2' className={classes.list_title}>落札時支払予定金額</Typography>
                <Typography variant='subtitle2' className={classes.list_text}>¥ {String(_totalPrice).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}</Typography>
              </ListItem>
            </List>
          </div>
          <span>※入札成功後のキャンセルはできません。</span><br />
          上記の内容で入札します。よろしいですか？<br></br>
        </DialogContent>
        <DialogActions>
          <Button variant="text" autoFocus onClick={() => setDialogOpen(false)}>いいえ</Button>
          <Button variant="text" onClick={handleBid} style={{ color: '#ff0000' }}>入札する</Button>
        </DialogActions>
      </Dialog>
    );
  }

  const handleCheckTerm = () => {
    setIsCheckedTerms(!isCheckedTerms)
  }

  const handleTermsViewToggle = (e: any) => {
    setOnShowTermsType(e.target.innerHTML)
    setIsCheckedTerms(false)
    setTermOfUseOpen(true)
  }

  const handleTermsViewClose = () => {
    setTermOfUseOpen(false)
    setIsCheckedTerms(false)
  }

  const handleToCheck = () => {
    // 新規カード時にカードが入力されているか確認
    if (productInfo.is_authorization && !myTicket && paymentStates.selectedPaymentMethodStr === '-1' && !isNewCardInputed) {
      dispatch(
        Common.actions.fetchAlert({
          alerts: [{ message: "カード情報を入力してください。" }],
        })
      );
      setPaymentStates({ ...paymentStates, paymentErrorMessage: "カード情報を入力してください。" })
      return
      // 支払い方法が選択されているか
    } else if (productInfo.is_authorization && !myTicket && !paymentStates.selectedPaymentMethodStr) {
      dispatch(
        Common.actions.fetchAlert({
          alerts: [{ message: "お支払い方法を選択してください。" }],
        })
      );
      setPaymentStates({ ...paymentStates, paymentErrorMessage: "お支払い方法を選択してください。" })
      return
    }
    setDialogOpen(true)
  }

  const handleToAuctionDetail = () => {
    history.push(`/auction/${productInfo.id}`)
    setStates({ ...states, bidProcess: 0 })
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 130)
  }

  const handleSelectCard = (e: any) => {
    setPaymentStates({
      ...paymentStates,
      paymentErrorMessage: e.target.value === '-1' ? paymentStates.paymentErrorMessage : "",
      isPaymentMethodSave: (e.target.value === '-1'),
      selectedPaymentMethodStr: e.target.value,
    })
  }

  const PaymentMethodsView = () => {
    return (
      <>
        <FormControl fullWidth>
          <RadioGroup
            onChange={handleSelectCard}
            value={paymentStates.selectedPaymentMethodStr}
          >
            {paymentStates.onPaymentMethodsLoading ?
              <div style={{ height: "80px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <CircularProgress size={36} style={{ color: "grey" }} />
              </div>
              :
              <>
                {paymentMethods.map((_payment_method: PaymentMethod, index) => (
                  <FormControlLabel
                    key={index}
                    className={classes.credit_card}
                    value={index.toString()}
                    control={<Radio color="default" />}
                    label={
                      <div className={classes.label}>
                        <Typography variant="subtitle2" style={{ marginRight: "12px", width: "110px" }}>****{_payment_method.card?.last4}</Typography>
                        <Typography variant="subtitle2" style={{ marginRight: "8px", fontSize: "0.75rem", lineHeight: "24px", width: "50px" }}>有効期限</Typography>
                        <Typography variant="subtitle2" style={{ marginRight: "8px", width: "73px" }}>{_payment_method.card?.exp_month} / {_payment_method.card?.exp_year}</Typography>
                      </div>
                    }
                  />
                ))}
              </>
            }
            <FormControlLabel
              className={classes.credit_card}
              value="-1"
              control={<Radio color="default" />}
              label={
                <div className={classes.label}>
                  <Typography variant="subtitle2" style={{ marginRight: "12px" }}>新しいカードを使用する</Typography>
                </div>
              }
            />
          </RadioGroup>
        </FormControl>
      </>
    )
  }

  const SelectedShippingMethodView = () => {
    const isOversea = !(shippingMethod.countryCode === 'JP' || shippingMethod.countryCode === undefined)

    return (
      <div className={classes.shipping_method_view}>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <Typography variant="subtitle2" style={{ color: "grey", fontSize: "1rem", marginBottom: "8px" }}>配送先情報</Typography>
          <Button variant="outlined" className={classes.update_button} onClick={handleReturnShipppingMethodSelect}>変更する</Button>
        </div>
        <dl className={classes.shipping_method_wrap}>
          <dt className={classes.shipping_method_title}>{isOversea ? "Name" : "お名前"}</dt>
          <dd className={classes.shipping_method_text}>{shippingMethod.secondName} {shippingMethod.firstName}</dd>
        </dl>
        {!isOversea && <dl className={classes.shipping_method_wrap}>
          <dt className={classes.shipping_method_title}>フリガナ</dt>
          <dd className={classes.shipping_method_text}>{shippingMethod.secondNameKana} {shippingMethod.firstNameKana}</dd>
        </dl>}
        <dl className={classes.shipping_method_wrap}>
          <dt className={classes.shipping_method_title}>{isOversea ? "Phone Number" : "電話番号"}</dt>
          <dd className={classes.shipping_method_text}>{shippingMethod.phone}</dd>
        </dl>
        <dl className={classes.shipping_method_wrap}>
          <dt className={classes.shipping_method_title}>{isOversea ? "Address" : "住所"}</dt>
          <dd className={classes.shipping_method_text}>
            {shippingMethod.zipcode}<br></br>
            {shippingMethod.province} {shippingMethod.address1}<br></br>
            {shippingMethod.address2} {isOversea && shippingMethod.city} {isOversea && getCountryName(shippingMethod.countryCode)}
          </dd>
        </dl>
      </div>
    )
  }

  // 配送先住所選択に戻る
  const handleReturnShipppingMethodSelect = () => {
    history.push(`/auction/${product_id}/shipping`)
    setStates({ ...states, bidProcess: 1 })
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 150)
  }

  const [isNewCardInputed, setIsNewCardInputed] = useState(false)
  const handleCardChange = (e: any) => {
    e.complete ? setIsNewCardInputed(true) : setIsNewCardInputed(false)
  }

  const handleCheckCard = (e: any) => {
    setPaymentStates({ ...paymentStates, isPaymentMethodSave: e.target.checked })
  }

  return (
    <Container className={classes.main_container}>
      <MuiThemeProvider theme={theme}>
        <div className={classes.card}>
          <ProductInfoView productInfo={productInfo} shippingFee={paymentStates.shippingFee} paymentStates={paymentStates} setPaymentStates={setPaymentStates} />
          {productInfo.is_authorization &&
            <>
              <div className={classes.bid_input_area}>
                <Typography variant="subtitle2" style={{ marginTop: "12px", fontSize: "0.75rem", letterSpacing: "1px" }}>
                  {productInfo.authorization_limit && paymentStates.inputAmount >= productInfo.authorization_limit ?
                    <>
                      ※オークションの最高入札者のユーザー(以下「落札者」とお呼びします。)につきまして、オークション落札時の成約手付金として所定の金額「¥{formatedYen(productInfo.authorization_limit)}」をいただいております。<br />
                      当該手付金は、オークション入札時にクレジットカードのオーソリ機能を使用して決済いただきます。<br />
                      最高入札者でなくなった場合、当該手付金は自動で返金されます。<br />
                      なお、オークション落札後、弊社が指定する期日までに残金をお支払いいただけない場合、落札者からの解約の意思表示とみなし、お預かりしております成約手付金は解約手付金として弊社が受領し、落札は解除としてお取り扱いいたします。
                    </>
                    :
                    <>
                      ※入札時にクレジットカードのご利用枠から「落札時支払予定金額」分を確保させていただきます。<br></br>
                      落札時にはご登録いただいたクレジットカードより自動的に決済させていただき、入札が抜かれた時はご利用枠を確保をキャンセルをさせていただきます。<br></br>
                      詳細に関しましては、オークション規約をご確認ください。
                    </>
                  }
                </Typography>
              </div>
              {/* オーソリは初回だけ */}
              {/* 決済方法 */}
              <div className={classes.card_wrapper}>
                <Typography style={{ color: "grey", fontSize: "1rem" }}>支払い方法 ※</Typography>
                <Typography variant="subtitle1" className={classes.error_message}>{paymentStates.paymentErrorMessage}</Typography>
                <PaymentMethodsView />
                {paymentStates.selectedPaymentMethodStr === '-1' &&
                  <>
                    <CardElement id="card_element" onChange={handleCardChange} className={paymentStates.selectedPaymentMethodStr === "-1" ? classes.selected_card_element : classes.card_element} options={cardElementOptions} />
                    <FormControlLabel
                      disabled={paymentStates.selectedPaymentMethodStr !== "-1"}
                      control={<CustomCheckBox checked={paymentStates.selectedPaymentMethodStr === '-1' && paymentStates.isPaymentMethodSave} onChange={handleCheckCard} />}
                      label="このカードを次回以降も使用する"
                    />
                  </>
                }
              </div>
            </>
          }
          {/* 配送先住所 */}
          <SelectedShippingMethodView />
          {!myTicket &&
            <FormControlLabel
              control={<CustomCheckBox onChange={handleCheckTerm} checked={isCheckedTerms} />}
              label={
                <div>
                  <span onClick={handleTermsViewToggle} style={{ color: "red" }}>利用規約</span>
                  <span>と</span>
                  <span onClick={handleTermsViewToggle} style={{ color: "red" }}>オークション規約</span>
                  <span>に同意する</span>
                </div>
              }
            />
          }
          <div style={{ marginTop: "60px", display: "flex", justifyContent: "center", alignItems: "center", }}>
            <div className={classes.botton_wrapper}>
              <Button onClick={handleToAuctionDetail}>入札をキャンセル</Button>
              <Button disabled={!myTicket && !isCheckedTerms} className={classes.next_botton} onClick={handleToCheck}>確認へ</Button>
            </div>
          </div>
        </div>
        <CheckDialogView />
        <TermOfUseSlideView
          termOfUseOpen={termOfUseOpen}
          handleTermsViewClose={handleTermsViewClose}
          onShowTermsType={onShowTermsType}
        />
        <BackdropLoading open={states.onBidProcess} />
      </MuiThemeProvider>
    </Container>
  )

});
export default BidInput;


import React, { memo } from "react";
import {
  Button,
  Container,
  makeStyles,
} from "@material-ui/core";
import { createMuiTheme } from '@material-ui/core';
import AddressSelectView from "./AddressSelectView";
import ProductInfoView from "./ProductInfoView";
import { getInternationalShippingFee, shipping_fees } from "../../utils/Shipping";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { selectShippingMethod, inputCheck } from "../../redux/shippingMethod/shippingMethodSlice";
import { Product } from "../../types/product";
import { useSelector } from "../../redux/rootReducer";

const theme = createMuiTheme();

const useStyles = makeStyles(() => ({
  main_container: {
    marginTop: theme.spacing(12),
    paddingBottom: theme.spacing(8),
    maxWidth: "450px",
  },
  card: {
    padding: "20px",
    margin: "0 auto",
    marginTop: "10px",
    position: "relative"
  },
  botton_wrapper: {
    width: "100%",
    textAlign: "center",
    paddingTop: "80px",
  },
  next_botton: {
    color: "red",
    marginLeft: "10px",
  },
}));

type Props = {
  productInfo: Product,
  currentTeaserEmail: any,
  states: any,
  setStates: any,
  paymentStates: any,
  setPaymentStates: any,
}
const ShippingMethodInput = memo((props: Props) => {
  const { productInfo, currentTeaserEmail, states, setStates, paymentStates, setPaymentStates } = props
  const shippingMethod = useSelector((state) => state.shippingMethod)
  const classes = useStyles();
  const history = useHistory()
  const dispatch = useDispatch()

  const handleToPaymentMethodView = () => {
    // 配送先が選択されているか
    // 新規配送先住所
    if (states.selectedShippingMethodStr === "-1") {
      const checkRes = dispatch(inputCheck({}))
      if (!checkRes.payload.isChecked) {
        scrollTo({ top: 800, behavior: "smooth" })
        return
      }
    }

    // 配送料を算出
    let _shipping_fee = 1000
    if (states.selectedShippingMethodStr === "-1") { // 新規の配送情報を選択
      if (shippingMethod.countryCode === 'JP' || shippingMethod.countryCode === undefined) { // 国内配送
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        _shipping_fee = productInfo.shipping_fee_name ? shipping_fees[`${productInfo.shipping_fee_name}`][`${shippingMethod.provinceCode}`] : 1000
      } else { // 海外配送
        _shipping_fee = getInternationalShippingFee(shippingMethod.countryCode, productInfo.weight)
      }
    } else { // 既存の配送情報を選択
      let _selectedShippingMethod = currentTeaserEmail.shipping_methods[states.selectedShippingMethodStr]
      if (_selectedShippingMethod.country_code === 'JP' || _selectedShippingMethod.country_code === undefined) { // 国内配送
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        _shipping_fee = productInfo.shipping_fee_name ? shipping_fees[`${productInfo.shipping_fee_name}`][`${_selectedShippingMethod.province_code}`] : 1000
      } else { // 海外配送
        _shipping_fee = getInternationalShippingFee(_selectedShippingMethod.country_code, productInfo.weight)
      }
      dispatch(selectShippingMethod({ shippingMethod: currentTeaserEmail.shipping_methods[Number(states.selectedShippingMethodStr)], index: Number(states.selectedShippingMethodStr) }))
    }

    history.push(`/auction/${productInfo.id}/payment`)
    setStates({ ...states, bidProcess: 2 })
    setPaymentStates({ ...paymentStates, shippingFee: _shipping_fee })
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 150)
  }

  const handleBidCancel = () => {
    history.push(`/auction/${productInfo.id}`)
    setStates({ ...states, bidProcess: 0 })
  }

  const ButtonView = () => {
    return (
      <div className={classes.botton_wrapper}>
        <Button onClick={handleBidCancel}>入札をキャンセル</Button>
        <Button className={classes.next_botton} onClick={handleToPaymentMethodView}>次へ</Button>
      </div>
    )
  }

  return (
    <Container className={classes.main_container}>
      <div className={classes.card}>
        <ProductInfoView productInfo={productInfo} paymentStates={paymentStates} setPaymentStates={setPaymentStates} shippingFee={null} />
        <AddressSelectView
          states={states}
          setStates={setStates}
          currentTeaserEmail={currentTeaserEmail}
        />
        <ButtonView />
      </div>
    </Container>
  )

});
export default ShippingMethodInput;

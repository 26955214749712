import { Button as MUIButton, Container, Dialog, DialogActions, DialogContent, makeStyles, Typography, InputLabel, Select, MenuItem } from "@material-ui/core"
import React, { useContext, useEffect, useState } from "react"
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom"
import { AuthContext } from "../../contexts/Auth"
import Common from "../../redux/common/commonSlice";
import { formatPhoneNumber } from "../../utils/Common";
import { CloudFunctions, firebase } from "../../utils/Firebase";
import BackdropLoading from "../common/BackdropLoading";
import { TextField } from "../form/TextField";
import { validatePhoneNumberVerifyCode } from "../../utils/validations/phoneVerifyCode";
import { useForm } from "react-hook-form";
import { Button } from "../form/Button";
import { countries } from "../../utils/Shipping";
import { parsePhoneNumber } from "libphonenumber-js";

const useStyles = makeStyles((theme) => ({
  paper: {
    color: "grey",
  },
  title: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    fontWeight: 'bold',
    textAlign: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(10),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#ff0000",
    borderRadius: '0',
    color: "#fff",
    fontWeight: 'bold',
  },
  root: {
    margin: "0 auto",
  },
  confirm_button: {
    color: "#ff0000",
  },
  text: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4)
  },
  link_wrapper: {
    marginTop: "50px",
    marginBottom: "50px",
    display: "flex",
    flexDirection: "column",
  },
  link_text: {
    color: "grey",
    textAlign: "right",
  },
  link: {
    color: "#ff0000",
    textDecoration: "none",
  },

  dropdownStyle: {
    height: "250px",
  },
  select: {
    '&:after': { //focus
      borderColor: "#ff0000",
    },
    '&:not(.Mui-disabled):hover::before': { //hover
      borderColor: "#ff0000",
    },
  },
  selectRoot: {
    '&:focus': {
      backgroundColor: "#F9F9F9",
    }
  },
}));

const ChangePhoneNumber = () => {
  const classes = useStyles()
  const { currentUser } = useContext<any>(AuthContext)
  const [formatedPhoneNumber, setFormatedPhoneNumber] = useState("")
  const [processionStatus, setProcessionStatus] = useState("inputPhoneNumber")
  const [confirmationResult, setConfirmationResult] = useState<any>("");
  const [inputedPhoneNumber, setInputedPhoneNumber] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()
  const { control, getValues, handleSubmit } = useForm()

  useEffect(() => {
    let isMounted = true
    if (currentUser?.phoneNumber && isMounted) {
      setFormatedPhoneNumber(formatPhoneNumber(currentUser.phoneNumber))
    }
    return (() => {
      isMounted = false
    })
  }, [currentUser])

  useEffect(() => {
    const recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: 'normal',
        callback: () => {
        },
        //reCAPTCHA応答が期限切れになり、ユーザーが再検証する必要があるときに実行される
        "expired-callback": () => {
        },
        // reCAPTCHAでエラー（通常はネットワーク接続）が発生し、接続が復元されるまで続行できない場合に実行される
        "error-callback": () => {
        }
      }
    );
    const recaptchaWidgetId = recaptchaVerifier.render();
    // @ts-expect-error TS(2339): Property 'recaptchaVerifier' does not exist on typ... Remove this comment to see the full error message
    window.recaptchaVerifier = recaptchaVerifier;
    // @ts-expect-error TS(2339): Property 'recaptchaWidgetId' does not exist on typ... Remove this comment to see the full error message
    window.recaptchaWidgetId = recaptchaWidgetId;
  }, [])

  const handleSendVerifyCode = () => {
    const phoneNumber = getValues('phoneNumber')
    // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
    const formatedPhoneNumber = parsePhoneNumber(phoneNumber, selectCountryCode)
    if (!formatedPhoneNumber.isValid()) {
      const errorMessage = selectCountryCode === 'JP' ? "入力された電話番号が正しくありません。" : "Invalid Phone Number."
      dispatch(
        Common.actions.fetchAlert({
          alerts: [{ message: errorMessage }],
        })
      );
      return;
    }
    setInputedPhoneNumber(formatedPhoneNumber.number)
    setConfirmDialogOpen(true)
  };

  // 国選択
  const [selectCountryCode, setSelectCountryCode] = useState('JP')
  const countrySelectChange = (e: any) => {
    const selectCountryCode = e.target.value
    setSelectCountryCode(selectCountryCode)
  }

  const sendPhoneNumber = async () => {
    try {
      setIsLoading(true)
      // 既存の電話番号連携を解除する
      const func = CloudFunctions.httpsCallable('unlinkPhoneNumber')
      await func({ uid: currentUser.uid })

      // @ts-expect-error TS(2339): Property 'recaptchaVerifier' does not exist on typ... Remove this comment to see the full error message
      const _confirmationResult = await firebase.auth().signInWithPhoneNumber(inputedPhoneNumber, window.recaptchaVerifier)
      setConfirmationResult(_confirmationResult)
      setIsLoading(false)
      setProcessionStatus("inputSmsNumber")
      dispatch(
        Common.actions.fetchAlert({
          alerts: [{ message: "確認コードを送信しました。" }],
        })
      );
    } catch (e) {
      setIsLoading(false)
      // @ts-expect-error TS(2339): Property 'grecaptcha' does not exist on type 'Wind... Remove this comment to see the full error message
      window.grecaptcha.reset(window.recaptchaWidgetId);
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      switch (e.code) {
        case "auth/too-many-requests":
          dispatch(
            Common.actions.fetchAlert({
              alerts: [{ message: "時間をあけて再度送信をお願いいたします。" }],
            })
          );
          break;
        default:
          dispatch(
            Common.actions.fetchAlert({
              alerts: [{ message: "電話番号認証に失敗しました。" }],
            })
          );
      }
      return
    }
  }

  // SMSに確認コードを送信する
  const sendVerificationCode = async () => {
    const verifyCode = getValues("verifyCode")
    try {
      setIsLoading(true)
      const credential = await firebase.auth.PhoneAuthProvider.credential(confirmationResult.verificationId, verifyCode)
      await currentUser.sendPhoneNumber(credential)
      setIsLoading(false)
      dispatch(
        Common.actions.fetchAlert({
          alerts: [{ message: "電話番号認証が完了しました。" }],
        })
      );
      history.push('/mypage')
    } catch (e) {
      setIsLoading(false)
      console.log('error', e)
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      switch (e.code) {
        case "auth/credential-already-in-use":
          dispatch(
            Common.actions.fetchAlert({
              alerts: [{ message: "こちらの電話番号はすでに他のアカウントと連携しております。" }],
            })
          );
          break;
        case "auth/provider-already-linked":
          dispatch(
            Common.actions.fetchAlert({
              alerts: [{ message: "こちらの電話番号はすでに承認済みです。" }],
            })
          );
          break;
        default:
          dispatch(
            Common.actions.fetchAlert({
              alerts: [{ message: "電話番号認証に失敗しました。" }],
            })
          );
          break;
      }
    }
  }

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const UnlinkConfirmDialogView = () => {
    return (
      <Dialog open={confirmDialogOpen}>
        <DialogContent>
          <Typography variant="subtitle2">現在の電話番号認証を解除し、新しい電話番号に認証番号をお送りいたします。<br></br>よろしいでしょうか？</Typography>
        </DialogContent>
        <DialogActions>
          <MUIButton onClick={() => setConfirmDialogOpen(false)}>いいえ</MUIButton>
          <MUIButton onClick={sendPhoneNumber} style={{ color: "#ff0000" }}>はい</MUIButton>
        </DialogActions>
      </Dialog>
    )
  }

  return (
    <Container component="main" maxWidth="xs" className={classes.root}>
      {processionStatus === 'inputPhoneNumber' &&
        <>
          <div className={classes.paper}>
            <Typography variant="subtitle1" className={classes.title}>電話番号変更</Typography>
            {currentUser?.phoneNumber &&
              <Typography component='p'>
                現在登録されている電話番号：
                <br></br><b>{formatedPhoneNumber}</b>
              </Typography>
            }
            <Typography style={{ marginTop: "30px", textAlign: "left" }} variant="subtitle1">
              新しい電話番号を入力してください。<br></br>
              ※変更を開始すると現在の電話番号認証が解除されます。<br />
              ※SMSが受け取れる電話番号を入力してください。
            </Typography>

            <form className={classes.form} onSubmit={handleSubmit(handleSendVerifyCode)}>
              <InputLabel style={{ marginLeft: "10px" }} id="country-code">国（Country）</InputLabel>
              <Select
                labelId="country-code"
                onChange={countrySelectChange}
                value={selectCountryCode}
                className={classes.select}
                classes={{ root: classes.selectRoot }}
                MenuProps={{
                  classes: {
                    paper: classes.dropdownStyle,
                  },
                }}
                style={{ width: "100%", height: "40px", marginBottom: "10px", padding: "10px" }}
              >
                {countries.map(country => (
                  <MenuItem key={country.code} value={country.code}>{country.label} {country.flag} +{country.phone}</MenuItem>
                ))}
              </Select>
              <TextField
                control={control}
                defaultValue=''
                id="phoneNumber"
                name="phoneNumber"
                label="電話番号 （ハイフンなし）"
                valudation={null}
                placeHolder="08012345678"
                helperText="※ハイフンなしで入力してください"
              />
              <div id="recaptcha-container"></div>
              <Button
                text="変更する"
                onClick={handleSubmit}
              />
            </form>

          </div>
          <UnlinkConfirmDialogView />
          <div className={classes.link_wrapper}>
            <Typography variant="subtitle2" className={classes.link_text}>メールアドレス変更は<Link to="/mypage/change-email" className={classes.link}>こちら</Link></Typography><br></br>
            <Typography variant="subtitle2" className={classes.link_text}>パスワード変更は<Link to="/mypage/change-password" className={classes.link}>こちら</Link></Typography><br></br>
          </div>
        </>
      }
      {processionStatus === 'inputSmsNumber' &&
        <>
          <div className={classes.paper}>
            <Typography variant="subtitle1" className={classes.title}>確認コード入力</Typography>
            <form className={classes.form} onSubmit={handleSubmit(sendVerificationCode)}>
              <TextField
                control={control}
                defaultValue=''
                id="verifyCode"
                name="verifyCode"
                label="確認コード"
                valudation={validatePhoneNumberVerifyCode}
              />
              <Button
                text="変更する"
                onClick={handleSubmit}
              />
            </form>
          </div>
        </>
      }
      <BackdropLoading open={isLoading} />
    </Container>
  )
}
export default ChangePhoneNumber;
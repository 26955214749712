import { useEffect, useState } from "react";
import { Product } from "../../types/product";
import { db } from "../../utils/Firebase";
import ArchivedProductsView from "../ArchivedProductsView";
import InfiniteScroll from "react-infinite-scroll-component";
import BackdropLoading from "../common/BackdropLoading";

const HomeArchivedView = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [lastDoc, setLastDoc] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const [products, setProducts] = useState<Product[]>([]);

    // アーカイブ商品を50件ずつ取得するクエリ
    const getProducts = async () => {
        setIsLoading(true)
        const _now = new Date();
        let query = db.collection("products")
            .where("deadline_date", "<", _now)
            .where("close", "==", true)
            .where("is_production", "==", true)
            .orderBy("deadline_date", "desc")
            .limit(50)

        if (lastDoc) {
            query = query.startAfter(lastDoc);
        }
        const snapshot = await query.get();

        if (snapshot.empty) {
            setHasMore(false);
            setIsLoading(false)
            return;
        }

        // @ts-expect-error TS(2345): Argument of type 'QueryDocumentSnapshot<DocumentDa... Remove this comment to see the full error message
        setLastDoc(snapshot.docs[snapshot.docs.length - 1]);
        const newProducts = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        // @ts-expect-error TS(2345): Argument of type '(prevProducts: Product[]) => (Pr... Remove this comment to see the full error message
        setProducts(prevProducts => [...prevProducts, ...newProducts]);
        setIsLoading(false)
    };

    //初期商品を取得
    useEffect(() => {
        getProducts();
    }, [])

    return (
        <InfiniteScroll
            dataLength={products.length}
            next={getProducts}
            hasMore={hasMore}
            loader={<BackdropLoading open={isLoading} />}
        >
            <div>
                {products.length > 0 && <ArchivedProductsView products={products} />}
            </div>
        </InfiniteScroll>
    )
}

export default HomeArchivedView
import React, { useEffect, useState, useContext, memo } from 'react';
import { Button, Container, makeStyles, Toolbar, Typography, Link, Grid, Box, FormControl, Select, MenuItem, MuiThemeProvider, Dialog, DialogContent, DialogActions } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { AnimatePresence, motion, useAnimation } from 'framer-motion';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Timer from '../components/auction/Timer';
import { db, auth, TicketStatusValue } from "../utils/Firebase";
import { getBiddableArrey, getBiddableArreyWithCurrentAmount } from "../utils/Auction";
import { AuthContext } from "../contexts/Auth";
import { pageEnterVariants } from '../Animations/variants';
import { TitleView } from '../components/TitleView';
import { useDispatch } from "react-redux";
import { blue } from '@material-ui/core/colors';

import BidInput from '../components/auction/BidInput';
import ShippingMethodInput from '../components/auction/ShippingMethodInput';
import { getInsuranceFee } from "../utils/Insurance";
import Common from '../redux/common/commonSlice';
import ProductImagesView from '../components/common/ProductImagesView';
import PaymentView from '../components/auction/PaymentView';
import { selectShippingMethod } from '../redux/shippingMethod/shippingMethodSlice';
import { Product } from '../types/product';
import { PaymentState, ProcessionStatus } from '../types/common';
import { Ticket } from '../types/ticket';

const useStyles = makeStyles((theme) => ({
  main_container: {
    marginTop: theme.spacing(9),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(8),
  },
  card: {
    marginTop: theme.spacing(2),
    height: "100%",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    [theme.breakpoints.up('sm')]: {}
  },
  iamge_view: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "24px",
  },
  item_info_view: {
    [theme.breakpoints.up('md')]: {
      marginTop: "50px",
    }
  },
  under_line: {
    height: "1px",
    borderTop: ".8px solid grey",
  },
  alert_text: {
    textAlign: "center",
    border: "1px solid green",
    padding: "4px",
    width: "80%",
    margin: "10px auto",
    color: "green",
  },
  icon: {
    color: "grey"
  },
  vendor_url: {
    color: '#585858',
  },
  description: {
    width: "100%",
    maxWidth: "1020px",
    position: 'relative',
    overflow: 'hidden',
    fontSize: '0.9rem',
    letterSpacing: '0.1rem',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(3),
  },
  desc_html: {
    lineHeight: "1.65",
    '& p': {
      margin: "0",
      padding: "0",
      '&.pi': {
        padding: "10px",
      },
    },
    '& ul': {
      paddingLeft: "1rem",
    },
    '& ol': {
      marginTop: "4px",
    },
  },
  dropdownStyle: {
    maxHeight: "200px",
  },
  share_icons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: 'center',
    marginTop: "32px",
  },
  share_icon: {
    margin: "8px",
  },
  images_wrapper: {
    maxWidth: "350px",
    width: "80%",
    margin: "0 auto",
  },
  entry_button_wrap: {
    minHeight: "30px",
    textAlign: 'center',
    width: "100%",
    padding: "0",
    marginTop: "18px",
  },
  entry_button: {
    backgroundColor: '#ff0000',
    width: "100%",
    lineHeight: "30px",
    color: 'white',
    fontSize: '0.9rem',
    fontWeight: 'bold',
    borderRadius: "0",
    "&:hover": {
      backgroundColor: '#ff0000',
      opacity: "0.7"
    }
  },
  success_button: {
    backgroundColor: '#ff0000',
    width: "100%",
    lineHeight: "40px",
    color: 'white',
    fontSize: '0.9rem',
    fontWeight: 'bold',
    borderRadius: "0",
    letterSpacing: "8px",
  },
  disabled_button: {
    width: "100%",
    lineHeight: "42px",
    height: "42px",
    backgroundColor: 'darkgray',
    color: 'white',
    fontSize: '0.9rem',
    fontWeight: 'bold',
  },
  root: {
    borderRadius: "0",
    border: "1px solid grey",
    textAlign: "center",
  },
  reminder_button: {
    backgroundColor: '#ff0000',
    lineHeight: "20px",
    width: "100%",
    color: 'white',
    fontSize: '0.8rem',
    padding: "6px 8px",
    marginTop: "8px",
    fontWeight: 'bold',
    borderRadius: "0",
    "&:hover": {
      backgroundColor: '#ff0000',
      opacity: "0.7"
    }
  },
  reminder_remove_button: {
    backgroundColor: 'darkgray',
    color: 'white',
    lineHeight: "20px",
    width: "100%",
    fontSize: '0.8rem',
    padding: "6px 8px",
    marginTop: "8px",
    fontWeight: 'bold',
    borderRadius: "0",
    "&:hover": {
      backgroundColor: 'darkgray',
      opacity: "0.7"
    }
  }
}));

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#000',
      main: '#000',
      dark: '#000',
      contrastText: '#fff',
    },
  },
  overrides: {
    MuiSelect: {
      select: {
        "&:focus": {
          backgroundColor: "#fff",
        },
      }
    },
    MuiInputLabel: {
      root: {
        color: "black",
      }
    },
  }
});

const DESCRIPTION_DELIMITER_STR = '###fold_below'

type Params = {
  product_id: string;
}
const AuctionDetail = (props: any) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { product_id } = useParams<Params>();
  const [myTicket, setMyTicket] = useState<Ticket | null>(null);
  const [shopLoading, setShopLoading] = useState(true);
  const [productInfo, setProductInfo] = useState<Product>();
  const [states, setStates] = useState({
    bidProcess: 0,
    firstname: "",
    firstnamekana: "",
    secondname: "",
    secondnamekana: "",
    zipcode: "",
    province: "",
    province_code: "",
    address1: "",
    address2: "",
    phone: "",
    firstnameErrorMessage: "",
    firstnamekanaErrorMessage: "",
    secondnameErrorMessage: "",
    secondnamekanaErrorMessage: "",
    zipcodeErrorMessage: "",
    provinceErrorMessage: "",
    address1ErrorMessage: "",
    phoneErrorMessage: "",
    selectedShippingMethodStr: "",
    isShippingMethodSave: false,
    shippingErrorMessage: "",
    signInDialogOpen: false,
    onBidProcess: false,
    remainderConfirmOpen: false,
  })

  const [paymentStates, setPaymentStates] = useState<PaymentState>({
    inputAmount: 0,
    droppFee: 0,
    shippingFee: 0,
    insuranceFee: 0,
    biddableAmounts: [],
    paymentErrorMessage: "",
    isPaymentMethodSave: false,
    selectedPaymentMethodStr: "",
  })

  const { currentUser } = useContext<any>(AuthContext);
  const controls = useAnimation();
  const history = useHistory()

  // /shiipingや/paymentに直接入れないようにする。
  useEffect(() => {
    if (window.location.pathname !== `/auction/${product_id}`) {
      props.history.push(`/auction/${product_id}`)
    }
  }, [])

  // 基本データの取得
  useEffect(() => {
    let isMounted = true;
    const f = () => {
      try {
        db.collection("products").doc(product_id)
          .onSnapshot(async (doc) => {
            let product = doc.data() as Product
            if (!product) return
            product['id'] = doc.id
            if (product.product_type !== 'オークション') {
              props.history.push('/')
            }

            const checkedDescription = checkDescription(product.description)
            const productInfo: Product = {
              id: product.id,
              title: product.title,
              subtitle: checkedDescription.subtitle,
              price: Number(product.variants[0].price),
              weight: Number(product.variants[0].weight),
              images: product.images.map((image: any) => {
                return image.src;
              }),
              options: product.options,
              variants: product.variants,
              payment_variants: product.payment_variants,
              payment_product_id: product.payment_product_id,
              showcase_date: product.showcase_date,
              deadline_date: product.deadline_date,
              release_date: product.release_date,
              draft_status: product.draft_status,
              description: checkedDescription.product_description,
              updated_at: product.updated_at,
              created_at: product.created_at,
              image: product.image,
              product_type: product.product_type,
              vendor: product.vendor,
              vendor_url: checkedDescription.vendor_url,
              is_authorization: product.is_authorization,
              current_bid_amount: product.bid_history?.length > 0 ? product.current_bid_amount : Number(product.variants[0].price),
              bid_history: product.bid_history?.length ? product.bid_history : [],
              highest_bid_ticket: product.highest_bid_ticket,
              expected_winning_bid_amount: product.expected_winning_bid_amount,
              auto_extension_time_flg: !!product.auto_extension_time_flg,
              shipping_fee_name: product.shipping_fee_name,
              insurance_fee_name: product.insurance_fee_name,
              bid_interval: product.bid_interval,
              authorization_limit: product.authorization_limit,
              level: product.level,
              hasWinImage: product.hasWinImage,
              winImageUrl: product.winImageUrl,
              is_banner_view: product.is_banner_view,
              notion_page_id: product.notion_page_id,
              auction_fee: (product.auction_fee || product.auction_fee === 0) ? product.auction_fee : 15,
            }

            // 入札幅を設定
            if (productInfo.bid_interval) {
              let _biddable_amount_arr = []
              for (let i = 1; i < 50; i++) {
                _biddable_amount_arr.push(productInfo.current_bid_amount + productInfo.bid_interval * i)
              }
              let amount = paymentStates.inputAmount ? paymentStates.inputAmount : _biddable_amount_arr[0]
              let insuranceFee = productInfo.insurance_fee_name ? getInsuranceFee(amount, productInfo.insurance_fee_name) : 0
              setPaymentStates({ ...paymentStates, biddableAmounts: _biddable_amount_arr, inputAmount: _biddable_amount_arr[0], insuranceFee: insuranceFee })
            } else {
              let _biddable_amount_arr = product.bid_history?.length > 0 ? getBiddableArrey(productInfo.current_bid_amount) : getBiddableArreyWithCurrentAmount(productInfo.current_bid_amount)
              let amount = paymentStates.inputAmount ? paymentStates.inputAmount : _biddable_amount_arr[0]
              let insuranceFee = productInfo.insurance_fee_name ? getInsuranceFee(amount, productInfo.insurance_fee_name) : 0
              setPaymentStates({ ...paymentStates, biddableAmounts: _biddable_amount_arr, inputAmount: _biddable_amount_arr[0], insuranceFee: insuranceFee })
            }

            if (isMounted) setProductInfo(productInfo);
            if (isMounted) setShopLoading(false);

            controls.start("visible");
          })

      } catch (e) {
        console.error(e);
        if (isMounted) setShopLoading(false);
      }
    }
    if (product_id) { f(); }

    return () => {
      isMounted = false;
    }

  }, [product_id]);

  // 所持チケット
  useEffect(() => {
    let isMounted = true;

    const f = async (user: any) => {
      if (!productInfo) return
      const mytickets = await db
        .collection("tickets")
        .where("product_id", "==", Number(productInfo.id))
        .where("uid", "==", user.uid).get();
      if (!mytickets.empty) {
        let _doc = null;
        mytickets.forEach((myticket) => {
          _doc = myticket;
        });
        // @ts-expect-error TS(2531): Object is possibly 'null'.
        let _tmp = await _doc.data();
        if (!_doc) return
        // @ts-expect-error
        _tmp["id"] = _doc.id;

        // 最高入札者の場合は最高額から入札可能にする
        if (productInfo.highest_bid_ticket && _tmp.id === productInfo.highest_bid_ticket.id) {
          // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          let insuranceFee = productInfo.insurance_fee_name ? getInsuranceFee(_biddable_amount_arr[0], productInfo.insurance_fee_name) : 0
          let _biddable_amount_arr = []
          if (productInfo.bid_interval) {
            for (let i = 1; i < 50; i++) {
              _biddable_amount_arr.push(Number(_tmp.bid_amount) + i * productInfo.bid_interval)
            }
          } else {
            _biddable_amount_arr = getBiddableArrey(Number(_tmp.bid_amount))
          }
          if (isMounted) {
            setPaymentStates({ ...paymentStates, biddableAmounts: _biddable_amount_arr, inputAmount: _biddable_amount_arr[0], insuranceFee, shippingFee: _tmp.shipping_fee })
          }
        }
        if (isMounted) {
          setMyTicket({ ..._tmp });
        }
      }
    };

    if (productInfo) {
      auth.onAuthStateChanged((user) => {
        if (user) f(user)
      })
    }

    return () => {
      isMounted = false;
    }

  }, [productInfo]);

  const [myReminder, setMyReminder] = useState(null)
  // @ts-expect-error TS(2345): Argument of type '() => Promise<() => void>' is no... Remove this comment to see the full error message
  useEffect(async () => {
    let isMounted = true
    if (currentUser?.email && productInfo?.id) {
      await db.collection('reminders').where('email', '==', currentUser.email).where('product_id', '==', productInfo.id).get().then(snapshot => {
        snapshot.forEach(doc => {
          let _myRemider = doc.data()
          _myRemider['id'] = doc.id
          if (isMounted) {
            // @ts-expect-error TS(2345): Argument of type 'DocumentData' is not assignable ... Remove this comment to see the full error message
            setMyReminder(_myRemider)
          }
        })
      })
    }
    return (() => {
      isMounted = false
    })
  }, [currentUser, productInfo?.id])

  // オークション状況ステータス
  const [processionStatus, setProcessionStatus] = useState<ProcessionStatus>(null);
  useEffect(() => {
    let isMounted = true;
    if (!productInfo) { return }
    let _now = new Date().getTime();

    // notRelase：リリース前
    if (_now < productInfo.release_date.seconds * 1000) {
      if (isMounted) {
        setProcessionStatus('notRelase');
      }
      return;
    }

    // outOfTerm：オークション終了
    if (new Date().getTime() > productInfo.deadline_date.seconds * 1000) {
      if (isMounted) {
        setProcessionStatus('outOfTerm');
      }
      return;
    }

    // 開始時間 ~ 終了時間内はオークション中
    if (isMounted) {
      setProcessionStatus('processionable');
      return;
    }

    return () => { isMounted = false }
  }, [productInfo, myTicket]);

  // チケットステータス
  const [ticketStatus, setTicketStatus] = useState('notBidder')
  useEffect(() => {
    if (!myTicket) return

    // 期間外
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    if (new Date().getTime() > productInfo.release_date.seconds * 1000) {

      if (myTicket.status === TicketStatusValue['WAITING_PAYMENT']) {
        setTicketStatus('waitingPayment') // オーソリなしの事後決済
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      } else if (myTicket.id === productInfo.highest_bid_ticket?.id) {
        setTicketStatus('highestBidder')
      } else {
        setTicketStatus('onLoseBidder')
      }

    } else {

      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      if (myTicket.id === productInfo.highest_bid_ticket?.id) {
        setTicketStatus('onHighestBidder')
      } else {
        setTicketStatus('onLoseBidder')
      }
    }
  }, [productInfo, myTicket])

  const [currentTeaserEmail, setCurrentTeaserEmail] = useState("")
  // @ts-expect-error TS(2345): Argument of type '() => Promise<() => void>' is no... Remove this comment to see the full error message
  useEffect(async () => {
    let isMounted = true
    if (currentUser && !currentTeaserEmail) {
      await db.collection('teaser_emails').where('email', "==", currentUser.email)
        .get().then(snapshot => {
          snapshot.forEach(doc => {
            if (doc.data() && isMounted) {
              let _current_teaser_email = doc.data()
              _current_teaser_email["id"] = doc.id
              // @ts-expect-error TS(2345): Argument of type 'DocumentData' is not assignable ... Remove this comment to see the full error message
              setCurrentTeaserEmail(_current_teaser_email)
            }
          })
        })
    }
    return () => { isMounted = false }
  }, [currentUser])

  // メイン画像
  const ImageSlideView = (props: any) => {
    const images = props.images ? props.images : [];
    if (images.length >= 1) {
      return (
        <>
          <Slider />
          <div className={classes.images_wrapper}>
            {/* 最高入札中 */}
            {(processionStatus === 'processionable' && productInfo?.highest_bid_ticket && productInfo.highest_bid_ticket?.id === myTicket?.id) &&
              <ProductImagesView images={images} ticketValue="onBidding" autoBidPrice={myTicket.bid_amount} />
            }
            {/* 落札 */}
            {(processionStatus === 'outOfTerm' && (myTicket && productInfo?.highest_bid_ticket?.id === myTicket?.id)) &&
              <ProductImagesView images={images} ticketValue="winAuction" />
            }
            {/* 入札可能 + 入札なし */}
            {(processionStatus === 'processionable' && (!productInfo?.highest_bid_ticket || productInfo.highest_bid_ticket?.id !== myTicket?.id)) &&
              <ProductImagesView images={images} ticketValue="" />
            }
            {/* 入札なし + 時間切れ */}
            {(processionStatus === 'outOfTerm' && (!myTicket || productInfo?.highest_bid_ticket?.id !== myTicket?.id)) &&
              <ProductImagesView images={images} ticketValue="" />
            }
            {/* 公開前 */}
            {processionStatus === 'notRelase' &&
              <ProductImagesView images={images} ticketValue="" />
            }
          </div>
        </>
      )
    } else {
      return (<></>)
    }
  };


  // 説明テキスト部分
  const DescriptionView = (props: any) => {
    return (
      <Container className={classes.description}>
        <span className={classes.desc_html} dangerouslySetInnerHTML={{ __html: props.htmlString.replace(DESCRIPTION_DELIMITER_STR, "") }}></span>
      </Container>
    )
  }

  const setMeta = () => {
    const headData = document.head.children;
    const title = productInfo ? `${productInfo.title}のオークションページ` : '商品のオークションページ';
    const description = productInfo ? `${productInfo.title}への入札はこちら` : 'オークションへの入札はこちら';
    const keywords = productInfo ? `${productInfo.title} オークション Dropp` : 'オークション Dropp';

    for (let i = 0; i < headData.length; i++) {
      const nameVal = headData[i].getAttribute('name');
      if (nameVal !== null) {
        if (nameVal.indexOf('keywords') != -1) {
          headData[i].setAttribute('content', keywords);
        }
        if (nameVal.indexOf('description') != -1) {
          headData[i].setAttribute('content', description);
        }
        // OGP(twitter)の設定
        if (nameVal.indexOf('twitter:title') != -1) {
          headData[i].setAttribute('content', title);
        }
        if (nameVal.indexOf('twitter:description') != -1) {
          headData[i].setAttribute('content', description);
        }
      }
    }
  }

  const TwitterShare = () => {
    const twitterBaseUrl = "https://twitter.com/intent/tweet?url=https://dropp.jp&text=抽選も楽しめる次世代型オークションハウス&hashtags=Dropp";
    window.open(twitterBaseUrl, "_blank")
  }

  const InstagramShare = () => {
    const instagramBaseUrl = "https://www.instagram.com/dropp.jp/?hl=ja";
    window.open(instagramBaseUrl, "_blank")
  }

  const ShareIconView = () => {
    return (
      <div className={classes.share_icons}>
        <Link onClick={TwitterShare}><TwitterIcon style={{ color: blue[500] }} className={classes.share_icon} /></Link>
        <Link onClick={InstagramShare}><InstagramIcon color="secondary" className={classes.share_icon} /></Link>
      </div>
    )
  }

  const checkDescription = (description: any) => {
    let res = [description];
    let res2;
    let subtitle;
    let vendor_url;
    if (res[0].split('###vendor_url').length >= 2) {
      res2 = res[0].split('###vendor_url');
      vendor_url = res2[1].replace(/(<([^>]+)>)/gi, '').trim();
      res[0] = res2[0]
    }
    if (res[0].split('###subtitle').length >= 2) {
      res2 = res[0].split('###subtitle');
      subtitle = res2[1].replace(/(<([^>]+)>)/gi, '');
      res[0] = res2[0]
    }
    const desc = res[0];
    return {
      product_description: desc,
      subtitle: subtitle,
      vendor_url: vendor_url,
    }
  };

  setMeta();

  const handleAmountChange = (e: any) => {
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    let insuranceFee = productInfo.insurance_fee_name ? getInsuranceFee(e.target.value, productInfo.insurance_fee_name) : 0
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    setPaymentStates({ ...paymentStates, inputAmount: e.target.value, droppFee: e.target.value * productInfo.auction_fee / 100, insuranceFee: insuranceFee })
  }

  const handleToBidInput = () => {
    if (!currentUser?.phoneNumber) {
      setStates({ ...states, signInDialogOpen: true })
      return
    }
    let _next_step = (myTicket && myTicket.shipping_info) ? 2 : 1
    if (_next_step === 1) {
      props.history.push(`/auction/${product_id}/shipping`)
    } else if (_next_step === 2) {
      console.log('myTicket.shipping_info', myTicket)
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      dispatch(selectShippingMethod({ shippingMethod: myTicket.shipping_info }))
      props.history.push(`/auction/${product_id}/payment`)
    }
    setStates({ ...states, bidProcess: _next_step })
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 130)
  }

  const handleToPayment = () => {
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    dispatch(selectShippingMethod({ shippingMethod: myTicket.shipping_info }))
    props.history.push(`/auction/${product_id}/payment`)
    setStates({ ...states, bidProcess: 3 })
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 130)
  }

  const BidBottonArea = memo(() => {
    return <>
      {processionStatus === "processionable" &&
        <>
          <Typography variant="subtitle2" style={{ color: "grey", fontSize: ".9rem" }}>入札価格</Typography>
          <FormControl size='small' required style={{ width: "100%", marginTop: "8px" }}>
            <Select
              value={paymentStates.inputAmount}
              onChange={handleAmountChange}
              variant='outlined'
              MenuProps={{
                classes: {
                  paper: classes.dropdownStyle,
                },
              }}
              classes={{
                root: classes.root,
              }}
              inputProps={{ classes: { icon: classes.icon } }}
            >
              {paymentStates.biddableAmounts.map((amount, index) => (
                <MenuItem value={amount} key={index}>¥{String(amount).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </>
      }
      <Toolbar className={classes.entry_button_wrap}>
        {processionStatus === "processionable" && <Button className={classes.entry_button} onClick={handleToBidInput}>入札を開始する</Button>}
        {processionStatus === "outOfTerm" && ticketStatus === 'waitingPayment' && <Button className={classes.entry_button} onClick={handleToPayment}>決済を開始する</Button>}
        {processionStatus === "outOfTerm" && ticketStatus === 'highestBidder' && <Typography className={classes.success_button}>落札</Typography>}
        {processionStatus === "outOfTerm" && ticketStatus !== 'waitingPayment' && ticketStatus !== 'highestBidder' && <Typography className={classes.disabled_button}>オークション終了</Typography>}
        {processionStatus === "notRelase" && <Typography className={classes.disabled_button} >入札開始までお待ちください</Typography>}
      </Toolbar>
    </>;
  })

  const SigninDialogView = () => {
    return (
      <Dialog
        open={states.signInDialogOpen}
      >
        <DialogContent style={{ color: 'black', marginBottom: '0' }}>
          入札を続けるにはログインをお願いいたします。
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setStates({ ...states, signInDialogOpen: false })}>キャンセル</Button>
          <Button onClick={() => history.push('/signup/email')} style={{ color: '#ff0000' }}>新規登録</Button>
          <Button onClick={() => history.push('/signin')} style={{ color: '#ff0000' }}>ログイン</Button>
        </DialogActions>
      </Dialog>
    )
  }

  const handleReminderSetting = async () => {
    setStates({ ...states, remainderConfirmOpen: false })
    let _now = new Date()
    let reminder = {
      email: currentUser.email,
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      product_id: productInfo.id,
      uid: currentUser.uid,
      created_at: _now
    }
    let message = ""
    await db.collection('reminders').add(reminder).then(res => {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      reminder['id'] = res.id
      // @ts-expect-error TS(2345): Argument of type '{ email: any; product_id: string... Remove this comment to see the full error message
      setMyReminder(reminder)
      message = "リマインダー通知の設定が完了しました。"
    })
      .catch(() => {
        message = "リマインダー通知の設定に失敗しました。申し訳ありませんが時間を空けて再度お試しください。"
      })
    dispatch(Common.actions.fetchAlert({ alerts: [{ message }] }));
  }

  const handleReminderRemove = async () => {
    setStates({ ...states, remainderConfirmOpen: false })
    let message = ""
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    await db.collection('reminders').doc(myReminder.id).delete().then(() => {
      message = "リマインダーを解除しました。"
      setMyReminder(null)
    }).catch(() => {
      message = "申し訳ございません。リマインダー解除に失敗しました。恐れ入りますが時間を空けて再度お試しください。"
    })
    dispatch(
      Common.actions.fetchAlert({ alerts: [{ message }] })
    );
  }

  const RemainderDialogView = () => {
    return (
      <Dialog
        open={states.remainderConfirmOpen}
      >
        <DialogContent style={{ color: 'black', marginBottom: '0' }}>
          {myReminder ? <>リマインダーを解除します。<br></br>よろしいでしょうか？</>
            : <>入札締切り１時間前にメールにて通知させていただきます。<br></br>よろしいでしょうか？</>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setStates({ ...states, remainderConfirmOpen: false })}>いいえ</Button>
          <Button onClick={myReminder ? handleReminderRemove : handleReminderSetting} style={{ color: '#ff0000' }}>はい</Button>
        </DialogActions>
      </Dialog>
    )
  }

  return (
    <React.Fragment key={product_id}>
      <MuiThemeProvider theme={theme}>
        {!shopLoading &&
          <>
            <motion.div
              initial={{ opacity: 0, scale: 1.05 }}
              variants={pageEnterVariants}
              animate={controls}
              exit={{ opacity: 0, transition: { duration: 0.8 }, scale: 1.05 }}
            >
              <AnimatePresence exitBeforeEnter initial={true}>
                <motion.div
                  key={states.bidProcess}
                  animate={{ opacity: 1, y: 0 }}
                  initial={{ opacity: 0, y: 0 }}
                  exit={{ opacity: 0, y: 0 }}
                  transition={{ duration: 0.15 }}
                >
                  {states.bidProcess == 0 &&
                    <Container className={classes.main_container}>
                      <div className={classes.card}>
                        <Grid container>
                          <Grid item xs={12} sm={12} md={7} className={classes.iamge_view}>
                            <ImageSlideView images={productInfo?.images} />
                          </Grid>
                          <Grid item xs={12} sm={12} md={5} className={classes.item_info_view}>
                            <Box display="flex" flexDirection="column" justifyContent="flex-start" pb={2.5}>
                              <TitleView style={{ letterSpacing: "1px", fontSize: "1.1rem", textAlign: "left", margin: "0 0 8px 0", fontWeight: "bold" }} title={productInfo?.title} subtitle={productInfo?.subtitle}></TitleView>
                              <Typography variant='subtitle2' style={{ color: "grey" }}>予想落札金額 : {productInfo?.expected_winning_bid_amount}</Typography>
                              {/* @ts-expect-error */}
                              <Typography variant='subtitle2' style={{ color: "grey" }}>入札数 : {productInfo?.bid_history?.length > 0 ? productInfo?.bid_history?.length : 0}</Typography>
                            </Box>
                            <Box className={classes.under_line}></Box>
                            <Box py={2.5}>
                              {processionStatus === 'processionable' &&
                                <>
                                  <Typography variant='subtitle2' style={{ margin: "0 0 4px 2px", color: "#ff0000" }}>入札締め切りまで</Typography>
                                  <Timer
                                    variant="subtitle1"
                                    styleColor={{ color: "#ff0000" }}
                                    endDateTime={new Date().setTime(
                                      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
                                      productInfo.release_date.seconds * 1000
                                    )}
                                  />
                                  <Typography variant='subtitle2' style={{ margin: "8px 0 4px 2px", color: "#ff0000", fontSize: "0.65rem" }}>終了5分以内に入札があった場合は5分延長されます。</Typography>
                                  {myReminder ? <Button className={classes.reminder_remove_button} onClick={() => setStates({ ...states, remainderConfirmOpen: true })}>リマインダーを解除する</Button>
                                    : <Button className={classes.reminder_button} onClick={() => setStates({ ...states, remainderConfirmOpen: true })}>締切１時間前にメール通知する</Button>
                                  }
                                </>
                              }
                              {processionStatus === 'outOfTerm' &&
                                <>
                                  <Typography variant='subtitle2' style={{ margin: "0 0 4px 2px", color: "grey" }}>オークション終了</Typography>
                                  <Timer
                                    variant="subtitle1"
                                    styleColor={{ color: "grey" }}
                                    endDateTime={new Date().setTime(
                                      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
                                      productInfo.release_date.seconds * 1000
                                    )}
                                  />
                                </>
                              }
                              {processionStatus === 'notRelase' &&
                                <>
                                  <Typography variant="subtitle2" style={{ margin: "0 0 4px 2px", color: "grey" }}>入札開始まで</Typography>
                                  <Timer
                                    variant="subtitle1"
                                    styleColor={{ color: "grey" }}
                                    endDateTime={new Date().setTime(
                                      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
                                      productInfo.release_date.seconds * 1000
                                    )}
                                  />
                                </>
                              }
                            </Box>
                            <Box className={classes.under_line}></Box>
                            <Box py={2.5} display="flex" flexDirection="column">
                              <div style={{ textAlign: "right" }}>
                                <Typography variant='subtitle2' style={{ color: "grey" }}>
                                  {processionStatus === "notRelase" && <>スタート価格</>}
                                  {(processionStatus === "processionable" && !productInfo?.highest_bid_ticket) && <>スタート価格</>}
                                  {(processionStatus === "processionable" && productInfo?.highest_bid_ticket) && <>現在価格</>}
                                  {processionStatus === "outOfTerm" && <>最終価格</>}
                                </Typography>
                                <Typography variant='subtitle2' style={{ fontSize: "1.3rem", letterSpacing: "1px", fontWeight: "bold", }}>¥{String(productInfo?.current_bid_amount).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}</Typography>
                              </div>
                            </Box>
                            <Box className={classes.under_line}></Box>
                            <Box py={4} >
                              <BidBottonArea></BidBottonArea>
                              <ShareIconView />
                            </Box>
                          </Grid>
                        </Grid>
                      </div>
                      <DescriptionView htmlString={productInfo?.description} />
                    </Container>
                    // </motion.div>
                  }
                  {states.bidProcess == 1 &&
                    <ShippingMethodInput
                      // @ts-expect-error TS(2322): Type '{ productInfo: Product | undefined; myTicket... Remove this comment to see the full error message
                      productInfo={productInfo}
                      myTicket={myTicket} setMyTicket={setMyTicket} currentTeaserEmail={currentTeaserEmail}
                      states={states} setStates={setStates} setCurrentTeaserEmail={setCurrentTeaserEmail}
                      paymentStates={paymentStates}
                      setPaymentStates={setPaymentStates}
                    />
                  }
                  {states.bidProcess == 2 &&
                    <BidInput
                      // @ts-expect-error TS(2322): Type '{ productInfo: Product | undefined; states: ... Remove this comment to see the full error message
                      productInfo={productInfo}
                      states={states}
                      setStates={setStates}
                      paymentStates={paymentStates}
                      setPaymentStates={setPaymentStates}
                      myTicket={myTicket}
                      setMyTicket={(e: any) => setMyTicket(e)}
                      currentTeaserEmail={currentTeaserEmail}
                    />
                  }
                  {states.bidProcess == 3 &&
                    <PaymentView
                      // @ts-expect-error TS(2322): Type 'Product | undefined' is not assignable to ty... Remove this comment to see the full error message
                      productInfo={productInfo}
                      states={states}
                      setStates={setStates}
                      paymentStates={paymentStates}
                      setPaymentStates={setPaymentStates}
                      myTicket={myTicket}
                      setMyTicket={(e: any) => setMyTicket(e)}
                      currentTeaserEmail={currentTeaserEmail}
                    />
                  }
                </motion.div>
              </AnimatePresence>
              <SigninDialogView />
              <RemainderDialogView />
            </motion.div>
          </>
        }
      </MuiThemeProvider>
    </React.Fragment >
  );
};

export default withRouter(AuctionDetail);
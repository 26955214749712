import React, { useContext, useState } from "react";
import { withRouter } from "react-router-dom";
import { AuthContext } from "../contexts/Auth";
import { makeStyles, Container } from "@material-ui/core";
import { useDispatch } from "react-redux";
import Common from "../redux/common/commonSlice";
import BackdropLoading from "../components/common/BackdropLoading";
import { TextField } from "../components/form/TextField";
import { useForm } from "react-hook-form";
import { validateEmail } from "../utils/validations/Email";
import { Button } from "../components/form/Button";
import { validatePassword } from "../utils/validations/password";
import { PageTitle } from "../components/common/PageTitle";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(8),
  },
}));

const SignUpPassword = (props: any) => {
  const { teaserPassword } = useContext<any>(AuthContext);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { control, getValues, setValue, handleSubmit } = useForm()

  // Emailを取得
  React.useEffect(() => {
    let queryStr = window.location.search.slice(1);
    if (!queryStr) {
      props.history.push("/signup/email");
      return
    }
    let targetEmail = '';
    queryStr.split('&').forEach(function (queryStr) {
      let [key, val] = queryStr.split('=');
      if (key == 'm') {
        targetEmail = Buffer.from(val, 'base64').toString();
      }
    });
    if (!targetEmail) props.history.push("/signup/email")

    setValue('email', targetEmail)
  }, []);

  const setPassword = async () => {
    setIsLoading(true);
    const password = getValues('password')
    const email = getValues('email')
    if (password.length > 6) {
      await teaserPassword(email, password, props.history)
    } else {
      dispatch(
        Common.actions.fetchAlert({
          alerts: [{ message: "パスワードは7文字以上で設定してください。" }],
        })
      );
    }
    setIsLoading(false);
  }

  return (
    <>
      <Container component="main" maxWidth="xs" className={classes.root}>
        <PageTitle title="パスワード設定" />

        <form onSubmit={handleSubmit(setPassword)}>
          <TextField
            control={control}
            id='email'
            name='email'
            label='メールアドレス'
            defaultValue={''}
            valudation={validateEmail}
            inputProps={{ readOnly: true }}
          />
          <TextField
            type="password"
            control={control}
            id='password'
            name='password'
            label='パスワード'
            defaultValue={''}
            valudation={validatePassword}
            helperText="※7文字以上で設定してください。"
          />
          <Button
            text='設定'
            onClick={handleSubmit}
          />
        </form>
      </Container >
      <BackdropLoading open={isLoading} />
    </ >
  );
};

export default withRouter(SignUpPassword);

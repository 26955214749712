import React, { useContext } from "react";
import { Route } from "react-router-dom";
import { AuthContext } from "../Auth";
import { AccessDeniedView } from "../../components/common/AccessDeniedView";
import AdminHeader from "../../components/admin/AdminHeader";

// @ts-expect-error
export const AdminRoute = ({ component: RouteComponent, ...options }) => {
    const { teaserEmail } = useContext<any>(AuthContext);

    if (teaserEmail?.is_admin) {
        return (
            <div style={{ paddingTop: "80px" }}>
                <AdminHeader />
                <Route {...options} component={RouteComponent} />
            </div>
        )
    } else {
        return (
            <Route {...options} component={AccessDeniedView} />
        )
    }
};


import React, { useState, useContext, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Button, Container, Grid, Typography, makeStyles, Dialog, DialogContentText, DialogActions } from "@material-ui/core";
import { useForm } from 'react-hook-form'
import BackdropLoading from '../components/common/BackdropLoading';
import { useDropzone } from 'react-dropzone';
import CloseIcon from '@material-ui/icons/Close';
import { CloudFunctions, db, storage } from '../utils/Firebase';
import { AuthContext } from '../contexts/Auth';
import { useDispatch } from 'react-redux';
import Common from '../redux/common/commonSlice';
import { DroppNoticeChannelId, DroppOperationChannelId, PalmnutsChannelId, amanoSlackMemberId, inuiSlackMemberId, sekiSlackMemberId, sscChannelId, yonaSlackMemberId } from '../utils/Notify';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Product } from '../types/product';

const useStyles = makeStyles(() => ({
    container: {
        marginTop: '100px',
        maxWidth: '780px',
        margin: 'auto',
        paddingBottom: '120px',
    },
    title: {
        fontSize: '16px',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    imageLabel: {
        fontSize: '16px',
    },
    mainButton: {
        backgroundColor: "#f00",
        borderRadius: '0',
        color: '#fff',
        width: '100%',
        marginBottom: '32px',
        height: "48px",
        fontWeight: 'bold',
        letterSpacing: "2px",
    },
    formItem: {
        display: 'flex',
        flexDirection: 'column'
    },
    inputLabel: {
        fontSize: '14px',
        marginBlock: '4px',
    },
    input: {
        padding: '14px',
    },
    errorMessage: {
        color: '#f00',
        fontSize: '14px',
        marginTop: '4px',
    },
    // 画像インプット
    dropZoneWrapper: {
        margin: "10px auto",
        padding: "0 10px",
        width: '300px',
    },
    dropzone: {
        margin: "10px auto",
        padding: "0 10px",
        width: '300px',
        height: '300px',
        border: "1px dashed gray",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "whitesmoke",
        boxSizing: 'border-box',
    },
    preview_wrapper: {
        paddingTop: "20px",
        display: "flex",
        flexWrap: "wrap",
    },
    preview_image: {
        display: 'block',
        width: '180px',
        height: '180px',
        marginRight: "30px",
        marginBottom: "30px",
    },
    image_remove_icon: {
        position: "relative",
        right: "-0px",
        top: "-230px",
    },
    requiredBadge: {
        color: '#f00',
        fontSize: '12px',
    },
    buttonWrapper: {
        margin: '40px 0 32px',
    },
}))

const Years = [
    '不明', '2023年', '2022年', '2021年', '2020年', '2019年', '2018年',
    '2017年', '2016年', '2014年', '2013年', '2012年', '2011年',
    '2010年', '2009年', '2008年', '2007年', '2006年', '2004年',
    '2003年', '2002年', '2001年', '2000年', '1999年', '1998年',
    '1997年', '1996年', '1994年', '1993年', '1992年', '1991年',
    '1990年', '1989年', '1988年', '1987年', '1986年', '1984年',
    '1983年', '1982年', '1981年', '~1980'
]
const Categories = { "sneaker": "スニーカー", "alcohol": 'アルコール', "apparel": 'アパレル', "car": 'クルマ', "interior": 'インテリア', "art": 'アート' }

const getExtensionFromURL = (_url: any) => {
    let extension
    if (_url.indexOf('.jpeg') !== -1) {
        extension = 'jpeg'
    } else if (_url.indexOf('.jpg') !== -1) {
        extension = 'jpg'
    } else if (_url.indexOf('.png') !== -1) {
        extension = 'png'
    } else if (_url.indexOf('.gif') !== -1) {
        extension = 'gif'
    } else {
        extension = 'jpg'
    }
    return extension
}
const getContentType = (_extension: any) => {
    let type = ''
    switch (_extension) {
        case 'jpeg':
        case 'jpg':
            type = 'image/jpeg'
            break;
        case 'png':
            type = 'image / png'
            break;
        case 'gif':
            type = ' image / gif'
            break;
    }
    return type
}

const getBlob = async (_url: any) => {
    let blob = null
    await fetch(_url).then(async r => {
        blob = await r.blob()
    }).catch(() => {
        blob = null
    })
    return blob
}

const getFirestoreImageData = (i: any, productTitle: any, productId: any, path: any, src: any) => {
    return {
        admin_graphql_api_id: null,
        alt: productTitle + (Number(i) + 1),
        created_at: new Date(),
        height: 1800,
        id: productId + String(Number(i + 1)),
        position: (Number(i) + 1),
        product_id: productId,
        src: src,
        updated_at: new Date(),
        variant_ids: [],
        width: 1800,
        provider: 'storage',
        path: path,
    }
}


const uploadImage = async (file: any, productId: any, extension: any, contentType: any, index: any) => {
    let src = ''
    let path = ''
    await storage.ref(`/product-images/${productId}-${Number(index) + 1}.${extension}`).put(file, { contentType }).then(async snapshot => {
        await snapshot.ref.getDownloadURL().then(res => {
            src = res
            path = `/product-images/${productId}-${Number(index) + 1}.${extension}`
        }).catch(() => { })
    })
    return { src, path }
}

type Params = {
    product_id: string;
}

export const AppraisalUpdate = () => {
    const classes = useStyles()
    const history = useHistory()
    const dispatch = useDispatch()
    const { product_id } = useParams<Params>();
    const [isLoading, setIsLoading] = useState(false)
    const [images, setImages] = useState<any[]>([])
    const files: any[] = []
    const [product, setProduct] = useState<Product | null>(null)
    const { register, getValues, setValue, handleSubmit, formState: { errors } } = useForm()
    const { currentUser } = useContext<any>(AuthContext);

    useEffect(() => {
        if (!product_id) return

        const getProduct = async () => {
            await db.collection('products').doc(product_id).get().then(doc => {
                const _product = doc.data() as Product
                _product['id'] = doc.id
                setProduct(_product)
                setValue('productName', _product.title)
                setImages(_product.images.map((image: any) => {
                    return {
                        ...image,
                        uploadType: 'current',
                    }
                }))

                setValue('description', _product.description)

                // @ts-expect-error TS(2532): Object is possibly 'undefined'.
                _product.appraisalDetails.forEach((detail: any) => {
                    switch (detail.title) {
                        case 'condition':
                            setValue('condition', detail.value)
                            break;
                        case 'productSize':
                            setValue('productSize', detail.value)
                            break;
                        case 'color':
                            setValue('color', detail.value)
                            break;
                        case 'quantity':
                            setValue('quantity', detail.value)
                            break;
                        case 'hasReciept': // Optionタグは文字列になる
                            setValue('hasReciept', detail.value ? 'true' : 'false')
                            break;
                        case 'hasAccessory': // Optionタグは文字列になる
                            setValue('hasAccessory', detail.value ? 'true' : 'false')
                            break;
                        case 'accessoryDetail':
                            setValue('accessoryDetail', detail.value)
                            break;
                        case 'modelYear':
                            setValue('modelYear', detail.value)
                            break;
                        case 'buyYear':
                            setValue('buyYear', detail.value)
                            break;
                        case 'status':
                            setValue('status', detail.value)
                            break;
                        case 'category':
                            setValue('category', detail.value)
                            break;
                    }
                })
            })
        }
        getProduct()

    }, [product_id])

    // Storageに画像を保存
    const uploadImagesAndGetImagesData = async (_product: any) => {
        const _images = []
        // 画像ごとをStorageにアップロード
        for (const [i, image] of Object.entries(images)) {
            let imageVal = ''
            if (image.uploadType === 'current') { // すでにアップされていた画像はURLを持っている。
                const extension = getExtensionFromURL(image.src)
                const contentType = getContentType(extension)
                const blobFile = await getBlob(image.src)
                // @ts-expect-error TS(2322): Type '{ src: string; path: string; }' is not assig... Remove this comment to see the full error message
                imageVal = await uploadImage(blobFile, _product.id, extension, contentType, i)
            } else { // File upload
                const contentType = image.type
                const extension = image.path.split('.').pop()
                const blobFile = image
                // @ts-expect-error TS(2322): Type '{ src: string; path: string; }' is not assig... Remove this comment to see the full error message
                imageVal = await uploadImage(blobFile, _product.id, extension, contentType, i)
            }

            // @ts-expect-error TS(2339): Property 'path' does not exist on type 'string'.
            const _image = getFirestoreImageData(i, _product.title, _product.id, imageVal.path, imageVal.src)
            _images.push(_image)
        }
        return _images
    }

    //買取商品を登録
    const onSubmit = async () => {
        if (!product) return

        setIsLoading(true)
        try {
            const appraisalDetails = [
                { title: 'condition', value: getValues().condition },
                { title: 'productSize', value: getValues().productSize },
                { title: 'color', value: getValues().color },
                { title: 'quantity', value: getValues().quantity },
                { title: 'hasReciept', value: getValues().hasReciept === 'true' },
                { title: 'hasAccessory', value: getValues().hasAccessory === 'true' },
                { title: 'accessoryDetail', value: getValues().accessoryDetail },
                { title: 'modelYear', value: getValues().modelYear },
                { title: 'buyYear', value: getValues().buyYear },
                { title: 'status', value: getValues().status },
                { title: 'category', value: getValues().category },
            ]
            const productName = getValues().productName

            // 2. 画像Storageに登録 [商品ID-index.type]で登録
            const _images = await uploadImagesAndGetImagesData(product)

            // 3.firestoreに登録
            const jsReleaseDateStr = getValues().status === 'active' ? new Date().toLocaleString('ja') : null
            const jsUpdatedAtStr = new Date().toLocaleString('ja')
            const _firestoreProduct = {
                draft_status: getValues().status,
                id: product.id,
                insurance_fee_name: "",
                is_authorization: null,
                is_featured: null,
                is_production: false,
                product_type: '買取',
                release_date: jsReleaseDateStr,
                status: 'draft',
                tags: [getValues().category,],
                title: productName,
                updated_at: jsUpdatedAtStr,
                user_id: currentUser.uid,
                user_email: currentUser.email,
                image: _images[0].src ? _images[0].src : null,
                images: _images,
                description: getValues().description,
                appraisalDetails,
            }

            const updateFirestoreProduct = CloudFunctions.httpsCallable('updateFirestoreProduct')
            const resp = await updateFirestoreProduct({ productId: Number(product.id), field: _firestoreProduct })
            if (resp.data.status === 'failed') {
                throw new Error(`Failed to CreateFirestoreProduct`)
            }

            // Slackに通知
            if (getValues().status === 'active') {
                const postSlackMessage = CloudFunctions.httpsCallable("postSlackMessage")
                const text = `【買取出品】\n商品名: ${productName} \n-> https://dropp.jp/appraisal/${_firestoreProduct.id}`
                try {
                    // Droppに通知
                    const memberText = amanoSlackMemberId
                    const droppText = memberText + '\n' + text
                    await postSlackMessage({ text: droppText, conversationId: DroppNoticeChannelId })
                    // SSCに通知
                    const sscMemberText = sekiSlackMemberId
                    const sscText = sscMemberText + '\n' + text
                    await postSlackMessage({ text: sscText, conversationId: sscChannelId })
                    // パームナッツに通知
                    const palmnutsMemberText = yonaSlackMemberId + inuiSlackMemberId
                    const palmnutsText = palmnutsMemberText + '\n' + text
                    await postSlackMessage({ text: palmnutsText, conversationId: PalmnutsChannelId })
                } catch (e) {
                    console.error(e)
                }
            }

            // 5.戻る
            setIsLoading(false)
            history.push('/appraisal')
            dispatch(
                Common.actions.fetchAlert({
                    alerts: [{ message: "商品登録が完了しました。" }],
                })
            );

        } catch (e) {
            dispatch(
                Common.actions.fetchAlert({
                    alerts: [{ message: "商品登録が失敗しました。" }],
                })
            );
            const errorMessage = `買取商品の更新に失敗しました。\n商品ID: ${product.id}`
            await postNotifyOperation(errorMessage)
            setIsLoading(false)
        }
    }

    const postNotifyOperation = async (message: any) => {
        const postSlackMessage = CloudFunctions.httpsCallable("postSlackMessage")
        const amano = '<@U01TJ3U9NHM>'
        const channelId = DroppOperationChannelId
        const text = `${amano} \n${message}`
        try {
            await postSlackMessage({ text, conversationId: channelId })
        } catch (e) {
            console.error(e)
        }
    }

    const handleImageRemove = (index: any) => {
        const newImages = images.filter((_, i) => i !== index)
        // 改めてBolbのURLを作成する
        const formatedImages = newImages.map((image, i) => {
            return Object.assign(image, {
                src: URL.createObjectURL(image),
                uploadType: "upload",
                position: i,
                provider: null,
                path: null,
            })
        })
        setImages(formatedImages)
    }

    const dragStart = (e: any) => {
        e.dataTransfer.setData("text/plain", e.target.id)
    }
    const dragOver = (e: any) => {
        e.preventDefault()
    }
    const drop = (e: any, dropedImage: any) => {
        e.preventDefault()
        // 一旦uploadした画像のrevokeURLする
        images.forEach(image => URL.revokeObjectURL(image.src))

        // Droppした画像とされた画像の場所を入れ替える
        // ファイルオブジェクトで渡されているものはfileオブジェクトで格納する
        let newArr: any = []
        images.forEach((image, index) => {
            if (image.position === dropedImage.position) {
                newArr.push(Object.assign(image, {
                    src: URL.createObjectURL(image),
                    position: index,
                    provider: null,
                    path: null,
                }))
                //     // @ts-expect-error TS(2304): Cannot find name 'id'.
                // } else if (id === index) {
                //     newArr.push(Object.assign(image, {
                //         src: URL.createObjectURL(image),
                //         uploadType: "upload",
                //         position: dropedImage.position,
                //         provider: null
                //     }))
            } else {
                newArr.push(image)
            }
        })
        newArr.sort((a: any, b: any) => a.position - b.position)
        setImages(newArr)
    }

    // 画像が選択された時の処理
    const { getRootProps, getInputProps } = useDropzone({
        onDrop: (acceptedFiles: any) => {
            if (files.length > 0) acceptedFiles.push(...files) // 既存の画像に追加
            console.log('acceptedFiles', acceptedFiles)
            // const currentImages = images
            const newImages = acceptedFiles.map((file: any, index: any) => Object.assign(file, {
                src: URL.createObjectURL(file),
                uploadType: "upload",
                position: images.length + index,
                provider: null,
            }))
            setImages([...images, ...newImages])
        }
    })


    const [cancelDialogOpen, setCancelDialogOpen] = useState(false)
    const CancelDialog = () => {
        return (
            <Dialog open={cancelDialogOpen} onClose={() => setCancelDialogOpen(false)}>
                <DialogContentText style={{ padding: '16px' }}>
                    <p>商品の出品をとりやめます。よろしいですか？</p>
                </DialogContentText>
                <DialogActions style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <Button onClick={() => setCancelDialogOpen(false)}>いいえ</Button>
                    <Button style={{ color: '#ff0000' }} onClick={handleArchived}>はい</Button>
                </DialogActions>
            </Dialog >
        )
    }

    // アーカイブ化する
    const handleArchived = async () => {
        setIsLoading(true)
        setCancelDialogOpen(false)
        try {
            const updateFirestoreProduct = CloudFunctions.httpsCallable('updateFirestoreProduct')
            // @ts-expect-error TS(2531): Object is possibly 'null'.
            const resp = await updateFirestoreProduct({ productId: Number(product.id), field: { draft_status: 'archived' } })
            if (resp.data.status === 'failed') {
                throw new Error(`Failed to CreateFirestoreProduct`)
            }
            console.log('Update firestore product:', resp.data)
            // 5.戻る
            setIsLoading(false)
            history.push('/appraisal')
            dispatch(
                Common.actions.fetchAlert({
                    alerts: [{ message: "商品登録が完了しました。" }],
                })
            );
        } catch (e) {
            dispatch(
                Common.actions.fetchAlert({
                    alerts: [{ message: "出品のとりやめに失敗しました。" }],
                })
            );
            // @ts-expect-error TS(2531): Object is possibly 'null'.
            const errorMessage = `買取商品のアーカイブ化に失敗しました。\n商品ID: ${product.id}`
            await postNotifyOperation(errorMessage)
            setIsLoading(false)
        }
    }

    return (
        <>
            {currentUser && currentUser.uid === product?.user_id &&
                <Container className={classes.container}>

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '40px' }}>
                        <div onClick={() => history.push(`/appraisal/${product?.id}`)} style={{ width: '68px', display: 'flex', color: 'dimgray' }}>
                            <ArrowBackIcon />
                            &nbsp;戻る
                        </div>
                        <Typography variant='h1' className={classes.title}>査定登録</Typography>
                        <div style={{ width: '68px' }}></div>
                    </div>

                    <div>
                        <form onSubmit={handleSubmit(onSubmit)}>

                            <div className={classes.dropZoneWrapper}>
                                <Typography variant="subtitle2" className={classes.imageLabel}>商品画像</Typography>
                                <div {...getRootProps({ className: 'dropzone' + ' ' + classes.dropzone })}>
                                    <input {...getInputProps()} />
                                    <p style={{ fontSize: '14px' }}>画像をドラッグ & ドロップするか、こちらをクリックしてアップロードしてください。
                                    </p>
                                </div>
                            </div>

                            {images.length > 1 && <div><Typography variant="subtitle2">※画像を移動させて順番を変えることができます。</Typography><br /></div>}
                            <div className={classes.preview_wrapper}>
                                {images.length > 0 && images.map((image, i) => (
                                    <div key={i}>
                                        <img
                                            id={String(i)} draggable="true" onDragStart={dragStart} onDragOver={dragOver} onDrop={(e) => drop(e, image)}
                                            key={image.name} src={image.src} className={classes.preview_image} onLoad={() => { URL.revokeObjectURL(image.src) }} />
                                        <CloseIcon className={classes.image_remove_icon} onClick={() => handleImageRemove(i)} />
                                    </div>
                                ))}
                            </div>


                            <div className={classes.formItem}>
                                <label className={classes.inputLabel} htmlFor='productName'>商品名<span className={classes.requiredBadge}>&nbsp;※必須</span></label>
                                <input
                                    className={classes.input}
                                    id="productName"
                                    {...register('productName', {
                                        required: {
                                            value: true,
                                            message: '入力をお願いいたします。'
                                        }
                                    })} />
                                <p className={classes.errorMessage}>{errors.productName?.message}</p>
                            </div>

                            <div className={classes.formItem}>
                                <label className={classes.inputLabel} htmlFor='description'>
                                    商品の説明
                                    <span className={classes.requiredBadge}>&nbsp;※必須</span>
                                </label>
                                <textarea
                                    className={classes.input}
                                    rows={4}
                                    id="description"
                                    {...register('description', {
                                        required: {
                                            value: true,
                                            message: '入力をお願いいたします。'
                                        }
                                    })} />
                                <p className={classes.errorMessage}>{errors.description?.message}</p>
                            </div>

                            <Grid container spacing={3}>
                                <Grid item xs={6} md={6} className={classes.formItem}>
                                    <label className={classes.inputLabel} htmlFor='condition'>
                                        商品の状態
                                        <span className={classes.requiredBadge}>&nbsp;※必須</span>
                                    </label>
                                    <input
                                        className={classes.input}
                                        id="condition"
                                        placeholder='例）新品、未使用品、中古品'
                                        {...register('condition', {
                                            required: {
                                                value: true,
                                                message: '入力をお願いいたします。'
                                            }
                                        })} />
                                    <p className={classes.errorMessage}>{errors.condition?.message}</p>
                                </Grid>

                                <Grid item xs={6} md={6} className={classes.formItem}>
                                    <label className={classes.inputLabel} htmlFor='productSize'>
                                        商品のサイズ
                                        <span className={classes.requiredBadge}>&nbsp;※必須</span>
                                    </label>
                                    <input
                                        className={classes.input}
                                        id="productSize"
                                        placeholder='例）縦: 100cm, 横: 100cm, 高さ: 100cm'
                                        {...register('productSize', {
                                            required: {
                                                value: true,
                                                message: '入力をお願いいたします。'
                                            }
                                        })} />
                                    <p className={classes.errorMessage}>{errors.productSize?.message}</p>
                                </Grid>
                            </Grid>

                            <Grid container spacing={3}>
                                <Grid item xs={6} md={6} className={classes.formItem}>
                                    <label className={classes.inputLabel} htmlFor='category'>
                                        カテゴリー
                                        <span className={classes.requiredBadge}>&nbsp;※必須</span>
                                    </label>
                                    <select
                                        className={classes.input}
                                        id='category'
                                        {...register('category')}
                                    >
                                        {Object.keys(Categories).map((key, index) => (
                                            // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                                            <option key={index} value={key}>{Categories[key]}</option>
                                        ))}
                                    </select>
                                    <p className={classes.errorMessage}>{errors.category?.message}</p>
                                </Grid>
                                <Grid item xs={6} md={6} className={classes.formItem}>
                                    <label className={classes.inputLabel} htmlFor='quantity'>
                                        数量
                                        <span className={classes.requiredBadge}>&nbsp;※必須</span>
                                    </label>
                                    <input
                                        type='number'
                                        className={classes.input}
                                        id="quantity"
                                        {...register('quantity', {
                                            required: {
                                                value: true,
                                                message: '入力をお願いいたします。'
                                            }
                                        })} />
                                    <p className={classes.errorMessage}>{errors.quantity?.message}</p>
                                </Grid>
                            </Grid>

                            <Grid container spacing={3}>
                                <Grid item xs={6} md={6} className={classes.formItem}>
                                    <label className={classes.inputLabel} htmlFor='hasReciept'>
                                        納品書の有無
                                        <span className={classes.requiredBadge}>&nbsp;※必須</span>
                                    </label>
                                    <select
                                        className={classes.input}
                                        id='hasReciept'
                                        {...register('hasReciept')}
                                    >
                                        {/* @ts-expect-error */}
                                        <option value={false}>なし</option>
                                        {/* @ts-expect-error */}
                                        <option value={true}>あり</option>
                                    </select>
                                    <p className={classes.errorMessage}>{errors.hasReciept?.message}</p>
                                </Grid>

                                <Grid item xs={6} md={6} className={classes.formItem}>
                                    <label className={classes.inputLabel} htmlFor='hasAccessory'>
                                        付属品の有無
                                        <span className={classes.requiredBadge}>&nbsp;※必須</span>
                                    </label>
                                    <select
                                        className={classes.input}
                                        id='hasAccessory'
                                        {...register('hasAccessory')}
                                    >
                                        {/* @ts-expect-error */}
                                        <option value={false}>なし</option>
                                        {/* @ts-expect-error */}
                                        <option value={true}>あり</option>
                                    </select>
                                    <p className={classes.errorMessage}>{errors.hasAccessory?.message}</p>
                                </Grid>

                                <Grid item xs={12}>
                                    <div className={classes.formItem}>
                                        <label className={classes.inputLabel} htmlFor='accessoryDetail'>
                                            付属品の詳細
                                        </label>
                                        <textarea
                                            className={classes.input}
                                            rows={4}
                                            id="accessoryDetail"
                                            {...register('accessoryDetail')} />
                                        <p className={classes.errorMessage}>{errors.accessoryDetail?.message}</p>
                                    </div>
                                </Grid>
                            </Grid>

                            <Grid container spacing={3}>
                                <Grid item xs={6} md={6} className={classes.formItem}>
                                    <label className={classes.inputLabel} htmlFor='modelYear'>
                                        商品の年式
                                    </label>
                                    <select
                                        className={classes.input}
                                        id='modelYear'
                                        {...register('modelYear')}
                                    >
                                        {Years.map((y, index) => (
                                            <option value={y} key={index}>{y}</option>
                                        ))}
                                    </select>
                                    <p className={classes.errorMessage}>{errors.modelYear?.message}</p>
                                </Grid>

                                <Grid item xs={6} md={6} className={classes.formItem}>
                                    <label className={classes.inputLabel} htmlFor='buyYear'>購入時期</label>
                                    <select
                                        className={classes.input}
                                        id='buyYear'
                                        {...register('buyYear')}
                                    >
                                        {Years.map((y, index) => (
                                            <option value={y} key={index}>{y}</option>
                                        ))}
                                    </select>
                                    <p className={classes.errorMessage}>{errors.buyYear?.message}</p>
                                </Grid>
                            </Grid>

                            <Grid container spacing={3}>
                                <Grid item xs={6} md={6} className={classes.formItem}>
                                    <label className={classes.inputLabel} htmlFor='status'>
                                        登録ステータス
                                        <span className={classes.requiredBadge}>&nbsp;※必須</span>
                                    </label>
                                    <select
                                        className={classes.input}
                                        id='status'
                                        {...register('status')}
                                    >
                                        <option value={'draft'}>下書き</option>
                                        <option value={'active'}>出品</option>
                                        <option value={'archived'}>終了</option>
                                    </select>
                                    <p className={classes.errorMessage}>{errors.status?.message}</p>
                                </Grid>
                            </Grid>

                            <div className={classes.buttonWrapper}>
                                <Button type='submit' value='save' className={classes.mainButton}>更新する</Button>
                            </div>

                        </form>

                        <div className={classes.buttonWrapper}>
                            <p>出品をやめた場合、再出品ができなくなります。<br />同じ商品を再出品する場合はもう一度新規登録する必要があります。</p>
                            <Button onClick={() => setCancelDialogOpen(true)} className={classes.mainButton}>出品をやめる</Button>
                        </div>
                    </div>

                    <CancelDialog />
                    <BackdropLoading open={isLoading} />
                </Container >
            }
        </>
    )
}

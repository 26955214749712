import React, { useState } from "react";
import {
  Button,
  CardMedia,
  Container,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContentText,
  withStyles,
  Grid,
  makeStyles,
  Typography,
  Divider,
} from "@material-ui/core";
import MuiDialogContent from '@material-ui/core/DialogContent';
import { db } from "../../utils/Firebase";
import { formatedYen } from "../../utils/Common";
import HoursTimer from "../common/HoursTimer";
import { Product } from "../../types/product";

const useStyles = makeStyles((theme) => ({
  cardGrid: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    paddingRight: 0,
    paddingLeft: 0,
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(4)
    }
  },
  grid_item: {
    marginBottom: theme.spacing(6),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    maxWidth: "450px",
    margin: "0 auto",
  },
  cardMedia: {
    width: "80%",
    paddingTop: "80%",
    margin: "0 auto",
  },
  item_info: {
    display: "flex",
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: "center",
    color: "#ff0000",
  },
  timer_text: {
    marginBottom: theme.spacing(1),
    fontSize: '0.65rem',
  },
  entryViewButton: {
    width: "240px",
    height: "32px",
    margin: theme.spacing(3, 1, 2),
    fontWeight: 'bold',
    border: "1px solid grey",
    borderRadius: "22.5px",
    backgroundColor: "white",
    fontSize: '0.8rem',
  },
  product_title: {
    marginTop: theme.spacing(2),
    margin: 'auto',
    letterSpacing: '0.03rem',
    wordBreak: 'keep-all',
    width: '80%',
    whiteSpace: "pre-line",
    lineHeight: "1.2em",
    color: "gray",
    fontSize: ".8rem",
    overflow: "hidden",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
    [theme.breakpoints.up('sm')]: {
      fontSize: "0.8rem",
    }
  },
  product_price: {
    textAlign: 'center',
    color: 'grey',
    marginTop: '4px',
    fontWeight: 'bold',
  },
  button_wrapper: {
    display: "flex",
    justifyContent: "space-around",
  },
  no_entry_product: {
    marginTop: theme.spacing(20),
    textAlign: 'center',
  },
  ticket_count: {
    color: "black",
    fontSize: '0.9rem',
  },
  entry_title: {
    textAlign: "center",
    margin: "8px 0",
  },
}));

const CustomDialogContent = withStyles(() => ({
  root: {
    flex: '1 1 auto',
    padding: '0px 22px',
    overflowY: 'auto',
    '&:first-child': {
      paddingTop: '25px',
    }
  },
}))(MuiDialogContent);

const AdminProductsView = (props: any) => {
  const classes = useStyles();
  const products = props.products ? props.products : [];
  const [showEntryFlg, setShowEntryFlg] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [productInfo, setProductInfo] = useState<Product | null>(null)

  const getProductInfo = async (product: any) => {
    setIsLoading(true)

    let _product = {
      ...product,
      title: product.title,
      price: product.variants[0].price,
      totalEntryCount: 0,
      variants: {},
      isAuthorization: false,
    }

    if (product.product_type === '抽選') {
      await db.collection('tickets').where("product_id", "==", Number(product.id)).get().then(snapshot => {
        snapshot.forEach(ticket => {
          let _ticket = ticket.data()
          let _variant = _ticket.variation["1"]
          if (_ticket.shipping_info?.country_code && _ticket.shipping_info?.country_code !== 'JP') {
            _product.totalOverseaCount += 1
          }
          // バリエーションごとに加算
          if (!_product.variants[_variant]) {
            _product.variants[_variant] = 1;
          } else {
            _product.variants[_variant] += 1;
          }
          _product.totalEntryCount += 1;
        })
      })
    }

    if (product.product_type === 'オークション') {
      await db.collection('tickets').where("product_id", "==", Number(product.id)).get().then(snapshot => {
        console.log('入札チケットの数: ', snapshot.size)
        _product.bid_users = snapshot.size
      })
      await db.collection('reminders').where("product_id", "==", product.id).get().then(snapshot => {
        console.log('リマインダーの数: ', snapshot.size)
        _product.reminders = snapshot.size
      })

    }
    setProductInfo(_product)
    console.log('productInfo', _product)
    setShowEntryFlg(true)
    setIsLoading(false)
  }

  const AuctionProductInfo = () => {
    if (!productInfo) return <></>
    let _current_amount = productInfo.bid_history?.length > 0 ? productInfo.current_bid_amount : 0
    let _highest_amount = productInfo.bid_history?.length > 0 ? productInfo.highest_bid_ticket?.bid_amount : 0
    let bid_length = productInfo.bid_history?.length > 0 ? productInfo.bid_history.length : 0
    return (
      <div>
        <Typography>商品名：{productInfo.title}</Typography>
        <Typography>価格：{formatedYen(Number(productInfo.price))}円</Typography>
        <Typography>ID：{productInfo.id}</Typography>
        <Typography>スタート価格：{formatedYen(Number(productInfo.price))}円</Typography>
        <Typography>現在価格：{formatedYen(_current_amount)}円</Typography>
        <Typography>最高入札額：{formatedYen(_highest_amount)}円</Typography>
        <Typography>入札数：{bid_length}入札</Typography>
        <Typography>入札者数：{productInfo.bid_users}人</Typography>
        <Typography>リマインダー設定：{productInfo.reminders}人</Typography>
      </div>
    )
  }

  const LotteryProductInfo = () => {
    if (!productInfo) return <></>
    return (
      <>
        <Typography>商品名：{productInfo.title}</Typography>
        <Typography>価格：{formatedYen(Number(productInfo.price))}円</Typography>
        <Typography>ID：{productInfo.id}</Typography>
        <Typography>出品者：{productInfo.vendor}</Typography>
        {productInfo.totalEntryCount && productInfo.totalEntryCount > 1 &&
          <>
            <Divider style={{ margin: "10px 0" }} />
            <Typography variant="subtitle2" className={classes.entry_title}>オプション別エントリー数</Typography>
            {Object.keys(productInfo.variants).map(variant => {
              return (
                // @ts-ignore
                <Typography variant="subtitle2" className={classes.ticket_count}>{`${variant}`}：{`${productInfo.variants[`${variant}`]}`}人</Typography>
              )
            })}
          </>
        }
        <Divider style={{ margin: "10px 0" }} />
        <Typography variant="subtitle2" className={classes.ticket_count}>合計エントリー数：{productInfo.totalEntryCount}人</Typography>
        <Typography variant="subtitle2" className={classes.ticket_count}>(うち海外エントリー数: {productInfo.totalOverseaCount}人)</Typography>
      </>
    )
  }

  const DialogView = () => {
    return <Dialog
      open={showEntryFlg}
      onClose={() => setShowEntryFlg(false)}
    >
      <CustomDialogContent>
        <DialogContentText>
          {productInfo?.product_type === 'オークション' && <AuctionProductInfo />}
          {productInfo?.product_type === '抽選' && <LotteryProductInfo />}
        </DialogContentText>
      </CustomDialogContent>
      <DialogActions>
        <Button onClick={() => setShowEntryFlg(false)}>閉じる</Button>
      </DialogActions>
    </Dialog>;
  }

  console.log('rendering ')

  if (products.length >= 1) {
    return (
      <Container className={classes.cardGrid} maxWidth="md">
        <Grid container>
          {products.map((product: any) => <Grid className={classes.grid_item} item key={product.id} xs={6} sm={4} md={4}>
            <div className={classes.card}>
              <div className={classes.item_info}>
                <Typography variant='subtitle2' className={classes.timer_text}>エントリー締め切り</Typography>
                <HoursTimer
                  variant="subtitle1"
                  endDateTime={new Date().setTime(
                    product.deadline_date.seconds * 1000
                  )}
                />
              </div>
              <CardMedia
                className={classes.cardMedia}
                image={product.image}
                title="Image title"
              />
              <Typography className={classes.product_title}>{product.title}</Typography>
              <Typography className={classes.product_price}>¥{formatedYen(product.variants[0].price)}</Typography>
              <div className={classes.button_wrapper}>
                <Button variant="outlined" onClick={() => getProductInfo(product)} className={classes.entryViewButton}>
                  {isLoading ? <CircularProgress style={{ color: 'white' }} /> : <>エントリーを確認</>}
                </Button>
              </div>
            </div>
          </Grid>)}
        </Grid>
        {showEntryFlg && <DialogView />}
      </Container>
    );
  } else {
    return (
      <>
        <Typography variant="subtitle2" className={classes.no_entry_product}>現在Dropp中の商品はございません。</Typography>
      </>
    )
  }
};
export default AdminProductsView;
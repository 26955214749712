import React, { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Checkbox, Container, FormControlLabel, Grid, Typography, makeStyles, withStyles } from "@material-ui/core";
import { useForm } from 'react-hook-form'
import BackdropLoading from '../components/common/BackdropLoading';
import { useDropzone } from 'react-dropzone';
import CloseIcon from '@material-ui/icons/Close';
import { CloudFunctions, storage } from '../utils/Firebase';
import { AuthContext } from '../contexts/Auth';
import { useDispatch } from 'react-redux';
import Common from '../redux/common/commonSlice';
import { DroppNoticeChannelId, PalmnutsChannelId, amanoSlackMemberId, inuiSlackMemberId, sekiSlackMemberId, sscChannelId, yonaSlackMemberId } from '../utils/Notify';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import TermOfUseSlideView from '../components/common/TermOfUseSlideView';
import { useProduct } from '../hooks/useProduct';

const useStyles = makeStyles(() => ({
    container: {
        marginTop: '100px',
        maxWidth: '780px',
        margin: 'auto',
        paddingBottom: '20px',
    },
    titleWrapper: {
        display: "flex",
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '40px',
    },
    title: {
        fontSize: '16px',
        fontWeight: 'bold',
        textAlign: 'center',
        letterSpacing: '1.2px',
    },
    mainButton: {
        backgroundColor: "#f00",
        borderRadius: '0',
        color: '#fff',
        width: '100%',
        marginBottom: '32px',
        height: "48px",
        fontWeight: 'bold',
        letterSpacing: "2px",
    },
    negativeButton: {
        backgroundColor: "gray",
        borderRadius: '0',
        color: 'gray',
        width: '100%',
        marginBottom: '32px',
        height: "48px",
        fontWeight: 'bold',
        letterSpacing: "2px",
    },
    formItem: {
        display: 'flex',
        flexDirection: 'column'
    },
    inputLabel: {
        fontSize: '14px',
        marginBlock: '4px',
        color: '#282828',
    },
    input: {
        padding: '14px',
    },
    errorMessage: {
        color: '#f00',
        fontSize: '14px',
        marginTop: '4px',
    },
    imageInputLabel: {
        width: '400px',
        margin: '0 auto',
    },
    // 画像インプット
    dropzone: {
        width: '400px',
        height: '400px',
        margin: "10px auto",
        padding: "0 10px",
        border: "1px dashed gray",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "whitesmoke",
        boxSizing: 'border-box',
    },
    previewWrapper: {
        paddingTop: "20px",
        display: "flex",
        flexWrap: "wrap",
    },
    previewImage: {
        display: 'block',
        width: '180px',
        height: '180px',
        marginRight: "30px",
        marginBottom: "30px",
    },
    imageRemoveIcon: {
        position: "relative",
        right: "-0px",
        top: "-230px",
    },
    requiredBadge: {
        color: '#f00',
        fontSize: '12px',
    },
    buttonWrapper: {
        margin: '40px 0 0',
    },
}))

const CustomCheckBox = withStyles({
    root: {
        color: "#ff0000",
        '&$checked': {
            color: "#ff0000",
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const Years = [
    '不明', '2023年', '2022年', '2021年', '2020年', '2019年', '2018年',
    '2017年', '2016年', '2014年', '2013年', '2012年', '2011年',
    '2010年', '2009年', '2008年', '2007年', '2006年', '2004年',
    '2003年', '2002年', '2001年', '2000年', '1999年', '1998年',
    '1997年', '1996年', '1994年', '1993年', '1992年', '1991年',
    '1990年', '1989年', '1988年', '1987年', '1986年', '1984年',
    '1983年', '1982年', '1981年', '~1980'
]
const Categories = { "sneaker": "スニーカー", "watch": '時計', "alcohol": 'アルコール', "apparel": 'アパレル', "car": 'クルマ', "interior": 'インテリア', "art": 'アート', 'hobby': 'ホビー', 'others': 'その他' }

const getExtensionFromURL = (_url: any) => {
    let extension
    if (_url.indexOf('.jpeg') !== -1) {
        extension = 'jpeg'
    } else if (_url.indexOf('.jpg') !== -1) {
        extension = 'jpg'
    } else if (_url.indexOf('.png') !== -1) {
        extension = 'png'
    } else if (_url.indexOf('.gif') !== -1) {
        extension = 'gif'
    } else {
        extension = 'jpg'
    }
    return extension
}
const getContentType = (_extension: any) => {
    let type = ''
    switch (_extension) {
        case 'jpeg':
        case 'jpg':
            type = 'image/jpeg'
            break;
        case 'png':
            type = 'image / png'
            break;
        case 'gif':
            type = ' image / gif'
            break;
    }
    return type
}

const getBlob = async (_url: any) => {
    let blob = null
    await fetch(_url).then(async r => {
        blob = await r.blob()
    }).catch(() => {
        blob = null
    })
    return blob
}


const uploadImage = async (file: any, productId: any, extension: any, contentType: any, index: any) => {
    let src = ''
    let path = ''
    await storage.ref(`/product-images/${productId}-${Number(index) + 1}.${extension}`).put(file, { contentType }).then(async snapshot => {
        await snapshot.ref.getDownloadURL().then(res => {
            src = res
            path = `/product-images/${productId}-${Number(index) + 1}.${extension}`
        }).catch(() => { })
    })
    return { src, path }
}

export const AppraisalNew = () => {
    const classes = useStyles()
    const history = useHistory()
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)
    const [images, setImages] = useState([])
    const files: any[] = []
    const { register, getValues, handleSubmit, formState: { errors } } = useForm()
    const { currentUser } = useContext<any>(AuthContext);
    const { createProductId } = useProduct()

    const getFirestoreImageData = (i: any, productTitle: any, productId: any, path: any, src: any) => {
        return {
            admin_graphql_api_id: null,
            alt: productTitle + (Number(i) + 1),
            created_at: new Date(),
            height: 1800,
            id: productId + String(Number(i + 1)),
            position: (Number(i) + 1),
            product_id: productId,
            src: src,
            updated_at: new Date(),
            variant_ids: [],
            width: 1800,
            provider: 'storage',
            path: path,
        }
    }

    const [termOfUseOpen, setTermOfUseOpen] = useState(false)
    const [isCheckedTerms, setIsCheckedTerms] = useState(false)
    // 利用規約に同意するかどうか
    const handleCheckTerm = () => {
        setIsCheckedTerms(!isCheckedTerms)
    }

    // 利用規約をオープン
    const handleTermsViewToggle = () => {
        setTermOfUseOpen(true)
        setIsCheckedTerms(false)
    }

    // 利用規約をクローズ
    const handleTermsViewClose = () => {
        setTermOfUseOpen(false)
        setIsCheckedTerms(false)
    }

    // Storageに画像を保存
    const uploadImageToStorage = async (_productTitle: string, _productId: number | string) => {
        const _images = []
        // 画像ごとをStorageにアップロード
        for (const [i, image] of Object.entries(images)) {
            let imageVal = ''
            {/* @ts-ignore */ }
            if (image.uploadType === 'current') { // すでにアップされていた画像はURLを持っている。
                {/* @ts-ignore */ }
                const extension = getExtensionFromURL(image.src)
                const contentType = getContentType(extension)
                {/* @ts-ignore */ }
                const blobFile = await getBlob(image.src)
                // @ts-expect-error TS(2322): Type '{ src: string; path: string; }' is not assig... Remove this comment to see the full error message
                imageVal = await uploadImage(blobFile, _productId, extension, contentType, i)
            } else { // File upload
                {/* @ts-ignore */ }
                const contentType = image.type
                {/* @ts-ignore */ }
                const extension = image.path.split('.').pop()
                const blobFile = image
                // @ts-expect-error TS(2322): Type '{ src: string; path: string; }' is not assig... Remove this comment to see the full error message
                imageVal = await uploadImage(blobFile, _productId, extension, contentType, i)
            }

            // @ts-expect-error TS(2339): Property 'path' does not exist on type 'string'.
            const _image = getFirestoreImageData(i, _productTitle, _productId, imageVal.path, imageVal.src)
            _images.push(_image)
        }
        return _images
    }

    //買取商品を登録
    const handleSave = async () => {

        // 画像は必須
        if (images.length === 0) {
            dispatch(
                Common.actions.fetchAlert({
                    alerts: [{ message: "商品画像のご登録をお願いいたします。" }],
                })
            );
            window.scrollTo({ top: 0, behavior: "smooth", })
            return
        }

        setIsLoading(true)
        try {
            const appraisalDetails = [
                { title: 'condition', value: getValues().condition },
                { title: 'stockNumber', value: getValues().stock_number },
                { title: 'productSize', value: getValues().productSize },
                { title: 'quantity', value: getValues().quantity },
                { title: 'hasReciept', value: getValues().hasReciept === 'true' },
                { title: 'hasAccessory', value: getValues().hasAccessory === 'true' },
                { title: 'accessoryDetail', value: getValues().accessoryDetail },
                { title: 'modelYear', value: getValues().modelYear },
                { title: 'buyYear', value: getValues().buyYear },
                { title: 'status', value: getValues().status },
                { title: 'category', value: getValues().category },
            ]
            const productName = getValues().productName

            // 1. ProductIdを作成
            const productId = await createProductId()
            if (!productId) throw new Error('ProductIdの作成に失敗しました。')

            // 2. 画像Storageに登録 [商品ID-index.type]で登録
            const _images = await uploadImageToStorage(productName, productId)

            // 3.firestoreに登録
            const jsReleaseDateStr = getValues().status === 'active' ? new Date().toLocaleString('ja') : null
            const jsUpdatedAtStr = new Date().toLocaleString('ja')
            const _firestoreProduct = {
                close: true,
                highest_bid_ticket: null,
                vendor_url: '',
                draft_status: getValues().status,
                id: productId,
                insurance_fee_name: "",
                is_authorization: null,
                is_featured: null,
                is_production: false,
                product_type: '買取',
                release_date: jsReleaseDateStr,
                status: 'draft',
                tags: [getValues().category,],
                title: productName,
                updated_at: jsUpdatedAtStr,
                user_id: currentUser.uid,
                userId: currentUser.uid,
                userEmail: currentUser.email,
                user_email: currentUser.email,
                image: _images[0].src ? _images[0].src : null,
                images: _images,
                description: getValues().description,
                appraisalDetails,
            }
            // console.log('Creating product data: ', _firestoreProduct)
            const createFirestoreProduct = CloudFunctions.httpsCallable('createFirestoreProduct')
            const resp = await createFirestoreProduct({ product: _firestoreProduct })
            if (resp.data.status === 'failed') {
                throw new Error(`Failed to CreateFirestoreProduct`)
            }

            // Slackに通知
            if (getValues().status === 'active') {
                const postSlackMessage = CloudFunctions.httpsCallable("postSlackMessage")
                const text = `【買取出品】\n商品名: ${productName} \n-> https://dropp.jp/appraisal/${_firestoreProduct.id}`
                try {
                    // Droppに通知
                    const memberText = amanoSlackMemberId
                    const droppText = memberText + '\n' + text
                    await postSlackMessage({ text: droppText, conversationId: DroppNoticeChannelId })
                    // SSCに通知
                    const sscMemberText = sekiSlackMemberId
                    const sscText = sscMemberText + '\n' + text
                    await postSlackMessage({ text: sscText, conversationId: sscChannelId })
                    // パームナッツに通知
                    const palmnutsMemberText = yonaSlackMemberId + inuiSlackMemberId
                    const palmnutsText = palmnutsMemberText + '\n' + text
                    await postSlackMessage({ text: palmnutsText, conversationId: PalmnutsChannelId })
                } catch (e) {
                    console.error(e)
                }
            }

            // 5.戻る
            setIsLoading(false)
            history.push('/appraisal')
            dispatch(
                Common.actions.fetchAlert({
                    alerts: [{ message: "商品登録が完了しました。" }],
                })
            );

        } catch (e) {
            setIsLoading(false)
            console.log('error', e)
            dispatch(
                Common.actions.fetchAlert({
                    alerts: [{ message: "商品登録が失敗しました。" }],
                })
            )
            setIsLoading(false)
        }
    }

    // 画像を削除する
    const handleImageRemove = (index: any) => {
        const newImages = images.filter((_, i) => i !== index)
        // 改めてBolbのURLを作成する
        const formatedImages = newImages.map((image, i) => {
            return Object.assign(image, {
                src: URL.createObjectURL(image),
                uploadType: "upload",
                position: i,
                provider: null,
            })
        })
        setImages(formatedImages)
    }

    // 画像が選択された時の処理
    const { getRootProps, getInputProps } = useDropzone({
        onDrop: (acceptedFiles: any) => {
            if (files.length > 0) acceptedFiles.push(...files) // 既存の画像に追加
            // const currentImages = images
            const newImages = acceptedFiles.map((file: any, index: any) => Object.assign(file, {
                src: URL.createObjectURL(file),
                uploadType: "upload",
                position: images.length + index,
                provider: null,
            }))
            // @ts-expect-error TS(2345): Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
            setImages([...images, ...newImages])
        },
        accept: {
            'image/jpeg': [],
            'image/png': [],
        }
    })

    return (
        <Container className={classes.container}>

            <div className={classes.titleWrapper}>
                <span onClick={() => history.push('/appraisal')} style={{ width: '68px', display: 'flex', color: 'dimgray' }}>
                    <ArrowBackIcon />
                    &nbsp;戻る
                </span>
                <Typography variant='h1' className={classes.title}>査定登録</Typography>
                <div style={{ width: '68px' }}></div>
            </div>

            <div>
                <form onSubmit={handleSubmit(handleSave)}>

                    <Typography variant="subtitle2" className={classes.imageInputLabel}>商品画像</Typography>

                    <div {...getRootProps({ className: 'dropzone' + ' ' + classes.dropzone })}>
                        <input {...getInputProps()} />
                        <p style={{ fontSize: '14px', textAlign: 'center' }}>
                            画像をドラッグ & ドロップ<br />または、<br />こちらをクリックしてアップロードしてください。<br /><br />
                            ※JPGかPNG形式の画像のみ。
                        </p>
                    </div>

                    <div className={classes.previewWrapper}>
                        {images.length > 0 && images.map((image, i) => (
                            // @ts-expect-error TS(2339): Property 'imageWrapper' does not exist on type 'Cl... Remove this comment to see the full error message
                            <div key={i} className={classes.imageWrapper}>
                                <img
                                    // @ts-expect-error TS(2322): Type 'number' is not assignable to type 'string'.
                                    id={i}
                                    // @ts-expect-error TS(2339): Property 'name' does not exist on type 'never'.
                                    key={image.name}
                                    // @ts-expect-error TS(2339): Property 'src' does not exist on type 'never'.
                                    src={image.src}
                                    className={classes.previewImage}
                                    // @ts-expect-error TS(2339): Property 'src' does not exist on type 'never'.
                                    onLoad={() => { URL.revokeObjectURL(image.src) }}
                                />
                                <CloseIcon className={classes.imageRemoveIcon} onClick={() => handleImageRemove(i)} />
                            </div>
                        ))}
                    </div>

                    <div className={classes.formItem}>
                        <label className={classes.inputLabel} htmlFor='productName'>商品名<span className={classes.requiredBadge}>&nbsp;※必須</span></label>
                        <input
                            className={classes.input}
                            id="productName"
                            {...register('productName', {
                                required: {
                                    value: true,
                                    message: '入力をお願いいたします。'
                                }
                            })} />
                        <p className={classes.errorMessage}>{errors.productName?.message}</p>
                    </div>

                    <div className={classes.formItem}>
                        <label className={classes.inputLabel} htmlFor='stock_number'>品番<span className={classes.requiredBadge}>&nbsp;※必須</span></label>
                        <input
                            className={classes.input}
                            id="stock_number"
                            {...register('stock_number', {
                                required: {
                                    value: true,
                                    message: '入力をお願いいたします。'
                                }
                            })} />
                        <p className={classes.errorMessage}>{errors.stock_number?.message}</p>
                    </div>

                    <div className={classes.formItem}>
                        <label className={classes.inputLabel} htmlFor='description'>
                            商品の説明
                            <span className={classes.requiredBadge}>&nbsp;※必須</span>
                        </label>
                        <textarea
                            className={classes.input}
                            rows={4}
                            id="description"
                            {...register('description', {
                                required: {
                                    value: true,
                                    message: '入力をお願いいたします。'
                                }
                            })} />
                        <p className={classes.errorMessage}>{errors.description?.message}</p>
                    </div>

                    <Grid container spacing={3}>
                        <Grid item xs={6} md={6} className={classes.formItem}>
                            <label className={classes.inputLabel} htmlFor='condition'>
                                商品の状態
                                <span className={classes.requiredBadge}>&nbsp;※必須</span>
                            </label>
                            <input
                                className={classes.input}
                                id="condition"
                                placeholder='例）新品、未使用品、中古品'
                                {...register('condition', {
                                    required: {
                                        value: true,
                                        message: '入力をお願いいたします。'
                                    }
                                })} />
                            <p className={classes.errorMessage}>{errors.condition?.message}</p>
                        </Grid>

                        <Grid item xs={6} md={6} className={classes.formItem}>
                            <label className={classes.inputLabel} htmlFor='productSize'>
                                商品のサイズ
                                <span className={classes.requiredBadge}>&nbsp;※必須</span>
                            </label>
                            <input
                                className={classes.input}
                                id="productSize"
                                placeholder='例）縦: 100cm, 横: 100cm, 高さ: 100cm'
                                {...register('productSize', {
                                    required: {
                                        value: true,
                                        message: '入力をお願いいたします。'
                                    }
                                })} />
                            <p className={classes.errorMessage}>{errors.productSize?.message}</p>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item xs={6} md={6} className={classes.formItem}>
                            <label className={classes.inputLabel} htmlFor='category'>
                                カテゴリー
                                <span className={classes.requiredBadge}>&nbsp;※必須</span>
                            </label>
                            <select
                                className={classes.input}
                                id='category'
                                {...register('category')}
                            >
                                {Object.keys(Categories).map((key) => (
                                    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                                    <option key={key} value={key}>{Categories[key]}</option>
                                ))}
                            </select>
                            <p className={classes.errorMessage}>{errors.category?.message}</p>
                        </Grid>
                        <Grid item xs={6} md={6} className={classes.formItem}>
                            <label className={classes.inputLabel} htmlFor='quantity'>
                                数量（半角数字）
                                <span className={classes.requiredBadge}>&nbsp;※必須</span>
                            </label>
                            <input
                                type='number'
                                className={classes.input}
                                id="quantity"
                                {...register('quantity', {
                                    required: {
                                        value: true,
                                        message: '入力をお願いいたします。'
                                    }
                                })} />
                            <p className={classes.errorMessage}>{errors.quantity?.message}</p>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item xs={6} md={6} className={classes.formItem}>
                            <label className={classes.inputLabel} htmlFor='hasReciept'>
                                納品書の有無
                                <span className={classes.requiredBadge}>&nbsp;※必須</span>
                            </label>
                            <select
                                className={classes.input}
                                id='hasReciept'
                                {...register('hasReciept')}
                            >
                                {/* @ts-expect-error */}
                                <option value={false}>なし</option>
                                {/* @ts-expect-error */}
                                <option value={true}>あり</option>
                            </select>
                            <p className={classes.errorMessage}>{errors.hasReciept?.message}</p>
                        </Grid>

                        <Grid item xs={6} md={6} className={classes.formItem}>
                            <label className={classes.inputLabel} htmlFor='hasAccessory'>
                                付属品の有無
                                <span className={classes.requiredBadge}>&nbsp;※必須</span>
                            </label>
                            <select
                                className={classes.input}
                                id='hasAccessory'
                                {...register('hasAccessory')}
                            >
                                {/* @ts-expect-error */}
                                <option value={false}>なし</option>
                                {/* @ts-expect-error */}
                                <option value={true}>あり</option>
                            </select>
                            <p className={classes.errorMessage}>{errors.hasAccessory?.message}</p>
                        </Grid>

                        <Grid item xs={12}>
                            <div className={classes.formItem}>
                                <label className={classes.inputLabel} htmlFor='accessoryDetail'>
                                    付属品の詳細
                                </label>
                                <textarea
                                    className={classes.input}
                                    rows={4}
                                    id="accessoryDetail"
                                    {...register('accessoryDetail')} />
                                <p className={classes.errorMessage}>{errors.accessoryDetail?.message}</p>
                            </div>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item xs={6} md={6} className={classes.formItem}>
                            <label className={classes.inputLabel} htmlFor='modelYear'>
                                商品の年式
                            </label>
                            <select
                                className={classes.input}
                                id='modelYear'
                                {...register('modelYear')}
                            >
                                {Years.map((y) => (
                                    <option key={y} value={y}>{y}</option>
                                ))}
                            </select>
                            <p className={classes.errorMessage}>{errors.modelYear?.message}</p>
                        </Grid>

                        <Grid item xs={6} md={6} className={classes.formItem}>
                            <label className={classes.inputLabel} htmlFor='buyYear'>購入時期</label>
                            <select
                                className={classes.input}
                                id='buyYear'
                                {...register('buyYear')}
                            >
                                {Years.map((y) => (
                                    <option key={y} value={y}>{y}</option>
                                ))}
                            </select>
                            <p className={classes.errorMessage}>{errors.buyYear?.message}</p>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item xs={6} md={6} className={classes.formItem}>
                            <label className={classes.inputLabel} htmlFor='status'>
                                登録ステータス
                                <span className={classes.requiredBadge}>&nbsp;※必須</span>
                            </label>
                            <select
                                className={classes.input}
                                id='status'
                                {...register('status')}
                            >
                                <option value={'draft'}>下書き</option>
                                <option value={'active'}>出品</option>
                            </select>
                            <p className={classes.errorMessage}>{errors.status?.message}</p>
                        </Grid>
                    </Grid>

                    {/* 規約同意 */}
                    <FormControlLabel
                        // @ts-expect-error TS(2339): Property 'checkbox' does not exist on type 'ClassN... Remove this comment to see the full error message
                        className={classes.checkbox}
                        // @ts-expect-error TS(2322): Type '{ onChange: () => void; checked: boolean; }'... Remove this comment to see the full error message
                        control={<CustomCheckBox onChange={handleCheckTerm} checked={isCheckedTerms} />}
                        label={
                            <div>
                                <span onClick={handleTermsViewToggle} style={{ color: "red", textDecoration: 'underline' }}>買取利用規約</span>
                                <span>に同意する</span>
                            </div>
                        }
                    />

                    <div className={classes.buttonWrapper}>
                        <Button
                            disabled={!isCheckedTerms} type='submit' value='save'
                            className={isCheckedTerms ? classes.mainButton : classes.negativeButton}>登録する</Button>
                    </div>

                </form>
            </div>

            <BackdropLoading open={isLoading} />

            <TermOfUseSlideView
                termOfUseOpen={termOfUseOpen}
                handleTermsViewClose={handleTermsViewClose}
                onShowTermsType={'買取規約'}
            />
        </Container >
    )
}

import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Button as MUIButton, CssBaseline, Dialog, DialogActions, DialogContent, DialogContentText, Typography, makeStyles, Container } from "@material-ui/core";
import { Link } from 'react-router-dom';
import { db, CloudFunctions } from "../utils/Firebase";
import common from "../redux/common/commonSlice";
import BackdropLoading from "../components/common/BackdropLoading";
import { TextField } from "../components/form/TextField";
import { useForm } from "react-hook-form";
import { validateEmail } from "../utils/validations/Email";
import { Button } from "../components/form/Button";
import { PageTitle } from "../components/common/PageTitle";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
  },
  login_text: {
    marginTop: theme.spacing(3),
    float: "right",
  },
}));

const SignUpEmail = (props: any) => {
  const [confirmFlg, setConfirmFlg] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { getValues, handleSubmit, watch, control, setValue } = useForm()
  const watchEmail = watch("email")

  const checkInputEmail = async () => {
    const email = getValues().email;
    if (!email) return;

    setIsLoading(true)
    const snapshot = await db.collection('teaser_emails').where('email', '==', email).get()
    if (!snapshot.empty) {
      const getAuthUserByEmail = CloudFunctions.httpsCallable("getAuthUserByEmail");
      const res = await getAuthUserByEmail({ email })

      if (res.data != null && res.data.email) { // 有効なユーザーが存在する場合
        dispatch(
          common.actions.fetchAlert({
            alerts: [{ message: "こちらのメールアドレスはすでに登録済みです。ログインをお願いいたします。" }],
          })
        );
        props.history.push('/signin')
      } else {
        const sendAuthEmail = CloudFunctions.httpsCallable("sendAuthEmail");
        await sendAuthEmail({ email })
        dispatch(
          common.actions.fetchAlert({
            alerts: [{ message: "認証メールを送信しました。ご確認お願いいたします。" }],
          })
        );
      }
    } else {
      setConfirmFlg(true);
    }
    setIsLoading(false)
  }

  const DialogView = () => {
    return (
      <Dialog
        open={confirmFlg}
        onClose={handleCancel}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogContent>
          <DialogContentText style={{ color: 'black' }}>
            <>{`${watchEmail} でアカウント登録の申し込みを行います。`}<br />
              よろしいでしょうか？
            </>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <>
            <MUIButton autoFocus onClick={handleCancel} style={{ color: '#ff0000' }}>いいえ</MUIButton>
            <MUIButton onClick={handleRegister} style={{ color: '#ff0000' }}>はい</MUIButton>
          </>
        </DialogActions>
      </Dialog>
    )
  }

  const handleRegister = () => {
    setConfirmFlg(false);
    handleCreateUser()
  }

  const handleCancel = () => setConfirmFlg(false);

  const handleCreateUser = async () => {
    setIsLoading(true);

    try {
      const createCustomer = CloudFunctions.httpsCallable("createCustomer");
      await createCustomer({ email: watchEmail });

      // analytics.logEvent('AUTH_signup_email', { name: "signup_email" })
      dispatch(
        common.actions.fetchAlert({
          alerts: [{ message: "認証メールを送信しました。ご確認お願いいたします。" }],
        })
      );
      setValue('email', '')
    } catch (e) {
      dispatch(
        common.actions.fetchAlert({
          alerts: [{ message: "大変申し訳ありません。時間を空けて再度ご登録お願いいたします。" }],
        })
      );
    }

    setIsLoading(false)
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <Container component="main" maxWidth="xs" className={classes.root}>
        <PageTitle title="アカウント作成" />
        <Typography variant="body2">メールが届かない場合は迷惑フィルターで仕分けされている場合がございますので、迷惑メールをご確認ください。</Typography>

        <form className={classes.form} onSubmit={handleSubmit(checkInputEmail)}>
          <TextField
            control={control}
            id='email'
            name='email'
            label="メールアドレス"
            defaultValue=''
            valudation={validateEmail}
          />
          <Button
            text='送信する'
            onClick={handleSubmit}
            disabled={false}
          />
          <span className={classes.login_text}>既にアカウント作成済みの方は<Link to="/signin">こちら</Link></span>
        </form>
        <DialogView />
        <BackdropLoading open={isLoading} />
      </Container>
    </React.Fragment>
  );
};

export default withRouter(SignUpEmail);

import firebase from 'firebase/app';
import "firebase/firestore";
import "firebase/auth";
import "firebase/functions";
import "firebase/analytics";
import "firebase/storage";
// import "firebase/messaging";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MERSUREMENT_ID
};
firebase.initializeApp(firebaseConfig);

const createTimeStamp = (_date: any) => {
    return firebase.firestore.Timestamp.fromDate(_date)
}

const auth = firebase.auth();
const db = firebase.firestore();
const storage = firebase.storage()
const analytics = firebase.analytics();
const CloudFunctions = firebase.app().functions("asia-northeast1");
// const messaging = firebase.messaging();
const TicketStatusValue = {
    'LOSE': 0,
    'VALID': 1,
    'WIN': 2,
    'PAID': 3,
    'WAITING_PAYMENT': 4,
    'ADDITIONAL_PAYMENT': 5,
    'CANCELLED': 6
}
// Buy Nowのフルフィルメント用ステータス
const PaymentStatusValue = {
    'WAITING_PAYMENT': 0,
    'AUTHORIZED': 1,
    'CAPTURE_PAYMENT': 2,
    'CANCELLED': 3,
    "PARTIALLY_CANCELLED": 4
};

// Buy Nowのフルフィルメント用ステータス
const ShippingStatusValue = {
    'PENDING': 0,
    'PARTIALLY_FULFILLED': 1,
    'FULFILLED': 2,
    'RETURNING': 3,
    'CANCELLED': 4,
    'PARTIALLY_CANCELLED': 5,
};

// const productDraftStatusValue = {
//     'draft': 0,
//     'completeDraft': 1,
//     'completeCreative': 2,
//     'testing': 3,
//     'active': 4,
//     'archived': 5,
//     'buybackWaitingSelectWinner': 10,
//     'buybackWatingDeposit': 11, // 業者からのデポジット待ち
//     'buybackWaitingShipping': 12, // 出品者の発送待ち
//     'buybackOnShipping': 13, // 出品者の発送待ち
//     'buybackOnChecking': 14, // 査定中
//     'buybackOnPaying': 15, // 送金中
//     'buybackComplete': 16, //成立
//     'buybackOnReturning': 17, // 返送中
//     'buybackCancelled': 18, // キャンセル済み
// }

const ShippingProviders = {
    'YAMATO': 0,
    'SAGAWA': 1,
    "YUPACK": 2
}

export { firebase, analytics, auth, db, CloudFunctions, storage, TicketStatusValue, PaymentStatusValue, ShippingStatusValue, ShippingProviders, createTimeStamp };
// export { firebase, auth, db, CloudFunctions, storage, TicketStatusValue, PaymentStatusValue, ShippingStatusValue, ShippingProviders, createTimeStamp };

import React, { useContext, useState, useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AuthContext } from "../contexts/Auth";
import { Button, CssBaseline, Dialog, DialogActions, DialogContent, DialogContentText, TextField, Typography, makeStyles, Container, withStyles } from "@material-ui/core";
import { CloudFunctions } from "../utils/Firebase";
import common from "../redux/common/commonSlice";
import QAListView from "../components/contact/QAListView";
import { PageTitle } from "../components/common/PageTitle";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(15),
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  input: {
    borderRadius: "0"
  },
  filter_text: {
    fontSize: ".9rem",
    marginBottom: "1rem",
  },
  email_text: {
    marginBottom: "1rem",
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  login_text: {
    marginTop: theme.spacing(3),
    float: "right",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#ff0000",
    borderRadius: '0',
    color: "#fff",
    fontWeight: 'bold',
  },
  disabled_button: {
    margin: theme.spacing(3, 0, 2),
    borderRadius: '0',
  },
  dialog_title: {
    marginBottom: ".5rem",
  },
  success_text: {
    marginTop: "50px",
  },
  top_botton: {
    marginTop: "30px",
    color: "#ff0000",
  }
}));

const CustomTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#ff0000',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '0',
      '&:hover fieldset': {
        borderColor: '#ff0000',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#ff0000',
      }
    },
  }
})(TextField);

const Contact = () => {
  const { currentUser } = useContext<any>(AuthContext);
  const [confirmFlg, setConfirmFlg] = useState(false);
  const [email, setEmail] = useState("");
  const [states, setStates] = useState({
    inputText: "",
    inputErrorMessage: "",
    sendProcess: 'input',
  })
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory()

  useEffect(() => {
    if (currentUser) {
      setEmail(currentUser.email);
    }
  }, [currentUser]);

  const blurPartEmailString = (string: any) => {
    return string.replace(/[^@]{1,4}@[^@]{1,4}/, "***@***");
  }

  const handleCancel = () => {
    setConfirmFlg(false);
  }

  const DialogView = () => {
    return <Dialog
      open={confirmFlg}
      onClose={handleCancel}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogContent>
        <DialogContentText style={{ color: 'black' }}>
          <>
            お問い合わせ内容：<br />
            {states.inputText}<br /><br />
            こちら内容で送信いたします。<br />よろしいですか？
          </>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <>
          <Button autoFocus onClick={handleCancel} style={{ color: '#ff0000' }}>いいえ</Button>
          <Button onClick={handleSubmit} style={{ color: '#ff0000' }}>はい</Button></>
      </DialogActions>
    </Dialog>;
  }

  const handleSubmit = async () => {
    setConfirmFlg(false)

    // slack通知で失敗したらinfo宛にメールを送っている
    try {
      const sendEmail = CloudFunctions.httpsCallable("sendContactMail");
      let text = `お問い合わせ先： ${email} \nお問い合わせ内容： ${states.inputText}`
      await sendEmail({ email, text })

      setStates({
        ...states,
        inputText: "",
        sendProcess: "success"
      })
    } catch (e) {
      dispatch(
        common.actions.fetchAlert({
          alerts: [{ message: "お問い合わせに失敗しました。お手数ですがinfo@dropp.jp宛にメールにてお問い合わせください。" }],
        })
      )
    }
  }

  const handleChange = (e: any) => {
    setStates({
      ...states,
      inputText: e.target.value,
      inputErrorMessage: "",
    })
  }

  const checkInput = () => {
    states.inputText ? setConfirmFlg(true) : setStates({ ...states, inputErrorMessage: "お問い合わせ内容を入力してください。" })
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <Container component="main" maxWidth="xs" className={classes.root}>
        <div className={classes.paper}>
          {states.sendProcess == 'input' &&
            <>
              <PageTitle title="お問合わせ" />
              <Typography variant="subtitle2" className={classes.filter_text}>２営業日以内にご登録されているメールアドレス宛に返信させていただきます。</Typography>
              {email &&
                <Typography component='p' className={classes.email_text}>ご登録されているメールアドレス： <b>{blurPartEmailString(email)}</b></Typography>
              }
              <CustomTextField
                variant="outlined"
                multiline
                rows={4}
                value={states.inputText}
                onChange={handleChange}
                fullWidth
                label="お問合わせ内容"
                error={states.inputErrorMessage.length > 0}
                helperText={states.inputErrorMessage}
              />
              <Button
                onClick={checkInput}
                type="submit"
                fullWidth
                variant="outlined"
                className={classes.submit}
              >
                <>送信</>
              </Button>
              <QAListView />
              <DialogView />
            </>
          }
          {states.sendProcess == 'success' &&
            <>
              <Typography variant="subtitle1" className={classes.success_text}>お問い合わせいただきありがとうございます。<br></br>返信までいましばらくお待ちください。</Typography>
              <Button className={classes.top_botton} onClick={() => history.push('/')}>TOPに戻る</Button>
            </>
          }
        </div>
      </Container>
    </React.Fragment>
  );
};

export default withRouter(Contact);

import React from 'react';
import { Container, Drawer, IconButton, List, ListItem, ListItemText, makeStyles, SvgIcon, ThemeProvider, createMuiTheme } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { ReactComponent as XMark } from "../../images/close.svg";
import SettingsIcon from "../../images/settings_icon_40px.png"
import Copyright from "../../components/Copyright";

const useStyles = makeStyles((theme) => ({
  drawer_paper: {
    width: '30%',
    paddingTop: 'env(safe-area-inset-top)',
  },
  settings_icon: {
    width: '44px',
  },
  close_button: {
    marginLeft: '0',
    marginTop: theme.spacing(1),
  },
  copy_wrapper: {
    position: 'absolute',
    bottom: '15px',
    width: '100%',
  },
}));

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      '"Arial"',
      '"ヒラギノ角ゴシック"',
      'sans-serif'
    ].join(','),
  },
})

const AdminDrawerMenu = () => {
  const classes = useStyles();
  const history = useHistory()
  const [isOpen, setIsOpen] = React.useState(false);

  const toggleDrawer = (state: any) => {
    setIsOpen(state);
  };

  const clickHandle = (e: any, path: any) => {
    e.stopPropagation()
    setIsOpen(false)
    history.push(`/admin/${path}`)
  }

  return (
    <ThemeProvider theme={theme}>
      <IconButton
        edge='start'
        onClick={() => toggleDrawer(true)}>
        <img src={SettingsIcon} className={classes.settings_icon}></img>
        <Drawer
          anchor='left'
          classes={{ paper: classes.drawer_paper }}
          open={isOpen}
          onClose={() => toggleDrawer(false)}>
          <Container maxWidth="md">
            <IconButton
              edge='start'
              onClick={(e) => { e.stopPropagation(); toggleDrawer(false); }}
              className={classes.close_button}>
              <SvgIcon component={XMark} viewBox="0 0 1000 900" />
            </IconButton>
            <List dense>
              <ListItem button onClick={(e) => clickHandle(e, "")}>
                <ListItemText primary='TOP' />
              </ListItem >
              <ListItem button onClick={(e) => clickHandle(e, "users")}>
                ・<ListItemText primary='USERS' />
              </ListItem>
              {/* <ListItem button onClick={(e) => clickHandle(e, 'products')}>
                ・<ListItemText primary='PRODUCTS' />
              </ListItem> */}
              {/* <ListItem button onClick={(e) => clickHandle(e, 'buyback')}>
                ・<ListItemText primary='買取査定一覧' />
              </ListItem> */}
              <ListItem button onClick={(e) => clickHandle(e, 'orders')}>
                ・<ListItemText primary='ORDERS' />
              </ListItem>
              {/* <ListItem button onClick={(e) => clickHandle(e, "products")}>
                  <ListItemText primary='商品情報' />
                </ListItem> */}
            </List >
          </Container >
          <div className={classes.copy_wrapper}>
            <Copyright />
          </div>
        </Drawer >
      </IconButton >
    </ThemeProvider >
  );

};

export default AdminDrawerMenu
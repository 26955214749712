import React, { memo, useContext, useState } from 'react';
import { Container, Drawer, IconButton, makeStyles, ThemeProvider, Button, FormGroup, FormControlLabel, Checkbox, withStyles } from '@material-ui/core';
import { createMuiTheme } from "@material-ui/core/styles";
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import { SortValueContext } from '../contexts/Sort';

const useStyles = makeStyles((theme) => ({
    drawer_paper: {
        width: '90%',
        maxWidth: "500px",
        paddingTop: 'env(safe-area-inset-top)',
    },
    open_icon: {
        position: "absolute",
        right: "70px",
        [theme.breakpoints.up('md')]: {
            right: "130px",
        }
    },
    container: {
        overflow: "hidden",
        padding: "0",
        height: "100vh",
    },
    content_wrapper: {
        padding: '0 12px',
    },
    search_wrapper: {
        marginTop: '24px',
    },
    button_wrapper: {
        position: 'absolute',
        width: '100%',
        boxSizing: 'border-box',
        bottom: '20px',
        display: 'flex',
        justifyContent: 'space-around',
    },
    clear_button: {
        width: '45%',
        border: '1px solid gray',
        borderRadius: '0',
    },
    search_button: {
        width: '45%',
        borderRadius: '0',
        color: 'white',
        backgroundColor: "#ff0000",
        fontWeight: 'bold',
    }
}));

const theme = createMuiTheme({})

type CheckBoxType = {
    checked: boolean,
    onChange: any,
    name: string,
}
const CustomCheckBox = withStyles({
    root: {
        color: "gray",
        '&$checked': {
            color: "#ff0000",
        },
    },
    checked: {},
})((props: CheckBoxType) => <Checkbox color="default" {...props} />);

const SortView = memo(() => {
    const classes = useStyles();
    const [isSortViewOpen, setIsSortViewOpen] = useState(false)
    const { sortValue, setSortValue, setIsOnSorting } = useContext<any>(SortValueContext)
    const [states, setStates] = useState({
        ...sortValue
    })

    const toggleDrawer = (state: any, e: any) => {
        e.stopPropagation();
        setIsSortViewOpen(state);
    };

    const handleClear = () => {
        setStates({
            category1: {
                sneaker: false,
                alcohol: false,
                apparel: false,
                car: false,
                interior: false,
                art: false,
            },
            category2: {}
        })
    }

    const handleSearch = (e: any) => {
        setIsOnSorting(true)
        toggleDrawer(false, e)
        setSortValue(states)
        setTimeout(() => {
            setIsOnSorting(false)
        }, 200);
    }

    const handleChange = (e: any) => {
        const { name, checked } = e.target
        setStates({
            category1: {
                ...states.category1,
                [name]: checked,
            },
            category2: {
                ...states.category2,
            }
        })
    }

    return (
        <ThemeProvider theme={theme}>
            <IconButton
                className={classes.open_icon}
                edge='start'
                onClick={(e) => toggleDrawer(true, e)}>
                <SearchIcon />
                <Drawer
                    anchor={'left'}
                    classes={{ paper: classes.drawer_paper }}
                    open={isSortViewOpen}
                    onClose={(e) => toggleDrawer(false, e)}
                >
                    <Container maxWidth="sm" className={classes.container}>
                        <div className={classes.content_wrapper}>
                            <IconButton
                                edge='start'
                                onClick={(e) => toggleDrawer(false, e)}
                            >
                                <CloseIcon />
                            </IconButton>
                            <div className={classes.search_wrapper}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<CustomCheckBox checked={states.category1.alcohol} onChange={handleChange} name="alcohol" />}
                                        label="アルコール"
                                    />
                                    <FormControlLabel
                                        control={<CustomCheckBox checked={states.category1.sneaker} onChange={handleChange} name="sneaker" />}
                                        label="スニーカー"
                                    />
                                    <FormControlLabel
                                        control={<CustomCheckBox checked={states.category1.car} onChange={handleChange} name="car" />}
                                        label="クルマ"
                                    />
                                    <FormControlLabel
                                        control={<CustomCheckBox checked={states.category1.apparel} onChange={handleChange} name="apparel" />}
                                        label="アパレル"
                                    />
                                    <FormControlLabel
                                        control={<CustomCheckBox checked={states.category1.art} onChange={handleChange} name="art" />}
                                        label="アート"
                                    />
                                    <FormControlLabel
                                        control={<CustomCheckBox checked={states.category1.interior} onChange={handleChange} name="interior" />}
                                        label="インテリア"
                                    />
                                </FormGroup>
                            </div>
                        </div>
                        <div className={classes.button_wrapper}>
                            <Button onClick={handleClear} className={classes.clear_button}>クリア</Button>
                            <Button onClick={handleSearch} className={classes.search_button}>検索</Button>
                        </div>
                    </Container>
                </Drawer>
            </IconButton >
        </ThemeProvider>
    );
});

export default SortView;
import { Typography } from "@material-ui/core";

const TermOfUseText = () => {
	return (
		<>
			<Typography variant="h4" component="h1" style={{ fontSize: "1.3rem", lineHeight: "2em", margin: "40px", textAlign: "center", color: "grey" }}>利用規約</Typography>
			<div style={{ textAlign: 'center', marginTop: '0px' }}>第１章 総則</div><br />

			<div style={{ color: 'grey' }}>第１条 本規約の適用範囲及び変更</div><br />
			<div style={{ textAlign: 'left' }}>
				<ol style={{ margin: '0', paddingLeft: '1rem' }}>
					<li>この規約（以下、「本規約」といいます。）は、株式会社Dropp（以下、「当社」といいます。）が運営するウェブサイト「Dropp」（以下、「本サイト」といいます。）において提供するサービス（以下、「本サービス」といいます。）の利用条件を定めるものです。</li><br />
					<li>本規約は、本サービスの利用に関して、会員（第４条で定義します。以下同じです。）及び当社に適用されます。</li><br />
					<li>当社は、以下の各号の何れかに該当する場合、当社の裁量により、本規約を変更することができます。
						<ul style={{ margin: '0', paddingLeft: '1rem', listStyleType: 'disc' }}>
							<li>本規約の変更が、会員の一般の利益に適合する場合</li>
							<li>本規約の変更が、契約をした目的に反せず、かつ、変更の必要性、変更後の内容の相当性、変更の内容その他の変更に係る事情に照らして合理的なものである場合</li>
						</ul>
					</li><br />
					<li>当社は前項による本規約の変更にあたり、変更後の本規約の効力発生日までの相当期間、本規約を変更する旨及び変更後の本規約の内容とその効力発生日を当社ウェブサイトに掲示その他当社が別途定める方法により、会員に対して通知します。</li><br />
					<li>変更後の本規約の効力発生日以降に会員が本サービスを利用した場合、会員は、変更後の本規約に同意したものとみなします。</li><br />
					<li>本規約の全部又は一部が変更された場合、本サービスの利用に関して、変更後の本規約のみが会員及び当社に適用され、会員及び当社は変更後の本規約のみに従うものとします。</li><br />
				</ol>
			</div><br /><br /><br />

			<div style={{ color: 'grey' }}>第２条 本サービスの利用</div><br />
			<div style={{ textAlign: 'left' }}>
				<ol style={{ margin: '0', paddingLeft: '1rem' }}>
					<li>会員は、法令、本規約及び当社が別途定めるプライバシーポリシーに従い、本サービスを利用するものとします。</li><br />
					<li>未成年、成年被後見人、被保佐人及び被補助人の会員は、法定代理人によって操作されていないもしくは法定代理人の事前の同意を得ていなかった場合は、本サービスを利用することができません。</li><br />
				</ol>
			</div><br /><br /><br />

			<div style={{ color: 'grey' }}>第３条 本サービスの内容</div><br />
			<div style={{ textAlign: 'left' }}>会員は、本サービスにおいて以下の各号に規定する行為ができます。<br /><br />
				<ol style={{ margin: '0', paddingLeft: '1rem' }}>
					<li>当社所定の方法に従い、当社が実施する商品の抽選に応募すること</li><br />
					<li>当社所定の方法に従い、当社から商品を購入すること</li><br />
					<li>会員登録情報機能を利用すること（「会員登録情報機能」とは、会員が本サービスを利用するにあたり登録した情報をいいます。）</li><br />
					<li>その他、当社が本サイトを通じて会員向けに提供するサービスを利用すること</li><br />
				</ol>
			</div><br /><br /><br /><br />

			<div style={{ textAlign: 'center' }}>第２章 会員</div><br />

			<div style={{ color: 'grey' }}>第４条 会員</div><br />
			<div style={{ textAlign: 'left' }}>本規約において「会員」とは、本規約に同意の上、本規約に従って本サービスの会員登録をした者をいいます。但し、第19条に定める「会員」には、本規約に同意の上、本規約に従って本サービスの会員登録をした者のみならず、第三者も含むものとします。</div><br /><br /><br />

			<div style={{ color: 'grey' }}>第５条 会員登録</div><br />
			<div style={{ textAlign: 'left' }}>
				<ol style={{ margin: '0', paddingLeft: '1rem' }}>
					<li>本サービスの会員登録を希望する者（以下、「会員登録希望者」といいます。）は、本規約に同意した上で、本サイトの会員登録ページから、当社が指定する方法に従い、自らのメールアドレス及びパスワードを設定及び入力し、また、本サービスにより商品を購入する際には、自らの氏名、住所及び電話番号も設定及び入力するものとします。未成年、成年被後見人、被保佐人及び被補助人は、法定代理人によって設定及び入力されていないもしくは法定代理人の事前の同意を得ていなかった場合は、登録申請ができないものとします。</li><br />
					<li>パソコン、タブレット端末及びスマートフォンから登録申請がなされた場合、当社は、会員登録希望者が設定及び入力したメールアドレスに登録確認メールを送信し、当該メールが受領され、当該メールに記載されたアドレスを会員登録希望者が押した時点で登録申請を承認するものとします。</li><br />
					<li>前項の規定に拘わらず、当社は、以下の各号の何れかに該当することが判明した場合、登録申請を承認しないことがあります。</li><br />
					<ul style={{ margin: '0', paddingLeft: '1rem', listStyleType: 'disc' }}>
						<li>会員登録希望者が、当社が現在提供する又は過去に提供していたサービスに関する規約に違反したこと又はその他の事由により、会員登録の取消その他の処分を受けていた場合</li>
						<li>会員登録希望者が登録申請に際して当社に提供した内容に、虚偽の事項が含まれていた場合</li>
						<li>会員登録希望者が、当社が現在提供する又は過去に提供していたサービスに関して、料金その他の支払をしていないこと（当社が別途認める支払方法に従って第三者に対して料金その他の支払をしていないことを含みますが、これに限りません。）又は正当な理由なく長期間にわたる商品の受領不能、返品若しくは交換の拒絶その他の債務不履行をしていた場合</li>
						<li>会員登録希望者が、過去に第19条第1項に規定する行為をした場合</li>
						<li>会員登録希望者が、第20条第1項に反した場合</li>
						<li>その他登録申請を承認することが本サービスの運営又は管理上、不適当であると当社が合理的に判断する場合</li>
					</ul>
				</ol>
			</div><br /><br /><br />

			<div style={{ color: 'grey' }}>第６条 登録内容の変更</div><br />
			<div style={{ textAlign: 'left' }}>
				<ol style={{ margin: '0', paddingLeft: '1rem' }}>
					<li>会員は、当社に登録している内容の全部又は一部に変更が生じた場合、当社が指定する方法により、直ちに登録内容を変更するものとします。</li><br />
					<li>当社は、会員が前項に従って登録内容を変更しなかったことに起因又は関連して、会員に生じた損害、損失、費用及び不利益（以下、「損害等」といいます。）について、損害賠償、補償、補填その他の責任を負いません。</li><br />
				</ol>
			</div><br /><br /><br />

			<div style={{ color: 'grey' }}>第７条 本サービスの利用停止及び会員登録の取消等</div><br />
			<div style={{ textAlign: 'left' }}>
				<ol style={{ margin: '0', paddingLeft: '1rem' }}>
					<li>当社は、会員が以下の各号の何れかに該当することが判明した場合、当該会員に事前に通知又は催告することなく、当該会員に対する本サービスの利用停止、会員登録の取消その他当社が適当と考える措置を講じることができるものとします。
						<ul style={{ listStyleType: 'disc', margin: '0', paddingLeft: '1rem' }}>
							<li>会員が、当社が現在提供する又は過去に提供していたサービスに関する規約に違反をしたこと又はその他の事由により、会員登録の取消その他の処分を受けていた場合</li>
							<li>会員が当社に登録した内容に、虚偽の事項が含まれていた場合</li>
							<li>会員が、当社が現在提供する又は過去に提供していたサービスに関して、金員の支払をしていないこと（当社が別途認める支払方法に従って第三者に対して金員の支払をしていないことを含みますが、これに限りません。）又は正当な理由なく長期間にわたる商品の受取り不能、返品若しくは交換の拒絶その他の債務不履行をしていた場合</li>
							<li>会員が、本規約に違反（軽微な違反を含みます。）した場合（第19条第1項及び第20条第1項の違反を含みますが、これらに限りません。）</li>
							<li>その他会員が当社の定める何らかの規約に違反した場合</li>
						</ul>
					</li><br />
					<li>会員は、前項各号に規定する場合が本サービスを利用できない重大な事由に該当することを確認するものとします。</li><br />
				</ol>
			</div><br /><br /><br />

			<div style={{ color: 'grey' }}>第８条 退会手続</div><br />
			<div style={{ textAlign: 'left' }}>会員は、当社が定める手続を経て、いつでも退会することができます。会員は、本規約に従って当社が会員からの退会申請を受領した時点で会員資格を喪失するものとします。</div><br /><br /><br />

			<div style={{ color: 'grey' }}>第９条ログイン情報の管理</div><br />
			<div style={{ textAlign: 'left' }}>
				<ol style={{ margin: '0', paddingLeft: '1rem' }}>
					<li>会員は、自己の責任において、本サービスのログインに用いる自己メールアドレス及びパスワード（以下、「ログイン情報」といいます。）を厳重に管理及び保管するものとします。</li><br />
					<li>会員は、自己のログイン情報を第三者に利用させ又は譲渡、売買、承継、貸与、開示若しくは漏洩してはならないものとします。</li><br />
					<li>会員は、自己のログイン情報が第三者によって不正に使用されていることが判明した場合、直ちに当社に連絡するものとし、当社の指示がある場合は当該指示に従うものとします。</li><br />
					<li>本サイトへのアクセスに関連して送信されたログイン情報が、会員登録申請時又は会員登録後のログイン情報変更時に当社に登録したログイン情報と同一である場合、当社は、当該会員からの送信とみなします。</li><br />
					<li>会員は、自己のログイン情報の管理不十分、使用上の過誤又は不手際、第三者の無断使用その他本条の違反に起因又は関連して会員に生じた損害等につき自ら責任を負うものとし、当社は損害賠償、補償、補填その他の責任を負いません。</li><br />
				</ol>
			</div><br /><br /><br />

			<div style={{ color: 'grey' }}>第１０条 個人情報の取扱い</div><br />
			<div style={{ textAlign: 'left' }}>当社は、当社が別途定める「プライバシーポリシー」等に従って、本サービスの利用に関連して当社が知り得た会員の個人情報を取り扱うものとします。</div><br /><br /><br /><br />

			<div style={{ textAlign: 'center' }}>第３章 商品の抽選・購入</div><br />

			<div style={{ color: 'grey' }}>第１１条 商品の購入</div><br />
			<div style={{ textAlign: 'left' }}>
				<ol style={{ margin: '0', paddingLeft: '1rem' }}>
					<li>会員は、本サービスにより商品の抽選を希望する場合、当社が別途指定する方法に従って、商品の抽選に申込むものとします。</li><br />
					<li>会員は、本サービスにより商品の購入を希望する場合、当社が別途指定する方法に従って、商品の購入を申込むものとします。</li><br />
					<li>第１項及び第２項の申込みに伴い、会員が入力及び登録した配達先及び注文内容等を確認の上で本サイトにおいて抽選に応募する旨又は商品を購入する旨のボタンをクリックした時点で、会員と当社との間に当該商品に関する売買契約が成立するものとします。</li><br />
					<li>前項の規定に拘わらず、本サービスの利用に関して会員に不正又は不適当な行為、本規約の違反（軽微な違反を含みます。）があった場合（第19条第1項及び第20条第1項の違反を含みますが、これらに限りません。）、当社は、売買契約について取消、解除、損害賠償請求その他当社が適当と考える措置を講じることができるものとします。</li><br />
					<li>本サービスによる商品の配送は、日本国内に限ります。</li><br />
				</ol>
			</div><br /><br /><br />

			<div style={{ color: 'grey' }}>第１２条 支払方法</div><br />
			<div style={{ textAlign: 'left' }}>
				<ol style={{ margin: '0', paddingLeft: '1rem' }}>
					<li>商品の代金は、商品の価格（消費税を含みます。）及び商品の販売に係る取扱手数料の合計となります。</li><br />
					<li>商品の代金の支払方法は、会員本人名義のクレジットカードによる支払又は当社が別途認める支払方法に限ります。</li><br />
					<li>クレジットカードによる支払の場合、会員は、会員がクレジットカード会社との間で別途契約する条件に従うものとします。なお、クレジットカードの利用に関連して、会員とクレジットカード会社の間で何らかの紛争が発生した場合、会員は、自己の責任において、当該紛争を解決するものとします。</li><br />
				</ol>
			</div><br /><br /><br />

			<div style={{ color: 'grey' }}>第１３条 所有権の移転</div><br />
			<div style={{ textAlign: 'left' }}>商品の所有権及び危険負担は、当社が商品を配送業者に引き渡した時点で、会員に移転するものとします。</div><br /><br /><br />

			<div style={{ color: 'grey' }}>第１４条 商品の返品、交換並びに注文の撤回及び取消し</div><br />
			<div style={{ textAlign: 'left' }}>
				<ol style={{ paddingLeft: '1rem', margin: '0' }}>
					<li>当社は、以下の各号の何れかに該当する場合に限り、商品の返品を受け付けます。
						<ul style={{ listStyleType: 'disc', margin: '0', paddingLeft: '1rem' }}>
							<li>商品が模造品又は海賊版であることが判明した場合</li>
							<li>商品の品質が契約内容に適合しない場合（当該商品の品質に関する説明に明記されていない破損がある場合、又は本サイトにおける販売ページ上の当該商品の品質に関する説明と異なる場合をいいます。）</li>
							<li>ご注文内容と異なる種類又は数の商品が到着した場合</li>
						</ul>
					</li><br />
					<li>会員は、当社が別途定める手順に従って返品の申請を行うものとします。前項第(1)号から第(3)号に該当する場合、返送にかかる費用は当社が負担し、当社は、会員が購入した時点の返品対象商品の販売価格、会員が購入した際にかかる配送料及び各種手数料を返還するか、又は代品に交換します。なお、代品への交換を希望された場合も、商品の欠品その他の事由により交換できないことがあります。複数の商品を注文し、その一部を返品する場合においては、前項第(1)号から第(3)号のいずれによる場合であっても、会員が購入した際にかかる配送料及び各種手数料は返金いたしません。</li><br />
					<li>当社は、会員が正当な理由なく商品を当社に返送した場合（第1項各号に定める場合に該当しない商品の返品、受領拒否及び受領不能を含みますが、これらに限りません。）、当該商品を受領後遅滞なく会員に対して通知し、保管期限（受領後１ヶ月）を定めた上で当該商品の受け取りにつき会員に対して指示を求めるものとします。なお、当社は、会員から商品の受け取りに関する指示を受けた場合、当該商品について現状有姿で引渡すものとし、当該商品の状態（商品の変質、変形、消耗、毀損及び腐敗を含みますが、これらに限りません。）について、責任を負わないものとします。ただし、保管期限を過ぎたものは会員の同意を得ることなく処分できるものとする。</li><br />
					<li>当社は、前項の相当の期間内に会員から指示がない場合、会員により当該商品の所有権その他の権利が放棄されたものとして、廃棄その他の方法により当該商品を任意に処分できるものとします。当社は、当該処分に関して会員に対して責任を負いません。</li><br />
					<li>会員が当社に返送した商品に会員の私物（現金、クレジットカードその他カード類及び鍵を含みますが、これらに限りません。）が同梱されていた場合、受領後遅滞なく会員に対して通知し、相当の期間を定めた上で当該私物の受け取りにつき会員に対して指示を求めるものとします。当社は、会員から当該私物の受け取りに関する指示を受けた場合、会員の費用負担によって、当該私物を現状有姿で引渡すものとし、配送中及び当社での保管中に紛失、破損、汚損等したとしても、当社は責任を負いません。なお、当社は、相当の期間内に会員から指示がない場合、会員により当該私物の所有権その他の権利が放棄されたものとして、廃棄その他の方法により当該私物を任意に処分できるものとします。当社は、当該処分に関して会員に対して責任を負いません。</li><br />
				</ol>
			</div><br /><br /><br />

			<div style={{ color: 'grey' }}>第１５条 商品に関する免責</div><br />
			<div style={{ textAlign: 'left' }}>
				<ol style={{ margin: '0', paddingLeft: '1rem' }}>
					<li>本サービスを通じて販売される商品の品質、材質、機能、性能、他の商品との適合性その他の欠陥に関する当社の責任は、前条に定めるものに限られるものとします。</li><br />
					<li>当社は、会員が登録した連絡先に連絡すること及び商品購入の際に指定された配達先に商品を配送することにより、商品の引渡債務を履行し、当該債務から免責されるものとします。</li>
				</ol>
			</div><br /><br /><br /><br />

			<div style={{ textAlign: 'center' }}>第４章 サービスの運用</div><br />

			<div style={{ color: 'grey' }}>第１６条 情報の管理</div><br />
			<div style={{ textAlign: 'left' }}>
				<ol style={{ margin: '0', paddingLeft: '1rem' }}>
					<li>当社は、その裁量により、当社及び当社と提携するサイトにおいて、本サービスに関連して会員が発信した画像、テキスト、デザイン、ロゴ、映像、プログラム、アイディアその他一切の情報（以下、「コンテンツ」といいます。）の全部又は一部を自由に利用できるものとします。当社は、当該コンテンツを利用するにあたり、会員に対して金銭その他一切の対価の支払を要しないものとします。</li><br />
					<li>当社は、会員のアクセス履歴及び利用状況の調査、会員へのサービス向上その他当社が別途定めるCookieポリシーに定める利用目的のために、以下の各号に規定する情報を収集します。<br />
						<ul style={{ margin: '0', listStyleType: 'disc', paddingLeft: '1rem' }}>
							<li>会員が本サービスのサーバーにアクセスする際のIPアドレス又は携帯端末の機体識別番号に関する情報</li>
							<li>当社が、Cookieの技術（Webブラウザを通じてユーザーのコンピューターに一時的にデータを書き込んで会員が最後にサイトを訪れた日時、そのサイトの訪問回数等を記録保存する技術をいいます。）を通じて取得する会員のアクセス情報</li>
							<li>会員は、会員がWebブラウザでCookieを拒否するための設定を行った場合、本サービスの利用が制限される場合があることを予め同意するものとします。</li>
						</ul>
					</li><br />
				</ol>
			</div><br /><br /><br />

			<div style={{ color: 'grey' }}>第１７条 サービスの停止又は中止</div><br />
			<div style={{ textAlign: 'left' }}>
				当社は、以下の各号の何れかに該当する場合、会員に事前に通知又は催告をすることなく、本サイト及び本サービスの提供の全部又は一部を停止又は中止できるものとします。<br /><br />
				<ol style={{ margin: '0', paddingLeft: '1rem' }}>
					<li>火災、地震、洪水、落雷、大雪その他の天変地異が生じた場合</li><br />
					<li>戦争、内乱、テロ、暴動、騒擾その他の社会不安が生じた場合</li><br />
					<li>当社が契約している電話会社、運送会社又はプロバイダから適切なサービスを受けられなかった場合</li><br />
					<li>当社が技術的に対応不可能な事由が生じた場合</li><br />
					<li>本サービス提供のためのコンピューターシステム（以下、「システム」といいます。）の定期保守及び緊急保守の場合</li><br />
					<li>システムの不良及び第三者からの不正アクセス、コンピューターウィルスの感染等により、システムの運用が困難になった場合</li><br />
					<li>行政機関又は司法機関から相当な根拠に基づき要請された場合</li><br />
					<li>その他やむを得ずシステムの停止又は中止が必要であると当社が判断した場合</li><br />
				</ol>
			</div><br /><br /><br /><br />

			<div style={{ textAlign: 'center' }}>第５章 一般条項</div><br />

			<div style={{ color: 'grey' }}>第１８条 本サービスに関する免責事項</div><br />
			<div style={{ textAlign: 'left' }}>
				<ol style={{ paddingLeft: '1rem', margin: '0' }}>
					<li>当社は、本サービスから他のウェブサイト若しくはリソースへのリンク又は第三者のウェブサイト若しくはリソースから本サービスへのリンクを提供している場合、当該リンク先又はリンク元のウェブサイト又はリソースの内容、利用及びその結果（適法性、有効性、正確性、確実性、安全性、最新性及び完全性を含みますが、これらに限りません。）について、何ら保証せず責任を負いません。当社は、リンク先のウェブサイト又はリソースの内容が、法令違反又は本サービスの管理若しくは運営上不適切であると合理的に判断した場合、会員に何らの通知又は催告をすることなく、当該リンクを削除できるものとします。</li><br />
					<li>本サービス中に掲載されている広告（懸賞広告を含みますが、これに限りません。）又は宣伝を経由して、会員と広告主又は宣伝主との間において取引（懸賞等のプロモーションへの参加を含みますが、これに限りません。）が行われる場合、会員は、自らの判断と責任により当該取引を行うものとし、当社は責任を負いません。当該取引にかかる代金の支払、契約条件の決定、保証、担保責任、ライセンスの有無その他の内容及び条件について、当社は何ら保証せず責任を負いません。</li><br />
					<li>第17条各号の何れかに該当する場合その他不可抗力によって本サービスが停止又は中止されたことに関して、当社は責任を負いません。</li><br />
					<li>当社は、会員が登録した内容に従って事務を処理することにより、当社の債務を履行し免責されるものとします。</li><br />
					<li>会員が、本サービスを利用することにより、他の会員又は第三者に対して損害等を与えた場合、当該会員は自らの責任と費用において解決するとともに、当社に損害等を与えないものとします。</li><br />
					<li>当社は、当社の責めに帰すべき事由により本サービスに起因又は関連して会員に生じた損害等について、当社に故意又は重過失がある場合を除き、会員に現実に生じた通常かつ直接の範囲の損害等に限り、代金相当額を限度として責任を負うものとします。</li><br />
				</ol>
			</div><br /><br /><br />

			<div style={{ color: 'grey' }}>第１９条 禁止事項</div><br />
			<div style={{ textAlign: 'left' }}>
				<ol style={{ margin: '0', paddingLeft: '1rem' }}>
					<li>会員は、以下の各号に該当する行為を行ってはならないものとします。
						<ul style={{ margin: '0', listStyleType: 'disc', paddingLeft: '1rem' }}>
							<li>他の会員、他の会員以外の第三者又は当社に迷惑若しくは損害等を与える行為又はそれらのおそれのある行為</li>
							<li>他の会員、他の会員以外の第三者又は当社の著作権その他の知的財産権、肖像権、人格権、プライバシー権、パブリシティ権その他の権利を侵害する行為又はそれらのおそれのある行為</li>
							<li>本サービスを商業目的で利用する行為（但し、当社が予め認めたものは除きます。）</li>
							<li>公序良俗に反する行為その他法令に違反する行為又はそれらのおそれのある行為</li>
							<li>虚偽又は誤解を招くような内容を含む情報を登録する行為</li>
							<li>当社が本サービスで提供するコンテンツを会員が私的使用の範囲外で使用する行為</li>
							<li>他の会員又は他の会員以外の第三者を介して、本サービスを通じて入手したコンテンツを複製、販売、出版、頒布、公開その他これらに類似する行為</li>
							<li>他の会員の個人情報を収集、蓄積又は保存する行為</li>
							<li>コンピューターのソフトウェア、ハードウェア又は通信機器の機能を妨害、破壊又は制限するように設計されたコンピューターウィルス、コンピューターコード、ファイル又はプログラムを本サービスにアップロード又はメール等の手段で送信する行為</li>
							<li>クローリング（クローラ、ロボット又はスパイダー等のプログラム）、スクレイピング及びその他の類似の手段によって本サービスにアクセスし、又は本サービスに関する情報を取得する行為</li>
							<li>同一会員が複数の会員登録をする行為（パソコン、携帯電話又はスマートフォンの何れかから別々に登録する行為も含みますが、これに限りません。）</li>
							<li>過度に注文をキャンセル又は商品を返品する行為</li>
							<li>当社の信用を毀損又は失墜させる行為その他当社が不適当であると合理的に判断する行為</li>
						</ul>
					</li><br />
					<li>会員は、前項の違反（軽微な違反を含みます。）に起因又は関連して当社又は第三者に生じた損害等を全て賠償するものとします。</li><br />
				</ol>
			</div><br /><br /><br />

			<div style={{ color: 'grey' }}>第２０条 反社会勢力の排除</div><br />
			<div style={{ textAlign: 'left' }}>
				<ol style={{ margin: '0', paddingLeft: '1rem' }}>
					<li>会員登録希望者及び会員は、当社に対して、以下の各号の事項を確約するものとします。
						<ul style={{ margin: '0', paddingLeft: '1rem', listStyleType: 'disc' }}>
							<li>自らが、暴力団、暴力団関係企業、総会屋若しくはこれらに準ずる者又はその構成員（以下、「反社会勢力」といいます。）ではないこと。</li>
							<li>会員登録希望者及び会員が法人の場合、自らの役員（業務を執行する社員、取締役、執行役又はこれらに準ずる者をいいます。）が反社会勢力ではないこと。</li>
							<li>反社会勢力に自己の名義を利用させ、又は反社会勢力の利益に供するために本サービスを利用するものではないこと。</li>
							<li>自ら又は第三者を利用して、当社に対する脅迫的な言動又は暴力を用いる行為や、偽計又は威力を用いて当社の業務を妨害し又は信用を毀損する行為を行わないこと。</li>
						</ul>
					</li><br />
					<li>当社は、会員登録希望者又は会員が前項に違反した場合、当該会員登録希望者又は会員に何らの通知又は催告なく、当該会員登録希望者又は会員との商品の売買契約の解除及び会員登録の抹消その他本規約に定める措置を講じることができるものとします。</li><br />
					<li>前項の措置に起因又は関連して当該会員登録希望者又は会員に生じた損害等について、当社は損害賠償、補償、補填その他の責任を負いません。また、当該会員登録希望者又は会員は、第1項に違反したことに起因又は関連して当社に生じた損害等を賠償するものとします。</li><br />
				</ol>
			</div><br /><br /><br />

			<div style={{ color: 'grey' }}>第２１条 知的財産権</div><br />
			<div style={{ textAlign: 'left' }}>
				<ol style={{ margin: '0', paddingLeft: '1rem' }}>
					<li>本サービスを通じて提供されるコンテンツの著作権その他の知的財産権は、全て当社及びコンテンツ提供者に専属的に帰属するものとします。</li><br />
					<li>目的の如何を問わず、会員によるコンテンツの無断複製、無断転載その他の無断二次利用行為その他の国内外の著作権法を含む法令により禁止される行為が発見された場合、当社は直ちに法的措置をとるものとします。</li><br />
					<li>本条の規定に違反して会員と第三者との間で何らかの紛争が生じた場合、会員はその責任と費用において解決するとともに、当社に損害等を与えないものとします。また、万が一、当社に損害等が生じた場合、会員は、当該損害等を全て賠償するものとします。</li><br />
				</ol>
			</div><br /><br /><br />

			<div style={{ color: 'grey' }}>第２２条 分離可能性</div><br />
			<div style={{ textAlign: 'left' }}>本規約の何れかの条項の全部又は一部が無効又は違法となった場合であっても、当該無効又は違法は、如何なる意味でも本規約の他の条項並びにその解釈及び適用に何ら影響せず、これらの適法性及び有効性を損なわず、またこれらを無効にするものではありません。</div><br /><br /><br />

			<div style={{ color: 'grey' }}>第23条 譲渡禁止</div><br />
			<div style={{ textAlign: 'left' }}>会員は、当社の書面による事前の承諾を得た場合を除き、本規約に基づく権利義務又は債権債務の全部又は一部を第三者に譲渡、移転、承継、担保提供その他の処分をしてはならないものとします。</div><br /><br /><br />

			<div style={{ color: 'grey' }}>第24条 その他</div><br />
			<div style={{ textAlign: 'left' }}>
				<ol style={{ margin: '0', paddingLeft: '1rem' }}>
					<li>本サービスに関して、本規約又は当社の指導若しくは対応により解決できない問題が生じた場合、当社と会員との間で双方誠実に話し合い、これを解決するものとします。</li><br />
					<li>本規約の準拠法は日本法とします。</li><br />
					<li>本規約に関して発生する紛争については、東京地方裁判所を第一審の専属合意管轄裁判所とします。</li><br />
				</ol>
			</div><br /><br /><br />

			<div style={{ textAlign: 'center' }}>付則：本規約は2021年5月12日からすべての会員に適用されるものとします。</div>
		</>
	)
}

export default TermOfUseText;
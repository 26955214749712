import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ProductInput from '../components/product/ProductInput'
import { db } from '../utils/Firebase'
import { Product } from '../types/product'

type Params = {
    product_id: string
}

const ProductsUpdate = () => {
    const { product_id } = useParams<Params>()
    const [product, setProduct] = useState<Product | null>(null)

    useEffect(() => {
        const _getProduct = async () => {
            await db.collection('products').doc(product_id).get().then(async doc => {
                const product = doc.data()
                // @ts-expect-error
                setProduct(product)
            })
        }
        _getProduct()
    }, [])

    if (product) {
        return (<ProductInput product={product} />)
    } else {
        return (<></>)
    }
}

export default ProductsUpdate
export const insuranceProfiles = {
  "ヤマト運輸美術品輸送": {
    500000: 4000,
    800000: 5000,
    1000000: 10000,
    2000000: 15000,
    3000000: 15000,
  }
}

export const getInsuranceFee = (_amount: any, _insuranceFeeName: any) => {
  let insurance_fee = 0
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  Object.keys(insuranceProfiles[`${_insuranceFeeName}`]).forEach(key => {
    if (key < _amount) {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      insurance_fee = insuranceProfiles[`${_insuranceFeeName}`][key]
    }
  })
  return insurance_fee
}
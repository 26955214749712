export const BidAmounts = [
  1000,1100,1200,1300,1400,1500,1600,1700,1800,1900,2000,2200,2400,2600,
  2800,3000,3200,3400,3600,3800,4000,4200,4400,4600,4800,5000,5500,6000,
  6500,7000,7500,8000,8500,9000,9500,10000,11000,12000,13000,14000,15000,
  16000,17000,18000,19000,20000,22000,24000,26000,28000,30000,32000,34000,
  36000,38000,40000,42000,44000,46000,48000,50000,55000,60000,65000,70000,
  75000,80000,85000,90000,95000,100000,110000,120000,130000,140000,150000,
  160000,170000,180000,190000,200000,220000,240000,260000,280000,300000,
  320000,340000,360000,380000,400000,420000,440000,460000,480000,500000,
  550000,600000,650000,700000,750000,800000,850000,900000,950000,1000000,
  1100000,1200000,1300000,1400000,1500000,1600000,1700000,1800000,1900000,
  2000000,2200000,2400000,2600000,2800000,3000000,3200000,3400000,3600000,
  3800000,4000000,4200000,4400000,4600000,4800000,5000000,5500000,6000000,
  6500000,7000000,7500000,8000000,8500000,9000000,9500000,10000000,11000000,
  12000000,13000000,14000000,15000000,16000000,17000000,18000000,19000000,
  20000000,22000000,24000000,26000000,28000000,30000000,32000000,34000000,
  36000000,38000000,40000000,42000000,44000000,46000000,48000000,50000000,
  55000000,60000000,65000000,70000000,75000000,80000000,85000000,90000000,
  95000000,100000000,110000000,120000000,130000000,140000000,150000000,
  160000000,170000000,180000000,190000000,200000000,220000000,240000000,
  260000000,280000000,300000000,320000000,340000000,360000000,380000000,
  400000000,420000000,440000000,460000000,480000000,500000000,550000000,
  600000000,650000000,700000000,750000000,800000000,850000000,900000000,
  950000000,1000000000,1100000000,1200000000,1300000000,1400000000,1500000000,
  1600000000,1700000000,1800000000,1900000000,2000000000,2200000000,2400000000,
  2600000000,2800000000,3000000000,3200000000,3400000000,3600000000,3800000000,
  4000000000,4200000000,4400000000,4600000000,4800000000,5000000000,5500000000,
  6000000000,6500000000,7000000000,7500000000,8000000000,8500000000,9000000000,
  9500000000,10000000000,
]

export const getBiddableArrey = (_current_amount: any) => {
  let _current_index = BidAmounts.indexOf(_current_amount)
  let _biddable_arrey = []
  for (let i = 1; i < 100; i++ ) {
    _biddable_arrey.push(BidAmounts[_current_index + i])
  }
  return _biddable_arrey
}

export const getBiddableArreyWithCurrentAmount = (_current_amount: any) => {
  let _current_index = BidAmounts.indexOf(_current_amount)
  let _biddable_arrey = []
  for (let i = 0; i < 100; i++ ) {
    _biddable_arrey.push(BidAmounts[_current_index + i])
  }
  return _biddable_arrey
}

export const getNextAmount = (_current_amount: any) => {
  let _current_index = BidAmounts.indexOf(_current_amount)
  return BidAmounts[_current_index + 1]
}
import React, { useContext, useEffect, useState } from 'react'
import { Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, FormControl, InputAdornment, InputLabel, makeStyles, MenuItem, Select, TextField, Typography } from '@material-ui/core'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CloudFunctions, storage } from '../../utils/Firebase';
import { AuthContext } from '../../contexts/Auth';
import { useHistory, useParams } from 'react-router-dom';
import Common from '../../redux/common/commonSlice';
import { useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import BackdropLoading from '../../components/common/BackdropLoading'

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { auctionTemplate, buynowTemplate, lotteryTemplate } from '../../utils/templates';
import {
  BairdBeerChannelId,
  BluenoiseChannelId,
  DesignChannelId,
  DesignerId,
  DroppDevelopChannelId,
  DroppOperationChannelId,
  HashimotoId,
  MCMChannelId,
  PalmnutsChannelId,
  SscChannelId,
  StylesChannelId,
  TatenokawaChannelId,
  TheWineConcordeClubChannelId,
  WineLuxuryChannelId,
  XLargeChannelId,
  AAndKChanelId,
  kanazawaSlackMemberId
} from '../../utils/Notify';
import { generateProductVariantId, vendors } from '../../utils/Common';
import { useProduct } from '../../hooks/useProduct';

const adminSiteUrl = 'http://dropp.jp'

const useStyles = makeStyles(() => ({
  contaienr: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "80px",
  },
  info_container: {
    padding: "10px 20px",
    marginBottom: "20px",
    borderRadius: "10px",
    backgroundColor: "whitesmoke",
  },
  page_title: {
    textAlign: "center",
    fontSize: "18px",
    marginTop: "130px",
    marginBottom: "30px",
  },
  title: {
    textAlign: "center",
    fontSize: "18px",
    marginTop: "20px",
  },
  mt30: {
    marginTop: "30px",
  },
  rich_text: {
    height: "400px",
  },
  datetime: {
    width: "200px",
    padding: "10px",
    boxSizing: "border-box",
    textAlign: "center",
  },
  active_button: {
    borderColor: "#ff0000",
    color: "#ff0000",
  },
  option_input_area: {
    padding: "10px 10px",
  },
  // 画像インプット
  dropzone: {
    width: "90%",
    margin: "10px auto",
    padding: "0 10px",
    height: "200px",
    border: "1px dashed gray",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "whitesmoke",
  },
  preview_wrapper: {
    paddingTop: "20px",
    display: "flex",
    flexWrap: "wrap",
  },
  preview_image: {
    display: 'block',
    width: '180px',
    height: '180px',
    marginRight: "30px",
    marginBottom: "30px",
  },
  image_remove_icon: {
    position: "relative",
    left: "-30px",
    top: "-20px",
  },
  confirm_item: {
    display: 'flex',
    fontSize: '0.8rem',
  },
  confirm_item_title: {
    width: '130px',
    color: "gray",
  },
  confirm_item_view: {},
  confirm_item_desc: {}
}))

const getChannelId = (_vendor: any) => {
  let _channelID = ''
  switch (_vendor) {
    case "Dropp":
    case "株式会社inspiration":
    case "Droppパートナー":
      _channelID = DroppOperationChannelId
      break;
    case "PALM NUTS CO.":
      _channelID = PalmnutsChannelId
      break;
    case "Mid - Century MODERN":
      _channelID = MCMChannelId
      break;
    case "THE CONCORDE WINE CLUB":
      _channelID = TheWineConcordeClubChannelId
      break;
    case "Wine Luxury":
      _channelID = WineLuxuryChannelId
      break;
    case "XLARGE":
      _channelID = XLargeChannelId
      break;
    case "合資会社べアードブルーイング":
      _channelID = BairdBeerChannelId
      break;
    case "楯の川酒造株式会社":
      _channelID = TatenokawaChannelId
      break;
    case "BLUE NOISE LTD.":
      _channelID = BluenoiseChannelId
      break;
    case "株式会社SSC":
      _channelID = SscChannelId
      break;
    case "Styles":
      _channelID = StylesChannelId
      break;
    case "A&K":
      _channelID = AAndKChanelId
      break
    default:
      break;
  }
  return _channelID
}

// 登録ステータスによってSlackへの通知場所を分けている。
const notifySlackMessage = async (_states: any, _product: any) => {
  const func = CloudFunctions.httpsCallable('postSlackMessage')

  try {
    // 下書き登録完了
    if (_states.status === 'completeDraft') {
      const textForVendor = `商品の下書き登録が完了しました。${_product.title}\n${adminSiteUrl}/products/${_product.id}`
      const channelId = getChannelId(_product.vendor)
      console.log('channelId', channelId)
      await func({ conversationId: channelId, text: textForVendor })
      const textForDisigner = `${DesignerId}\nクリエイティブ依頼がございました。\n${_product.title}\n${adminSiteUrl}/products/${_product.id}`
      await func({ conversationId: DesignChannelId, text: textForDisigner })

      // クリエイティブ作成完了・承認待ち
    } else if (_states.status === 'completeCreative') {
      const textForDesigner = `${HashimotoId}\nクリエイティブ作成が完了しました。\n${_product.title}\n${adminSiteUrl}/products/${_product.id}`
      await func({ conversationId: DroppOperationChannelId, text: textForDesigner })

      // 承認完了・本番反映
    } else if (_states.status === 'active') {
      let inventoryQuantityText = ''
      _product.variants.forEach((_variant: any) => {
        inventoryQuantityText += `在庫数${_variant.option1 === 'Default Title' ? '' : `(${_variant.option1})`}: ${_variant.inventory_quantity}\n`
      })
      const textForVendor = `商品のDroppが確定しました。${_product.title}\n開始日${_states.releaseDate.toLocaleString('ja')} \n終了日${_states.deadlineDate.toLocaleString('ja')}\n` + inventoryQuantityText
      const channelId = getChannelId(_product.vendor)
      await func({ conversationId: channelId, text: textForVendor })

      // 修正承認待ち
    } else if (_states.status === 'waitingFix') {
      const textForDropp = `${HashimotoId}${kanazawaSlackMemberId}【変更依頼】${_product.title}\n${adminSiteUrl}/products/${_product.id}`
      await func({ conversationId: DroppOperationChannelId, text: textForDropp })
    }
  } catch (e) {
    console.error('error', e)
  }
}


type Params = {
  product_id: string
}

const ProductInput = (props: any) => {
  const classes = useStyles()
  const history = useHistory()
  const { product } = props
  const { product_id } = useParams<Params>()
  const dispatch = useDispatch()
  const { teaserEmail } = useContext<any>(AuthContext)
  const [loading, setLoading] = useState(false)
  const [states, setStates] = useState({
    status: "",
    title: "",
    productType: "",
    price: "",
    vendor: "",
    priceErrorMessage: "",
    inventoryQuantity: "",
    inventoryQuantityErrorMessage: "",
    auctionStartPrice: "",
    auctionStartPriceErrorMessage: "",
    auctionFeeErrorMessage: "",
    reserve_price: null,
    reserve_priceErrorMessage: '',
    auctionFee: 15,
    hasOptions: false,
    optionName: "",
    options: [
      { variationName: "", variationPrice: 0, variationQuantity: 0, variationNameErrorMessage: "", variationPriceErrorMessage: "", variationQuantityErrorMessage: "" }
    ],
    variants: [{
      "option1": "",
      "price": "",
      "inventory_quantity": "",
      "inventory_management": "shopify",
      "weight": "",
    }],
    defaultVariant: { price: "", inventory_quantity: "" },
    category1: null,
    category1ErrorMessage: "",
    category2: null,
    category2ErrorMessage: "",
    weight: 5,
    shippingFee: "",
    releaseDate: null,
    titleErrorMessage: "",
    showcaseDate: "",
    deadlineDate: "",
    productTypeErrorMessage: "",
    shippingFeeErrorMesssage: "",
    is_banner_view: false,
    is_featured: false,
    is_authorization: true,
    expected_winning_bid_amount: "",
    expected_winning_bid_amountErrorMessage: "",
    insurance_fee_name: "",
    bid_interval: "",
    bidIntervalErrorMessage: "",
    authorization_limit: "",
    authorizationLimitErrorMessage: "",
    notion_page_id: "",
    shipping_date: null,
    requestedShippingDate: null,
    requestVariants: [{ id: '', quantity: null }],
    created_at: null,
    updated_at: null,
  })
  const [description, setDescription] = useState("")
  const files: any = []
  const categories = { "sneaker": "スニーカー", "alcohol": 'アルコール', "apparel": 'アパレル', "car": 'クルマ', "interior": 'インテリア', "art": 'アート' }
  const statuses = { 'draft': "下書き", 'completeDraft': "下書き完了（承認待ち）", 'completeCreative': "クリエイティブ完了", 'testing': "テスト適用", 'active': "本番適用", 'archvied': "アーカイブ", 'waitingFix': '本番適用後Fix待ち' }
  const [isLoading, setIsLoading] = useState(true)
  const { createProductId } = useProduct()

  useEffect(() => {
    setTimeout(() => {
      if (!teaserEmail?.vendor) {
        // history.push('/products')
      }
      setIsLoading(false)
    }, 0);
  }, [])

  const checkCategoryTags = (productTags: any) => {
    let tag = null
    if (productTags.length > 0) {
      productTags.forEach((t: any) => {
        Object.keys(categories).forEach(category => {
          if (t == category) {
            tag = t
          }
        })
      })
    }
    return tag
  }

  const [images, setImages] = useState([])
  useEffect(() => {
    if (!product || product.images.length === 0) return
    setImages(product.images.map((image: any, index: any) => {
      return {
        id: image.id,
        src: image.src,
        uploadType: "current",
        position: index,
        provider: image.provider ? image.provider : null,
        path: image.path ? image.path : null,
      }
    }))
  }, [product])

  // 更新ページでは商品情報が渡される
  useEffect(() => {
    if (!product) return
    console.log('product', product)
    const hasOptions = product.variants[0].option1 !== "Default Title"
    setStates({
      ...states,
      id: product.id,
      status: product.draft_status,
      title: product.title,
      productType: product.product_type,
      price: hasOptions ? "" : product.variants[0].price,
      vendor: product.vendor,
      category1: checkCategoryTags(product.tags),
      category2: null, // 最初は小カテゴリーはなし
      inventoryQuantity: hasOptions ? "" : product.variants[0].inventory_quantity,
      auctionStartPrice: product.product_type === 'オークション' ? product.variants[0].price : "", //要修正
      auctionFee: (product.auction_fee || product.auction_fee === 0) ? product.auction_fee : 15,
      hasOptions,
      reserve_price: product.reserve_price,
      optionName: hasOptions ? product.options[0].name : "",
      variants: product.variants,
      shippingFee: product.shipping_fee_name,
      // weight: product.variants[0].weight,
      weight: 5,
      // @ts-expect-error TS(2322): Type 'string | Date' is not assignable to type 'nu... Remove this comment to see the full error message
      releaseDate: product.release_date ? new Date(product.release_date.seconds * 1000) : "",
      // @ts-expect-error TS(2322): Type 'string | Date' is not assignable to type 'st... Remove this comment to see the full error message
      showcaseDate: product.showcase_date ? new Date(product.showcase_date.seconds * 1000) : "",
      // @ts-expect-error TS(2322): Type 'string | Date' is not assignable to type 'st... Remove this comment to see the full error message
      deadlineDate: product.deadline_date ? new Date(product.deadline_date.seconds * 1000) : "",
      is_featured: product.is_featured,
      is_banner_view: product.is_banner_view,
      is_authorization: product.is_authorization,
      expected_winning_bid_amount: product.expected_winning_bid_amount,
      insurance_fee_name: product.insurance_fee_name,
      bid_interval: product.bid_interval,
      authorization_limit: product.authorization_limit,
      notion_page_id: product.notion_page_id,
      // @ts-expect-error TS(2322): Type 'Date | null' is not assignable to type 'null... Remove this comment to see the full error message
      shipping_date: product.shipping_date ? new Date(product.shipping_date.seconds * 1000) : null,
      // @ts-expect-error TS(2322): Type 'Date | null' is not assignable to type 'null... Remove this comment to see the full error message
      created_at: product.created_at ? new Date(product.created_at.seconds * 1000) : null,
      // @ts-expect-error TS(2322): Type 'Date | null' is not assignable to type 'null... Remove this comment to see the full error message
      updated_at: product.updated_at ? new Date(product.updated_at.seconds * 1000) : null,
    })

  }, [product])

  const checkIsEditing = (_inputType: any) => {
    // Droppはいつでも編集可能
    if (teaserEmail.vendor == 'Dropp') return true
    if (history.location.pathname === '/product/new') return true
    if (!product) return true
    if (product.draft_status === 'draft' || product.draft_status === 'completeDraft' || product.draft_status === 'completeCreative') return true

    const now = new Date()
    const deadlineDate = new Date(product.deadline_date.seconds * 1000)
    const limitChangeRequestTime = new Date(deadlineDate.getFullYear(), deadlineDate.getMonth(), deadlineDate.getDate() - 1, 16) // 前日の16時が締切
    // 編集が可能なのはDropp終了の前日16時まで
    if (limitChangeRequestTime < now) false

    //時間, 登録ステータス
    switch (_inputType) {
      case 'requestedVariants':
        return true
      case 'requestedShippingDate':
        return true
      default:
        return false
    }

  }

  const WineDescTemplate = () => {
    return (
      `<p><strong>【商品説明】</strong></p>` +
      '<p><br></p>' +
      '<p><strong>【商品詳細】</strong></p>' +
      '<p>商品名: </p>' +
      '<p>生産: </p>' +
      '<p>容量: </p>'
    )
  }

  const DefaultDescTemplate = () => {
    return (
      `<p><strong>【商品説明】</strong></p>` +
      '<p><br></p>' +
      '<p><strong>【商品詳細】</strong></p>' +
      '<p>商品名: </p>' +
      '<p>制作年: </p>' +
      '<p>コンディション: </p>'
    )
  }

  useEffect(() => {
    if (!teaserEmail?.vendor) return
    if (!product?.description) {
      if (teaserEmail.vendor === 'Wine Luxury' || teaserEmail.vendor === 'THE CONCORDE WINE CLUB' || teaserEmail.vendor === '楯の川酒造株式会社') {
        setDescription(WineDescTemplate)
      } else {
        setDescription(DefaultDescTemplate)
      }
    } else {
      setDescription(product.description)
    }
  }, [teaserEmail, product])

  const handleInputChange = (e: any) => {
    const { name, value } = e.target
    setStates({ ...states, [name]: value })
  }

  const handleNumberInputChange = (e: any) => {
    const { name, value } = e.target
    const arrow0 = ['inventory_quantity', 'inventoryQuantity', 'auctionStartPrice', 'auctionFee']
    if (arrow0.includes(name)) {
      if (/^[1-9]+[0-9]*$/.test(value) || value === '0' || value === '') { // 半角数字か空白
        setStates({ ...states, [name]: value })
      }
    } else {
      if (/^[1-9]+[0-9]*$/.test(value) || value === '') {
        setStates({ ...states, [name]: value })
      }
    }
  }

  const handleInputDescript = (e: any) => {
    setDescription(e)
  }

  const handleOptionChange = (e: any) => {
    setStates({ ...states, hasOptions: e.target.value })
  }

  const handleSelectChange = (e: any) => {
    const { name, value } = e.target
    setStates({ ...states, [name]: value })
  }

  const handleDatePick = (e: any, name: any) => {
    setStates({ ...states, [name]: new Date(e) })
  }

  const getOptionsAndVariants = (_productId: any) => {
    let _options = []
    let _variants = []

    if (states.hasOptions) {

      _variants = states.variants.map(variant => {
        return {
          price: Number(variant.price),
          inventory_quantity: Number(variant.inventory_quantity),
          old_inventory_quantity: Number(variant.inventory_quantity),
          title: variant.option1,
          option1: variant.option1,
          option2: null,
          option3: null,
          admin_graphql_api_id: null,
          barcode: null,
          compare_at_price: null,
          created_at: new Date(new Date().getTime() - 3600 * 9 * 1000),
          fulfillment_service: "manual",
          grams: states.weight * 1000,
          // @ts-expect-error TS(2339): Property 'id' does not exist on type '{ option1: s... Remove this comment to see the full error message
          id: variant.id ? variant.id : generateProductVariantId(),
          image_id: null,
          inventory_item_id: null,
          inventory_management: "shopify",
          inventory_policy: "deny",
          position: 1,
          product_id: _productId,
          requires_shipping: true,
          sku: "",
          taxable: true,
          updated_at: new Date(new Date().getTime() - 3600 * 9 * 1000),
          weight: states.weight,
          weight_unit: "kg",

        }
      })
      const values = states.variants.map(v => v.option1)
      const option = {
        name: states.optionName === 'その他' ? otherOptionName : states.optionName,
        values: values,
        position: 1,
        product_id: _productId,
      }
      _options.push(option)

    } else {
      const variant = {
        admin_graphql_api_id: null,
        barcode: null,
        compare_at_price: null,
        created_at: new Date(new Date().getTime() - 3600 * 9 * 1000),
        fulfillment_service: "manual",
        grams: states.weight * 1000,
        id: null,
        image_id: null,
        inventory_item_id: null,
        inventory_management: "shopify",
        inventory_policy: "deny",
        inventory_quantity: states.productType === 'オークション' ? 1 : states.inventoryQuantity,
        old_inventory_quantity: states.productType === 'オークション' ? 1 : states.inventoryQuantity,
        option1: "Default Title",
        option2: null,
        option3: null,
        position: 1,
        price: states.productType === 'オークション' ? states.auctionStartPrice : states.price,
        product_id: _productId,
        requires_shipping: true,
        sku: "",
        taxable: true,
        title: "Default Title",
        updated_at: new Date(new Date().getTime() - 3600 * 9 * 1000),
        weight: states.weight,
        weight_unit: "kg",
      }
      const option = {
        name: 'Title',
        position: 1,
        product_id: _productId,
        values: ['Default Title']
      }
      _variants.push(variant)
      _options.push(option)

    }

    return { options: _options, variants: _variants }

  }

  const getExtensionFromURL = (_url: any) => {
    let extension
    if (_url.indexOf('.jpeg') !== -1) {
      extension = 'jpeg'
    } else if (_url.indexOf('.jpg') !== -1) {
      extension = 'jpg'
    } else if (_url.indexOf('.png') !== -1) {
      extension = 'png'
    } else if (_url.indexOf('.gif') !== -1) {
      extension = 'gif'
    } else {
      extension = 'jpg'
    }
    return extension
  }

  const getContentType = (_extension: any) => {
    let type = ''
    switch (_extension) {
      case 'jpeg':
      case 'jpg':
        type = 'image/jpeg'
        break;
      case 'png':
        type = 'image / png'
        break;
      case 'gif':
        type = ' image / gif'
        break;
    }
    return type
  }

  const getBlob = async (_url: any) => {
    let blob = null
    await fetch(_url).then(async r => {
      blob = await r.blob()
    }).catch(() => {
      blob = null
    })
    return blob
  }

  const uploadImage = async (file: any, productId: any, extension: any, contentType: any, index: any) => {
    let src = ''
    let path = ''
    await storage.ref(`/product-images/${productId}-${Number(index) + 1}.${extension}`).put(file, { contentType }).then(async snapshot => {
      await snapshot.ref.getDownloadURL().then(res => {
        src = res
        path = `/product-images/${productId}-${Number(index) + 1}.${extension}`
      }).catch(() => { })
    })
    return { src, path }
  }

  const getFirestoreImageData = (i: any, productTitle: any, productId: any, path: any, src: any) => {
    return {
      admin_graphql_api_id: null,
      alt: productTitle + (Number(i) + 1),
      created_at: new Date(),
      height: 1800,
      id: productId + String(Number(i + 1)),
      position: (Number(i) + 1),
      product_id: productId,
      src: src,
      updated_at: new Date(),
      variant_ids: [],
      width: 1800,
      provider: 'storage',
      path: path,
    }
  }

  // Storageに画像を保存
  const uploadImages = async (_productTitle: any, _productId: any) => {
    console.log('images', images)
    const _images = []

    // 画像ごとをStorageにアップロード
    for (const [i, image] of Object.entries(images)) {
      let imageVal = ''
      // @ts-expect-error TS(2339): Property 'uploadType' does not exist on type 'neve... Remove this comment to see the full error message
      if (image.uploadType === 'current') {
        // @ts-expect-error TS(2339): Property 'src' does not exist on type 'never'.
        const extension = getExtensionFromURL(image.src)
        const contentType = getContentType(extension)
        // @ts-expect-error TS(2339): Property 'src' does not exist on type 'never'.
        const blobFile = await getBlob(image.src)
        // @ts-expect-error TS(2322): Type '{ src: string; path: string; }' is not assig... Remove this comment to see the full error message
        imageVal = await uploadImage(blobFile, _productId, extension, contentType, i)
      } else { // File upload
        // @ts-expect-error TS(2339): Property 'type' does not exist on type 'never'.
        const contentType = image.type
        // @ts-expect-error TS(2339): Property 'path' does not exist on type 'never'.
        const extension = image.path.split('.').pop()
        const blobFile = image
        // @ts-expect-error TS(2322): Type '{ src: string; path: string; }' is not assig... Remove this comment to see the full error message
        imageVal = await uploadImage(blobFile, _productId, extension, contentType, i)
      }

      // @ts-expect-error TS(2339): Property 'path' does not exist on type 'string'.
      const _image = getFirestoreImageData(i, _productTitle, _productId, imageVal.path, imageVal.src)
      _images.push(_image)
    }
    return _images
  }

  // 画像をStorageから削除
  const deleteImages = async () => {
    for (const image of images) {
      // @ts-expect-error TS(2339): Property 'provider' does not exist on type 'never'... Remove this comment to see the full error message
      if (image.provider === 'storage') {
        try {
          // @ts-expect-error TS(2339): Property 'path' does not exist on type 'never'.
          await storage.ref(image.path).delete()
        } catch (e) {
          console.error(e)
        }
      }
    }
  }

  // 更新時は画像をすべてアップロードし直す。
  const replaceProductImages = async () => {
    const imagesData = []
    // 1.全ての既存のblobを取得しておく
    for (const [i, image] of Object.entries(images)) {
      console.log('image', image, i)
      // @ts-expect-error TS(2339): Property 'uploadType' does not exist on type 'neve... Remove this comment to see the full error message
      if (image.uploadType === 'current') {
        // @ts-expect-error TS(2339): Property 'src' does not exist on type 'never'.
        const extension = getExtensionFromURL(image.src)
        const contentType = getContentType(extension)
        // @ts-expect-error TS(2339): Property 'src' does not exist on type 'never'.
        const blobFile = await getBlob(image.src)
        const data = {
          blob: blobFile,
          // @ts-expect-error TS(2339): Property 'type' does not exist on type 'never'.
          type: image.type,
          // @ts-expect-error TS(2339): Property 'path' does not exist on type 'never'.
          path: image.path,
          extension,
          contentType,
        }
        imagesData.push(data)
      } else { // File upload
        // @ts-expect-error TS(2339): Property 'path' does not exist on type 'never'.
        const extension = image.path.split('.').pop()
        const contentType = getContentType(extension)
        const data = {
          blob: image,
          // @ts-expect-error TS(2339): Property 'type' does not exist on type 'never'.
          type: image.type,
          // @ts-expect-error TS(2339): Property 'path' does not exist on type 'never'.
          path: image.path,
          extension: extension,
          contentType: contentType,
        }
        imagesData.push(data)
      }
    }

    // 2. productの画像を削除(Storageのみ) product.images
    for (const image of product.images) {
      try {
        if (image.provider === 'storage') await storage.ref(image.path).delete()
      } catch (e) {
        console.error(e)
      }
    }

    // 3. 全てのBlobをアップロード
    let imagesVal = []
    for (const [i, data] of Object.entries(imagesData)) {
      try {
        const imageVal = await uploadImage(data.blob, product.id, data.extension, data.contentType, i)
        imagesVal.push(imageVal)
      } catch (e) {
        console.error(e)
      }
    }
    return imagesVal

  }

  const getInputProductData = (_product: any) => {
    // @ts-expect-error TS(2554): Expected 0 arguments, but got 1.
    const jsShowcaseDateStr = states.showcaseDate ? states.showcaseDate.toLocaleString('ja') : null
    // @ts-expect-error TS(2339): Property 'toLocaleString' does not exist on type '... Remove this comment to see the full error message
    const jsReleaseDateStr = states.releaseDate ? states.releaseDate.toLocaleString('ja') : null
    // @ts-expect-error TS(2554): Expected 0 arguments, but got 1.
    const jsDeadlineDateStr = states.deadlineDate ? states.deadlineDate.toLocaleString('ja') : null
    // @ts-expect-error TS(2339): Property 'toLocaleString' does not exist on type '... Remove this comment to see the full error message
    const jsShippingDateStr = states.shipping_date ? states.shipping_date.toLocaleString('ja') : null
    const jsUpdatedAtStr = new Date().toLocaleString('ja')
    const jsCreatedAtStr = new Date().toLocaleString('ja')
    // @ts-expect-error TS(2339): Property 'toLocaleString' does not exist on type '... Remove this comment to see the full error message
    const jsRequestedShippingDateStr = states.requestedShippingDate ? states.requestedShippingDate.toLocaleString('ja') : null
    const DroppSelcetVendor = (teaserEmail.vendor === 'Dropp' && states.vendor) ? states.vendor : 'Dropp'

    return {
      auction_fee: states.productType === 'オークション' ? states.auctionFee : null,
      authorization_limit: states.productType === 'オークション' ? Number(states.authorization_limit) : null,
      auto_extension_time_flg: !!_product.auto_extension_time_flg,
      bid_history: _product.bid_history ? _product.bid_history : [],
      description: description,
      current_bid_amount: _product.current_bid_amount ? _product.current_bid_amount : null,
      bid_interval: states.productType === 'オークション' ? Number(states.bid_interval) : null,
      close: (states.status === 'archived'),
      // created_at: jsCreatedAtStr,
      deadline_date: jsDeadlineDateStr,
      expected_winning_bid_amount: states.productType === 'オークション' ? states.expected_winning_bid_amount : '',
      reserve_price: states.reserve_price,
      highest_bid_ticket: _product.highest_bid_ticket ? _product.highest_bid_ticket : null,
      vendor_url: '',
      subtitle: '',
      draft_status: states.status,
      id: _product.id,
      insurance_fee_name: "",
      is_authorization: states.is_authorization,
      is_banner_view: states.is_banner_view,
      is_featured: states.is_featured,
      is_production: (states.status === 'active' || states.status === 'waitingFix' || states.status === 'archived'),
      is_unknown_start_date_product: false,
      level: 1,
      notion_page_id: states.notion_page_id,
      payment_product_id: null,
      payment_variants: null,
      product_type: states.productType,
      release_date: jsReleaseDateStr,
      shipping_date: jsShippingDateStr,
      shipping_fee_name: states.shippingFee,
      showcase_date: jsShowcaseDateStr,
      status: (states.status === 'active' || states.status === 'waitingFix' || states.status === 'archived') ? 'active' : 'draft',
      tags: [states.category1,],
      title: states.title,
      updated_at: jsUpdatedAtStr,
      created_at: jsCreatedAtStr,
      vendor: (teaserEmail.vendor !== 'Dropp') ? teaserEmail.vendor : DroppSelcetVendor,
      requested_shipping_date: jsRequestedShippingDateStr,
      requested_variants: _product.requested_variants ? _product.requested_variants : null
    }
  }

  // 新規作成
  const handleSave = async () => {
    setConfirmDialogOpen(false)
    setLoading(true)

    try {
      const productId = await createProductId()
      if (!productId) throw new Error('ProductIdの作成に失敗しました。')

      // 2. 画像Storageに登録 [商品ID-index.type]で登録
      const _images = await uploadImages(states.title, productId)
      console.log('Uploaded Images:', _images)

      // 3.Firestoreに登録
      const { options, variants } = getOptionsAndVariants(productId)
      const _firestoreProduct = getInputProductData({ id: productId })
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      _firestoreProduct['options'] = options
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      _firestoreProduct['variants'] = variants
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      _firestoreProduct['image'] = _images[0].src ? _images[0].src : null
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      _firestoreProduct['images'] = _images
      console.log('Creating product data: ', _firestoreProduct)

      const createFirestoreProduct = CloudFunctions.httpsCallable('createFirestoreProduct')
      const resp = await createFirestoreProduct({ product: _firestoreProduct })
      if (resp.data.status === 'failed') {
        throw new Error(`Failed to CreateFirestoreProduct`)
      }
      console.log('Created firestore product:', resp.data)

      // 5. 保存が完了したことをSlackに通知
      await notifySlackMessage(states, _firestoreProduct)

      // 6.戻る
      setLoading(false)
      history.push('/products')
      dispatch(
        Common.actions.fetchAlert({
          alerts: [{ message: "商品登録が完了しました。" }],
        })
      );

    } catch (e) {
      setLoading(false)
      console.log('error', e)

      dispatch(
        Common.actions.fetchAlert({
          alerts: [{ message: "商品登録が失敗しました。" }],
        })
      );

      const postSlackMessage = CloudFunctions.httpsCallable("postSlackMessage")
      const amano = '<@U01TJ3U9NHM>'
      const channelId = DroppOperationChannelId
      const text = `${amano} \n商品登録でエラーがありました。\nProduct ID: \n${e}`
      try {
        await postSlackMessage({ text, conversationId: channelId })
      } catch (e) {
        console.error(e)
      }
    }
  }

  // 商品情報を更新
  const handleUpdate = async () => {
    setConfirmDialogOpen(false)
    setLoading(true)

    try {
      // 1.画像をアップし直す。
      // @ts-expect-error TS(2554): Expected 0 arguments, but got 1.
      const _imagesVal = await replaceProductImages(product)

      // 2. 新しい画像Storageに登録 [商品ID-index.type]で登録
      let _images = []
      for (const [i, imageVal] of Object.entries(_imagesVal)) {
        const _image = getFirestoreImageData(i, product.title, product.id, imageVal.path, imageVal.src)
        _images.push(_image)
      }
      console.log('商品画像を再アップロード:', _images)

      // 3.firestoreに登録
      const { options, variants } = getOptionsAndVariants(product.id)
      const _firestoreProduct = getInputProductData(product)
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      _firestoreProduct['options'] = options
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      _firestoreProduct['variants'] = variants
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      _firestoreProduct['image'] = _images[0].src ? _images[0].src : null
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      _firestoreProduct['images'] = _images
      console.log('update product: ', _firestoreProduct)

      const updateFirestoreProduct = CloudFunctions.httpsCallable('updateFirestoreProduct')
      const resp = await updateFirestoreProduct({ productId: product.id, field: _firestoreProduct })
      if (resp.data.status === 'failed') {
        throw new Error(`商品データの更新に失敗しました。 ${product.id} `)
      }
      console.log('商品データの更新に成功しました。 ', resp.data)

      //5. Slackに通知
      await notifySlackMessage(states, _firestoreProduct)

      // 5.戻る
      setLoading(false)
      history.push('/products')
      dispatch(
        Common.actions.fetchAlert({
          alerts: [{ message: "商品登録が完了しました。" }],
        })
      );

    } catch (e) {
      setLoading(false)
      console.log('error', e)
      dispatch(
        Common.actions.fetchAlert({
          alerts: [{ message: "商品登録が失敗しました。" }],
        })
      );

      const postSlackMessage = CloudFunctions.httpsCallable("postSlackMessage")
      const amano = '<@U01TJ3U9NHM>'
      const channelId = DroppDevelopChannelId
      const text = `${amano} \n商品登録でエラーがありました。\n${e} `
      try {
        await postSlackMessage({ text, conversationId: channelId })
      } catch (e) {
        console.error(e)
      }
    }

    return
  }


  const checkInput = () => {
    let checked = true
    // 入力チェック
    if (!states.title) {
      setStates({ ...states, titleErrorMessage: "入力してください." })
      checked = false
    }
    if (!description) {
      // @ts-expect-error TS(2345): Argument of type '{ descriptionErrorMessage: strin... Remove this comment to see the full error message
      setStates({ ...states, descriptionErrorMessage: "入力してください." })
      checked = false
    }
    if (!states.productType) {
      setStates({ ...states, productTypeErrorMessage: "選択してください。" })
      checked = false
    }
    if (!states.category1) {
      setStates({ ...states, category1ErrorMessage: "選択してください。" })
      checked = false
    }
    if (!states.shippingFee) {
      setStates({ ...states, shippingFeeErrorMesssage: "選択してください。" })
      checked = false
    }
    if (!states.hasOptions && (!states.price && !states.auctionStartPrice)) {
      setStates({ ...states, priceErrorMessage: "金額を入力してください." })
      checked = false
    }
    // if (!states.hasOptions && states.price && Number(states.price)) { //数字ではない
    //   setStates({...states, priceErrorMessage: '数字のみを入力してください'})
    //   checked = false
    // }
    // if (!states.hasOptions && states.auctionStartPrice && Number(states.auctionStartPrice)) { //数字ではない
    //   setStates({...states, priceErrorMessage: '数字のみを入力してください'})
    //   checked = false
    // }
    if (states.productType !== 'オークション' && !states.hasOptions && !states.inventoryQuantity) {
      setStates({ ...states, inventoryQuantityErrorMessage: "数量を入力してください." })
      checked = false
    }
    if (states.status === 'active' && !states.showcaseDate) {
      // @ts-expect-error TS(2345): Argument of type '{ showcaseDateErrorMessage: stri... Remove this comment to see the full error message
      setStates({ ...states, showcaseDateErrorMessage: "日付を入力してください." })
      checked = false
    }
    if (states.status === 'active' && !states.deadlineDate) {
      // @ts-expect-error TS(2345): Argument of type '{ deadlineDateErrorMessage: stri... Remove this comment to see the full error message
      setStates({ ...states, deadlineDateErrorMessage: "日付を入力してください." })
      checked = false
    }
    if (states.productType === 'オークション') {
      if (!states.auctionFee) {
        setStates({ ...states, auctionFeeErrorMessage: "入力してください。" })
        checked = false
      }
      if (!states.expected_winning_bid_amount) {
        setStates({ ...states, expected_winning_bid_amountErrorMessage: "予想金額を入力してください。" })
        checked = false
      }
      if (!states.auctionStartPrice) {
        setStates({ ...states, auctionStartPriceErrorMessage: "金額を入力してください。" })
        checked = false
      }
    }

    return { status: checked }
  }

  const handleConfirmOpen = () => {
    const res = checkInput()
    if (res.status) {
      setConfirmDialogOpen(true)
    } else {
      console.log('states', states)
      scrollTo({ top: 300, behavior: "smooth" })
    }
  }

  // 画像が選択された時の処理
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: acceptedFiles => {
      if (files.length > 0) acceptedFiles.push(...files) // 既存の画像に追加
      console.log('acceptedFiles', acceptedFiles)
      const newImages = acceptedFiles.map((file, index) => Object.assign(file, {
        src: URL.createObjectURL(file),
        uploadType: "upload",
        position: images.length + index,
        provider: null,
      }))
      // @ts-expect-error TS(2345): Argument of type '(T & { src: string; uploadType: ... Remove this comment to see the full error message
      setImages([...images, ...newImages])
    }
  })

  const [imagesLoading, setImagesLoading] = useState(false)
  // const handleImageRemove = (index: any) => {
  //   setImagesLoading(true)
  //   let newArr: any = []
  //   images.forEach((image, i) => {
  //     // @ts-expect-error TS(2339): Property 'uploadType' does not exist on type 'neve... Remove this comment to see the full error message
  //     if (image.uploadType === 'upload') {
  //       // @ts-expect-error TS(2339): Property 'src' does not exist on type 'never'.
  //       URL.revokeObjectURL(image.src)
  //     }
  //     if (i !== index) {
  //       newArr.push(image)
  //     }
  //   })
  //   // 改めてBolbのURLを作成する
  //   // @ts-expect-error TS(7006): Parameter 'image' implicitly has an 'any' type.
  //   const formatedImages = newArr.map((image, i) => {
  //     if (image.uploadType === 'upload') {
  //       return Object.assign(image, {
  //         src: URL.createObjectURL(image),
  //         uploadType: "upload",
  //         position: i,
  //         provider: null,
  //         path: null,
  //       })
  //     } else {
  //       image.position = i
  //       return image
  //     }
  //   })
  //   setImagesLoading(false)
  //   setImages(formatedImages)
  // }

  const dragStart = (e: any) => {
    // ドラッグしている画像のIDを取得
    e.dataTransfer.setData("text/plain", e.target.id)
  }
  const dragOver = (e: any) => {
    e.preventDefault()
  }
  // 
  const drop = (e: any, dropedImage: any) => {
    e.preventDefault()
    setImagesLoading(true)
    let id = Number(e.dataTransfer.getData("text/plain"))
    let newArr: any = []

    // 一旦uploadした画像のrevokeURLする
    images.forEach(image => {
      // @ts-expect-error TS(2339): Property 'uploadType' does not exist on type 'neve... Remove this comment to see the full error message
      if (image.uploadType === 'upload') {
        // @ts-expect-error TS(2339): Property 'src' does not exist on type 'never'.
        URL.revokeObjectURL(image.src)
      }
    })
    // Droppした画像とされた画像の場所を入れ替える
    // ファイルオブジェクトで渡されているものはfileオブジェクトで格納する
    images.forEach((image, index) => {
      // @ts-expect-error TS(2339): Property 'position' does not exist on type 'never'... Remove this comment to see the full error message
      if (image.position === dropedImage.position) {
        // @ts-expect-error TS(2339): Property 'uploadType' does not exist on type 'neve... Remove this comment to see the full error message
        if (image.uploadType === 'upload') {
          newArr.push(Object.assign(image, {
            src: URL.createObjectURL(image),
            uploadType: "upload",
            position: id,
            provider: null,
            path: null,
          }))
        } else {
          newArr.push({
            // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
            ...image,
            position: id,
            // @ts-expect-error TS(2339): Property 'src' does not exist on type 'never'.
            src: image.src,
            // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
            id: image.id,
            // @ts-expect-error TS(2339): Property 'provider' does not exist on type 'never'... Remove this comment to see the full error message
            provider: image.provider ? image.provider : null,
          })
        }
      } else if (id === index) {
        // @ts-expect-error TS(2339): Property 'uploadType' does not exist on type 'neve... Remove this comment to see the full error message
        if (image.uploadType === 'upload') {
          newArr.push(Object.assign(image, {
            src: URL.createObjectURL(image),
            uploadType: "upload",
            position: dropedImage.position,
            provider: null
          }))
        } else {
          newArr.push({
            // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
            ...image,
            position: dropedImage.position,
            // @ts-expect-error TS(2339): Property 'src' does not exist on type 'never'.
            src: image.src,
            // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
            id: image.id,
            // @ts-expect-error TS(2339): Property 'provider' does not exist on type 'never'... Remove this comment to see the full error message
            provider: image.provider ? image.provider : null,
            // @ts-expect-error TS(2339): Property 'path' does not exist on type 'never'.
            path: image.path ? image.path : null,
          })
        }
      } else {
        newArr.push(image)
      }
    })
    // @ts-expect-error TS(7006): Parameter 'a' implicitly has an 'any' type.
    newArr.sort((a, b) => a.position - b.position)
    setImages(newArr)
    setImagesLoading(false)
  }

  // unmount
  useEffect(() => {
    // @ts-expect-error TS(2339): Property 'src' does not exist on type 'never'.
    return () => images.forEach(image => URL.revokeObjectURL(image.src));
  }, [])


  //登録内容の確認ダイアログ
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const ConfirmDialogView = () => {
    return (
      <Dialog open={confirmDialogOpen} onClose={() => setConfirmDialogOpen(false)}>
        <DialogContent>

          <div className={classes.confirm_item_view}>
            <dl className={classes.confirm_item}>
              <dt className={classes.confirm_item_title}>商品名</dt>

              <dd className={classes.confirm_item_desc}>{states.title}</dd>
            </dl>
            <dl className={classes.confirm_item}>
              <dt className={classes.confirm_item_title}>販売方法</dt>

              <dd className={classes.confirm_item_desc}>{states.productType}</dd>
            </dl>
            <dl className={classes.confirm_item}>
              <dt className={classes.confirm_item_title}>カテゴリー</dt>
              {/* @ts-expect-error */}
              <dd className={classes.confirm_item_desc}>{categories[states.category1]}</dd>
            </dl>
            <dl className={classes.confirm_item}>
              <dt className={classes.confirm_item_title}>重量</dt>

              <dd className={classes.confirm_item_desc}>~{states.weight}KG</dd>
            </dl>
            <dl className={classes.confirm_item}>
              <dt className={classes.confirm_item_title}>送料</dt>

              <dd className={classes.confirm_item_desc}>{states.shippingFee}</dd>
            </dl>
            {states.productType === 'オークション' ?
              <>
                <dl className={classes.confirm_item}>
                  <dt className={classes.confirm_item_title}>落札予想価格</dt>

                  <dd className={classes.confirm_item_desc}>{states.expected_winning_bid_amount}</dd>
                </dl>
                <dl className={classes.confirm_item}>
                  <dt className={classes.confirm_item_title}>入札開始価格</dt>

                  <dd className={classes.confirm_item_desc}>{states.auctionStartPrice}</dd>
                </dl>
                {teaserEmail.vendor == 'Dropp' &&
                  <> <dl className={classes.confirm_item}>
                    <dt className={classes.confirm_item_title}>落札手数料</dt>

                    <dd className={classes.confirm_item_desc}>{states.auctionFee}</dd>
                  </dl>
                    <dl className={classes.confirm_item}>
                      <dt className={classes.confirm_item_title}>オーソリ上限</dt>

                      <dd className={classes.confirm_item_desc}>{states.authorization_limit ? states.authorization_limit : 'なし'}</dd>
                    </dl>
                    <dl className={classes.confirm_item}>
                      <dt className={classes.confirm_item_title}>入札幅</dt>

                      <dd className={classes.confirm_item_desc}>{states.bid_interval ? states.bid_interval : 'なし'}</dd>
                    </dl>
                  </>
                }
              </>
              : // 抽選 or BuyNow
              <>
                {states.hasOptions ?
                  <>
                    <dl className={classes.confirm_item}>
                      <dt className={classes.confirm_item_title}>オプション名</dt>
                      <dd className={classes.confirm_item_desc}>{states.optionName}</dd>
                    </dl>
                    {states.variants.map((variant, i) => (
                      <div style={{ padding: "6px", border: '1px solid gray' }} key={i}>
                        <dl className={classes.confirm_item}>
                          <dt className={classes.confirm_item_title}>オプション{i + 1}</dt>
                          <dd className={classes.confirm_item_desc}>{variant.option1}</dd>
                        </dl>
                        <dl className={classes.confirm_item}>
                          <dt className={classes.confirm_item_title}>金額</dt>
                          <dd className={classes.confirm_item_desc}>{variant.price}円</dd>
                        </dl>
                        <dl className={classes.confirm_item}>
                          <dt className={classes.confirm_item_title}>数量</dt>
                          <dd className={classes.confirm_item_desc}>{variant.inventory_quantity}個</dd>
                        </dl>
                      </div>
                    ))}
                  </> :
                  <>
                    <dl className={classes.confirm_item}>
                      <dt className={classes.confirm_item_title}>金額</dt>
                      <dd className={classes.confirm_item_desc}>{states.price}円</dd>
                    </dl>
                    <dl className={classes.confirm_item}>
                      <dt className={classes.confirm_item_title}>数量</dt>
                      <dd className={classes.confirm_item_desc}>{states.inventoryQuantity}個</dd>
                    </dl>
                  </>
                }
              </>
            }
            <dl className={classes.confirm_item}>
              <dt className={classes.confirm_item_title}>Dropp開始可能日</dt>
              {/* @ts-expect-error */}
              <dd className={classes.confirm_item_desc}>{states.releaseDate?.toLocaleString('ja')}</dd>
            </dl>
            {teaserEmail.vendor == 'Dropp' &&
              <>
                <dl className={classes.confirm_item}>
                  <dt className={classes.confirm_item_title}>カミスン告知開始日</dt>
                  {/* @ts-expect-error */}
                  <dd className={classes.confirm_item_desc}>{states.showcaseDate?.toLocaleString('ja')}</dd>
                </dl>
                <dl className={classes.confirm_item}>
                  <dt className={classes.confirm_item_title}>Dropp締切日</dt>
                  {/* @ts-expect-error */}
                  <dd className={classes.confirm_item_desc}>{states.deadlineDate?.toLocaleString('ja')}</dd>
                </dl>
                <dl className={classes.confirm_item}>
                  <dt className={classes.confirm_item_title}>事前決済</dt>
                  <dd className={classes.confirm_item_desc}>{states.is_authorization ? 'あり' : 'なし'}</dd>
                </dl>
                <dl className={classes.confirm_item}>
                  <dt className={classes.confirm_item_title}>注目商品</dt>
                  <dd className={classes.confirm_item_desc}>{states.is_featured ? 'あり' : 'なし'}</dd>
                </dl>
                <dl className={classes.confirm_item}>
                  <dt className={classes.confirm_item_title}>バナー掲載</dt>
                  <dd className={classes.confirm_item_desc}>{states.is_banner_view ? 'あり' : 'なし'}</dd>
                </dl>
              </>
            }
            <dl className={classes.confirm_item}>
              <dt className={classes.confirm_item_title}>登録ステータス</dt>
              {/* @ts-expect-error */}
              <dd className={classes.confirm_item_desc}>{statuses[states.status]}</dd>
            </dl>
          </div>
          <Typography variant="subtitle2">
            登録内容を保存します。<br />
            よろしいでしょうか？
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDialogOpen(false)}>キャンセル</Button>
          {product_id ? <Button onClick={handleUpdate}>更新</Button> : <Button onClick={handleSave}>保存</Button>}
        </DialogActions>
      </Dialog >
    )
  }

  const handleVariantsInput = (e: any, index: any) => {
    const { name, value } = e.target
    let newVariants = states.variants
    if (name === 'inventory_quantity' || name === 'price') {
      if (/^[0-9]*$/.test(value) || value == '') { // 半角数字か空白
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        newVariants[index][name] = value
        setStates({ ...states, variants: newVariants })
      }
    } else {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      newVariants[index][name] = value
      setStates({ ...states, variants: newVariants })
    }
  }

  const handleAddVariant = () => {
    let newVariants = states.variants
    // @ts-expect-error TS(2322): Type 'number' is not assignable to type 'string'.
    newVariants.push({ option1: "", price: "", inventory_quantity: 0 })
    setStates({ ...states, variants: newVariants })
  }

  const handleRemoveVariant = (removeIndex: any) => {
    let newVariants = states.variants.filter((_, i) => i !== removeIndex)
    setStates({ ...states, variants: newVariants })
  }

  const [otherOptionName, setOtherOptionName] = useState("")
  const handleSelectOptionName = (e: any) => {
    setStates({ ...states, optionName: e.target.value })
  }
  const inputOtherOptionName = (e: any) => {
    setOtherOptionName(e.target.value)
  }

  const handleImagesDownload = async () => {
    for (const [index, image] of Object.entries(images)) {
      const fileName = product.id + `_` + index
      // @ts-expect-error TS(2339): Property 'src' does not exist on type 'never'.
      await downloadImage(fileName, image.src)
    }
  }

  const downloadImage = async (fileName: any, src: any) => {
    try {
      // fetchで画像データを取得
      const image = await fetch(src);
      const imageBlob = await image.blob();
      const imageURL = URL.createObjectURL(imageBlob);

      // 拡張子取得
      const mimeTypeArray = imageBlob.type.split('/');
      const extension = mimeTypeArray[1];

      // ダウンロード
      const link = document.createElement('a');
      link.href = imageURL;
      link.download = `${fileName}.${extension}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Could not download image', error)
      throw new Error(`${error}. Image src: ${src} `);
    }
  }

  const handleAddDescriptionTemplate = () => {
    const _product = product ? product : { vendor: 'Dropp', product_type: '抽選' }
    switch (_product.product_type) {
      case '抽選':
        setDescription(description + lotteryTemplate(_product))
        break
      case 'オークション':
        setDescription(description + auctionTemplate(_product))
        break
      case '先着':
        setDescription(description + buynowTemplate(_product))
        break
      default:
        setDescription(description + lotteryTemplate(_product))
        break;
    }
  }

  const [deleteConfirmFlg, setDeleteConfirmFlg] = useState(false)
  const DeleteProductConfirmView = () => {
    return (
      <Dialog open={deleteConfirmFlg}>
        <DialogContent>
          <DialogContentText>
            <Typography variant="subtitle2">
              こちらの商品を削除しますか？
            </Typography>
          </DialogContentText>
          <DialogActions>
            <Button variant="outlined" onClick={() => setDeleteConfirmFlg(false)}>やめる</Button>
            <Button variant="outlined" onClick={handleDeleteProduct}>削除する</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    )
  }

  const handleDeleteProduct = async () => {
    setLoading(true)
    setDeleteConfirmFlg(false)
    try {
      // 2. Storageを削除 -> 3.Firestoreを削除

      //imagesを削除する。
      await deleteImages()

      const deleteNotionPageFunc = CloudFunctions.httpsCallable('deleteNotionPage')
      if (states.notion_page_id) await deleteNotionPageFunc({ pageId: states.notion_page_id })

      const func3 = CloudFunctions.httpsCallable('deleteFirestoreProduct')
      await func3({ product_id: product.id })

      setLoading(false)
      history.push('/products')
      dispatch(
        Common.actions.fetchAlert({
          alerts: [{ message: "商品の削除に成功しました。" }],
        })
      );
    } catch (e) {
      console.log('error', e)
      setLoading(false)
      dispatch(
        Common.actions.fetchAlert({
          alerts: [{ message: "商品の削除に失敗しました。" }],
        })
      )
    }
  }

  const handleCategory1Change = (e: any) => {
    const { name, value } = e.target
    setStates({ ...states, [name]: value })
  }

  const handleCopy = async () => {
    if (!product) return
    setLoading(true)
    try {

      const productId = await createProductId()
      if (!productId) throw new Error('ProductIdの作成に失敗しました。')

      // 2. 画像をStorageにアップロード
      const imagesData = []
      // 現在の画像のblobを取得しておく
      for (const [i, image] of Object.entries(images)) {
        console.log(i)
        // @ts-expect-error TS(2339): Property 'src' does not exist on type 'never'.
        const extension = getExtensionFromURL(image.src)
        const contentType = getContentType(extension)
        // @ts-expect-error TS(2339): Property 'src' does not exist on type 'never'.
        const blobFile = await getBlob(image.src)
        const data = {
          blob: blobFile,
          // @ts-expect-error TS(2339): Property 'type' does not exist on type 'never'.
          type: image.type,
          // @ts-expect-error TS(2339): Property 'path' does not exist on type 'never'.
          path: image.path,
          extension,
          contentType,
        }
        imagesData.push(data)
      }
      // StorageにBlobをアップロード
      let imagesVal = []
      for (const [i, data] of Object.entries(imagesData)) {
        try {
          const imageVal = await uploadImage(data.blob, productId, data.extension, data.contentType, i)
          imagesVal.push(imageVal)
        } catch (e) {
          console.error(e)
        }
      }

      // 2. 新しい画像Storageに登録 [商品ID-index.type]で登録
      let _images = []
      for (const [i, imageVal] of Object.entries(imagesVal)) {
        const _image = getFirestoreImageData(i, product.title, productId, imageVal.path, imageVal.src)
        _images.push(_image)
      }
      console.log('New Images: ', _images)

      // 3.firestoreに登録
      const { options, variants } = getOptionsAndVariants(product.id)
      const _firestoreProduct = getInputProductData(product)
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      _firestoreProduct['options'] = options
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      _firestoreProduct['variants'] = variants
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      _firestoreProduct['image'] = _images[0].src ? _images[0].src : null
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      _firestoreProduct['images'] = _images
      _firestoreProduct['id'] = productId
      _firestoreProduct['title'] = states.title + 'のコピー'
      _firestoreProduct['status'] = 'draft'
      _firestoreProduct['draft_status'] = 'draft'
      console.log('複製する商品: ', _firestoreProduct)

      const createFirestoreProduct = CloudFunctions.httpsCallable('createFirestoreProduct')
      const resp = await createFirestoreProduct({ product: _firestoreProduct })
      if (resp.data.status === 'failed') {
        throw new Error(`商品のコピーに失敗しました。: ${product.id} `)
      }
      dispatch(
        Common.actions.fetchAlert({
          alerts: [{ message: "商品を複製しました。" }],
        })
      );
      history.push(`/products/${productId}`)
      window.location.reload()
    } catch (e) {
      dispatch(
        Common.actions.fetchAlert({
          alerts: [{ message: "商品のコピーに失敗しました。" }],
        })
      );
    }
    setLoading(false)
  }

  // 変更依頼
  const handleRequestUpdate = async () => {
    setLoading(true)
    //在庫数を整形
    // @ts-expect-error TS(2339): Property 'toLocaleString' does not exist on type '... Remove this comment to see the full error message
    const jsRequestedShippingDateStr = requestStates.requestedShippingDate ? requestStates.requestedShippingDate.toLocaleString('ja') : null
    let requested_variants = requestStates.variants.map(variant => {
      let quantity = null
      // @ts-expect-error TS(2339): Property 'requestedInventoryQuantity' does not exi... Remove this comment to see the full error message
      if (variant.requestedInventoryQuantity === '') { // 何も入力されなかった時
        quantity = null
        // @ts-expect-error TS(2339): Property 'requestedInventoryQuantity' does not exi... Remove this comment to see the full error message
      } else if (variant.requestedInventoryQuantity == 0) { // 0が入力された時
        // @ts-expect-error TS(2339): Property 'currentInventoryQuantity' does not exist... Remove this comment to see the full error message
        quantity = 0 - Number(variant.currentInventoryQuantity)
        // @ts-expect-error TS(2339): Property 'requestedInventoryQuantity' does not exi... Remove this comment to see the full error message
      } else if (variant.requestedInventoryQuantity) {
        // @ts-expect-error TS(2339): Property 'requestedInventoryQuantity' does not exi... Remove this comment to see the full error message
        quantity = Number(variant.requestedInventoryQuantity) - Number(variant.currentInventoryQuantity)
      }
      return {
        // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
        'id': variant.id,
        // @ts-expect-error TS(2339): Property 'option1' does not exist on type 'never'.
        'option1': variant.option1,
        'quantity': quantity,
        // @ts-expect-error TS(2339): Property 'price' does not exist on type 'never'.
        'price': variant.price,
        // @ts-expect-error TS(2339): Property 'weight' does not exist on type 'never'.
        'weight': variant.weight,
      }
    })

    // 変更依頼を送信
    const func = CloudFunctions.httpsCallable('updateFirestoreProduct')
    await func({
      productId: product.id,
      field: {
        requested_shipping_date: jsRequestedShippingDateStr,
        requested_variants: requested_variants,
        draft_status: 'waitingFix',
      }
    })

    // 先着商品の更新はSlackに通知する
    const postSlackMessage = CloudFunctions.httpsCallable("postSlackMessage")
    const url = adminSiteUrl + '/products/' + product.id
    const text = `${HashimotoId}${kanazawaSlackMemberId}\n商品の在庫数変更依頼がありました。変更を承認してください。\n${product.title}\n${url} `
    try {
      const channelId = getChannelId(product.vendor)
      await postSlackMessage({ text, conversationId: channelId })
    } catch (e) {
      console.log("error post slack message: ", e)
    }

    setLoading(false)
    history.push('/products')
    dispatch(
      Common.actions.fetchAlert({
        alerts: [{ message: "商品登録が完了しました。" }],
      })
    );
  }

  // 在庫数と発送予定日
  // 希望の在庫数と、データと保持しておく在庫増減数は別の数字になるため。
  const [requestStates, setRequestStates] = useState({ currentShippingDate: null, requestedShippingDate: null, variants: [] })
  useEffect(() => {
    if (!product) return
    // 現在の配送予定日、入力された（未承認のものも）配送予定日、現在の在尾、現在の在庫変更依頼
    const currentShippingDate = product.shipping_date ? new Date(product.shipping_date.seconds * 1000) : null
    const requestedShippingDate = product.requested_shipping_date ? new Date(product.requested_shipping_date.seconds * 1000) : null
    let variants = []
    if (product.draft_status === 'waitingFix') { // 現在商品待ちの変更がある場合
      variants = product.requested_variants?.map((requestedVariant: any) => {
        let currentVariant = product.variants.find((variant: any) => variant.id === requestedVariant.id)
        return {
          id: requestedVariant.id,
          option1: requestedVariant.option1,
          currentInventoryQuantity: currentVariant.inventory_quantity,
          requestedInventoryQuantity: Number(currentVariant.inventory_quantity) + Number(requestedVariant.quantity),
          price: requestedVariant.price,
          weight: requestedVariant.weight,
        }
      })
    } else { // 承認待ちの変更がない場合
      variants = product.variants.map((variant: any) => {
        return {
          id: variant.id,
          option1: variant.option1,
          currentInventoryQuantity: variant.inventory_quantity,
          requestedInventoryQuantity: '',
          price: variant.price,
          weight: variant.weight,
        }
      })
    }
    setRequestStates({
      ...requestStates,
      // @ts-expect-error TS(2322): Type 'Date | null' is not assignable to type 'null... Remove this comment to see the full error message
      currentShippingDate,
      // @ts-expect-error TS(2322): Type 'Date | null' is not assignable to type 'null... Remove this comment to see the full error message
      requestedShippingDate,
      variants
    })
  }, [product])

  const handleRequestVariantInput = (e: any, index: any) => {
    const { value } = e.target
    let newVariants = requestStates.variants.map((requestVariant, i) => {

      if (index !== i) {
        return requestVariant
      } else {
        if (/^[0-9]*$/.test(value) || value == '') {
          if (value == '') {
            return {
              // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
              ...requestVariant,
              requestedInventoryQuantity: ''
            }
          } else if (value == 0) {

            return {
              // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
              ...requestVariant,
              requestedInventoryQuantity: 0
            }
          } else if (/^[0-9]*$/.test(value)) {
            return {
              // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
              ...requestVariant,
              requestedInventoryQuantity: Number(value)
            }
          }
        } else {
          return requestVariant
        }
      }
    })
    // @ts-expect-error TS(2322): Type 'any[]' is not assignable to type 'never[]'.
    setRequestStates({ ...requestStates, variants: newVariants })
  }

  const handleRequestDatePick = (e: any) => {
    // @ts-expect-error TS(2322): Type 'Date' is not assignable to type 'null'.
    setRequestStates({ ...requestStates, requestedShippingDate: new Date(e) })
  }

  // 変更依頼の承認
  const handleRequestRegister = async () => {
    setLoading(true)

    let newVariants = []
    // Variantsをフォーマット (先着購入のためのFirestoreのVariants)
    newVariants = states.variants.map((currentVariant) => {
      // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
      const targetRequestedVariant = requestStates.variants.find(variant => variant.id === currentVariant.id)
      let quantity = 0
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      if (targetRequestedVariant.requestedInventoryQuantity === '') { // 何も入力されていない
        // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'number'.
        quantity = null
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      } else if (targetRequestedVariant.requestedInventoryQuantity == 0) { // ０が入力された
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        quantity = 0 - targetRequestedVariant.currentInventoryQuantity
      } else { // 数字が入力された
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        quantity = Number(targetRequestedVariant.requestedInventoryQuantity) - Number(targetRequestedVariant.currentInventoryQuantity)
      }
      return {
        ...currentVariant,
        inventory_quantity: Number(currentVariant.inventory_quantity) + Number(quantity),
        // @ts-expect-error TS(2339): Property 'old_inventory_quantity' does not exist o... Remove this comment to see the full error message
        old_inventory_quantity: currentVariant.old_inventory_quantity + quantity,
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        price: targetRequestedVariant.price,
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        weight: targetRequestedVariant.weight,
      }
    })

    // FirestoreをUpdate
    // @ts-expect-error TS(2339): Property 'toLocaleString' does not exist on type '... Remove this comment to see the full error message
    const jsRequestedShippingDateStr = requestStates.requestedShippingDate ? requestStates.requestedShippingDate.toLocaleString('ja') : null
    console.log('承認', jsRequestedShippingDateStr)
    const func = CloudFunctions.httpsCallable('updateFirestoreProduct')
    await func({
      productId: product.id,
      field: {
        requested_shipping_date: null,
        shipping_date: jsRequestedShippingDateStr,
        requested_variants: null,
        variants: newVariants,
        draft_status: product.is_production ? 'active' : 'completeCreative'
      }
    })

    //変更依頼が承認されたことを通知
    const postMessage = CloudFunctions.httpsCallable('postSlackMessage')
    let inventoryQuantityText = ''
    newVariants.forEach((_variant, index) => {
      inventoryQuantityText += `在庫数${_variant.option1 === 'Default Title' ? '' : `(${_variant.option1})`}: ${product.variants[index].inventory_quantity} -> ${_variant.inventory_quantity}\n`
    })
    const text = `商品情報の変更依頼が承認されました。\n${product.title}\n ${adminSiteUrl}/products/${product.id}\n` + inventoryQuantityText
    const channelId = getChannelId(product.vendor)
    await postMessage({ conversationId: channelId, text })

    setLoading(false)
    history.push('/products')
    dispatch(
      Common.actions.fetchAlert({
        alerts: [{ message: "変更依頼の承認が完了しました。" }],
      })
    );
  }

  const [requestConfirmOpen, setRequestConfirmOpen] = useState(false)
  const formatedDate = (date: any) => {
    let fDate = new Date(date)
    // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'Date'.
    fDate = fDate.getFullYear() + '年' + (fDate.getMonth() + 1) + '月' + + fDate.getDate() + "日"
    return fDate
  }

  const RequestConfirmView = () => {
    return (
      <Dialog open={requestConfirmOpen}>
        <DialogContent>
          <Typography variant='subtitle2'>
            <span style={{ fontWeight: 'bold' }}>発送予定日</span><br />
            現在: {states.shipping_date ? formatedDate(states.shipping_date) : '予定通り'} <span style={{ color: '#ff0000' }}>変更後</span>: {requestStates.requestedShippingDate ? formatedDate(requestStates.requestedShippingDate) : '変更なし'}
          </Typography>
          <Typography variant='subtitle2' style={{ marginTop: '16px' }}>
            <span style={{ fontWeight: 'bold' }}>在庫数</span><br />
          </Typography>
          {requestStates.variants?.map(variant => (
            // @ts-expect-error TS(2339): Property 'option1' does not exist on type 'never'.
            <div style={{ margin: '0 0 16px' }} key={variant.option1}>
              {/* @ts-expect-error */}
              {variant.option1 !== 'Default Title' && <Typography variant='subtitle2'>オプション: {variant.option1}</Typography>}
              <Typography variant='subtitle2'>
                {/* @ts-expect-error */}
                変更前: {variant.currentInventoryQuantity} <span style={{ color: '#ff0000' }}>変更後</span>: {variant.requestedInventoryQuantity !== '' ? variant.requestedInventoryQuantity : '変更なし'}
              </Typography>
            </div>
          ))}
        </DialogContent>
        <DialogActions style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button onClick={() => setRequestConfirmOpen(false)}>やめる</Button>
          <Button onClick={handleRequestUpdate}>送信する</Button>
        </DialogActions>
      </Dialog >
    )
  }

  return (
    <Container maxWidth="md" className={classes.contaienr}>
      {isLoading ?
        <></>
        :
        <>
          <Typography variant="subtitle2" className={classes.page_title}>商品登録</Typography>
          <div style={{ position: "relative", top: "-50px", display: "flex", justifyContent: "space-between" }}>
            <Button variant='outlined' color="secondary" onClick={() => history.push('/products')}>戻る</Button>
            <div>
              <Button variant="outlined"
                disabled={teaserEmail.vendor !== 'Dropp' && (product?.draft_status !== 'draft' && product?.draft_status !== 'completeDraft' && product?.draft_status !== 'completeCreative')}
                className={classes.active_button} style={{ marginRight: "24px" }} onClick={() => setDeleteConfirmFlg(true)}>
                削除
              </Button>
              {product && <Button className={classes.active_button} variant='outlined' color="secondary" onClick={handleCopy}>複製する</Button>}
            </div>
          </div>
          <div className={classes.info_container}>
            <Typography variant="subtitle2" className={classes.title}>基本情報</Typography>
            <TextField
              name="title"
              label="商品名"
              required
              fullWidth
              value={states.title}
              onChange={handleInputChange}
              error={states.titleErrorMessage.length > 0}
              // @ts-expect-error TS(2322): Type '{ name: string; label: string; required: tru... Remove this comment to see the full error message
              helpertext={states.titleErrorMessage}
              disabled={!checkIsEditing('title')}
            />
          </div>
          <div className={classes.info_container}>
            <div className={classes.mt30} />
            <span>商品説明</span>
            {teaserEmail.vendor === 'Dropp' &&
              <div style={{ display: "flex", justifyContent: "end", marginBottom: "10px" }}>
                <Button onClick={handleAddDescriptionTemplate} className={classes.active_button} variant="outlined">テンプレートを挿入する</Button>
              </div>
            }
            <ReactQuill theme="snow" value={description} readOnly={!checkIsEditing('description')} onChange={handleInputDescript} className={classes.rich_text} />
            <div style={{ height: "80px" }} />
          </div>
          <div className={classes.info_container}>
            <div className={classes.mt30} />
            {teaserEmail.vendor === 'Dropp' &&
              <><FormControl>
                <InputLabel id="product-vendor-option">出品者</InputLabel>
                <Select
                  style={{ width: "200px" }}
                  labelId="product-vendor-option"
                  onChange={handleSelectChange}
                  name={'vendor'}
                  required
                  value={states.vendor}
                  disabled={!checkIsEditing('vendor')}
                >
                  {vendors.map((vendor) => (
                    <MenuItem key={vendor} value={vendor}>{vendor}</MenuItem>
                  ))}
                </Select>
              </FormControl>
                <div className={classes.mt30} /></>
            }
            <FormControl>
              <InputLabel id="product-type-option">商品カテゴリー</InputLabel>
              <Select
                style={{ width: "200px" }}
                labelId="product-category-option"
                onChange={handleCategory1Change}
                name='category1'
                error={states.category1ErrorMessage.length > 0}
                // @ts-expect-error TS(2322): Type '{ children: Element[]; style: { width: strin... Remove this comment to see the full error message
                helpertext={states.category1ErrorMessage}
                required
                value={states.category1}
                disabled={!checkIsEditing('category1')}
              >
                {Object.keys(categories).map((key) => (
                  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                  <MenuItem key={key} value={key}>{categories[key]}</MenuItem>
                ))}
              </Select>
              <Typography variant='subtitle2' style={{ color: '#ff0000', fontSize: '0.7rem' }}>{states.category1ErrorMessage}</Typography>
            </FormControl>
            <div className={classes.mt30} />
            <FormControl>
              <InputLabel id="product-type-option">販売方法✳</InputLabel>
              <Select
                style={{ width: "200px" }}
                labelId="product-type-option"
                onChange={handleSelectChange}
                name={'productType'}
                required
                value={states.productType}
                disabled={!checkIsEditing('productType')}
              >
                <MenuItem value={'抽選'}>抽選</MenuItem>
                <MenuItem value={'オークション'}>オークション</MenuItem>
                <MenuItem value={'先着'}>先着</MenuItem>
              </Select>
            </FormControl>
            <div className={classes.mt30} />
            <FormControl>
              <InputLabel id="weight">重量(KG) ※梱包後の総重量</InputLabel>
              <Select
                style={{ width: "200px" }}
                labelId="weight"
                onChange={handleSelectChange}
                value={states.weight}
                name="weight"
                disabled={!checkIsEditing('weight')}
              >
                <MenuItem value={5}>0kg~5kg</MenuItem>
                <MenuItem value={10}>5kg ~ 10kg</MenuItem>
                <MenuItem value={15}>10kg ~ 15kg</MenuItem>
                <MenuItem value={20}>15kg ~ 20kg</MenuItem>
                <MenuItem value={25}>20kg ~ 25kg</MenuItem>
                <MenuItem value={30}>25kg ~ 30kg</MenuItem>
              </Select>
            </FormControl>
            <div className={classes.mt30} />
            <FormControl>
              <InputLabel id="shipping-fee">送料</InputLabel>
              <Select
                style={{ width: "200px" }}
                labelId="shipping-fee"
                onChange={handleSelectChange}
                value={states.shippingFee}
                name="shippingFee"
                disabled={!checkIsEditing('shippingFee')}
              >
                <MenuItem value="1000円配送">1000円配送</MenuItem>
                <MenuItem value="1500円配送">1500円配送</MenuItem>
                <MenuItem value="2000円配送">2000円配送</MenuItem>
                <MenuItem value="3000円沖縄5000円配送">3000円沖縄5000円配送</MenuItem>
                <MenuItem value="大型配送1">大型配送1</MenuItem>
              </Select>
            </FormControl>
            <div className={classes.mt30} />
            <div>
              <div style={{ margin: '16px 0' }}>
                {teaserEmail.vendor === 'Dropp' &&
                  <>
                    <Typography variant="subtitle2" style={{ color: "gra" }}>カミスン開始日</Typography>
                    <DatePicker
                      className={classes.datetime}
                      dateFormat="yyyy年MM月dd日 HH:mm:ss"
                      // @ts-expect-error
                      selected={states.showcaseDate}
                      showTimeSelect={teaserEmail.vendor === 'Dropp'}
                      disabled={!checkIsEditing('showcaseDate')}
                      onChange={(e: any) => handleDatePick(e, 'showcaseDate')} />
                    {/* @ts-expect-error */}
                    {states.showcaseDateErrorMessage &&
                      // @ts-expect-error TS(2339): Property 'showcaseDateErrorMessage' does not exist... Remove this comment to see the full error message
                      <Typography variant="subtitle2" style={{ color: "red" }}>{states.showcaseDateErrorMessage}</Typography>
                    }
                  </>
                }
              </div>
              <div style={{ margin: '16px 0' }}>
                <Typography variant="subtitle2" style={{ color: "gra" }}>
                  {teaserEmail.vendor === 'Dropp' ?
                    <>Dropp開始日</>
                    :
                    <>Dropp開始可能日</>
                  }
                </Typography>
                <DatePicker
                  className={classes.datetime}
                  dateFormat="yyyy年MM月dd日 HH:mm:ss"
                  selected={states.releaseDate}
                  showTimeSelect={teaserEmail.vendor === 'Dropp'}
                  disabled={!checkIsEditing('releaseDate')}
                  onChange={(e: any) => handleDatePick(e, 'releaseDate')} />
              </div>
              <div style={{ margin: '16px 0' }}>
                <Typography variant="subtitle2" style={{ color: "gra" }}>Dropp終了日</Typography>
                <DatePicker
                  className={classes.datetime}
                  dateFormat="yyyy年MM月dd日 HH:mm:ss"
                  // @ts-ignore
                  selected={states.deadlineDate}
                  showTimeSelect={teaserEmail.vendor === 'Dropp'}
                  disabled={!checkIsEditing('deadlineDate')}
                  onChange={(e: any) => handleDatePick(e, 'deadlineDate')} />
                {/* @ts-expect-error */}
                {states.deadlineDateErrorMessage &&
                  // @ts-expect-error TS(2339): Property 'deadlineDateErrorMessage' does not exist... Remove this comment to see the full error message
                  <Typography variant="subtitle2" style={{ color: "red" }}>{states.deadlineDateErrorMessage}</Typography>
                }
              </div>
              <div style={{ margin: '16px 0' }}>
                <Typography variant="subtitle2" style={{ color: "gra" }}>発送予定日</Typography>
                {(teaserEmail.vendor === 'Dropp' || states.shipping_date) ?
                  <DatePicker
                    className={classes.datetime}
                    dateFormat="yyyy年MM月dd日"
                    selected={states.shipping_date}
                    showTimeSelect={teaserEmail.vendor === 'Dropp'}
                    disabled={!checkIsEditing('shipping_date')}
                    onChange={(e: any) => handleDatePick(e, 'shipping_date')} />
                  :
                  // @ts-expect-error TS(2769): No overload matches this call.
                  <Typography variants='subtitle2' style={{ fontSize: '0.85rem' }}>
                    抽選・オークション商品: Dropp終了日から原則5営業日以内。<br />先着商品: 購入日から原則3営業日以内。
                  </Typography>
                }
              </div>
            </div>
            {teaserEmail.vendor === 'Dropp' &&
              <>
                <div className={classes.mt30} />
                <FormControl>
                  <InputLabel id="is_authorization">事前決済</InputLabel>
                  <Select
                    style={{ width: "200px" }}
                    labelId="status"
                    onChange={handleSelectChange}
                    value={states.is_authorization}
                    name="is_authorization"
                    disabled={!checkIsEditing('is_authorization')}
                  >
                    {/* @ts-expect-error */}
                    <MenuItem value={false}>いいえ</MenuItem>
                    {/* @ts-expect-error */}
                    <MenuItem value={true}>はい</MenuItem>
                  </Select>
                </FormControl>
                <div className={classes.mt30} />
                <FormControl>
                  <InputLabel id="is_featured">注目商品</InputLabel>
                  <Select
                    style={{ width: "200px" }}
                    labelId="status"
                    onChange={handleSelectChange}
                    value={states.is_featured}
                    name="is_featured"
                    disabled={!checkIsEditing('is_featured')}
                  >
                    {/* @ts-expect-error */}
                    <MenuItem value={false}>いいえ</MenuItem>
                    {/* @ts-expect-error */}
                    <MenuItem value={true}>はい</MenuItem>
                  </Select>
                </FormControl>
                <div className={classes.mt30} />
                <FormControl>
                  <InputLabel id="is_banner_view">バナー掲載</InputLabel>
                  <Select
                    style={{ width: "200px" }}
                    labelId="is_banner_view"
                    onChange={handleSelectChange}
                    value={states.is_banner_view}
                    name="is_banner_view"
                    disabled={!checkIsEditing('is_banner_view')}
                  >
                    {/* @ts-expect-error */}
                    <MenuItem value={false}>いいえ</MenuItem>
                    {/* @ts-expect-error */}
                    <MenuItem value={true}>はい</MenuItem>
                  </Select>
                </FormControl>
              </>
            }
          </div>

          <div className={classes.info_container}>
            <Typography variant="subtitle2" className={classes.title}>オプション・金額</Typography>
            {states.productType === 'オークション' ?
              <>
                <div className={classes.mt30} />
                <TextField
                  style={{ width: "500px" }}
                  name="expected_winning_bid_amount"
                  label="予想落札金額（表示される文字を記入 例: ¥10,000 ~ ¥20,000）"
                  required
                  value={states.expected_winning_bid_amount}
                  onChange={handleInputChange}
                  error={states.expected_winning_bid_amountErrorMessage.length > 0}
                  // @ts-expect-error TS(2322): Type '{ style: { width: string; }; name: string; l... Remove this comment to see the full error message
                  helpertext={states.expected_winning_bid_amountErrorMessage}
                  disabled={!checkIsEditing('expected_winning_bid_amount')}
                />
                <div className={classes.mt30} />
                <TextField
                  style={{ width: "500px" }}
                  name="auctionStartPrice"
                  label="入札開始価格 (半角数字)"
                  required
                  value={states.auctionStartPrice}
                  onChange={handleNumberInputChange}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">¥</InputAdornment>,
                  }}
                  error={states.auctionStartPriceErrorMessage.length > 0}
                  // @ts-expect-error TS(2322): Type '{ style: { width: string; }; name: string; l... Remove this comment to see the full error message
                  helpertext={states.auctionStartPriceErrorMessage}
                  disabled={!checkIsEditing('auctionStartPrice')}
                />
                {teaserEmail.vendor === 'Dropp' &&
                  <>
                    <div className={classes.mt30} />
                    <TextField
                      style={{ width: "500px" }}
                      name="auctionFee"
                      label="落札手数料（％）半角数字"
                      required
                      value={states.auctionFee}
                      onChange={handleNumberInputChange}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                      }}
                      error={states.auctionFeeErrorMessage.length > 0}
                      // @ts-expect-error TS(2322): Type '{ style: { width: string; }; name: string; l... Remove this comment to see the full error message
                      helpertext={states.auctionFeeErrorMessage}
                      disabled={!checkIsEditing('auctionFee')}
                    />
                    <div className={classes.mt30} />
                    <TextField
                      style={{ width: "500px" }}
                      name="reserve_price"
                      label="最低落札金額（半角数字）option"
                      value={states.reserve_price}
                      onChange={handleInputChange}
                      error={states.reserve_priceErrorMessage.length > 0}
                      // @ts-expect-error TS(2322): Type '{ style: { width: string; }; name: string; l... Remove this comment to see the full error message
                      helpertext={states.reserve_priceErrorMessage}
                      disabled={!checkIsEditing('reserve_price')}
                    />
                    <Typography variant='subtitle2' style={{ fontSize: '0.75rem', marginTop: '4px', color: 'gray' }}>
                      自動入札する際はテストサイトよりお願いいたします。
                    </Typography>
                    <div className={classes.mt30} />
                    <TextField
                      style={{ width: "500px" }}
                      name="bid_interval"
                      label="入札幅を指定（半角数字） Option"
                      value={states.bid_interval}
                      onChange={handleNumberInputChange}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">¥</InputAdornment>,
                      }}
                      error={states.bidIntervalErrorMessage.length > 0}
                      // @ts-expect-error TS(2322): Type '{ style: { width: string; }; name: string; l... Remove this comment to see the full error message
                      helpertext={states.bidIntervalErrorMessage}
                      disabled={!checkIsEditing('bid_interval')}
                    />
                    <div className={classes.mt30} />
                    <TextField
                      style={{ width: "500px" }}
                      name="authorization_limit"
                      label="オーソリ上限（半角数字）Option"
                      value={states.authorization_limit}
                      onChange={handleNumberInputChange}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">¥</InputAdornment>,
                      }}
                      error={states.authorizationLimitErrorMessage.length > 0}
                      // @ts-expect-error TS(2322): Type '{ style: { width: string; }; name: string; l... Remove this comment to see the full error message
                      helpertext={states.authorizationLimitErrorMessage}
                      disabled={!checkIsEditing('authorization_limit')}
                    />
                  </>
                }
              </>
              :
              <>
                <FormControl>
                  <InputLabel id="option">オプション選択</InputLabel>
                  <Select
                    style={{ width: "200px" }}
                    labelId="option"
                    onChange={handleOptionChange}
                    value={states.hasOptions}
                    disabled={!checkIsEditing('option')}
                  >
                    {/* @ts-expect-error */}
                    <MenuItem value={true}>あり</MenuItem>
                    {/* @ts-expect-error */}
                    <MenuItem value={false}>なし</MenuItem>
                  </Select>
                </FormControl>
                {states.hasOptions ?
                  <>
                    <div className={classes.mt30} />
                    <FormControl>
                      <InputLabel id="option">オプション名</InputLabel>
                      <Select
                        style={{ width: "200px" }}
                        labelId="option"
                        onChange={handleSelectOptionName}
                        value={states.optionName}
                        disabled={!checkIsEditing('optionName')}
                      >
                        <MenuItem value={'サイズ'}>サイズ</MenuItem>
                        <MenuItem value={'カラー'}>カラー</MenuItem>
                        <MenuItem value={'額装'}>額装</MenuItem>
                        <MenuItem value={'その他'}>その他</MenuItem>
                      </Select>
                    </FormControl>
                    {states.optionName === 'その他' &&
                      <TextField
                        style={{ marginLeft: "10px" }}
                        name="variationName"
                        label="オプションタイトル（例: カラー）"
                        required
                        value={otherOptionName}
                        onChange={inputOtherOptionName}
                        disabled={!checkIsEditing('otherOptionName')}
                      // error={states.options[i].variationNameErrorMessage.length > 0}
                      // helpertext={states.options[i].variationNameErrorMessage}
                      />
                    }
                    <div style={{ marginTop: "20px" }} />
                    {states.variants.map((variant, i) => (
                      <>
                        <div className={classes.option_input_area}>
                          <TextField
                            style={{ margin: "10px" }}
                            name="option1"
                            label={'オプション' + (i + 1)}
                            required
                            value={states.variants[i].option1}
                            onChange={(e) => handleVariantsInput(e, i)}
                            disabled={!checkIsEditing('option1')}
                          // error={states.options[i].option1ErrorMessage.length > 0}
                          // helpertext={states.options[i].option1ErrorMessage}
                          />
                          <TextField
                            style={{ margin: "10px", width: "310px" }}
                            name="price"
                            label="価格(商品+オプションの合計) (半角数字)"
                            required
                            value={states.variants[i].price}
                            onChange={(e) => handleVariantsInput(e, i)}
                            disabled={!checkIsEditing('price')}
                          // error={states.variants[i].priceErrorMessage.length > 0}
                          // helpertext={states.variants[i].priceErrorMessage}
                          />
                          <TextField
                            style={{ margin: "10px", width: "130px" }}
                            name="inventory_quantity"
                            label="数量 (半角数字)"
                            required
                            value={states.variants[i].inventory_quantity}
                            onChange={(e) => handleVariantsInput(e, i)}
                            disabled={!checkIsEditing('inventory_quantity')}
                          // error={states.variants[i].variationQuantityErrorMessage.length > 0}
                          // helpertext={states.variants[i].variationQuantityErrorMessage}
                          />
                          {/* @ts-expect-error */}
                          <Button disabled={!checkIsEditing()} variant="outlined" onClick={() => handleRemoveVariant(i)}>削除</Button>
                        </div>
                      </>
                    ))}
                    <div>
                    </div>
                    {/* @ts-expect-error */}
                    <Button disabled={!checkIsEditing()} style={{ width: "50px", marginTop: "10px" }} variant="outlined" onClick={handleAddVariant} >追加</Button>
                  </> :
                  <>
                    <div className={classes.mt30} />
                    <TextField
                      style={{ width: "300px" }}
                      name="price"
                      label="価格 (半角数字)"
                      required
                      value={states.price}
                      onChange={handleNumberInputChange}
                      error={states.priceErrorMessage.length > 0}
                      // @ts-expect-error TS(2322): Type '{ style: { width: string; }; name: string; l... Remove this comment to see the full error message
                      helpertext={states.priceErrorMessage}
                      disabled={!checkIsEditing('price')}
                    />
                    <div className={classes.mt30} />
                    <TextField
                      style={{ width: "300px" }}
                      name="inventoryQuantity"
                      label="数量 (半角数字)"
                      required
                      value={states.inventoryQuantity}
                      onChange={handleNumberInputChange}
                      error={states.inventoryQuantityErrorMessage.length > 0}
                      // @ts-expect-error TS(2322): Type '{ style: { width: string; }; name: string; l... Remove this comment to see the full error message
                      helpertext={states.inventoryQuantityErrorMessage}
                      disabled={!checkIsEditing('inventoryQuantity')}
                    />
                  </>
                }
              </>
            }
          </div>

          <div className={classes.info_container}>
            {/* @ts-expect-error */}
            {checkIsEditing() && <>
              <Typography variant="subtitle2" className={classes.title}>画像</Typography>
              <div {...getRootProps({ className: 'dropzone' + ' ' + classes.dropzone })}>
                < input {...getInputProps()} />
                <p>画像をドラッグ & ドロップするか、こちらをクリックしてアップロードしてください。
                  正方形の画像をアップロードしてください。
                </p>
              </div>
              {images.length > 1 && <div><Typography variant="subtitle2">※画像を移動させて順番を変えることができます。</Typography><br /></div>}
              <div className={classes.preview_wrapper}>
                {/* {images.length > 0 && images.map((image, i) => ( */}
                {!imagesLoading && images.length > 0 && images.map((image, i) => (
                  <div key={i}>
                    <img
                      // @ts-expect-error TS(2322): Type 'number' is not assignable to type 'string'.
                      id={i} draggable="true" onDragStart={dragStart} onDragOver={dragOver} onDrop={(e) => drop(e, image)}
                      // @ts-expect-error TS(2339): Property 'name' does not exist on type 'never'.
                      key={image.name} src={image.src} className={classes.preview_image} onLoad={() => { URL.revokeObjectURL(image.src) }} />
                  </div>
                ))}
              </div>
            </>
            }
            {teaserEmail.vendor === 'Dropp' &&
              <div style={{ display: "flex", justifyContent: "end" }}>
                <Button variant="outlined" className={classes.active_button} onClick={handleImagesDownload}>全画像をダウンロード</Button>
              </div>
            }
          </div>
          <div className={classes.info_container}>
            <Typography variant="subtitle2" className={classes.title}>登録ステータス</Typography>
            <div className={classes.mt30} />
            <FormControl>
              <InputLabel id="status">ステータス</InputLabel>
              <Select
                style={{ width: "200px" }}
                labelId="status"
                onChange={handleSelectChange}
                value={states.status}
                name="status"
                disabled={!checkIsEditing('status')}
              >
                <MenuItem value="draft">下書き</MenuItem>
                <MenuItem value="completeDraft">下書き完了（クリエイティブ依頼）</MenuItem>
                <MenuItem value="completeCreative" disabled={teaserEmail.vendor !== 'Dropp'}>クリエイティブ修正済み</MenuItem>
                <MenuItem value="testing" disabled={teaserEmail.vendor !== 'Dropp'}>テスト適用</MenuItem>
                <MenuItem value="active" disabled={teaserEmail.vendor !== 'Dropp'}>本番適用</MenuItem>
                <MenuItem value="waitingFix" disabled={teaserEmail.vendor !== 'Dropp'}>本番適用後FIX待ち</MenuItem>
                <MenuItem value="archived" disabled={teaserEmail.vendor !== 'Dropp'}>Dropp終了</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className={classes.mt30} />
          {/* @ts-expect-error */}
          <div className={classes.button_area}>
            {teaserEmail.vendor !== 'Dropp' && product?.draft_status && (product.draft_status !== 'draft' && product.draft_status !== 'completeDraft' && product.draft_status !== 'completeCreative') ?
              <></> :
              <Button variant="outlined" className={classes.active_button} onClick={handleConfirmOpen}>保存</Button>
            }
          </div>
          {(checkIsEditing('requestedShippingDate') && product && (product.draft_status === 'active' || product.draft_status === 'waitingFix')) &&
            <>
              <div className={classes.info_container}>
                <Typography variant="subtitle2" className={classes.title}>変更依頼</Typography>
                <div style={{ margin: '16px 0', display: 'flex' }}>
                  <Typography variant='subtitle2'>
                    現在の発送予定日: <br />
                    {requestStates.currentShippingDate ? formatedDate(requestStates.currentShippingDate) : <>抽選・オークション商品: Dropp終了日から原則5営業日以内。<br />先着商品: 購入日から原則3営業日以内。</>}
                  </Typography>
                  <div style={{ marginLeft: '3rem' }}>
                    <Typography variant="subtitle2" style={{ margin: '1rem 0 0' }}>
                      発送予定日（変更する場合はご記入ください。）<br />
                    </Typography>
                    <DatePicker
                      className={classes.datetime}
                      dateFormat="yyyy年MM月dd日"
                      selected={requestStates.requestedShippingDate}
                      onChange={(e: any) => handleRequestDatePick(e)} />
                  </div>
                </div>
                <div style={{ margin: '40px 0' }}>
                  <Typography variant="subtitle2">在庫数</Typography>
                  {requestStates.variants?.map((variant, i) => (
                    <div key={i}>
                      <div className={classes.option_input_area} style={{ display: 'flex', borderBottom: '1px solid gray' }}>
                        <Typography variant='subtitle2' style={{ margin: '0 16px' }}>
                          オプション名<br />
                          {/* @ts-expect-error */}
                          <span style={{ fontSize: '1rem' }}>{variant.option1}</span>
                        </Typography>
                        <Typography variant='subtitle2' style={{ margin: '0 16px' }}>
                          現在の在庫数:<br />
                          {/* @ts-expect-error */}
                          <span style={{ fontSize: '1.2rem' }}>{variant.currentInventoryQuantity}</span>
                        </Typography>
                        <TextField
                          style={{ margin: "10px", width: "250px" }}
                          name="request_inventory_quantity"
                          label="新しい在庫数を記入 (半角数字)"
                          // @ts-expect-error TS(2339): Property 'requestedInventoryQuantity' does not exi... Remove this comment to see the full error message
                          value={requestStates.variants[i].requestedInventoryQuantity}
                          onChange={(e) => handleRequestVariantInput(e, i)}
                        />
                        <Typography>
                          増減数:<br />
                          {/* @ts-expect-error */}
                          {variant.requestedInventoryQuantity !== '' ? variant.requestedInventoryQuantity - variant.currentInventoryQuantity : 'なし'}
                        </Typography>
                      </div>
                    </div>
                  ))}
                </div>
                <Button variant="outlined" className={classes.active_button} onClick={() => setRequestConfirmOpen(true)}>変更依頼を送信する</Button>
                {(teaserEmail.vendor == 'Dropp' && product?.draft_status === 'waitingFix') &&
                  <Button variant="outlined" className={classes.active_button} onClick={handleRequestRegister} style={{ margin: '0 24px' }}>承認する(Dropp側)</Button>
                }
              </div>

            </>
          }

          <ConfirmDialogView />
          <DeleteProductConfirmView />
          <RequestConfirmView />
          <BackdropLoading open={loading} />
        </>
      }
    </Container >
  );
}

export default ProductInput

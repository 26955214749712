import { Button, Container, makeStyles, TextField, Typography, withStyles } from "@material-ui/core"
import React, { useContext, useState } from "react"
import { Link } from "react-router-dom"
import { AuthContext } from "../../contexts/Auth"
import BackdropLoading from "../common/BackdropLoading";

const CustomTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#ff0000',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '0',
      '&:hover fieldset': {
        borderColor: '#ff0000',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#ff0000',
      }
    },
  }
})(TextField);

const CustomButton = withStyles({
  root: {
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
    },
    '&:focus': {
      boxShadow: 'none',
    }
  },
})(Button);

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "grey",
  },
  title: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    fontWeight: 'bold'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#ff0000",
    borderRadius: '0',
    color: "#fff",
    fontWeight: 'bold',
  },
  root: {
    margin: "0 auto",
  },
  confirm_button: {
    color: "#ff0000",
    // border: '1px solid #ff0000',
  },
  text: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4)
  },
  link_wrapper: {
    marginTop: "50px",
    marginBottom: "50px",
    display: "flex",
    flexDirection: "column",
  },
  link_text: {
    textAlign: "right",
    color: "grey",
  },
  link: {
    color: "#ff0000",
    textDecoration: "none",
  }
}));

const ChangePassword = () => {
  const classes = useStyles()
  const { currentUser, resetPassword } = useContext<any>(AuthContext)
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const { email } = event.target.elements;
    try {
      setIsLoading(true)
      resetPassword(email.value);
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
    }
  };

  if (currentUser) {
    return (
      <Container component="main" maxWidth="xs" className={classes.root}>
        <div className={classes.paper}>
          <Typography variant="subtitle1" className={classes.title}>パスワードの再設定</Typography>
          <form className={classes.form} onSubmit={handleSubmit}>
            {currentUser?.email ?
              <>
                <Typography component='p'>
                  現在登録されているメールアドレス：<b>{currentUser.email}</b>
                </Typography>
                <Typography component='p' className={classes.text}>上記、現在登録されているメールアドレスに、再設定用のリンクをご案内いたします。こちらから再設定を行ってください。</Typography>
                <input type="hidden" value={currentUser.email} name="email"></input>
              </>
              :
              <CustomTextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="メールアドレス"
                name="email"
                autoComplete="email"
                style={{ borderRadius: '0' }}
                autoFocus
                color="secondary"
              />
            }
            <CustomButton
              type="submit"
              fullWidth
              variant="contained"
              className={classes.submit}
            >
              変更する
            </CustomButton>
          </form>
        </div>
        <div className={classes.link_wrapper}>
          <Typography variant="subtitle2" className={classes.link_text}>メールアドレス変更は<Link to="/mypage/change-email" className={classes.link}>こちら</Link></Typography><br></br>
          <Typography variant="subtitle2" className={classes.link_text}>電話番号変更は<Link to="/mypage/change-phone-number" className={classes.link}>こちら</Link></Typography><br></br>
        </div>
        <BackdropLoading open={isLoading} />
      </Container>
    )
  } else {
    return (<></>)
  }
}
export default ChangePassword;
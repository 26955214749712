import React, { useContext, useEffect, useState } from 'react'
import { Button, Container, makeStyles } from "@material-ui/core";
import { useHistory, useParams } from 'react-router-dom';
import { CloudFunctions, db } from '../utils/Firebase';
import { AuthContext } from '../contexts/Auth';
import { useForm } from 'react-hook-form';
import BackdropLoading from '../components/common/BackdropLoading';
import { useDispatch } from 'react-redux';
import Common from '../redux/common/commonSlice';
import { DroppOperationChannelId } from '../utils/Notify';
import AppraisalTitle from '../components/appraisal/AppraisalTitle';
import ProductInfoView from '../components/appraisal/ProductInfoView';
import { Product } from '../types/product';

const useStyles = makeStyles(() => ({
    container: {
        marginTop: '100px',
    },
    bidWrapper: {
        padding: '40px 0'
    },
    title: {
        fontSize: '24px'
    },
    input: {
        padding: '14px',
    },
    button: {
        backgroundColor: "#f00",
        borderRadius: '0',
        color: '#fff',
        width: '100%',
    },
    backButton: {
        margin: '32px auto',
    }
}))

type Params = {
    product_id: string
}

export const AppraisalBidUpdate = () => {
    const classes = useStyles()
    const history = useHistory()
    const dispatch = useDispatch()
    const { product_id } = useParams<Params>()
    const [product, setProduct] = useState<Product>()
    const [isLoading, setIsLoading] = useState(false)
    const { currentUser, teaserEmail } = useContext<any>(AuthContext)
    const { getValues, setValue, register, handleSubmit, formState: { errors } } = useForm()

    useEffect(() => {
        if (!currentUser || !teaserEmail.is_buyer || !product_id) return
        const getProduct = async () => {
            await db.collection('products').doc(String(product_id)).get().then(doc => {
                let _product = doc.data() as Product
                _product['id'] = doc.id
                setProduct(_product)
                const myTicket = _product.bid_history.find((ticket: any) => ticket.bidUserId === currentUser.uid)
                setValue('bidPrice', myTicket?.bid_price)
            })
        }
        getProduct()
    }, [product_id, currentUser])

    //買取商品に入札
    const handleBid = async () => {
        if (!product) return
        setIsLoading(true)
        const bidTicket = {
            productId: product_id,
            bidPrice: getValues().bidPrice,
            bidUserId: currentUser.uid,
            bidUserEmail: teaserEmail.email,
        }

        try {
            const createBid = CloudFunctions.httpsCallable('createBidForAppraisalProduct')
            const res = await createBid({ product_id, bidTicket })
            console.log('res', res)
            if (res.data.status === 'failed') throw new Error('入札に失敗')

            // Slackに通知
            const postSlackMessage = CloudFunctions.httpsCallable("postSlackMessage")
            const amano = '<@U01TJ3U9NHM>'
            const channelId = DroppOperationChannelId
            const text = `${amano} \n買取商品へ新規入札がありました。\n商品名: ${product.title} \n-> http://dropp.jp/appraisal/`
            try {
                await postSlackMessage({ text, conversationId: channelId })
            } catch (e) {
                console.error(e)
            }
            dispatch(Common.actions.fetchAlert({ alerts: [{ message: '買取商品への入札が完了しました。' }], }))
            history.push('/appraisal')
            setIsLoading(false)
        } catch (e) {
            console.log('error', e)
        }
    }

    return (
        <Container className={classes.container} maxWidth='sm'>
            <AppraisalTitle />


            {product ?
                <>
                    <ProductInfoView product={product} />
                    <div className={classes.bidWrapper}>
                        <p>希望買取価格: {product.expected_winning_bid_amount}</p>

                        <form onSubmit={handleSubmit(handleBid)}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <label style={{ marginBottom: '4px' }} htmlFor='bidPrice'>入札金額&nbsp;(税込)
                                    <span style={{ fontSize: '12px', color: 'red' }}>&nbsp;※半角数字のみで入力して下さい。</span></label>
                                <input
                                    className={classes.input}
                                    type='number'
                                    id="bidPrice"
                                    {...register('bidPrice', {
                                        required: {
                                            value: true,
                                            message: '入力をお願いします。'
                                        }
                                    })}
                                />
                                <p>{errors.bidPrice?.message}</p>
                            </div>
                        </form>
                        <Button onClick={handleBid} className={classes.button}>入札を更新する</Button>
                    </div >
                </>
                : <></>
            }
            <div style={{ textAlign: 'center' }}>
                <Button onClick={() => history.push('/appraisal')} className={classes.backButton}>戻る</Button>
            </div>
            <BackdropLoading open={isLoading} />
        </Container >
    )
}

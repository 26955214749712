import { Container, makeStyles, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

const useStyles = makeStyles(() => ({
    variant: {
        display: "flex",
    },
    variant_title: {
        display: "flex",
        alignItems: "center",
    },
    inventory_quantity: {
        fontSize: "18px",
    },
    icon: {
        padding: "4px",
        margin: "4px",
    },
    calc_inventory_quantity: {
        fontSize: "16px",
        marginLeft: "10px",
        color: "green",
    },
}))

const AdminProductInput = ({
    product,
    setProduct
}: any) => {
    const classes = useStyles()
    const [calcInventoryQuantity, setCalcInventoryQuantity] = useState(0)

    const handleIncrement = (index: any) => {
        console.log('calcInventoryQuantity+1', calcInventoryQuantity + 1)
        setCalcInventoryQuantity(calcInventoryQuantity + 1)
        let newVariants = product.variants
        newVariants[index].inventory_quantity += 1
        setProduct({ ...product, variants: newVariants })
    }
    const handleDecrement = (index: any) => {
        setCalcInventoryQuantity(calcInventoryQuantity - 1)
        let newVariants = product.variants
        newVariants[index].inventory_quantity -= 1
        setProduct({ ...product, variants: newVariants })
    }

    return (
        <Container maxWidth="sm">
            {product &&
                <>
                    {product.variants.map((variant: any, index: any) => (
                        <div className={classes.variant}>
                            <Typography variant="subtitle2" className={classes.variant_title}>{variant.title === 'Default Title' ? 'オプションなし' : variant.title}</Typography>
                            <div className={classes.variant_title}>
                                <Typography variant="subtitle2" style={{ margin: "0 10px" }}>在庫数</Typography>
                                <AddIcon className={classes.icon} style={{ fontSize: "22" }} onClick={() => handleIncrement(index)} />
                                <span className={classes.inventory_quantity}>{variant.inventory_quantity}</span>
                                <RemoveIcon className={classes.icon} style={{ fontSize: "22" }} onClick={() => handleDecrement(index)} />
                                <Typography variant="subtitle2" className={classes.calc_inventory_quantity}>変更数: {calcInventoryQuantity}</Typography>
                            </div>
                        </div>
                    ))}
                </>
            }
        </Container>
    );
}

export default AdminProductInput
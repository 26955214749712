import { db } from "../utils/Firebase"

const generateProductId = () => '7' + Math.floor(Math.random() * 1000000000000)

const isExistProductId = async (productId: string) => {
    return await db.collection("products").doc(productId).get().then((doc) => {
        if (doc.exists) {
            return true
        } else {
            return false
        }
    }).catch(() => {
        return true
    })
}

export const useProduct = () => {

    // 7から始める13桁の数字を生成
    const createProductId = async () => {
        let productId = generateProductId()
        let isExist = false
        let count = 0
        do {
            isExist = await isExistProductId(productId)
            if (isExist) {
                productId = generateProductId()
            }
            count++
        } while (isExist && count < 3)
        return !isExist ? productId : null
    }

    return {
        createProductId,
    }

}
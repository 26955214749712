import { Container, createStyles, Grid, makeStyles, Theme, Typography } from "@material-ui/core"
import { useContext } from "react"
import { useHistory } from "react-router-dom"
import { AuthContext } from "../../contexts/Auth"
import SquareImageView from "./SquareImageView"
import { Product } from "../../types/product"

const useStyles = makeStyles((theme: Theme) => createStyles({
    cardGrid: {
        padding: "10px",
        width: "100%",
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(8),
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(4),
        }
    },
    gridItem: {
        padding: "6px 20px",
        marginBottom: theme.spacing(9),
        display: "flex",
        flexDirection: "column",
        width: "90%",
        margin: "0 auto",
        [theme.breakpoints.up('sm')]: {
            marginBottom: theme.spacing(10),
            padding: "6px 32px",
        }
    },
    itemInfo: {
        display: "flex",
        flexFlow: 'column',
        justifyContent: 'center',
        color: "#ff0000",
        marginBottom: "10px",
    },
    sectionTitle: {
        fontWeight: "bold",
        fontSize: "1.2rem",
        padding: "8px 0px 0px",
        margin: "0 auto",
        borderBottom: "1px solid black",
        letterSpacing: "3px",
        marginBottom: "30px",
        [theme.breakpoints.up('sm')]: {
            marginBottom: "50px",
        }
    },
    productTitle: {
        fontSize: ".85rem",
        overflow: "hidden",
        maxHeight: "50px",
        lineHeight: "25px",
        color: "gray",
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: 2,
        [theme.breakpoints.up('sm')]: {
            fontSize: "0.9rem",
        }
    },
    productPrice: {
        fontSize: "0.85rem",
        color: 'gray',
        [theme.breakpoints.up('sm')]: {
            fontSize: "0.9rem",
        }
    },
    bidCount: {
        fontSize: "0.85rem",
        color: '#ff0000',
        [theme.breakpoints.up('sm')]: {
            fontSize: "0.9rem",
        }
    },

}))

const DroppNowProductsView = ({
    products
}: any) => {
    const classes = useStyles()
    const history = useHistory()
    const { currentUser, teaserEmail } = useContext<any>(AuthContext)

    return (
        <Container className={classes.cardGrid} maxWidth="md">
            <Typography className={classes.sectionTitle} variant="subtitle1">出品中</Typography>
            <Grid container>
                {products.map((product: Product) => (
                    <Grid className={classes.gridItem} item key={product.id} xs={6} sm={4} onClick={() => history.push(`/appraisal/${product.id}`)} >
                        <div className={classes.itemInfo}>
                            <SquareImageView imageSrc={product.image} alt={product.title} />
                        </div>
                        <Typography className={classes.productTitle}>
                            {product.title}
                        </Typography>
                        {!teaserEmail?.is_buyer ? // 出品者
                            <Typography className={product.bid_history?.length > 0 ? classes.bidCount : classes.productPrice}>
                                {product.bid_history?.length > 0 ? product.bid_history?.length : 0}&thinsp;入札
                            </Typography>
                            : // 買取業者
                            <>
                                {(product.bid_history?.find((_ticket: any) => _ticket.user_id === currentUser.id)) &&
                                    <Typography className={classes.bidCount}>
                                        入札中
                                    </Typography>
                                }
                            </>
                        }
                    </Grid>
                ))}
            </Grid>
        </Container >
    );
}

export default DroppNowProductsView
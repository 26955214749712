import React, { memo, useEffect, useState } from 'react'
import { createStyles, makeStyles, Typography } from "@material-ui/core";
import { useHistory } from 'react-router-dom';
import { Product } from '../../types/product';
// import { analytics } from '../../utils/Firebase';

const useStyles = makeStyles(() => createStyles({
  banner_wrapper: {
    position: "fixed",
    top: "0",
    height: "40px",
    minWidth: "100vw",
    backgroundColor: "#ff0000",
    // @ts-expect-error TS(2322): Type '"100"' is not assignable to type 'ZIndexProp... Remove this comment to see the full error message
    zIndex: "100",
  },
  banner_inner: {
    animation: "flowing linear infinite",
    transform: "translateX(100vw)",
  },
  banner_text: {
    fontFamily: "digital7-mono",
    color: "white",
    marginRight: "150px",
    cursor: "pointer",
    fontSize: "1.2rem",
    whiteSpace: "nowrap",
    lineHeight: "40px",
    letterSpacing: "1px",
  },
}))

type Props = {
  bannerProducts: Product[]
}

const BannerView = memo((props: Props) => {
  const classes = useStyles()
  const history = useHistory()
  const { bannerProducts } = props
  const [baseDuration, setBaseDuration] = useState(12)

  useEffect(() => {
    let isMounted = true
    let totalTitltStrLength = 0
    bannerProducts.forEach((product: any) => {
      totalTitltStrLength += product.title.length
    })
    // 文字数に応じて速度を調整
    if (isMounted) {
      if (window.outerWidth > 900) {
        setBaseDuration(Math.floor(totalTitltStrLength / 1.5 / bannerProducts.length))
        // 基本数 + 文字数によってプラス
      } else if (window.outerWidth > 600) {
        setBaseDuration(9 + Math.floor(totalTitltStrLength / 10))
      } else {
        setBaseDuration(8 + Math.floor(totalTitltStrLength / 10))
      }
    }
  }, [bannerProducts])

  const productType = {
    "オークション": "Auction",
    "抽選": "Raffle",
    "先着": "Buy"
  }

  const handleBannerClick = (_product: any) => {

    if (_product.product_type === '抽選') {
      history.push(`/detail/${_product.id}`)
    } else if (_product.product_type === 'オークション') {
      history.push(`/auction/${_product.id}`)
    } else if (_product.product_type === '先着') {
      history.push(`/onsale/${_product.id}`)
    }
  }

  return <>
    <style>
      {`@keyframes flowing {
        100% {
          transform: translateX(-100%);
        }
      }`}
    </style>
    <div className={classes.banner_wrapper}>
      <div className={classes.banner_inner} style={{ animationDuration: `${bannerProducts.length * baseDuration}s` }}>
        <Typography variant="subtitle1" style={{ display: 'flex' }}>
          {bannerProducts.map((product: any) => (
            <span
              onClick={() => handleBannerClick(product)}
              key={product.id}
              className={classes.banner_text}>
              {/* @ts-expect-error */}
              {product.title} {productType[product.product_type]} Now
            </span>
          ))}
        </Typography>
      </div>
    </div>
  </>;
})

export default BannerView
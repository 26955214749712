import React from "react";
import {
	Container,
	makeStyles,
	Typography,
	Box,
	Theme,
	createStyles,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import Copyright from "../components/Copyright";

const useStyles = makeStyles((theme: Theme) => createStyles({
	paper: {
		marginTop: theme.spacing(5),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: '0 10px'
	},
	root: {
		margin: "0 auto",
		height: "100%",
		padding: '0',
		marginBottom: theme.spacing(8)
	},
	title: {
		fontSize: "1.3rem",
		lineHeight: "2em",
		marginTop: theme.spacing(12),
		textAlign: 'center',
		width: '100%',
		color: 'grey',
	},
	box: {
		width: '90%',
		margin: "auto",
		padding: "1e,m",
		marginBottom: theme.spacing(4)
	},
	subtitle: {
		fontSize: "1.2em",
		marginBottom: theme.spacing(2),
		fontWeight: 500,
		color: 'grey',
	},
	text: {
		fontSize: '0.9rem',
	},
	top_dotted: {
		borderTop: '2px dotted #000',
	},
}));

const PolicyText = () => {
	return (
		<div>
			株式会社Droppは、インターネット抽選型サイト「Dropp」（以下「サービス」といいます。）を提供しています。<br />
			<br />
			当社は、サービスをご利用になるお客様をはじめとして、当社に個人情報をご提供される全ての方々の個人情報の重要性を認識しております。<br />
			<br />
			個人情報保護に関する法令およびその他の規範を遵守し、自主的なルールおよび体制を確立し、以下のプライバシーポリシーを定め、これを実施し、かつ、維持することを宣言いたします。<br />
			<br /><br /><br />
			<div style={{ textAlign: 'center' }}>
				第1条 取得する個人情報の項目<br /><br />
				当社は、法に基づき、下記の個人情報を取得いたします。<br /><br />
			</div>
			<div style={{ textAlign: 'left' }}>
				<ol style={{ margin: '0', paddingLeft: '1rem' }}>
					<li>サービスへのログインおよび利用等に関する情報
						<ul style={{ margin: '0', paddingLeft: '1rem', listStyleType: 'disc' }}>
							<li>サービスログインのためのアカウントのIDおよびパスワード</li>
							<li>アカウント登録情報としての性別、生年月日、郵便番号およびメールアドレス</li>
							<li>アカウントに紐づく情報としてお客様から提供される、氏名、住所、電話番号、SNSアカウント情報、身長、身体的特徴その他のお客様に関する情報</li>
							<li>ポイント、クーポン、キャッシュバックその他のサービスの利用に関する情報</li>
							<li>サービスの利用を通じてお客様が行った取引の遂行のための情報および当該取引内容に関する情報</li>
							<li>クレジットカード情報、銀行口座情報、電子マネー、収納代行、代引きに関する情報その他の決済およびその方法に関する情報</li>
							<li>お客様から当社へのお問い合わせやご連絡等に関する情報・法律上の要請等により、</li>
							<span>本人確認を行うための本人確認書類（運転免許証、健康保険証、住民票の写し等のことをいいます。）および当該書類に含まれる情報</span>
						</ul>
					</li><br /><br />
					<li>お客様が使用する端末に関する情報およびCookie等を利用して取得する情報
						<ul style={{ margin: '0', paddingLeft: '1rem', listStyleType: 'disc' }}>
							<li>情報通信端末の機体識別に関する情報</li>
							<li>情報通信端末のOS情報</li>
							<li>インターネットへの接続に関する情報</li>
							<li>位置情報</li>
							<li>リファラー情報</li>
							<li>IPアドレス情報</li>
							<li>閲覧したURLおよび閲覧した日時に関するタイムスタンプ情報</li>
							<li>サービスの利用に関する情報</li>
							<li>お客様の端末ごとに割り振られる広告識別子情報</li>
							<li>サーバログ情報</li>
						</ul>
					</li><br /><br />
					<li>第三者から取得する情報
						<ul style={{ margin: '0', paddingLeft: '1rem', listStyleType: 'disc' }}>
							<li>お客様の同意を得て第三者から取得する情報</li>
							<li>法令に基づき取得する情報</li>
							<li>公開されていることにより取得する情報当社が取得するお客様の情報は、全てお客様の意思によってご提供いただくものです。</li>
							<span>なお、サービスの利用に関連して当社が取得する情報の全部又は一部をご提供いただけない場合は、サービスの利用をお断りする場合がございますので、あらかじめご了承下さい。</span>
						</ul>
					</li>
				</ol>
				<br /><br /><br />

			</div>
			<div style={{ textAlign: 'center' }}>第2条 個人情報の利用目的<br /><br /></div>
			<div style={{ textAlign: 'left' }}>
				<ol style={{ margin: '0', paddingLeft: '1rem' }}>
					<li>当社は、第1条に記載した個人情報を、法に基づき、下記のとおり利用いたします。
						<ul style={{ margin: '0', paddingLeft: '1rem', listStyleType: 'disc' }}>
							<li>サービスへのログインおよびサービスの利用（お客様の同意に基づく利用またはお客様との契約の履行のための利用）</li>
							<li>お客様がサービスにログインするため</li>
							<li>お客様に適したサービスを提供・運用するため</li>
							<li>お客様と当社グループとの間の取引の成立および履行その他のお客様によるサービスの利用のため</li>
							<li>サービスの会員であるお客様の管理のため</li>
							<li>サービスの商品等の梱包・発送業務のため</li>
							<li>サービスの対価の請求のため</li>
							<li>ポイントサービスの運用のため</li>
							<li>サービスの運営上必要な事項の通知のため</li>
							<li>サービスの各種問合わせ、アフターサービス対応のため</li>
							<li>退会したお客様へのご連絡・各種問合わせ対応のため</li>
							<li>不正行為等の防止および対応のため</li>
							<li>当社が実施するサービス又は企画に関する連絡のため</li>
							<li>お客様の本人認証及び各種画面における登録情報の自動表示<br /></li>
							<span>なお、お客様がサービスへログインし、サービスをご利用いただくためには、当社が上記の目的で利用するために、お客様の個人情報をご提供いただく必要がございます。</span>
						</ul>
					</li><br /><br />
					<li>広告・マーケティングのための利用（お客様の同意に基づく利用）
						<ul style={{ margin: '0', paddingLeft: '1rem', listStyleType: 'disc' }}>
							<li>サービス上または第三者の媒体においてサービスの勧誘 、広告その他のマーケティングをするため</li>
							<li>メールマガジンの送信のため</li>
							<li>ダイレクトメールの送付のため</li>
							<li>キャンペーン、懸賞企画、アンケートの実施のため</li>
							<li>サービス上または第三者の媒体において第三者が広告主となる広告を配信するため</li>
						</ul>
					</li><br /><br />
					<li>商品開発・研究のための利用（お客様との同意に基づく利用）
						<ul style={{ margin: '0', paddingLeft: '1rem', listStyleType: 'disc' }}>
							<li>マーケティングデータの調査・分析のため</li>
							<li>当社の新たなサービスや商品等の開発のため</li>
						</ul>
					</li>
				</ol><br /><br /><br />
			</div>

			<div style={{ textAlign: 'center' }}>第3条 個人情報の提供の同意<br /><br /></div>
			当社は、法令で認められる場合またはお客様の同意をいただいた場合には第三者に対して個人情報を提供いたします。以下の場合においては、当社は第三者に対して、
			必要な範  囲で個人情報（第1号および第2号並びに第4号にあっては,氏名や住所など直接特定の個人を識別することができる情報を除外した情報）を提供させていただきます。<br /><br />
			<div style={{ textAlign: 'left' }}>
				<ol style={{ margin: '0', paddingLeft: '1rem' }}>
					<li>サービスの提供または品質向上に必要な場合</li>
					<li>新たなサービスの検討・開発のために必要な場合</li>
					<li>外国にある第三者に個人情報の取り扱いを委託する場合</li>
					<li>大学 その他の学術研究を目的とする機関若しくは団体又はそれらに属する者に学術研究の用に供する目的で個人情報を提供する場合</li>
				</ol>
				<span>なお、当社は、お客様へのサービスの提供等のため必要がある場合には、委託先に対する必要かつ適切な監督を行った上で、個人情報の取り扱いを第三者に委託することがあります。</span>
				<br /><br /><br />
			</div>

			<div style={{ textAlign: 'center' }}>第4条 本プライバシーポリシーの変更<br /><br /></div>
			個人情報を適切に利用し、その保護を徹底 するために本プライバシーポリシーの全部または一部を改定することがあります。重要な変更 がある場合は、適用開始日をお知らせしたうえで通知を行います。<br />
			<br /><br /><br />
			第5条 クッキーの利用について<br /><br />
			当社のサービスではお客様の閲覧情報を分析し、個々のお客様に適したサービスや情報、広告等を提供する目的のため、Cookieを利用しています。<br />
			<br /><br /><br />
			第6条 開示、訂正等の手続きについて<br /><br />
			当社は、お客様ご本人からの開示対象  個人情報の開示、利用目的の通知、開示対象個人情報の内容が事実に反する場合等における訂正等、利用停止等および第三者提供の停止 （以下「開示等」という。）のご請求を受付いたします。<br />
			<br />
			<div style={{ textAlign: 'left' }}>
				<ol style={{ margin: '0', paddingLeft: '1rem' }}>
					<li>開示等の求めの申し出</li>
					先郵送でご請求いただく場合は、第7条の規定の宛先に所定の請求書に必要書類を添付のうえ、ご郵送ください。<br /><br />
					<li>ご提出いただくもの<br />
						<ul style={{ listStyleType: 'disc', margin: '0', paddingLeft: '1rem' }}>
							<li>個人情報利用目的の通知・</li>
							<li>個人情報訂正等請求書</li>
							<li>個人情報利用停止等請求書</li>
							<li>本人確認のための書類（運転免許証、パスポートなど）</li>
							<li>法定代理人の場合は、上記(4)に加え、法定代理権があることを確認する書類</li>
							<li>任意代理人の場合は、上記(4)に加え、当社所定の委任状及び本人の印鑑証明書</li>
						</ul>
					</li>
					<span>※ご提出いただいた書類に記載された本籍地等の機微情報については、あらかじめ塗り潰した上でご提出下さい。当社受領時に塗り潰されていない場合は、当社にて塗り潰すことにより取得しないものとさせていただきます。<br /><br /></span>
					<li>手数料当該ご請求のうち、開示のご請求および利用目的の通知のご請求につきましては、1回のご請求につき、1,000円の手数料をご負担いただきますので、あらかじめご了承ください。</li>
					<span>なお、郵送でご請求いただく場合は、振込み等により、手数料をいただきます。<br />
						開示等のご請求の具体的な手続きにつきましては、第9条の窓口までお問い合わせください。<br /></span>
				</ol><br /><br /><br />
			</div>
			<div style={{ textAlign: 'center' }}>第7条 個人情報の取扱いに関するご相談・苦情等について<br /><br /></div>
			当社の個人情報の取扱いに関するご相談や苦情、異議等のお問い合わせについては、下記の窓口までご連絡いただきますよう、お願い申し上げます。
			また、お客様のご同意はいつでも撤回することが可能です。<br /><br />
			<br /><br />
			<div style={{ borderTop: '2px dashed #fff', paddingTop: '1.5rem', width: '100%' }}><br />［個人情報についてのお問い合わせ先］</div><br />
			<br />
			<div style={{ textAlign: 'left' }}>
				〒150-0043 <br />
				東京都渋谷区道玄坂１丁目２−３ 渋谷フクラス 17階<br />
				株式会社Dropp カスタマーサポートセンター<br />
				info@dropp.jp<br />
				<br />
				第8条 個人情報保護管理者<br /><br />
				株式会社Dropp 代表取締役西方雄作<br />
				個人情報保護管理者に対するお問い合わせ等は、上記の「個人情報についてのお問い合わせ先」までご連絡ください。<br /><br />
			</div>
		</div>
	)
}

const Privacy = () => {
	const classes = useStyles();
	return (
		<React.Fragment>
			<Container component="main" maxWidth="lg" className={classes.root}>
				<Typography variant="h4" component="h1" className={classes.title}>プライバシーポリシー</Typography>
				<Box className={classes.paper}>
					<div className={classes.box}>
						<PolicyText />
					</div>
				</Box>
			</Container>
			<Copyright />
		</React.Fragment>
	);
};

export default withRouter(Privacy);

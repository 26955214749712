import React, { useContext } from 'react';
import { Badge, Container, Drawer, IconButton, List, ListItem, ListItemText, makeStyles, Typography, SvgIcon, ThemeProvider } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core';
import { withRouter, Link } from 'react-router-dom';
import { AuthContext } from '../contexts/Auth';
import { db } from "../utils/Firebase";
import { ReactComponent as XMark } from "../images/close.svg";
import SettingsIcon from "../images/settings_icon_40px.png"

const useStyles = makeStyles((theme) => ({
  drawer_paper: {
    width: '100%',
    paddingTop: 'env(safe-area-inset-top)',
  },
  settings_icon: {
    width: '44px',
  },
  close_button: {
    marginLeft: '0',
    marginTop: theme.spacing(1),
  },
  auth_btns: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  },
  signup_btn: {
    borderRadius: '2em',
    border: '1px solid #CC3300',
    width: '47%',
    height: '2.4em',
    backgroundColor: '#CC3300',
    color: 'white',
    fontWeight: 'bold'
  },
  signin_btn: {
    borderRadius: '2em',
    border: '1px solid #CC3300',
    width: '47%',
    height: '2.4em',
    color: '#CC3300',
    fontWeight: 'bold'
  },
  signout_btn: {
    borderRadius: '2em',
    border: '1px solid gray',
    width: '47%',
    height: '2.4em',
    backgroundColor: 'gray',
    marginLeft: theme.spacing(3),

  },
  menu_title: {
    paddingLeft: theme.spacing(2),
    color: 'gray'
  },
  menu_content: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2)
  },
  notice_icon: {
    color: "#ff0000",
    verticalAlign: "bottom",
    fontSize: "1.5em",
    paddingLeft: ".3em"
  },
  logout_button: {
    marginTop: '36px',
  },
}));

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      '"Arial"',
      '"ヒラギノ角ゴシック"',
      'sans-serif'
    ].join(','),
  },
})



const DrawerMenu = (props: any) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = React.useState(false);
  const { currentUser, signout, teaserEmail } = useContext<any>(AuthContext);
  const [uncheckCount, setUncheckCount] = React.useState(0);

  const toggleDrawer = (state: any) => {
    setIsOpen(state);
  };

  React.useEffect(() => {
    let isMounted = true
    const f = async (user: any) => {
      let query = db
        .collection("notices")
        .where("uid", "==", user.uid);
      let copy_result: any = null;//不要かも
      let timerId: any = null;
      query.onSnapshot(
        (response) => {
          // 同時に来ると落ちるので最後だけ実行
          clearTimeout(timerId);
          copy_result = response;
          timerId = setTimeout(() => {
            let _uncheckCount = 0;
            copy_result.forEach((doc: any) => {
              let tmp = doc.data();
              _uncheckCount += tmp.check ? 0 : 1;
            });
            if (isMounted) setUncheckCount(_uncheckCount);
          }, 500);
        },
        (err) => {
          console.error(`Encountered error: ${err}`);
        }
      );
    };
    if (currentUser) {
      f(currentUser);
    }
    return (() => {
      isMounted = false
    })
  }, [currentUser]);

  const clickHandle = (e: any, path: any) => {
    e.stopPropagation();
    if (location.pathname == path) {
      toggleDrawer(false);
    } else {
      props.history.push(path)
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <IconButton
        edge='start'
        onClick={() => toggleDrawer(true)}>
        <img src={SettingsIcon} className={classes.settings_icon}></img>
        <Drawer
          anchor='left'
          classes={{ paper: classes.drawer_paper }}
          open={isOpen}
          onClose={() => toggleDrawer(false)}>
          <Container maxWidth="md">
            <IconButton
              edge='start'
              onClick={(e) => { e.stopPropagation(); toggleDrawer(false); }}
              className={classes.close_button}>
              <SvgIcon component={XMark} viewBox="0 0 1000 900" />
            </IconButton>
            <List dense>
              <Typography variant='caption' className={classes.menu_title}>商品情報</Typography>
              {(currentUser && currentUser.phoneNumber != null) && (
                <>
                  <ListItem button onClick={(e) => clickHandle(e, "/")}>
                    <ListItemText primary='ホーム' />
                  </ListItem>
                  <ListItem button onClick={(e) => clickHandle(e, "/history")}>
                    <ListItemText primary={
                      <>
                        {uncheckCount > 0 ?
                          <Badge color="secondary" variant="dot">
                            <>抽選・購入履歴</>
                          </Badge> : <>抽選・購入履歴</>}
                      </>
                    } />
                  </ListItem>
                  {/* <ListItem button onClick={(e) => clickHandle(e, "/appraisal")}>
                    <ListItemText primary='査定' />
                  </ListItem> */}
                </>
              )}
              {/* <ListItem button onClick={(e) => clickHandle(e, "/archived")}>
                <ListItemText primary='アーカイブ商品' />
              </ListItem> */}
            </List>
            {currentUser &&
              <List dense>
                <Typography variant='caption' className={classes.menu_title}>アカウント情報</Typography>
                <ListItem button onClick={(e) => clickHandle(e, "/mypage")}>
                  <ListItemText primary='マイページ' />
                </ListItem>
              </List>
            }
            {teaserEmail?.vendor &&
              <List dense>
                <Typography variant='caption' className={classes.menu_title}>管理者</Typography>
                <ListItem button component={Link} to='/products' key='products'>
                  <ListItemText primary='出品登録' />
                </ListItem>
              </List>
            }
            {teaserEmail?.is_admin &&
              <List dense>
                <Typography variant='caption' className={classes.menu_title}>管理者</Typography>
                <ListItem button component={Link} to='/admin' key='admin'>
                  <ListItemText primary='管理画面' />
                </ListItem>
              </List>
            }
            {!currentUser &&
              <List dense>
                <Typography variant='caption' className={classes.menu_title}>アカウント</Typography>
                <ListItem button component={Link} to='/signin' key='signin'>
                  <ListItemText primary='ログイン' />
                </ListItem>
                <ListItem button component={Link} to='/signup/email' key='signup'>
                  <ListItemText primary='アカウント登録' />
                </ListItem>
              </List>
            }
            <List dense>
              <ListItem button onClick={(e) => clickHandle(e, "/about")}>
                <ListItemText primary='Droppについて' />
              </ListItem>
              <ListItem button onClick={(e) => clickHandle(e, "/privacy")}>
                <ListItemText primary='プライバシーポリシー' />
              </ListItem>
              <ListItem button onClick={(e) => clickHandle(e, "/term-of-use")}>
                <ListItemText primary='利用規約' />
              </ListItem>
              <ListItem button onClick={(e) => clickHandle(e, "/tokutei")}>
                <ListItemText primary='特定商取引法に基づく表示' />
              </ListItem>
              {/* <ListItem button onClick={(e) => clickHandle(e, "/kobutu")}>
                <ListItemText primary='古物営業法に基づく表示' />
              </ListItem> */}
              {currentUser && (
                <>
                  <ListItem button onClick={(e) => clickHandle(e, "/contact")}>
                    <ListItemText primary='お問合わせ' />
                  </ListItem>
                  <ListItem className={classes.logout_button} button component="a" href='/' key='logout' onClick={() => signout()}>
                    <ListItemText primary='ログアウト' />
                  </ListItem>
                </>
              )}
            </List>
          </Container>
        </Drawer>
      </IconButton >
    </ThemeProvider>
  );

};

export default withRouter(DrawerMenu);
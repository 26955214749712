import React, { useState } from "react";
import {
  Container,
  createStyles,
  makeStyles,
  Box,
  Toolbar,
  Tabs,
  Tab,
  Theme,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import Copyright from "../components/Copyright";
import TermOfUseText from "../components/common/TermOfUseText";
import AuctionTermOfUseText from "../components/common/AuctionTermText";
import { withStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    margin: "0 auto",
    height: "100%",
    padding: '0',
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8)
  },
  paper: {
    marginTop: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0 10px'
  },
  tab_bar: {
    margin: '68px auto',
    display: 'flex',
    justifyContent: 'center',
    position: "fixed",
    width: "100%",
    zIndex: 1000,
    backgroundColor: "white",
    paddingLeft: "0",
    height: "90px",
  },
  box: {
    width: '90%',
    margin: "auto",
    padding: "1e,m",
    marginBottom: theme.spacing(4)
  },
}));

const StyledTab = withStyles((theme) => ({
  root: {
    color: 'grey',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(14),
    marginRight: theme.spacing(0),
    marginLeft: theme.spacing(0),
    letterSpacing: "2px",
    textTransform: "none",
    padding: "6px 20px",
    '&:focus': {
      opacity: 1,
      color: 'black',
      fontWeight: "bold",
    },
    '&.Mui-selected': {
      color: 'black',
      fontWeight: "bold",
    },
    minWidth: 0,
  },
}))((props: { label: string }) => <Tab {...props} />);

type Props = {
  index: number,
  value: any,
  children: any,
}

const TabPanel = (props: Props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

const TermOfuse = () => {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0)
  const handleTabChange = (event: any, newTabValue: any) => {
    window.scrollTo(0, 0);
    setTabValue(newTabValue);
  }


  return (
    <React.Fragment>
      {/* @ts-expect-error */}
      <Toolbar position='static' className={classes.tab_bar}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          variant="scrollable"
          textColor="secondary"
          TabIndicatorProps={{ style: { display: "none" } }}
        >
          <StyledTab label="抽選利用規約" />
          <StyledTab label="オークション利用規約" />
        </Tabs>
      </Toolbar>
      <Container component="main" maxWidth="lg" className={classes.root}>
        <Box className={classes.paper}>
          <TabPanel index={0} value={tabValue}>
            <div className={classes.box}>
              <TermOfUseText />
            </div>
          </TabPanel>
          <TabPanel index={1} value={tabValue}>
            <div className={classes.box}>
              <AuctionTermOfUseText />
            </div>
          </TabPanel>
        </Box>
      </Container>
      <Copyright />
    </React.Fragment>
  );
};

export default withRouter(TermOfuse);

import { Button, Container, makeStyles, TextField, Typography, withStyles } from "@material-ui/core"
import React, { useContext, useState } from "react"
import { Link } from "react-router-dom"
import { AuthContext } from "../../contexts/Auth"
import BackdropLoading from "../common/BackdropLoading";

const CustomTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#ff0000',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '0',
      '&:hover fieldset': {
        borderColor: '#ff0000',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#ff0000',
      }
    },
  }
})(TextField);

const CustomButton = withStyles({
  root: {
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
    },
    '&:focus': {
      boxShadow: 'none',
    }
  },
})(Button);


const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "grey",
  },
  title: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    fontWeight: 'bold'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#ff0000",
    borderRadius: '0',
    color: "#fff",
    fontWeight: 'bold',
  },
  root: {
    margin: "0 auto",
  },
  text: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4)
  },
  link_wrapper: {
    marginTop: "50px",
    marginBottom: "50px",
    display: "flex",
    flexDirection: "column",
    color: "grey",
  },
  link_text: {
    textAlign: "right",
  },
  link: {
    color: "#ff0000",
    textDecoration: "none",
  }
}));

const ChangeEmail = (props: any) => {
  const classes = useStyles()
  const { currentUser, updateEmail, signout } = useContext<any>(AuthContext)
  const [isLoading, setIsLoding] = useState(false)


  const handleSubmit = async (event: any) => {
    event.preventDefault();
    try {
      setIsLoding(true)
      const { email, password } = event.target.elements;
      const res = await updateEmail(email.value, password.value);
      if (!res) return;
      signout();
      props.history.push("/");
      setIsLoding(false)
    } catch (e) {
      setIsLoding(false)
    }
  };

  if (currentUser) {
    return (
      <Container component="main" maxWidth="xs" className={classes.root}>
        <div className={classes.paper}>
          <Typography variant="subtitle1" className={classes.title}>メールアドレスの変更</Typography>
          {currentUser.email && <Typography component='p'>
            現在登録されているメールアドレス：<b>{currentUser.email}</b>
          </Typography>}
          <Typography component='p' className={classes.text}>変更するメールアドレスを入力し、ボタンを押してください。変更するメールアドレスに、登録用のリンクをご案内いたしますので、認証を行ってください。</Typography>
          <form className={classes.form} onSubmit={handleSubmit}>
            <CustomTextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="新しいメールアドレス"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <CustomTextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="パスワード"
              type="password"
              id="password"
            />
            <CustomButton
              type="submit"
              fullWidth
              variant="contained"
              className={classes.submit}
            >
              変更する
            </CustomButton>
          </form>
        </div>
        <div className={classes.link_wrapper}>
          <Typography variant="subtitle2" className={classes.link_text}>電話番号変更は<Link to="/mypage/change-phone-number" className={classes.link}>こちら</Link></Typography><br></br>
          <Typography variant="subtitle2" className={classes.link_text}>パスワード変更は<Link to="/mypage/change-password" className={classes.link}>こちら</Link></Typography>
        </div>
        <BackdropLoading open={isLoading} />
      </Container>
    )
  } else {
    return (<></>)
  }
}
export default ChangeEmail;
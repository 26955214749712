import { Container, Typography, makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
    main: {
        marginTop: theme.spacing(20),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    }
}))

export const AccessDeniedView = () => {
    const classes = useStyles();
    return (
        <Container maxWidth="sm" className={classes.main}>
            <Typography variant="subtitle1" component="p">
                こちらのページへはアクセスできません。
            </Typography>
        </Container>
    )
}
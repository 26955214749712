import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, makeStyles } from "@material-ui/core"
import { formatFullYearDate } from "../../../utils/Common";
import { CloudFunctions, createTimeStamp, db } from "../../../utils/Firebase";
import BackdropLoading from "../../common/BackdropLoading";
import { useState } from "react";

const useStyles = makeStyles(() => ({
    table: {},
    productNameCell: {
        minWidth: '250px',
    },
    createCell: {
        minWidth: '120px',
    },
    statusCell: {
        minWidth: '100px',
    }
}))

const TicketsTable = ({
    tickets
}: any) => {
    const TicketStatus = ['落選', 'エントリー中', '当選', '決済待ち']
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false)

    // 当選のキャンセル
    const handleCancel = async (_ticket: any) => {
        console.log(_ticket, _ticket.payment_intent.id)
        // return
        // 1.チケットにis_cancelとcancelled_atを追加
        // 2.決済をキャンセルする
        if (_ticket) {
            setIsLoading(true)
            await db.collection('tickets').doc(_ticket.id).update({
                is_cancel: true,
                cancelled_at: createTimeStamp(new Date())
            })
            const func = CloudFunctions.httpsCallable('stripeRefund')
            const res = await func({ paymentIntentId: _ticket.payment_intent.id })
            console.log('当選チケットをキャンセル & 決済を返金', res)
            setIsLoading(false)
        }
    }

    return <>
        <TableContainer component={Paper}>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell className={classes.productNameCell}>商品名</TableCell>
                        <TableCell style={{ minWidth: '190px' }} > 出品者</TableCell>
                        <TableCell style={{ minWidth: '90px' }}>タイプ</TableCell>
                        <TableCell>商品ID</TableCell>
                        <TableCell>Payment Intent</TableCell>
                        <TableCell className={classes.statusCell}>ステータス</TableCell>
                        <TableCell className={classes.statusCell}>配送先名</TableCell>
                        <TableCell className={classes.createCell}>作成日</TableCell>
                        <TableCell className={classes.createCell}></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tickets.map((ticket: any) => <TableRow key={ticket.id}>
                        <TableCell>{ticket.id}</TableCell>
                        <TableCell>{ticket.productInfo?.title}</TableCell>
                        <TableCell>{ticket.productInfo?.vendor}</TableCell>
                        <TableCell>{ticket.productInfo?.product_type}</TableCell>
                        <TableCell>{ticket.product_id}</TableCell>
                        <TableCell>{ticket.payment_intent ? ticket.payment_intent.id : ''}</TableCell>
                        <TableCell>{TicketStatus[ticket.status]}</TableCell>
                        <TableCell>{ticket.shipping_info ? ticket.shipping_info?.second_name + " " + ticket.shipping_info?.first_name : ''}</TableCell>
                        < TableCell > {ticket?.created_at ? formatFullYearDate(new Date(ticket.created_at.seconds * 1000)) : ''}</TableCell>
                        <TableCell>
                            <Button style={{ color: 'red' }} onClick={() => handleCancel(ticket)}>キャンセル</Button>
                        </TableCell>
                    </TableRow>)}
                </TableBody>
            </Table>
        </TableContainer >

        {isLoading && <BackdropLoading open={isLoading} />
        }
    </>;
}

export default TicketsTable
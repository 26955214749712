import { makeStyles, Typography } from '@material-ui/core'
import { useMotionValue, motion } from 'framer-motion'
import React, { useEffect, useRef, useState } from 'react'

import WaitingWinnerTicketImg from '../../images/waiting-win-ticket.png';
import OnCheckingTicketImg from '../../images/on-checking-ticket.png';
import AppraisalCompleteTicketImg from '../../images/appraisal-complete-ticket.png';
import OnPayingTicketImg from '../../images/on-paying-ticket.png';
import WaitingDepositTicketImg from '../../images/waiting-deposit-ticket.png';
import ProceesionedTicketImg from '../../images/processioned-ticket.png';
import OnShippingTicketImg from '../../images/on-shipping-tkcket.png';
import WaitingShippingTicketImg from '../../images/waiting-shipping-ticket.png';
import CancelledTicketImg from '../../images/cancelled-ticket.png';

const useStyles = makeStyles((theme) => ({
    image_wrapper: {
        position: "relative",
        textAlign: "center",
    },
    ticket_img: {
        width: "40%",
        position: "absolute",
        left: "10px",
        top: "10px",
        [theme.breakpoints.down('sm')]: {
            top: '16px',
        }
    },
    thumbnails: {
        display: "flex",
        justifyContent: "center",
        marginTop: "10px",
        flexWrap: "wrap",
    },
    helper_wrapper: {
        width: "100%",
        position: "absolute",
        bottom: "30px",
        textAlign: "center",
    },
    image_helper_text: {
        textAlign: "center",
        background: "rgba(0,0,0,0.4)",
        color: "white",
        width: "auto",
        display: "inline-block",
        borderRadius: "15px",
        padding: "4px 10px",
    },
    image: {
        width: '100%',
        maxWidth: '350px',
        maxHeight: "350px",
        objectFit: 'cover',
    },
    line_images: {
        width: "50px",
        height: "50px",
        margin: "4px",
    },
    line_image: {
        width: "50px",
        height: '50px',
        objectFit: 'cover',
        display: "block",
    },
    // auto_bit_wrapper: {
    //   position: 'absolute',
    //   width: "100%",
    //   bottom: "0",
    //   padding: "10px",
    //   backgroundColor: "#ff0000",
    //   color: "white",
    //   boxSizing: "border-box",
    // },
    auto_bid_text: {
        color: "#ff0000",
        margin: "8px 0",
        letterSpacing: "1px",
        textAlign: "center",
        fontWeight: "bold",
    },
}))

const ProductImagesView = (props: any) => {
    const classes = useStyles()
    const { images, processionStatus } = props
    const zoomMag = 4
    const [isZoomActive, setIsZoomActive] = useState(false)
    const [isZoomActivePc, setIsZoomActivePc] = useState(false)
    const [zoomConstraint, setZoomConstraint] = useState({ top: -170, left: -170, right: 170, bottom: 170 })
    const imageWrapperRef = useRef<HTMLInputElement>(null);
    const imageRef = useRef(null);
    const touchCount = useRef(0)
    const x = useMotionValue(0);
    const y = useMotionValue(0);
    const scale = useMotionValue(1)
    const [selectImageNum, setSelectImageNum] = useState(0)
    const [isPc, setIsPc] = useState(false)

    // 画像幅でPCかSPか判別
    useEffect(() => {
        setIsPc(window.outerWidth > 640)
    }, [window.outerWidth])

    // モバイルはタブルタップで画像拡大と縮小
    const handleTouch = () => {
        if (isPc) return;
        // 500ms以内はタブルタップ
        if (touchCount.current == 0) {
            touchCount.current = 1
            setTimeout(function () {
                touchCount.current = 0
            }, 200);
        } else {
            // 拡大時のタブルタップ
            if (!isZoomActive) {
                const width = imageWrapperRef?.current?.getBoundingClientRect().width
                const height = imageWrapperRef?.current?.getBoundingClientRect().height
                if (!width || !height) return
                const zoomWidth = imageWrapperRef?.current?.getBoundingClientRect().width * zoomMag
                const zoomHeight = imageWrapperRef?.current?.getBoundingClientRect().height * zoomMag
                setZoomConstraint({
                    top: -(zoomHeight / 2 - height / 2),
                    right: zoomWidth / 2 - width / 2,
                    bottom: zoomHeight / 2 - height / 2,
                    left: -(zoomWidth / 2 - width / 2),
                })
                // 縮小時のダブルタップ
            } else {
                setZoomConstraint({
                    top: 0,
                    right: imageWrapperRef?.current?.getBoundingClientRect().width ?? 0,
                    bottom: imageWrapperRef?.current?.getBoundingClientRect().height ?? 0,
                    left: 0,
                })
                x.set(0)
                y.set(0)
            }
            touchCount.current = 0
            setIsZoomActive(!isZoomActive)
        }
    }

    const handleMouseMove = (event: any) => {
        if (!isPc) return
        // カーソルの位置によって画像を移動させる
        const rect = event.currentTarget.getBoundingClientRect()
        const pointerXOnImage = event.clientX - rect.left
        const pointerYOnImage = event.clientY - rect.top
        // PCの時、画像幅は350
        x.set((350 * zoomMag / 2 - 350 / 2) - (350 * pointerXOnImage / 350 * zoomMag - 350 * pointerXOnImage / 350))
        y.set((350 * zoomMag / 2 - 350 / 2) - (350 * pointerYOnImage / 350 * zoomMag - 350 * pointerYOnImage / 350))
        scale.set(zoomMag)
        setIsZoomActivePc(true)
    }

    const hanldeHoverEnd = () => {
        if (!isPc) return
        x.set(0)
        y.set(0)
        scale.set(1)
        setIsZoomActivePc(false)
    }

    return (
        <div>
            <motion.div
                ref={imageWrapperRef}
                style={{ overflow: "hidden" }}
                // @ts-ignore
                whileHover={() => scale.set(4)}
                onMouseMove={handleMouseMove}
                onHoverEnd={hanldeHoverEnd}
                className={classes.image_wrapper}
            >
                {!isZoomActive && <motion.div onTouchStart={handleTouch} style={{ width: "100%", height: "100%" }}></motion.div>}
                <motion.img
                    ref={imageRef}
                    onTouchStart={handleTouch}
                    drag={isZoomActive}
                    dragConstraints={zoomConstraint}
                    style={{ x, y, scale: (isZoomActive || isZoomActivePc) ? zoomMag : 1 }}
                    className={classes.image} src={images[selectImageNum]}
                />

                {processionStatus === 'processionable' && <></>}
                {processionStatus === 'appraisalWatingSelectWinner' && <img src={WaitingWinnerTicketImg} className={classes.ticket_img} />}
                {processionStatus === 'appraisalWatingDeposit' && <img src={WaitingDepositTicketImg} className={classes.ticket_img} />}
                {processionStatus === 'appraisalWaitingShipping' && <img src={WaitingShippingTicketImg} className={classes.ticket_img} />}
                {processionStatus === 'appraisalOnShipping' && <img src={OnShippingTicketImg} className={classes.ticket_img} />}
                {processionStatus === 'appraisalOnChecking' && <img src={OnCheckingTicketImg} className={classes.ticket_img} />}
                {processionStatus === 'appraisalOnPaying' && <img src={OnPayingTicketImg} className={classes.ticket_img} />}
                {processionStatus === 'appraisalComplete' && <img src={AppraisalCompleteTicketImg} className={classes.ticket_img} />}
                {processionStatus === 'appraisalOnReturning' && <img src={OnShippingTicketImg} className={classes.ticket_img} />}
                {processionStatus === 'appraisalCancelled' && <img src={CancelledTicketImg} className={classes.ticket_img} />}
                {processionStatus === 'processioned' && <img src={ProceesionedTicketImg} className={classes.ticket_img} />}
                {processionStatus === 'notRelease' && <></>}

                {(!isZoomActive && !isZoomActivePc) &&
                    <div className={classes.helper_wrapper}>
                        {!isPc && <Typography variant="subtitle2" className={classes.image_helper_text}>タブルタップで画像を拡大</Typography>}
                    </div>
                }
            </motion.div>
            <div className={classes.thumbnails}>
                {images.map((image: any, index: any) => (
                    <div key={index} className={classes.line_images} style={{ outline: selectImageNum === index ? "1.5px solid black" : "", borderSpacing: "0", outlineOffset: "-1.5px" }} >
                        <img key={index} onClick={() => setSelectImageNum(index)} src={image} className={classes.line_image} style={{ verticalAlign: "bottom" }} />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default ProductImagesView
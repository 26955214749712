import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { provinces } from "../../utils/Shipping";
import axios from 'axios'

const initialState = {
  countryCode: "JP",
  firstName: "",
  secondName: "",
  firstNameKana: "",
  secondNameKana: "",
  zipcode: "",
  province: "",
  provinceCode: "",
  address1: "",
  address2: "",
  city: "",
  // 入力されている番号
  phone: "",
  // 入力チェックが完了した整形した番号
  formatedPhone: "",
  isZipcode: false,
  isPhone: false,
  firstNameErrorMessage: "",
  secondNameErrorMessage: "",
  address1ErrorMessage: "",
  zipcodeErrorMessage: "",
  cityErrorMessage: "",
  provinceErrorMessage: "",
  phoneErrorMessage: "",
  searchZipcodeErrorMessage: "",
  isChecked: false,
  isAddressSearching: false,
  selectedShippingMethodsStr: "-1", // -1は新規追加。更新の場合は0以上のindexが入る
}

export const searchAddress = createAsyncThunk("address/get", async (arg, { getState }) => {
  const state = getState()
  const searchAddressApi = "https://zipcloud.ibsnet.co.jp/api/search?zipcode="
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const api_url = searchAddressApi + state.shippingMethod.zipcode
  const res = await axios.get(api_url)
  return res
})

export const shippingMethodSlice = createSlice({
  name: "shippingMethod",
  initialState,
  reducers: {
    errorMessageChange: (state, action) => {
      const { name, value } = action.payload
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      state[name] = value
    },
    inputChange: (state, action) => {
      const { name, value } = action.payload.target
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      state[name] = value
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      state[name + "ErrorMessage"] = ""
    },
    // 郵便番号の入力
    inputZipcodeChange: (state, action) => {
      const { value } = action.payload.target
      // 1.入力の確認
      let isCorrectZipcode = value.match(/^[0-9]{7}$/)
      let isCorrectHyphenZipcode = value.match(/^[0-9]{3}-[0-9]{4}$/)
      if (!isCorrectZipcode && !isCorrectHyphenZipcode) {
        state.zipcodeErrorMessage = state.countryCode === 'JP' ? "７ケタの数字を入力してください。" : "Invalid Information."
        state.zipcode = value
        state.province = ""
        state.provinceCode = ""
        state.address1 = ""
        state.address2 = ""
        state.isZipcode = false
        state.searchZipcodeErrorMessage = ""
      } else {
        let formated_zipcode = isCorrectHyphenZipcode ? Number(String(value).replace("-", "")) : value
        state.zipcode = formated_zipcode
        state.zipcodeErrorMessage = ""
        state.searchZipcodeErrorMessage = ""
        state.isZipcode = true
      }

    },
    // 都道府県選択
    selectProvinceChange: (state, action) => {
      const { value } = action.payload.target
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      const _province_code = provinces[value]
      state.province = value
      state.provinceCode = _province_code
      state.provinceErrorMessage = ""
    },
    // 電話番号入力
    inputPhoneChange: (state, action) => {
      const { value } = action.payload.target
      state.phone = value
      // 入力のテスト  一旦7桁 ~ 15桁の数字のみ
      const numberRegex = /^([0-9]{7,15})$/
      // @ts-expect-error TS(2345): Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
      const result = numberRegex.test(Number(value))
      if (result) {
        state.phoneErrorMessage = ""
        state.isPhone = true
        // @ts-expect-error TS(2322): Type 'number' is not assignable to type 'string'.
        state.formatedPhone = Number(value)
      } else {
        state.phoneErrorMessage = state.countryCode === 'JP' ? "入力された電話番号が正しくありません。" : "Invalid information"
        state.isPhone = false
      }
    },
    // 既存の配送情報を選択（更新のため）
    selectShippingMethod: (state, action) => {
      state.firstName = action.payload.shippingMethod.first_name
      state.secondName = action.payload.shippingMethod.second_name
      state.firstNameKana = action.payload.shippingMethod.first_name_kana
      state.secondNameKana = action.payload.shippingMethod.second_name_kana
      state.zipcode = action.payload.shippingMethod.zipcode
      state.isZipcode = true
      state.province = action.payload.shippingMethod.province
      state.provinceCode = action.payload.shippingMethod.province_code
      state.address1 = action.payload.shippingMethod.address1
      state.address2 = action.payload.shippingMethod.address2
      state.city = action.payload.shippingMethod.city
      state.countryCode = action.payload.shippingMethod.country_code ? action.payload.shippingMethod.country_code : "JP"
      state.phone = action.payload.shippingMethod.phone
      state.isPhone = true
      state.selectedShippingMethodsStr = action.payload.index
    },
    resetShippingMethod: () => initialState,
    inputCheck: (state, action) => {
      // console.log('inputCheck action', action)
      let isChecked = true
      // 国内配送の場合 firstName + secondName + zipCode + province + address1 + phone
      // 海外配送の場合 firstName + secondName + zipcode + province + address1 + phone + city
      if (!state.firstName || !state.secondName || !state.isZipcode || !state.province || !state.address1 || !state.isPhone || (state.countryCode !== 'JP' && !state.city)) {
        // console.log('inputCheck', state)
        console.log('state.firstName', state.firstName)
        isChecked = false
        if (!state.firstName) {
          state.firstNameErrorMessage = state.countryCode === 'JP' ? "入力してください。" : "Can't be empty."
        }
        if (!state.secondName) {
          state.secondNameErrorMessage = state.countryCode === 'JP' ? "入力してください。" : "Can't be empty."
        }
        if (!state.isZipcode) {
          state.zipcodeErrorMessage = state.countryCode === 'JP' ? "入力してください。" : "Can't be empty."
        }
        if (!state.province) {
          state.provinceErrorMessage = state.countryCode === 'JP' ? "選択してください" : "Can't be empty."
        }
        if ((state.countryCode !== 'JP' && !state.city)) {
          state.cityErrorMessage = "Can't be empty."
        }
        if (!state.address1) {
          state.address1ErrorMessage = state.countryCode === 'JP' ? "入力してください。" : "Can't be empty."
        }
        if (!state.isPhone) {
          state.phoneErrorMessage = state.countryCode === 'JP' ? "電話番号を入力してください。" : "Please input currect number."
        }
        if (state.countryCode !== 'JP' && !state.city) {
          state.cityErrorMessage = state.countryCode === 'JP' ? "入力してください。" : "Can't be empty."
        }
      } else {
        state.isChecked = true
      }
      action.payload = { isChecked }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(searchAddress.pending, (state) => {
      state.isAddressSearching = true
    })
    builder.addCase(searchAddress.rejected, (state) => {
      state.searchZipcodeErrorMessage = "住所が見つかりませんでした。"
      state.isAddressSearching = false
    })
    builder.addCase(searchAddress.fulfilled, (state, action) => {
      if (action.payload.data.results) {
        const data = action.payload.data.results[0]
        state.province = data.address1
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        state.provinceCode = provinces[data.address1],
          state.address1 = data.address1 + data.address2 + data.address3
        state.searchZipcodeErrorMessage = ""
        state.provinceErrorMessage = ""
        state.address1ErrorMessage = ""
      } else {
        state.searchZipcodeErrorMessage = "住所が見つかりませんでした。"
      }
      state.isAddressSearching = false
    })
  }
})

export const {
  inputChange,
  inputZipcodeChange,
  selectProvinceChange,
  inputPhoneChange,
  errorMessageChange,
  selectShippingMethod,
  resetShippingMethod,
  inputCheck
} = shippingMethodSlice.actions

export default shippingMethodSlice.reducer
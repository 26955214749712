import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useHistory } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const useStyles = makeStyles((theme) => ({
  products_view: {
    padding: "10px",
    marginBottom: theme.spacing(5),
  },
  products_title: {
    fontWeight: "bold",
    fontSize: "1.3rem",
    padding: "8px 0px",
    margin: "0 auto",
    borderBottom: "1px solid black",
    letterSpacing: "3px",
  },
  grid_wrapper: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    marginTop: "40px",
    [theme.breakpoints.up('sm')]: {
      paddingTop: "60px",
    }
  },
  grid_item: {
    maxWidth: "350px",
    padding: "0 16px",
    marginBottom: theme.spacing(10),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(14),
      padding: "0 24px",
    }
  },
  cardMedia: {
    width: "100%",
    height: 'auto',
  },
  product_title: {
    marginTop: "6px",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    fontSize: "1rem",
    color: "gray",
  },
  archive_time: {
    fontFamily: "digital7-mono",
    color: "black",
    textAlign: "center",
    marginBottom: "20px",
    letterSpacing: "2px",
    [theme.breakpoints.up('sm')]: {
      fontSize: "1.5rem",
    }
  },
}));

const ColumnedProductsView = (props: any) => {
  const classes = useStyles();
  const history = useHistory();
  const { products, type } = props
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec",]

  const toDoubleDigits = (num: any) => {
    num += "";
    if (num.length === 1) {
      num = "0" + num;
    }
    return num;
  };

  let displayTime = ""
  if (type === 'nextProducts') {
    displayTime = "release_date"
  } else if (type === 'archivedProducts') {
    displayTime = "deadline_date"
  }

  const calcFluid = (products_length: any) => {
    if (products_length <= 1) {
      return 12
    } else if (products_length <= 3) {
      return 6
    } else {
      return 4
    }
  }

  const [columnStatus, setColumnStatus] = useState({
    breakpoints: products.length <= 3 ? "450px" : "450px",
    fluid: calcFluid(products.length),
    fontSize: "1.3rem",
  })

  useEffect(() => {
    // 全ての画面で３カラムまであり。2or1カラムの時は350pxまで。
    let isMounted = true
    if (isMounted) {
      let isPcView = window.outerWidth > 640
      if (isPcView) {
        if (products.length <= 1) {
          setColumnStatus({ breakpoints: "450px", fluid: 12, fontSize: "1.6rem" })
        } else if (products.length <= 8) {
          setColumnStatus({ breakpoints: "450px", fluid: 6, fontSize: "1.4rem" })
        } else {
          setColumnStatus({ breakpoints: "450px", fluid: 4, fontSize: "1.4rem" })
        }
      } else {
        if (products.length <= 1) {
          setColumnStatus({ breakpoints: "450px", fluid: 12, fontSize: "1.6rem" })
        } else {
          setColumnStatus({ breakpoints: "450px", fluid: 6, fontSize: "1.4rem" })
        }
      }
    }
    return (() => {
      isMounted = false
    })
  }, [])

  return (
    <Container className={classes.products_view} maxWidth="md" >
      <Typography className={classes.products_title} variant="subtitle1">UPCOMING</Typography>
      <Grid container className={classes.grid_wrapper}>
        {products.map((product: any, index: any) => (
          <Grid
            className={classes.grid_item}
            xs={products.length <= 1 ? 12 : 6}
            sm={4}
            md={4}
            key={index} item
            onClick={() => {
              if (type === 'nextProducts' && product.product_type === 'オークション') {
                history.push(`/auction/${product.id}`)
              } else if (type === 'nextProducts' && product.product_type === '抽選') {
                history.push(`/detail/${product.id}`)
              } else if (type === 'nextProducts' && product.product_type === '先着') {
                history.push(`/coming_soon/${product.id}`)
              } else if (type === 'archivedProducts' && product.product_type === 'オークション') {
                history.push(`/auction/${product.id}`)
              } else if (type === 'archivedProducts' && product.product_type === '抽選') {
                history.push(`/detail/${product.id}`)
              } else if (type === 'archivedProducts' && product.product_type === '先着') {
                history.push(`/onsale/${product.id}`)
              }
            }}>
            <Typography variant="subtitle1" className={classes.archive_time} style={{ fontSize: columnStatus.fontSize }}>
              <span>{months[product.release_date.toDate().getMonth()]}</span>.
              <span>{toDoubleDigits(product[`${displayTime}`].toDate().getDate())}</span>
            </Typography>
            <LazyLoadImage height={350} src={product.image} className={classes.cardMedia} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};
export default ColumnedProductsView;

import React from "react";
import {
  Container,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { useHistory } from 'react-router-dom';
import { formatedYen } from "../../utils/Common";

const useStyles = makeStyles((theme: Theme) => createStyles({
  cardGrid: {
    paddingRight: 0,
    paddingLeft: 0,
    maxWidth: "350px",
    width: "100%",
    marginBottom: theme.spacing(12),
  },
  grid_item: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(14),
    display: "flex",
    flexDirection: "column",
    width: "90%",
    margin: "0 auto",
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(18),
    }
  },
  products_title: {
    fontSize: "1.15rem",
    fontWeight: "bold",
    paddingBottom: "8px",
    letterSpacing: ".5px",
    borderBottom: "1px solid black",
  },
  cardMedia: {
    width: "100%",
  },
  item_info: {
    display: "flex",
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: "center",
    color: "#ff0000",
    marginBottom: "10px",
  },
  inventory_text: {
    height: "38px",
    lineHeight: "38px",
    marginBottom: "4px",
    textAlign: "center",
    fontSize: "1.4rem",
    letterSpacing: "2px",
    color: "#ff0000",
    fontFamily: "digital7-mono",
  },
  product_title: {
    textAlign: "left",
    fontWeight: "bold",
    overflow: "hidden",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
  },
  product_price: {
    textAlign: 'left',
    color: "gray",
  },
}));

const OneColumnProductsView = (props: any) => {
  const classes = useStyles();
  const history = useHistory();
  const { products } = props
  const calcMinInventory = (_product: any) => {
    let arr = _product.variants.map((variant: any) => variant.inventory_quantity).filter((n: any) => n)
    return Math.min(...arr) === Infinity ? 0 : Math.min(...arr)
  }

  return (
    <Container className={classes.cardGrid} maxWidth="md">
      {products.map((product: any) => <Grid className={classes.grid_item} item key={product.id} xs={12} sm={12} md={12} onClick={() => {
        if (product.product_type === '抽選') {
          history.push(`/detail/${product.id}`)
        } else if (product.product_type === 'オークション') {
          history.push(`/auction/${product.id}`)
        } else if (product.product_type === 'nft') {
          history.push(`/nft/${product.id}`)
        } else if (product.product_type === '先着') {
          history.push(`/onsale/${product.id}`)
        }
      }}>
        <Typography variant="subtitle2" className={classes.inventory_text}> LAST {calcMinInventory(product)}</Typography>
        <div className={classes.item_info}>
          <img src={product.image} className={classes.cardMedia} />
        </div>
        <Typography variant="subtitle2" className={classes.product_title}>{product.title}</Typography>
        <Typography className={classes.product_price}>
          ¥ {formatedYen(product.variants[0].price)}
        </Typography>
      </Grid>)}
    </Container>
  );

};
export default OneColumnProductsView;

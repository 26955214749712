import './App.css';
import { BrowserRouter, Route, Switch, useLocation } from 'react-router-dom';
import Home from './pages/Home';
import SignIn from './pages/SignIn';
import Detail from './pages/Detail';
import AuctionDetail from './pages/AuctionDetail';
import AuctionComingSoonDetail from './pages/AuctionComingSoonDetail';
import Privacy from './pages/Privacy';
import TermOfUse from './pages/TermOfUse';
import Tokutei from './pages/Tokutei';
import Contact from './pages/Contact';
import About from './pages/About';
import Authorize from './pages/Authorize';
import Alert from "./redux/common/Alert";
import { Provider } from "react-redux";
import store from "./redux/store";
import { useContext, useEffect, useRef, useState } from "react";
import * as Sentry from "@sentry/react";

// import Tag from './pages/Tag';
import History from './pages/History';
import { AuthProvider } from './contexts/Auth';
import { AnimatePresence, motion, useAnimation } from "framer-motion";
import { makeStyles, ThemeProvider } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core';
import SmsLogin from './pages/SmsLogin';
import ResetPassword from './pages/ResetPassword'
import Header from './components/Header';
import AdminEmails from './pages/AdminEmails';
import AdminIndex from './pages/AdminIndex';
import AdminUsers from './pages/AdminUsers';
import AdminProducts from './pages/AdminProducts';
import SignUpEmail from './pages/SignUpEmail';
import SignUpPassword from './pages/SignUpPassword';
import SignUpSms from './pages/SignUpSms';
import SignUpThanks from './pages/SignUpThanks';
import { fadeVariants } from "./Animations/variants"
import Logo from "./images/dropp.png";
import ScrollToTop from './utils/ScrollToTop';
import ComingSoonDetail from './pages/ComingSoonDetail';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Mypage from './pages/Mypage';
import OnSaleDetail from './pages/OnSaleDetail';
import { CartProvider } from './contexts/Cart';
import CartPayment from './pages/CartPayment';
import CartThanks from './pages/CartThanks';
import { TabValueContext, TabValueProvider } from './contexts/TabValue';
import AdminOrders from './pages/AdminOrders';
import AdminProductDetail from './pages/AdminProductDetail';
import AdminOrderDetail from './pages/AdminOrderDetail';
import BannerView from './components/home/BannerView';
import { db } from './utils/Firebase';

import Products from './pages/Products';
import ProductsNew from './pages/ProductsNew';
import ProductsUpdate from './pages/ProductsUpdate';
import AdminProductUpdate from './pages/AdminProductUpdate';
import { SortValueProvider } from './contexts/Sort';
import Withdrawal from './components/mypage/Withdrawal';
import WithdrawalSuccess from './components/mypage/WithdrawalSuccess';
import { Appraisal } from './pages/Appraisal';
import { AppraisalNew } from './pages/AppraisalNew';
import { AppraisalUpdate } from './pages/AppraisalUpdate';
import { AppraisalDetail } from './pages/AppraisalDetail';
import { AppraisalBidUpdate } from './pages/AppraisalBidUpdate';
// import Archived from './pages/Archived';
import { AdminRoute } from './contexts/route/AdminRoute';
import { VendorRoute } from './contexts/route/VendorRoute';
import { Product } from './types/product';

// test_mode
// const stripePromise = loadStripe(String(process.env.REACT_APP_STRIPE_TEST_PUBLIC_API_KEY))
// live_mode
// @ts-expect-error TS(2580): Cannot find name 'process'. Do you need to install... Remove this comment to see the full error message
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PRODUCTION_PUBLIC_API_KEY);

type Props = {
  bannerProducts: Product[]
}

const Routes = (props: Props) => {
  const theme = createMuiTheme({
    typography: {
      fontFamily: [
        '"Arial"',
        '"ヒラギノ角ゴシック"',
        'sans-serif'
      ].join(','),
    },
  })

  const location = useLocation();
  const [_, rootPath] = location.pathname.split("/")
  const { bannerProducts } = props
  const locationRef = useRef<string>('')
  const { scrollPositionY, setScrollPositionY } = useContext<any>(TabValueContext)

  useEffect(() => {
    _
    //Homeページから遷移した時にスクロールポジションを保持しておく
    if (locationRef.current === '/') {
      setScrollPositionY(window.scrollY)
    }
    // Homeページに入ってきた時に保持していたスクロールポジションに戻る
    if (location.pathname === '/') {
      setTimeout(() => {
        window.scrollTo(0, scrollPositionY)
      }, 1000)
    }

    locationRef.current = location.pathname
  }, [location])

  return (
    <div style={{ overflow: 'hidden' }}>
      <ThemeProvider theme={theme}>
        <Elements stripe={stripePromise}>
          <AnimatePresence exitBeforeEnter initial={true}>
            <Switch>
              <Route path="/" render={() => (
                <div>
                  <ScrollToTop />

                  <Header bannerProducts={bannerProducts} />
                  <Switch>
                    <Route exact path="/" render={() => <Home bannerProducts={bannerProducts} />} />
                    {/* <Route exact path="/" component={() => Home({bannerProducts})} /> */}
                    <Route path="/detail/:product_id" component={Detail} />
                    <Route path="/auction/:product_id" component={AuctionDetail} />
                    <Route path="/onsale/:product_id" component={OnSaleDetail} />
                    <Route path="/cart/payment" component={CartPayment} />
                    <Route path="/cart/thanks" component={CartThanks} />
                    <Route path="/coming_soon/:product_id" component={ComingSoonDetail} />
                    <Route path="/auction_coming_soon/:product_id" component={AuctionComingSoonDetail} />
                    {/* <Route path="/tag/:tag" component={Tag} /> */}
                    <Route exact path="/mypage/widhdrawal/success" component={WithdrawalSuccess} />
                    <Route exact path="/mypage/withdrawal" component={Withdrawal} />
                    {/* <Route path="/archived" component={Archived} /> */}
                    <Route path="/mypage" component={Mypage} />
                    <Route path="/signin" component={SignIn} />
                    <Route path="/signup/email" component={SignUpEmail} />
                    <Route path="/signup/password" component={SignUpPassword} />
                    <Route path="/signup/sms" component={SignUpSms} />
                    <Route path="/signup/thanks" component={SignUpThanks} />
                    <Route path="/reset_password" component={ResetPassword} />
                    <Route path="/sms_login" component={SmsLogin} />
                    <Route path="/history" component={History} />
                    <Route path="/privacy" component={Privacy} />
                    <Route path="/about" component={About} />
                    <Route path="/term-of-use" component={TermOfUse} />
                    <Route path="/tokutei" component={Tokutei} />
                    <Route path="/contact" component={Contact} />
                    <Route path="/authorize" component={Authorize} />
                    <Route exact path="/appraisal/new" component={AppraisalNew} />
                    <Route exact path="/appraisal/:product_id" component={AppraisalDetail} />
                    <Route exact path="/appraisal/:product_id/update" component={AppraisalUpdate} />
                    <Route exact path="/appraisal/:product_id/bid/update" component={AppraisalBidUpdate} />
                    <Route exact path="/appraisal" component={Appraisal} />
                    {/* <Route path="/kobutu" component={Kobutu} /> */}
                    {/* <Route path="/temp" component={Empty} /> */}
                    <Route path="/tos" component={Privacy} />

                    <VendorRoute exact path="/products/new" component={ProductsNew} />
                    <VendorRoute exact path="/products/:product_id" component={ProductsUpdate} />
                    <VendorRoute exact path="/products" component={Products} />


                    <Route path="/admin" render={() => (
                      <Switch location={location} key={rootPath}>
                        <AdminRoute path="/admin/emails" component={AdminEmails} />
                        <AdminRoute path="/admin/productupdate" component={AdminProductUpdate} />
                        <AdminRoute exact path="/admin/products" component={AdminProducts} />
                        <AdminRoute path="/admin/products/:product_id" component={AdminProductDetail} />
                        <AdminRoute exact path="/admin/orders" component={AdminOrders} />
                        <AdminRoute path="/admin/orders/:order_id" component={AdminOrderDetail} />
                        <AdminRoute path="/admin/users" component={AdminUsers} />
                        <AdminRoute exact path="/admin" component={AdminIndex} />
                      </Switch>
                    )} />

                  </Switch>
                </div>
              )} />
            </Switch >
          </AnimatePresence >
        </Elements >
      </ThemeProvider >
    </div >
  )
}


const useStyles = makeStyles((theme) => ({
  logo_wrapper: {
    width: "100vw",
    height: "100vh",
    overflow: "hidden",
    padding: "0",
    margin: "0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  example_container: {
    background: "#f2f2f2",
    width: "45px",
    height: "45px",
  },
  animation_logo: {
    width: "100%",
    maxWidth: "300px",
    maxHeight: "70px",
  },
  image: {
    width: "100%",
    maxWidth: "300px",
    [theme.breakpoints.down('xs')]: {
      maxWidth: "250px",
      position: 'relative',
      top: '-32px',
    }
  },
}))

function App() {
  const [isFirstViewAnimationFlg, setIsFirstViewAnimationFlg] = useState(true);
  const classes = useStyles();
  const controls = useAnimation();
  const [bannerProducts, setBannerProducts] = useState<Product[]>([])

  useEffect(() => {
    let isMounted = true;
    let _now = new Date();
    db.collection("products")
      .where("showcase_date", "<", _now)
      .where("close", "==", false)
      .where("is_production", "==", true)
      .orderBy("showcase_date", "desc")
      .onSnapshot((response) => {
        let now = new Date().getTime() / 1000
        let banner_products: Product[] = []

        response.forEach((doc) => {
          let temp = doc.data() as Product;
          if (now > temp.release_date.seconds && now < temp.deadline_date.seconds) {
            if (temp.is_banner_view) {
              banner_products.push(temp)
            }
          }
        });
        if (isMounted) {
          setBannerProducts(banner_products)
        }
      },
      );
    return (() => {
      isMounted = false
    })
  }, [])


  useEffect(() => {
    const func = async () => {
      let timerId = undefined;
      clearTimeout(timerId);
      timerId = setTimeout(async () => {
        await controls.start('fadeIn')
        await controls.start('fadeOut')
        setIsFirstViewAnimationFlg(false)
      }, 500);
    }
    func()

  }, [])

  if (isFirstViewAnimationFlg && window.location.pathname == '/') {
    return (
      <div className={classes.logo_wrapper}>
        <motion.img
          className={classes.image}
          src={Logo}
          alt="logo"
          animate={controls}
          variants={fadeVariants}
          initial={{ opacity: 0 }}
          exit={{ opacity: 0, transition: { duration: 1 } }}
        />
      </div>
    )
  } else {
    return (
      <Sentry.ErrorBoundary>
        <Provider store={store}>
          <AuthProvider>
            <CartProvider>
              <TabValueProvider>
                <SortValueProvider>
                  <BrowserRouter>
                    {(location.pathname === '/' || !!location.pathname.match('/detail') || !!location.pathname.match('/auction') || !!location.pathname.match('/auction') || !!location.pathname.match('coming_soon') || !!location.pathname.match('/onsale')) && bannerProducts?.length > 0 &&
                      <BannerView bannerProducts={bannerProducts} />
                    }
                    <Routes bannerProducts={bannerProducts} />
                  </BrowserRouter>
                </SortValueProvider>
              </TabValueProvider>
            </CartProvider>
          </AuthProvider>
          <Alert />
        </Provider>
      </Sentry.ErrorBoundary>
    );
  }
}

export default App;

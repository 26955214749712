import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  alert: null,
  loading: false
};

const Common = createSlice({
  name: "common",
  initialState,
  reducers: {
    fetchAlert(state, action) {
      state.alert = action.payload;
      state.loading = false;
    },
    resetAlert(state) {
      state.alert = null;
      state.loading = false;
    },
  },
});

export default Common;

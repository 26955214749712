import React, { useState } from "react";
import { Button, makeStyles, Modal, Slide, Typography } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { CardElement } from '@stripe/react-stripe-js';


const useStyles = makeStyles(() => ({
  slide_wrapper: {
    marginTop: "50px",
    backgroundColor: "white",
    height: "100vh",
    overflow: "scroll",
    color: "grey",
    padding: "0 24px",
  },
  title: {
    fontSize: "1.2rem",
    textAlign: "center",
    marginTop: "50px",
  },
  card_input_wrap: {
    margin: "50px auto",
    textAlign: "center",
    maxWidth: "380px",
  },
  card_element: {
    boxSizing: "border-box",
    border: "1px solid black",
    borderRadius: "0",
    padding: "15px",
    margin: "10px auto"
  },
  error_message: {
    color: "#ff0000",
    margin: "10px 0"
  },
  button_wrapper: {
    marginTop: "30px",
    display: "flex",
    justifyContent: "end",
  },
  active_button: {
    color: "#ff0000",
  },
  negative_button: {
    color: "grey",
  },
  loading_wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "50vh",
  },
}));

const cardElementOptions = {
  hidePostalCode: true,
}

const PaymentMethodInputSlide = (props: any) => {
  const { isOpen, setIsOpen, handleAttachCard, errorMessage, setErrorMessage } = props
  const classes = useStyles();

  // クレカ入力を監視
  const [isCardInputed, setIsCardInputed] = useState(false)
  const handleCardChange = (e: any) => {
    if (e.complete) {
      setErrorMessage("")
    }
    setIsCardInputed(e.complete)
  }

  const handleSubmit = async () => {
    if (!isCardInputed) {
      setErrorMessage('カード情報を入力してください。')
      return
    }
    await handleAttachCard()
  }

  return (
    <Modal
      open={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <Slide direction="up" in={isOpen} mountOnEnter unmountOnExit timeout={550}>
        <div className={classes.slide_wrapper}>
          <div style={{ position: "fixed", top: "80px", left: "30px" }} onClick={() => setIsOpen(false)}><CloseIcon /></div>
          <div>
            <Typography variant="subtitle2" className={classes.title}>新しいカードを追加</Typography>
          </div>
          <div className={classes.card_input_wrap}>
            <CardElement id="card_element" onChange={handleCardChange} className={classes.card_element} options={cardElementOptions} />
            <Typography variant="subtitle2" className={classes.error_message} >{errorMessage}</Typography>
          </div>
          <div className={classes.button_wrapper}>
            <Button variant="text" className={classes.negative_button} onClick={() => setIsOpen(false)}>キャンセル</Button>
            <Button variant="text" className={classes.active_button} onClick={handleSubmit}>保存</Button>
          </div>
        </div>
      </Slide>
    </Modal>
  )
}
export default PaymentMethodInputSlide;

export const lotteryTemplate = (product: any) => {
  return (
    `<p><strong>【抽選概要】</strong></p>` +
    '<p>応募受付期間：月日()12:00～月日()11:59</p>' +
    '<p>当落選発表：月日(）12:30以降を予定</p>' +
    '<p>※商品ページにて当落選連絡を行います。</p>' +
    '<p><br></p>' +
    '<p><strong>【Product Details】</strong></p>' +
    '<p>Title: </p>' +
    '<p>Year of sale: </p>' +
    '<p>Condition: </p>' +
    '<p><br></p>' +
    '<p> <strong>【Lottery Outline】</strong></p>' +
    '<p>Application period: Thursday, October 6, 12:00 - Saturday, October 8, 11:59</p>' +
    '<p>Announcement of winners: Scheduled after 12:30 p.m. on October 8 (Sat.)</p>' +
    '<p>Winners will be notified on the product page.</p>' +
    '<p>※こちらの商品は抽選エントリー時に、オーソリによるクレジットカード枠の確保が行われます。当選者様は当選時に自動で決済され、落選者様には落選時に自動キャンセルされるものですので、エントリー時に事前決済が実施されるものではございません。詳細は<a href="https://dropp.jp/authorize" rel="noopener noreferrer" target="_blank">オーソリ規約</a>をご覧ください。</p>' +
    '<p>&nbsp;※&nbsp;デビットカード、プリペイド方式など、口座、チャージ残高、ポイント残高から直接引き落としされる形式の決済手段は推奨しておりません。これらの決済手法を使用された場合、落選時の返金にお時間を要するケースがございます。ご使用の場合、この旨ご了承ください。</p>' +
    '<p><br></p>' +
    '<p><strong>【注意事項】（必ずご確認ください。）</strong></p>' +
    '<ul>' +
    '<li>抽選結果につきましては、当選・落選に関わらず抽選サイト「Dropp」にてお知らせします。また、抽選結果のお問い合わせにはお答えできません。</li>' +
    '<li>キャリア（docomo、au、Softbank等）のメールに発送通知メールが届かない場合がございますので、PCメールアドレスをお持ちのお客様はそちらをご登録ください。</li>' +
    '<li>ドメイン指定受信を設定されているお客様は@dropp.jpを受信できるように設定してください。</li>' +
    '<li>おひとり様1商品につき1エントリーとさせていただきます。</li>' +
    '<li>複数エントリー等、ルールに該当しない行為が確認できた場合は、抽選対象外となります。</li>' +
    '<li>トラブルが発生した場合、不正が発覚した場合は販売を中止する場合がございます。</li>' +
    `<li>販売元・発送元：${product.vendor}となります。</li>` +
    '<li>お支払い方法は、クレジットカード、デビットカードのみとなります。</li>' +
    '<li>返品・交換はできません。</li>' +
    '<li>当選後のキャンセルは承っておりません。</li>' +
    '<li>配送日時の指定は承っておりません。</li>' +
    '<li>現状有姿でのお引渡しとなります。あらかじめご了承ください。</li>' +
    '<li>万が一、当選商品が不良品の場合は、弊社で交換品がご用意できる場合は交換対応とし、交換品がご用意いたしかねる場合は返金対応といたします。なお、交換品がご用意できる場合は、返品・返金対応は行っておりません。</li>' +
    '<li>外箱の破損は不良品の対象外となります。なお、配送中の破損はすべて返金対応といたします。</li>' +
    '<li>配送手続き後、弊社に返送された場合であってもキャンセルとしてのお取り扱いはいたしかねます。</li>' +
    '<li>配送料は決済時に商品代金に加えて請求させていただきます。</li>' +
    '</ul>'
  )
}

export const auctionTemplate = (product: any) => {
  return (
    `<p><strong>【オークション概要】</strong></p>` +
    '<p>入札受付期間：月日()12:00～月日()11:59</p>' +
    '<p>※商品ページにて当落選連絡を行います。</p>' +
    '<p><br></p>' +
    '<p><strong>【Product Details】</strong></p>' +
    '<p>Title: </p>' +
    '<p>Year of sale:　</p>' +
    '<p>Condition: </p>' +
    '<p><br></p>' +
    '<p> <strong>【Lottery Outline】</strong></p>' +
    '<p>Application period: Thursday, October 6, 12:00 - Saturday, October 8, 11:59</p>' +
    '<p>Announcement of winners: Scheduled after 12:30 p.m. on October 8 (Sat.)</p>' +
    '<p>こちらの商品は入札エントリー時に、オーソリによるクレジットカード枠の確保が行われます。落札者様は落札時に自動で決済され、不成立の場合は、自動キャンセルされるものですので、入札エントリー時に事前決済が実施されるものではございません。</p>' +
    '<p>入札金額が更新された際は自動でクレジットカード枠の確保をキャンセルさせていただきます。</p>' +
    '<p>&nbsp;※&nbsp;デビットカード、プリペイド方式など、口座、チャージ残高、ポイント残高から直接引き落としされる形式の決済手段は推奨しておりません。これらの決済手法を使用された場合、返金にお時間を要するケースがございます。ご使用の場合、この旨ご了承ください。</p>' +
    '<p><br></p>' +
    '<p><strong>【注意事項】（必ずご確認ください。）</strong></p>' +
    '<ul>' +
    '<li>キャリア（docomo、au、Softbank等）のメールに落札メールが届かない場合がございますので、PCメールアドレスをお持ちのお客様はそちらをご登録ください。</li>' +
    '<li>ドメイン指定受信を設定されているお客様は<a tabindex="0" href="https://www.instagram.com/dropp.jp/" class="notranslate" data-mce-fragment="1" data-mce-href="https://www.instagram.com/dropp.jp/" data-mce-tabindex="0">@dropp.jp</a>を受信できるように設定してください。</li>' +
    '<li>トラブルが発生した場合、不正が発覚した場合は販売を中止する場合がございます。</li>' +
    `<li>販売・発送元：${product.vendor}となります。</li>` +
    '<li>お支払い方法は、クレジットカードのみとなります。デビットカード、プリペイド方式など、口座、チャージ残高、ポイント残高から直接引き落としされる形式の決済手段は推奨しておりません。</li>' +
    '<li>ご購入時に商品を選定（交換）すること、キャンセル及びサイズ変更等はできません。現状有姿でのお引渡しになります。</li>' +
    '<li>返品・交換はできません。</li>' +
    '<li>入札エントリー後のキャンセルは承っておりません。</li>' +
    '<li>配送日時の指定は承っておりません。</li>' +
    '<li>万が一、当選商品が不良品の場合は、弊社で交換品がご用意できる場合は交換対応とし、交換品がご用意いたしかねる場合は返金対応といたします。なお、交換品がご用意できる場合は、返品・返金対応は行っておりません。</li>' +
    '<li>外箱の破損は不良品の対象外となります。なお、配送中の破損はすべて返金対応といたします。</li>' +
    '<li>配送料は決済時に商品代金に加えて請求させていただきます。</li>' +
    '</ul>'
  )
}

export const buynowTemplate = (product: any) => {
  return (
    '<p><strong>【Product Details】</strong></p>' +
    '<p>Title: </p>' +
    '<p>Year of sale: </p>' +
    '<p>Condition: </p>' +
    '<p><br></p>' +
    '<p><strong>【注意事項】（必ずご確認ください。）</strong></p>' +
    '<ul>' +
    '<li>キャリア（docomo、au、Softbank等）のメールにメールが届かない場合がございますので、PCメールアドレスをお持ちのお客様はそちらをご登録ください。</li>' +
    '<li>ドメイン指定受信を設定されているお客様は@dropp.jpを受信できるように設定してください。</li>' +
    '<li>トラブルが発生した場合、不正が発覚した場合は販売を中止する場合がございます。</li>' +
    `<li>販売元・発送元：${product.vendor}となります。</li>` +
    '<li>お支払い方法は、クレジットカード、デビットカードのみとなります。</li>' +
    '<li>交換は受け付けておりません</li>' +
    '<li>欠陥・不良の返品につきましては商品の到着後5日以内に限らせていただきます。</li>' +
    '<li>注文キャンセルは購入後２４時間以内に購入履歴から承っております。それ以降のキャンセルはお受け致しかねます。</li>' +
    '<li>配送日時の指定は承っておりません。</li>' +
    '<li>現状有姿でのお引渡しとなります。あらかじめご了承ください。</li>' +
    '<li>万が一、当選商品が不良品の場合は、弊社で交換品がご用意できる場合は交換対応とし、交換品がご用意いたしかねる場合は返金対応といたします。なお、交換品がご用意できる場合は、返品・返金対応は行っておりません。</li>' +
    '<li>外箱の破損は不良品の対象外となります。なお、配送中の破損はすべて返金対応といたします。</li>' +
    '<li>配送料は決済時に商品代金に加えて請求させていただきます。</li>' +
    '</ul>'
  )
}
import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles(() => ({
  backdrop: {
    zIndex: 1500,
    color: '#fff',
  },
}))

const BackdropLoading = (props: any) => {
  const classes = useStyles()
  const { open } = props

  return (
    <Backdrop className={classes.backdrop} open={open} >
      <CircularProgress color='inherit' />
    </Backdrop>
  )
}

export default BackdropLoading
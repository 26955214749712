import { Container, makeStyles, Typography } from "@material-ui/core"
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '100px',
    },
    pageTitle: {
        textAlign: 'center',
        fontWeight: 'bold',
        marginBottom: theme.spacing(4),
        marginTop: theme.spacing(10),
    },
    pageText: {
        marginBottom: '40px',
        fontSize: '0.85rem',
    },
    toTop: {
        color: '#ff0000',
        textAlign: 'center',
        marginBottom: '100px',
        '&:hover': {
            cursor: 'pointer',
        }
    }
}))

const WithdrawalSuccess = () => {
    const classes = useStyles()
    const history = useHistory()

    return (
        <Container className={classes.container} maxWidth='sm'>
            <Typography variant='subtitle1' className={classes.pageTitle}>退会</Typography>
            <Typography variant='subtitle1' className={classes.pageText}>
                Droppをご利用くださいましてありがとうございます。<br />
                <br />
                退会手続きが完了しました。<br />
                またのご利用をお待ちしております。
            </Typography>
            <Typography onClick={() => history.push('/')} className={classes.toTop}>TOPへ戻る</Typography>
        </Container>
    )
}

export default WithdrawalSuccess;
import { Box, Button, Checkbox, CircularProgress, Container, FormControlLabel, FormGroup, makeStyles, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { CloudFunctions, db, PaymentStatusValue, ShippingStatusValue } from '../utils/Firebase'
import { PaymentStatusValueFromNum, ShippingStatusValueFromNum } from '../utils/Orders';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: "50px",
  },
  container_title: {
    fontSize: "1.2rem",
    borderBottom: "1px solid gray",
    marginBottom: "30px",
  },
  select: {
    width: "150px",
  },
  search_button: {

  },
  circular_progress: {
    position: "absolute",
    top: "150px",
    left: "50%",
  },
  data_table: {
    width: "150%",
    height: "400px",
  },
  table_column: {
    '&:hover': {
      cursor: "pointer",
    }
  }
}))

const formateDate = (date: any) => {
  const _date = new Date(date)
  return (_date.getMonth() + 1) + '月' + _date.getDate() + '日'
}

const AdminOrders = () => {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [orders, setOrders] = useState([])
  const selectionModel: any[] = []

  // 初期は発送準備中の商品を取得
  useEffect(() => {
    let orders: any = []
    const getOrders = async () => {
      setIsLoading(true)

      console.log('order取得')
      // await db.collection('orders').where('shippingStatus', 'in', [ShippingStatusValue['PENDING'], ShippingStatusValue['PARTIALLY_FULFILLED']]).get().then(snapshot => {
      await db.collection('orders').orderBy("created_at").get().then(snapshot => {
        snapshot.forEach(doc => {
          let order = doc.data()
          order.id = doc.id
          orders.push(order)
        })
      })
      setOrders(orders)
      setIsLoading(false)
    }
    getOrders()
  }, [])

  const handleSearch = async () => {
    // @ts-expect-error TS(2769): No overload matches this call.
    const states = Object.keys(query).filter(status => query[status])
    let statuses: any = []
    if (queryTitle === 'shippingStatus') {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      statuses = states.map(status => ShippingStatusValue[status])
    } else if (queryTitle === 'paymentStatus') {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      statuses = states.map(status => PaymentStatusValue[status])
    }

    if (statuses.length === 0) return

    setIsLoading(true)
    let orders: any = []
    await db.collection('orders').where(queryTitle, 'in', statuses).get().then(snapshot => {
      snapshot.forEach(doc => {
        let order = doc.data()
        order.id = doc.id
        orders.push(order)
        console.log(order.paymentInfo.id)
      })
    })
    setOrders(orders)
    setIsLoading(false)
  }

  const handleCelClick = (_orderId: any) => {
    window.open(`/admin/orders/${_orderId}`)
  }

  const [queryTitle, setQueryTitle] = useState("")
  const [query, setQuery] = useState(null)
  const handleQueryTitleChange = (e: any) => {
    setQueryTitle(e.target.name)
    // 初期値にチェックを入れる
    if (e.target.name === 'shippingStatus') {
      setQuery({
        // @ts-expect-error TS(2345): Argument of type '{ PENDING: boolean; PARTIALLY_FU... Remove this comment to see the full error message
        'PENDING': true,
        'PARTIALLY_FULFILLED': true,
        'FULFILLED': true,
        'RETURNING': true,
        'CANCELLED': true,
        'PARTIALLY_CANCELLED': true,
      })
    } else if (e.target.name === 'paymentStatus') {
      setQuery({
        // @ts-expect-error TS(2345): Argument of type '{ WAITING_PAYMENT: boolean; AUTH... Remove this comment to see the full error message
        'WAITING_PAYMENT': true,
        'AUTHORIZED': true,
        'CAPTURE_PAYMENT': true,
        'CANCELLED': true,
      })
    }
  }

  const handleQueryChecked = (e: any) => {
    // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
    setQuery({ ...query, [e.target.name]: e.target.checked })
  }

  const downlaodCsv = async () => {
    // 選択されているORDERを取得
    // @ts-expect-error TS(2345): Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
    const _orders = orders.filter(order => selectionModel.includes(order.id))
    const func = CloudFunctions.httpsCallable('sendOrdersCsv')
    const res = await func({ orders: _orders })
    console.log('CSVの送信', res)
  }

  return (
    <div className={classes.container}>
      {isLoading ? <CircularProgress className={classes.circular_progress} /> :
        <Container>
          <Typography variant="subtitle1" className={classes.container_title}>注文管理</Typography>
          <Box mb={2}>
            <Typography variant="subtitle2">検索条件を選択</Typography>
            <FormGroup row style={{ marginBottom: "30px" }}>
              <FormControlLabel
                control={<Checkbox name="shippingStatus" checked={queryTitle === "shippingStatus"} onChange={handleQueryTitleChange} />}
                label="配送状況"
              />
              <FormControlLabel
                control={<Checkbox name="paymentStatus" checked={queryTitle === "paymentStatus"} onChange={handleQueryTitleChange} />}
                label="決済状況"
              />
            </FormGroup>
            {
              queryTitle === 'shippingStatus' && (
                <>
                  <Typography variant="subtitle2">検索条件の詳細選択</Typography>
                  <FormGroup row onChange={handleQueryChecked}>
                    <FormControlLabel
                      control={<Checkbox defaultChecked name='PENDING' />}
                      label="発送準備中"
                    />
                    <FormControlLabel
                      control={<Checkbox defaultChecked name='PARTIALLY_FULFILLED' />}
                      label="一部発送済み"
                    />
                    <FormControlLabel
                      control={<Checkbox defaultChecked name='FULFILLED' />}
                      label="発送済み"
                    />
                    <FormControlLabel
                      control={<Checkbox defaultChecked name='RETURNING' />}
                      label="返品中"
                    />
                    <FormControlLabel
                      control={<Checkbox defaultChecked name='CANCELLED' />}
                      label="キャンセル済み"
                    />
                    <FormControlLabel
                      control={<Checkbox defaultChecked name='PARTIALLY_CANCELLED' />}
                      label="一部キャンセル済み"
                    />
                  </FormGroup>
                </>
              )
            }

            {
              queryTitle === 'paymentStatus' && (
                <>
                  <Typography variant="subtitle2">検索条件の詳細選択</Typography>
                  <FormGroup row onChange={handleQueryChecked}>
                    <FormControlLabel
                      control={<Checkbox defaultChecked name='WAITING_PAYMENT' />}
                      label="決済待ち"
                    />
                    <FormControlLabel
                      control={<Checkbox defaultChecked name='AUTHORIZED' />}
                      label="オーソリ中"
                    />
                    <FormControlLabel
                      control={<Checkbox defaultChecked name='CAPTURE_PAYMENT' />}
                      label="決済済み"
                    />
                    <FormControlLabel
                      control={<Checkbox defaultChecked name='CANCELLED' />}
                      label="返金済み"
                    />
                    <FormControlLabel
                      control={<Checkbox defaultChecked name='PARTIALLY_CANCELLED' />}
                      label="一部返金済み"
                    />
                  </FormGroup>
                </>
              )
            }
          </Box >
          <Button className={classes.search_button} disabled={!queryTitle} onClick={handleSearch}>検索する</Button>
          <Button className={classes.search_button} onClick={downlaodCsv}>CSVでダウンロードする</Button>

          {/* @ts-expect-error */}
          <table cellPadding={3} border={1} style={{ borderCollapse: 'collapse' }}>
            <thead>
              <th>ID</th>
              <th>メール</th>
              <th>決済</th>
              <th>配送</th>
              <th>アイテム</th>
              <th>作成日</th>
              <th></th>
            </thead>
            <tbody>
              {orders.length > 0 && orders.map(order => (
                // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
                <tr key={order.id} onClick={() => handleCelClick(order.id)}>
                  <td>
                    {/* @ts-expect-error */}
                    <Typography variant="subtitle2">{order.id}</Typography>
                  </td>
                  <td>
                    {/* @ts-expect-error */}
                    <Typography variant="subtitle2">{order.email}</Typography>
                  </td>
                  <td>
                    {/* @ts-expect-error */}
                    <Typography variant="subtitle2">{PaymentStatusValueFromNum[order.paymentStatus]}</Typography>
                  </td>
                  <td>
                    {/* @ts-expect-error */}
                    <Typography variant="subtitle2">{ShippingStatusValueFromNum[order.shippingStatus]}</Typography>
                  </td>
                  <td>
                    <Typography variant="subtitle2">
                      {/* @ts-expect-error */}
                      {order.items.map((item: any, index: any) => {
                        console.log('item', item.productInfo.title)
                        return (
                          <div key={index}>
                            {index + 1}:&nbsp;{item.productInfo.title}
                          </div>
                        )
                      })}
                    </Typography>
                  </td>
                  <td>
                    {/* @ts-expect-error TS(2339): Property 'created_at' does not exist on type 'neve... Remove this comment to see the full error message */}
                    <Typography variant="subtitle2">{formateDate(order.created_at)}</Typography>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Container>
      }
    </div >
  );
}

export default AdminOrders
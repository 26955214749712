import { Button, Container, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, makeStyles } from "@material-ui/core"
import { useState } from "react"
import { CloudFunctions, db } from "../../../utils/Firebase"
import BackdropLoading from "../../common/BackdropLoading"
import TicketsTable from "../common/TicketsTable"
import { formatFullYearDate } from "../../../utils/Common"
import { User } from "../../../types/user"

const useStyles = makeStyles(() => ({
    container: {
        marginTop: '40px',
    },
    wrapper: {
        marginBottom: '40px'
    },
    searchItem: {
        display: 'flex',
        alignItems: 'bottom',
    },
    entryCountView: {
        minWidth: '200px',
    },
    tableTitle: {
        marginBottom: '8px',
    }
}))

const UserSearchView = () => {
    const classes = useStyles();
    const [searchEmail, setSearchEmail] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [myTickets, setMyTickets] = useState([])
    const [targetUser, setTargetUser] = useState<User | null>(null)

    // AuthUser取得 -> Ticket取得 -> DBUser取得
    const handleSearch = async () => {
        setIsLoading(true)
        try {
            const getAuthUser = CloudFunctions.httpsCallable('getAuthUserByEmail')
            const user = await getAuthUser({ email: searchEmail })
            if (!user.data) {
                setIsLoading(false)
                return
            }

            let _myTickets: any = []
            db.collection('tickets')
                .where('uid', "==", user.data.uid)
                .orderBy('created_at', 'desc')
                .get()
                .then(snapshot => {
                    snapshot.forEach(async doc => {
                        let ticket = doc.data()
                        ticket.id = doc.id

                        // 商品名がTicketに入っていないので取得
                        db.collection("products").doc(ticket.product_id.toString()).get().then(doc => {
                            ticket.productInfo = doc.data()
                        })

                        _myTickets.push(ticket)
                    })
                    setMyTickets(_myTickets)
                })
            setTargetUser(user.data)
        } catch (e) {
            console.log('error: ', e)
        }

        setTimeout(() => {
            setIsLoading(false)
        }, 1000)
    }

    const handleWithdrawalUsersItem = async () => {
        setIsLoading(true)
        let _myTickets: any = []

        db.collection('tickets')
            .where('email', "==", 'deleted_' + searchEmail)
            .get()
            .then(snapshot => {
                snapshot.forEach(async doc => {
                    let ticket = doc.data()
                    ticket.id = doc.id

                    // 商品名がTicketに入っていないので取得
                    db.collection("products").doc(ticket.product_id.toString()).get().then(doc => {
                        ticket.productInfo = doc.data()
                    })

                    _myTickets.push(ticket)
                })
                setMyTickets(_myTickets)
            })
        setTimeout(() => {
            setIsLoading(false)
        }, 1000)
    }

    return (
        <Container className={classes.container}>

            <Grid className={classes.wrapper}>
                <Typography variant="body2" className={classes.tableTitle}>ユーザーを検索</Typography>
                <div className={classes.searchItem}>
                    <TextField
                        onChange={(e) => setSearchEmail(e.target.value)}
                        variant="outlined"
                        label="Email"
                    />
                    <Button
                        style={{ marginLeft: '16px' }}
                        onClick={handleSearch}
                        variant="outlined"
                    >
                        検索
                    </Button>

                    <Button
                        style={{ marginLeft: '16px' }}
                        onClick={handleWithdrawalUsersItem}
                        variant="outlined"
                    >
                        退会ユーザーを検索
                    </Button>
                </div>
            </Grid>

            <Grid className={classes.wrapper}>
                <Typography variant="body2" className={classes.tableTitle}>ユーザー詳細</Typography>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>UID</TableCell>
                                <TableCell>Eamil</TableCell>
                                <TableCell>電話番号</TableCell>
                                <TableCell className={classes.entryCountView}>エントリー数</TableCell>
                                <TableCell>作成日</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {targetUser && (
                                <TableRow>
                                    <TableCell>{targetUser.uid}</TableCell>
                                    <TableCell>{targetUser.email}</TableCell>
                                    <TableCell>{targetUser.phoneNumber}</TableCell>
                                    <TableCell>{myTickets.length}</TableCell>
                                    <TableCell>{formatFullYearDate(targetUser.metadata.creationTime)}</TableCell>
                                    {/* <TableCell>{targetUser.uid}</TableCell> */}
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

            </Grid>

            <Grid className={classes.wrapper}>
                <Typography variant="body2" className={classes.tableTitle}>チケット</Typography>
                <TicketsTable tickets={myTickets} />
            </Grid>

            <BackdropLoading open={isLoading} />

        </Container>
    )
}

export default UserSearchView
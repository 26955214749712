import { createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { formatedYen } from '../../utils/Common'

const useStyles = makeStyles((theme: Theme) => createStyles({
  grid_item: {
    padding: "0 12px",
    marginBottom: theme.spacing(12),
    "&:hover": {
      cursor: "pointer",
    },
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(16),
    },
  },
  cardMedia: {
    width: "100%",
  },
  product_title: {
    fontSize: ".85rem",
    overflow: "hidden",
    maxHeight: "50px",
    lineHeight: "25px",
    // whiteSpace: "nowrap",
    // textOverflow: "ellipsis",
    color: "gray",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
    [theme.breakpoints.up('sm')]: {
      fontSize: "0.9rem",
    }
  },
  inventory_text: {
    height: "38px",
    lineHeight: "38px",
    marginBottom: "4px",
    textAlign: "center",
    fontSize: "1.4rem",
    letterSpacing: "2px",
    color: "#ff0000",
    fontFamily: "digital7-mono",
  },
  product_price: {
    fontSize: "0.85rem",
    fontWeight: "bold",
    [theme.breakpoints.up('sm')]: {
      fontSize: "0.9rem",
    }
  },
}))

const ThreeColumnProductsView = (props: any) => {
  const { products } = props
  const history = useHistory()
  const classes = useStyles()

  const calcMinInventory = (_product: any) => {
    let arr = _product.variants.map((variant: any) => variant.inventory_quantity).filter((n: any) => n)
    return Math.min(...arr) === Infinity ? 0 : Math.min(...arr)
  }

  return (
    <Grid container>
      {products.map((product: any) => <Grid
        item
        className={classes.grid_item}
        key={product.id}
        xs={6}
        sm={4}
        md={4}
        onClick={() => history.push(`/onsale/${product.id}`)}
      >
        <Typography variant="subtitle2" className={classes.inventory_text}> LAST {calcMinInventory(product)}</Typography>
        <img src={product.image} className={classes.cardMedia} />
        <Typography variant="subtitle2" className={classes.product_title}>{product.title}</Typography>
        <Typography className={classes.product_price}>
          {product.product_type === 'オークション' && <>¥ {String(product.current_bid_amount ? product.current_bid_amount : product.variants[0].price).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}</>}
          {product.product_type === '抽選' && <>¥ {formatedYen(product.variants[0].price)}</>}
          {product.product_type === 'nft' && <>¥ {formatedYen(product.variants[0].price)}</>}
          {product.product_type === '先着' && <>¥ {formatedYen(product.variants[0].price)}</>}
        </Typography>
      </Grid>)}
    </Grid>
  );
}

export default ThreeColumnProductsView
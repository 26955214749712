import { Backdrop, Button, CircularProgress, Container, FormControl, FormControlLabel, FormLabel, List, ListItem, makeStyles, Radio, RadioGroup, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { calculate_dropp_fees, formatedYen } from '../../../utils/Common'
import { db } from '../../../utils/Firebase'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const useStyles = makeStyles(() => ({
    table_wrapper: {
        padding: "16px 10px 10px",
        border: "1px solid gray",
        margin: "20px auto",
    },
    date_picker_view: {
        display: "flex",
        justifyContent: "space-around",
    },
}))

// 予想粗利益を計算(在庫 × 単価)
const calcProfit = (_product: any) => {
    let total = 0
    _product.variants.forEach((variant: any) => {
        total += Number(variant.price) * variant.inventory_quantity
    })
    return total * calculate_dropp_fees(_product.vendor)

}

// 予想GMVを計算(在庫 × 単価 × 獲得手数料)
const calcGmv = (_product: any) => {
    let total = 0
    _product.variants.forEach((variant: any) => {
        total += Number(variant.price) * variant.inventory_quantity
    })
    return total
}

// 管理者による入札がどうかを判定
const isAdminBid = (_product: any) => {
    const adminUids = ['NTk2MTk0MDY5NzI4Ng==', 'NTI1Njc4MjYxMDYzMA=='] // 橋本, 天野
    return adminUids.some(_uid => _product.highest_bid_ticket?.uid === _uid)
}

type KPIType = {
    scheduledProductsCount: number,
    closeProductsCount: number,
    estimatedGMV: number,
    estimatedProfit: number,
    compoleteSoldGmv: number,
    actualGmv: number,
    actualProfit: number,
    soldOutProductsCount: number,
}

const AdminKpiView = () => {
    const classes = useStyles()
    // 初期値は今週の月曜日
    const [startDate, setStartDate] = useState(() => {
        const now = new Date()
        const today = now.getDate()
        const dayNum = now.getDay()
        return new Date(now.getFullYear(), now.getMonth(), today - dayNum + 1)
    });
    // 初期値は今週の日曜日
    const [endDate, setEndDate] = useState(() => {
        const now = new Date()
        const today = now.getDate()
        const dayNum = now.getDay()
        return new Date(now.getFullYear(), now.getMonth(), today + (7 - dayNum))
    });
    const [isLoading, setIsLoading] = useState(false)
    const [kpiState, setKpiState] = useState<KPIType>({} as KPIType)
    const days = ["日", "月", "火", "水", "木", "金", "土"]

    // 商品のKPIを取得する
    const getKpiState = async (_products: any) => {
        let now = new Date().getTime() / 1000
        let scheduledProductsCount = 0
        let closeProductsCount = 0
        let estimatedGMV = 0
        let estimatedProfit = 0
        let compoleteSoldGmv = 0
        let actualGmv = 0
        let actualProfit = 0
        let soldOutProductsCount = 0

        for (const product of _products) {

            // KPI予定
            if (product.deadline_date.seconds > now) {
                scheduledProductsCount++

                if (product.product_type === 'オークション') {
                    estimatedGMV += Number(product.variants[0].price)
                    estimatedProfit += Number(product.variants[0].price) * 0.3

                } else if (product.product_type === '抽選') {
                    estimatedGMV += calcGmv(product)
                    estimatedProfit += calcProfit(product)
                }

                // KPI実績
            } else {
                closeProductsCount++
                if (product.product_type === 'オークション' && product.highest_bid_ticket && !isAdminBid(product)) {
                    actualGmv += product.current_bid_amount + product.current_bid_amount * 0.15 //商品代金 + Dropp手数料
                    actualProfit += product.vendor === "Dropp" ? product.current_bid_amount * 0.15 : product.current_bid_amount * 0.3 //商品代金 + Dropp手数料 + 出品者手数料
                    compoleteSoldGmv += product.current_bid_amount + product.current_bid_amount * 0.15 //商品代金 + Dropp手数料
                    soldOutProductsCount += 1

                } else if (product.product_type === 'オークション' && (!product.highest_bid_ticket || isAdminBid(product))) {
                    compoleteSoldGmv += Number(product.variants[0].price) + Number(product.variants[0].price) * 0.15 //商品代金 + Dropp手数料

                } else if (product.product_type === '抽選') {
                    // 売上の計算
                    await db.collection('tickets').where('product_id', '==', Number(product.id)).where('status', '==', 2).get().then(snapshot => {
                        let gmv = 0
                        // オプションごとに金額が違う可能性あり
                        snapshot.forEach(doc => {
                            let ticket = doc.data()
                            gmv += ticket.payment_intent?.amount ? ticket.payment_intent.amount : Number(product.variants[0].price)
                        })
                        actualGmv += gmv //実績GMV
                        actualProfit += gmv * calculate_dropp_fees(product.vendor) // 実績粗利益

                        // 完売かどうか
                        let totalInventoryQuantity = 0
                        product.variants.forEach((variant: any) => {
                            totalInventoryQuantity += variant.inventory_quantity
                            compoleteSoldGmv += variant.inventory_quantity * Number(variant.price) //完売した時のGMVも出しておく
                        })
                        if (totalInventoryQuantity === snapshot.size) soldOutProductsCount++

                    })
                }


            }
        }

        setKpiState({
            scheduledProductsCount,
            closeProductsCount,
            estimatedGMV,
            estimatedProfit,
            compoleteSoldGmv,
            actualGmv,
            actualProfit,
            soldOutProductsCount,
        })

    }

    // 選択された期間のKPIを取得
    const handleSearchKpi = async () => {
        setIsLoading(true)
        await db.collection('products').orderBy(selectedSearchStr).startAt(startDate).endAt(endDate).get().then(async snapshot => {
            let products: any = []
            snapshot.forEach(async doc => {
                let product = doc.data()

                if (product.product_type !== '先着' && product.is_production) {
                    products.push(product)
                }
            })
            await getKpiState(products)
        })

        setTimeout(() => {
            setIsLoading(false)
        }, 1000);

    }

    const [selectedSearchStr, setSelectedSearchStr] = useState("deadline_date")
    const handleRadioChange = (e: any) => {
        setSelectedSearchStr(e.target.value)
    }

    return (
        <Container maxWidth="md">
            {isLoading ?
                <Backdrop style={{ zIndex: 5000, }} open={isLoading}>
                    <CircularProgress style={{ color: "white" }} size={42} />
                </Backdrop>
                :
                <>
                    <FormControl>
                        <FormLabel style={{ color: "black", fontSize: "0,8rem" }}>検索日</FormLabel>
                        <RadioGroup style={{ display: "flex", flexDirection: "row" }} onChange={handleRadioChange} value={selectedSearchStr}>
                            <FormControlLabel control={<Radio />} value="deadline_date" label="締切日で選択"></FormControlLabel>
                            <FormControlLabel control={<Radio />} value="release_date" label="開始日で選択"></FormControlLabel>
                        </RadioGroup>
                    </FormControl>

                    <div className={classes.date_picker_view}>
                        <div style={{ width: "auto" }}>
                            {/* @ts-ignore */}
                            <DatePicker selected={startDate} style={{ width: "300px" }} onChange={(date: any) => setStartDate(date)} />
                        </div>
                        ~
                        <div style={{ width: "auto" }}>
                            {/* @ts-ignore */}
                            <DatePicker selected={endDate} style={{ width: "300px" }} onChange={(date: any) => setEndDate(date)} />
                        </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "end", marginTop: "20px" }}>
                        <Button style={{ border: "1px solid black", borderRadius: "0" }} onClick={handleSearchKpi}>検索</Button>
                    </div>
                    <div className={classes.table_wrapper}>

                        <Typography variant="subtitle2" style={{ textAlign: "center", fontWeight: "bold", }}>今週のDropp</Typography>
                        <Typography variant="subtitle2" style={{ textAlign: "center", fontWeight: "bold", }}>
                            {startDate.getMonth() + 1 + '月' + startDate.getDate() + '日（' + days[startDate.getDay()] + '）'}~
                            {endDate.getMonth() + 1 + '月' + endDate.getDate() + '日（' + days[endDate.getDay()] + '）'}
                        </Typography>

                        <List>
                            <ListItem>
                                <Typography variant="subtitle2">Dropp予定商品数： </Typography>
                                <Typography variant="subtitle2">{kpiState.scheduledProductsCount}個</Typography>
                            </ListItem>
                            <ListItem>
                                <Typography variant="subtitle2">予想GMV： </Typography>
                                <Typography variant="subtitle2">¥ {formatedYen(kpiState.estimatedGMV)}</Typography>
                            </ListItem>
                            <ListItem>
                                <Typography variant="subtitle2">予想粗利益: </Typography>
                                <Typography variant="subtitle2">¥ {formatedYen(kpiState.estimatedProfit)}</Typography>
                            </ListItem>
                            <div style={{ height: "20px" }}></div>
                            <ListItem>
                                <Typography variant="subtitle2">Dropp終了商品数： </Typography>
                                <Typography variant="subtitle2">{kpiState.closeProductsCount}個</Typography>
                            </ListItem>
                            <ListItem>
                                <Typography variant="subtitle2">完売時GMV: </Typography>
                                <Typography variant="subtitle2">¥ {formatedYen(kpiState.compoleteSoldGmv)}</Typography>
                            </ListItem>
                            <ListItem>
                                <Typography variant="subtitle2">実績GMV: </Typography>
                                <Typography variant="subtitle2">¥ {formatedYen(kpiState.actualGmv)}</Typography>
                            </ListItem>
                            <ListItem>
                                <Typography variant="subtitle2">実績粗利益: </Typography>
                                <Typography variant="subtitle2">¥ {formatedYen(kpiState.actualProfit)}</Typography>
                            </ListItem>
                            <ListItem>
                                <Typography variant="subtitle2">完売率: </Typography>
                                <Typography variant="subtitle2">{(kpiState.soldOutProductsCount / kpiState.closeProductsCount * 100)} % ({kpiState.soldOutProductsCount} / {kpiState.closeProductsCount})</Typography>
                            </ListItem>
                        </List>

                    </div>
                </>
            }
        </Container>
    );
}

export default AdminKpiView
import React, { useContext, memo } from "react";
import {
  AppBar,
  makeStyles,
  MuiThemeProvider,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { createMuiTheme } from '@material-ui/core';
import { useHistory, useLocation } from "react-router-dom";
import Logo from "../images/dropp.png";
import DrawerMenu from "./DrawerMenu";
import Cart from "./Cart";
import { TabValueContext } from "../contexts/TabValue";
import SortView from "./Sort";
import { Product } from "../types/product";

const useStyles = makeStyles((theme) => ({
  appbar: {
    paddingTop: "env(safe-area-inset-top)",
    boxShadow: "none !important",
    [theme.breakpoints.up(1020)]: {
      paddingLeft: 'calc(50% - 1020px/2);',
    }
  },
  toolbar: {
    position: "relative",
    maxWidth: '1020px',
    height: "70px",
    paddingRight: 0,
    boxSizing: "border-box",
  },
  image_container: {
    width: "22%",
    maxWidth: "125px",
    height: "80%",
    position: "absolute",
    left: 0,
    right: 0,
    margin: "auto",
  },
  cart_container: {
    padding: "10px",
    position: "absolute",
    right: "80px",
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  title: {
    position: "absolute",
    left: 0,
    right: 0,
    width: '75%',
    margin: "auto",
  },
}));

const theme = createMuiTheme({});

type Props = {
  title?: string;
  processionStatus?: string;
  bannerProducts: Product[];
}

const Header = memo((props: Props) => {
  const classes = useStyles();
  const { title, processionStatus, bannerProducts } = props;
  const { tabValue, setTabValue } = useContext<any>(TabValueContext)
  const history = useHistory()
  const location = useLocation()

  return (
    <MuiThemeProvider theme={theme}>
      <AppBar className={classes.appbar} position="fixed" color="inherit"
        style={(bannerProducts?.length > 0 && (location.pathname === '/' || !!location.pathname.match('/detail') || !!location.pathname.match('/auction') || !!location.pathname.match('/auction') || !!location.pathname.match('coming_soon') || !!location.pathname.match('/onsale')) && bannerProducts?.length > 0)
          ? { top: "40px" } : {}}>
        <Toolbar className={classes.toolbar}>
          {location.pathname === "/" && (
            <>
              <div className={classes.image_container}>
                <img onClick={() => setTabValue(0)} className={classes.image} src={Logo} alt="logo" />
              </div>
            </>
          )}
          {location.pathname.indexOf("/detail/") === 0 &&
            processionStatus === "processioned" && (
              <div className={classes.image_container} onClick={() => history.push('/')}>
                <img className={classes.image} src={Logo} alt="logo" />
              </div>
            )}
          {location.pathname.indexOf("/detail/") === 0 &&
            processionStatus !== "processioned" && (
              <div className={classes.image_container} onClick={() => history.push('/')}>
                <img className={classes.image} src={Logo} alt="logo" />
              </div>
            )}
          {location.pathname !== "/" && (
            <>
              <DrawerMenu {...props} />
              {title ?
                <Typography className={classes.title} align="center" variant="h6">
                  {title}
                </Typography>
                :
                <div className={classes.image_container} onClick={() => history.push('/')}>
                  <img className={classes.image} src={Logo} alt="logo" />
                </div>
              }
            </>
          )}
          {location.pathname === "/" && <DrawerMenu {...props} />}
          {location.pathname === "/" && tabValue == 3 && <SortView />}
          {(location.pathname === "/" || location.pathname.match(/onsale/)) && <Cart />}
        </Toolbar>
      </AppBar>
    </MuiThemeProvider>
  );
});

export default Header

import { Button, Grid, makeStyles } from "@material-ui/core";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../contexts/Auth";

const useStyles = makeStyles((theme) => ({
    producWrapper: {
        display: 'flex',
        marginBottom: '60px',
        width: '100%',
    },
    imageWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    image: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '80%',
        }
    },
    infoWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '24px 0',
        width: "100%",
    },
}))

export default function ProductInfoView({
    product
}: any) {
    const classes = useStyles()
    const history = useHistory()
    const { teaserEmail } = useContext<any>(AuthContext)

    return (
        <Grid container className={classes.producWrapper}>
            <Grid item xs={12} sm={5} className={classes.imageWrapper}>
                {product.images?.length > 0 && <img className={classes.image} src={product.images[0].src} />}
            </Grid>
            <Grid item xs={12} sm={7} className={classes.infoWrapper}>
                <div style={{ marginBottom: '24px' }}>
                    <p style={{ margin: '0 0 8px' }}>{product.title}</p>
                    {product.bid_history?.length > 0 && <p style={{ margin: '0 0 8px' }}>{product.bid_history?.length} 入札</p>}
                </div>

                {!teaserEmail?.is_buyer &&
                    <div style={{ width: '100%' }}>
                        <Button style={{ width: '100%' }} onClick={() => history.push(`/appraisal/${product.id}/update`)}>商品情報を更新する</Button>
                    </div>
                }
            </Grid>
        </Grid>
    )
}
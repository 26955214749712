import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { AuthContext } from "../contexts/Auth";
import { firebase } from "../utils/Firebase";
import {
  Button,
  TextField,
  makeStyles,
  Container,
  withStyles,
} from "@material-ui/core";
import Copyright from "../components/Copyright";
import common from "../redux/common/commonSlice";
// import { GoogleReCaptchaProvider, GoogleReCaptcha } from "react-google-recaptcha-v3";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(8),
    minHeight: '94vh',
  },
  paper: {
    marginTop: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(4),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#ff0000",
    color: "#fff",
    borderRadius: '0',
    fontWeight: 'bold',
  },
  recaptcha_container: {
    margin: 'auto',
    height: '78px',
  },
  textfield: {
    marginBottom: theme.spacing(2),
  },
}));

const CustomTextField = withStyles({
  root: {
    marginTop: '8px',
    '& label.Mui-focused': {
      color: '#ff0000',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '0',
      '&:hover fieldset': {
        borderColor: '#ff0000',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#ff0000',
      }
    },
  }
})(TextField);

const CustomButton = withStyles({
  root: {
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
    },
    '&:focus': {
      boxShadow: 'none',
    }
  },
})(Button);

const SmsLogin = (props: any) => {
  const { currentUser } = useContext<any>(AuthContext);
  const [confirmationResult, setConfirmationResult] = useState('');
  const [verifyState, serVerifyState] = useState(1);
  const classes = useStyles();
  const dispatch = useDispatch();
  const numberRegex = /^0\d{9,10}$/

  const sendSmsVerification = async (event: any) => {
    let sendNumber = "";
    event.preventDefault();
    const { phoneNumber } = event.target.elements;
    if (!numberRegex.test(phoneNumber.value)) {
      dispatch(
        common.actions.fetchAlert({
          alerts: [{ message: "入力された電話番号が正しくありません。" }],
        })
      );
      return;
    }
    if (phoneNumber.value.slice(0, 1) == "0") {
      sendNumber = '+81' + phoneNumber.value.slice(1);
    }
    try {
      // @ts-expect-error TS(2339): Property 'recaptchaVerifier' does not exist on typ... Remove this comment to see the full error message
      const confirmationResult = await firebase.auth().signInWithPhoneNumber(sendNumber, window.recaptchaVerifier)
      // @ts-expect-error TS(2345): Argument of type 'ConfirmationResult' is not assig... Remove this comment to see the full error message
      setConfirmationResult(confirmationResult)
      serVerifyState(2)
      dispatch(
        common.actions.fetchAlert({
          alerts: [{ message: "確認コードを送信しました。" }],
        })
      );
    } catch (e) {
      console.error('error is ', e)
      // @ts-expect-error TS(2339): Property 'grecaptcha' does not exist on type 'Wind... Remove this comment to see the full error message
      window.grecaptcha.reset(window.recaptchaWidgetId);
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      switch (e.code) {
        case "auth/too-many-requests":
          dispatch(
            common.actions.fetchAlert({
              alerts: [{ message: "時間をあけて再度送信をお願いいたします。" }],
            })
          );
          break;
        default:
          dispatch(
            common.actions.fetchAlert({
              alerts: [{ message: "電話番号認証に失敗しました。" }],
            })
          );
      }
    }
  };

  const sendVerificationCode = async (event: any) => {
    event.preventDefault();
    const { verifyCode } = event.target.elements;
    try {
      if (currentUser) {
        // @ts-expect-error TS(2339): Property 'verificationId' does not exist on type '... Remove this comment to see the full error message
        const credential = await firebase.auth.PhoneAuthProvider.credential(confirmationResult.verificationId, verifyCode.value)
        await currentUser.linkAndRetrieveDataWithCredential(credential)
        props.history.push('/');
      } else {
        // @ts-expect-error TS(2339): Property 'verificationId' does not exist on type '... Remove this comment to see the full error message
        await firebase.auth.PhoneAuthProvider.credential(confirmationResult.verificationId, verifyCode.value)
        // @ts-expect-error TS(2339): Property 'confirm' does not exist on type 'string'... Remove this comment to see the full error message
        confirmationResult.confirm(verifyCode.value).then(() => {
          props.history.push('/');
        })
      }
    } catch (e) {
      // e.code: "auth/provider-already-linked"
      console.log('error', e);
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      switch (e.code) {
        case "auth/credential-already-in-use":
          dispatch(
            common.actions.fetchAlert({
              alerts: [{ message: "こちらの電話番号はすでに他のアカウントと連携しております。" }],
            })
          );
          break;
        case "auth/provider-already-linked":
          dispatch(
            common.actions.fetchAlert({
              alerts: [{ message: "こちらの電話番号はすでに承認済みです。" }],
            })
          );
          break;
        default:
          dispatch(
            common.actions.fetchAlert({
              alerts: [{ message: "電話番号認証に失敗しました。" }],
            })
          );
      }
    }
  }

  // 表示データの取得
  React.useEffect(() => {
    const _getRecaptcha = async () => {
      const recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        "recaptcha-container",
        {
          size: 'normal',
          callback: () => {
          },

          //reCAPTCHA応答が期限切れになり、ユーザーが再検証する必要があるときに実行される
          "expired-callback": () => {
          },

          // reCAPTCHAでエラー（通常はネットワーク接続）が発生し、接続が復元されるまで続行できない場合に実行される
          "error-callback": () => {
          }
        }
      );
      const recaptchaWidgetId = recaptchaVerifier.render();
      // @ts-expect-error TS(2339): Property 'recaptchaVerifier' does not exist on typ... Remove this comment to see the full error message
      window.recaptchaVerifier = recaptchaVerifier;
      // @ts-expect-error TS(2339): Property 'recaptchaWidgetId' does not exist on typ... Remove this comment to see the full error message
      window.recaptchaWidgetId = recaptchaWidgetId;
    }
    _getRecaptcha()
  }, []);

  return (
    <React.Fragment>
      {/* <GoogleReCaptchaProvider reCaptchaKey="6LcjetsaAAAAAJ7JPEjs9a7CUgxUKbrbxIJOQPnX">     */}
      <Container component="main" maxWidth="xs" className={classes.container}>
        <>
          <div className={classes.paper}>
            {/* <Typography variant="h6" className={classes.title}>電話番号認証</Typography> */}
            {verifyState == 1 &&
              <form className={classes.form} onSubmit={sendSmsVerification}>
                {/* <p style={{textAlign: 'center'}}>電話番号を入力してください</p> */}
                <CustomTextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="phoneNumber"
                  label="電話番号"
                  placeholder="08011112222"
                  name="phoneNumber"
                  color="secondary"
                  autoFocus
                  className={classes.textfield}
                />
                <div className={classes.recaptcha_container} id="recaptcha-container"></div>
                <CustomButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  id="linkPhoneNumberBtn"
                  className={classes.submit}
                >
                  確認コードを送信
                </CustomButton><br></br><br></br>
              </form>
            }
            {verifyState == 2 &&
              <form className={classes.form} onSubmit={sendVerificationCode}>
                {/* <p style={{textAlign: 'center'}}>確認コードを入力してください</p> */}
                <CustomTextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="verifyCode"
                  label="確認コード"
                  placeholder="000000"
                  name="verifyCode"
                  color="secondary"
                  style={{ borderRadius: '0' }}
                  autoFocus
                />
                <CustomButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  className={classes.submit}
                >
                  確認
                </CustomButton>
              </form>
            }
          </div>
        </>
      </Container>
      {/* </GoogleReCaptchaProvider> */}
      <Copyright />
    </React.Fragment>
  );
};

export default withRouter(SmsLogin);

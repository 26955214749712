import React, { useContext, useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { AuthContext } from "../contexts/Auth";
import { CssBaseline, Typography, makeStyles, Container, Select, MenuItem, InputLabel } from "@material-ui/core";
import { db, firebase, CloudFunctions } from "../utils/Firebase";
// import { analytics, db, firebase, CloudFunctions } from "../utils/Firebase";
import { useDispatch } from "react-redux";
import common from "../redux/common/commonSlice";
import { countries } from "../utils/Shipping";
import { parsePhoneNumber } from "libphonenumber-js";
import { PageTitle } from "../components/common/PageTitle";
import { useForm } from "react-hook-form";
import BackdropLoading from "../components/common/BackdropLoading";
import { Button } from "../components/form/Button";
import { TextField } from "../components/form/TextField";
import { validatePhoneNumberVerifyCode } from "../utils/validations/phoneVerifyCode";

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	form: {
		width: '100%',
		marginTop: theme.spacing(1),
	},
	dropdownStyle: {
		height: "250px",
	},
	select: {
		'&:after': { //focus
			borderColor: "#ff0000",
		},
		'&:not(.Mui-disabled):hover::before': { //hover
			borderColor: "#ff0000",
		},
	},
	selectRoot: {
		'&:focus': {
			backgroundColor: "#F9F9F9",
		}
	},
	linkText: {
		textDecoration: 'underline',
		color: '#ff0000',
		letterSpacing: '0.05em',
	}
}));

const SignUpSms = () => {
	const classes = useStyles();
	const history = useHistory()
	const dispatch = useDispatch();
	const { currentUser } = useContext<any>(AuthContext);
	const [verifyState, setVerifyState] = useState(1);
	const [confirmationResult, setConfirmationResult] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const { control, getValues, setValue, handleSubmit } = useForm()

	// reCaptchaを設定
	useEffect(() => {
		const getRecaptchaVerfifier = async () => {
			const recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
				"recaptcha-container",
				{
					size: 'normal',
					callback: () => {
					},
					//reCAPTCHA応答が期限切れになり、ユーザーが再検証する必要があるときに実行される
					"expired-callback": () => {
					},
					// reCAPTCHAでエラー（通常はネットワーク接続）が発生し、接続が復元されるまで続行できない場合に実行される
					"error-callback": () => {
					}
				}
			);
			const recaptchaWidgetId = recaptchaVerifier.render();
			// @ts-expect-error TS(2339): Property 'recaptchaVerifier' does not exist on typ... Remove this comment to see the full error message
			window.recaptchaVerifier = recaptchaVerifier;
			// @ts-expect-error TS(2339): Property 'recaptchaWidgetId' does not exist on typ... Remove this comment to see the full error message
			window.recaptchaWidgetId = recaptchaWidgetId;
		}
		currentUser && getRecaptchaVerfifier();
	}, [currentUser]);

	const sendPhoneNumber = async () => {
		const phoneNumber = getValues('phoneNumber')
		// @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
		const formatedPhoneNumber = parsePhoneNumber(phoneNumber, selectCountryCode)
		if (!formatedPhoneNumber.isValid()) {
			const errorMessage = selectCountryCode === 'JP' ? "入力された電話番号が正しくありません。" : "Invalid Phone Number."
			dispatch(
				common.actions.fetchAlert({
					alerts: [{ message: errorMessage }],
				})
			);
			return;
		}
		setIsLoading(true)

		try {
			// @ts-expect-error TS(2339): Property 'recaptchaVerifier' does not exist on typ... Remove this comment to see the full error message
			const confirmationResult = await firebase.auth().signInWithPhoneNumber(formatedPhoneNumber.number, window.recaptchaVerifier)
			// @ts-expect-error TS(2345): Argument of type 'ConfirmationResult' is not assig... Remove this comment to see the full error message
			setConfirmationResult(confirmationResult)
			setVerifyState(2)
			setIsLoading(false)
			// analytics.logEvent('AUTH_signup_phone_number', { name: "signup_phone_number" })
			dispatch(
				common.actions.fetchAlert({
					alerts: [{ message: "確認コードを送信しました。" }],
				})
			);
		} catch (e) {
			setIsLoading(false)
			// @ts-expect-error TS(2339): Property 'grecaptcha' does not exist on type 'Wind... Remove this comment to see the full error message
			window.grecaptcha.reset(window.recaptchaWidgetId);
			// @ts-expect-error TS(2571): Object is of type 'unknown'.
			switch (e.code) {
				case "auth/too-many-requests":
					dispatch(
						common.actions.fetchAlert({
							alerts: [{ message: "時間をあけて再度送信をお願いいたします。" }],
						})
					);
					break;
				default:
					dispatch(
						common.actions.fetchAlert({
							alerts: [{ message: "電話番号認証に失敗しました。" }],
						})
					);
			}
		}
	};

	const asyncTeaserStatus = async (status: any) => {
		let response: any[] = []
		await db.collection("teaser_emails").where('email', "==", currentUser.email).get()
			.then(snapshot => {
				snapshot.forEach(doc => {
					response.push(doc.id)
				})
			})
			.catch(e => {
				console.log('error ', e)
			})
		await db.collection('teaser_emails').doc(response[0]).update({
			status,
			country_code: selectCountryCode,
		})
	}

	// 認証コードを確認して登録
	const sendVerificationCode = async () => {
		setIsLoading(true)
		const verifyCode = getValues('verifyCode')
		try {
			if (currentUser) {
				// @ts-expect-error TS(2339): Property 'verificationId' does not exist on type '... Remove this comment to see the full error message
				const credential = await firebase.auth.PhoneAuthProvider.credential(confirmationResult.verificationId, verifyCode)
				await currentUser.linkAndRetrieveDataWithCredential(credential)
				await asyncTeaserStatus(2)
				const sendSuccessSignup = CloudFunctions.httpsCallable("sendSuccessSignup");
				await sendSuccessSignup({ email: currentUser.email })
				// analytics.logEvent('AUTH_signup_success', { name: "signup_success" })
				history.push('/signup/thanks');
			} else {
				dispatch(
					common.actions.fetchAlert({
						alerts: [{ message: "アカウント登録メールよりパスワード設定をお願いいたします。" }],
					})
				);
				history.push('/signup/email');
				// const credential = await firebase.auth.PhoneAuthProvider.credential(confirmationResult.verificationId, verifyCode)
				// confirmationResult.confirm(verifyCode).then((result) => {
				//   history.push('/teaser/thanks');
				// })
			}
		} catch (e) {
			setIsLoading(false)
			// @ts-expect-error TS(2571): Object is of type 'unknown'.
			switch (e.code) {
				case "auth/credential-already-in-use":
					dispatch(
						common.actions.fetchAlert({
							alerts: [{ message: "こちらの電話番号はすでに他のアカウントと連携しております。" }],
						})
					);
					break;
				case "auth/provider-already-linked":
					dispatch(
						common.actions.fetchAlert({
							alerts: [{ message: "こちらの電話番号はすでに承認済みです。" }],
						})
					);
					break;
				default:
					dispatch(
						common.actions.fetchAlert({
							alerts: [{ message: "電話番号認証に失敗しました。" }],
						})
					);
					break;
			}
		}
	}

	const [selectCountryCode, setSelectCountryCode] = useState('JP')
	const countrySelectChange = (e: any) => {
		const selectCountryCode = e.target.value
		setSelectCountryCode(selectCountryCode)
	}
	const [isCurrectPhoneNumber, setIsCurrectPhoneNumber] = useState(false)
	const handlePhoneNumberChange = (e: any) => {
		setValue('phoneNumber', e.target.value)
		if (!Number(e.target.value)) return // NaN
		try {
			// @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
			const formatedPhoneNumber = parsePhoneNumber(e.target.value, selectCountryCode)
			setIsCurrectPhoneNumber(formatedPhoneNumber.isValid())
		} catch (e) {
			setIsCurrectPhoneNumber(false)
		}
	}

	return (
		<React.Fragment>
			<CssBaseline />

			<Container component="main" maxWidth="xs" className={classes.root}>
				<PageTitle title='電話番号登録' />

				{currentUser ?
					<>
						{verifyState == 1 &&
							<form className={classes.form} onSubmit={handleSubmit(sendPhoneNumber)}>
								<InputLabel style={{ marginLeft: "10px" }} id="country-code">国（Country）</InputLabel>
								<Select
									labelId="country-code"
									onChange={countrySelectChange}
									value={selectCountryCode}
									className={classes.select}
									classes={{ root: classes.selectRoot }}
									MenuProps={{
										classes: {
											paper: classes.dropdownStyle,
										},
									}}
									style={{ width: "100%", height: "40px", marginBottom: "10px", padding: "10px" }}
								>
									{countries.map(country => (
										<MenuItem key={country.code} value={country.code}>{country.label} {country.flag} +{country.phone}</MenuItem>
									))}
								</Select>

								<TextField
									control={control}
									id='phoneNumber'
									name='phoneNumber'
									label='電話番号 (Phone Number)'
									onChange={handlePhoneNumberChange}
									defaultValue={''}
									valudation={null}
									placeHolder="08012345678"
									helperText="※ハイフンなしで入力してください"
								/>
								<div id="recaptcha-container" />
								<Button
									text='確認コードを送信'
									onClick={handleSubmit}
									disabled={!isCurrectPhoneNumber}
								/>
								{/* {isLoading ?
											<CircularProgress size={24} style={{ color: "white", height: "24px" }} />
											:
											<>確認コードを送信</>
										} */}
								{/* </CustomButton> */}
							</form>
						}

						{verifyState == 2 &&
							<form className={classes.form} onSubmit={handleSubmit(sendVerificationCode)}>
								<p style={{ textAlign: 'center', fontSize: '1rem' }}>確認コードを入力してください</p>
								<TextField
									control={control}
									id='verifyCode'
									name='verifyCode'
									label='確認コード'
									defaultValue={''}
									valudation={validatePhoneNumberVerifyCode}
									placeHolder="確認コード"
									helperText="※６桁の数字を入力してください。"
								/>
								<Button
									text='確認'
									onClick={handleSubmit}
								/>
							</form>
						}
					</>
					:
					<>
						<Typography variant="subtitle2">
							<span className={classes.linkText} onClick={() => history.push('/signin')}>ログイン</span>
							または
							<span className={classes.linkText} onClick={() => history.push('/signup/email')}>アカウント作成</span>
							をお願いいたします。
						</Typography>
					</>
				}

				<BackdropLoading open={isLoading} />
			</Container>
		</React.Fragment >
	);
};

export default withRouter(SignUpSms);

import { List, ListItem, makeStyles, Typography } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
    listRoot: {
        marginTop: theme.spacing(0),
    },
    productInfoList: {
        display: 'flex',
        flexFlow: "column",
        justifyContent: 'center',
        marginTop: theme.spacing(2),
        textAlign: 'center',
    },
    productInfoListTitle: {
        color: "gray",
        width: '90%',
    },
    productInfoListText: {
        width: '90%',
    },
    columnList: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        maxWidth: '650px',
        margin: '0 auto',
    },
}))

const AppraisalProductDetailView = ({
    product
}: any) => {
    const classes = useStyles()

    const GetDetailJa = ({
        item
    }: any) => {
        let titleJa = ''
        let valueJa = ''

        switch (item.title) {
            case "condition":
                titleJa = '状態'
                valueJa = item.value
                break;
            case "stockNumber":
                titleJa = '品番'
                valueJa = item.value
                break;
            case "hasAccessory":
                titleJa = '付属品の有無'
                valueJa = item.value ? 'あり' : 'なし'
                break;
            case "accessoryDetail":
                titleJa = '付属品の説明'
                valueJa = item.value
                break;
            case "productSize":
                titleJa = '大きさ・サイズ'
                valueJa = item.value
                break;
            case 'quantity':
                titleJa = '数量'
                valueJa = item.value
                break;
            case 'hasReciept':
                titleJa = '納品書の有無'
                valueJa = item.value ? 'あり' : 'なし'
                break;
            case 'category':
                titleJa = 'カテゴリー'
                valueJa = item.value
                break;
            case 'modelYear':
                titleJa = '年式'
                valueJa = item.value
                break;
            case 'buyYear':
                titleJa = '購入日'
                valueJa = item.value
                break;
            case 'status':
                titleJa = '登録'
                valueJa = item.value === 'active' ? '出品中' : '下書き'
                break;
            default:
                break;
        }

        // category / exptectedBuyPrice などがある。
        if (titleJa) {
            return (
                <ListItem className={classes.productInfoList}>
                    <Typography variant='subtitle2' className={classes.productInfoListTitle}>{titleJa}</Typography>
                    <Typography variant='subtitle1' className={classes.productInfoListText}>{valueJa}</Typography>
                </ListItem>
            )
        } else {
            return (<></>)
        }
    }

    if (product) {
        return <>
            <List className={classes.listRoot}>
                <ListItem className={classes.productInfoList}>
                    <Typography variant='subtitle2' className={classes.productInfoListTitle}>商品名</Typography>
                    <Typography variant='subtitle1' className={classes.productInfoListText}>{product.title}</Typography>
                </ListItem>
                <div className={classes.columnList}>
                    {product.appraisalDetails.map((_detail: any, index: any) => <GetDetailJa item={_detail} key={index} />)}
                </div>
            </List>
        </>;
    } else {
        return (
            <></>
        )
    }
}

export default AppraisalProductDetailView
import { TypedUseSelectorHook, useSelector as rowUseSelector } from "react-redux";
import { combineReducers } from "@reduxjs/toolkit";
import common from "./common/commonSlice";
import shippingMethodReducer from './shippingMethod/shippingMethodSlice'
import { ReduxState } from "../types/common";

const rootReducer = combineReducers({
  common: common.reducer,
  shippingMethod: shippingMethodReducer
});

export default rootReducer;
export const useSelector: TypedUseSelectorHook<ReduxState> = rowUseSelector;

import { createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { formatedYen } from '../../utils/Common'

const useStyles = makeStyles((theme: Theme) => createStyles({
  grid_container: {
    display: "flex",
  },
  grid_item: {
    padding: "0 12px",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(10),
    display: "flex",
    flexDirection: "column",
    width: "90%",
    [theme.breakpoints.up('sm')]: {
      padding: "0 12px",
      marginBottom: theme.spacing(12),
    },
    "&:hover": {
      cursor: "pointer",
    }
  },
  cardMedia: {
    width: "100%",
  },
  item_info: {
  },
  inventory_text: {
    height: "38px",
    lineHeight: "38px",
    marginBottom: "4px",
    textAlign: "center",
    fontSize: "1.4rem",
    letterSpacing: "2px",
    color: "#ff0000",
    fontFamily: "digital7-mono",
  },
  product_title: {
    textAlign: "left",
    fontWeight: "bold",
    overflow: "hidden",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
  },
  product_price: {
    textAlign: 'left',
    color: "gray",
  },
}))

const ThreeColumnProductsView = (props: any) => {
  const { products } = props
  const history = useHistory()
  const classes = useStyles()
  const calcMinInventory = (_product: any) => {
    let arr = _product.variants.map((variant: any) => variant.inventory_quantity).filter((n: any) => n)
    return Math.min(...arr) === Infinity ? 0 : Math.min(...arr)
  }

  return (
    <Grid container className={classes.grid_container}>
      {products.map((product: any) => <Grid
        item
        className={classes.grid_item}
        key={product.id}
        xs={6}
        sm={6}
        md={6}
        onClick={() => history.push(`/onsale/${product.id}`)}
      >
        <Typography variant="subtitle2" className={classes.inventory_text}> LAST {calcMinInventory(product)}</Typography>
        <div className={classes.item_info}>
          <img src={product.image} className={classes.cardMedia} />
        </div>
        <Typography variant="subtitle2" className={classes.product_title}>{product.title}</Typography>
        <Typography className={classes.product_price}>
          ¥ {formatedYen(product.variants[0].price)}
        </Typography>
      </Grid>)}
    </Grid>
  );
}

export default ThreeColumnProductsView
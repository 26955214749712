import { Typography } from "@material-ui/core"
const AppraisalTermsText = () => {
    return (
        <>
            <Typography variant="h4" component="h1" style={{ fontSize: "1.3rem", lineHeight: "2em", textAlign: "center", color: "grey", width: "90%", margin: "60px auto 40px", }}>買取利用規約</Typography>
            <div style={{ textAlign: 'left' }}>
                株式会社Dropp（以下「当社」といいます。）が提供する買取仲介サービス（以下「本サービス」といいます。）に関する利用規約を、以下のとおり定めます。
                本サービスに基づき商品を当社が紹介するパートナー企業（以下「パートナー」といいます。）にお送りいただきました時点で本規約に同意したものとみなします。
            </div><br></br><br></br>

            <div style={{ color: 'grey' }}>第1条（目的）</div><br />
            <div style={{ textAlign: 'left' }}>
                本規約は、パートナーと商品をお売りいただくユーザー（以下「ユーザー」といいます。）との間でお取引するにあたり、当事者間の権利義務関係を明確にすることを目的とします。
                なお、本規約は予告なしに変更する場合があり、お取引に際しては常に最新の内容が適用されます。
                最新の規約については本サービス内に掲載するものとします。
            </div><br /><br /><br />

            <div style={{ color: 'grey' }}>第2条（本サービスの利用）</div><br />
            <div style={{ textAlign: 'left' }}>
                お客様は本サービスの利用に際し、当社が運営するWEBサービス「Dropp」に登録するものとし、Droppを介して利用することとします。
            </div><br /><br /><br />

            <div style={{ color: 'grey' }}>第3条（買取対象品）</div><br />
            <div style={{ textAlign: 'left' }}>
                本サービスにて買取可能な商品は、スニーカー、宝石、貴金属、時計、ブランドバッグ、衣類、骨董品、美術品とします。
                なお、買取可能な商品であっても、荷箱に入らない場合には取引をお断りする場合があります。
            </div><br /><br /><br />

            <div style={{ color: 'grey' }}>第4条（商品の取扱い）</div><br />
            <div style={{ textAlign: 'left' }}>
                商品が未開封であっても状態等の確認のために開封することがあります。
                その後開封した商品を返却する場合、当社及びパートナーは開封に伴う原状回復は行わないものとします。
                商品を返却する場合、商品の状態等を査定前の状態等に復元することができない場合があります。
            </div><br /><br /><br />

            <div style={{ color: 'grey' }}>第5条（査定）</div><br />
            <div style={{ textAlign: 'left' }}>
                本サービスでは、パートナーが買取りさせていただく商品の現物を実際に触り拝見した上で査定するものとします。
                査定金額とは、その商品の付属品等を全て含む価値とします。
            </div><br /><br /><br />

            <div style={{ color: 'grey' }}>第6条（古物営業法に基づく本人確認）</div><br />
            <div style={{ textAlign: 'left' }}>
                パートナーは、古物営業法の定めによりユーザーのご本人の確認を行う場合があります。
                なお、確認方法はパートナーの指示に従うものとし、パートナーへ必要書類のご提出をお願いする場合がございます。
                なお、必要書類のご提示をいただけない場合、買取をお断りする場合がございます。
            </div><br /><br /><br />

            <div style={{ color: 'grey' }}>第7条（いたずら申込み）</div><br />
            <div style={{ textAlign: 'left' }}>
                商品が入っていない状態又は別商品が入った状態で送られた場合、当社はこれをいたずら申込みと判断し、返送料に加え手数料1,000円（税別）をユーザーに請求します。
                郵送等の際に費用が発生した場合にもその金額を請求します。
                不正確な住所又は架空の住所によるお申込みがあった場合、ユーザーは、当社及びパートナーで発生した費用の一切を負担するものとします。
            </div><br /><br /><br />

            <div style={{ color: 'grey' }}>第8条（入金）</div><br />
            <div style={{ textAlign: 'left' }}>
                査定金額はパートナーからユーザーに提示します。
                ユーザーが当該査定金額及びパートナーとの取引に同意し、当社がこれを確認した日から原則として5営業日以内に、当社仲介手数料を控除した金額を入金するものとします。
                ただし、口座情報に不備があった場合、不正な取引（規約違反、法令違反、その他社会通念に照らして当社が不正と判断した取引）に当たる場合、依頼先金融機関の事情による場合はこの限りではありません。
                前項にかかわらず、マイページにご登録いただいた口座名義がユーザーのお名前と異なる場合、当該口座には入金いたしません。
                前項の支払方法に関し、当社はユーザー情報の不備に基づいて生じた損害等には、一切責任を負わないものとします。
            </div><br /><br /><br />

            <div style={{ color: 'grey' }}>第9条（預かり期間）</div><br />
            <div style={{ textAlign: 'left' }}>
                お預かりした商品の最大保管期間は商品到着から2ヶ月間とします。
                当社は、商品到着から2ヶ月が経過し、かつ、ユーザーの所有の意思が確認できない場合には、ユーザーが当該商品に対する所有権その他一切の権利を放棄し、その処分を当社及びパートナーに委託したものとみなします。
                この場合、当社又はパートナーはユーザーに対し、一切の責任を負わないものとします。
            </div><br /><br /><br />

            <div style={{ color: 'grey' }}>第10条（査定結果連絡）</div><br />
            <div style={{ textAlign: 'left' }}>
                査定結果は、Dropp内にて通知するものとします。
                当社は、ユーザーに対して、ユーザーが指定したメールアドレスに査定結果の通知を発信する場合があり、当該発信をした時点で、当該通知はユーザーに到達したものとみなします。
            </div><br /><br /><br />

            <div style={{ color: 'grey' }}>第11条（売買契約の成立）</div><br />
            <div style={{ textAlign: 'left' }}>
                <p style={{ marginBottom: '8px' }}>
                    パートナーがユーザーに対し査定金額を提示し、ユーザーが当該査定金額及びパートナーとのお取引に同意した時点で、売買契約は成立するものとします。
                    ユーザーは当社及びパートナーと取引をするにあたり、次の各号について保証するものとします。なお、ユーザーが下記のいずれかに該当すると当社が判断した場合、当社はユーザーとの売買契約を解除できるものとします。
                </p>
                <ol style={{ margin: '0', paddingLeft: '1rem' }}>
                    <li>
                        売買契約の目的が不正な目的（不正品の換金、クレジットカードの現金化、マネーロンダリングなど、違法な目的又は社会通念に照らして当社が不正な目的と判断したものをいいます。）に該当しないこと。
                    </li>
                    <li>
                        ユーザーが反社会的勢力（暴力団、暴力団員、暴力団準構成員、暴力団関係企業、総会屋、社会運動標榜ゴロ、政治活動標榜ゴロ、特殊知能暴力集団及びこれらに準じるものをいいます。）に該当しないこと
                    </li>
                    <li>
                        商品が不正流通品、偽物、贋作に該当しないこと
                    </li>
                    <li>
                        Dropp内にユーザーがアップロードした商品画像と、実際に査定した商品の状態に乖離がある場合
                    </li>
                </ol>
            </div><br /><br /><br />

            <div style={{ color: 'grey' }}>第12条（返却）</div><br />
            <div style={{ textAlign: 'left' }}>
                パートナーから査定結果を提示した商品について、ユーザーは当社及びパートナーに対し返却を希望することができます。
                前項に基づいて商品の返却をする場合、返送先はDroppにご登録いただいた住所に限るものとします。
                ユーザーは、査定の結果、金額がつかなかった商品について、当該商品の返却もしくは当社又はパートナーによる処分のいずれかを選択することができます。ただし、当社又はパートナーによる処分については、内容によりお断りさせていただく場合があります。
                売買契約成立後は、返品請求、代金の増額請求等、ユーザーの都合による異議申立て等は一切できません。
                売買契約成立後に当社又はパートナーが不正な商品（コピー商品、類似商品、誤認を招く商品又は真物ではない商品、盗品など）と判断した場合、パートナーは当該売買契約を解除し、支払った代金の全部又は一部の返金を請求できるものとします。その場合、ユーザーは速やかに当該代金を返還しなければなりません。
            </div><br /><br /><br />

            <div style={{ color: 'grey' }}>第13条（責任範囲）</div><br />
            <div style={{ textAlign: 'left' }}>
                当社はユーザーとパートナーの売買契約の内容、商品の発送及び返送、商品の価値に影響しない付随品の毀損・紛失、発送中及び返送中の事故及び商品の査定結果に対し、一切の責任を負いません。
                本サービスの利用におけるユーザーに対する損害賠償額の上限は、損害が生じた商品のパートナー査定金額を上限とします。
                当社は、ハードウェア障害、ソフトウェア障害、インターネット接続障害、第三者の不正なプログラム等により当社のWebシステムに不具合が生じた結果、ユーザーに生じた損害等について一切責任を負わないものとします。
                ユーザーの本サービス利用に関し、当社がユーザーに負う損害賠償責任の範囲は、ユーザーに直接かつ現実に生じた通常損害に限るものとし、予見又はその可能性の有無にかかわらず特別の事情による損害、逸失利益、間接的損害、その他の損害については責任を負わないものとします。
            </div><br /><br /><br />

            <div style={{ color: 'grey' }}>第14条（禁止行為）</div><br />
            <div style={{ textAlign: 'left' }}>
                <p style={{ marginBottom: '8px' }}>ユーザーが次のいずれかに該当する場合、本サービスを利用できません。</p>
                <ol style={{ margin: '0', paddingLeft: '1rem', marginBottom: '8px' }}>
                    <li>ユーザーのご登録情報に虚偽の記載、誤記、記入漏れなどがある場合</li>
                    <li>ユーザーが18歳未満である場合</li>
                    <li>ユーザーが未成年者、成年被後見人、被保佐人及び補助人の同意を要する旨の審判を受けた被補助人のいずれかであり、お申込みの際に法定代理人、後見人、保佐人又は補助人の同意等を得ていなかった場合</li>
                    <li>ユーザーが過去にパートナーから売買契約を解除され、又は本サービスの利用を停止されていた場合</li>
                    <li>ユーザーが当社の競合他社等に該当し、又は当社の事業上の秘密を調査する目的で契約を行うものであると当社が判断した場合</li>
                    <li>故意に複数回に分けて商品を発送する、明らかに査定・買取を目的としていないなど、ユーザーによる本サービスの利用が本サービスの運営に支障をきたすおそれがあると当社が判断する場合</li>
                    <li>ユーザーが本規約上の義務を怠るおそれがあると当社が判断する場合</li>
                    <li>不正又は違法な手段、目的により本サービスを利用するものであると当社が判断する場合</li>
                    <li>ユーザーが反社会的勢力（暴力団、暴力団員、暴力団準構成員、暴力団関係企業、総会屋、社会運動標榜ゴロ、政治活動標榜ゴロ、特殊知能暴力集団及びこれらに準じるものをいいます。）である場合</li>
                    <li>上記各号のほか、ユーザーに本サービスを提供することを当社が不適当と判断する場合</li>
                </ol>
                売買契約成立後、ユーザーが前項各号のいずれかに該当することが判明した場合、当社及びパートナーはユーザーとの売買契約を解除することができるものとします。
            </div><br /><br /><br />

            <div style={{ color: 'grey' }}>第15条（個人情報）</div><br />
            <div style={{ textAlign: 'left' }}>
                当社は、本サービスの提供に関連して知り得たお客様の個人情報について、当社が別に定める「プライバシーポリシー」
                (<a href="https://dropp.jp/privacy" target="_blank">https://dropp.jp/privacy</a>）
                に従って適切に取り扱います。
            </div><br /><br /><br />

            <div style={{ color: 'grey' }}>第16条（裁判管轄）</div><br />
            <div style={{ textAlign: 'left' }}>
                ユーザーと当社又はパートナーの間で訴訟の必要が生じた場合、東京地方裁判所又は東京簡易裁判所をもって第一審の専属的合意管轄裁判所とします。
            </div><br /><br /><br />

            <div style={{ color: 'grey' }}>第17条（準拠法）</div><br />
            <div style={{ textAlign: 'left' }}>
                本規約は日本法を準拠法とし、日本法によって解釈されます。
            </div><br /><br /><br />

            <div style={{ color: 'grey' }}>第18条（分離条項）</div><br />
            <div style={{ textAlign: 'left' }}>
                本規約に定めのない事項及び定められた項目について疑義が生じた場合は、両者誠意を持って協議の上、解決することとします。
                なお、本規約のいずれかの部分が無効である場合でも、本規約全体の有効性には影響がないものとし、かかる無効の部分については、当該部分の趣旨に最も近い有効な定めを無効な部分と置き換えるものとします。
            </div>

            <div style={{ textAlign: 'left' }}>
                以上
            </div><br /><br /><br />

        </>
    )
}
export default AppraisalTermsText;

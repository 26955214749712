
import React, { memo } from "react";
import {
  List,
  ListItem,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { TitleView } from "../TitleView";
import { Product } from "../../types/product";

const useStyles = makeStyles(() => ({
  product_info_wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    marginBottom: "50px",
  },
  image_wrapper: {
    marginBottom: "16px",
    position: "relative",
  },
  image: {
    maxWidth: '100%',
    objectFit: 'contain'
  },
  lists: {
    paddingBottom: "12px",
  },
  list: {
    display: "flex",
    textAlign: "center",
    marginTop: "16px",
    color: "grey"
  },
  total_price_list: {
    borderTop: "2px solid darkgray",
    paddingTop: "20px",
  },
  list_item: {
    width: "50%",
  },
}));

type Props = {
  productInfo: Product,
  shippingFee: number,
  paymentStates: any,
}

const PaymentInfoView = memo((props: Props) => {
  const { productInfo, shippingFee, paymentStates } = props
  const classes = useStyles();

  // オーソリなしで全額決済の場合、オーソリ上限を設定して差額を決済の場合があり
  let _winAmount = productInfo.current_bid_amount
  let _dropp_fee = _winAmount * productInfo.auction_fee / 100
  let _shipping_fee = shippingFee ? shippingFee : 0
  let _insuranceFee = paymentStates.insuranceFee ? paymentStates.insuranceFee : 0
  let _totalPrice = Number(_winAmount) + Number(_shipping_fee) + Number(_dropp_fee) + Number(_insuranceFee) - (productInfo.highest_bid_ticket?.authorization_amount ?? 0)

  const images = productInfo.images ? productInfo.images : [];
  return <>
    <div className={classes.product_info_wrapper}>
      <div className={classes.image_wrapper}>
        <img className={classes.image} src={images[0]} />
      </div>
      <TitleView style={{ letterSpacing: "1px", fontSize: "1.1rem", textAlign: "center", fontWeight: "bold" }} title={productInfo.title} ></TitleView>
      <List className={classes.lists}>
        <ListItem className={classes.list}>
          <Typography variant='subtitle2' className={classes.list_item}>落札価格</Typography>
          <Typography variant='subtitle2' className={classes.list_item}>¥ {String(productInfo.current_bid_amount).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}</Typography><br></br>
        </ListItem>
        <ListItem className={classes.list}>
          <Typography variant='subtitle2' className={classes.list_item}>手数料<br></br>(入札価格の{productInfo.auction_fee}%)</Typography>
          <Typography variant='subtitle2' className={classes.list_item}>¥ {String(productInfo.current_bid_amount * productInfo.auction_fee / 100).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}</Typography>
        </ListItem>
        {paymentStates.insuranceFee > 0 &&
          <ListItem className={classes.list}>
            <Typography variant='subtitle2' className={classes.list_item}>保険料</Typography>
            <Typography variant='subtitle2' className={classes.list_item}>¥ {String(paymentStates.insuranceFee).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}</Typography>
          </ListItem>
        }
        <ListItem className={classes.list}>
          <Typography variant='subtitle2' className={classes.list_item}>配送料</Typography>
          <Typography variant='subtitle2' className={classes.list_item}>
            {shippingFee ?
              <>¥ {String(shippingFee).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}</>
              :
              <>次のステップで計算されます....</>
            }
          </Typography>
        </ListItem>
        <ListItem className={classes.list + " " + classes.total_price_list}>
          <Typography variant='subtitle2' className={classes.list_item}>事前オーソリ金額</Typography>
          <Typography variant='subtitle2' className={classes.list_item}>
            -¥ {productInfo.highest_bid_ticket ? String(productInfo.highest_bid_ticket.authorization_amount).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,') : ''}
          </Typography>
        </ListItem>
        <ListItem className={classes.list + " " + classes.total_price_list}>
          <Typography variant='subtitle2' className={classes.list_item}>支払い金額</Typography>
          <Typography variant='subtitle2' className={classes.list_item}>
            {shippingFee ?
              <>¥ {String(_totalPrice).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}</>
              :
              <>次のステップで計算されます....</>
            }
          </Typography>
        </ListItem>
      </List>
    </div>
  </>;
});
export default PaymentInfoView;

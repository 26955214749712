import React from "react";
import {
  Container,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Timer from "../../components/Timer"
import { useHistory } from 'react-router-dom';
import { TitleView } from "../TitleView";
import { myTicket } from "../../types/ticket";

const useStyles = makeStyles((theme) => ({
  cardGrid: {
    paddingTop: theme.spacing(4),
    paddingRight: 0,
    paddingLeft: 0,
  },
  grid_item: {
    marginBottom: theme.spacing(10),
    '&:hover': {
      backgroundColor: 'white'
    },
    '&:active': {
      backgroundColor: 'white'
    },
    '&:focus': {
      backgroundColor: 'white'
    },
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(14),
    }
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  imageView: {
    position: "relative",
    textAlign: "center",
    width: "80%",
    margin: "0 auto",
    maxWidth: "350px",
  },
  cardMedia: {
    width: "100%",
  },
  statusImg: {
    position: "absolute",
    top: "25px",
    left: "10px",
    width: "40%",
  },
  item_info: {
    display: "flex",
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: "center",
    color: "#ff0000",
  },
  product_price: {
    textAlign: 'center',
    marginTop: '4px',
  },
  no_entry_product: {
    marginTop: theme.spacing(20),
    textAlign: 'center',
  },
}));

type Props = {
  myTickets: myTicket[],
}

const EntryProductsView = (props: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const { myTickets } = props;

  const ImageView = (props: any) => {
    const { product } = props
    return (
      <div className={classes.imageView}>
        <img src={product.image} className={classes.cardMedia} />
      </div>
    )
  }

  if (myTickets.length >= 1) {
    return (
      <Container className={classes.cardGrid} maxWidth="md">
        <Grid container spacing={2}>
          {myTickets.map((_myTicket) => <React.Fragment key={_myTicket.id}>
            <Grid className={classes.grid_item} item key={_myTicket.id} xs={12} sm={12} md={12} onClick={() => {
              history.push(`/detail/${_myTicket.productInfo.id}`)
            }}>
              <div className={classes.card}>
                <div className={classes.item_info}>
                  <Timer
                    variant="subtitle1"
                    endDateTime={new Date().setTime(
                      _myTicket.productInfo.deadline_date.seconds * 1000
                    )}
                  />
                </div>
                <ImageView product={_myTicket.productInfo} />
                <TitleView title={_myTicket.productInfo.title}></TitleView>
                <Typography className={classes.product_price}>{new Date(_myTicket.created_at.seconds * 1000).toLocaleDateString()}</Typography>
                {/* <Typography className={classes.product_price}>¥{String(product.variants[0].price).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}</Typography> */}
              </div>
            </Grid>
          </React.Fragment>)}
        </Grid>
      </Container>
    );
  } else {
    return (
      <>
        <Typography variant="subtitle2" className={classes.no_entry_product}>現在エントリー済みの商品はございません。</Typography>
      </>
    )
  }
};
export default EntryProductsView;

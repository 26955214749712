// incoming Webhookで#dropp_operationに通知
export const notifySlackDroppOperation = async (_text: any) => {
  const payload = {
    text: _text
  }
  const url = process.env.REACT_APP_SLACK_DROPP_INFORMATION_INCOMING_WEB_HOOK_URL
  if (!url) return
  fetch(url, {
    method: "POST",
    body: JSON.stringify(payload)
  })
}

export const notifySlackDroppOrder = async (_text: any) => {
  const payload = {
    text: _text
  }
  const url = process.env.REACT_APP_SLACK_DROPP_ORDER_INCOMING_WEB_HOOK_URL
  if (!url) return
  fetch(url, {
    method: "POST",
    body: JSON.stringify(payload)
  })
}

// const DroppDatabaseId = "effc2137fc1f485da92f703f8a5fd7c5"
// const StylesDatabaseId = "c901043d393749b1a2f9b2b2b0c63fb4"
// const WineLuxuryDatabaseId = "a828a14e0e864d418828eb7e0ceafc79"
// const TheWineConcordeClubDatabaseId = "f36b22a04e974b9db71dd98f1db5a1b8"
// const BlueNoiseDatabaseId = "ac6799ae9c7f40269a6a1dfa64ccd0b4"
// const PalmNutsDatabaseId = "f279b35ecf834c988d49b197c531d8d0"

// スラックチャンネルID
export const DroppOperationChannelId = 'C01UVF1KE5B'
export const DroppNoticeChannelId = 'C04VBF5R9KL'
export const PalmnutsChannelId = 'C03FA7WHK63'
export const sscChannelId = 'C04JPJRG5B7'
export const AAndKChanelId = 'C05Q6NN7WAF'
export const MCMChannelId = 'C0462BUS6P9'
export const TheWineConcordeClubChannelId = 'C048AC7S4HM'
export const WineLuxuryChannelId = 'C049CAV4X32'
export const XLargeChannelId = 'C04293625RD'
export const BairdBeerChannelId = 'C04BHTUR8TU'
export const BenlyChannelId = 'C04BT96JC9H'
export const LizaplanChannelId = 'C04FVCE9JMV'
export const TatenokawaChannelId = 'C049M5R56HE'
export const BluenoiseChannelId = 'C03EX21U6CD'
export const DroppDevelopChannelId = 'C01H0KBCSV9'
export const SscChannelId = 'C04JPJRG5B7'
export const StylesChannelId = 'C03F290ESTE'
export const DesignChannelId = 'C01H6JFFHKN'

// メンバー
export const DesignerId = '<@U01U3JUGW83>'
export const HashimotoId = '<@U02CYQGSYDN>'
export const AmanoId = '<@U01TJ3U9NHM>'

// SlackメンバーID
// Dropp
export const amanoSlackMemberId = '<@U01TJ3U9NHM>'
export const hashimotoSlackMemberId = '<@D02C663NP2R>'
export const pengSlackMemberId = '<@D01TNKAEVUP>'
export const ogawaSlackMemberId = '<@D01TS3Q85SA>'
export const kanazawaSlackMemberId = '<@D058VG0R7M2>'
// パームナッツ
export const yonaSlackMemberId = '<@U03FA7YJ77Z>'
export const inuiSlackMemberId = '<@U03V033BNBW>'
// SSC
export const sekiSlackMemberId = '<@U04KA80NG3T>'
// A&K
export const InuuraMemberId = "<@U05Q8DU91JN>"
import {
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    InputLabel,
    makeStyles,
    MenuItem,
    Select,
    TextField,
    Typography,
    withStyles
} from "@material-ui/core"
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../contexts/Auth";
import { CloudFunctions, db } from "../../utils/Firebase";
import BackdropLoading from "../common/BackdropLoading";
import { Product } from "../../types/product";

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '100px',
    },
    pageTitle: {
        textAlign: 'center',
        fontWeight: 'bold',
        marginBottom: theme.spacing(4),
        marginTop: theme.spacing(10),
    },
    pageText: {
        marginBottom: '80px',
        fontSize: '0.85rem',
    },
    buttonWrapper: {
        marginTop: '30px',
    },
    button: {
        borderRadius: '0',
        backgroundColor: '#ff0000',
        color: 'white',
        width: '100%',
        marginBottom: '100px'
    },
    alertText: {
        margin: '16px 0',
        color: '#ff0000',
    },
    linkText: {
        textDecoration: 'underline'
    },
    selectWrapper: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '40px',
    },
    select: {
        borderRadius: '0',
        "&:active": {
            border: "none",
        },
        "&:after": {
            border: "black",
        },
        "& .MuiSelect-select:focus": {
            backgroundColor: 'white',
        },
    },
    reasonLabel: {
        marginBottom: '16px',
        fontSize: '14px',
    },
    icon: {
        color: "grey"
    },
}))

const CustomTextField = withStyles({
    root: {
        '& label.Mui-focused': {
            color: 'dimgray',
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: '0',
            '&:hover fieldset': {
                borderColor: 'dimgray',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'dimgray',
            }
        },
    }
})(TextField);

const CustomButton = withStyles({
    root: {
        boxShadow: 'none',
        '&:hover': {
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
        },
        '&:focus': {
            boxShadow: 'none',
        }
    },
})(Button);

// 退会理由一覧
const WithdrawalReasons = [
    '欲しいものがない',
    '興味がなくなった',
    '使用頻度が低い',
    '電話番号認証ができない',
    '機種変更に伴い',
    'メールマガジンが多い',
    '料金が高い',
    'サポートに不安がある',
    '当たらない',
    'その他'
]

const Withdrawal = () => {
    const classes = useStyles()
    const history = useHistory()
    const [states, setStates] = useState({ reason: '' })
    const [isLoading, setIsLoading] = useState(false)
    const { currentUser, signout } = useContext<any>(AuthContext)
    const [confirmOpen, setConfirmOpen] = useState(false)
    const [reason, setReason] = useState('')
    const [arrivalProducts, setArrivalProducts] = useState<Product[]>([])
    const handleChange = (e: any) => {
        const { name, value } = e.target
        setStates({ ...states, [name]: value })
    }

    // 生きている抽選がある場合は退会できないようにする
    useEffect(() => {
        const func = async () => {
            if (!currentUser?.email) {
                history.push('/')
            } else {
                let arrivalTickets: any = []
                await db.collection('tickets').where('email', '==', currentUser.email).get().then((snapshot) => {
                    snapshot.forEach((doc) => {
                        if (doc.data().status === 1) {
                            arrivalTickets.push(doc.data())
                        }
                    })

                })
                let _arrivalProducts: any = []
                for (const ticket of arrivalTickets) {
                    await db.collection('products').doc(ticket.product_id.toString()).get().then(doc => {
                        _arrivalProducts.push(doc.data())
                    })
                }
                setArrivalProducts(_arrivalProducts)
            }
        }
        func()

    }, [currentUser])

    const handleWithdrawal = async () => {
        setConfirmOpen(false)
        setIsLoading(true)
        const func = CloudFunctions.httpsCallable('withdrawal')
        const text = `${reason}\n${states.reason}`
        await func({ email: currentUser.email, text })
        await signout()
        setIsLoading(false)
        history.push('/mypage/widhdrawal/success')
        window.location.reload()
    }

    // 退会を確認するダイアログ
    const ConfirmView = () => {
        return (
            <Dialog open={confirmOpen}>
                <DialogContent>
                    退会します。<br />
                    よろしいですか？
                </DialogContent>
                <DialogActions>
                    <Button variant='text' onClick={() => setConfirmOpen(false)}>キャンセル</Button>
                    <Button variant='text' style={{ color: "#ff0000" }} onClick={handleWithdrawal}>退会する</Button>
                </DialogActions>
            </Dialog>
        )
    }

    return (
        <Container className={classes.container} maxWidth='sm'>
            <Typography variant='subtitle1' className={classes.pageTitle}>退会</Typography>
            <Typography variant='subtitle1' className={classes.pageText}>
                退会が完了するとマイページで確認できるすべての会員情報やクレジットカード情報が削除され、
                今後一切WEBページにて履歴が確認できなくなります。<br />
                <br />
                なお、当選・購入・落札いただいた商品に関しましては退会後も発送をさせていただきます。<br />
                <br />
                退会完了後、ご登録のメールアドレスにメールをさせていただきます。<br />
                万が一メールが届かない場合は、退会手続きが完了していない場合がございますので、お手数ではございますが、info@dropp.jpまでご登録のメールアドレスを記載の上ご連絡ください。<br />
                <br />
                また、退会後３営業日はメールマガジンの配信がされる場合がございますこと、ご了承くださいますようお願いいたします。
            </Typography>

            <div className={classes.selectWrapper}>
                <InputLabel className={classes.reasonLabel}>退会理由を選択してください。</InputLabel>
                <Select
                    onChange={(e: any) => setReason(e.target.value)}
                    className={classes.select}
                >
                    {WithdrawalReasons.map((reason, index) => (
                        <MenuItem value={reason} key={index}>{reason}</MenuItem>
                    ))}
                </Select>
            </div>

            <InputLabel className={classes.reasonLabel}>退会理由をご記入ください。</InputLabel>
            <CustomTextField
                value={states.reason}
                variant='outlined'
                label="退会理由（任意）"
                multiline
                rows={3}
                fullWidth
                name="reason"
                onChange={handleChange}
            />
            {arrivalProducts.length > 0 ?
                <>
                    <Typography variant='subtitle2' className={classes.alertText}>
                        エントリー中の商品がある場合は退会することができません。<br />
                        エントリーをキャンセルしてからの退会をお願いいたします。
                    </Typography>
                    {arrivalProducts.map((_product, index) => (
                        <Typography onClick={() => history.push(`/detail/${_product.id}`)} variant='subtitle1' key={_product.id}>
                            エントリ中の商品:<br />
                            <span className={classes.linkText}>{(index + 1) + _product.title}</span>
                        </Typography>
                    ))}
                </>
                :
                <div className={classes.buttonWrapper}>
                    <CustomButton
                        onClick={() => setConfirmOpen(true)} className={classes.button}>退会する
                    </CustomButton>
                </div>
            }
            <BackdropLoading open={isLoading} />
            <ConfirmView />
        </Container>
    )
}

export default Withdrawal;
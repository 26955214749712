import { Button, Container, makeStyles, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import AdminProductInput from '../components/admin/AdminProductInput'
import { db } from '../utils/Firebase'

const useStyles = makeStyles(() => ({
    container: {
        marginTop: "50px",
    },
    product_info: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    product_image: {
        width: "80px",
    },
    button: {
        color: "#ff0000",
        border: "1px solid #ff0000",
        borderRadius: "0",
        margin: "20px 10px",
    },
}))

const AdminProductDetail = () => {
    // @ts-expect-error TS(2339): Property 'product_id' does not exist on type '{}'.
    const { product_id } = useParams()
    const [product, setProduct] = useState(null)
    const classes = useStyles()

    useEffect(() => {
        db.collection('products').doc(product_id.toString()).get().then(doc => {
            let _product = doc.data()
            // @ts-expect-error TS(2532): Object is possibly 'undefined'.
            _product['id'] = doc.id
            // @ts-expect-error TS(2345): Argument of type 'DocumentData | undefined' is not... Remove this comment to see the full error message
            setProduct(_product)
        })
    }, [product_id])

    return (
        <Container maxWidth="md" className={classes.container}>
            {product &&
                <div className={classes.product_info}>
                    {/* @ts-expect-error */}
                    <div className={classes.image}>
                        {/* @ts-expect-error */}
                        <img className={classes.product_image} src={product.images[0].src} />
                    </div>
                    {/* @ts-expect-error */}
                    <Typography variant="subtitle2">ID: {product.id}</Typography>
                    {/* @ts-expect-error */}
                    <Typography variant="subtitle2">商品名: {product.title}</Typography>
                </div>
            }
            <AdminProductInput product={product} setProduct={setProduct} />
            <Button className={classes.button} onClick={() => (true)}>エントリー数を表示する</Button>
        </Container>
    )
}

export default AdminProductDetail
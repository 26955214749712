import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";

const useStyles = makeStyles((theme) => ({
  timer: {
    minHeight: "40px",
    fontSize: "1rem",
  },
  table: {
    marginBottom: "4px",
    width: "90%",
    maxWidth: "160px",
    margin: "0 auto 4px",
  },
  limit_time: {
    textAlign: "center",
    lineHeight: "1.38",
    letterSpacing: "3px",
    fontFamily: "digital7-mono",
  },
  limit_time_item: {
    position: "relative",
    left: "4px",
  },
  time: {
    letterSpacing: "2px",
    fontSize: "1.5rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.5rem",
    }
  },
  limit_dot: {
    textAlign: "center",
    maxWidth: "2px",
    fontSize: "1.4rem",
    fontWeight: "bold",
  },
}));

const HoursTimer = ({
  endDateTime,
}: any) => {
  const classes = useStyles();
  const limit = new Date(endDateTime);
  const now = new Date();
  const diff = limit.getTime() - now.getTime();
  const [msLeft, setMsLeft] = useState(diff);
  const [dispTimeLeftHours, setDispTimeLeftHours] = useState('');
  const [dispTimeLeftMinutes, setDispTimeLeftMinutes] = useState('');
  const [dispTimeLeftSeconds, setDispTimeLeftSeconds] = useState('');
  const [isFinished, setIsFinished] = useState(false);

  useEffect(() => {
    if (!msLeft) return;

    const interval = setInterval(() => {
      setMsLeft(msLeft - 1000);

      if (msLeft > 0) {
        setIsFinished(false);
        const secondLeft = Math.round(msLeft / 1000);

        // const d = Math.floor(secondLeft / 60 / 60 / 24);
        const h = Math.floor(secondLeft / 60 / 60);
        const m = Math.floor(
          (secondLeft - h * 60 * 60) / 60
        );
        const s = Math.floor(
          secondLeft - h * 60 * 60 - m * 60
        );

        const hh = h.toString().length === 1 ? "0" + h : String(h);
        const mm = m.toString().length === 1 ? "0" + m : String(m);
        const ss = s.toString().length === 1 ? "0" + s : String(s);
        // setDispTimeLeftDays(dd);
        setDispTimeLeftHours(hh);
        setDispTimeLeftMinutes(mm);
        setDispTimeLeftSeconds(ss);
      } else {
        setIsFinished(true);
        setDispTimeLeftHours("00");
        setDispTimeLeftMinutes("00");
        setDispTimeLeftSeconds("00");
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [msLeft]);

  return (
    <table
      style={isFinished ? { opacity: "0.7" } : {}}
      className={classes.table}
    >
      <thead>
        <tr className={classes.limit_time}>
          {/* <td className={classes.limit_time_item_first + ' ' + classes.time}>{dispTimeLeftDays}</td>
          <td className={classes.limit_dot}>:</td> */}
          <td className={classes.limit_time_item + ' ' + classes.time}>{dispTimeLeftHours}</td>
          <td className={classes.limit_dot}>:</td>
          <td className={classes.limit_time_item + ' ' + classes.time}>{dispTimeLeftMinutes}</td>
          <td className={classes.limit_dot}>:</td>
          <td className={classes.limit_time_item + ' ' + classes.time}>{dispTimeLeftSeconds}</td>
        </tr>
      </thead>
    </table>
  );

};

export default HoursTimer;

import React, { memo, useState, useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
  Typography,
  withStyles,
  MuiThemeProvider,
} from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core";
import { getCountryName } from "../../utils/Shipping";
import { AuthContext } from "../../contexts/Auth";
import { useHistory } from "react-router-dom";
import ShippingMethod from "../../redux/shippingMethod/shippingMethod";
import { inputCheck, selectShippingMethod } from "../../redux/shippingMethod/shippingMethodSlice";

type CheckboxType = {
  name?: string,
  checked: boolean,
  onChange: any
}

const CustomCheckBox = withStyles({
  root: {
    color: "#ff0000",
    '&$checked': {
      color: "#ff0000",
    },
  },
  checked: {},
})((props: CheckboxType) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles(() => ({
  main_container: {
    width: "100%",
    padding: "0",
  },
  input_area: {
    paddingTop: "50px",
  },
  input_title: {
    color: "grey",
    fontSize: "1rem",
  },
  error_message: {
    color: "#ff0000",
    fontSize: "0.9rem",
    fontWeight: "bold",
  },
  shipping_method_card: {
    border: "1px solid black",
    width: "100%",
    position: "relative",
    left: "10px",
    marginTop: "12px",
    padding: "12px",
    boxSizing: "border-box",
  },
  label: {
    width: "100%",
    display: "flex",
    alignItems: "justify-content",
  },
  shipping_method_card_name: {
    width: "130px",
    display: "flex",
    alignItems: "center",
  },
  shipping_method_card_address: {
    width: "100%",
  },
  shipping_method_card_text: {
    fontSize: "0.8rem",
  },
  shipping_method_card_phone: {
    fontSize: "0.8rem",
    marginTop: "8px",
  },
  remove_button: {
    color: "#ff0000",
    width: "60px",
    display: "flex",
    alignItems: "center",
    padding: "15px",
  },
  zip_input: {
    minWidth: "60%",
    borderRadius: "0",
    marginBottom: "2rem",
    "& .MuiOutlinedInput-root": {
      borderRadius: "0",
    },
  },
  address_input: {
    borderRadius: "0",
    marginBottom: "2rem",
    width: "100%",
    "& .MuiOutlinedInput-root": {
      borderRadius: "0",
    },
  },
  name_card: {
    marginTop: "18px",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  name_input: {
    marginBottom: "2rem",
    // minWidth: "47%",
    // maxWidth: "47%",
    width: "47%",
    "& .MuiOutlinedInput-root": {
      borderRadius: "0",
    },
  },
  error_label: {
    color: "#ff0000",
  },
  zip_card: {
    maxWidth: "100%",
    position: "relative",
  },
  zip_search_wrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  add_search_botton: {
    lineHeight: "24px",
    marginLeft: "30px",
    borderRadius: "0",
    height: "56px",
    border: ".5px solid black",
    fontWeight: "bold",
    letterSpacing: "1px",
    minWidth: "92px",
  },
  dropdownStyle: {
    height: "250px",
  },
  button_wrapper: {
    marginTop: "30px",
    display: "flex",
    justifyContent: "center",
    margin: "50px 0",
    border: "0",
  },
  active_button: {
    color: "#ff0000",
  },
}));

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#696969',
      main: '#696969',
      dark: '#696969',
      contrastText: '#fff',
    },
  },
  shadows: ["none", '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
  overrides: {
    MuiSelect: {
      select: {
        "&:focus": {
          backgroundColor: "#fff",
        },
      }
    },
  }
});

type Props = {
  handleToPayment: any
}

const ShippingMethodInput = memo((props: Props) => {
  const classes = useStyles();
  const history = useHistory()
  const { handleToPayment } = props
  const dispatch = useDispatch();
  const { teaserEmail } = useContext<any>(AuthContext)
  const [states, setStates] = useState({
    selectedShippingMethodStr: "",
    isShippingMethodSave: false,
    shippingErrorMessage: "",
    isPaymentMethodSave: false,
    isPaymentMethodSelectCompoeted: false,
  })

  useEffect(() => {
    if (teaserEmail?.shipping_methods?.length > 0) {
      setStates({
        ...states,
        selectedShippingMethodStr: "0",
        isShippingMethodSave: false,
        shippingErrorMessage: "",
      })
    }
  }, [teaserEmail])

  const handleSelectShippingMethod = (e: any) => {
    setStates({
      ...states,
      selectedShippingMethodStr: e.target.value,
      // 新しい住所が選択されたらデフォルトで保存にチェック
      isShippingMethodSave: e.target.value === '-1',
      shippingErrorMessage: "",
    })
  }

  const handleCheckAddressSave = (e: any) => {
    setStates({ ...states, [e.target.name]: e.target.checked })
  }

  const handleNext = () => {
    if (states.selectedShippingMethodStr === "-1") { // 新規の配送先住所
      const checkRes = dispatch(inputCheck({}))
      if (!checkRes.payload.isChecked) {
        scrollTo({ top: 800, behavior: "smooth" })
        return
      }
    } else { // 既存の配送先住所
      dispatch(selectShippingMethod({ shippingMethod: teaserEmail.shipping_methods[Number(states.selectedShippingMethodStr)], index: Number(states.selectedShippingMethodStr) }))
    }
    handleToPayment()
  }

  const handleCancel = () => {
    history.push('/')
  }

  return (
    <Container className={classes.main_container} id="anchor" maxWidth="sm">
      <MuiThemeProvider theme={theme}>
        <Typography variant="subtitle1" className={classes.error_message}>{states.shippingErrorMessage}</Typography>

        <FormControl fullWidth>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            onChange={handleSelectShippingMethod}
            value={states.selectedShippingMethodStr}
            name="address-input-group"
          >
            {teaserEmail?.shipping_methods?.map((_shipping_method: any, index: any) => (
              <FormControlLabel
                key={index}
                className={classes.shipping_method_card}
                value={index.toString()}
                control={<Radio color="default" />}
                label={
                  <div className={classes.label}>
                    <div className={classes.shipping_method_card_name}>
                      <Typography className={classes.shipping_method_card_text}>{_shipping_method.second_name} {_shipping_method.first_name}</Typography>
                    </div>
                    <div className={classes.shipping_method_card_address}>
                      <Typography className={classes.shipping_method_card_text}>{_shipping_method.zipcode}</Typography>
                      <Typography className={classes.shipping_method_card_text}>{_shipping_method.province}{_shipping_method.address1}</Typography>
                      <Typography className={classes.shipping_method_card_text}>{_shipping_method.address2}</Typography>
                      {_shipping_method.country_code !== 'JP' && _shipping_method.country_code !== undefined && <Typography className={classes.shipping_method_card_text}>{_shipping_method.province} {getCountryName(_shipping_method.country_code)}</Typography>}
                      <Typography className={classes.shipping_method_card_phone}>{_shipping_method.phone}</Typography>
                    </div>
                  </div>
                }
              />
            ))}
            <FormControlLabel
              key="-1"
              className={classes.shipping_method_card}
              value="-1"
              control={<Radio color="default" />}
              label={
                <div className={classes.label}>
                  <Typography className={classes.shipping_method_card_text}>新しい配送先住所を使用する</Typography>
                </div>
              }
            />
          </RadioGroup>
        </FormControl>
        {states.selectedShippingMethodStr === '-1' &&
          <div className={classes.input_area}>
            <ShippingMethod />
            <FormControlLabel
              disabled={states.selectedShippingMethodStr !== "-1"}
              name="isShippingMethodSave"
              control={<CustomCheckBox checked={states.selectedShippingMethodStr === '-1' && states.isShippingMethodSave} onChange={handleCheckAddressSave} />}
              label="この住所を次回以降も使用する"
            />
          </div>
        }
        <div className={classes.button_wrapper}>
          <Button onClick={handleCancel} variant="text">キャンセル</Button>
          <Button onClick={handleNext} variant="text" className={classes.active_button}>次へ</Button>
        </div>
      </MuiThemeProvider>
    </Container>
  );

});
export default ShippingMethodInput;

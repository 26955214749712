import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AuthContext } from "../../contexts/Auth";
import Common from "../../redux/common/commonSlice";
import { db, CloudFunctions } from "../../utils/Firebase";

import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import PaymentMethodInputSlide from "./PaymentMethodInputSlide";
import BackdropLoading from "../common/BackdropLoading";

const useStyles = makeStyles((theme) => ({
  main: {
    padding: "0",
    marginTop: "30px",
    width: "100%",
  },
  loading_wrapper: {
    marginTop: "80px",
    width: "100%",
    textAlign: "center",
  },
  add_card_wrapper: {
    color: "grey",
    border: "1px solid grey",
    textAlign: "center",
    height: "130px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "80px",
  },
  add_icon: {
    fontSize: "2rem",
  },
  card_wrap: {
    boxSizing: "border-box",
    marginTop: theme.spacing(4),
    border: "1px solid grey",
    padding: "12px 6px 12px 24px",
    color: "grey",
  },
  button_wrap: {
    textAlign: "right",
  },
  delete_button: {
    color: "black",
  },
}));

const ChangePaymentMethods = () => {
  const { teaserEmail } = useContext<any>(AuthContext);
  const classes = useStyles();
  const dispatch = useDispatch()
  const elements = useElements();
  const stripe = useStripe();
  const [onPaymentMethodsLoading, setOnPaymentMethodsLoading] = useState(true);
  const [paymentRemoveDialogOpen, setPyamentRemoveDialogOpen] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [isInputSlideOpen, setIsInputSlideOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")


  const getPaymentMethods = async () => {
    if (teaserEmail?.stripe_customer_id) {
      // if (teaserEmail?.test_stripe_customer_id) {
      try {
        let stripeGetPaymentMethods = CloudFunctions.httpsCallable("stripeGetPaymentMethods");
        // let stripeGetPaymentMethods = CloudFunctions.httpsCallable("testStripeGetPaymentMethods");
        let res = await stripeGetPaymentMethods({ customer_id: teaserEmail.stripe_customer_id });
        // let res = await stripeGetPaymentMethods({ customer_id: teaserEmail.test_stripe_customer_id });
        res.data.data.length > 0 ? setPaymentMethods(res.data.data) : setPaymentMethods([])
      } catch (e) {
        setPaymentMethods([])
      }
    } else {
      setPaymentMethods([])
    }
  }

  // 支払いカード一覧取得
  useEffect(() => {
    const _getPaymentMethods = async () => {
      setOnPaymentMethodsLoading(true);
      if (teaserEmail) await getPaymentMethods()
      setOnPaymentMethodsLoading(false);
    }
    _getPaymentMethods()
  }, [teaserEmail]);


  // 支払い用カードの追加
  const handleAttachCard = async () => {
    if (!stripe || !elements) { return }
    setOnPaymentMethodsLoading(true)

    // 1.customer_idを取得
    let customer_id = ""
    if (teaserEmail?.stripe_customer_id) {
      // if (teaserEmail?.test_stripe_customer_id) {
      customer_id = teaserEmail.stripe_customer_id
      // customer_id = teaserEmail.test_stripe_customer_id
    } else {
      const createCustomer = CloudFunctions.httpsCallable('stripeCreateCustomer')
      // const createCustomer = CloudFunctions.httpsCallable('testStripeCreateCustomer')
      let customer = await createCustomer({ email: teaserEmail.email })
      customer_id = customer.data.id
      await db.collection('teaser_emails').doc(teaserEmail.id).update({
        stripe_customer_id: customer_id,
        // test_stripe_customer_id: customer_id,
      })
    }

    // payment_methodの作成 -> 顧客に紐付け
    let resultMessage = ""
    {/* @ts-ignore */ }
    await stripe.createPaymentMethod({ type: 'card', card: elements.getElement(CardElement) })
      .then(async (result) => {
        if (result.paymentMethod) {
          const stripeAttachPaymentMethod = CloudFunctions.httpsCallable('stripeAttachPaymentMethod')
          // const stripeAttachPaymentMethod = CloudFunctions.httpsCallable('testStripeAttachPaymentMethod')
          let res = await stripeAttachPaymentMethod({ customer_id, payment_method: result.paymentMethod })
          if (res.data.status === 'success') {
            // setPaymentMethods([...paymentMethods, result.paymentMethod])
            await getPaymentMethods()
            resultMessage = "カードの登録に成功しました。"
          } else if (res.data.status === 'fail') {
            resultMessage = "カードの登録に失敗しました。申し訳ありませんが時間を空けてお試しください。"
          }
        }
      })
      .catch(() => {
        resultMessage = "カードの登録に失敗しました。申し訳ありませんが時間を空けてお試しください。"
      })
    dispatch(Common.actions.fetchAlert({ alerts: [{ message: resultMessage }] }))
    setOnPaymentMethodsLoading(false)
    setIsInputSlideOpen(false)
  }

  // カード登録削除
  const [removeCardIndex, setRemoveCardIndex] = useState("")
  const handleRemoveDialogOpen = (card_index: any) => {
    setRemoveCardIndex(card_index)
    setPyamentRemoveDialogOpen(true)
  }
  const handleRemoveCard = async () => {
    setOnPaymentMethodsLoading(true)
    setPyamentRemoveDialogOpen(false)
    let resultMessage = ""
    // @ts-expect-error TS(7015): Element implicitly has an 'any' type because index... Remove this comment to see the full error message
    let payment_method_id = paymentMethods[removeCardIndex].id

    const stripeDetachPaymentMethod = CloudFunctions.httpsCallable("stripeDetachPaymentMethod");
    // const stripeDetachPaymentMethod = CloudFunctions.httpsCallable("testStripeDetachPaymentMethod");
    let res = await stripeDetachPaymentMethod({ payment_method_id })

    if (res.data.status === 'success') {
      // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
      let payment_methods = paymentMethods.filter(payment_method => payment_method.id !== payment_method_id)
      setPaymentMethods(payment_methods)
      resultMessage = "カード情報の削除に成功しました。"
    } else if (res.data.status === 'fail') {
      resultMessage = "カードの削除に失敗しました。申し訳ありませんが、時間を空けて再度お願いいたします。"
    }
    dispatch(Common.actions.fetchAlert({ alerts: [{ message: resultMessage }] }))
    setOnPaymentMethodsLoading(false)
  }

  const PaymentRemoveDialogView = () => {
    // @ts-expect-error TS(7015): Element implicitly has an 'any' type because index... Remove this comment to see the full error message
    let removeCard = paymentMethods[removeCardIndex]
    return (
      <Dialog
        open={paymentRemoveDialogOpen}
      >
        <DialogContent>
          <DialogContentText style={{ color: 'black', marginBottom: '0' }}>
            <table style={{ marginBottom: "20px" }}>
              <tr>
                <td style={{ width: "100px" }}>カード番号</td>
                <td>****-****-****-{removeCard.card.last4}</td>
              </tr>
              <tr>
                <td style={{ width: "100px" }}>有効期限</td>
                <td>{removeCard.card.exp_month} / {removeCard.card.exp_year}</td>
              </tr>
            </table>
            こちらのカード登録を削除します。<br></br>
            よろしいでしょうか？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPyamentRemoveDialogOpen(false)}>いいえ</Button>
          <Button onClick={handleRemoveCard} style={{ color: '#ff0000' }}>はい</Button>
        </DialogActions>
      </Dialog>
    )
  }

  return (
    <Container className={classes.main}>
      <div className={classes.add_card_wrapper} onClick={() => setIsInputSlideOpen(true)}>
        <Typography>
          <span className={classes.add_icon}>+</span><br></br>支払い方法を追加する
        </Typography>
      </div>
      {paymentMethods.length > 0 &&
        <div style={{ marginBottom: "50px" }}>
          {paymentMethods.map((_paymentMethod, index) => (
            <div className={classes.card_wrap} key={index}>
              {/* @ts-expect-error */}
              <Typography>{_paymentMethod.card.brand}</Typography><br></br>
              <Typography>
                {/* @ts-expect-error */}
                <span style={{ marginRight: "1rem" }}>****-****-****-{_paymentMethod.card.last4}</span>
                {/* @ts-expect-error */}
                {_paymentMethod.card.exp_month} / {_paymentMethod.card.exp_year}
              </Typography>
              <div className={classes.button_wrap}>
                <Button onClick={() => handleRemoveDialogOpen(index)} variant="text" className={classes.delete_button}>
                  削除
                </Button>
              </div>
            </div>
          ))}
        </div>
      }
      {paymentRemoveDialogOpen && <PaymentRemoveDialogView />}
      <PaymentMethodInputSlide
        isOpen={isInputSlideOpen}
        setIsOpen={setIsInputSlideOpen}
        handleAttachCard={handleAttachCard}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      />
      <BackdropLoading open={onPaymentMethodsLoading} />
    </Container>
  );
};
export default ChangePaymentMethods;

import { CloudFunctions } from "../utils/Firebase"
import {
    BairdBeerChannelId,
    BluenoiseChannelId,
    DroppOperationChannelId,
    MCMChannelId,
    PalmnutsChannelId,
    TatenokawaChannelId,
    TheWineConcordeClubChannelId,
    WineLuxuryChannelId,
    XLargeChannelId,
    sscChannelId
} from "../utils/Notify"

// 通知に関するカスタムフック
export const useNotify = () => {

    // メールを送信する
    const sendEmail = async ({
        mailTo,
        subject,
        content
    }: any) => {
        const func = CloudFunctions.httpsCallable("sendEmail")
        try {
            const { data } = await func({
                mailTo,
                subject,
                content
            })
            if (data.status === 'success') {
                return { status: 'success', error: null }
            } else {
                return { status: 'error', error: data.error }
            }
        } catch (e) {
            console.error('error', e)
            return { status: 'error', error: e }
        }
    }

    // Slackに通知を送信する
    const sendSlack = async ({
        text,
        conversationId
    }: any) => {
        const postSlackMessage = CloudFunctions.httpsCallable("postSlackMessage")
        try {
            if (conversationId) await postSlackMessage({ text, conversationId: conversationId })
            return { status: 'success', error: null }
        } catch (e) {
            console.error(e)
            return { status: 'error', error: e }
        }
    }

    // SlackのチャンネルIDを業者名から取得する
    const getSlackChannelIdByBuyerName = (_buyerName: any) => {
        let _channelID = DroppOperationChannelId
        switch (_buyerName) {
            case "Dropp":
            case "株式会社inspiration":
            case "Droppパートナー":
                _channelID = DroppOperationChannelId
                break;
            case "PALM NUTS CO.":
                _channelID = PalmnutsChannelId
                break;
            case "Mid - Century MODERN":
                _channelID = MCMChannelId
                break;
            case "THE CONCORDE WINE CLUB":
                _channelID = TheWineConcordeClubChannelId
                break;
            case "Wine Luxury":
                _channelID = WineLuxuryChannelId
                break;
            case "XLARGE":
                _channelID = XLargeChannelId
                break;
            case "合資会社べアードブルーイング":
                _channelID = BairdBeerChannelId
                break;
            case "楯の川酒造株式会社":
                _channelID = TatenokawaChannelId
                break;
            case "BLUE NOISE LTD.":
                _channelID = BluenoiseChannelId
                break;
            case "株式会社SSC":
                _channelID = sscChannelId
                break;
            default:
                break;
        }
        return _channelID
    }

    return { sendEmail, sendSlack, getSlackChannelIdByBuyerName }
}
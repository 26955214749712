export const pageEnterVariants = {
    visible: { opacity: 1, scale: 1, transition: { duration: 0.4 } },
    hidden: { opacity: 0, scale: 1, transition: { duration: 0.7 } }
}

export const topLogoVariants = {
    scaleIn: { opacity: [1, 1, 1, 1, 1, 1], scaleY: [1, 6, 1, 1, 1, 1], scaleX: [1, 1, 1, 6, 6, 6], transition: { duration: 2, ease: "easeInOut", times: [0, 0.2, 0.5, 0.8, 1] } },
    fadeOut: { opacity: 0, transition: { duration: 0.7 } }
}

export const fadeVariants = {
    fadeIn: { opacity: 1, transition: { duration: 1 } },
    fadeOut: { opacity: 0, transition: { duration: 1 } }
}

export const isProductVariants = {
    product: { opacity: 1, scale: 1, transition: { duration: 0.7 } },
    noProductTextEnter: { opacity: 1, scale: 1, transition: { duration: 0 } }
}
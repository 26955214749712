
import React, { memo } from "react";
import { makeStyles, Modal, Slide } from "@material-ui/core";
import AuthorizationText from "../common/AuthorizationText";
import TermOfUseText from "../common/TermOfUseText";
import AuctionTermsText from "../common/AuctionTermText";
import CloseIcon from '@material-ui/icons/Close';
import AppraisalTermsText from "./AppraisalTermsText";

const useStyles = makeStyles(() => ({
  terms_wrapper: {
    backgroundColor: "white",
    height: "100vh",
    padding: "30px 30px",
    width: "100%",
    margin: "30px auto",
    maxHeight: "100%",
    boxSizing: "border-box",
    overflow: "scroll",
  },
  terms_wrapper_inner: {
    maxWidth: "800px",
    margin: "0 auto",
  },
}));

type Props = {
  termOfUseOpen: boolean,
  handleTermsViewClose: () => void,
  onShowTermsType: string,
}
const TermOfUseSlideView = memo((props: Props) => {
  const { termOfUseOpen, handleTermsViewClose, onShowTermsType } = props
  const classes = useStyles();

  return (
    <Modal
      open={termOfUseOpen}
      onClose={handleTermsViewClose}
    >
      <Slide direction="up" in={termOfUseOpen} mountOnEnter unmountOnExit timeout={550}>
        <div className={classes.terms_wrapper}>
          <div style={{ position: "fixed", top: "60px", left: "30px" }} onClick={handleTermsViewClose}><CloseIcon /></div>
          <div className={classes.terms_wrapper_inner}>
            <div style={{ textAlign: "center", margin: "50px 0px 100px" }}>
              {onShowTermsType === "オーソリ規約" && <AuthorizationText />}
              {onShowTermsType === "オークション規約" && <AuctionTermsText />}
              {onShowTermsType === "買取規約" && <AppraisalTermsText />}
              {onShowTermsType === "先着販売規約" && <TermOfUseText />}
              {onShowTermsType === "利用規約" && <TermOfUseText />}
            </div>
          </div>
        </div>
      </Slide>
    </Modal>
  )
});
export default TermOfUseSlideView;

import React, { useEffect, useContext, useState } from "react";
import { Route } from "react-router-dom";
import { AuthContext } from "../Auth";
import { withRouter } from 'react-router-dom';
import { AccessDeniedView } from "../../components/common/AccessDeniedView";

// @ts-expect-error TS(7031): Binding element 'RouteComponent' implicitly has an... Remove this comment to see the full error message
export const VendorRoute = ({ component: RouteComponent, ...options }) => {
    const { currentUser, teaserEmail } = useContext<any>(AuthContext);
    const Component = teaserEmail?.vendor ? RouteComponent : AccessDeniedView;
    const [shopLoading, setShopLoading] = useState(true);

    useEffect(() => {
        if (currentUser) {
            setShopLoading(false);
            return;
        }
        // 初期表示はcurrentUserの取得が鈍い
        let timerId = setTimeout(() => {
            setShopLoading(false);
            clearTimeout(timerId);
        }, 2000);
    }, [currentUser]);

    return <Route {...options} component={shopLoading ? withRouter(() => { return <></> }) : Component} />;
};


import React, { memo, useEffect } from "react";
import {
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
  Typography,
  withStyles,
  MuiThemeProvider,
} from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core";
import { getCountryName } from "../../utils/Shipping";
import ShippingMethod from "../../redux/shippingMethod/shippingMethod";

type CheckboxType = {
  checked: any,
  onChange: any
}

const CustomCheckBox = withStyles({
  root: {
    color: "#ff0000",
    '&$checked': {
      color: "#ff0000",
    },
  },
  checked: {},
})((props: CheckboxType) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
  main_container: {
    marginTop: theme.spacing(2),
    marginBottom: "18px",
    padding: "0",
    maxWidth: "100%",
  },
  input_area: {
    paddingTop: "30px",
  },
  input_title: {
    color: "grey",
    fontSize: "1rem",
  },
  error_message: {
    color: "#ff0000",
    fontSize: "0.9rem",
    fontWeight: "bold",
  },
  shipping_method_card: {
    border: "1px solid black",
    width: "100%",
    position: "relative",
    left: "10px",
    marginTop: "12px",
  },
  input_card: {
    marginTop: "30px",
  },
  label: {
    width: "100%",
    display: "flex",
    alignItems: "justify-content",
  },
  shipping_method_card_name: {
    width: "130px",
    display: "flex",
    alignItems: "center",
  },
  shipping_method_card_address: {
    width: "100%",
  },
  shipping_method_card_text: {
    fontSize: "0.8rem",
  },
  remove_button: {
    color: "#ff0000",
    width: "60px",
    display: "flex",
    alignItems: "center",
    padding: "15px",
  },
  zip_input: {
    minWidth: "60%",
    borderRadius: "0",
    marginBottom: "2rem",
    "& .MuiOutlinedInput-root": {
      borderRadius: "0",
    },
  },
  address_input: {
    borderRadius: "0",
    marginBottom: "2rem",
    width: "100%",
    "& .MuiOutlinedInput-root": {
      borderRadius: "0",
    },
  },
  name_card: {
    marginTop: "18px",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  name_input: {
    marginBottom: "2rem",
    width: "47%",
    "& .MuiOutlinedInput-root": {
      borderRadius: "0",
    },
  },
  error_label: {
    color: "#ff0000",
  },
  zip_card: {
    maxWidth: "100%",
    position: "relative",
  },
  zip_search_wrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  add_search_botton: {
    lineHeight: "24px",
    marginLeft: "30px",
    borderRadius: "0",
    height: "56px",
    border: ".5px solid black",
    fontWeight: "bold",
    letterSpacing: "1px",
    minWidth: "92px",
  },
  dropdownStyle: {
    height: "250px",
  },
  select: {
    '&:after': { //focus
      borderColor: "#000",
    },
    '&:not(.Mui-disabled):hover::before': { //hover
      borderColor: "#000",
    },
  },
  selectRoot: {
    '&:focus': {
      backgroundColor: "white",
    }
  }
}));

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#696969',
      main: '#696969',
      dark: '#696969',
      contrastText: '#fff',
    },
  },
  shadows: ["none", '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
  overrides: {
    MuiSelect: {
      select: {
        "&:focus": {
          backgroundColor: "#fff",
        },
      }
    },
  }
});

type Props = {
  states: any,
  setStates: any,
  currentTeaserEmail: any
}

const AddressSelectView = memo((props: Props) => {
  const { states, setStates, currentTeaserEmail } = props
  const classes = useStyles();

  useEffect(() => {
    if (currentTeaserEmail?.shipping_methods?.length > 0) {
      setStates({
        ...states,
        selectedShippingMethodStr: "0",
        isShippingMethodSave: false,
        shippingErrorMessage: "",
      })
    }
  }, [])

  const handleSelectShippingMethod = (e: any) => {
    setStates({
      ...states,
      selectedShippingMethodStr: e.target.value,
      // 新しい住所が選択されたらデフォルトで保存にチェック
      isShippingMethodSave: e.target.value === '-1',
      shippingErrorMessage: "",
    })
  }

  const handleCheckAddressSave = (e: any) => {
    setStates({ ...states, [e.target.name]: e.target.checked })
  }

  return (
    <Container className={classes.main_container} id="anchor">
      <MuiThemeProvider theme={theme}>
        <Typography variant="subtitle1" className={classes.input_title}>配送先住所</Typography>
        <Typography variant="subtitle1" className={classes.error_message}>{states.shippingErrorMessage}</Typography>

        <FormControl fullWidth>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            onChange={handleSelectShippingMethod}
            value={states.selectedShippingMethodStr}
            name="address-input-group"
          >
            {currentTeaserEmail?.shipping_methods?.map((_shipping_method: any, index: any) => (
              <FormControlLabel
                key={index}
                className={classes.shipping_method_card}
                value={index.toString()}
                control={<Radio color="default" />}
                label={
                  <div className={classes.label}>
                    <div className={classes.shipping_method_card_name}>
                      <Typography className={classes.shipping_method_card_text}>{_shipping_method.second_name} {_shipping_method.first_name}</Typography>
                    </div>
                    <div className={classes.shipping_method_card_address}>
                      <Typography className={classes.shipping_method_card_text}>{_shipping_method.zipcode}</Typography>
                      <Typography className={classes.shipping_method_card_text}>{_shipping_method.province}{_shipping_method.address1}</Typography>
                      <Typography className={classes.shipping_method_card_text}>{_shipping_method.address2}</Typography>
                      {_shipping_method.country_code !== 'JP' && _shipping_method.country_code !== undefined && <Typography className={classes.shipping_method_card_text}>{_shipping_method.province} {getCountryName(_shipping_method.country_code)}</Typography>}
                      <Typography className={classes.shipping_method_card_text}>{_shipping_method.phone}</Typography>
                    </div>
                  </div>
                }
              />
            ))}
            <FormControlLabel
              key="-1"
              className={classes.shipping_method_card}
              value="-1"
              control={<Radio color="default" />}
              label={
                <div className={classes.label}>
                  <Typography className={classes.shipping_method_card_text}>新しい配送先住所を使用する</Typography>
                </div>
              }
            />
          </RadioGroup>
        </FormControl>
        {states.selectedShippingMethodStr === '-1' &&
          <div className={classes.input_area}>
            <ShippingMethod />
            <FormControlLabel
              disabled={states.selectedShippingMethodStr !== "-1"}
              name="isShippingMethodSave"
              control={<CustomCheckBox checked={states.selectedShippingMethodStr === '-1' && states.isShippingMethodSave} onChange={handleCheckAddressSave} />}
              label="この住所を次回以降も使用する"
            />
          </div>
        }
      </MuiThemeProvider>
    </Container>
  );

});
export default AddressSelectView;

import { formatedYen } from "../../../utils/Common"
import { Divider, Typography, makeStyles } from "@material-ui/core"
import MainButton from "../../common/MainButton"
import UpDownDialog from "../../common/UpDownDialog"
import { useContext } from "react"
import { AuthContext } from "../../../contexts/Auth"

const useStyles = makeStyles(() => ({
    dialogTitle: {
        fontSize: '18px',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    bidWrapper: {
        padding: '40px 0'
    },
    bidInput: {
        height: '32px',
        borderRadius: '0',
        padding: '0 8px',
        border: '1px solid black',
    },
}))

const BidDialogView = ({
    product,
    open,
    setOpen,
    handleBid,
    register,
    getValues,
    processionStatus,
    errors,
    watch,
    myTicket
}: any) => {
    const classes = useStyles()
    const watchBidPrice = watch('bid_price')
    const { teaserEmail } = useContext<any>(AuthContext)

    return (
        <UpDownDialog open={open} setOpen={setOpen}>
            <div>
                <Typography variant='h1' className={classes.dialogTitle}>査定商品へ入札</Typography>
            </div>

            {product ?
                <div className={classes.bidWrapper}>

                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '32px' }}>
                        <div style={{ maxWidth: '200px' }}>
                            <img src={product.images[0].src} style={{ maxWidth: '200px' }} />
                        </div>
                        <div style={{ paddingLeft: '24px' }}>
                            <p>商品名: {product.title}</p>
                        </div>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <label htmlFor='bid_price' style={{ marginBottom: '8px' }}>入札金額&nbsp;（半角数字）</label>
                        <input
                            className={classes.bidInput}
                            type='number'
                            id="bid_price"
                            {...register('bid_price', {
                                required: {
                                    value: true,
                                    message: '入力をお願いします。'
                                }
                            })}
                        />
                        <p style={{ color: '#f00', fontSize: '14px' }}>{errors.bid_price?.message}</p>
                    </div>

                    {watchBidPrice &&
                        <div>
                            <dl>
                                <dt>入札金額</dt>
                                <dd>¥{formatedYen(getValues().bid_price)}</dd>
                            </dl>
                            <dl>
                                <dt>手数料</dt>
                                <dd>¥{formatedYen(getValues().bid_price * 0.1)}</dd>
                            </dl>
                            <Divider />
                            <dl>
                                <dt>落札時支払い総額</dt>
                                <dd>¥{formatedYen(Number(getValues().bid_price) + Number(getValues().bid_price * 0.1))}</dd>
                            </dl>
                        </div>
                    }

                    {teaserEmail?.is_buyer && (processionStatus === 'processionable' || processionStatus === 'processioned') &&
                        <MainButton
                            text={myTicket ? '入札を更新する' : '入札する'}
                            onClick={handleBid}
                            disabled={!watchBidPrice}
                        />
                    }
                </div>
                : <></>
            }
        </UpDownDialog>
    )
}

export default BidDialogView
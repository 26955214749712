import { Typography } from "@material-ui/core"
const AuctionTermsText = () => {
	return (
		<>
			<Typography variant="h4" component="h1" style={{ fontSize: "1.3rem", lineHeight: "2em", textAlign: "center", color: "grey", width: "90%", margin: "60px auto 40px", }}>オークション規約</Typography>
			<div style={{ textAlign: 'left' }}>
				Droppオークション利用規約（以下「本規約」といいます。）は、株式会社Dropp（以下「当社」といいます。）
				が提供するインターネットオークションにおける商品の取引等についての詳細を定めるものです。<br></br>
				当社が提供するインターネットオークションにおいては、商品に入札を希望する会員、
				商品を落札した会員及びインターネットオークションが行われるWEBページ及びこれを構成するコンテンツ（以下「オークションサイト」といいます。）
				を閲覧する者（いずれも法人を含むものとし、以下「利用者」と総称します。）は、本規約を遵守しなければならないものとします。
				なお、利用者がオークションサイトを閲覧した場合、利用者は本規約に同意したものとみなします。
				なお、本規約に定めのない事項については、当社が別途定める利用規約（https://dropp.jp/term-of-use以下「一般規約」といいます。）
				が性質上適用されない規定を除いて適用されるものとしますが、本規約と一般規約の内容が相互に矛盾抵触する場合は、本規約が優先するものとします。
				また、本規約で別段の定めがない限り、オークション規約において定義された用語は、本規約においても同様の意味を有するものとします。
			</div><br></br><br></br>
			<div style={{ color: 'grey' }}>第1条（入札）</div><br />
			<div style={{ textAlign: 'left' }}>
				会員が、オークションサイトを利用して商品の買い受けの申出を行うことを希望する場合（以下「入札希望者」といいます。）は、
				本規約の内容を承認のうえ、オークションサイトにおいて希望入札価額をオークションサイト上にて、
				当社が定める方法により申出を行わなければならないものとします。その他の方法による入札の申出は一切受け付けないものとします。
			</div><br /><br /><br />

			<div style={{ color: 'grey' }}>第2条（商品の売買契約の成立時期）</div><br />
			<div style={{ textAlign: 'left' }}>
				<ol style={{ margin: '0', paddingLeft: '1rem' }}>
					<li>
						入札希望者が商品を落札した場合（以下「落札者」といいます。）、
						その事実を落札者オークションサイト上で閲覧できるようになった時点又は当社から送信された
						落札者により商品が落札された旨の電子メールが落札者に到達した時点のいずれか早い時点で、
						落札者と販売者との間で、落札価額にて商品を売買する売買契約が成立するものとします。
						なお、落札価額とは、オークションサイトにて開示されるオークション開催期間を経過した時点における最高入札価額をいうものとします。
					</li>
					<li>
						オンラインオークション買受希望者がオンライン作品を落札した場合（オンラインオークション落札者となった場合）、
						その事実がオンラインオークション落札者の利用するオンラインオークションサイト上で閲覧できるようになった時点
						又は当社から送信された当該オンラインオークション落札者によりオンライン作品が落札された旨の電子メールが当該
						オンラインオークション落札者に到達した時点のいずれか早い時点で、当該オンラインオークション落札者と当社との間で、
						落札価額にてオンライン作品を売買する売買契約が成立するものとします。
					</li>
					<li>
						オークションサイトに出品される商品は、代替品を提供できない特定物であり、
						売買契約成立時点における現状有姿により引渡しが行われるものとします。
					</li>
				</ol>
			</div><br /><br /><br />

			<div style={{ color: 'grey' }}>第3条（商品情報の掲載）</div><br />
			<div style={{ textAlign: 'left' }}>会員は、本サービスにおいて以下の各号に規定する行為ができます。<br /><br />
				<ol style={{ margin: '0', paddingLeft: '1rem' }}>
					<li>
						当社は、オークションサイトにおいて、商品の画像、予想落札価額、最低入札価額及びその他の作品情報（以下「商品情報」といいます。）
						を掲載します。なお、オークションサイトにおけるは、商品の予想落札価額が、既存の評価額の上限を超え、又は評価額の下限を下回る場合があります。
					</li>
					<li>
						当社は、当社の裁量により、事前の予告なく、オークションサイトにおける商品情報の掲載を中止し、
						又は掲載内容を変更する場合があります。当社は、当該掲載中止及び掲載内容の変更について、一切責任を負いません。
					</li>
					<li>
						利用者は、自己の責任で、第1項に基づき掲載された商品情報を閲覧することにより、商品の情報及び状態等を確認するものとします。
					</li>
					<li>
						当社は、当社がその裁量により決定した場合を除いて、オークションサイトに出品する商品について、
						利用者が商品の下見を行うための展覧会を開催せず、商品情報を掲載したカタログは配布しないものとします。
					</li>
					<li>
						オークションサイトに掲載されている商品情報は、利用者の参考資料としてご利用いただくことを目的とするものです。
						当社は、商品の真贋、商品の品質、商品情報の真実性及び正確性等を一切保証せず、
						オークションサイトに掲載されているオンライン作品の画像の不明瞭等について、一切責任を負いません。
					</li>
				</ol>
			</div><br /><br /><br /><br />

			<div style={{ color: 'grey' }}>第4条（現在価額及び入札可能価額）</div><br />
			<div style={{ textAlign: 'left' }}>
				<ol style={{ margin: '0', paddingLeft: '1rem' }}>
					<li>
						当社は、オークション開催期間中、各商品について、利用者から入札がなされている現在の最高価額（未入札の商品については、
						当社がその裁量によりオークション開始時の価額として設定した価額とします。以下「現在価額」といいます。）及び利用者による
						入札が可能な最低価額（現在価額を上回る価額とし、以下「入札可能価額」といいます。）を、オークションサイトにおいて公表するものとします。
					</li>
					<li>
						当社は、当社の裁量により、商品の最低入札価額を下回る価額を、商品の現在価額及び入札可能価額として設定することができます。
					</li>
					<li>
						第1項の現在価額及び入札可能価額は、日本円により表示され、落札者と当社との間で締結される商品の売買契約の決済は、日本円により行われます。
					</li>
					<li>
						当社は、現在価額及び入札可能価額に係る表示につき、その内容が真実であり、かつ正確であることを保証するものではなく、
						これらの表示が真実ではなく、又は不正確であったことにより利用者に生じた損害については、当
						社の故意又は重大な過失による場合を除き、当社は一切責任を負いません。
					</li>
				</ol>
			</div><br /><br /><br />

			<div style={{ color: 'grey' }}>第5条（販売者名等の不公表）</div><br />
			<div style={{ textAlign: 'left' }}>
				当社は、商品の販売者名を公表しないものとします。但し、販売者の同意の下に、当社がその裁量により公表を決定した場合はこの限りではありません。
			</div><br /><br /><br />

			<div style={{ color: 'grey' }}>第6条（オークション開催期間）</div><br />
			<div style={{ textAlign: 'left' }}>
				<ol style={{ margin: '0', paddingLeft: '1rem' }}>
					<li>
						当社は、その裁量により、商品ごとにオークション開催期間を設定し、オークションサイト上に公表するものとします。
					</li>
					<li>
						前項にかかわらず、当社が設定したオークション開催期間の終了の5分前からオークション開催期間の終了までの間に、
						商品に対して入札の申出がなされた場合、当社は、商品のオークション開催期間を、
						当社が設定したオークション開催期間の終了日時から5分間延長するものとし、その後も同様とします。
						なお、オークション開催期間が延長された場合、当該延長がなされた期間をオークション開催期間として、本規約が適用されるものとします。
					</li>
				</ol>
			</div><br /><br /><br />

			<div style={{ color: 'grey' }}>第7条（入札の申出）</div><br />
			<div style={{ textAlign: 'left' }}>
				<ol style={{ margin: '0', paddingLeft: '1rem' }}>
					<li>
						入札希望者は、オークション開催期間中、オークションサイト上の所定のログイン画面からID及びパスワードを入力のうえ、
						オークションサイトへログインすることにより、オークションサイトにおいて商品の入札の申出を行うことができるものとします。
					</li>
					<li>
						入札希望者は、入札を希望する商品について、商品の商品情報、現在価額及び入札可能価額等が表示されるオークションサイト上の画面
						（以下「オークション画面」といいます。）上に表示される入札希望価額欄（以下「入札希望価額欄」といいます。）に入札希望価額を入力し、
						オークション画面上に表示されるビッドボタンをクリックすることにより、当該価額にて、当該商品の入札の申出を行うものとします。
						なお、入札希望者が入札希望価額を入力する前においては、入札希望価額欄に入札可能価額が自動的に入力され表示されていますが、
						入札希望者は、当該表示価額以上の価額を入札希望価額欄に入力することもできます。
					</li>
					<li>
						入札希望者が入札希望価額欄に入札希望価額を入力する場合、当社所定の入札希望価額の単位（以下「入札可能単位」といいます。）に従い、
						入札可能価額以上の価額を入力する必要があります。なお、入札希望者が、入札可能単位と整合しない入札希望価額を入力した場合、
						当該入札の申出は無効となります。
					</li>
					<li>
						入札希望者が一度ビッドボタンをクリックした場合、入札希望者その他の第三者による誤操作等の理由の如何を問わず、
						入札希望者は当該入札の申出の取消しをすることはできず、入札希望者はこれに対して異議を述べないものとします。
					</li>
					<li>
						入札希望者は、日本円によってのみ商品の入札の申出を行うことができるものとし、
						その他の通貨による入札の申出を行うことはできないものとします。
					</li>
					<li>
						入札希望者がビッドボタンをクリックした後、当該クリックの事実及び当該クリックの時点で入力されていた入札希望価額が当社に到達したとき、
						当該入札希望者から当社に対して当該入札希望価額にて商品の購入の申し込みがなされたものとします。
					</li>
					<li>
						入札希望者は、自己の入札の申出の状況について、自己の責任で確認するものとします。
					</li>
					<li>
						複数の者が共同の名義により、一つの入札の申出をすることはできません。
					</li>
				</ol>
			</div><br /><br /><br />

			<div style={{ color: 'grey' }}>第8条（自動入札機能)</div><br />
			<div style={{ textAlign: 'left' }}>
				<ol style={{ margin: '0', paddingLeft: '1rem' }}>
					<li>
						入札希望者は、オークション画面上において、入札を希望する商品について、あらかじめ定めた入札希望価額の上限額
						（以下「自動入札価額」といいます。）に達するまで、自己の入札の申出が、他の入札希望者からより高額の入札の申出がなされたことにより失効した場合、
						直ちに、その時点の入札可能価額により、自己を入札希望者として、自動的に入札の申出がなされます。
					</li>
					<li>
						入札希望者は、自動入札機能の設定解除、撤回、取消し又は変更を行うことはできません。
					</li>
					<li>
						入札希望者は、自動入札価額を日本円でのみ設定することができます。また、入札希望者は、自動入札価額の設定にあたり、入札可能単位に従わなければなりません。
					</li>
					<li>
						自動入札機能により入札の申出がなされた場合、入札希望者及びその他の第三者による誤操作等の理由の如何を問わず、
						当該入札の申出の取消しをすることはできず、入札希望者はこれに対して異議を述べないものとします。
					</li>
				</ol>
			</div><br /><br /><br />

			<div style={{ color: 'grey' }}>第9条（入札の申出の失効）</div><br />
			<div style={{ textAlign: 'left' }}>
				入札の申出は、以下の場合に失効します。
				<ol style={{ margin: '0', paddingLeft: '1rem' }}>
					<li>
						より高額の入札の申出がなされたとき。
					</li>
					<li>
						当社がオークションを中断したとき。
					</li>
					<li>
						入札の申出が、オークションサイト上で提供される決済を通過しないとき。
					</li>
				</ol>
			</div><br /><br /><br />

			<div style={{ color: 'grey' }}>第10条（作品の状態等）</div><br />
			<div style={{ textAlign: 'left' }}>
				商品は、その性質上、現状有姿のままオークションサイトに出品及び販売されるものであり、当社は、
				本作品のシミ、キズその他の瑕疵、欠陥等について一切責任を負いません。利用者は、
				オークションサイトに出品される商品又は商品情報について、オークション画面上により確認するものとします。
			</div><br /><br /><br />


			<div style={{ color: 'grey' }}>第11条（支払方法及び引渡し方法の選択）</div><br />
			<div style={{ textAlign: 'left' }}>
				<ol style={{ margin: '0', paddingLeft: '1rem' }}>
					<li>
						落札者は、支払方法として、事前にクレジットカードにおけるオーソリ機能を利用した決済による支払を行うものとします。
						なお、落札者が決済手段として使用できるクレジットカードは、落札者本人名義のカードに限られます。
					</li>
					<li>
						引渡し方法は、当社又は販売者から落札者に対して商品を配送する方法によるものとします。
						なお、配送は当社又は販売者の指定する運送業者が行うものとし、商品を運送業者に引き渡した時点で、落札者に対する引渡しが完了したものとみなします。
					</li>
				</ol>
			</div><br /><br /><br />

			<div style={{ color: 'grey' }}>第12条（支払金額）</div><br />
			<div style={{ textAlign: 'left' }}>
				落札者は、落札者が落札したオンライン作品の落札価額、落札価格の15.0％（金1円未満の金額は切り捨てるものとします。）
				の手数料（以下「当社手数料」といいます。）、当社手数料にかかる消費税、当社の定める配送料及びその他当社がオークションサイト上
				に表示するものを合算した金額を、第11条第1項に基づき支払うものとします。落札者とクレジットカード会社又は立替代行業者等との間で、
				請求金額の支払その他の債務に関して紛争（トラブル、クレーム、訴訟その他裁判上又は裁判外における一切の紛争又は争訟を含みます。以下同じです。）
				が発生したとき、全て落札者の責任及び費用により解決するものとします。
			</div><br /><br /><br />

			<div style={{ color: 'grey' }}>第13条（危険負担及び所有権の移転）</div><br />
			<div style={{ textAlign: 'left' }}>
				売買契約成立時点以降の当社の責に帰すべからざる事由による商品の滅失、紛失、盗難、毀損、汚損その他一切の危険は、
				落札者の負担とし、落札者は請求金額の支払いを免れることができません。
			</div><br /><br /><br />

			<div style={{ color: 'grey' }}>第14条（引渡し）</div><br />
			<div style={{ textAlign: 'left' }}>
				<ol style={{ margin: '0', paddingLeft: '1rem' }}>
					<li>
						当社は、落札者が第12条に定める支払金額の支払いを完了した日から20営業日以内（以下この期間を「引渡期間」といいます。
						但し、オークションサイト上に別途引渡期間を定めた場合は当該引渡期間が優先されるものとします。）に、
						落札者が落札した商品を引き渡すものとします。なお、落札者の指定する配送先又は指定先への配送途中において、
						落札者以外の第三者が当該商品を取得したこと等に起因して損害が発生した場合であっても、当社は一切責任を負いません。
					</li>
					<li>
						落札者は、運送業者から商品を受領した場合、当社に対して、落札者が署名捺印又は記名押印をした受領書を直ちに交付するものとします。
					</li>
					<li>
						商品の引渡し完了後の事故（滅失、紛失、盗難、毀損、汚損等）について、当社は一切責任を負いません。
						また、落札者の求めにより、当社が運送業者を斡旋し、又は本作品の梱包を施した場合であっても、
						その運送業者の選定や梱包の状態にかかわらず、当社はその事故について責任を負いません。落札者には、商品の引き取りにつき、
						自らの責任と費用負担において、保険を付す等の対応を行っていただくものとします。
					</li>
					<li>
						商品の引渡後は、落札者は、当社に対し、商品違いの主張を行うことができません。
						但し、当社が、誤って落札した商品と別の商品を落札者に引き渡した場合はこの限りでないものとし、
						落札者は、速やかに引渡しを受けた当該商品を当社に返還するものとします。
					</li>
					<li>
						正当な理由なく商品を当社に返送した場合、当該商品を受領後遅滞なく会員に対して通知し、
						保管期限（受領後１ヶ月）を定めた上で当該商品の受け取りにつき会員に対して指示を求めるものとします。
						なお、当社は、会員から商品の受け取りに関する指示を受けた場合、当該商品について現状有姿で引渡すものとし、
						当該商品の状態（商品の変質、変形、消耗、毀損及び腐敗を含みますが、これらに限りません。）について、責任を負わないものとします。
						ただし、保管期限を過ぎたものは処分できるものとする。
					</li>
				</ol>
			</div><br /><br /><br />


			<div style={{ color: 'grey' }}>第15条（諸費用）</div><br />
			<div style={{ textAlign: 'left' }}>
				落札者は、引渡期間内に商品の引き取りができない場合、
				引渡期間終了後から引き取りのときまでの当社が指定する商品の保管及び保険に要する費用を負担するものとします。
			</div><br /><br /><br />

			<div style={{ color: 'grey' }}>第16条（盗品、遺失物等）</div><br />
			<div style={{ textAlign: 'left' }}>
				<ol style={{ margin: '0', paddingLeft: '1rem' }}>
					<li>
						当社が落札者に商品の引渡しをする以前に、商品について、盗品、遺失、相続、その他の原因の如何を問わず、
						真正な所有者と主張する者から返還請求があった場合又は商品が法律の定めによる売買禁止物（所持の禁止を含みます。）
						であることが判明した場合、当社は当該商品の売買契約を催告なしに解除することができるものとします。
						この場合、当社は落札者から支払金額の支払いを受けているときはこれを無利息で返還するものとし、
						落札者は当社に対し、損害賠償その他の請求をすることはできないものとします。
					</li>
					<li>
						古物営業法（昭和24年法律第108号。その後の改正を含みます。）第21条の規定により警察本部長等が当社に対し保管を命じ、
						その保管の期間の終了日が引渡期間の満了後に到来するときは、その保管の期間の終了まで当社は本作品の引渡しをせず、
						第14条第1項の「支払金額の支払を完了した日から20営業日以内」とあるのは
						「警察本部長等が保管を命じた期間の終了日から20営業日以内」と読み替えて適用するものとします。
						なお、このため引渡しが遅滞したことに起因する損害について、当社はその責任を負わないものとします。
					</li>
				</ol>
			</div><br /><br /><br />

			<div style={{ color: 'grey' }}>第17条（落札者の債務不履行）</div><br />
			<div style={{ textAlign: 'left' }}>
				落札者が支払金額を支払わないときは、以下の各号の定めに従うものとします。
				<ol style={{ margin: '0', paddingLeft: '1rem' }}>
					<li>
						落札者は、支払金額（消費税分を除きます。）の未払残金について、年14.6％の遅延損害金を支払わなければならないものとします。
					</li>
					<li>
						前項の定めにかかわらず、当社のオークションサイト上において、商品の作者名が断定的に明記され（作者について争いがある旨の記載、
						「伝○○」、「推定○○作」等作者名の表示が推定に基づくものであることを示す記載、又は「○○派」、「○○工房」、「○○スクール」
						等必ずしも作者を特定できない記載がなされている場合等を除きます。）、かつ、当該商品の真贋を保証する旨が明示されている場合において、
						後日当該商品がオークションサイト上に表示された作者の制作物でないこと（以下「不真正性」といいます。）が判明し
						（但し、オークション開催当時の学者、専門家の知見の水準において一般にオークションサイト上に表示された作者の制作物と認識されていた場合、
						当該商品が落札されたオークション開催当時に一般的でなかった科学的検査方法若しくは多額の費用を要する検査方法の実施により不真正性が判明した場合、
						又は制作物を傷つける等通常は行われない検査方法若しくは破損等により不真正性が判明した場合を除きます。）、当社が当該不真正性を認めるときは
						、以下の各号の条件全てを満たす場合に限り、当社は落札者の請求により売買契約を解除し、当該商品の返還と引換えに支払金額の払い戻しをします。
						但し、当社は、支払金額の払い戻しをする他は責任を負わず、利息及び損害賠償等は一切負担しないものとします。
						<ol style={{ margin: '0', paddingLeft: '1rem' }}>
							<li>
								落札者が、当該商品が落札されたオークション終了日又はオークション終了日から1年以内で、
								かつ当該商品の不真正性を知ったときから1ヶ月以内に、当該作者の制作物ではないことについて当社が納得しうる
								客観的・合理的な根拠を添えて、オークション開催期間、商品名、落札価額等を明記した書面により当社に対し請求すること。
							</li>
							<li>
								落札者が、当社に商品の完全な所有権を移転し、かつ、商品をオークション等当時の状態で当社に引き渡すこと。
							</li>
						</ol>
					</li>
					<li>
						成約手付金が設定されているオークション商品については、オークション落札後、
						落札者が当社が指定する期日までにオークション落札金額から設定された成約手付金を控除した残額を支払わない場合、
						支払期日の経過を以って落札者からの解約の意思表示とみなし、成約手付金を解約手付金として当社が受け取るものとし、
						落札金額による売買契約は解除されるものとする。
					</li>
				</ol>
			</div><br /><br /><br />


			<div style={{ color: 'grey' }}>第18条（真贋保証）</div><br />
			<div style={{ textAlign: 'left' }}>
				落札者が支払金額を支払わないときは、以下の各号の定めに従うものとします。
				<ol style={{ margin: '0', paddingLeft: '1rem' }}>
					<li>
						当社は、商品の真贋について保証しません。入札希望者は自らの責任と判断で商品の購入の申し込みを行うものとします。
					</li>
					<li>
						当社が落札者に支払金額の支払いを催告しても支払わない場合、当社は売買契約を解除することができます。
					</li>
				</ol>
			</div><br /><br /><br />

			<div style={{ textAlign: 'left' }}>
				以上
			</div><br /><br /><br />

		</>
	)
}
export default AuctionTermsText;


import React, { memo, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Checkbox,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  List,
  ListItem,
  makeStyles,
  MuiThemeProvider,
  Radio,
  RadioGroup,
  Typography,
  withStyles,
} from "@material-ui/core";
import { createMuiTheme } from '@material-ui/core/';
import { CardElement } from '@stripe/react-stripe-js'
import { CloudFunctions, TicketStatusValue } from "../../utils/Firebase";
import Common from "../../redux/common/commonSlice";
import ProductInfoView from "./ProductInfoView";
import TermOfUseSlideView from "../common/TermOfUseSlideView";
import { getCountryName } from "../../utils/Shipping";
import { resetShippingMethod } from "../../redux/shippingMethod/shippingMethodSlice";
import { useHistory } from "react-router-dom";
import { Product } from "../../types/product";
import { useSelector } from "../../redux/rootReducer";

const cardElementOptions = {
  hidePostalCode: true,
  style: {
    base: {
    },
    invalid: {},
    complete: {},
  },
}

type CheckboxType = {
  checked: any,
  onChange: any,
  name?: string
}

const CustomCheckBox = withStyles({
  root: {
    color: "#ff0000",
    '&$checked': {
      color: "#ff0000",
    },
  },
  checked: {},
})((props: CheckboxType) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
  main_container: {
    marginTop: theme.spacing(12),
    paddingBottom: theme.spacing(8),
    maxWidth: "450px",
  },
  card: {
    padding: "20px",
    margin: "0 auto",
    marginTop: "10px",
    position: "relative"
  },
  error_message: {
    color: "#ff0000",
    fontSize: "0.9rem",
    fontWeight: "bold",
    marginTop: "6px",
  },
  card_wrapper: {
    minHeight: "100px",
    marginBottom: "50px",
  },
  credit_card: {
    border: "1px solid black",
    width: "100%",
    position: "relative",
    left: "10px",
    marginTop: "12px",
  },
  selected_credit_card: {
    border: "1px solid rgba(0, 0, 0, 0.38)",
    width: "340px",
    position: "relative",
    left: "10px",
    marginTop: "12px",
  },
  label: {
    width: "100%",
    display: "flex",
    alignItems: "justify-content",
  },
  add_button: {
    borderRadius: "20px",
    margin: "16px 0",
    border: "1px solid black",
  },
  card_element: {
    marginTop: "10px",
    marginBottom: "16px",
    border: "1px solid rgba(0, 0, 0, 0.38)",
    borderRadius: "0",
    padding: "15px",
    boxSizing: "border-box",
    minWidth: "100%",
  },
  selected_card_element: {
    marginTop: "10px",
    marginBottom: "16px",
    border: "1px solid black",
    borderRadius: "0",
    padding: "15px",
  },
  botton_wrapper: {
    marginTop: "80px",
    display: "flex",
    justifyContent: "center",
  },
  next_botton: {
    color: "red",
    marginLeft: "10px",
  },
  list_root: {
    marginBottom: "20px",
  },
  product_info_list: {
    display: "flex",
    textAlign: "center",
  },
  product_info_list_title: {
    width: "50%",
  },
  product_info_list_text: {
    width: "50%",
  },
  shipping_method_view: {
    marginBottom: "60px",
  },
  shipping_method_wrap: {
    display: "flex",
    alignItems: "center",
    marginBottom: "0.5rem",
    width: "100%",
  },
  shipping_method_title: {
    color: "grey",
    width: "20%",
    verticalAlign: "middle"
  },
  shipping_method_text: {
    width: "70%",
    color: "grey",
  },
  shipping_method: {
    width: "100%",
    boxSizing: "border-box",
    marginBottom: "16px",
  },
  update_button: {
    color: "#ff0000",
    borderRadius: "0",
    border: "1px solid #ff0000",
  },
}));

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#000',
      main: '#000',
      dark: '#000',
      contrastText: '#fff',
    },
  },
});

type Props = {
  productInfo: Product,
  currentTeaserEmail: any,
  states: any,
  setStates: any,
  handleEnter: any,
  setEntryProcess: any,
  myTicket: any,
  optionPrice: any,
  variation: any,
}

const PaymentMethodInput = memo((props: Props) => {
  const { productInfo, currentTeaserEmail, states, setStates, handleEnter, setEntryProcess, myTicket, optionPrice, variation } = props
  const dispatch = useDispatch();
  const classes = useStyles();
  const [termOfUseOpen, setTermOfUseOpen] = useState(false)
  const [isCheckedTerms, setIsCheckedTerms] = useState(false)
  const [onShowTermsType, setOnShowTermsType] = useState("")
  const [variationPrice, setVariationPrice] = useState(0)
  const shippingMethod = useSelector((state) => state.shippingMethod)
  const history = useHistory()

  useEffect(() => {
    if (variation) {
      setVariationPrice(Number(variation.price) - Number(productInfo.price))
    }
  }, [variation])

  // 支払いカード一覧取得
  const [onPaymentMethodsLoading, setOnPaymentMethodsLoading] = useState(false)
  useEffect(() => {
    const _getPaymentMethods = async () => {
      if (currentTeaserEmail?.stripe_customer_id) {
        // if (currentTeaserEmail?.test_stripe_customer_id) {
        setOnPaymentMethodsLoading(true)
        try {
          let stripeGetPaymentMethods = CloudFunctions.httpsCallable("stripeGetPaymentMethods");
          // let stripeGetPaymentMethods = CloudFunctions.httpsCallable("testStripeGetPaymentMethods");
          let customer_id = currentTeaserEmail.stripe_customer_id
          // let customer_id = currentTeaserEmail.test_stripe_customer_id
          let _payment_methods = await stripeGetPaymentMethods({ customer_id })
          let selectedPaymentMethodStr = _payment_methods.data.data.length > 0 ? "0" : "-1"
          setStates({
            ...states,
            paymentMethods: _payment_methods.data.data,
            selectedPaymentMethodStr,
            isPaymentMethodSave: selectedPaymentMethodStr === '-1',
          })
        } catch (e) {
          setStates({ ...states, selectedPaymentMethodStr: "-1", isPaymentMethodSave: true })
        }
        setOnPaymentMethodsLoading(false)
      }
    }
    _getPaymentMethods()
  }, [])

  // 支払いカード削除
  // const [onDetachPaymentMethod, setOnDetachPaymentMethod] = useState(false)
  // const handleRemoveCard = async (_payment_method_id: any) => {
  //   console.log('支払いカード削除')
  //   setOnDetachPaymentMethod(true)
  //   setStates({ ...states, selectedPaymentMethodStr: "" })
  //   const stripeDetachPaymentMethod = CloudFunctions.httpsCallable("stripeDetachPaymentMethod");
  //   // const stripeDetachPaymentMethod = CloudFunctions.httpsCallable("testStripeDetachPaymentMethod");
  //   let res = await stripeDetachPaymentMethod({
  //     payment_method_id: _payment_method_id
  //   })
  //   if (res.data.status === 'success') {
  //     let _new_payment_methods = states.paymentMethods.filter((_payment_method: any) => _payment_method.id !== _payment_method_id)
  //     console.log('new_paymentmethods', _new_payment_methods)
  //     setStates({ ...states, paymentMethods: _new_payment_methods })
  //     console.log('支払いカード削除完了')
  //   }
  //   setOnDetachPaymentMethod(false)
  // }

  const handleCheckTerm = () => {
    setIsCheckedTerms(!isCheckedTerms)
  }

  const handleTermsViewToggle = (e: any) => {
    setOnShowTermsType(e.target.innerHTML)
    setIsCheckedTerms(false)
    setTermOfUseOpen(true)
  }

  const handleTermsViewClose = () => {
    setTermOfUseOpen(false)
    setIsCheckedTerms(false)
  }

  // 確認ボタン
  const handleToConfirm = () => {
    // ①支払い方法が選択されているか
    if (!states.selectedPaymentMethodStr) {
      setStates({ ...states, paymentErrorMessage: "支払い方法を選択してください。" })
      scrollTo({ top: 500, behavior: "smooth" })
      return
    }
    // ②新しいカードの場合正しい情報が入力されているか
    if (states.selectedPaymentMethodStr === '-1' && !isNewCardInputCompleted) {
      setStates({ ...states, paymentErrorMessage: "カード情報を入力してください。" })
      dispatch(
        Common.actions.fetchAlert({
          alerts: [{ message: `カード情報を入力してください。` }],
        })
      );
      scrollTo({ top: 500, behavior: "smooth" })
      return
    } else if (!states.selectedPaymentMethodStr) {
      dispatch(
        Common.actions.fetchAlert({
          alerts: [{ message: `お支払いカードを選択してくだい` }],
        })
      );
      scrollTo({ top: 500, behavior: "smooth" })
      return
    }
    setStates({ ...states, dialogOpen: true })
  }

  // エントリー中断
  const handleEntryCancel = () => {
    history.push(`/detail/${productInfo.id}`)
    setEntryProcess(0)
    window.location.reload()
  }

  // 支払いカード選択
  const handleSelectCard = (e: any) => {
    setStates({
      ...states,
      selectedPaymentMethodStr: e.target.value,
      isPaymentMethodSave: e.target.value === '-1',
      paymentErrorMessage: e.target.value === '-1' ? states.paymentErrorMessage : "",
    })
  }

  // 配送先住所選択に戻る
  const handleReturnShipppingMethodSelect = () => {
    if (states.selectedShippingMethodStr !== '-1') {
      dispatch(resetShippingMethod())
    }
    history.push(`/detail/${productInfo.id}/shipping`)
    setEntryProcess(2)
    window.scrollTo(0, 0)
  }

  // クレカ入力を監視
  const [isNewCardInputCompleted, setIsNewCardInputCompleted] = useState(false)
  const handleCardChange = (e: any) => {
    if (e.complete) {
      setStates({ ...states, paymentErrorMessage: "" })
    }
    setIsNewCardInputCompleted(e.complete)
  }

  const handleCheckCard = (e: any) => {
    setStates({
      ...states,
      [e.target.name]: e.target.checked,
    })
  }

  const PaymentMethodsView = () => {
    return <>
      <FormControl fullWidth>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          onChange={handleSelectCard}
          value={states.selectedPaymentMethodStr}
          name="radio-buttons-group"
        >

          <>
            {states.paymentMethods.map((_payment_method: any, index: any) => (
              <FormControlLabel
                key={index}
                className={classes.credit_card}
                value={index.toString()}
                // classes={{ checked: classes.selected_credit_card }}
                control={<Radio color="default" />}
                label={
                  <div className={classes.label}>
                    <Typography variant="subtitle1" style={{ marginRight: "12px", width: "110px" }}>****{_payment_method.card.last4}</Typography>
                    <Typography variant="subtitle1" style={{ marginRight: "8px", fontSize: "0.75rem", lineHeight: "24px", width: "50px" }}>有効期限</Typography>
                    <Typography variant="subtitle1" style={{ marginRight: "8px", width: "73px" }}>{_payment_method.card.exp_month} / {_payment_method.card.exp_year}</Typography>
                    {/* <Typography style={{ fontSize: "0.75rem", color: "#ff0000", lineHeight: "24px", float: "right" }} onClick={() => handleRemoveCard(_payment_method.id)}>削除</Typography> */}
                  </div>
                }
              />
            ))}
          </>
          <FormControlLabel
            className={classes.credit_card}
            value="-1"
            control={<Radio color="default" />}
            label={
              <div className={classes.label}>
                <Typography variant="subtitle1" style={{ marginRight: "12px" }}>新しいカードを使用する</Typography>
              </div>
            }
          />
        </RadioGroup>
      </FormControl>
    </>;
  }

  const ConfirmDialogView = () => {
    return (
      <Dialog
        open={states.dialogOpen}
      >
        <DialogContent style={{ color: 'black', marginBottom: '0' }}>
          <div>
            <List className={classes.list_root}>
              {myTicket?.status == TicketStatusValue["ADDITIONAL_PAYMENT"] ?
                <>
                  <ListItem className={classes.product_info_list}>
                    <Typography variant='subtitle2' className={classes.product_info_list_title}>オプション<br></br>{myTicket?.variation['1']}</Typography>
                    <Typography variant='subtitle1' className={classes.product_info_list_text}> ¥{String(Number(optionPrice)).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}</Typography>
                  </ListItem>
                  <Divider />
                  <ListItem className={classes.product_info_list}>
                    <Typography variant='subtitle2' className={classes.product_info_list_title}>追加費用合計</Typography>
                    <Typography variant='subtitle1' className={classes.product_info_list_text}> ¥{String(Number(optionPrice)).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}</Typography>
                  </ListItem>
                </>
                :
                <>
                  <ListItem className={classes.product_info_list}>
                    <Typography variant='subtitle2' className={classes.product_info_list_title}>商品金額</Typography>
                    <Typography variant='subtitle1' className={classes.product_info_list_text}> ¥{String(productInfo.price).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}</Typography>
                  </ListItem>
                  {variation?.title !== 'Default Title' &&
                    <ListItem className={classes.product_info_list}>
                      <Typography variant='subtitle2' className={classes.product_info_list_title}>オプション<br></br>{variation.title}</Typography>
                      <Typography variant='subtitle1' className={classes.product_info_list_text}> ¥{String(variationPrice).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}</Typography>
                    </ListItem>
                  }
                  <ListItem className={classes.product_info_list}>
                    <Typography variant='subtitle2' className={classes.product_info_list_title}>配送料</Typography>
                    <Typography variant='subtitle1' className={classes.product_info_list_text}> ¥{String(states.shippingFee).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}</Typography>
                  </ListItem>
                  <ListItem className={classes.product_info_list}>
                    <Typography variant='subtitle2' className={classes.product_info_list_title}>合計</Typography>
                    <Typography variant='subtitle1' className={classes.product_info_list_text}> ¥{String(Number(productInfo.price) + Number(states.shippingFee) + Number(variationPrice)).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}</Typography>
                  </ListItem>
                </>
              }
            </List>
          </div>
          上記の内容で決済を実行します。よろしいですか？<br></br>
        </DialogContent>
        <DialogActions>
          <Button variant="text" autoFocus onClick={() => setStates({ ...states, dialogOpen: false })}>いいえ</Button>
          <Button variant="text" onClick={handleEnter} style={{ color: '#ff0000' }}>実行する</Button>
        </DialogActions>
      </Dialog>
    );
  }

  const SelectedShippingMethodView = () => {
    const isOversea = !(shippingMethod.countryCode === 'JP' || shippingMethod.countryCode === undefined)

    return (
      <div className={classes.shipping_method_view}>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <Typography variant="subtitle2" style={{ color: "grey", fontSize: "1rem", marginBottom: "8px" }}>配送先情報</Typography>
          <Button variant="outlined" className={classes.update_button} onClick={handleReturnShipppingMethodSelect}>変更する</Button>
        </div>
        <dl className={classes.shipping_method_wrap}>
          <dt className={classes.shipping_method_title}>
            {isOversea ? "Name" : "お名前"}
          </dt>
          <dd className={classes.shipping_method_text}>{shippingMethod.secondName} {shippingMethod.firstName}</dd>
        </dl>
        {!isOversea && <dl className={classes.shipping_method_wrap}>
          <dt className={classes.shipping_method_title}>フリガナ</dt>
          <dd className={classes.shipping_method_text}>{shippingMethod.secondNameKana} {shippingMethod.firstNameKana}</dd>
        </dl>}
        <dl className={classes.shipping_method_wrap}>
          <dt className={classes.shipping_method_title}>
            {isOversea ? "Phone Number" : "電話番号"}
          </dt>
          <dd className={classes.shipping_method_text}>{shippingMethod.phone}</dd>
        </dl>
        <dl className={classes.shipping_method_wrap}>
          <dt className={classes.shipping_method_title}>
            {isOversea ? "Address" : "住所"}
          </dt>
          <dd className={classes.shipping_method_text}>
            {shippingMethod.zipcode}<br></br>
            {shippingMethod.province} {shippingMethod.address1}<br></br>
            {shippingMethod.address2} {isOversea && shippingMethod.city} {isOversea && getCountryName(shippingMethod.countryCode)}
          </dd>
        </dl>
      </div>
    )
  }

  return (
    <Container className={classes.main_container}>
      <MuiThemeProvider theme={theme}>
        <div className={classes.card}>
          <ProductInfoView productInfo={productInfo} shippingFee={states.shippingFee} myTicket={myTicket} optionPrice={optionPrice} variation={variation} />
          {/* 決済方法 */}
          <div className={classes.card_wrapper}>
            <Typography style={{ color: "grey", fontSize: "1rem" }}>支払い方法</Typography>
            <Typography variant="subtitle1" className={classes.error_message}>{states.paymentErrorMessage}</Typography>
            {onPaymentMethodsLoading ? <div style={{ textAlign: "center" }}><CircularProgress size={36} style={{ color: "grey" }} /></div>
              :
              <>
                <PaymentMethodsView />
                {states.selectedPaymentMethodStr === '-1' &&
                  <>
                    <CardElement id="card_element" onChange={handleCardChange} className={states.selectedPaymentMethodStr === "-1" ? classes.selected_card_element : classes.card_element} options={cardElementOptions} />
                    <FormControlLabel
                      disabled={states.selectedPaymentMethodStr !== "-1"}
                      control={<CustomCheckBox name="isPaymentMethodSave" checked={states.selectedPaymentMethodStr === '-1' && states.isPaymentMethodSave} onChange={handleCheckCard} />}
                      label="このカードを次回以降も使用する"
                    />
                  </>
                }
              </>
            }
          </div>
          {/* 配送先住所 */}
          <SelectedShippingMethodView />
          {/* 規約同意 */}
          <FormControlLabel
            control={<CustomCheckBox onChange={handleCheckTerm} checked={isCheckedTerms} />}
            label={
              <div>
                <span onClick={handleTermsViewToggle} style={{ color: "red" }}>利用規約</span>
                <span>と</span>
                <span onClick={handleTermsViewToggle} style={{ color: "red" }}>オーソリ規約</span>
                <span>に同意する</span>
              </div>
            }
          />
          <div className={classes.botton_wrapper}>
            <Button onClick={handleEntryCancel}>
              {myTicket?.status === TicketStatusValue['WAITING_PAYMENT'] ? <>戻る</> : <>エントリーをキャンセル</>}
            </Button>
            <Button disabled={!isCheckedTerms} className={classes.next_botton} onClick={handleToConfirm}>確認へ</Button>
          </div>
        </div>
        <ConfirmDialogView />
        <TermOfUseSlideView
          termOfUseOpen={termOfUseOpen}
          handleTermsViewClose={handleTermsViewClose}
          onShowTermsType={onShowTermsType}
        />
      </MuiThemeProvider>
    </Container>
  )

});
export default PaymentMethodInput;

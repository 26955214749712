import { Button, Container, makeStyles } from '@material-ui/core'
import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { CartContext } from '../contexts/Cart'

const useStyles = makeStyles(() => ({
  container: {
    marginTop: "136px",
  },
  thanks_text: {},
  button_wrapper: {
    marginTop: "30px",
    display: "flex",
    justifyContent: "center"
  },
  home_button: {
    color: "#ff0000"
  }
}))

const CartThanks = () => {
  const history = useHistory()
  const classes = useStyles()
  const { myCart, deleteCart } = useContext<any>(CartContext)

  useEffect(() => {
    const _deleteCart = async () => {
      if (myCart?.items?.length > 0) {
        await deleteCart()
      }
    }
    _deleteCart()
  }, [])

  const handleToTop = () => {
    history.push('/')
  }

  return (
    <Container className={classes.container} maxWidth="xs">
      <div className={classes.thanks_text}>
        ご購入いただきありがとうございます。<br></br>
        商品の購入が完了いたしました。<br></br>
        発送準備をさせていただいておりますので、発送完了まで今しばらくお待ちください。<br></br>
        ご購入内容はメール及び購入履歴からご確認いただけます。
      </div>
      <div className={classes.button_wrapper}>
        <Button className={classes.home_button} onClick={handleToTop} variant="text">ホームに戻る</Button>
      </div>

    </Container>
  )
}

export default CartThanks
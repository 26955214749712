import { useContext } from "react";
import { AuthContext } from "../../../contexts/Auth";

const ActionsView = () => {
    const { teaserEmail } = useContext<any>(AuthContext);


    if (!teaserEmail) {
        return (<></>)
    } else {
        if (teaserEmail.is_buyer) {
            return (
                <></>
            )
        } else {
            return (
                <></>
            )
        }
    }
}

export default ActionsView
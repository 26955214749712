import React, { useEffect, useContext, useState } from "react";
import {
  CircularProgress,
  Container,
  makeStyles,
  MuiThemeProvider,
  Tab,
  Tabs,
  createStyles,
} from "@material-ui/core";
import { Theme, createMuiTheme } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import EntryProductsView from "../components/entry/ProductsView";
import { db } from "../utils/Firebase";
import { AuthContext } from "../contexts/Auth";
import { motion, useAnimation } from "framer-motion";
import { isProductVariants } from "../Animations/variants";
import { withStyles } from "@material-ui/styles";
import BuyNowHistoryView from "../components/entry/BuyNowHistoryView";
import { myTicket } from "../types/ticket";

const useStyles = makeStyles((theme: Theme) => createStyles({
  tabs: {
    display: "flex",
    width: "100%",
    height: "60px",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
    position: "fixed",
    top: "67px",
    backgroundColor: "white",
  },
  title: {
    fontWeight: 'bold',
    marginTop: theme.spacing(2),
  },
  container: {
    marginTop: "127px",
    minHeight: '94vh',
  }
}));

const StyledTab = withStyles(() => ({
  root: {
    width: "100px",
    minWidth: "100px",
    '&.Mui-selected': {
      color: 'black',
      fontWeight: "bold",
    },
  },
}))((props: { label: string }) => <Tab {...props} />)

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: "#212121",
    }
  }
});


const History = () => {
  const classes = useStyles();
  const controls = useAnimation();
  const [tabValue, setTabValue] = React.useState(0)
  const [shopLoading, setShopLoading] = React.useState(false);
  const [mytickets, setMytickets] = React.useState<myTicket[]>([]);
  const [orders, setOrders] = useState([])
  const { currentUser } = useContext<any>(AuthContext);

  // 抽選データの取得
  useEffect(() => {
    let isMounted = true

    const f = async (user: any) => {
      setShopLoading(true)
      let _mytickets: any = [];
      await db.collection("tickets").where("uid", "==", user.uid).get().then(snapshot => {
        snapshot.forEach((_ticket) => {
          _mytickets.push(_ticket.data());
        });
      })

      if (_mytickets.length == 0 && isMounted) {
        setShopLoading(false);
        controls.start('product');
        return;
      }

      // 所持チケットの商品情報を取得
      let _myTickets: myTicket[] = []
      for (const ticket of _mytickets) {
        await db.collection('products').doc(String(ticket.product_id)).get().then(doc => {
          let _product = doc.data()
          if (_product && _product.product_type !== '買取') {
            _product['id'] = doc.id
            _myTickets.push({ ...ticket, productInfo: _product })
          }
        })
      }

      //  作成順にソート
      _myTickets = _myTickets.sort((a: any, b: any) => {
        if (a.productInfo.created_at > b.productInfo.created_at) return -1;
        if (a.productInfo.created_at < b.productInfo.created_at) return 1;
        return -1
      });

      if (isMounted) {
        setMytickets(_myTickets);
        setShopLoading(false);
      }
      setTimeout(() => {
        controls.start('product');
      }, 300);
    }

    currentUser && f(currentUser)
    return (() => {
      isMounted = false
    })
  }, [currentUser]);


  useEffect(() => {
    const _orderRefresh = async () => {
      if (!currentUser) return
      await orderRefresh()
    }
    _orderRefresh()
  }, [currentUser])

  const orderRefresh = async () => {
    let _orders: any = []
    if (currentUser?.uid) {
      await db.collection('orders')
        .where('uid', '==', currentUser.uid)
        .orderBy('created_at', 'desc')
        .get().then(snapshot => {
          snapshot.forEach(doc => {
            let _order = doc.data()
            _order['id'] = doc.id
            _orders.push(_order)
          })
          setOrders(_orders)
        })
    }
  }

  const handleTabChange = (e: any, value: any) => {
    window.scrollTo(0, 0);
    setTabValue(value)
    setTimeout(() => {
      if (value === 0) controls.start('product');
    }, 100);
  }

  return (
    <React.Fragment>
      {shopLoading ?
        <div style={{ width: "100%", height: "60vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <CircularProgress style={{ color: "gray" }} />
        </div>
        :
        <>
          <MuiThemeProvider theme={theme}>
            <Tabs
              value={tabValue}
              className={classes.tabs}
              centered
              onChange={handleTabChange}
              textColor="secondary"
              TabIndicatorProps={{ style: { display: "none" } }}
            >
              <StyledTab label="抽選"></StyledTab>
              <StyledTab label="購入"></StyledTab>
            </Tabs>
          </MuiThemeProvider>
          <Container className={classes.container}>
            {tabValue === 0 &&
              <motion.div
                initial={{ opacity: 0 }}
                animate={controls}
                variants={isProductVariants}
                exit={mytickets.length == 0 ? { opacity: 1, transition: { duration: 0 } } : { opacity: 0, transition: { duration: 0.8 } }}
              >
                <EntryProductsView myTickets={mytickets} />
              </motion.div>
            }
            {tabValue === 1 &&
              <BuyNowHistoryView orders={orders} orderRefresh={orderRefresh} />
            }
          </Container>
        </>
      }
    </React.Fragment>
  );
}

export default withRouter(History);
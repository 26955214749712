import React from "react";
import {
  Container,
  makeStyles,
  Box,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import Copyright from "../components/Copyright";
import AuthorizationText from "../components/common/AuthorizationText";


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0 10px'
  },
  root: {
    margin: "0 auto",
    height: "100%",
    padding: '0',
    marginBottom: theme.spacing(8)
  },
  box: {
    width: '90%',
    margin: "auto",
    padding: "1e,m",
    marginBottom: theme.spacing(4)
  },
}));

const Authorize = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Container component="main" maxWidth="lg" className={classes.root}>
        <Box className={classes.paper}>
          <div className={classes.box}>
            <AuthorizationText />
          </div>
        </Box>
      </Container>
      <Copyright />
    </React.Fragment>
  );
};

export default withRouter(Authorize);

import { FormControl, FormHelperText, TextField as MaterialTextField, makeStyles, withStyles } from "@material-ui/core";
import { Control, Controller, FieldValues } from "react-hook-form";

const CustomTextField = withStyles({
    root: {
        '& label.Mui-focused': {
            color: '#ff0000',
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: '0',
            '&:hover fieldset': {
                borderColor: '#ff0000',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#ff0000',
            }
        },
    }
})(MaterialTextField);

type Props = {
    control: Control<FieldValues, any>,
    id: string,
    name: string,
    label: string,
    defaultValue: string | number,
    valudation: any,
    inputProps?: any,
    helperText?: string,
    onChange?: any,
    placeHolder?: string,
    type?: "text" | "password" | "number" | "email" | "search" | "tel" | "url"
}

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(0),
        width: '100%',
    },
    textField: {
        marginBottom: '4px'
    },
    helperText: {
        color: "#ff0000",
        marginLeft: '1rem',
        // height: '1rem',
    }
}))

export const TextField = (props: Props) => {
    const classes = useStyles()
    const { id, label, control, defaultValue, valudation, inputProps, helperText, onChange, placeHolder, type } = props

    return (
        <Controller
            name={id}
            control={control}
            defaultValue={defaultValue}
            rules={valudation}
            render={({ field, formState: { errors } }) => (
                <>
                    <FormControl
                        className={classes.formControl}
                    >
                        <CustomTextField
                            type={type ?? 'text'}
                            className={classes.textField}
                            {...field}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id={id}
                            label={label}
                            helperText={helperText}
                            name={id}
                            error={!!errors?.[`${id}`]?.message}
                            inputProps={inputProps}
                            onChange={onChange ?? field.onChange}
                            placeholder={placeHolder}
                        />
                        <FormHelperText
                            className={classes.helperText}
                            id="helper-text"
                        >
                            {errors?.[`${id}`]?.message}
                        </FormHelperText>
                    </FormControl>
                </>
            )}
        />
    )
}
import { Typography, makeStyles } from "@material-ui/core"

type Props = {
    title: string
}

const useStyles = makeStyles((theme) => ({
    title: {
        textAlign: 'center',
        fontWeight: 'bold',
        marginBottom: theme.spacing(4),
        marginTop: theme.spacing(6),
    },
}))

export const PageTitle = ({ title }: Props) => {
    const classes = useStyles()

    return (
        <Typography variant="subtitle1" className={classes.title}>{title}</Typography>
    )
}
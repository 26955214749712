import { Collapse, Container, ListItem, ListItemIcon, ListItemText, makeStyles, Typography } from "@material-ui/core"
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
    container: {
        margin: '150px 0 100px',
        padding: '0'
    },
    title: {
        textAlign: 'center',
        fontWeight: 'bold',
        marginBottom: theme.spacing(4),
        marginTop: theme.spacing(6),
    },
    item: {
        borderBottom: '1px solid lightgray',
        padding: '.5rem 0'
    },
    itemIcon: {
        width: '16px',
        minWidth: '20px',
        fontSize: '.75rem',
        color: 'black',
    },
    itemText: {
        padding: '0 .8rem',
        lineHeight: '2'
    },
}))

const QAListView = () => {
    const classes = useStyles()
    const [lists, setLists] = useState([
        {
            quastion: '退会方法について教えてください。',
            answer: 'マイページ下部の「退会はこちらから」より可能でございます。',
            isOpen: false,
        },
        {
            quastion: '当選・落札した商品のキャンセルできますか？',
            answer: '当選後の商品は不可となります。抽選期間中に商品ページの下部のキャンセルボタンより手続きをお願い致します。',
            isOpen: false,
        },
        {
            quastion: 'サイズを間違えてエントリーしてしまいました。変更できますか？',
            answer: '商品ページ下部のキャンセルボタンより一度抽選エントリーをキャンセル後、再度正しいサイズを選択の上エントリーをお願いいたします。',
            isOpen: false,
        },
        {
            quastion: 'クレジットカードを登録しなくても抽選できますか？',
            answer: '一部抽選商品は、エントリー時のクレジットカード枠の確保を行わず、当選者様のみお支払いをお願いしております。その場合は抽選エントリー時にクレジットカード情報をご入力いただく必要はございません。',
            isOpen: false,
        },
        {
            quastion: 'エントリー・購入は正しくされていますでしょうか？',
            answer: '「エントリー・抽選履歴」より履歴をご確認いただけます。「エントリー中」の表示がされている場合は正常にエントリーが完了しております。',
            isOpen: false,
        },
    ])

    const handleClick = (i: any) => {
        console.log('asdfas')
        const newLists = lists.map((list, index) => (
            {
                quastion: list.quastion,
                answer: list.answer,
                isOpen: i === index ? !list.isOpen : false // 開いているもの or Answerを押したら閉じる
            }
        ))
        setLists(newLists)
    }

    return (
        <Container className={classes.container}>

            <Typography variant="subtitle1" className={classes.title}>よくあるご質問</Typography>
            <div>
                {lists.map((item, index) => (
                    <div className={classes.item} key={item.answer}>
                        <ListItem button onClick={() => handleClick(index)}>
                            <ListItemIcon className={classes.itemIcon}>
                                Q.
                            </ListItemIcon>
                            <ListItemText
                                disableTypography
                                primary={
                                    <Typography variant="body2"
                                        style={{ color: '', lineHeight: '1.6', fontSize: '.85rem', letterSpacing: '.5px' }}>
                                        {item.quastion}
                                    </Typography>
                                }
                            />
                            {item.isOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={item.isOpen} timeout="auto" unmountOnExit>
                            <ListItem button onClick={() => handleClick(index)}>
                                <ListItemIcon className={classes.itemIcon}>
                                    A.
                                </ListItemIcon>
                                <ListItemText
                                    disableTypography
                                    primary={
                                        <Typography variant="body2"
                                            style={{ color: '', lineHeight: '1.6', fontSize: '.85rem', letterSpacing: '.5px' }}>
                                            {item.answer}
                                        </Typography>
                                    }
                                />
                            </ListItem>
                            {/* </List> */}
                        </Collapse>
                    </div>
                ))}
            </div>
        </Container >
    )
}

export default QAListView
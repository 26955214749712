
import React, { memo, PropsWithChildren } from "react";
import { makeStyles, Modal, Slide } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(() => ({
    terms_wrapper: {
        backgroundColor: "white",
        height: "100vh",
        padding: "30px 30px",
        width: "100%",
        margin: "30px auto",
        maxHeight: "100%",
        boxSizing: "border-box",
        overflow: "scroll",
    },
    terms_wrapper_inner: {
        maxWidth: "650px",
        margin: "0 auto",
    },
}));

type Props = {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const UpDownDialog = memo((props: PropsWithChildren<Props>) => {
    const { open, setOpen, children } = props
    const classes = useStyles();

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
        >
            <Slide direction="up" in={open} mountOnEnter unmountOnExit timeout={550}>
                <div className={classes.terms_wrapper}>
                    <div style={{ position: "fixed", top: "60px", left: "30px" }} onClick={() => setOpen(false)}><CloseIcon /></div>
                    <div className={classes.terms_wrapper_inner}>
                        {children}
                    </div>
                </div>
            </Slide>
        </Modal >
    )
});
export default UpDownDialog;

import { makeStyles, Button as MUIButton, withStyles } from "@material-ui/core"

const useStyles = makeStyles(() => ({
    button: {
        lineHeight: "30px",
        height: '40px',
        fontSize: '0.9rem',
        width: "100%",
        maxWidth: "1020px",
        margin: "24px auto",
        color: '#fff',
        backgroundColor: "#ff0000",
        fontWeight: 'bold',
        borderRadius: "0",
    },
    disabledButton: {
        lineHeight: "30px",
        height: '40px',
        fontSize: '0.9rem',
        width: "100%",
        maxWidth: "1020px",
        margin: "24px auto",
        color: '#fff',
        backgroundColor: "#ccc",
        fontWeight: 'bold',
        borderRadius: "0",
    }
}))

type Props = {
    text: string,
    onClick: any,
    disabled?: boolean,
    style?: any

}

const CustomButton = withStyles({
    root: {
        boxShadow: 'none',
        '&:hover': {
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
        },
        '&:focus': {
            boxShadow: 'none',
        }
    },
})(MUIButton);

export const Button = ({ text, onClick, disabled = false, style }: Props) => {
    const classes = useStyles()

    return (
        <CustomButton
            className={disabled ? classes.disabledButton : classes.button}
            style={style}
            onClick={onClick}
            disabled={disabled}
            type="submit"
        >
            {text}
        </CustomButton>
    )
}
import React, { createContext, useState, useEffect } from "react";
import { firebase, auth, CloudFunctions, db } from "../utils/Firebase";
import common from "../redux/common/commonSlice";
import { useDispatch } from "react-redux";
import * as Sentry from "@sentry/react"

export const AuthContext = createContext({});

const auth_error = {
  // "auth/claims-too-large": "クレーム ペイロードが、最大許容サイズ（1,000 バイト）を超えています。",
  "auth/email-already-exists": "メールアドレスは既に登録されています。",
  "auth/id-token-expired": "指定された ID トークンは期限切れです。",
  "auth/id-token-revoked": "ID トークンが取り消されました。",
  // "auth/insufficient-permission":	"初期化に使用された認証情報に、リクエストされた Authentication リソースにアクセスするための権限がありません。",
  "auth/internal-error": "リクエストの処理中に、認証サーバーで予期しないエラーが発生しました。",
  // "auth/invalid-argument": "無効な引数が Authentication メソッドに提供されました。",
  // "auth/invalid-claims": "カスタム クレーム属性が無効です。",
  // "auth/invalid-continue-uri": "続行 URL は有効な URL 文字列でなければなりません。",
  // "auth/invalid-creation-time":	"作成時刻は有効な UTC 日付文字列でなければなりません。",
  // "auth/invalid-credential": "認証に使用された認証情報は、目的のアクションの実行には使用できません。",
  // "auth/invalid-disabled-field": "disabled ユーザー プロパティに指定された値は無効です。ブール値を指定する必要があります。",
  // "auth/invalid-display-name":	"displayName ユーザー プロパティに指定された値は無効です。空でない文字列を指定する必要があります。",
  // "auth/invalid-dynamic-link-domain": "指定されたダイナミック リンクのドメインは、現在のプロジェクトに対して構成されていないか、承認されていません。",
  "auth/invalid-email": "メールアドレスに入力された値は無効です。文字列のメールアドレスを指定する必要があります。",
  // "auth/invalid-email-verified": "ユーザー プロパティに指定された値は無効です。ブール値を指定する必要があります。",
  // "auth/invalid-hash-algorithm": "ハッシュ アルゴリズムは、サポートされているアルゴリズムのリスト内の文字列の 1 つと一致している必要があります。",
  // "auth/invalid-hash-block-size": "ハッシュのブロックサイズには有効な数値を指定してください。",
  // "auth/invalid-hash-derived-key-length": "ハッシュ派生キーの長さには有効な数値を指定してください。",
  // "auth/invalid-hash-key": "ハッシュキーは有効なバイトバッファでなければなりません。",
  // "auth/invalid-hash-memory-cost": "ハッシュメモリのコストには有効な数値を指定してください。",
  // "auth/invalid-hash-parallelization": "ハッシュ並列化には有効な数値を指定してください。",
  // "auth/invalid-hash-rounds": "ハッシュ ラウンドには有効な数値を指定してください。",
  // "auth/invalid-hash-salt-separator": "ハッシング アルゴリズムの salt separator フィールドは、有効なバイトバッファでなければなりません。",
  // "auth/invalid-id-token": "指定された ID トークンは有効な  ID トークンではありません。",
  // "auth/invalid-last-sign-in-time": "最終ログイン時間は、有効な UTC 日付文字列でなければなりません。",
  // "auth/invalid-page-token": "指定されたネクスト ページトークンが無効です。空ではない文字列を指定する必要があります。",
  "auth/invalid-password": "パスワードに入力された値は無効です。6 文字以上の文字列を指定する必要があります。",
  // "auth/invalid-password-hash": "パスワード ハッシュは有効なバイトバッファでなければなりません。",
  // "auth/invalid-password-salt": "パスワード ソルトは有効なバイトバッファでなければなりません。",
  // "auth/invalid-phone-number": "指定された値は無効です。空でない E.164 標準準拠の ID 文字列を指定する必要があります。",
  // "auth/invalid-photo-url": "ユーザー プロパティに指定された値は無効です。文字列 URL を指定する必要があります。",
  // "auth/invalid-provider-data": "有効な UserInfo オブジェクトの配列でなければなりません。",
  // "auth/invalid-provider-id": " 有効なサポートされているプロバイダ ID 文字列でなければなりません。",
  // "auth/invalid-session-cookie-duration": "セッション Cookie の期間には、5 分から 2 週間の間の有効な数値をミリ秒単位で指定してください。",
  // "auth/invalid-uid": "uid は、128 文字以下の空でない文字列を指定する必要があります。",
  // "auth/invalid-user-import": "インポート対象のユーザー レコードが無効です。",
  // "auth/maximum-user-count-exceeded": "インポートするユーザーの最大許容数を超えています。",
  // "auth/missing-android-pkg-name": "Android アプリをインストールする必要がある場合は、Android パッケージ名を入力する必要があります。",
  // "auth/missing-continue-uri": "リクエストで有効な続行 URL を提供する必要があります。",
  // "auth/missing-hash-algorithm": "パスワード ハッシュを使用してユーザーをインポートするには、ハッシング アルゴリズムとそのパラメータを指定する必要があります。",
  // "auth/missing-ios-bundle-id": "リクエストに iOS Bundle ID が指定されていません。",
  // "auth/missing-uid": "現在のオペレーションには uid 識別子が必要です。",
  // "auth/operation-not-allowed": "提供されたログイン プロバイダは  プロジェクトで無効になっています。  コンソールの [ログイン方法] セクションから有効にしてください。",
  // "auth/phone-number-already-exists": "提供された phoneNumberはすでに既存のユーザーによって使用されています。各ユーザーに固有の phoneNumber が必要です。",
  // "auth/project-not-found": "Admin SDK を初期化するために使用された認証情報に対して プロジェクトが見つかりませんでした。プロジェクトの認証情報を生成し、Admin SDK の認証に使用する方法については、 Firebase プロジェクトの設定をご覧ください。",
  // "auth/reserved-claims": "setCustomUserClaims() に指定された 1 つ以上のカスタム ユーザー クレームは予約済みです。たとえば、OIDC 固有のクレーム（sub、iat、iss、exp、aud、auth_time など）をカスタム クレームのキーとして使用することはできません。",
  "auth/session-cookie-expired": "提供された セッションの Cookie は期限切れです。",
  "auth/session-cookie-revoked": "セッション Cookie が取り消されました。",
  "auth/uid-already-exists": "提供された uid はすでに既存のユーザーによって使用されています。",
  "auth/unauthorized-continue-uri": "続行 URL のドメインがホワイトリストに登録されていません。",
  "auth/user-not-found": "入力されたユーザーの登録がありません。",
  "auth/wrong-password": "認証に失敗しました。メールアドレス、パスワードの確認をしてください。",
  "auth/email-already-in-use": "メールアドレスは既に登録されています。",
  "auth/too-many-requests": "",
}

export const AuthProvider = ({
  children
}: any) => {
  const dispatch = useDispatch();
  const [currentUser, setCurrentUser] = useState(null);
  const [teaserEmail, setTeaserEmail] = useState(null)
  const [isLoading, setIsLoading] = useState(true);
  let timerId: any = null;

  const signup = async () => { };

  const teaserPassword = async (email: any, password: any, history: any) => {
    try {
      const func = CloudFunctions.httpsCallable("createTeaserAuth")
      const res = await func({ email, password })

      if (res.data.status === 'ok') {
        await auth.signInWithEmailAndPassword(email, password)
        history.push('/signup/sms');
      } else {
        dispatch(
          common.actions.fetchAlert({
            alerts: [{ message: "アカウントの作成に失敗しました。" }],
          })
        );
        history.push('/signup/email')
      }
    } catch (error: any) {
      if (error.code == "auth/wrong-password") {
        dispatch(
          common.actions.fetchAlert({
            alerts: [{ message: "こちらのメールアドレスはすでにご登録されております。ログインをお願いいたします。" }],
          })
        );
        history.push('/signin')
      } else {
        console.error(error);
        history.push('/signup/email')
      }
    }
  };

  const signin = async (email: any, password: any, history: any) => {
    try {
      await auth.signInWithEmailAndPassword(email, password);
      history.push("/");
    } catch (error: any) {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      if (auth_error[error.code]) {
        dispatch(
          common.actions.fetchAlert({
            // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            alerts: [{ message: auth_error[error.code] }],
          })
        );
      } else {
        console.error(error.code);
        dispatch(
          common.actions.fetchAlert({
            alerts: [{ message: auth_error["auth/internal-error"] }],
          })
        );

      }
    }
  };

  const signout = async () => {
    await auth.signOut();
    setTeaserEmail(null)
    setCurrentUser(null)
  };

  const resetPassword = async (email: any) => {
    try {
      auth.sendPasswordResetEmail(email, { url: `${location.origin}/signin` }).then(function () {
        dispatch(
          common.actions.fetchAlert({
            alerts: [{ message: "パスワード再設定メールを送信しました。" }],
          })
        );
      }).catch(function (error) {
        console.error(error);
        switch (error.code) {
          case "auth/user-not-found":
            dispatch(
              common.actions.fetchAlert({
                alerts: [{ message: "入力されたメールアドレスが見つかりません。" }],
              })
            );
            break;
          case "auth/invalid-email":
            dispatch(
              common.actions.fetchAlert({
                alerts: [{ message: "メールアドレスのフォーマットが正しくないです。" }],
              })
            );
            break;
          default:
            dispatch(
              common.actions.fetchAlert({
                alerts: [{ message: "パスワード再設定メールを送信に失敗しました。" }],
              })
            );
        }
      });
    } catch (error: any) {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      if (auth_error[error.code]) {
        dispatch(
          common.actions.fetchAlert({
            // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            alerts: [{ message: auth_error[error.code] }],
          })
        );
      } else {
        console.error(error);
        dispatch(
          common.actions.fetchAlert({
            alerts: [{ message: auth_error["auth/internal-error"] }],
          })
        );
      }
    }
  }

  const updateEmail = async (newEmail: any, password: any) => {
    // @ts-expect-error
    const oldEmail = currentUser.email;
    const credential = firebase.auth.EmailAuthProvider.credential(
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      currentUser.email,
      password
    );
    try {
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      await currentUser.reauthenticateWithCredential(credential);
    } catch (e) {
      dispatch(
        common.actions.fetchAlert({
          alerts: [{ message: "認証に失敗しました。パスワードを確認してください。" }],
        })
      );
      return false;
    }

    try {
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      await currentUser.updateEmail(newEmail); // authのemailを更新
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      await auth.currentUser.sendEmailVerification({ url: `${location.origin}/signin` }) // 認証リンクを送信
      await db.collection('teaser_emails').where('email', '==', oldEmail).get().then(async snapshot => { // ログイン後Teaser_emailのemailの値を同期するために入力
        let _teaserUser = null
        snapshot.forEach(async doc => {
          _teaserUser = doc.data()
          _teaserUser['id'] = doc.id
        })
        if (_teaserUser) {
          // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
          await db.collection('teaser_emails').doc(_teaserUser.id).update({
            updateing_email: newEmail
          })
        }
      })

    } catch (e) {
      console.log('e', e)
      let message = "";
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      switch (e.code) {
        case "auth/network-request-failed":
          message = "タイムアウトになりました。通信環境を確認してください。";
          break;
        case "auth/weak-password":
          message = "認証に失敗しました。パスワードを確認してください。";
          break;
        case "auth/invalid-email":
          message = "メールアドレスのフォーマットが正しくないです。";
          break;
        case "auth/requires-recent-login":
          message = "セッション情報が無効です。ログアウトして再度ログインをしてください。";
          break;
        case "auth/user-disabled":
          message = "入力されたメールアドレスは無効になっています。";
          break;
        case "auth/email-already-in-use":
          message = "入力されたメールアドレスは既に使われています。";
          break;
        default:
          message = "失敗しました。通信環境を確認してください。";
      }
      dispatch(
        common.actions.fetchAlert({
          alerts: [{ message }],
        })
      );
      return false;
    }
    dispatch(
      common.actions.fetchAlert({
        alerts: [{ message: "登録申請確認メールをご確認ください。" }],
      })
    );
    return true;
  }

  useEffect(() => {
    const unsubscrive = auth.onAuthStateChanged(async (user) => {
      setIsLoading(false);
      if (!user) return;
      let url = user.phoneNumber == null ? `${location.origin}` : `${location.origin}/signup/sms`;

      // Emailが認証されているかどうか
      if (!user.emailVerified) {
        clearTimeout(timerId);
        timerId = setTimeout(() => {
          // @ts-expect-error TS(2531): Object is possibly 'null'.
          auth.currentUser.sendEmailVerification({ url: url })
            .then(function () {
              dispatch(
                common.actions.fetchAlert({
                  alerts: [{ message: "ログインを継続するには登録申請確認メールをご確認ください。" }],
                })
              );
            })
            .catch(function (error) {
              // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
              if (auth_error[error.code]) {
                dispatch(
                  common.actions.fetchAlert({
                    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                    alerts: [{ message: auth_error[error.code] }],
                  })
                );
              } else {
                console.error(error);
              }
            });
        }, 1000);
        return;
      }

      // // Firestoreのuser情報を取得 配送情報などを持っている。
      if (user.email) {
        // teaser_emailを取得
        let _teaserUser = null
        await db.collection('teaser_emails').where('email', '==', user.email).get().then(snapshot => {
          snapshot.forEach(doc => {
            _teaserUser = doc.data()
            _teaserUser['id'] = doc.id
            // @ts-expect-error TS(2345): Argument of type 'DocumentData' is not assignable ... Remove this comment to see the full error message
            setTeaserEmail(_teaserUser)
          })
        })

        // メールアドレスを更新して初めてログインした場合
        if (!_teaserUser) {
          // signup時にすでにユーザーがあ流状態の確認をauthを信頼する
          let updatingUser = null
          await db.collection('teaser_emails').where('updateing_email', '==', user.email).get().then(snapshot => {
            snapshot.forEach(doc => {
              updatingUser = doc.data()
              updatingUser['id'] = doc.id
            })
          })
          // @ts-expect-error TS(2531): Object is possibly 'null'.
          await db.collection('teaser_emails').doc(updatingUser.id).update({
            updateing_email: null,
            // @ts-expect-error TS(2531): Object is possibly 'null'.
            email: updatingUser.updateing_email
          })

        }
      }

      // Sentryのトラッキングを設定
      // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
      Sentry.setUser({ id: user.uid, email: user.email })
      // @ts-expect-error TS(2345): Argument of type 'User' is not assignable to param... Remove this comment to see the full error message
      setCurrentUser(user);
      unsubscrive()

    });
  }, []);


  return (
    <AuthContext.Provider
      value={{
        currentUser,
        teaserEmail,
        setTeaserEmail,
        signup,
        signin,
        signout,
        resetPassword,
        isLoading,
        updateEmail,
        teaserPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
export const formatedYen = (_amount: any) => {
  return String(_amount).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
}

export const formatPhoneNumber = (_firestore_phone_number: any) => {
  let phoneNumber = _firestore_phone_number
  phoneNumber = phoneNumber.replace('+81', '0')
  return phoneNumber.slice(0, 3) + '-' + phoneNumber.slice(3, 7) + '-' + phoneNumber.slice(7, 11)
}

export const formatFullYearDate = (_date: any) => {
  let date = new Date(_date)
  return date.getFullYear() + '年' + (date.getMonth() + 1) + '月' + date.getDate() + '日'
}

export const generateProductVariantId = () => {  // 4から始まる14ケタの数字生成
  return Math.floor((4 + Math.random()) * 10000000000000)
}

export const tabStatusValue = {
  1: "オークション",
  2: "抽選",
  3: "NFT"
}

export const limitedStr = (_str: any, limitSize: any) => {
  return _str.slice(0, limitSize) + "..."
}

// エントリー数が見れる出品者
export const hasEntryViewPermissionVendors = [
  'Dropp',
  'PALM NUTS CO.',
  '株式会社SSC',
  'THE CONCORDE WINE CLUB'
]

// 出品者一覧
export const vendors = [
  'Dropp',
  "PALM NUTS CO.",
  "BLUE NOISE LTD.",
  "THE CONCORDE WINE CLUB",
  "Styles",
  "株式会社inspiration",
  "Wine Luxury",
  "Droppパートナー",
  "Mid-Century MODERN",
  "XLARGE",
  "楯の川酒造株式会社",
  "SELECTsk",
  "株式会社SSC",
  '合資会社べアードブルーイング',
  'watch shop bambi',
  'A&K'
]

export const calculate_dropp_fees = (vendor: any) => {
  let fee_rate = 0.15
  switch (vendor) {
    case 'THE CONCORDE WINE CLUB':
      fee_rate = 0.08
      break
    case 'BLUE NOISE LTD.':
      fee_rate = 0.15
      break
    case 'PALM NUTS CO.':
      fee_rate = 0.15
      break
    case 'Dropp':
      fee_rate = 0
      break
    case 'Styles':
      fee_rate = 0
      break
    case 'Mid-Century MODERN':
      fee_rate = 0.15
      break
    case 'XLARGE':
      fee_rate = 0.15
      break
    default:
      fee_rate = 0.15
      break
  }
  return fee_rate
}
import { makeStyles, Button } from "@material-ui/core"

const useStyles = makeStyles(() => ({
    button: {
        lineHeight: "30px",
        fontSize: '0.9rem',
        width: "100%",
        maxWidth: "1020px",
        margin: "0 auto",
        color: '#f00',
        border: '1px solid #f00',
        fontWeight: 'bold',
        borderRadius: "0",
        marginRight: '8px'
    },
    disabledButton: {
        lineHeight: "30px",
        fontSize: '0.9rem',
        width: "100%",
        maxWidth: "1020px",
        margin: "0 auto",
        color: '#fff',
        backgroundColor: "#ccc",
        fontWeight: 'bold',
        borderRadius: "0",
    }
}))
const OutlinedButton = ({
    text,
    onClick,
    disabled = false,
    style
}: any) => {
    const classes = useStyles()

    return (
        <Button className={disabled ? classes.disabledButton : classes.button} style={style} onClick={onClick} disabled={disabled}>
            {text}
        </Button>
    )
}
export default OutlinedButton
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
    imageWrapper: {
        position: 'relative',
        width: '100%',
        overflow: 'hidden',
        '&::before': {
            content: '""',
            paddingTop: '100%',
            display: 'block',
        },
    },
    image: {
        position: 'absolute',
        top: '0',
        left: '0',
        height: '100%',
        width: '100%',
        objectFit: 'cover',
        objectPosition: 'center',
    },

}))

const SquareImageView = ({
    imageSrc,
    alt
}: any) => {
    const classes = useStyles()
    return (
        <div className={classes.imageWrapper}>
            <img className={classes.image} src={imageSrc} alt={alt} />
        </div>
    );
}

export default SquareImageView;
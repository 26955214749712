import React from "react";
import { withRouter } from "react-router-dom";
import { Typography, makeStyles, Container } from "@material-ui/core";
import bgArt from "../images/about_bg_art.jpg"
import bgFearniture from "../images/about_bg_fearniture.jpg"
import bgSneaker from "../images/about_bg_sneaker.jpg"
import bgWine from "../images/about_bg_wine.jpg"
import hourImage from "../images/hour_24.jpg"
import timerImage from "../images/about_timer.png"
import categorySneaker from "../images/about_category_sneaker.jpg"
import categoryWine from "../images/about_category_item_wine.jpg"
import categoryCar from "../images/about_category_car.jpg"
import categoryArt from "../images/about_category_item_art.jpg"
import iPhoneTousenImage from "../images/about_iphone_tousen.png"
import Copyright from "../components/Copyright";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "0",
    paddingTop: theme.spacing(8),
  },
  topContainer: {
    minHeight: "350px",
  },
  topContent: {
    maxWidth: "840px",
    minHeight: "350px",
    boxSizing: "border-box",
    margin: "0 auto",
    padding: "50px 0",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center"
  },
  topLeftContainer: {
    textAlign: "center",
  },
  topTitle: {
    fontSize: "1.8rem",
    letterSpacing: "1px",
    fontWeight: "bold",
    [theme.breakpoints.down('sm')]: {
      fontSize: "1.5rem",
    }
  },
  topDescription: {
    marginTop: "8px",
    [theme.breakpoints.down('sm')]: {
      fontSize: "0.95rem",
    }
  },
  topIphomeImage: {
    width: "100%",
  },
  contentWrapper: {
    position: "relative",
  },
  contentContainer: {
    position: "absolute",
    padding: "0",
    top: "45%",
    left: "0",
  },
  bgContainer: {
    width: "100%",
    margin: "0 auto",
    paddingTop: "50px",
    paddingBottom: "50px",
    opacity: "0.5",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
  },
  bgItem: {
    textAlign: "center",
    width: "50%",
    [theme.breakpoints.up('sm')]: {
      paddingTop: "50px",
      paddingBottom: "50px",
    }
  },
  bgImage: {
    width: "150px",
  },
  contentDescription: {
    fontWeight: "bold",
    textAlign: "center",
    // transform: "translate(-50%, -40%)",
  },
  contentTitle: {
    fontSize: "1.4rem",
  },
  categoryWapper: {
  },
  wrapperTitle: {
    fontSize: "2rem",
    fontFamily: "Copperplate,Copperplate Gothic Light,fantasy",
    textAlign: "center",
    fontWeight: "bold",
    margin: "0 auto",
    width: "80%",
    padding: "30px 0",
    borderTop: "dotted 1px",
    borderBottom: "dotted 1px",
    [theme.breakpoints.down('sm')]: {
      fontSize: "1.6rem",
      width: "100%",
      boxSizing: "border-box",
      padding: "20px 0",
    }
  },
  categoryContent: {
    textAlign: "center",
    padding: "0",
  },
  categories: {
    padding: "30px 0",
  },
  category: {
    padding: "10px",
    color: "grey",
    [theme.breakpoints.down('sm')]: {
      padding: "6px",
    }
  },
  categoryImageWrapper: {
    paddingBottom: "80px",
  },
  categoryImages: {
    width: "20%",
    padding: "0 2%",
  },

  worksWrapper: {
    paddingBottom: "80px",
  },
  worksContents: {
    textAlign: "center",
  },
  hourImage: {
    marginTop: "80px",
    width: "90%",
    [theme.breakpoints.up('sm')]: {
      width: "40%",
    }
  },
  timerImage: {
    width: "100%",
    [theme.breakpoints.up('sm')]: {
      width: "50%",
    }
  },
  tousenImage: {
    width: "60%",
    [theme.breakpoints.up('sm')]: {
      width: "25%",
    }
  },
  worksDescription: {
    marginTop: "20px",
    marginBottom: "120px",
    fontWeight: "bold",
  },
  copyright: {
    fontSize: "11px",
    fontWeight: "bold",
  }
}));

const About = () => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Container component="main" className={classes.root}>
        <div className={classes.topContainer}>
          <div className={classes.topContent}>
            <Typography variant="subtitle1" className={classes.topTitle}>価値あるモノを全てのひとへ</Typography>
            <Typography className={classes.topDescription}>
              次世代型デジタルオークションハウスDropp
            </Typography>
          </div>
        </div>

        <Container maxWidth="md" className={classes.contentWrapper}>
          <div className={classes.bgContainer}>
            <div className={classes.bgItem}>
              <img className={classes.bgImage} src={bgWine}></img>
            </div>
            <div className={classes.bgItem}>
              <img className={classes.bgImage} src={bgSneaker}></img>
            </div>
            <div className={classes.bgItem}>
              <img className={classes.bgImage} src={bgFearniture}></img>
            </div>
            <div className={classes.bgItem}>
              <img className={classes.bgImage} src={bgArt}></img>
            </div>
          </div>
          <Container className={classes.contentContainer}>
            <Typography className={classes.contentDescription}>
              各カテゴリーのレアモノが<br></br>
              <span className={classes.contentTitle}>毎週抽選&オークション</span>
            </Typography>
          </Container>
        </Container>

        <Container className={classes.categoryWapper}>
          <Typography className={classes.wrapperTitle}>CATEGORY</Typography>
          <Container className={classes.categoryContent}>
            <Typography className={classes.categories}>
              <span className={classes.category}>スニーカー</span>
              <span className={classes.category}>ワイン</span>
              <span className={classes.category}>カー</span>
              <span className={classes.category}>アート</span>
            </Typography>
            <div className={classes.categoryImageWrapper}>
              <img src={categorySneaker} className={classes.categoryImages}></img>
              <img src={categoryWine} className={classes.categoryImages}></img>
              <img src={categoryCar} className={classes.categoryImages}></img>
              <img src={categoryArt} className={classes.categoryImages}></img>
            </div>
          </Container>
        </Container>

        <Container className={classes.worksWrapper}>
          <Typography className={classes.wrapperTitle}>HOW IT WORKS</Typography>
          <Container className={classes.worksContents}>
            <img className={classes.hourImage} src={hourImage}></img>
            <Typography className={classes.worksDescription}>
              厳選されたレア商品をDropp,<br></br>
              24時間前から告知いたします。
            </Typography>
          </Container>
          <Container className={classes.worksContents}>
            <img width="10" className={classes.timerImage} src={timerImage}></img>
            <Typography className={classes.worksDescription}>
              所定時間中にDropp商品へ<br></br>
              エントリー・入札することが可能です。
            </Typography>
          </Container>
          <Container className={classes.worksContents}>
            <img className={classes.tousenImage} src={iPhoneTousenImage}></img>
            <Typography className={classes.worksDescription}>
              所定の時間終了後に、
              当選者・落札者の発表をサイト内のページにて行います。<br />
              当選者・落札者の方には、エントリー時・入札時にご入力いただいた住所に商品を発送いたします。


            </Typography>
            <Copyright />
          </Container>
        </Container>

      </Container>
    </React.Fragment>
  );
};

export default withRouter(About);

import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";

const Timer = ({
  endDateTime,
  style,
  styleColor
}: any) => {
  const useStyles = makeStyles((theme) => ({
    timer: {
      minHeight: "40px",
      ...style,
    },
    table: {
    },
    limit_time: {
      textAlign: "center",
      lineHeight: "16px",
      fontSize: "1.8rem",
      letterSpacing: "3px",
      fontFamily: "digital7-mono",
      ...styleColor,
    },
    limit_time_item_first: {
      maxWidth: "32px",
      minWidth: "32px",
      color: "#ff0000",
      ...styleColor,
      [theme.breakpoints.up("sm")]: {
        maxWidth: "40px",
        minWidth: "40px",
      },
    },
    limit_time_item: {
      position: "relative",
      left: "6px",
      color: "#ff0000",
      boxSizing: "border-box",
      ...styleColor,
      maxWidth: "52px",
      minWidth: "52px",
      [theme.breakpoints.up("sm")]: {
        maxWidth: "60px",
        minWidth: "60px",
      },
    },
    time: {
      letterSpacing: "2px",
      fontSize: "2.2rem",
      ...styleColor
    },
    limit_dot: {
      textAlign: "center",
      maxWidth: "2px",
      color: "#ff0000",
      ...styleColor,
    },
    time_title: {
      lineHeight: "24px",
      color: "#ff0000",
      fontSize: "0.6rem",
      textAlign: "center",
      fontFamily: "Copperplate,Copperplate Gothic Light,fantasy",
      [theme.breakpoints.up("sm")]: {
        fontSize: "0.8rem",
      },
    },
  }));
  const classes = useStyles();
  const limit = new Date(endDateTime);
  const now = new Date();
  const diff = limit.getTime() - now.getTime();
  const [msLeft, setMsLeft] = useState(diff);
  const [dispTimeLeftDays, setDispTimeLeftDays] = useState('');
  const [dispTimeLeftHours, setDispTimeLeftHours] = useState('');
  const [dispTimeLeftMinutes, setDispTimeLeftMinutes] = useState('');
  const [dispTimeLeftSeconds, setDispTimeLeftSeconds] = useState('');
  const [isFinished, setIsFinished] = useState(false);

  useEffect(() => {
    if (!msLeft) return;

    const interval = setInterval(() => {
      setMsLeft(msLeft - 1000);

      if (msLeft > 0) {
        setIsFinished(false);
        const secondLeft = Math.round(msLeft / 1000);
        const d = Math.floor(secondLeft / 60 / 60 / 24);
        const h = Math.floor((secondLeft - d * 60 * 60 * 24) / 60 / 60);
        const m = Math.floor(
          (secondLeft - d * 60 * 60 * 24 - h * 60 * 60) / 60
        );
        const s = Math.floor(
          secondLeft - d * 60 * 60 * 24 - h * 60 * 60 - m * 60
        );

        const dd = d.toString().length === 1 ? "0" + d : String(d);
        const hh = h.toString().length === 1 ? "0" + h : String(h);
        const mm = m.toString().length === 1 ? "0" + m : String(m);
        const ss = s.toString().length === 1 ? "0" + s : String(s);
        setDispTimeLeftDays(dd);
        setDispTimeLeftHours(hh);
        setDispTimeLeftMinutes(mm);
        setDispTimeLeftSeconds(ss);
      } else {
        setIsFinished(true);
        setDispTimeLeftDays("00");
        setDispTimeLeftHours("00");
        setDispTimeLeftMinutes("00");
        setDispTimeLeftSeconds("00");
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [msLeft]);

  return (
    <>
      {/* <Typography variant={variant} className={classes.timer}>{dispTimeLeft}</Typography> */}
      <table
        style={isFinished ? { opacity: "0.7" } : {}}
        className={classes.table}
      >
        <thead>
          <tr className={classes.limit_time}>
            <td className={classes.limit_time_item_first + ' ' + classes.time}>{dispTimeLeftDays}</td>
            <td className={classes.limit_dot}>:</td>
            <td className={classes.limit_time_item + ' ' + classes.time}>{dispTimeLeftHours}</td>
            <td className={classes.limit_dot}>:</td>
            <td className={classes.limit_time_item + ' ' + classes.time}>{dispTimeLeftMinutes}</td>
            <td className={classes.limit_dot}>:</td>
            <td className={classes.limit_time_item + ' ' + classes.time}>{dispTimeLeftSeconds}</td>
          </tr>
        </thead>
        <tbody>
          <tr className={classes.time_title}>
            <td className={classes.limit_time_item_first}>DAYS</td>
            <td className={classes.limit_dot}></td>
            <td className={classes.limit_time_item}>HOURS</td>
            <td className={classes.limit_dot}></td>
            <td className={classes.limit_time_item}>MINUTES</td>
            <td className={classes.limit_dot}></td>
            <td className={classes.limit_time_item}>SECONDS</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default Timer;

import React, { useEffect, useState, useContext } from 'react';
import { Button, Container, List, ListItem, makeStyles, Typography, Link, withStyles } from '@material-ui/core';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import { useParams, withRouter } from 'react-router-dom';
import { motion, useAnimation } from 'framer-motion';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { db } from "../utils/Firebase";
import { AuthContext } from "../contexts/Auth";
import { pageEnterVariants } from '../Animations/variants';
import { TitleView } from '../components/TitleView';
import { blue } from '@material-ui/core/colors';
import ProductImagesView from '../components/common/ProductImagesView';
import { Product } from '../types/product';

const useStyles = makeStyles((theme) => ({
  images_wrapper: {
    maxWidth: "350px",
    width: "80%",
    margin: "0 auto",
  },
  main_container: {
    marginTop: theme.spacing(9),
    paddingBottom: theme.spacing(8),
  },
  card: {
    marginTop: theme.spacing(2),
    height: "100%",
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  item_info: {
    marginTop: theme.spacing(4),
    display: "flex",
    color: '#ff0000',
    justifyContent: 'center',
    minHeight: '30px',
  },
  list_root: {
    marginTop: theme.spacing(0),
  },
  product_info_list: {
    display: 'flex',
    flexFlow: "column",
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    textAlign: 'center',
  },
  product_info_list_title: {
    color: "gray",
    width: '50%',
  },
  product_info_list_text: {
    width: '50%',
  },
  vendor_url: {
    color: '#585858',
  },
  start: {
    fontWeight: 'bold'
  },
  description: {
    width: "100%",
    maxWidth: "1020px",
    position: 'relative',
    overflow: 'hidden',
    fontSize: '0.9rem',
    letterSpacing: '0.1rem',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(3),
  },
  share_icons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: 'center',
  },
  share_icon: {
    margin: "8px",
  },
  limited_text: {
    width: "100%",
    textAlign: "center",
    color: "gray",
    fontSize: "0.85rem",
  },
  button_wrap: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  signin_button: {
    height: "50px",
    width: "300px",
    margin: "0px auto",
    marginTop: "20px",
    backgroundColor: "#ff0000",
    color: "white",
    fontWeight: "bold",
  },
  signup_button: {
    height: "50px",
    width: "300px",
    margin: "0px auto",
    marginTop: "20px",
    border: "1px solid gray",
    color: "gray",
    fontWeight: "bold",
  },
  full_date_view: {
    display: "flex",
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: "center",
    color: "#ff0000",
  },
  full_date_title: {
    fontSize: "0.75rem",
    lineHeight: "1.5",
    marginBottom: "10px",
    letterSpacing: "1px",
  },
  full_date: {
    color: "#ff0000",
    textAlign: "center",
    lineHeight: "16px",
    fontSize: "2rem",
    fontFamily: "digital7-mono",
    marginBottom: theme.spacing(2)
  },
  full_date_times: {
    margin: "2px",
    letterSpacing: "1.5px",
  }
}));

const CustomButton = withStyles({
  root: {
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
    },
    '&:focus': {
      boxShadow: 'none',
    }
  },
})(Button);



type Paramas = {
  product_id: string
}
const ComingSoonDetail = (props: any) => {
  const classes = useStyles();
  const { product_id } = useParams<Paramas>();
  const [shopLoading, setShopLoading] = useState(true);
  const [productInfo, setProductInfo] = useState<Product>();
  const { currentUser } = useContext<any>(AuthContext);
  const controls = useAnimation();

  // 基本データの取得
  useEffect(() => {
    let isMounted = true;

    const f = async () => {
      try {
        let query = db.collection("products").doc(product_id);
        const _product = await query.get();
        const product = _product.data();
        if (!product) return;
        const checkedDescription = checkDescription(product.description)
        // @ts-expect-error TS(2741): Property 'created_at' is missing in type '{ id: an... Remove this comment to see the full error message
        const _productInfo: Product = {
          id: product.id,
          title: product.title,
          subtitle: checkedDescription.subtitle,
          price: product.variants[0].price,
          images: product.images.map((image: any) => {
            return image.src;
          }),
          release_date: product.release_date,
          options: product.options,
          description: checkedDescription.product_description,
          updated_at: new Date(product.updated_at),
          product_type: product.product_type,
          vendor: product.vendor,
          vendor_url: checkedDescription.vendor_url,
          variants: product.variants,
          // firestoreではjson文字列に変換されているのでjsonに変換
          payment_variants: product.payment_variants,
          payment_product_id: product.payment_product_id,
          level: product.level,
          is_authorization: product.is_authorization,
          deadline_date: product.deadline_date,
          showcase_date: product.showcase_date,
          image: product.image,
          owner: product.owner,
          shipping_fee_name: product.shipping_fee_name,
          auction_fee: product.auction_fee,
          insurance_fee_name: product.insurance_fee_name,
          hasWinImage: product.hasWinImage,
          winImageUrl: product.winImageUrl,
          current_bid_amount: product.current_bid_amount,
          expected_winning_bid_amount: product.expected_winning_bid_amount,
          bid_history: product.bid_history,
          authorization_limit: product.authorization_limit
        }

        if (isMounted) {
          setProductInfo(_productInfo);
        }

        if (isMounted) {
          setShopLoading(false);
          controls.start("visible");
        }

      } catch (e) {
        if (isMounted) {
          setShopLoading(false);
        }
      }
    }
    if (product_id) { f(); }

    return () => {
      isMounted = false;
    }

  }, [product_id]);

  // メイン画像
  const ImageSlideView = (props: any) => {
    const images = props.images ? props.images : [];
    if (images.length >= 1) {
      return (
        <>
          <Slider />
          <div className={classes.images_wrapper}>
            <ProductImagesView images={images} ticketValue="" />
          </div>
        </>
      )
    } else {
      return (<></>)
    }
  };


  // 説明テキスト部分
  const DescriptionView = (props: any) => {
    return (
      <Container className={classes.description}>
        <span dangerouslySetInnerHTML={{ __html: props.htmlString }} />
      </Container>
    )
  }

  const LimitedDescriptionView = (props: any) => {
    let text = props.htmlString.slice(0, 250) + "..."
    return (
      <Container className={classes.description}>
        <span dangerouslySetInnerHTML={{ __html: text }}></span>
      </Container>
    )
  }

  const setMeta = () => {
    const headData = document.head.children;
    const title = productInfo ? `${productInfo.title} Coming Soon` : 'Coming Soon';
    const description = productInfo ? `${productInfo.title} Coming Soon` : '開始までお待ちください。';
    const keywords = productInfo ? `${productInfo.title} エントリー 抽選 Dropp` : 'エントリー 抽選 Dropp';

    for (let i = 0; i < headData.length; i++) {
      const nameVal = headData[i].getAttribute('name');
      if (nameVal !== null) {
        if (nameVal.indexOf('keywords') != -1) {
          headData[i].setAttribute('content', keywords);
        }
        if (nameVal.indexOf('description') != -1) {
          headData[i].setAttribute('content', description);
        }
        // OGP(twitter)の設定
        if (nameVal.indexOf('twitter:title') != -1) {
          headData[i].setAttribute('content', title);
        }
        if (nameVal.indexOf('twitter:description') != -1) {
          headData[i].setAttribute('content', description);
        }
      }
    }
  }

  const checkDescription = (description: any) => {
    let res = [description];
    let res2;
    let subtitle;
    let vendor_url;
    if (res[0].split('###vendor_url').length >= 2) {
      res2 = res[0].split('###vendor_url');
      vendor_url = res2[1].replace(/(<([^>]+)>)/gi, '').trim();
      res[0] = res2[0]
    }
    if (res[0].split('###subtitle').length >= 2) {
      res2 = res[0].split('###subtitle');
      subtitle = res2[1].replace(/(<([^>]+)>)/gi, '');
      res[0] = res2[0]
    }
    const desc = res[0];
    return {
      product_description: desc,
      subtitle: subtitle,
      vendor_url: vendor_url,
    }
  };

  const TwitterShare = () => {
    const twitterBaseUrl = "https://twitter.com/intent/tweet?url=https://dropp.jp&text=抽選も楽しめる次世代型オークションハウス&hashtags=Dropp";
    window.open(twitterBaseUrl, "_blank")
  }

  const InstagramShare = () => {
    const instagramBaseUrl = "https://www.instagram.com/dropp.jp/?hl=ja";
    window.open(instagramBaseUrl, "_blank")
  }

  const handleSignin = () => {
    props.history.push('/signin')
  }

  const handleSignup = () => {
    props.history.push('/signup/email')
  }

  setMeta();

  const FullDateView = (props: any) => {
    let toDoubleDigits = (num: any) => {
      num += "";
      if (num.length === 1) {
        num = "0" + num;
      }
      return num;
    };

    return (
      <div className={classes.full_date_view}>
        <Typography variant="subtitle2" className={classes.full_date_title}>Dropp開始日</Typography>
        <Typography variant="subtitle1" className={classes.full_date} style={{ marginBottom: "20px" }}>
          <span className={classes.full_date_times}>{toDoubleDigits(props.releaseDate.toDate().getMonth() + 1)}</span>.
          <span className={classes.full_date_times}>{toDoubleDigits(props.releaseDate.toDate().getDate())}</span>.
          <span className={classes.full_date_times}>{props.releaseDate.toDate().getFullYear()}</span>
        </Typography>
      </div>
    )
  }



  return (
    <React.Fragment key={product_id}>
      {!shopLoading &&
        <>
          <motion.div
            initial={{ opacity: 0, scale: 1.05 }}
            variants={pageEnterVariants}
            animate={controls}
            exit={{ opacity: 0, transition: { duration: 0.8 }, scale: 1.05 }}
          >
            <Container className={classes.main_container}>
              <div className={classes.card}>
                <div className={classes.item_info}>
                  <FullDateView releaseDate={productInfo?.release_date} />
                </div>
                <ImageSlideView images={productInfo?.images} />
                <TitleView title={productInfo?.title} subtitle={productInfo?.subtitle}></TitleView>
              </div>
              <List className={classes.list_root}>
                <ListItem className={classes.product_info_list}>
                  <Typography variant='subtitle2' className={classes.product_info_list_title}>価格 (税込み)</Typography>
                  <Typography variant='subtitle1' className={classes.product_info_list_text}>¥{String(productInfo?.price).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}</Typography>
                </ListItem>
                <ListItem className={classes.product_info_list}>
                  <Typography variant='subtitle2' className={classes.product_info_list_title}>購入方法</Typography>
                  <Typography variant='subtitle1' className={classes.product_info_list_text}>{productInfo?.product_type}</Typography>
                </ListItem>
                <ListItem className={classes.product_info_list}>
                  <Typography variant='subtitle2' className={classes.product_info_list_title}>開始日時</Typography>
                  <Typography variant='subtitle1' className={classes.product_info_list_text}>{new Date(productInfo?.release_date.seconds * 1000).toLocaleString("ja")}</Typography>
                </ListItem>
                {productInfo?.vendor &&
                  <ListItem className={classes.product_info_list}>
                    <Typography variant='subtitle2' className={classes.product_info_list_title}>販売元</Typography>
                    {productInfo?.vendor_url ?
                      <Typography variant='subtitle1' className={classes.product_info_list_text}>
                        <Link underline="always" className={classes.vendor_url} href={productInfo?.vendor_url} target="_blank" rel="noopener">
                          {productInfo?.vendor}
                        </Link>
                      </Typography>
                      :
                      <Typography variant='subtitle1' className={classes.product_info_list_text}>
                        {productInfo.vendor}
                      </Typography>
                    }
                  </ListItem>
                }
                <ListItem className={classes.share_icons}>
                  <Link onClick={TwitterShare}><TwitterIcon style={{ color: blue[500] }} className={classes.share_icon} /></Link>
                  <Link onClick={InstagramShare}><InstagramIcon color="secondary" className={classes.share_icon} /></Link>
                </ListItem>
              </List>
              {(currentUser && currentUser.phoneNumber != null) ?
                <>
                  <DescriptionView htmlString={productInfo?.description} />
                </>
                :
                <>
                  <LimitedDescriptionView htmlString={productInfo?.description} />
                  <div className={classes.button_wrap}>
                    {!currentUser &&
                      <>
                        <Typography variant="subtitle1" className={classes.limited_text}>続きを読むにはアカウント登録が必要です。</Typography>
                        <CustomButton onClick={handleSignin} variant="contained" className={classes.signin_button}>ログイン</CustomButton>
                        <CustomButton onClick={handleSignup} variant="outlined" className={classes.signup_button}>アカウント作成</CustomButton>
                      </>
                    }
                    {(currentUser && currentUser.phoneNumber == null) &&
                      <>
                        <Typography variant="subtitle1" className={classes.limited_text}>続きを読むには電話番号登録が必要です。</Typography>
                        <CustomButton onClick={() => props.history.push('/signup/sms')} variant="contained" className={classes.signin_button}>電話番号認証</CustomButton>
                      </>
                    }
                  </div>
                </>
              }
            </Container>
          </motion.div>
        </>
      }
    </React.Fragment >
  );
};

export default withRouter(ComingSoonDetail);
import React from "react";
import {
  Container,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { useHistory } from 'react-router-dom';
import { formatedYen } from "../../utils/Common";
import Timer from "../Timer";
import HoursTimer from "../common/HoursTimer";

const useStyles = makeStyles((theme: Theme) => createStyles({
  cardGrid: {
    padding: "10px",
    width: "100%",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(4),
    }
  },
  grid_item: {
    marginBottom: theme.spacing(10),
    padding: "6px 20px",
    display: "flex",
    flexDirection: "column",
    width: "90%",
    margin: "0 auto",
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(10),
      padding: "6px 32px",
    }
  },
  section_title: {
    fontWeight: "bold",
    fontSize: "1.3rem",
    padding: "8px 0px",
    margin: "0 auto",
    borderBottom: "1px solid black",
    letterSpacing: "3px",
    marginBottom: "30px",
    [theme.breakpoints.up('sm')]: {
      marginBottom: "50px",
    }
  },
  cardMedia: {
    width: "100%",
  },
  item_info: {
    display: "flex",
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: "center",
    color: "#ff0000",
    marginBottom: "10px",
  },
  coming_soon_text: {
    marginBottom: theme.spacing(2),
    textAlign: "center",
    lineHeight: "16px",
    fontSize: "1.8rem",
    letterSpacing: "3px",
    fontFamily: "digital7-mono",
  },
  timer_text: {
    fontSize: '0.65rem',
    letterSpacing: "1px",
  },
  product_title: {
    fontSize: ".85rem",
    textAlign: "left",
    color: "gray",
    display: "-webkit-box",
    overflow: 'hidden',
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
    [theme.breakpoints.up('sm')]: {
      fontSize: "0.9rem",
    }
  },
  product_price: {
    fontSize: "0.9rem",
    textAlign: 'left',
    fontWeight: "bold",
    [theme.breakpoints.down('sm')]: {
      fontSize: "0.85rem",
    }
  },
  type_text: {
    fontSize: "0.9rem",
    textAlign: 'left',
    color: "gray",
    [theme.breakpoints.down('sm')]: {
      fontSize: "0.85rem",
    }
  },
  inventory_text: {
    height: "38px",
    lineHeight: "38px",
    marginBottom: "4px",
    textAlign: "center",
    fontSize: "1.4rem",
    letterSpacing: "2px",
    color: "#ff0000",
    fontFamily: "digital7-mono",
  },
}));

const calcMinInventory = (_product: any) => {
  let arr = _product.variants.map((variant: any) => variant.inventory_quantity).filter((n: any) => n)
  return Math.min(...arr) === Infinity ? 0 : Math.min(...arr)
}

const FeaturedProductsView = (props: any) => {
  const classes = useStyles();
  const history = useHistory();
  const { products } = props
  const viewItems = products

  return (
    <Container className={classes.cardGrid} maxWidth="md">
      {viewItems.length > 2 && <Typography className={classes.section_title} variant="subtitle1">FEATURE</Typography>}
      <Grid container style={viewItems.length < 3 ? { maxWidth: '350px', margin: '0 auto' } : {}}>
        {viewItems.map((product: any) => {
          let now = new Date().getTime() / 1000
          // 告知前でも目玉商品として本番登録されているものは表示する（商品ページへの遷移はさせない）
          if (now < product.showcase_date.seconds) {
            return (
              <Grid
                className={classes.grid_item}
                item
                key={product.id}
                xs={viewItems.length >= 3 ? 6 : 12} sm={viewItems.length >= 3 ? 4 : 12} md={viewItems.length >= 3 ? 4 : 12}>
                <div className={classes.item_info}>
                  <Typography variant='subtitle2' className={classes.coming_soon_text}>
                    Coming&thinsp;Soon
                  </Typography>
                  <img src={product.image} className={classes.cardMedia} />
                </div>
                <Typography variant="subtitle2" className={classes.product_title}>{product.title}</Typography>
                <Typography variant='subtitle2' className={classes.type_text}
                >
                  {product.product_type === 'オークション' && <>オークション</>}
                  {product.product_type === '抽選' && <>抽選</>}
                  {product.product_type === '先着' && <>Buy&thinsp;Now</>}
                </Typography>
                <Typography className={classes.product_price}>
                  {product.product_type === 'オークション' && <>スタート価格 : ¥{formatedYen(product.variants[0].price)}</>}
                  {product.product_type === '抽選' && <>¥ {formatedYen(product.variants[0].price)}</>}
                  {product.product_type === '先着' && <>¥ {formatedYen(product.variants[0].price)}</>}
                </Typography>
              </Grid>
            )
            // 告知日 ~ 開始日は商品ページを公開カミスン
          } else if (now < product.release_date.seconds) {
            return (
              <Grid
                className={classes.grid_item}
                item
                key={product.id}
                xs={viewItems.length >= 3 ? 6 : 12}
                sm={viewItems.length >= 3 ? 4 : 12}
                md={viewItems.length >= 3 ? 4 : 12}
                onClick={() => {
                  if (product.product_type === '抽選') {
                    history.push(`/detail/${product.id}`)
                  } else if (product.product_type === 'オークション') {
                    history.push(`/auction/${product.id}`)
                  }
                }}>
                <div className={classes.item_info}>
                  <Typography variant='subtitle2' className={classes.timer_text}>
                    {product.product_type === 'オークション' && <>入札開始まで</>}
                    {product.product_type === '抽選' && <>エントリー開始まで</>}
                    {product.product_type === '先着' && <>販売開始まで</>}
                  </Typography>
                  {viewItems.length > 2 ?
                    <HoursTimer
                      variant="subtitle1"
                      product={product}
                      endDateTime={new Date().setTime(
                        product.release_date.seconds * 1000
                      )}
                    />
                    :
                    <Timer
                      variant="subtitle1"
                      endDateTime={new Date().setTime(
                        product.release_date.seconds * 1000
                      )}
                    />
                  }
                  <img src={product.image} className={classes.cardMedia} />
                </div>
                <Typography variant="subtitle2" className={classes.product_title}>{product.title}</Typography>
                <Typography className={classes.product_price}>
                  {product.product_type === 'オークション' && <>スタート価格 : ¥{formatedYen(product.variants[0].price)}</>}
                  {product.product_type === '抽選' && <>¥ {formatedYen(product.variants[0].price)}</>}
                  {product.product_type === '先着' && <>¥ {formatedYen(product.variants[0].price)}</>}
                </Typography>
                <Typography variant='subtitle2' className={classes.type_text}>
                  {product.product_type === 'オークション' && <>オークション</>}
                  {product.product_type === '抽選' && <>抽選</>}
                  {product.product_type === '先着' && <>Buy&thinsp;Now</>}
                </Typography>
              </Grid>
            )
            // 開始日 ~ 締切日はDropp中(ページ遷移させる)
          } else if (now < product.deadline_date.seconds) {
            return (
              <Grid
                className={classes.grid_item}
                item
                key={product.id}
                xs={viewItems.length >= 3 ? 6 : 12}
                sm={viewItems.length >= 3 ? 4 : 12}
                md={viewItems.length >= 3 ? 4 : 12}
                onClick={() => {
                  if (product.product_type === '抽選') {
                    history.push(`/detail/${product.id}`)
                  } else if (product.product_type === 'オークション') {
                    history.push(`/auction/${product.id}`)
                  } else if (product.product_type === 'nft') {
                    history.push(`/nft/${product.id}`)
                  } else if (product.product_type === '先着') {
                    history.push(`/onsale/${product.id}`)
                  }
                }}>
                <div className={classes.item_info}>
                  <Typography variant='subtitle2' className={classes.timer_text}>
                    {product.product_type === 'オークション' && <>入札締め切り</>}
                    {product.product_type === '抽選' && <>エントリー締め切り</>}
                  </Typography>
                  {product.product_type === '先着' ?
                    <>
                      <Typography variant="subtitle2" className={classes.inventory_text}> LAST {calcMinInventory(product)}</Typography>
                    </>
                    :
                    <>
                      {viewItems.length > 2 ?
                        <HoursTimer
                          variant="subtitle1"
                          product={product}
                          endDateTime={new Date().setTime(
                            product.deadline_date.seconds * 1000
                          )}
                        />
                        :
                        <Timer
                          variant="subtitle1"
                          endDateTime={new Date().setTime(
                            product.deadline_date.seconds * 1000
                          )}
                        />
                      }
                    </>
                  }
                  <img src={product.image} className={classes.cardMedia} />
                </div>
                <Typography variant="subtitle2" className={classes.product_title}>{product.title}</Typography>
                <Typography variant='subtitle2' className={classes.type_text}>
                  {product.product_type === 'オークション' && <>オークション</>}
                  {product.product_type === '抽選' && <>抽選</>}
                  {product.product_type === '先着' && <>Buy&thinsp;Now</>}
                </Typography>
                <Typography className={classes.product_price}>
                  {product.product_type === 'オークション' && <>現在価格 : ¥{String(product.current_bid_amount ? product.current_bid_amount : product.variants[0].price).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}</>}
                  {product.product_type === '抽選' && <>¥ {formatedYen(product.variants[0].price)}</>}
                  {product.product_type === '先着' && <>¥ {formatedYen(product.variants[0].price)}</>}
                </Typography>
              </Grid>
            );
            // 締切日~は表示しない
          } else {
            return (<div key={product.id}></div>)
          }
        })}
      </Grid>
    </Container>
  );
}

export default FeaturedProductsView
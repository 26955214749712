
import React, { memo, useState, useContext, useEffect } from "react";
import {
  Checkbox,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  makeStyles,
  MuiThemeProvider,
  Radio,
  RadioGroup,
  Typography,
  withStyles,
} from "@material-ui/core";
import { createMuiTheme } from '@material-ui/core';
import { CardElement } from '@stripe/react-stripe-js'
import { CloudFunctions } from "../../utils/Firebase";
import { AuthContext } from "../../contexts/Auth";

const cardElementOptions = {
  hidePostalCode: true,
  style: {
    base: {
    },
    invalid: {},
    complete: {},
  },
}

type CheckboxType = {
  name?: string,
  checked?: boolean,
  onChange?: any
}
const CustomCheckBox = withStyles({
  root: {
    color: "#ff0000",
    '&$checked': {
      color: "#ff0000",
    },
  },
  checked: {},
})((props: CheckboxType) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles(() => ({
  main_container: {
    marginTop: "10px",
    padding: "0",
  },
  card: {
    padding: "0",
    margin: "0 auto",
    position: "relative"
  },
  error_message: {
    color: "#ff0000",
    fontSize: "0.9rem",
    fontWeight: "bold",
    marginTop: "6px",
  },
  card_wrapper: {
    minHeight: "100px",
    marginBottom: "50px",
  },
  credit_card: {
    border: "1px solid black",
    width: "100%",
    position: "relative",
    left: "10px",
    marginTop: "12px",
    padding: "5px",
    boxSizing: "border-box",
  },
  selected_credit_card: {
    border: "1px solid rgba(0, 0, 0, 0.38)",
    width: "340px",
    position: "relative",
    left: "10px",
    marginTop: "12px",
  },
  label: {
    width: "100%",
    display: "flex",
    alignItems: "justify-content",
  },
  add_button: {
    borderRadius: "20px",
    margin: "16px 0",
    border: "1px solid black",
  },
  card_element: {
    marginTop: "10px",
    marginBottom: "16px",
    border: "1px solid rgba(0, 0, 0, 0.38)",
    borderRadius: "0",
    padding: "15px",
    boxSizing: "border-box",
    minWidth: "100%",
  },
  selected_card_element: {
    marginTop: "10px",
    marginBottom: "16px",
    border: "1px solid black",
    borderRadius: "0",
    padding: "15px",
  },
  update_button: {
    color: "#ff0000",
    borderRadius: "0",
    border: "1px solid #ff0000",
  },
}));

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#000',
      main: '#000',
      dark: '#000',
      contrastText: '#fff',
    },
  },
});

type Props = {
  paymentStates: any,
  setPaymentStates: any
}

const PaymentMethodInput = memo((props: Props) => {
  const { paymentStates, setPaymentStates } = props
  const { teaserEmail } = useContext<any>(AuthContext);
  const classes = useStyles();

  // 支払いカード一覧取得
  const [onPaymentMethodsLoading, setOnPaymentMethodsLoading] = useState(false)
  useEffect(() => {
    const getPaymentMethods = async () => {
      if (teaserEmail?.stripe_customer_id) {
        // if (teaserEmail?.test_stripe_customer_id) {
        setOnPaymentMethodsLoading(true)
        try {
          let stripeGetPaymentMethods = CloudFunctions.httpsCallable("stripeGetPaymentMethods");
          // let stripeGetPaymentMethods = CloudFunctions.httpsCallable("testStripeGetPaymentMethods");
          let customer_id = teaserEmail.stripe_customer_id
          // let customer_id = teaserEmail.test_stripe_customer_id
          let _payment_methods = await stripeGetPaymentMethods({ customer_id })
          let selectedPaymentMethodStr = _payment_methods.data.data.length > 0 ? "0" : "-1"
          setPaymentStates({
            ...paymentStates,
            paymentMethods: _payment_methods.data.data,
            selectedPaymentMethodStr,
            isPaymentMethodSave: selectedPaymentMethodStr === '-1',
          })
        } catch (e) {
          setPaymentStates({ ...paymentStates, selectedPaymentMethodStr: "-1", isPaymentMethodSave: true })
        }
        setOnPaymentMethodsLoading(false)
      }
    }
    getPaymentMethods()
  }, [])

  // 支払いカード削除
  // const [onDetachPaymentMethod, setOnDetachPaymentMethod] = useState(false)
  // const handleRemoveCard = async (_payment_method_id: any) => {
  //   console.log('支払いカード削除')
  //   setOnDetachPaymentMethod(true)
  //   setPaymentStates({ ...paymentStates, selectedPaymentMethodStr: "" })
  //   const stripeDetachPaymentMethod = CloudFunctions.httpsCallable("stripeDetachPaymentMethod");
  //   // const stripeDetachPaymentMethod = CloudFunctions.httpsCallable("testStripeDetachPaymentMethod");
  //   let res = await stripeDetachPaymentMethod({
  //     payment_method_id: _payment_method_id
  //   })
  //   let _new_payment_methods = []
  //   if (res.data.status === 'success') {
  //     _new_payment_methods = paymentStates.paymentMethods.filter((_payment_method: any) => {
  //       _payment_method.id !== _payment_method_id
  //     })
  //     console.log('new_paymentmethods', _new_payment_methods)
  //     setPaymentStates({ ...paymentStates, paymentMethods: _new_payment_methods })
  //     console.log('支払いカード削除完了')
  //   }
  //   setOnDetachPaymentMethod(false)
  // }

  // const handleTermsViewClose = () => {
  // }

  // 支払いカード選択
  const handleSelectCard = (e: any) => {
    setPaymentStates({
      ...paymentStates,
      selectedPaymentMethodStr: e.target.value,
      isPaymentMethodSave: e.target.value === '-1',
      errorMessage: e.target.value === '-1' ? paymentStates.errorMessage : "",
    })
  }

  // クレカ入力を監視
  // const [isNewCardInputCompleted, setIsNewCardInputCompleted] = useState(false)
  const handleCardChange = (e: any) => {
    if (e.complete) {
      setPaymentStates({ ...paymentStates, errorMessage: "" })
    }
    // setIsNewCardInputCompleted(e.complete)
  }

  const handleCheckCard = (e: any) => {
    setPaymentStates({
      ...paymentStates,
      [e.target.name]: e.target.checked,
    })
  }

  const PaymentMethodsView = () => {
    return <>
      <FormControl fullWidth>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          onChange={handleSelectCard}
          value={paymentStates.selectedPaymentMethodStr}
          name="radio-buttons-group"
        >

          <>
            {paymentStates.paymentMethods.map((_payment_method: any, index: any) => (
              <FormControlLabel
                key={index}
                className={classes.credit_card}
                value={index.toString()}
                // classes={{ checked: classes.selected_credit_card }}
                control={<Radio color="default" />}
                label={
                  <div className={classes.label}>
                    <Typography variant="subtitle1" style={{ marginRight: "12px", width: "110px" }}>****{_payment_method.card.last4}</Typography>
                    <Typography variant="subtitle1" style={{ marginRight: "8px", fontSize: "0.75rem", lineHeight: "24px", width: "50px" }}>有効期限</Typography>
                    <Typography variant="subtitle1" style={{ marginRight: "8px", width: "73px" }}>{_payment_method.card.exp_month} / {_payment_method.card.exp_year}</Typography>
                    {/* <Typography style={{ fontSize: "0.75rem", color: "#ff0000", lineHeight: "24px", float: "right" }} onClick={() => handleRemoveCard(_payment_method.id)}>削除</Typography> */}
                  </div>
                }
              />
            ))}
          </>
          <FormControlLabel
            className={classes.credit_card}
            value="-1"
            control={<Radio color="default" />}
            label={
              <div className={classes.label}>
                <Typography variant="subtitle1" style={{ marginRight: "12px" }}>新しいカードを使用する</Typography>
              </div>
            }
          />
        </RadioGroup>
      </FormControl>
    </>;
  }

  return (
    <Container className={classes.main_container}>
      <MuiThemeProvider theme={theme}>
        <div className={classes.card}>
          {/* 決済方法 */}
          <div className={classes.card_wrapper}>
            <Typography variant="subtitle1" className={classes.error_message}>{paymentStates.errorMessage}</Typography>
            {onPaymentMethodsLoading ? <div style={{ textAlign: "center" }}><CircularProgress size={36} style={{ color: "grey" }} /></div>
              :
              <>
                <PaymentMethodsView />
                {paymentStates.selectedPaymentMethodStr === '-1' &&
                  <>
                    <CardElement id="card_element" onChange={handleCardChange} className={paymentStates.selectedPaymentMethodStr === "-1" ? classes.selected_card_element : classes.card_element} options={cardElementOptions} />
                    <FormControlLabel
                      disabled={paymentStates.selectedPaymentMethodStr !== "-1"}
                      control={<CustomCheckBox name="isPaymentMethodSave" checked={paymentStates.selectedPaymentMethodStr === '-1' && paymentStates.isPaymentMethodSave} onChange={handleCheckCard} />}
                      label="このカードを次回以降も使用する"
                    />
                  </>
                }
              </>
            }
          </div>
        </div>
      </MuiThemeProvider>
    </Container>
  )

});
export default PaymentMethodInput;

import React from "react";
import { useHistory, withRouter } from "react-router-dom";
import { CssBaseline, Typography, makeStyles, Container } from "@material-ui/core";
// import { analytics } from "../utils/Firebase";

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing(14),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	thanks_text: {
		marginBottom: theme.spacing(5),
	},
	toTop: {
		color: '#ff0000',
		'&:hover': {
			cursor: 'pointer',
		}
	}
}));

const SignUpThanks = () => {
	const classes = useStyles();
	const history = useHistory()

	return (
		<React.Fragment>
			<CssBaseline />
			<Container component="main" className={classes.root}>
				<Typography className={classes.thanks_text} variant="subtitle1">
					アカウント登録ありがとうございます。<br />
					アカウントの作成が完了しました。Droppをお楽しみください。<br />
				</Typography>
				<Typography className={classes.toTop} variant='subtitle2' onClick={() => history.push('/')}>TOPへ</Typography>
			</Container>
		</React.Fragment>
	);
};

export default withRouter(SignUpThanks);

import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./rootReducer";

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          "shippingMethod/inputChange",
          "shippingMethod/inputZipcodeChange",
          "shippingMethod/selectProvinceChange",
          "shippingMethod/inputPhoneChange",
        ],
      },
    }),
});
export default store;

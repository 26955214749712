import { Backdrop, Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, makeStyles, Typography } from "@material-ui/core";
import { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { AuthContext } from "../../contexts/Auth";
import Common from "../../redux/common/commonSlice";
import { selectShippingMethod } from "../../redux/shippingMethod/shippingMethodSlice";
import { db } from "../../utils/Firebase";
import ShippingMethodInputSlide from "./ShippingMethodInputSlide";

const useStyles = makeStyles((theme) => ({
  main: {
    marginTop: "30px",
  },
  current_shipping_method_wrapper: {
    marginBottom: "30px",
  },
  shipping_method_view: {
    border: "1px solid grey",
    padding: "16px 8px",
    marginBottom: "70px",
  },
  shipping_method_wrap: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    color: "grey",
  },
  shipping_method_title: {
    width: "25%",
    verticalAlign: "middle",
    marginLeft: "5%",
  },
  shipping_method_text: {
    width: "70%",
  },
  shipping_method: {
    width: "100%",
    boxSizing: "border-box",
    marginBottom: "16px",
  },
  update_button_wrap: {
    marginTop: "50px",
    textAlign: "center",
  },
  new_shipping_method_wrapper: {
    color: "grey",
    border: "1px solid grey",
    textAlign: "center",
    height: "130px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "80px",
  },
  plus_icon: {
    fontSize: "2rem",
  },
  positive_button: {
    color: "#ff0000",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}))

const ChangeShippingMethods = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { teaserEmail, setTeaserEmail } = useContext<any>(AuthContext)
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false)
  const [removeShippingMethodIndex, setRemoveShippingMethodIndex] = useState<string | null>(null)
  const [onShippingMethodsLoading, setOnShippingMethodsLoading] = useState(false)
  const [shippingMethodInputOpen, setShippingMethodInputOpen] = useState(false)

  const handleUpdateSlideOpen = (_shippingMethod: any, index: any) => {
    setShippingMethodInputOpen(true)
    dispatch(selectShippingMethod({ shippingMethod: _shippingMethod, index }))
  }

  const handleRemoveDialogOpen = (_shippingMethod: any, index: any) => {
    setRemoveDialogOpen(true)
    setRemoveShippingMethodIndex(index)
  }
  const handleRemoveDialogClose = () => {
    setRemoveDialogOpen(false)
    setRemoveShippingMethodIndex(null)
  }

  // 配送情報を削除
  const handleRemove = async () => {
    setRemoveDialogOpen(false)
    setOnShippingMethodsLoading(true)
    let newShippingMethods = teaserEmail.shipping_methods.filter((_: any, index: any) => index != removeShippingMethodIndex)
    await db.collection('teaser_emails').doc(teaserEmail.id).update({
      shipping_methods: newShippingMethods,
    })

    let newTeaserEmail = teaserEmail
    newTeaserEmail.shipping_methods = newShippingMethods
    setTeaserEmail(newTeaserEmail)
    setRemoveShippingMethodIndex("")
    dispatch(Common.actions.fetchAlert({
      alerts: [{ message: "配送情報の削除が完了しました。" }],
    }))
    window.scrollTo({ top: 0, behavior: "smooth" })
    setOnShippingMethodsLoading(false)
  }

  const ShippingMethodView = ({
    shippingMethod,
    index
  }: any) => {
    return (
      <div className={classes.shipping_method_view}>
        <dl className={classes.shipping_method_wrap}>
          <dt className={classes.shipping_method_title}>お名前</dt>
          <dd className={classes.shipping_method_text}>{shippingMethod.second_name} {shippingMethod.first_name}</dd>
        </dl>
        <dl className={classes.shipping_method_wrap}>
          <dt className={classes.shipping_method_title}>フリガナ</dt>
          <dd className={classes.shipping_method_text}>{shippingMethod.second_name_kana} {shippingMethod.first_name_kana}</dd>
        </dl>
        <dl className={classes.shipping_method_wrap}>
          <dt className={classes.shipping_method_title}>電話番号</dt>
          <dd className={classes.shipping_method_text}>{shippingMethod.phone}</dd>
        </dl>
        <dl className={classes.shipping_method_wrap}>
          <dt className={classes.shipping_method_title}>住所</dt>
          <dd className={classes.shipping_method_text}>
            {shippingMethod.zipcode}<br></br>
            {shippingMethod.province}{shippingMethod.address1}<br></br>
            {shippingMethod.address2}
          </dd>
        </dl>
        <div className={classes.update_button_wrap}>
          <Button variant="text" onClick={() => handleRemoveDialogOpen(shippingMethod, index)}>削除</Button> |
          <Button variant="text" onClick={() => handleUpdateSlideOpen(shippingMethod, index)}>変更</Button>
        </div>
      </div>
    )
  }

  const RemoveDialogView = () => {
    return (
      <Dialog open={removeDialogOpen}>
        <DialogContent>
          <Typography variant="subtitle2">こちらの配送情報を削除します。<br />よろしいですか？</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={handleRemoveDialogClose}>キャンセル</Button>
          <Button variant="text" onClick={handleRemove} className={classes.positive_button}>削除</Button>
        </DialogActions>
      </Dialog>
    )
  }

  return (
    <Container className={classes.main}>
      {onShippingMethodsLoading ?
        <Backdrop className={classes.backdrop} open={onShippingMethodsLoading} >
          <CircularProgress color='inherit' />
        </Backdrop>
        :
        <>
          <div className={classes.new_shipping_method_wrapper} onClick={() => setShippingMethodInputOpen(true)}>
            <Typography variant="subtitle2"><span className={classes.plus_icon}>+</span><br></br>配送先住所を追加</Typography>
          </div>
          <div className={classes.current_shipping_method_wrapper}>
            {teaserEmail && teaserEmail.shipping_methods?.map((shipping_method: any, index: any) => <ShippingMethodView shippingMethod={shipping_method} index={index} key={index} />)}
          </div>
          <ShippingMethodInputSlide
            isOpen={shippingMethodInputOpen}
            setIsOpen={setShippingMethodInputOpen}
          />
          <RemoveDialogView />
        </>
      }
    </Container>
  );
}
export default ChangeShippingMethods;
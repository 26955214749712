import React from "react";
import {
    Container,
    createStyles,
    Grid,
    makeStyles,
    Theme,
    Typography,
} from "@material-ui/core";
import { useHistory } from 'react-router-dom';
import WaitingWinnerTicketImg from '../../images/waiting-win-ticket.png';
import OnCheckingTicketImg from '../../images/on-checking-ticket.png';
import AppraisalCompleteTicketImg from '../../images/appraisal-complete-ticket.png';
import OnPayingTicketImg from '../../images/on-paying-ticket.png';
import WaitingDepositTicketImg from '../../images/waiting-deposit-ticket.png';
import ProceesionedTicketImg from '../../images/processioned-ticket.png';
import OnShippingTicketImg from '../../images/on-shipping-tkcket.png';
import WaitingShippingTicketImg from '../../images/waiting-shipping-ticket.png';
import CancelledTicketImg from '../../images/cancelled-ticket.png';
import HoursTimer from "../common/HoursTimer";
import SquareImageView from "./SquareImageView";
import { formatedYen } from "../../utils/Common";

const useStyles = makeStyles((theme: Theme) => createStyles({
    cardGrid: {
        padding: "10px",
        width: "100%",
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(8),
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(4),
        }
    },
    gridItem: {
        marginBottom: theme.spacing(10),
        padding: "6px 20px",
        display: "flex",
        flexDirection: "column",
        width: "90%",
        margin: "0 auto",
        [theme.breakpoints.up('sm')]: {
            marginBottom: theme.spacing(10),
            padding: "6px 32px",
        }
    },
    sectionTitle: {
        fontWeight: "bold",
        fontSize: "1.2rem",
        padding: "8px 0px 0px",
        margin: "0 auto",
        borderBottom: "1px solid black",
        letterSpacing: "3px",
        marginBottom: "30px",
        [theme.breakpoints.up('sm')]: {
            marginBottom: "50px",
        }
    },
    ticketImg: {
        width: "40%",
        maxWidth: '140px',
        position: "absolute",
        left: "10px",
        top: "10px",
        [theme.breakpoints.down('sm')]: {
            top: '16px',
        }
    },
    itemInfo: {
        display: "flex",
        flexFlow: 'column',
        justifyContent: 'center',
        alignItems: "center",
        color: "#ff0000",
        marginBottom: "10px",
    },
    timerText: {
        fontSize: '0.65rem',
        letterSpacing: "1px",
    },
    productTitle: {
        fontSize: ".85rem",
        textAlign: "left",
        color: "gray",
        display: "-webkit-box",
        overflow: 'hidden',
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: 2,
        [theme.breakpoints.up('sm')]: {
            fontSize: "0.9rem",
        }
    },
    productPrice: {
        fontSize: "0.9rem",
        textAlign: 'left',
    },
}));

const RequireActionProductsView = (props: any) => {
    const classes = useStyles();
    const history = useHistory();
    const { products } = props
    const viewItems = products

    const TicketImageView = ({
        product
    }: any) => {
        return (
            <>
                {product.draft_statsus === 'processionable' && <></>}
                {product.draft_statsus === 'appraisalWatingSelectWinner' && <img src={WaitingWinnerTicketImg} className={classes.ticketImg} />}
                {product.draft_statsus === 'appraisalWatingDeposit' && <img src={WaitingDepositTicketImg} className={classes.ticketImg} />}
                {product.draft_statsus === 'appraisalWaitingShipping' && <img src={WaitingShippingTicketImg} className={classes.ticketImg} />}
                {product.draft_statsus === 'appraisalOnShipping' && <img src={OnShippingTicketImg} className={classes.ticketImg} />}
                {product.draft_statsus === 'appraisalOnChecking' && <img src={OnCheckingTicketImg} className={classes.ticketImg} />}
                {product.draft_statsus === 'appraisalOnPaying' && <img src={OnPayingTicketImg} className={classes.ticketImg} />}
                {product.draft_statsus === 'appraisalComplete' && <img src={AppraisalCompleteTicketImg} className={classes.ticketImg} />}
                {product.draft_statsus === 'appraisalOnReturning' && <img src={OnShippingTicketImg} className={classes.ticketImg} />}
                {product.draft_statsus === 'appraisalCancelled' && <img src={CancelledTicketImg} className={classes.ticketImg} />}
                {product.draft_statsus === 'processioned' && <img src={ProceesionedTicketImg} className={classes.ticketImg} />}
                {product.draft_statsus === 'notRelease' && <></>}
            </>
        )
    }

    return (
        <Container className={classes.cardGrid} maxWidth="md">
            {viewItems.length > 2 && <Typography className={classes.sectionTitle} variant="subtitle1">取引中</Typography>}

            <Grid container style={viewItems.length < 3 ? { maxWidth: '350px', margin: '0 auto' } : {}}>
                {viewItems.map((product: any) => {
                    return (
                        <Grid className={classes.gridItem} item key={product.id} xs={viewItems.length >= 3 ? 6 : 12} sm={viewItems.length >= 3 ? 6 : 12} onClick={() => { history.push(`/appraisal/${product.id}`) }}>
                            <div className={classes.itemInfo}>

                                {product.draft_status === 'active' &&
                                    <>
                                        <Typography variant='subtitle2' className={classes.timerText}>
                                            <>確定締切まで</>
                                        </Typography>
                                        <HoursTimer
                                            variant="subtitle1"
                                            product={product}
                                            endDateTime={new Date().setTime(
                                                product.release_date.seconds * 1000
                                            )}
                                        />
                                    </>
                                }

                                <SquareImageView imageSrc={product.image} alt={product.title} />
                                <TicketImageView product={product} />

                            </div>

                            <Typography variant="subtitle2" className={classes.productTitle}>{product.title}</Typography>
                            <Typography className={classes.productPrice}>
                                買取金額: ¥ {formatedYen(product.win_ticket.bid_price)}
                            </Typography>

                        </Grid>
                    )
                })}
            </Grid>
        </Container >
    );
}

export default RequireActionProductsView;
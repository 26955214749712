import { FormControl, makeStyles, MenuItem, MuiThemeProvider, Select } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core";
import React, { memo } from "react";
import { getInsuranceFee } from "../../utils/Insurance";
import { Product } from "../../types/product";
import { PaymentState } from "../../types/common";

const useStyles = makeStyles(() => ({
  select_form_control: {
    width: "100%",
  },
  select_root: {
    borderRadius: "0",
    border: "1px solid grey",
    textAlign: "center",
  },
  dropdownStyle: {
    maxHeight: "200px",
  },
  icon: {
    color: "grey",
  },
}))

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#000',
      main: '#000',
      dark: '#000',
      contrastText: '#fff',
    },
  },
  overrides: {
    MuiSelect: {
      select: {
        padding: "14px",
        border: "1px solid grey",
        "&:focus": {
          backgroundColor: "#fff",
        },
        '&:after': {
          border: "1px splid black",
        },
        '&:before': {
          border: "1px splid black",
        },
      }
    },
    MuiInputLabel: {
      root: {
        color: "black",
      }
    }
  }
});

type Props = {
  paymentStates: PaymentState,
  setPaymentStates: React.Dispatch<React.SetStateAction<PaymentState>>,
  productInfo: Product,
}

const AmountSelect = memo((props: Props) => {
  const { paymentStates, setPaymentStates, productInfo } = props
  const classes = useStyles();

  const handleAmountChange = (e: any) => {
    let insuranceFee = productInfo.insurance_fee_name ? getInsuranceFee(e.target.value, productInfo.insurance_fee_name) : 0
    setPaymentStates({ ...paymentStates, inputAmount: e.target.value, droppFee: e.target.value * productInfo.auction_fee / 100, insuranceFee: insuranceFee })
  }

  return (
    <MuiThemeProvider theme={theme}>
      <FormControl required className={classes.select_form_control}>
        <Select
          value={paymentStates.inputAmount ? paymentStates.inputAmount : paymentStates.biddableAmounts[0]}
          onChange={handleAmountChange}
          classes={{ root: classes.select_root }}
          variant="outlined"
          MenuProps={{
            classes: {
              paper: classes.dropdownStyle,
            },
          }}
          inputProps={{ classes: { icon: classes.icon } }}
        >
          {paymentStates.biddableAmounts.map((amount: any, index: any) => (
            <MenuItem value={amount} key={index}>¥ {String(amount).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </MuiThemeProvider>
  );
})

export default AmountSelect;
const PaymentStatusValueFromNum = {
  0: '決済待ち',
  1: 'オーソリ中',
  2: '決済済み',
  3: 'キャンセル済み',
};

const ShippingStatusValueFromNum = {
  0: '発送準備中',
  1: '一部発送済み',
  2: '発送済み',
  3: '返品中',
  4: 'キャンセル済み',
  5: '一部キャンセル済み',
};

export { PaymentStatusValueFromNum, ShippingStatusValueFromNum }
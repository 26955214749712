import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Common from "./commonSlice";
import { useSelector, useDispatch } from "react-redux";
import { withStyles } from "@material-ui/core";

const CustomSnackbar = withStyles({
  root: {
    marginTop: '30px',
    zIndex: 10000,
  },
})(Snackbar);

function Alert() {
  // @ts-expect-error TS(2339): Property 'common' does not exist on type 'DefaultR... Remove this comment to see the full error message
  const alert = useSelector((state) => state.common.alert);
  const dispatch = useDispatch();
  let alerts;
  let message;

  if (alert && alert.alerts) {
    alerts = alert.alerts;
    message = alerts[0].message;
  }
  function handleClose() {
    dispatch(Common.actions.resetAlert());
  }

  return (
    <React.Fragment>
      <CustomSnackbar
        autoHideDuration={3000}
        id="Snackbar_root"
        color="error"
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={alert ? true : false}
        onClose={() => handleClose()}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
        message={message}
      />
    </React.Fragment>
  );
}

export default Alert;

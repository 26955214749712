import React, { useEffect } from "react";
import { withRouter, useLocation, useHistory, Switch, Route } from "react-router-dom";
import {
  Typography,
  makeStyles,
  Container,
  withStyles,
  Toolbar,
  Tabs,
  Tab,
  Theme,
  createStyles,
} from "@material-ui/core";
import Copyright from "../components/Copyright";
import MypageIndex from "../components/mypage/MypageIndex";
import ChangePhoneNumber from "../components/mypage/ChangePhoneNumber";
import ChangeEmail from "../components/mypage/ChangeEmail";
import ChangePassword from "../components/mypage/ChangePassword";
import ChangePaymentMethods from "../components/mypage/ChangePaymentMethods";
import ChangeShippingMethods from "../components/mypage/ChangeShippingMethods";

const useStyles = makeStyles((theme: Theme) => createStyles({
  tab_wrapper: {
    position: "fixed",
    background: "white",
    width: "100%",
    zIndex: 1000,
  },
  root: {
    paddingTop: theme.spacing(8),
    minHeight: '94vh',
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  tab_bar: {
    display: 'flex',
    justifyContent: 'center',
    width: "100%",
    padding: "0",
    backgroundColor: "white",
  },
  title: {
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
}));

const StyledTab = withStyles(() => ({
  root: {
    color: 'grey',
    letterSpacing: "3px",
    padding: "6px 16px",
    '&:focus': {
      opacity: 1,
      color: 'black',
      fontWeight: "bold",
    },
    '&.Mui-selected': {
      color: 'black',
      fontWeight: "bold",
    },
    minWidth: 0,
  },
}))((props: { style: any, label: string }) => <Tab {...props} />);

const Mypage = () => {
  const classes = useStyles();
  const history = useHistory()
  const location = useLocation();
  const [_, rootPath] = location.pathname.split("/");
  console.log('_', _)
  const [tabValue, setTabValue] = React.useState(0);

  useEffect(() => {
    switch (location.pathname) {
      case '/mypage/shipping-methods':
        setTabValue(1)
        break;
      case '/mypage/payment-methods':
        setTabValue(2)
        break;
      default:
        setTabValue(0)
        break
    }
  }, [])

  const handleTabChange = (e: any, newTabValue: any) => {
    if (newTabValue === 0) {
      history.push('/mypage')
    } else if (newTabValue === 1) {
      history.push('/mypage/shipping-methods')
    } else if (newTabValue === 2) {
      history.push('/mypage/payment-methods')
    }
    window.scrollTo(0, 0);
    setTabValue(newTabValue)
  }

  return (
    <React.Fragment>
      <Container component="main" maxWidth="sm" className={classes.root}>
        <div className={classes.tab_wrapper}>
          <Typography variant="subtitle1" className={classes.title}>マイページ</Typography>
          <Toolbar className={classes.tab_bar}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              variant="scrollable"
              textColor="secondary"
              TabIndicatorProps={{ style: { display: "none" } }}
              aria-label="home tabs"
            >
              <StyledTab style={{ width: "120px" }} label="登録情報" />
              <StyledTab style={{ width: "120px" }} label="配送情報" />
              <StyledTab style={{ width: "120px" }} label="支払い情報" />
            </Tabs>
          </Toolbar>
        </div>
        <div style={{ marginTop: '100px', width: '100%' }}>
          <Switch location={location} key={rootPath}>
            <Route path="/mypage" render={() => (
              <Switch location={location} key={rootPath}>
                <Route path="/mypage/change-phone-number" component={ChangePhoneNumber} />
                <Route path="/mypage/change-email" component={ChangeEmail} />
                <Route path="/mypage/change-password" component={ChangePassword} />
                <Route path="/mypage/shipping-methods" component={ChangeShippingMethods} />
                <Route path="/mypage/payment-methods" component={ChangePaymentMethods} />
                <Route exact path="/mypage" component={MypageIndex} />
              </Switch>
            )} />
          </Switch>
        </div>
      </Container>
      <Copyright />
    </React.Fragment>
  );
};

export default withRouter(Mypage);

import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Button, Container, makeStyles, Dialog, DialogContent, DialogActions, Typography, Toolbar, FormControlLabel, Radio, FormControl, RadioGroup, Divider } from "@material-ui/core";
import { AuthContext } from '../contexts/Auth';
import { CloudFunctions, createTimeStamp, db } from '../utils/Firebase';
import { formatedYen } from '../utils/Common';
import { useDispatch } from 'react-redux';
import Common from '../redux/common/commonSlice';
import { DroppNoticeChannelId, DroppOperationChannelId, amanoSlackMemberId } from '../utils/Notify';
import ProductImagesView from '../components/appraisal/ProductImagesView';
import AppraisalProductDetailView from '../components/appraisal/AppraisalProductDetailView';
import { useForm } from 'react-hook-form';
import BackdropLoading from '../components/common/BackdropLoading';
import ActionsView from '../components/appraisal/detail/ActionsView';
import MessagesView from '../components/appraisal/detail/MessagesView';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import BackLinkView from '../components/common/BackLinkView';
import MainButton from '../components/common/MainButton';
import ShippedInfoInputView from '../components/appraisal/detail/ShippedInfoInputView';
import BidDialogView from '../components/appraisal/detail/BidDialogView';
import { useNotify } from '../hooks/useNotify';
import OutlinedButton from '../components/common/OutlinedButton';
import { ProcessionStatus } from '../types/common';
import { Product } from '../types/product';
import { Message, Ticket } from '../types/ticket';

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '60px',
        margin: '100px auto 0',
        [theme.breakpoints.up('sm')]: {
            marginTop: '100px',
        }
    },
    messageViewText: {
        textAlign: 'right',
        width: '100%',
        color: "#f00",
        textDecoration: 'underline',
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
        marginTop: '8px',
        '&:hover': {
            cursor: 'pointer',
        }
    },
    negativeMessagesLinkText: {
        color: "#f00",
        textDecoration: 'underline',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&:hover': {
            cursor: 'pointer',
        }
    },
    images_wrapper: {
        maxWidth: "350px",
        width: "80%",
        margin: "0 auto",
    },
    main_container: {
        marginTop: theme.spacing(9),
        paddingBottom: '120px',
    },
    card: {
        marginTop: theme.spacing(2),
        height: "100%",
        display: "flex",
        flexDirection: "column",
        position: "relative",
    },
    item_info: {
        display: "flex",
        color: '#ff0000',
        justifyContent: 'center',
        minHeight: '30px',
    },
    timer_text: {
        fontSize: '0.8rem',
        letterSpacing: "1px",
    },
    desc_html: {
        lineHeight: "1.65",
        '& p': {
            margin: "0",
            padding: "0",
            '&.pi': {
                padding: "10px",
            },
        },
        '& ul': {
            paddingLeft: "1rem",
        },
        '& ol': {
            marginTop: "4px",
            paddingLeft: "1rem",
        }
    },
    buttonWrapper: {
        position: "fixed",
        width: "100%",
        display: 'flex',
        justifyContent: 'center',
        left: "0",
        bottom: "0",
        textAlign: 'center',
        backgroundColor: '#fff',
        padding: "24px",
        boxSizing: "border-box",
        zIndex: 1300,
        [theme.breakpoints.down('sm')]: {
            padding: "16px",
        }
    },
    myBidWrapper: {
        boxSizing: 'border-box',
        border: '1px solid #f00',
        height: '40px',
        padding: '4px 8px',
        width: '100%',
        margin: '0 auto',
        marginBottom: '24px',
    },
    myBidText: {
        fontSize: '14px',
        fontWeight: 'bold',
        color: '#f00',
        textAlign: 'center',
        lineHeight: '32px',
    },
    selectTicketWrapper: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '32px',
    },
    selectTicketText: {
        color: '#ff0000',
        textAlign: 'center',
        fontSize: '14px',
    },
    bidHistoryInput: {
        border: "1px solid black",
        width: "100%",
        position: "relative",
        left: "10px",
        marginTop: "12px",
        margin: '0 auto',
    },
    label: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
    },
    bidPriceLists: {
        marginBottom: '16px',
    },
    bidPriceList: {
        display: "flex",
    },
    bidPriceTitle: {
        width: "50%",
    },
    bidPriceDesc: {
        width: "50%",
    },
    justifyStart: {
        width: '100%',
        display: 'flex',
        marginBottom: '16px',
    },
    justifyEnd: {
        width: '100%',
        display: 'flex',
        justifyContent: 'end',
        marginBottom: '16px',
    },
    messageTime: {},
    messageText: {
        backgroundColor: '#e6e6e6',
        color: '#1a1a1a',
        border: '1px solid dimgray',
        borderRadius: '4px',
        padding: '8px'
    }
}))

type Params = {
    product_id: string
}

export const AppraisalDetail = () => {
    const classes = useStyles()
    const history = useHistory()
    const dispatch = useDispatch()
    const { currentUser, teaserEmail } = useContext<any>(AuthContext)
    const { product_id } = useParams<Params>();
    const [product, setProduct] = useState<Product | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [myTicket, setMyticket] = useState<Ticket | null>(null)
    const { setValue, getValues, register, handleSubmit, formState: { errors }, watch } = useForm()
    const [tickets, setTickets] = useState<Ticket[]>([])
    const { sendEmail, sendSlack, getSlackChannelIdByBuyerName } = useNotify()

    // チケットを取得
    const getTickets = async (_productId: any) => {
        db.collection('tickets').where('product_id', '==', Number(_productId)).onSnapshot(snapshot => {
            const _tickets: any = []
            snapshot.forEach(doc => {
                _tickets.push(doc.data())
            })
            setTickets(_tickets)
        })
    }

    // 自分のチケットを取得
    const getMyTicket = async (_productId: any) => {
        db.collection('tickets').where('product_id', '==', Number(_productId)).where('uid', '==', currentUser.uid).onSnapshot(snapshot => {
            snapshot.forEach(doc => {
                setMyticket(doc.data() as Ticket)
            })
        })
    }

    // 商品情報とを取得 
    useEffect(() => {
        const getProduct = async () => {
            await db.collection('products').doc(String(product_id)).get().then(async doc => {
                let _product = doc.data() as Product
                _product["id"] = doc.id
                setProduct(_product)
                teaserEmail.is_buyer ? await getMyTicket(_product.id) : await getTickets(_product.id)
            })
        }
        if (currentUser) getProduct()
    }, [currentUser])

    // 入札状況
    const [processionStatus, setProcessionStatus] = useState<ProcessionStatus>(null)
    useEffect(() => {
        if (!product) return

        if (teaserEmail?.is_buyer) { // 買取側
            if (myTicket) {
                if (myTicket.uid === product.win_ticket?.uid) { // 落札されている
                    if (product.draft_status === 'appraisalWatingDeposit') { //業者からのデポジット待ち
                        setProcessionStatus('appraisalWatingDeposit')
                        return
                    } else if (product.draft_status === 'appraisalWaitingShipping') { //業者からのデポジット待ち
                        setProcessionStatus('appraisalWaitingShipping')
                        return
                    } else if (product.draft_status === 'appraisalOnShipping') { //配送中
                        setProcessionStatus('appraisalOnShipping')
                        return
                    } else if (product.draft_status === 'appraisalOnChecking') { //査定中
                        setProcessionStatus('appraisalOnChecking')
                        return
                    } else if (product.draft_status === 'appraisalOnPaying') { //送金中
                        setProcessionStatus('appraisalOnPaying')
                        return
                    } else if (product.draft_status === 'appraisalComplete') { //成立
                        setProcessionStatus('appraisalComplete')
                        return
                    } else if (product.draft_status === 'appraisalOnReturning') { //返送中
                        setProcessionStatus('appraisalOnReturning')
                        return
                    } else if (product.draft_status === 'appraisalCancelled') { //キャンセル済み
                        setProcessionStatus('appraisalCancelled')
                        return
                    }
                } else { // 落札されていない + チケットを持っている => 入札中
                    setProcessionStatus('processioned')
                    return
                }
            } else if (!myTicket) {
                if (product.draft_status === 'active') {
                    setProcessionStatus('processionable')
                } else if (product.draft_status === 'archived') {
                    setProcessionStatus('archived')
                } else {
                    setProcessionStatus('notRelease')
                }
            }

        } else { // 出品者側
            if (product.draft_status === 'draft') {
                setProcessionStatus('draft')
            } else if (product.draft_status === 'archived') {
                setProcessionStatus('archived')
            } else if (product.draft_status === 'active') {
                setProcessionStatus('active')
            } else if (product.draft_status === 'appraisalWatingDeposit') {
                setProcessionStatus('appraisalWatingDeposit')
                return
            } else if (product.draft_status === 'appraisalWaitingShipping') {
                setProcessionStatus('appraisalWaitingShipping')
                return
            } else if (product.draft_status === 'appraisalOnShipping') {
                setProcessionStatus('appraisalOnShipping')
                return
            } else if (product.draft_status === 'appraisalOnChecking') {
                setProcessionStatus('appraisalOnChecking')
                return
            } else if (product.draft_status === 'appraisalOnPaying') {
                setProcessionStatus('appraisalOnPaying')
                return
            } else if (product.draft_status === 'appraisalComplete') {
                setProcessionStatus('appraisalComplete')
                return
            } else if (product.draft_status === 'appraisalOnReturning') {
                setProcessionStatus('appraisalOnReturning')
                return
            } else if (product.draft_status === 'appraisalCancelled') {
                setProcessionStatus('appraisalCancelled')
                return
            }
        }

    }, [myTicket, product])

    // メイン画像
    const ImageSlideView = (props: any) => {
        const images = props.images ? props.images.map((image: any) => image.src) : [];

        if (images.length == 0) {
            return (<></>)
        } else {
            return (
                <>
                    <div className={classes.images_wrapper}>
                        <ProductImagesView images={images} processionStatus={processionStatus} />
                    </div>
                </>
            )
        }
    };

    // 落札者選択ダイアログ
    const [selectWinnerDialogOpen, setSelectWinnerDialogOpen] = useState(false)
    const handleSelectWinnerDialogOpen = () => {
        setSelectWinnerDialogOpen(true)
    }
    const SelectWinnerDialog = () => {
        if (!product) return <></>
        const targetTicket = product.bid_history?.length > 0 ? product.bid_history[Number(selectedTicketStr)] : null
        if (targetTicket) {
            return (
                <Dialog
                    open={selectWinnerDialogOpen}
                    onClose={() => setSelectWinnerDialogOpen(false)}
                >
                    <DialogContent>
                        <div className={classes.bidPriceLists}>
                            <dl className={classes.bidPriceList}>
                                <dt className={classes.bidPriceTitle}>
                                    <Typography variant='body2'>
                                        商品金額
                                    </Typography></dt>
                                <dd className={classes.bidPriceDesc}>¥{formatedYen(targetTicket.bid_price)}</dd>
                            </dl>
                            <dl className={classes.bidPriceList}>
                                <dt className={classes.bidPriceTitle}>
                                    <Typography variant='body2'>
                                        販売手数料
                                    </Typography></dt>
                                <dd className={classes.bidPriceDesc}>- ¥{formatedYen(targetTicket.bid_price * 0.05)}</dd>
                            </dl>
                            <Divider />
                            <dl className={classes.bidPriceList}>
                                <dt className={classes.bidPriceTitle}>
                                    <Typography variant='body2'>
                                        売上金額
                                    </Typography></dt>
                                <dd className={classes.bidPriceDesc}>¥{formatedYen(Number(targetTicket.bid_price) - Number(targetTicket.bid_price * 0.05))}</dd>
                            </dl>
                        </div>
                        <Typography variant='body2' style={{ marginTop: '24px', marginBottom: '8px' }}>
                            <span style={{ fontSize: '13px' }}>※落札確定後、買取業者からの入金が完了しましたらメールにてご連絡させていただきますので商品の発送をお願い致します。</span><br />
                        </Typography>
                        <Typography variant='body2'>
                            こちらの入札で確定します。<br />
                            よろしいですか？
                        </Typography>
                    </DialogContent>
                    <DialogActions style={{ display: 'flex', justifyContent: 'space-around' }}>
                        <Button onClick={() => setSelectWinnerDialogOpen(false)} style={{}}>いいえ</Button>
                        <Button onClick={handleSelectWinner} style={{ color: '#ff0000' }}>はい</Button>
                    </DialogActions>
                </Dialog >
            )
        } else {
            return (<></>)
        }
    }

    // 発送連絡入力ダイアログ
    const [isShippingInputDialogOpen, setIsShippingInputDialogOpen] = useState(false)
    const handleShippingInputDialogOpen = () => {
        setIsShippingInputDialogOpen(true)
    }

    //発送完了連絡
    const handleSendShippingInfo = async () => {
        if (errors.shipping_number?.message || errors.shipping_provider?.message || !product) {
            return
        }
        setIsLoading(true)

        try {
            // 商品情報を更新する appraisalOnShipping
            const func = CloudFunctions.httpsCallable('updateFirestoreProduct')
            const updateField = {
                draft_status: 'appraisalOnShipping',
                shipping_provider: getValues().shipping_provider,
                shipping_number: getValues().shipping_number
            }
            await func({ productId: product.id, field: updateField })

            // 業者とDroppに連絡
            const postSlackMessage = CloudFunctions.httpsCallable("postSlackMessage")
            const amano = '<@U01TJ3U9NHM>'
            const channelId = DroppNoticeChannelId
            const text = `${amano} \n買取商品の発送が完了しました。\n商品名: ${product.title}\n買取業者: Dropp \n配送業社: ${getValues().shipping_provider}\n配送番号: ${getValues().shipping_number}\n-> https://dropp.jp/appraisal/${product.id}`
            await postSlackMessage({ text, conversationId: channelId })
        } catch (e) {
            console.error(e)
        }
        dispatch(Common.actions.fetchAlert({ alerts: [{ message: '買取商品の落札が完了しました。' }], }))
        history.push('/appraisal')


        setIsLoading(false)
    }

    //落札を確定
    const handleSelectWinner = async () => {
        if (!product) return
        setSelectWinnerDialogOpen(false)
        setIsLoading(true)

        try {
            const winTicket = product.bid_history[Number(selectedTicketStr)]

            // 1.商品情報の更新 and チケットの更新andメール送信
            const selectAppraisalWinTicket = CloudFunctions.httpsCallable('selectAppraisalWinner')
            const res = await selectAppraisalWinTicket({ product_id: product.id, winTicket: winTicket })

            if (res.data.status === 'failed') throw new Error('入札確定に失敗')

            // 2.買取落札が確定したら、業者にデポジットの連絡
            // 3.Slackに通知
            try {
                const postSlackMessage = CloudFunctions.httpsCallable("postSlackMessage")
                const text = `
${amanoSlackMemberId}
買取商品の落札が確定しました。
買取業者へのデポジット入金の依頼をお願いいたします。
入金が完了したら出品者に発送連絡をお願いいたします。
商品名: ${product.title}
落札金額: ¥${formatedYen(Number(winTicket.bid_price))}
買取者: ${winTicket.uid}
-> https://dropp.jp/appraisal/${product.id}`
                await postSlackMessage({ text, conversationId: DroppNoticeChannelId })
            } catch (e) {
                console.error(e)
            }
            dispatch(Common.actions.fetchAlert({ alerts: [{ message: '買取商品の落札が完了しました。' }], }))
            history.push('/appraisal')
        } catch (e) {
            console.log('error', e)
        }
        setIsLoading(false)
    }

    const [upDownDialogOpen, setUpDownDialogOpen] = useState(false)
    const handleBidDialogOpen = () => {
        setUpDownDialogOpen(true)
        if (myTicket) setValue('bid_price', Number(myTicket.bid_price))
    }

    const ButtonView = () => {
        return (
            <Toolbar className={classes.buttonWrapper}>
                {teaserEmail.is_buyer ? // 買取業者
                    <>
                        {(processionStatus === 'processionable' || processionStatus === 'processioned') &&
                            <>
                                {!myTicket && <OutlinedButton text='入札しない' onClick={handleWithdrawalBid} />}
                                <MainButton text={myTicket ? '入札を更新する' : '入札する'} onClick={handleBidDialogOpen} />
                            </>
                        }
                    </>
                    : // 出品者
                    <>
                        {processionStatus === 'draft' &&
                            <MainButton text='商品情報を更新する' onClick={() => history.push(`/appraisal/${product?.id}/update`)} />
                        }
                        {processionStatus === 'active' &&
                            <>
                                <MainButton text='商品情報を更新する' onClick={() => history.push(`/appraisal/${product?.id}/update`)} />
                                <MainButton
                                    disabled={!(selectedTicketStr && tickets[selectedTicketStr].bid_price)}
                                    text='落札を確定'
                                    onClick={handleSelectWinnerDialogOpen}
                                    style={selectedTicketStr ? { marginLeft: '10px' } : { backgroundColor: 'gray', marginLeft: '10px' }}
                                />
                            </>
                        }
                        {processionStatus === 'appraisalWaitingShipping' &&
                            <MainButton text='発送完了連絡をする' onClick={handleShippingInputDialogOpen} />
                        }
                    </>
                }
            </Toolbar>
        )

    }

    // 新規入札 or 入札更新
    const handleBid = async () => {
        const bidPrice = Number(getValues().bid_price)
        if (!bidPrice) return

        setIsLoading(true)
        setUpDownDialogOpen(false)

        try {

            if (myTicket) {
                // ticketデータを更新
                await db.collection('tickets').doc(myTicket.id).update({ bid_price: bidPrice })
                    .then(() => {
                    }).catch(() => {
                        throw new Error('入札に失敗')
                    })
            } else {
                const bidTicket = {
                    product_id: product_id,
                    bid_price: bidPrice,
                    uid: currentUser.uid,
                    email: teaserEmail.email,
                    status: 10, //
                    order_id: null,
                    payment_intent: null,
                    shipping_info: null,
                    messages: []
                }
                // 1.チケットの作成と商品のbid_historyを更新
                const createBid = CloudFunctions.httpsCallable('createAppraisalTicket')
                const res = await createBid({ product_id, bidTicket })
                if (res.data.status === 'failed') throw new Error('入札に失敗')
            }

            // 2. 出品者にメール通知
            await sendEmail({
                mailTo: product?.user_email,
                subject: '【Dropp】買取商品への入札がありました。',
                content: `いつもDroppをご利用いただきありがとうございます。

下記の商品へ入札がございました。つきましては内容をご確認の上、商品ページのリンクよりご返信をお願いいたします。

Droppチーム

【入札内容】
商品名: ${product?.title}
入札金額: ¥${formatedYen(bidPrice)}

【商品ページ】
http://dropp.jp/appraisal/${product?.id}
`
            })

            // 2.Slackに通知
            const postSlackMessage = CloudFunctions.httpsCallable("postSlackMessage")
            const text = `${amanoSlackMemberId} \n【買取入札】\n商品名: ${product?.title}
入札金額: ¥${formatedYen(bidPrice)}
-> https://dropp.jp/appraisal/${product?.id}`
            try {
                await postSlackMessage({ text, conversationId: DroppNoticeChannelId })
            } catch (e) {
                console.error(e)
            }

            dispatch(Common.actions.fetchAlert({ alerts: [{ message: '買取商品への入札が完了しました。' }], }))
            history.push('/appraisal')
            setIsLoading(false)
        } catch (e) {
            console.log('error', e)
            dispatch(Common.actions.fetchAlert({ alerts: [{ message: '入札に失敗しました。' }], }))
            setIsLoading(false)
        }
    }

    //チケットを選択
    const [selectedTicketStr, setSelectedTicketStr] = useState(null)
    const handleSelectBidTicket = (e: any) => {
        setSelectedTicketStr(e.target.value)
    }

    // お問合せ
    const handleSendMessage = async (_text: any, _ticket: any) => {
        if (!product) return
        setIsLoading(true)
        setUpDownDialogOpen(false)

        // 出品者からの返信か、買取業者からのお問合せか。
        const isSenderByUser = product.user_id === currentUser.uid
        if (!_ticket) {
            const buyerName = currentUser.vendor
            const bidTicket = {
                product_id: product_id,
                bid_price: null,
                uid: currentUser.uid,
                email: teaserEmail.email,
                status: 10,
                order_id: null,
                payment_intent: null,
                shipping_info: null,
                messages: [
                    {
                        content: _text,
                        created_at: createTimeStamp(new Date()),
                        is_sender_by_buyer: isSenderByUser,
                        buyerName
                    }
                ]
            }
            try {
                // チケットの作成 & 商品のbid_historyを更新
                const createBid = CloudFunctions.httpsCallable('createAppraisalTicket')
                const res = await createBid({ product_id, bidTicket })
                if (res.data.status === 'failed') throw new Error('入札に失敗')
                // Droppと出品者にSlack通知、ユーザーにメール通知
                await notifyContactForSlackAndEmail(isSenderByUser, _text, product.user_email, buyerName)
                await getTickets(product_id)
                dispatch(Common.actions.fetchAlert({ alerts: [{ message: 'お問合せが完了しました。' }], }))
            } catch (e) {
                console.log('error', e)
                dispatch(Common.actions.fetchAlert({ alerts: [{ message: 'お問合せに失敗しました。' }], }))
            }
        } else { // チケットが既にある場合
            let messages = _ticket.messages
            const buyerName = currentUser.vendor ?? messages[0]?.buyerName
            messages.push({
                content: _text,
                created_at: createTimeStamp(new Date()),
                is_sender_by_buyer: isSenderByUser,
                buyerName: buyerName ?? '',
            })

            // ticketデータを更新
            await db.collection('tickets').doc(_ticket.id).update({ messages: messages })
                .then(async () => {
                    // Droppと出品者にSlack通知、ユーザーにメール通知
                    await notifyContactForSlackAndEmail(isSenderByUser, _text, product.user_email, buyerName)
                    dispatch(Common.actions.fetchAlert({ alerts: [{ message: 'お問合せが完了しました。' }], }))
                }).catch(e => {
                    console.error('error', e)
                    dispatch(Common.actions.fetchAlert({ alerts: [{ message: 'お問合せに失敗しました。' }], }))
                })

            await getTickets(product_id)
        }
        setIsLoading(false)
    }

    // 入札をしない
    const handleWithdrawalBid = async () => {
        setIsLoading(true)
        try {
            const bidTicket = {
                product_id: product_id,
                bid_price: null,
                uid: currentUser.uid,
                email: teaserEmail.email,
                status: 10, //
                order_id: null,
                payment_intent: null,
                shipping_info: null,
                messages: []
            }
            // 1.チケットの作成と商品のbid_historyを更新
            const createBid = CloudFunctions.httpsCallable('createAppraisalTicket')
            const res = await createBid({ product_id, bidTicket })
            if (res.data.status === 'failed') throw new Error('入札に失敗')
        } catch (e) {
            console.error('error', e)
        }
        setIsLoading(false)
    }

    // お問合せがあったことをSlackとメールで通知
    const notifyContactForSlackAndEmail = async (isSenderByUser: any, text: any, userEmail: any, buyerName: any) => {
        if (!product) return
        const slackText = `【買取商品へのお問合せ】\n商品名: ${product.title}\n商品ID: ${product.id}\nお問合せ内容: ${text}`
        if (isSenderByUser) { // 出品者からの返信の場合
            await sendSlack({ text: slackText, conversationId: DroppOperationChannelId }) // DroppへSlack通知
            await sendSlack({ text: slackText, conversationId: getSlackChannelIdByBuyerName(buyerName) }) // 業者へSlack通知
        } else { // 業者からのお問合せの場合
            const mailTo = userEmail
            const subject = '【Dropp】査定商品へのお問合せがありました。'
            const link = `http://dropp.jp/appraisal/${product.id}`
            const content = `いつもDroppをご利用いただきありがとうございます。

下記の商品へお問合せがございました。つきましては内容をご確認の上、商品ページのリンクよりご返信をお願いいたします。

Droppチーム

【商品名】
${product.title}

【お問合せ内容】
${text}

【商品ページ】
${link}
`
            await sendEmail({ mailTo, subject, content }) // ユーザーへメール通知
            await sendSlack({ text: slackText, conversationId: DroppOperationChannelId }) // DroppへSlack通知
            await sendSlack({ text: slackText, conversationId: getSlackChannelIdByBuyerName(buyerName) }) // 業者へSlack通知
        }
    }

    const [openMessageIndex, setOpenMessageIndex] = useState(null)
    const handleMessageViewOpen = (_index: any) => {
        openMessageIndex === _index ? setOpenMessageIndex(null) : setOpenMessageIndex(_index)
    }

    const formateDate = (firestoreTimeStampDate: any) => {
        const _date = new Date(firestoreTimeStampDate.seconds * 1000)
        return `${_date.getFullYear()}-${_date.getMonth() + 1}-${_date.getDate()} ${_date.getHours()}:${_date.getMinutes()}`
    }

    if (product) {
        return (
            <Container className={classes.main_container} maxWidth="md">

                <div style={{ paddingTop: '12px' }}>
                    <BackLinkView text={'戻る'} handleClick={() => history.push('/appraisal')} />

                </div>

                <div className={classes.card}>
                    <ImageSlideView images={product.images} processionStatus={processionStatus} />
                </div>

                <ActionsView />

                {/*  入札履歴 */}
                {teaserEmail?.is_buyer ? // 買取業者
                    <>
                        {myTicket ? // 買取業者 & 入札あり
                            <Container maxWidth='sm' style={{ padding: '0', marginBottom: '32px' }}>
                                <div className={classes.myBidWrapper}>
                                    <Typography className={classes.myBidText} variant="body2"
                                        style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '8px', paddingLeft: '8px' }}
                                    >
                                        <span>
                                            {myTicket.bid_price ?
                                                "¥" + String(myTicket.bid_price).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,') + 'で入札中' :
                                                '入札なし'
                                            }
                                        </span>
                                        <span className={classes.negativeMessagesLinkText} >
                                            コメント: {myTicket.messages ? myTicket.messages.length : '0'}件
                                        </span>
                                    </Typography>
                                </div>
                                <Typography variant="body2" style={{ textAlign: 'center', marginTop: '8px', marginBottom: '8px' }}>
                                    出品者へお問合せ
                                </Typography>
                                <MessagesView ticket={myTicket} handleSendMessage={(text: any) => handleSendMessage(text, myTicket)} />
                                <Container maxWidth="sm" style={{ width: '100%', display: 'flex', justifyContent: 'end', padding: '0', flexDirection: 'column' }}>
                                    {myTicket.messages?.reverse()?.map((_message: Message, index: any) => (
                                        <div className={_message.is_sender_by_buyer ? classes.justifyStart : classes.justifyEnd} key={index}>
                                            <div>
                                                <Typography className={classes.messageTime}
                                                    style={_message.is_sender_by_buyer ? { textAlign: 'right' } : {}}
                                                    variant='body2'>{formateDate(_message.created_at)}</Typography>
                                                <Typography className={classes.messageText} variant='body2'>{_message.content}</Typography>
                                            </div>
                                        </div>
                                    ))}
                                </Container>
                            </Container>
                            : // 出品者 & 入札なし
                            <>
                                <Typography variant="body2" style={{ textAlign: 'center', marginTop: '16px', marginBottom: '8px' }}>
                                    出品者へお問合せ
                                </Typography>
                                <MessagesView ticket={myTicket} handleSendMessage={(text: any) => handleSendMessage(text, null)} />
                            </>
                        }
                    </>
                    : // 出品者側
                    <>
                        {tickets.length > 0 &&
                            <Container maxWidth='sm' className={classes.selectTicketWrapper}>
                                {processionStatus === 'active' ?
                                    <>
                                        <FormControl fullWidth>
                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                onChange={handleSelectBidTicket}
                                                value={selectedTicketStr}
                                                name="address-input-group"
                                            >
                                                {tickets.map((_ticket, index) => (
                                                    <div key={index} style={{ marginBottom: '24px' }}>
                                                        <FormControlLabel
                                                            key={index}
                                                            className={classes.bidHistoryInput}
                                                            value={index.toString()}
                                                            control={<Radio color="default" />}
                                                            label={
                                                                <div className={classes.label}>
                                                                    <Typography variant='body2'>
                                                                        業者{index + 1}: {_ticket.bid_price ? '¥' + String(_ticket.bid_price).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,') : '入札希望なし'}
                                                                    </Typography>
                                                                </div>
                                                            }
                                                        />
                                                        <div className={classes.messageViewText} onClick={() => handleMessageViewOpen(index)}>
                                                            <span className={classes.negativeMessagesLinkText} >
                                                                コメント: {_ticket.messages ? _ticket.messages.length : '0'}件
                                                                {openMessageIndex === index ? <ExpandMore /> : <ExpandLess />}
                                                            </span>
                                                        </div>
                                                        {index === openMessageIndex &&
                                                            <>
                                                                <Typography variant="body2" style={{ textAlign: 'center', marginTop: '16px', marginBottom: '8px' }}>
                                                                    業者からのお問合せ
                                                                </Typography>
                                                                <MessagesView ticket={myTicket} handleSendMessage={(text: any) => handleSendMessage(text, _ticket)} />
                                                                <Container maxWidth="sm" style={{ width: '100%', display: 'flex', justifyContent: 'end', padding: '0', flexDirection: 'column', marginBottom: '16px' }}>
                                                                    {_ticket.messages?.reverse()?.map((_message: any) => (
                                                                        <div className={_message.is_sender_by_buyer ? classes.justifyStart : classes.justifyEnd}>
                                                                            <div>
                                                                                <Typography className={classes.messageTime} style={!_message.is_sender_by_buyer ? { textAlign: 'right' } : {}} variant='body2'>{formateDate(_message.created_at)}</Typography>
                                                                                <Typography className={classes.messageText} variant='body2'>{_message.content}</Typography>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </Container>
                                                            </>
                                                        }
                                                    </div>
                                                ))}
                                            </RadioGroup>
                                        </FormControl>
                                    </>
                                    :
                                    <>
                                        {tickets.map((_ticket, index) => (
                                            <div key={index}>
                                                <div key={index} style={{ border: '1px solid black', padding: '8px 8px', maxWidth: '600px', margin: '0 auto', marginBottom: '16px' }}>
                                                    <Typography variant='subtitle2' style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '8px', paddingLeft: '8px' }}>
                                                        <span>
                                                            業者&thinsp;{index + 1}: &nbsp;
                                                            {_ticket.bid_price ? '¥' + formatedYen(_ticket.bid_price) : '入札なし'}
                                                        </span>
                                                        <span className={classes.negativeMessagesLinkText} onClick={() => handleMessageViewOpen(index)}>
                                                            コメント: {_ticket.messages ? _ticket.messages.length : '0'}件
                                                            {openMessageIndex === index ? <ExpandMore /> : <ExpandLess />}
                                                        </span>
                                                    </Typography>
                                                </div>
                                                {index === openMessageIndex &&
                                                    <>
                                                        <Typography variant="body2" style={{ textAlign: 'center', marginTop: '16px', marginBottom: '8px' }}>
                                                            業者からのお問合せ
                                                        </Typography>
                                                        <MessagesView ticket={myTicket} handleSendMessage={(text: any) => handleSendMessage(text, _ticket)} />
                                                        <Container maxWidth="sm" style={{ width: '100%', display: 'flex', justifyContent: 'end', padding: '0', flexDirection: 'column', marginBottom: '16px' }}>
                                                            {_ticket.messages?.reverse()?.map((_message: any) => (
                                                                <div className={_message.is_sender_by_buyer ? classes.justifyStart : classes.justifyEnd}>
                                                                    <div>
                                                                        <Typography className={classes.messageTime} style={!_message.is_sender_by_buyer ? { textAlign: 'right' } : {}} variant='body2'>{formateDate(_message.created_at)}</Typography>
                                                                        <Typography className={classes.messageText} variant='body2'>{_message.content}</Typography>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </Container>
                                                    </>
                                                }
                                            </div>
                                        ))}
                                    </>
                                }
                            </Container>
                        }
                    </>
                }


                <AppraisalProductDetailView product={product} />

                <div style={{ maxWidth: '600px', margin: '40px auto' }}>
                    <Typography variant='body2' style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '8px' }}>商品説明</Typography>
                    <span className={classes.desc_html} dangerouslySetInnerHTML={{ __html: product.description }}></span>
                </div>

                <ButtonView />

                {/* 入札ダイアログ */}
                <BidDialogView
                    product={product}
                    open={upDownDialogOpen}
                    setOpen={setUpDownDialogOpen}
                    handleBid={handleBid}
                    register={register}
                    getValues={getValues}
                    processionStatus={processionStatus}
                    errors={errors}
                    watch={watch}
                    myTicket={myTicket}
                />

                {/* 発送完了連絡 */}
                <ShippedInfoInputView
                    product={product}
                    open={isShippingInputDialogOpen}
                    setOpen={setIsShippingInputDialogOpen}
                    handleSendShippingInfo={handleSubmit(handleSendShippingInfo)}
                    register={register}
                    errors={errors}
                />

                <SelectWinnerDialog />
                <BackdropLoading open={isLoading} />
            </Container >
        );
    } else {
        return (<></>)
    }
}

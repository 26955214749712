import React, { useContext, useState } from 'react'
import { makeStyles, Typography, Container, Button, FormControl, InputLabel, Select, MenuItem, CircularProgress } from '@material-ui/core';
import { db } from '../utils/Firebase';
import { useHistory, withRouter } from 'react-router-dom';
import { AuthContext } from '../contexts/Auth';
import ProductsTable from '../components/admin/ProductsTable';
import { vendors } from '../utils/Common';

const useStyles = makeStyles((theme) => ({
  main: {
    marginTop: theme.spacing(20),
    marginBottom: "60px",
  },
  search_input_wrapper: {
    display: "flex",
    alignItems: "center",
    marginBottom: "60px",
  },
  button: {
    borderRadius: "0",
    border: "1px solid #ff0000",
    color: "#ff0000",
    marginLeft: "20px",
    marginBottom: "20px",
  },
  profile_title: {
    marginBottom: "10px",
  },
  table_title: {
    fontSize: "18px",
    marginBottom: "24px",
  },
  select: {
    width: "130px",
  },
  circular_progress: {
    position: "relative",
    left: "50%",
  },
  signin_container: {
    marginTop: "180px",
    display: "flex",
    justifyContent: "center",
  },
  signin_button: {
    color: "#ff0000",
  },
  selects: {
    display: "flex",
  },
  select_wrapper: {
    marginRight: "20px",
  },
}))

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 350,
    },
  },
}


const Products = () => {
  const classes = useStyles();
  const history = useHistory()
  const [products, setProducts] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  // @ts-expect-error TS(2339): Property 'teaserEmail' does not exist on type '{}'... Remove this comment to see the full error message
  const { teaserEmail } = useContext(AuthContext)
  const [selectStates, setSelectStates] = useState({ vendor: "", product_type: "", status: "" })

  // 商品検索
  const handleProductSeach = async () => {
    let _products: any = []
    setIsLoading(true)


    let query = db.collection('products')
    if (selectStates.vendor && selectStates.vendor !== '指定なし') {
      // @ts-expect-error TS(2739): Type 'Query<DocumentData>' is missing the followin... Remove this comment to see the full error message
      query = query.where('vendor', '==', selectStates.vendor)
    }
    if (selectStates.product_type && selectStates.product_type !== '指定なし') {
      // @ts-expect-error TS(2322): Type 'Query<DocumentData>' is not assignable to ty... Remove this comment to see the full error message
      query = query.where('product_type', '==', selectStates.product_type)
    }
    if (selectStates.status && selectStates.status !== '指定なし') {
      let _status = ""
      switch (selectStates.status) {
        case 'Dropp終了':
          _status = "archived"
          break
        case '本番適用Fix待ち':
          _status = "waitingFix"
          break
        case '本番適用':
          _status = "active"
          break
        case 'テスト適用':
          _status = "testing"
          break
        case 'クリエイティブ修正済み':
          _status = "completeCreative"
          break;
        case '下書き完了':
          _status = "completeDraft"
          break;
        case '下書き':
          _status = "draft"
          break;
      }
      // @ts-expect-error TS(2322): Type 'Query<DocumentData>' is not assignable to ty... Remove this comment to see the full error message
      query = query.where('draft_status', '==', _status)
    }

    await query.get().then(snapshot => {
      snapshot.forEach(doc => {
        let product = doc.data()
        if (product.product_type === '買取') return
        product['id'] = doc.id
        if (teaserEmail.vendor === 'Dropp' || product.vendor === teaserEmail.vendor) { // Dropp以外は自分の商品だけ
          _products.push(product)
        }
      })
      setProducts(_products)
    })
    setIsLoading(false)
  }

  // 検索条件を変更
  const handleSelectStatesChange = (e: any) => {
    const { name, value } = e.target
    setSelectStates({ ...selectStates, [name]: value })
  }

  return (
    <Container className={classes.main} maxWidth="xl">
      {/* 一般ユーザー */}
      {teaserEmail && !teaserEmail.vendor && <></>}
      {/* 出品者 */}
      {teaserEmail && teaserEmail.vendor &&
        <>
          <div className={classes.search_input_wrapper}>
            <div className={classes.selects}>
              {teaserEmail.vendor === 'Dropp' &&
                <>
                  <FormControl className={classes.select_wrapper}>
                    <InputLabel id="product_vendor_select_label">出品者</InputLabel>
                    <Select
                      className={classes.select}
                      labelId='product_vendor_select_label'
                      id='product_vendor_select'
                      value={selectStates.vendor}
                      name="vendor"
                      onChange={handleSelectStatesChange}
                      renderValue={(selected) => selected}
                      MenuProps={MenuProps}
                    >
                      <MenuItem key="指定ない" value="指定なし">指定なし</MenuItem>
                      {vendors.map(vendor => (
                        <MenuItem key={vendor} value={vendor}>{vendor}</MenuItem>
                      ))}
                    </Select>
                  </FormControl><br />
                </>
              }
              <FormControl className={classes.select_wrapper}>
                <InputLabel id="product_type_label">販売方法</InputLabel>
                <Select
                  className={classes.select}
                  labelId='product_type_label'
                  id='product_type'
                  name="product_type"
                  value={selectStates.product_type}
                  onChange={handleSelectStatesChange}
                  renderValue={(selected) => selected}
                  MenuProps={MenuProps}
                >
                  <MenuItem key="指定ない" value="指定なし">指定なし</MenuItem>
                  <MenuItem key="抽選" value="抽選">抽選</MenuItem>
                  <MenuItem key="オークション" value="オークション">オークション</MenuItem>
                  <MenuItem key="先着" value="先着">先着</MenuItem>
                </Select>
              </FormControl><br />
              <FormControl className={classes.select_wrapper}>
                <InputLabel id="product_status_label">商品ステータス</InputLabel>
                <Select
                  className={classes.select}
                  labelId='product_status_label'
                  id='product_status'
                  name="status"
                  value={selectStates.status}
                  onChange={handleSelectStatesChange}
                  renderValue={(selected) => selected}
                  MenuProps={MenuProps}
                >
                  <MenuItem key="draft" value="下書き">下書き</MenuItem>
                  <MenuItem key="completeDraft" value="下書き完了">下書き完了（クリエイティブ依頼）</MenuItem>
                  <MenuItem key="completeCreative" value="クリエイティブ修正済み">クリエイティブ修正済み</MenuItem>
                  <MenuItem key="testing" value="テスト適用">テスト適用</MenuItem>
                  <MenuItem key="active" value="本番適用">本番適用</MenuItem>
                  <MenuItem key="waitingFix" value="本番適用後Fix待ち">本番適用Fix待ち</MenuItem>
                  <MenuItem key="archived" value="Dropp終了">Dropp終了</MenuItem>
                  <MenuItem key="false" value="指定なし">指定なし</MenuItem>
                </Select>
              </FormControl>
            </div>
            <Button className={classes.button} onClick={handleProductSeach}>検索</Button>
            <Button className={classes.button} onClick={() => history.push('/products/new')}>新規作成</Button>
          </div>
          {isLoading ?
            <CircularProgress className={classes.circular_progress} color="primary" />
            :
            <ProductsTable products={products} setProducts={setProducts} />
          }
        </>
      }

      {/* 未ログイン */}
      {
        !teaserEmail &&
        <>
          <div className={classes.signin_container}>
            <Typography variant="subtitle2">
              <Button variant='text' className={classes.signin_button} onClick={() => history.push('/signin')}>こちら</Button>からログインをお願いいたします。
            </Typography>
          </div>
        </>
      }
    </Container >
  )
}

export default withRouter(Products);
import React, { useEffect, useState, memo } from 'react';
import { Container, makeStyles, Toolbar, Typography, Link, Grid, Box, MuiThemeProvider } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import { useParams, withRouter } from 'react-router-dom';
import { motion, useAnimation } from 'framer-motion';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Timer from '../components/auction/Timer';
import { db } from "../utils/Firebase";
import { pageEnterVariants } from '../Animations/variants';
import { TitleView } from '../components/TitleView';
import { blue } from '@material-ui/core/colors';
import ProductImagesView from '../components/common/ProductImagesView';
import { Product } from '../types/product';


const useStyles = makeStyles((theme) => ({
  main_container: {
    marginTop: theme.spacing(9),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(8),
  },
  card: {
    marginTop: theme.spacing(2),
    height: "100%",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    [theme.breakpoints.up('sm')]: {}
  },
  images_wrapper: {
    maxWidth: "350px",
    width: "80%",
    margin: "0 auto",
  },
  item_view: {
    [theme.breakpoints.up('md')]: {
      alignItems: "center",
    }
  },
  item_image_view: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "24px",
  },
  item_info_view: {
    [theme.breakpoints.up('md')]: {
      marginTop: "16px",
    }
  },
  under_line: {
    height: "1px",
    borderTop: ".8px solid grey",
  },
  icon: {
    color: "grey"
  },
  vendor_url: {
    color: '#585858',
  },
  description: {
    width: "100%",
    maxWidth: "1020px",
    position: 'relative',
    overflow: 'hidden',
    fontSize: '0.9rem',
    letterSpacing: '0.1rem',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(3),
  },
  share_icons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: 'center',
    marginTop: "32px",
  },
  share_icon: {
    margin: "8px",
  },
  entry_button_wrap: {
    minHeight: "30px",
    textAlign: 'center',
    width: "100%",
    padding: "0",
  },
  disabled_button: {
    width: "100%",
    lineHeight: "42px",
    height: "42px",
    backgroundColor: 'darkgray',
    color: 'white',
    fontSize: '0.9rem',
    fontWeight: 'bold',
  },
  timer_text: {
    marginBottom: theme.spacing(1),
    fontSize: '0.85rem',
    color: "grey",
    fontWeight: "bold",
    letterSpacing: ".8px",
  },
}));

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#000',
      main: '#000',
      dark: '#000',
      contrastText: '#fff',
    },
  }
});

const DESCRIPTION_DELIMITER_STR = '###fold_below';

type Params = {
  product_id: string
}
const AuctionComingSoonDetail = () => {
  const classes = useStyles();
  const { product_id } = useParams<Params>();
  const [shopLoading, setShopLoading] = useState(true);
  const [productInfo, setProductInfo] = useState<Product | null>(null);

  const controls = useAnimation();

  // オプション金額の一番低い金額がデフォルトの金額とする
  const calculateDefaultPrice = (product: any) => {
    let prices = product.variants.map((variant: any) => Number(variant.price))
    return Math.min(...prices)
  }

  // 基本データの取得
  useEffect(() => {
    let isMounted = true;
    const f = () => {
      try {
        db.collection("products").doc(product_id)
          .get().then(async (doc) => {
            let product = doc.data()
            if (!product) return
            const checkedDescription = checkDescription(product.description)
            // @ts-expect-error TS(2741): Property 'created_at' is missing in type '{ id: an... Remove this comment to see the full error message
            const _productInfo: Product = {
              id: product.id,
              title: product.title,
              subtitle: checkedDescription.subtitle,
              price: calculateDefaultPrice(product),
              images: product.images.map((image: any) => {
                return image.src;
              }),
              deadline_date: product.deadline_date,
              release_date: product.release_date,
              options: product.options,
              description: checkedDescription.product_description,
              updated_at: new Date(product.updated_at),
              product_type: product.product_type,
              vendor: product.vendor,
              vendor_url: checkedDescription.vendor_url,
              variants: product.variants,
              // firestoreではjson文字列に変換されているのでjsonに変換
              payment_variants: product.payment_variants,
              payment_product_id: product.payment_product_id,
              level: product.level,
              is_authorization: product.is_authorization,
              shipping_fee_name: product.shipping_fee_name,
              showcase_date: new Date(product.showcase_date),
              image: product.image,
              owner: product.owner,
              auction_fee: product.auction_fee,
              insurance_fee_name: product.insurance_fee_name,
              hasWinImage: product.hasWinImage,
              winImageUrl: product.winImageUrl,
              current_bid_amount: product.current_bid_amount,
              bid_history: product.bid_history,
              expected_winning_bid_amount: product.expected_winning_bid_amount,
              authorization_limit: product.authorization_limit
            }
            if (isMounted) setProductInfo(_productInfo);
            if (isMounted) setShopLoading(false);

            controls.start("visible");
          })

      } catch (e) {
        console.error(e);
        if (isMounted) setShopLoading(false);
      }
    }
    if (product_id) { f(); }

    return () => {
      isMounted = false;
    }

  }, [product_id]);

  // メイン画像
  const ImageSlideView = (props: any) => {
    const images = props.images ? props.images : [];
    if (images.length >= 1) {
      return (
        <>
          <Slider />
          <div className={classes.images_wrapper}>
            <ProductImagesView images={images} ticketValue="" />
          </div>
        </>
      )
    } else {
      return (<></>)
    }
  };


  // 説明テキスト部分
  const DescriptionView = (props: any) => {
    return (
      <Container className={classes.description}>
        <span dangerouslySetInnerHTML={{ __html: props.htmlString.replace(DESCRIPTION_DELIMITER_STR, "") }}></span>
      </Container>
    )
  }

  const setMeta = () => {
    const headData = document.head.children;
    const title = productInfo ? `${productInfo.title}の詳細ページ` : '商品の詳細ページ';
    const description = productInfo ? `${productInfo.title}への入札はこちらのページから` : 'オークションへの入札はこちらのページから';
    const keywords = productInfo ? `${productInfo.title} オークション Dropp` : 'オークション Dropp';

    for (let i = 0; i < headData.length; i++) {
      const nameVal = headData[i].getAttribute('name');
      if (nameVal !== null) {
        if (nameVal.indexOf('keywords') != -1) {
          headData[i].setAttribute('content', keywords);
        }
        if (nameVal.indexOf('description') != -1) {
          headData[i].setAttribute('content', description);
        }
        // OGP(twitter)の設定
        if (nameVal.indexOf('twitter:title') != -1) {
          headData[i].setAttribute('content', title);
        }
        if (nameVal.indexOf('twitter:description') != -1) {
          headData[i].setAttribute('content', description);
        }
      }
    }
  }

  const TwitterShare = () => {
    const twitterBaseUrl = "https://twitter.com/intent/tweet?url=https://dropp.jp&text=抽選も楽しめる次世代型オークションハウス&hashtags=Dropp";
    window.open(twitterBaseUrl, "_blank")
  }

  const InstagramShare = () => {
    const instagramBaseUrl = "https://www.instagram.com/dropp.jp/?hl=ja";
    window.open(instagramBaseUrl, "_blank")
  }

  const ShareIconView = () => {
    return (
      <div className={classes.share_icons}>
        <Link onClick={TwitterShare}><TwitterIcon style={{ color: blue[500] }} className={classes.share_icon} /></Link>
        <Link onClick={InstagramShare}><InstagramIcon color="secondary" className={classes.share_icon} /></Link>
      </div>
    )
  }

  const checkDescription = (description: any) => {
    let res = [description];
    let res2;
    let subtitle;
    let vendor_url;
    if (res[0].split('###vendor_url').length >= 2) {
      res2 = res[0].split('###vendor_url');
      vendor_url = res2[1].replace(/(<([^>]+)>)/gi, '').trim();
      res[0] = res2[0]
    }
    if (res[0].split('###subtitle').length >= 2) {
      res2 = res[0].split('###subtitle');
      subtitle = res2[1].replace(/(<([^>]+)>)/gi, '');
      res[0] = res2[0]
    }
    const desc = res[0];
    return {
      product_description: desc,
      subtitle: subtitle,
      vendor_url: vendor_url,
    }
  };

  setMeta();

  const BidBottonArea = memo(() => {
    return (
      <Toolbar className={classes.entry_button_wrap}>
        <Typography className={classes.disabled_button} >入札開始までお待ちください。</Typography>
      </Toolbar>
    )
  })

  return (
    <React.Fragment key={product_id}>
      <MuiThemeProvider theme={theme}>
        {!shopLoading &&
          <>
            <motion.div
              initial={{ opacity: 0, scale: 1.05 }}
              variants={pageEnterVariants}
              animate={controls}
              exit={{ opacity: 0, transition: { duration: 0.8 }, scale: 1.05 }}
            >
              <Container className={classes.main_container}>
                <div className={classes.card}>
                  <Grid container className={classes.item_view}>
                    <Grid item xs={12} sm={12} md={7} className={classes.item_image_view}>
                      <ImageSlideView images={productInfo?.images} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={5} className={classes.item_info_view}>
                      <Box display="flex" flexDirection="column" justifyContent="flex-start" pb={2.5}>
                        <TitleView style={{ letterSpacing: "1px", fontSize: "1.1rem", textAlign: "left", margin: "0 0 8px 0", fontWeight: "bold" }} title={productInfo?.title} subtitle={productInfo?.subtitle}></TitleView>
                        {productInfo?.expected_winning_bid_amount && <Typography variant='subtitle2' style={{ color: "grey" }}>予想落札金額 : {productInfo?.expected_winning_bid_amount}</Typography>}
                      </Box>
                      <Box className={classes.under_line}></Box>
                      <Box py={2.5}>
                        <Typography variant="subtitle2" className={classes.timer_text}>入札開始まで</Typography>
                        <Timer
                          variant="subtitle1"
                          styleColor={{ color: "grey" }}
                          endDateTime={new Date().setTime(
                            // @ts-expect-error TS(2531): Object is possibly 'null'.
                            productInfo.release_date.seconds * 1000
                          )}
                        />
                      </Box>
                      <Box className={classes.under_line}></Box>
                      <Box py={2.5} display="flex" flexDirection="column">
                        <div style={{ textAlign: "right" }}>
                          <Typography variant='subtitle2' style={{ color: "grey" }}>スタート価格</Typography>
                          <Typography variant='subtitle2' style={{ fontSize: "1.3rem", letterSpacing: "1px", fontWeight: "bold", }}>¥{String(productInfo?.price).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}</Typography>
                        </div>
                      </Box>
                      <Box className={classes.under_line}></Box>
                      <Box py={4} >
                        <BidBottonArea></BidBottonArea>
                        <ShareIconView />
                      </Box>
                    </Grid>
                  </Grid>
                </div>
                <DescriptionView htmlString={productInfo?.description} />
              </Container>
            </motion.div>
          </>
        }
      </MuiThemeProvider>
    </React.Fragment >
  );
};

export default withRouter(AuctionComingSoonDetail);

import React, { useState, memo, useRef, useContext } from "react";
import { Backdrop, Button, CircularProgress, makeStyles, Modal, MuiThemeProvider, Slide, Typography } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { getCountryName } from "../../utils/Shipping";
import ShippingMethod from "../../redux/shippingMethod/shippingMethod";
import { useDispatch } from "react-redux";
import { inputCheck, resetShippingMethod } from "../../redux/shippingMethod/shippingMethodSlice";
import { db } from "../../utils/Firebase";
import { AuthContext } from "../../contexts/Auth";
import Common from "../../redux/common/commonSlice";
import { useSelector } from "../../redux/rootReducer";

const useStyles = makeStyles(() => ({
  slide_wrapper: {
    marginTop: "50px",
    backgroundColor: "white",
    height: "100vh",
    overflow: "scroll",
    color: "grey",
  },
  input_wrapper: {
    padding: "0px 16px",
  },
  title: {
    fontSize: "1.2rem",
    textAlign: "center",
    marginTop: "50px",
    fontWeight: "bold",
    marginBottom: "50px",
  },
  name_card: {
    marginTop: "18px",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  name_input: {
    marginBottom: "2rem",
    width: "47%",
    "& .MuiOutlinedInput-root": {
      borderRadius: "0",
    },
  },
  zip_card: {
    maxWidth: "100%",
    position: "relative",
  },
  zip_search_wrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  zip_input: {
    minWidth: "60%",
    borderRadius: "0",
    marginBottom: "2rem",
    "& .MuiOutlinedInput-root": {
      borderRadius: "0",
    },
  },
  address_input: {
    borderRadius: "0",
    marginBottom: "2rem",
    width: "100%",
    "& .MuiOutlinedInput-root": {
      borderRadius: "0",
    },
  },
  add_search_botton: {
    lineHeight: "24px",
    marginLeft: "30px",
    borderRadius: "0",
    height: "56px",
    border: ".5px solid black",
    fontWeight: "bold",
    letterSpacing: "1px",
    minWidth: "92px",
  },
  dropdownStyle: {
    height: "250px",
  },
  button_wrapper: {
    margin: "auto",
    marginTop: "10px",
    maxWidth: "400px",
    marginBottom: "200px",
    display: "flex",
    justifyContent: "end",
  },
  active_button: {
    color: "#ff0000",
  },
  negative_button: {
    color: "grey",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#696969',
      main: '#696969',
      dark: '#696969',
      contrastText: '#fff',
    },
  },
  shadows: ["none", '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
  overrides: {
    MuiSelect: {
      select: {
        "&:focus": {
          backgroundColor: "#fff",
        },
      }
    },
  }
});

type Props = {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}
const ShippingMethodInputSlide = memo((props: Props) => {
  const { isOpen, setIsOpen } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const shippingMethod = useSelector((state) => state.shippingMethod)
  const shippingMethodRef = useRef()
  const { teaserEmail, setTeaserEmail } = useContext<any>(AuthContext)

  const handleCancel = () => {
    setIsOpen(false)
    dispatch(resetShippingMethod())
  }

  // 配送先を追加 or 更新
  const [isLoading, setIsLoading] = useState(false)
  const handleSubmit = async () => {
    // 入力の確認
    const checkRes = dispatch(inputCheck({}))
    if (!checkRes.payload.isChecked) return

    setIsLoading(true)
    const newShippingMethod = {
      first_name: shippingMethod.firstName,
      second_name: shippingMethod.secondName,
      first_name_kana: shippingMethod.firstNameKana,
      second_name_kana: shippingMethod.secondNameKana,
      zipcode: shippingMethod.zipcode,
      province: shippingMethod.province,
      province_code: shippingMethod.provinceCode,
      address1: shippingMethod.address1,
      address2: shippingMethod.address2,
      city: shippingMethod.city,
      country_code: shippingMethod.countryCode,
      country: getCountryName(shippingMethod.countryCode),
      phone: shippingMethod.phone,
    }

    let _shipping_methods = teaserEmail.shipping_methods?.length ? teaserEmail.shipping_methods : []
    // 新規追加 or 更新
    if (shippingMethod.selectedShippingMethodsStr === "-1") {
      _shipping_methods.push(newShippingMethod)
    } else {
      _shipping_methods[shippingMethod.selectedShippingMethodsStr] = newShippingMethod
    }

    await db.collection('teaser_emails').doc(teaserEmail.id).update({
      shipping_methods: _shipping_methods
    })
    let newTeaserEmail = teaserEmail
    newTeaserEmail.shipping_methods = _shipping_methods
    setTeaserEmail(newTeaserEmail)
    setIsLoading(false)
    setIsOpen(false)
    dispatch(Common.actions.fetchAlert({
      alerts: [{ message: "配送先住所の登録が完了しました。" }],
    }))
    dispatch(resetShippingMethod())
  }

  return (
    <MuiThemeProvider theme={theme}>
      <Modal open={isOpen} onClose={() => setIsOpen(false)}>
        <Slide direction="up" in={isOpen} mountOnEnter unmountOnExit timeout={550}>
          <div className={classes.slide_wrapper}>
            <div style={{ position: "fixed", top: "80px", left: "30px" }} onClick={() => setIsOpen(false)}>
              <CloseIcon />
            </div>
            <Typography variant="subtitle2" className={classes.title}>配送先住所</Typography>
            <ShippingMethod ref={shippingMethodRef} />
            <div className={classes.button_wrapper}>
              <Button className={classes.negative_button} onClick={handleCancel} >キャンセル</Button>
              <Button className={classes.active_button} onClick={handleSubmit} >保存</Button>
            </div>
          </div>
        </Slide>
      </Modal>
      <Backdrop className={classes.backdrop} open={isLoading} >
        <CircularProgress color='inherit' />
      </Backdrop>
    </MuiThemeProvider>
  )
});
export default ShippingMethodInputSlide;

import { Button, CircularProgress, FormControl, FormHelperText, InputLabel, makeStyles, MenuItem, Select, TextField } from '@material-ui/core'
import React, { forwardRef, useImperativeHandle } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { countries, province_arr } from '../../utils/Shipping'
import { inputChange, inputZipcodeChange, searchAddress, selectProvinceChange, inputPhoneChange, errorMessageChange } from './shippingMethodSlice'

const useStyles = makeStyles(() => ({
  input_wrapper: {
    padding: "0px 16px",
    maxWidth: "400px",
    margin: "0 auto",
  },
  name_card: {
    marginTop: "18px",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  name_input: {
    marginBottom: "2rem",
    width: "47%",
    "& .MuiOutlinedInput-root": {
      borderRadius: "0",
    },
  },
  zip_card: {
    maxWidth: "100%",
    position: "relative",
  },
  zip_search_wrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  zip_input: {
    minWidth: "60%",
    borderRadius: "0",
    marginBottom: "2rem",
    "& .MuiOutlinedInput-root": {
      borderRadius: "0",
    },
  },
  address_input: {
    borderRadius: "0",
    marginBottom: "2rem",
    width: "100%",
    "& .MuiOutlinedInput-root": {
      borderRadius: "0",
    },
  },
  add_search_botton: {
    lineHeight: "24px",
    marginLeft: "30px",
    borderRadius: "0",
    height: "56px",
    border: ".5px solid black",
    fontWeight: "bold",
    letterSpacing: "1px",
    minWidth: "92px",
  },
  dropdownStyle: {
    height: "250px",
  },
  selectRoot: {
    '&:focus': {
      backgroundColor: "white",
    }
  },
  select: {
    '&:after': { //focus
      borderColor: "#000",
    },
    '&:not(.Mui-disabled):hover::before': { //hover
      borderColor: "#000",
    },
  },
}))

const ShippingMethod = forwardRef((_, ref) => {
  // @ts-expect-error TS(2339): Property 'shippingMethod' does not exist on type '... Remove this comment to see the full error message
  const shippingMethod = useSelector((state) => state.shippingMethod)
  const dispatch = useDispatch()
  const classes = useStyles()

  // 親コンポーネントから呼ばれる
  useImperativeHandle(ref, () => ({
    handleInputCheck() {
      // 1.firstName, 2.secondName, 3.zipcode(isZipcode), 4.province, 5.address1, 6.phone(isPhone) の入力は必須
      if (!shippingMethod.firstName || !shippingMethod.secondName || !shippingMethod.isZipcode || !shippingMethod.province || !shippingMethod.address1 || !shippingMethod.isPhone) {
        if (!shippingMethod.firstName) {
          console.log('firstName')
          dispatch(errorMessageChange({ name: "firstNameErrorMessage", value: "入力してください。" }))
        }
        if (!shippingMethod.secondName) {
          dispatch(errorMessageChange({ name: "secondNameErrorMessage", value: "入力してください。" }))
        }
        if (!shippingMethod.isZipcode) {
          dispatch(errorMessageChange({ name: "zipcodeErrorMessage", value: "入力してください。" }))
        }
        if (!shippingMethod.province) {
          dispatch(errorMessageChange({ name: "provinceErrorMessage", value: "選択してください。" }))
        }
        if (!shippingMethod.address1) {
          dispatch(errorMessageChange({ name: "address1ErrorMessage", value: "入力してください。" }))
        }
        if (!shippingMethod.isPhone) {
          dispatch(errorMessageChange({ name: "phoneErrorMessage", value: "電話番号を入力してください。" }))
        }
        return { status: "failed" }
      }
      return { status: "success" }
    }
  }))

  return (
    <div className={classes.input_wrapper}>
      <div>
        <InputLabel id="country-code">国（Country）</InputLabel>
        <Select
          labelId="country-code"
          onChange={(e) => dispatch(inputChange(e))}
          value={shippingMethod.countryCode}
          name="countryCode"
          className={classes.select}
          classes={{ root: classes.selectRoot }}
          MenuProps={{
            classes: {
              paper: classes.dropdownStyle,
            },
          }}
          style={{ width: "100%", height: "40px", marginBottom: "10px" }}
        >
          {countries.map(country => (
            <MenuItem value={country.code} key={country.code}>{country.label} {country.flag}</MenuItem>
          ))}
        </Select>
      </div>
      {shippingMethod.countryCode === 'JP' &&
        <>
          <div className={classes.name_card}>
            <TextField
              className={classes.name_input}
              label="姓"
              name="secondName"
              value={shippingMethod.secondName}
              id="secondName"
              required
              onChange={(e) => dispatch(inputChange(e))}
              error={shippingMethod.secondNameErrorMessage.length > 0}
              helperText={shippingMethod.secondNameErrorMessage}
            />
            <TextField
              className={classes.name_input}
              label="名"
              id="firstName"
              name="firstName"
              required
              value={shippingMethod.firstName}
              onChange={(e) => dispatch(inputChange(e))}
              error={shippingMethod.firstNameErrorMessage.length > 0}
              helperText={shippingMethod.firstNameErrorMessage}
            />
            <TextField
              className={classes.name_input}
              label="セイ"
              name="secondNameKana"
              value={shippingMethod.secondNameKana}
              id="secondNameKana"
              onChange={(e) => dispatch(inputChange(e))}
            />
            <TextField
              className={classes.name_input}
              label="メイ"
              id="firstNameKana"
              name="firstNameKana"
              value={shippingMethod.firstNameKana}
              onChange={(e) => dispatch(inputChange(e))}
            />
          </div>
          <div className={classes.zip_card}>
            <div className={classes.zip_search_wrapper}>
              <TextField
                className={classes.zip_input}
                placeholder="1500001"
                label="郵便番号 (ハイフンなし)"
                id="zipcode"
                required
                value={shippingMethod.zipcode}
                onChange={(e) => dispatch(inputZipcodeChange(e))}
                error={(shippingMethod.zipcodeErrorMessage.length > 0 || shippingMethod.hasSearchAddressError)}
                helperText={shippingMethod.zipcodeErrorMessage || shippingMethod.searchZipcodeErrorMessage}
              />
              <Button
                onClick={() => dispatch(searchAddress())}
                variant="outlined"
                className={classes.add_search_botton}
              >
                {shippingMethod.isAddressSearching ? <><CircularProgress size={24} style={{ color: "black" }} /></> : <>住所検索</>}
              </Button>
            </div>
            <FormControl
              style={{ width: "100%", marginBottom: "2rem" }}
              required
              error={shippingMethod.provinceErrorMessage.length > 0}
            >
              <InputLabel>都道府県</InputLabel>
              <Select
                defaultValue=""
                MenuProps={{
                  classes: {
                    paper: classes.dropdownStyle,
                  },
                }}
                style={{ width: "60%" }}
                value={shippingMethod.province}
                onChange={(e) => dispatch(selectProvinceChange(e))}
              >
                {province_arr.map(_province_name => {
                  return <MenuItem key={_province_name} value={_province_name}>{_province_name}</MenuItem>
                })}
              </Select>
              <FormHelperText style={{ color: "#ff0000" }}>{shippingMethod.provinceErrorMessage}</FormHelperText>
            </FormControl>
            <TextField
              className={classes.address_input}
              label="住所"
              required
              id="address1"
              name="address1"
              value={shippingMethod.address1}
              onChange={(e) => dispatch(inputChange(e))}
              error={shippingMethod.address1ErrorMessage.length > 0}
              helperText={shippingMethod.address1ErrorMessage}
            />
            <TextField
              className={classes.address_input}
              label="マンション名・号室（任意)"
              name="address2"
              value={shippingMethod.address2}
              onChange={(e) => dispatch(inputChange(e))}
            />
            <TextField
              className={classes.address_input}
              label="電話番号（ハイフンなし）"
              name="phone"
              value={shippingMethod.phone}
              onChange={(e) => dispatch(inputPhoneChange(e))}
              required
              helperText={shippingMethod.phoneErrorMessage}
              error={shippingMethod.phoneErrorMessage.length > 0}
            />
          </div>
        </>
      }
      {shippingMethod.countryCode !== 'JP' &&
        <>
          <div className={classes.name_card}>
            <TextField
              className={classes.name_input}
              label="Second Name"
              name="secondName"
              value={shippingMethod.secondName}
              id="secondName"
              onChange={(e) => dispatch(inputChange(e))}
              error={shippingMethod.secondNameErrorMessage.length > 0}
              helperText={shippingMethod.secondNameErrorMessage}
              required
            />
            <TextField
              className={classes.name_input}
              label="First Name"
              id="firstName"
              name="firstName"
              value={shippingMethod.firstName}
              onChange={(e) => dispatch(inputChange(e))}
              error={shippingMethod.firstNameErrorMessage.length > 0}
              helperText={shippingMethod.firstNameErrorMessage}
              required
            />
          </div>
          <div className={classes.zip_card}>
            <div className={classes.zip_search_wrapper}>
              <TextField
                className={classes.zip_input}
                placeholder="1500001"
                label="Zipcode"
                id="zipcode"
                value={shippingMethod.zipcode}
                onChange={(e) => dispatch(inputZipcodeChange(e))}
                error={shippingMethod.zipcodeErrorMessage.length > 0}
                helperText={shippingMethod.zipcodeErrorMessage}
                required
              />
            </div>
            <TextField
              className={classes.address_input}
              label="Address Line 1"
              id="address1"
              name="address1"
              error={shippingMethod.address1ErrorMessage.length > 0}
              value={shippingMethod.address1}
              onChange={(e) => dispatch(inputChange(e))}
              helperText={shippingMethod.address1ErrorMessage}
              required
            />
            <TextField
              className={classes.address_input}
              label="Address Line 2"
              name="address2"
              value={shippingMethod.address2}
              onChange={(e) => dispatch(inputChange(e))}
            />
            <TextField
              className={classes.address_input}
              label="City"
              id="city"
              name="city"
              error={shippingMethod.cityErrorMessage.length > 0}
              value={shippingMethod.city}
              onChange={(e) => dispatch(inputChange(e))}
              helperText={shippingMethod.address1ErrorMessage}
              required
            />
            <TextField
              className={classes.address_input}
              label="State, Province or Region"
              name="province"
              error={shippingMethod.provinceErrorMessage.length > 0}
              value={shippingMethod.province}
              onChange={(e) => dispatch(inputChange(e))}
              required
            />
            <TextField
              className={classes.address_input}
              label="Phone Number"
              name="phone"
              value={shippingMethod.phone}
              error={shippingMethod.phoneErrorMessage.length > 0}
              helperText={shippingMethod.phoneErrorMessage}
              onChange={(e) => dispatch(inputPhoneChange(e))}
              required
            />
          </div>
        </>
      }
    </div>
  )
})

export default ShippingMethod
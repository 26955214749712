import { Button, CircularProgress, makeStyles, TextField, Typography } from '@material-ui/core';
import { useState } from 'react'
import { formatedYen } from '../utils/Common';
import { db, CloudFunctions } from "../utils/Firebase";
import { notifySlackDroppOperation } from '../utils/Notify';
import BackdropLoading from "../components/common/BackdropLoading"
import { Product } from '../types/product';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch } from 'react-redux';
import Common from '../redux/common/commonSlice';
// import { getShippingFee } from '../utils/Shipping';

const useStyles = makeStyles((theme) => ({
    tabs: {
        flexGrow: 1,
        marginTop: theme.spacing(8),
        backgroundColor: 'rgba(0,0,0,0.04)',
        color: "black",
        boxShadow: 'none',
    },
    tab: {
        width: '20%'
    },
    container: {
        marginTop: theme.spacing(16),
        maxWidth: '800px',
        margin: 'auto',
    },
    wrapper: {
        border: "1px solid gray",
        padding: "10px",
        margin: "30px 0",
    },
    title: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        textAlign: 'center',
        fontWeight: 'bold',
    },
    mail_wrapper: {
        marginBottom: theme.spacing(3),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    email_text: {
    },
    status_wrapper: {
        marginTop: theme.spacing(1),
        display: 'flex',
        justifyContent: 'space-between',
    },
    status: {
        display: 'flex',
        justifyContent: 'space-between',
        maxWidth: '30%',
    },
    user_delete_area: {
        marginTop: "20px",
        marginBottom: "20px",
    },
    button_wrapper: {
        display: 'flex',
        justifyContent: 'around',
    },
    datetime: {
        width: "200px",
        padding: "10px",
        boxSizing: "border-box",
        textAlign: "center",
    },
    update_buttons: {
        margin: "10px",
    },
    auth_button: {
    },
    fail_button: {
        marginLeft: theme.spacing(1),
    },
    app_button: {
        marginLeft: theme.spacing(1),
    }
}))

const _choiceDrawing = (tickets: any, num: any) => {
    if (num == 0) {
        return [[], tickets]
    }
    if (tickets.length <= num) {
        return [tickets, []];
    }
    tickets.sort(() => Math.random() - Math.random());
    const win = tickets.slice(0, num);
    const lose = tickets.slice(win.length);
    return [win, lose];
};

const _filterTicket = async (tikects: any, variant: any) => {
    const _targetTickets = [];
    for (const tikect of tikects) {
        let flg = true;
        for (const i in tikect.variation) {
            if (!flg) break;
            flg = (variant[`option${i}`] == tikect.variation[parseInt(i)]);
        }
        if (flg) {
            _targetTickets.push(tikect);
        }
    }
    return [_targetTickets];
};

const AdminEmails = () => {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()
    const [productNumber, setProductNumber] = useState("");
    const handleChangeProductNumber = (e: any) => {
        setLotteryStates({ ...lotteryStates, winTickets: [], loseTickets: [], entryTickets: [], updatedWinCount: 0, updatedLoseCount: 0 })
        setProductNumber(e.target.value)
    }

    const [lotteryStates, setLotteryStates] = useState({
        entryTickets: [],
        winTickets: [],
        loseTickets: [],
        updatedWinCount: 0,
        updatedLoseCount: 0,
    })

    const getRaffleProductInfo = async () => {
        let _winTickets: any = []
        let _loseTickets: any = []
        let _unKnownTickets: any = []
        await db.collection('products').doc(productNumber).get().then(doc => {
            const _product = doc.data() as Product
            setProduct(_product)
        })
        await db.collection('tickets').where('product_id', '==', Number(productNumber)).get().then(snapshot => {
            snapshot.forEach(doc => {
                let _tmp = doc.data()
                _tmp["id"] = doc.id
                if (_tmp.status === 2) {
                    console.log('ステータス2: ', _tmp)
                }
                if (!_tmp.lottery_flg) {
                    _unKnownTickets.push(_tmp)
                    _loseTickets.push(_tmp)
                } else if (_tmp.lottery_flg == "win") {
                    if (_tmp.status === 4) {
                        console.log('ステータス4: ', _tmp)
                    } else if (_tmp.status === 2) {
                        // console.log('ステータス2: ', _tmp)
                    }
                    _winTickets.push(_tmp)
                } else if (_tmp.lottery_flg == 'lose') {
                    _loseTickets.push(_tmp)
                }
                if (_tmp.shipping_info && _tmp.shipping_info.country_code !== 'JP' && _tmp.shipping_info.country_code !== undefined) {
                    // console.log('海外発送のエントリー: ', _tmp)
                }
            })
        })
        console.log('_winTickets', _winTickets.length)
        console.log('_loseTickets', _loseTickets.length)
        console.log('_unKnownTickets', _unKnownTickets)
        // @ts-expect-error TS(2322): Type 'any[]' is not assignable to type 'never[]'.
        setLotteryStates({ entryTickets: [..._winTickets, ..._loseTickets, ..._unKnownTickets], winTickets: _winTickets, loseTickets: _loseTickets })
    }

    // 当選チケットをCSVでINFOに送信
    const sendWinnerShippingInfo = async () => {
        setIsLoading(true)
        let sendShippingCsv = CloudFunctions.httpsCallable('sendShippingCsv')
        let res = await sendShippingCsv({ tickets: lotteryStates.winTickets })
        console.log('InfoにCSVを送信', res)
        dispatch(
            Common.actions.fetchAlert({
                alerts: [{ message: "INFOにメールが送信されました。" }],
            })
        )
        setIsLoading(false)
    }

    // マルチプロセスで当選チケットを更新する
    const updateWinTickets = async () => {
        const asyncFuncArr = []
        const processNum = 10 // 10個までプロセスを回す
        const stripeCaptureAuthorization = CloudFunctions.httpsCallable("stripeCaptureAuthorization");

        const baseFunc = async (num: number) => {
            let count = 0
            for (const _win of lotteryStates.winTickets) {
                if (count % processNum === num) {
                    try {
                        // @ts-ignore
                        if (_win.payment_intent?.id) {
                            // @ts-ignore
                            await db.collection('tickets').doc(_win.id).update({ lottery_flg: "win", authorization_status: "succeeded", status: 2 })
                            // @ts-ignore
                            await stripeCaptureAuthorization({ payment_intent_id: _win.payment_intent.id })
                        } else { // オーソリがない場合はWaitingPaymentにする
                            // @ts-ignore
                            await db.collection('tickets').doc(_win.id).update({ lottery_flg: "win", authorization_status: "succeeded", status: 4 })
                        }
                    } catch (e) {
                        console.error(e)
                    }
                }
                count++
            }
        }

        for (let i = 0; i < processNum; i++) {
            asyncFuncArr.push(baseFunc(i))
        }

        setIsLoading(true)
        try {
            await Promise.all(asyncFuncArr);
            dispatch(
                Common.actions.fetchAlert({
                    alerts: [{ message: "当選チケットの更新が完了しました。" }],
                })
            )
        } catch (error) {
            console.error(`Error: `, error)
        }
        setIsLoading(false)
    }

    // マルチプロセスで落選チケットを更新する
    const updateLoseTickets = async () => {
        const asyncFuncArr = []
        const processNum = 10 // 10個までプロセスを回す
        const stripeCancelAuthorization = CloudFunctions.httpsCallable("stripeCancelAuthorization");

        const baseFunc = async (num: number) => {
            let count = 0
            for (const _lose of lotteryStates.loseTickets) {
                if (count % processNum === num) {
                    try {
                        // @ts-ignore
                        await db.collection('tickets').doc(_lose.id).update({ lottery_flg: "lose", authorization_status: "canceled", status: 0 })
                        // @ts-ignore
                        if (_lose.payment_intent?.id) {
                            // @ts-ignore
                            await stripeCancelAuthorization({ payment_intent_id: _lose.payment_intent.id })
                        }
                    } catch (e) {
                        console.error(e)
                    }
                }
                count++
            }
        }

        for (let i = 0; i < processNum; i++) {
            asyncFuncArr.push(baseFunc(i))
        }

        setIsLoading(true)
        try {
            await Promise.all(asyncFuncArr);
            dispatch(
                Common.actions.fetchAlert({
                    alerts: [{ message: "落選チケットの更新が完了しました。" }],
                })
            )
        } catch (error) {
            console.error(`Error: `, error)
        }
        setIsLoading(false)
    }

    const getAllUserEmailCsv = async () => {
        setIsLoading(true)
        let func = CloudFunctions.httpsCallable('testSendAllUsersEmailCsv')
        let _emails: any = []
        await db.collection('teaser_emails').get().then(snapshot => {
            console.log("snapshot size", snapshot.size)
            snapshot.forEach(doc => {
                if (doc.data().status == 2) {
                    _emails.push(doc.data().email)
                }
            })
        }).catch(e => {
            console.error('e', e)
            setIsLoading(false)
            return
        })
        console.log('ユーザー数', _emails.length)
        try {
            await func({ emails: _emails })
            console.log('メール送信が完了しました。')
        } catch (e) {
            console.log('送信に失敗しました。 testSendAllUserEmailsCsvを確認してください。', e)
        }
        setIsLoading(false)
    }

    const [product, setProduct] = useState<Product | null>(null)
    const [reminderProductId, setReminderProductId] = useState("")
    const [reminderProduct, setReminderProduct] = useState("")
    const [remiderSize, setRemiderSize] = useState(0)
    const [sendStatus, setSendStatus] = useState("")
    const sendReminder = async () => {
        const func = CloudFunctions.httpsCallable('sendReminder')
        const res = await func({ product_id: Number(reminderProductId) })
        console.log('res', res)
        setSendStatus(res.data.message)
    }
    const getReminderInfo = async () => {
        await db.collection('products').doc(reminderProductId).get().then(doc => {
            // @ts-expect-error TS(2345): Argument of type 'DocumentData | undefined' is not... Remove this comment to see the full error message
            setReminderProduct(doc.data())
        })
        await db.collection('reminders').where('product_id', '==', Number(reminderProductId)).get().then(snapshot => {
            setRemiderSize(snapshot.size)
            snapshot.forEach(doc => {
                console.log(doc.data().email)
            })
        })
    }
    const handleChangeReminderId = (e: any) => {
        setReminderProductId(e.target.value)
    }

    const [lotteryProductNumber, setLotteryProducctNumber] = useState("")
    const handleChangeLotteryProductNumber = (e: any) => {
        setLotteryProducctNumber(e.target.value)
    }

    // 手動で抽選を行う
    const [onLottery, setOnLottery] = useState(false)
    const handleLottery = async () => {
        setOnLottery(true)
        let slackNoticeText = ""
        // 対象商品を取得
        let targetProduct = null
        await db.collection('products').doc(lotteryProductNumber.toString()).get().then(doc => {
            targetProduct = doc.data()
            // @ts-expect-error TS(2532): Object is possibly 'undefined'.
            targetProduct['id'] = doc.id
            // @ts-expect-error TS(2532): Object is possibly 'undefined'.
            console.log('対象商品', doc.data().title)
            // @ts-expect-error TS(2532): Object is possibly 'undefined'.
            slackNoticeText += `抽選が終了しました。\n商品名: ${doc.data().title}\n ID: ${doc.id}\n 出品者: ${doc.data().vendor}\n`
        })

        // 対象チケットを取得
        let validTickets: any = []
        await db.collection('tickets').where('product_id', '==', Number(lotteryProductNumber)).get().then(snapshot => {
            console.log('対象エントリー数: ', snapshot.size)
            slackNoticeText += `合計エントリー数: ${snapshot.size}\n`
            snapshot.forEach(doc => {
                validTickets.push(doc.data())
            })
        })

        // オプションごとに抽選
        let allWinTickets = []
        let allLoseTickets = []
        // @ts-expect-error TS(2531): Object is possibly 'null'.
        for (const variant of targetProduct.variants) {
            // チケットの選定
            let targetTickets = [];
            let winTickets = [];
            let loseTickets = [];
            [targetTickets] = await _filterTicket(validTickets, variant);

            // @ts-expect-error TS(2531): Object is possibly 'null'.
            if (targetProduct.product_type == "抽選") {
                [winTickets, loseTickets] = _choiceDrawing(
                    targetTickets,
                    variant.inventory_quantity,
                );
            }
            allWinTickets.push(...winTickets)
            allLoseTickets.push(...loseTickets)
            if (variant['option1'] !== 'Default Title') {
                slackNoticeText += `\n\nオプション: ${variant['option1']}\n`
            }
            slackNoticeText += `価格: ¥${formatedYen(variant.price)}\n 当選者数: ${winTickets.length}\n 落選者数: ${loseTickets.length}\nGMV ¥${formatedYen(Number(variant.price) * winTickets.length)}`
            console.log("オプション: ", variant['option1'], targetTickets.length);
            console.log("当選者数: ", winTickets.length, "落選者数: ", loseTickets.length);
        }
        console.log('allWinTiciet', allWinTickets.length)
        // @ts-expect-error TS(2322): Type 'any[]' is not assignable to type 'never[]'.
        setLotteryStates({ entryTickets: [], winTickets: allWinTickets, loseTickets: allLoseTickets })
        setOnLottery(false)
        await notifySlackDroppOperation(slackNoticeText)
    }

    // SendGrid用のメールマガジンのHTMLをInfoにメールする
    const [startDate, setStartDate] = useState(new Date())
    const getHtmlEmail = async () => {
        setIsLoading(true)
        const date = startDate
        const targetDate = new Date(date.getFullYear(), date.getMonth(), date.getDate())
        const targetProducts: any = []
        await db.collection("products")
            .where('release_date', '>', targetDate)
            .where('is_production', '==', true)
            .where('close', '==', false)
            .get().then(snapshot => {
                snapshot.forEach(doc => {
                    const _product = doc.data()
                    console.log('targetProduct', _product.title)
                    targetProducts.push(_product)
                })
            })
        const func = CloudFunctions.httpsCallable('sendTodayMailMagazine')
        const res = await func({ targetProducts })
        console.log('res', res)
        setIsLoading(false)
        dispatch(
            Common.actions.fetchAlert({
                alerts: [{ message: "info@にメルマガHTMLを送信しました。" }],
            })
        );
    }

    return (
        <div className={classes.container}>
            <div className={classes.wrapper}>
                <Typography variant='subtitle2' style={{ marginBottom: "10px" }}>抽選結果の更新</Typography>
                <TextField onChange={handleChangeProductNumber} label="商品ID" /><br></br><br></br>
                {lotteryStates.entryTickets.length > 0 &&
                    <Typography variant="subtitle2" style={{ marginBottom: "10px" }}>
                        商品名: {product?.title} <br></br>
                        出品者: {product?.vendor} <br></br>
                        エントリー数: {lotteryStates.entryTickets.length} <br></br>
                        当選者数: {lotteryStates.winTickets.length}   落選者数: {lotteryStates.loseTickets.length}
                    </Typography>
                }
                {lotteryStates.updatedWinCount > 0 && <Typography variant="subtitle2">{lotteryStates.updatedWinCount}の当選オーソリ確定とステータス更新を完了</Typography>}
                {lotteryStates.updatedLoseCount > 0 && <Typography variant="subtitle2">{lotteryStates.updatedLoseCount}の落選オーソリキャンセルとステータス更新を完了</Typography>}
                <button style={{ height: "48px", marginLeft: "15px" }} onClick={getRaffleProductInfo}>抽選情報を取得する</button>
                <button style={{ height: "48px", marginLeft: "15px" }} onClick={updateWinTickets}>当選者の決済を確定する</button>
                <button style={{ height: "48px", marginLeft: "15px" }} onClick={updateLoseTickets}>落選者の決済をキャンセルする</button><br /><br />
                <button style={{ height: "48px", marginLeft: "15px" }} onClick={sendWinnerShippingInfo}>当選者の配送情報CSVをinfoに送信する</button><br /><br />
            </div>

            <div className={classes.wrapper}>
                <Typography variant='subtitle2' style={{ marginBottom: "10px" }}>再抽選</Typography>
                <TextField onChange={handleChangeLotteryProductNumber} /><br /><br />
                <Button onClick={handleLottery} disabled={!lotteryProductNumber} variant="outlined">再抽選する</Button>
            </div>

            <button onClick={getAllUserEmailCsv}>全ユーザーのEメールアドレスをCSVをinfoにメールする</button><br></br><br></br>

            <div className={classes.wrapper}>
                <Typography variant='subtitle2' style={{ marginBottom: "10px" }}>SENDGRID</Typography>
                <Typography variant='subtitle2' style={{ marginBottom: "4px", fontSize: '12px' }}>告知開始日</Typography>
                <DatePicker
                    className={classes.datetime}
                    dateFormat="yyyy年MM月dd日 HH:mm:ss"
                    showTimeSelect={true}
                    selected={startDate}
                    onChange={(e: any) => setStartDate(new Date(e))}
                /><br /><br /><br />
                <Button onClick={getHtmlEmail} variant="outlined">HTMLメールを取得する</Button>
            </div>

            <div className={classes.wrapper}>
                <TextField onChange={handleChangeReminderId} value={reminderProductId} label="リマインダー商品ID" /><br></br><br></br>
                {/* @ts-expect-error */}
                {reminderProduct.title && <Typography variant="subtitle2" style={{ marginTop: "8px" }}>商品名: {reminderProduct.title} </Typography>}
                {remiderSize > 0 && <><Typography variant="subtitle2" style={{ marginTop: "8px" }}>リマインダー数: {remiderSize}人 </Typography><br></br></>}
                <button disabled={!reminderProductId} onClick={getReminderInfo}>リマインダー情報を取得する</button>
                <button style={{ marginLeft: "8px" }} disabled={!reminderProductId} onClick={sendReminder}>リマインダーメールを送信する</button>
                <Typography variant="subtitle2">{sendStatus}</Typography>
            </div>

            {onLottery && <CircularProgress />}
            <BackdropLoading open={isLoading} />
        </div>
    )

}

export default AdminEmails;
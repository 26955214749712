import React, { memo, useContext, useEffect, useState } from 'react';
import { Container, Drawer, IconButton, makeStyles, Typography, ThemeProvider, Divider, Dialog, DialogActions, Button, Theme, createStyles } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core';
import { ShoppingCart } from '@material-ui/icons';
import { CartContext } from '../contexts/Cart';
import { useHistory } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import CloseIcon from '@material-ui/icons/Close';
import { formatedYen } from '../utils/Common';

const useStyles = makeStyles((theme: Theme) => createStyles({
  drawer_paper: {
    width: '90%',
    maxWidth: "500px",
    paddingTop: 'env(safe-area-inset-top)',
  },
  container: {
    overflow: "hidden",
    padding: "0 12px",
  },
  content_wrapper: {
    width: "100%",
    overflow: "hidden",
  },
  open_icon: {
    position: "absolute",
    right: "20px",
    [theme.breakpoints.up('md')]: {
      right: "80px",
    }
  },
  close_button: {
    marginLeft: '0',
    marginTop: theme.spacing(1),
  },
  cart_title: {
    marginTop: "10px",
    marginBottom: "6px",
    fontWeight: "bold",
  },
  cart_items: {
    padding: "8px 0",
    margin: "0 auto",
    height: "100vh",
    overflow: "scroll",
  },
  item_wrapper: {
    width: "100%",
    display: "flex",
    borderBottom: "1px solid gray",
    padding: "16px 0",
    "&:last-child": {
      borderBottom: "none",
    }
  },
  item_image_wrapper: {
    width: "33%",
    boxSizing: "border-box",
    padding: "8px",
    display: "flex",
    alignItems: "center",
  },
  item_image: {
    width: "100%",
  },
  item_info_wrapper: {
    boxSizing: "border-box",
    width: "70%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  item_title: {
    fontWeight: "bold",
  },
  delete_button_wrapper: {
    padding: "0 6px",
  },
  payment_button_wrapper: {
    backgroundColor: "white",
    zIndex: 100,
    position: "absolute",
    bottom: "0px",
    width: "95%",
    padding: "0px 6px 18px",
    boxSizing: "border-box",
  },
  sub_total: {
    textAlign: "right",
    fontWeight: "bold",
    margin: "0",
    padding: "10px 0",
    borderTop: "1px solid gray",
  },
  no_item_text: {
    marginTop: "30px",
    color: "gray",
    textAlign: "center",
  },
  quantity_wrapper: {
    marginTop: "8px",
    height: "40px",
    display: "flex",
    alignItems: "center",
    fontSize: ".9rem",
  },
  quantity_text: {},
  quantity_button_wrapper: {
    border: "1px solid black",
    lineHeight: "40px",
    marginLeft: "1rem",
    verticalAlign: "center",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    width: "100px",
  },
  add_icon: {
    padding: "0 6px",
    "&:hover": {
      cursor: "pointer"
    },
  },
  remove_icon: {
    padding: "0 6px",
    "&:hover": {
      cursor: "pointer"
    },
  },
  payment_button: {
    width: "100%",
    backgroundColor: "#ff0000",
    color: "white",
    textAlign: "center",
    fontWeight: "bold",
    height: "35px",
    lineHeight: "35px",
    '&:hover': {
      cursor: "pointer",
      opacity: "0.6",
    }
  },
  remove_dialog: {
    padding: "22px 10px 10px 10px",
  },
  delete_text: {
    fontWeight: "bold",
    marginBottom: "20px",
  },
  remove_button_wrapper: {
    display: "flex",
    justifyContent: "center",
  },
  cancel_botton: {
    margin: "8px",
  },
  confirm_button: {
    color: "#ff0000",
    margin: "8px",
  },
}));

const theme = createMuiTheme({})

const Cart = memo(() => {
  const classes = useStyles();
  const history = useHistory()
  const { isCartOpen, setIsCartOpen, myCart, addCartItem, removeCartItem, deleteCartItem } = useContext<any>(CartContext)
  const [subTotal, setSubTotal] = useState(0)

  useEffect(() => {
    if (!isCartOpen) return
    setTimeout(() => {
      let header = document.getElementById('cart_header')
      let footer = document.getElementById('cart_footer')
      let cart_content = document.getElementById('cart_content')
      if (!header || !footer || !cart_content) return
      let header_height = header.clientHeight
      let footer_height = footer.clientHeight
      cart_content.style.height = (cart_content.clientHeight - header_height - footer_height - 40) + 'px'
    }, 100)
  }, [isCartOpen])

  // カート内商品の小計金額
  useEffect(() => {
    let _subTotal = 0
    let isMounted = true
    if (!myCart || myCart.items.length === 0) return
    myCart.items.forEach((item: any) => {
      _subTotal += (item.variant.price * item.quantity)
    })
    if (isMounted) {
      setSubTotal(_subTotal)
    }
    return (() => {
      isMounted = false
    })
  }, [myCart])

  const toggleDrawer = (state: any, e: any) => {
    e.stopPropagation();
    setIsCartOpen(state);
  };

  const handlePayment = (e: any) => {
    e.stopPropagation();
    if (!myCart || myCart.items.length === 0) return;
    setIsCartOpen(false)
    history.push('/cart/payment')
  }

  const [isDisabled, setIsDisabled] = useState(false)
  const [deleteItem, setDeleteItem] = useState(null)
  const handleAddQuantity = async (_item: any) => {
    if (isDisabled) return
    setIsDisabled(true)
    setTimeout(async () => {
      await addCartItem(_item)
      setTimeout(() => {
        setIsDisabled(false)
      }, 300)
    }, 700)
  }
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false)
  const handleRemoveQuantity = async (_item: any) => {
    if (isDisabled) return
    if (_item.quantity === 1) {
      handleDelteItemDialogOpen(_item)
      return
    }
    setIsDisabled(true)
    setTimeout(async () => {
      await removeCartItem(_item)
      setTimeout(() => {
        setIsDisabled(false)
      }, 300)
    }, 700)
  }

  const handleDelteItemDialogOpen = (_item: any) => {
    setRemoveDialogOpen(true)
    setDeleteItem(_item)
  }

  const handleDeleteMyCart = async () => {
    if (!deleteItem) return
    await deleteCartItem(deleteItem)
    setRemoveDialogOpen(false)
  }

  const RemoveDialog = () => {
    return (
      <Dialog
        open={removeDialogOpen}
        onClose={() => setRemoveDialogOpen(false)}
      >
        <div className={classes.remove_dialog}>
          <Typography variant="subtitle1" className={classes.delete_text}>こちら商品をカート内から削除しますか？</Typography>
          <DialogActions className={classes.remove_button_wrapper}>
            <Button onClick={() => setRemoveDialogOpen(false)} className={classes.cancel_botton}>いいえ</Button>
            <Button onClick={handleDeleteMyCart} className={classes.confirm_button}>はい</Button>
          </DialogActions>
        </div>
      </Dialog>
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <IconButton
        className={classes.open_icon}
        edge='start'
        onClick={(e) => toggleDrawer(true, e)}>
        <ShoppingCart />
        <Drawer
          anchor='right'
          classes={{ paper: classes.drawer_paper }}
          open={isCartOpen}
          onClose={(e) => toggleDrawer(false, e)}
        >
          <Container maxWidth="sm" className={classes.container}>
            <div className={classes.content_wrapper}>
              <div id="cart_header">
                <IconButton
                  edge='start'
                  onClick={(e) => toggleDrawer(false, e)}
                  className={classes.close_button}>
                  <CloseIcon />
                </IconButton>
                <Typography variant="subtitle1" className={classes.cart_title}>カートに入っている商品</Typography>
                <Divider />
              </div>
              <div id="cart_content" className={classes.cart_items}>
                {myCart?.items?.length > 0 ?
                  <>
                    {myCart.items.map((item: any) => <div key={item.variant.id} className={classes.item_wrapper}>
                      <div className={classes.item_image_wrapper}>
                        <img className={classes.item_image} src={item.productInfo.images[0]} />
                      </div>
                      <div className={classes.item_info_wrapper}>
                        <Typography variant="subtitle2" className={classes.item_title}>{item.productInfo.title}</Typography>
                        {item.variant.title !== 'Default Title' && <Typography variant="subtitle2">{item.variant.title}</Typography>}
                        <Typography variant="subtitle2">{formatedYen(item.variant.price)}円 (税込)</Typography>
                        <div className={classes.quantity_wrapper}>
                          <span className={classes.quantity_text}>数量:</span>
                          <div className={classes.quantity_button_wrapper}>
                            <AddIcon onClick={() => handleAddQuantity(item)} className={classes.add_icon} style={{ fontSize: "16" }} />
                            {item.quantity}
                            <RemoveIcon className={classes.remove_icon} style={{ fontSize: "16" }} onClick={() => handleRemoveQuantity(item)} />
                          </div>
                        </div>
                      </div>
                      <div className={classes.delete_button_wrapper}>
                        <CloseIcon onClick={() => handleDelteItemDialogOpen(item)} />
                      </div>
                    </div>)}
                  </>
                  :
                  <Typography variant="body2" className={classes.no_item_text}>現在カートに商品はありません。</Typography>
                }
              </div>
            </div>
            <div id="cart_footer" className={classes.payment_button_wrapper}>
              {myCart &&
                <>
                  <Typography variant="subtitle2" className={classes.sub_total}>商品合計： ¥{formatedYen(subTotal)} (税込)</Typography>
                  <Typography variant='subtitle2' className={classes.payment_button} onClick={handlePayment}>
                    購入手続きへ
                  </Typography>
                </>
              }
            </div>
            <RemoveDialog />
          </Container>
        </Drawer>
      </IconButton >
    </ThemeProvider>
  );
});

export default Cart;
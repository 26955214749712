import { makeStyles, Typography } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles((theme) => ({
    link_wrapper: {
        paddingBottom: theme.spacing(2),
        width: '100%',
    },
    copyrights: {
        textAlign: 'center',
        fontSize: '11px',
        lineHeight: '1.2rem',
        margin: '5px',
        color: "grey",
    }
}));

const Copyright = () => {
    const classes = useStyles();
    return (
        <div className={classes.link_wrapper}>
            <Typography className={classes.copyrights} variant="subtitle2">
                COPYRIGHT © DROPP INC.<br />ALL RIGHTS RESERVED.
            </Typography>
        </div>
    )
}

export default Copyright

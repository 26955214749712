export const provinces = {
  "北海道": "JP-01",
  "青森県": "JP-02",
  "岩手県": "JP-03",
  "宮城県": "JP-04",
  "秋田県": "JP-05",
  "山形県": "JP-06",
  "福島県": "JP-07",
  "茨城県": "JP-08",
  "栃木県": "JP-09",
  "群馬県": "JP-10",
  "埼玉県": "JP-11",
  "千葉県": "JP-12",
  "東京都": "JP-13",
  "神奈川県": "JP-14",
  "新潟県": "JP-15",
  "富山県": "JP-16",
  "石川県": "JP-17",
  "福井県": "JP-18",
  "山梨県": "JP-19",
  "長野県": "JP-20",
  "岐阜県": "JP-21",
  "静岡県": "JP-22",
  "愛知県": "JP-23",
  "三重県": "JP-24",
  "滋賀県": "JP-25",
  "京都府": "JP-26",
  "大阪府": "JP-27",
  "兵庫県": "JP-28",
  "奈良県": "JP-29",
  "和歌山県": "JP-30",
  "鳥取県": "JP-31",
  "島根県": "JP-32",
  "岡山県": "JP-33",
  "広島県": "JP-34",
  "山口県": "JP-35",
  "徳島県": "JP-36",
  "香川県": "JP-37",
  "愛媛県": "JP-38",
  "高知県": "JP-39",
  "福岡県": "JP-40",
  "佐賀県": "JP-41",
  "長崎県": "JP-42",
  "熊本県": "JP-43",
  "大分県": "JP-44",
  "宮崎県": "JP-45",
  "鹿児島県": "JP-46",
  "沖縄県": "JP-47",
}

export const provinces_from_code = {
  "JP-01": "北海道",
  "JP-02": "青森県",
  "JP-03": "岩手県",
  "JP-04": "宮城県",
  "JP-05": "秋田県",
  "JP-06": "山形県",
  "JP-07": "福島県",
  "JP-08": "茨城県",
  "JP-09": "栃木県",
  "JP-10": "群馬県",
  "JP-11": "埼玉県",
  "JP-12": "千葉県",
  "JP-13": "東京都",
  "JP-14": "神奈川県",
  "JP-15": "新潟県",
  "JP-16": "富山県",
  "JP-17": "石川県",
  "JP-18": "福井県",
  "JP-19": "山梨県",
  "JP-20": "長野県",
  "JP-21": "岐阜県",
  "JP-22": "静岡県",
  "JP-23": "愛知県",
  "JP-24": "三重県",
  "JP-25": "滋賀県",
  "JP-26": "京都府",
  "JP-27": "大阪府",
  "JP-28": "兵庫県",
  "JP-29": "奈良県",
  "JP-30": "和歌山県",
  "JP-31": "鳥取県",
  "JP-32": "島根県",
  "JP-33": "岡山県",
  "JP-34": "広島県",
  "JP-35": "山口県",
  "JP-36": "徳島県",
  "JP-37": "香川県",
  "JP-38": "愛媛県",
  "JP-39": "高知県",
  "JP-40": "福岡県",
  "JP-41": "佐賀県",
  "JP-42": "長崎県",
  "JP-43": "熊本県",
  "JP-44": "大分県",
  "JP-45": "宮崎県",
  "JP-46": "鹿児島県",
  "JP-47": "沖縄県",
}

export const province_arr = [
  "北海道", "青森県", "岩手県", "宮城県", "秋田県", "山形県", "福島県", "茨城県", "栃木県",
  "群馬県", "埼玉県", "千葉県", "東京都", "神奈川県", "新潟県", "富山県", "石川県", "福井県", "山梨県",
  "長野県", "岐阜県", "静岡県", "愛知県", "三重県", "滋賀県", "京都府", "大阪府", "兵庫県",
  "奈良県", "和歌山県", "鳥取県", "島根県", "岡山県", "広島県", "山口県", "徳島県", "香川県",
  "愛媛県", "高知県", "福岡県", "佐賀県", "長崎県", "熊本県", "大分県", "宮崎県", "鹿児島県", "沖縄県",
]


export const shipping_fees = {
  "1000円配送": {
    "JP-01": 1000, "JP-02": 1000, "JP-03": 1000, "JP-04": 1000, "JP-05": 1000, "JP-06": 1000, "JP-07": 1000,
    "JP-08": 1000, "JP-09": 1000, "JP-10": 1000, "JP-11": 1000, "JP-12": 1000, "JP-13": 1000, "JP-14": 1000,
    "JP-15": 1000, "JP-16": 1000, "JP-17": 1000, "JP-18": 1000, "JP-19": 1000, "JP-20": 1000, "JP-21": 1000,
    "JP-22": 1000, "JP-23": 1000, "JP-24": 1000, "JP-25": 1000, "JP-26": 1000, "JP-27": 1000, "JP-28": 1000,
    "JP-29": 1000, "JP-30": 1000, "JP-31": 1000, "JP-32": 1000, "JP-33": 1000, "JP-34": 1000, "JP-35": 1000,
    "JP-36": 1000, "JP-37": 1000, "JP-38": 1000, "JP-39": 1000, "JP-40": 1000, "JP-41": 1000, "JP-42": 1000,
    "JP-43": 1000, "JP-44": 1000, "JP-45": 1000, "JP-46": 1000, "JP-47": 1000
  },
  "1500円配送": {
    "JP-01": 1500, "JP-02": 1500, "JP-03": 1500, "JP-04": 1500, "JP-05": 1500, "JP-06": 1500, "JP-07": 1500,
    "JP-08": 1500, "JP-09": 1500, "JP-10": 1500, "JP-11": 1500, "JP-12": 1500, "JP-13": 1500, "JP-14": 1500,
    "JP-15": 1500, "JP-16": 1500, "JP-17": 1500, "JP-18": 1500, "JP-19": 1500, "JP-20": 1500, "JP-21": 1500,
    "JP-22": 1500, "JP-23": 1500, "JP-24": 1500, "JP-25": 1500, "JP-26": 1500, "JP-27": 1500, "JP-28": 1500,
    "JP-29": 1500, "JP-30": 1500, "JP-31": 1500, "JP-32": 1500, "JP-33": 1500, "JP-34": 1500, "JP-35": 1500,
    "JP-36": 1500, "JP-37": 1500, "JP-38": 1500, "JP-39": 1500, "JP-40": 1500, "JP-41": 1500, "JP-42": 1500,
    "JP-43": 1500, "JP-44": 1500, "JP-45": 1500, "JP-46": 1500, "JP-47": 1500
  },
  "2000円配送": {
    "JP-01": 2000, "JP-02": 2000, "JP-03": 2000, "JP-04": 2000, "JP-05": 2000, "JP-06": 2000, "JP-07": 2000,
    "JP-08": 2000, "JP-09": 2000, "JP-10": 2000, "JP-11": 2000, "JP-12": 2000, "JP-13": 2000, "JP-14": 2000,
    "JP-15": 2000, "JP-16": 2000, "JP-17": 2000, "JP-18": 2000, "JP-19": 2000, "JP-20": 2000, "JP-21": 2000,
    "JP-22": 2000, "JP-23": 2000, "JP-24": 2000, "JP-25": 2000, "JP-26": 2000, "JP-27": 2000, "JP-28": 2000,
    "JP-29": 2000, "JP-30": 2000, "JP-31": 2000, "JP-32": 2000, "JP-33": 2000, "JP-34": 2000, "JP-35": 2000,
    "JP-36": 2000, "JP-37": 2000, "JP-38": 2000, "JP-39": 2000, "JP-40": 2000, "JP-41": 2000, "JP-42": 2000,
    "JP-43": 2000, "JP-44": 2000, "JP-45": 2000, "JP-46": 2000, "JP-47": 2000
  },
  "3000円沖縄5000円配送": {
    "JP-01": 3000, "JP-02": 3000, "JP-03": 3000, "JP-04": 3000, "JP-05": 3000, "JP-06": 3000, "JP-07": 3000,
    "JP-08": 3000, "JP-09": 3000, "JP-10": 3000, "JP-11": 3000, "JP-12": 3000, "JP-13": 3000, "JP-14": 3000,
    "JP-15": 3000, "JP-16": 3000, "JP-17": 3000, "JP-18": 3000, "JP-19": 3000, "JP-20": 3000, "JP-21": 3000,
    "JP-22": 3000, "JP-23": 3000, "JP-24": 3000, "JP-25": 3000, "JP-26": 3000, "JP-27": 3000, "JP-28": 3000,
    "JP-29": 3000, "JP-30": 3000, "JP-31": 3000, "JP-32": 3000, "JP-33": 3000, "JP-34": 3000, "JP-35": 3000,
    "JP-36": 3000, "JP-37": 3000, "JP-38": 3000, "JP-39": 3000, "JP-40": 3000, "JP-41": 3000, "JP-42": 3000,
    "JP-43": 3000, "JP-44": 3000, "JP-45": 3000, "JP-46": 3000, "JP-47": 5000
  },
  "大型配送1": {
    "JP-01": 30000, "JP-02": 20000, "JP-03": 20000, "JP-04": 20000, "JP-05": 20000, "JP-06": 20000, "JP-07": 20000,
    "JP-08": 20000, "JP-09": 20000, "JP-10": 20000, "JP-11": 20000, "JP-12": 20000, "JP-13": 20000, "JP-14": 20000,
    "JP-15": 20000, "JP-16": 20000, "JP-17": 20000, "JP-18": 20000, "JP-19": 20000, "JP-20": 20000, "JP-21": 20000,
    "JP-22": 20000, "JP-23": 20000, "JP-24": 20000, "JP-25": 20000, "JP-26": 20000, "JP-27": 20000, "JP-28": 20000,
    "JP-29": 20000, "JP-30": 20000, "JP-31": 26000, "JP-32": 26000, "JP-33": 26000, "JP-34": 26000, "JP-35": 26000,
    "JP-36": 26000, "JP-37": 26000, "JP-38": 26000, "JP-39": 26000, "JP-40": 35000, "JP-41": 35000, "JP-42": 35000,
    "JP-43": 35000, "JP-44": 35000, "JP-45": 35000, "JP-46": 35000, "JP-47": 50000
  },
}


export const countries = [
  { shippingArea: "area3", flag: '🇦🇩', code: 'AD', label: "Andorra", phone: '376' },
  { shippingArea: "area3", flag: '🇦🇫', code: 'AF', label: "Afghanistan", phone: '93' },
  { shippingArea: "area5", flag: '🇦🇬', code: 'AG', label: "Antigua and Barbuda", phone: '1-268' },
  { shippingArea: "area5", flag: '🇦🇮', code: 'AI', label: "Anguilla", phone: '1-264' },
  { shippingArea: "area3", flag: '🇦🇱', code: 'AL', label: "Albania", phone: '355' },
  { shippingArea: "area3", flag: '🇦🇲', code: 'AM', label: "Armenia", phone: '374' },
  { shippingArea: "area3", flag: '🇦🇴', code: 'AO', label: "Angola", phone: '244' },
  { shippingArea: "area3", flag: '🇦🇶', code: 'AQ', label: "Antarctica", phone: '672' },
  { shippingArea: "area5", flag: '🇦🇷', code: 'AR', label: "Argentina", phone: '54' },
  { shippingArea: "area5", flag: '🇦🇸', code: 'AS', label: "American Samoa", phone: '1-684' },
  { shippingArea: "area3", flag: '🇦🇹', code: 'AT', label: "Austria", phone: '43' },
  { shippingArea: "area3", flag: '🇦🇺', code: 'AU', label: "Australia", phone: '61' },
  { shippingArea: "area3", flag: '🇦🇼', code: 'AW', label: "Aruba", phone: '297' },
  { shippingArea: "area3", flag: '🇦🇽', code: 'AX', label: "Åland Islands", phone: '358' },
  { shippingArea: "area3", flag: '🇦🇿', code: 'AZ', label: "Azerbaijan", phone: '994' },
  { shippingArea: "area3", flag: '🇧🇦', code: 'BA', label: "Bosnia and Herzegovina", phone: '387' },
  { shippingArea: "area5", flag: '🇧🇧', code: 'BB', label: "Barbados", phone: '1-246' },
  { shippingArea: "area2", flag: '🇧🇩', code: 'BD', label: "Bangladesh", phone: '880' },
  { shippingArea: "area3", flag: '🇧🇪', code: 'BE', label: "Belgium", phone: '32' },
  { shippingArea: "area3", flag: '🇧🇫', code: 'BF', label: "Burkina Faso", phone: '226' },
  { shippingArea: "area3", flag: '🇧🇬', code: 'BG', label: "Bulgaria", phone: '359' },
  { shippingArea: "area3", flag: '🇧🇭', code: 'BH', label: "Bahrain", phone: '973' },
  { shippingArea: "area3", flag: '🇧🇮', code: 'BI', label: "Burundi", phone: '257' },
  { shippingArea: "area3", flag: '🇧🇯', code: 'BJ', label: "Benin", phone: '229' },
  { shippingArea: "area3", flag: '🇧🇱', code: 'BL', label: "Saint Barthélemy", phone: '590' },
  { shippingArea: "area5", flag: '🇧🇲', code: 'BM', label: "Bermuda", phone: '1-441' },
  { shippingArea: "area2", flag: '🇧🇳', code: 'BN', label: "Brunei Darussalam", phone: '673' },
  { shippingArea: "area3", flag: '🇧🇴', code: 'BO', label: "Bolivia", phone: '591' },
  { shippingArea: "area3", flag: '🇧🇶', code: 'BQ', label: "Bonaire, Sint Eustatius and Saba", phone: '599' },
  { shippingArea: "area5", flag: '🇧🇷', code: 'BR', label: "Brazil", phone: '55' },
  { shippingArea: "area5", flag: '🇧🇸', code: 'BS', label: "Bahamas", phone: '1-242' },
  { shippingArea: "area2", flag: '🇧🇹', code: 'BT', label: "Bhutan", phone: '975' },
  { shippingArea: "area3", flag: '🇧🇻', code: 'BV', label: "Bouvet Island", phone: '47' },
  { shippingArea: "area5", flag: '🇧🇼', code: 'BW', label: "Botswana", phone: '267' },
  { shippingArea: "area3", flag: '🇧🇾', code: 'BY', label: "Belarus", phone: '375' },
  { shippingArea: "area3", flag: '🇧🇿', code: 'BZ', label: "Belize", phone: '501' },
  { shippingArea: "area2", flag: '🇰🇭', code: 'KH', label: "Cambodia", phone: '855' },
  { shippingArea: "area3", flag: '🇨🇦', code: 'CA', label: "Canada", phone: '1' },
  { shippingArea: "area3", flag: '🇨🇨', code: 'CC', label: "Cocos (Keeling) Islands", phone: '61' },
  { shippingArea: "area5", flag: '🇨🇩', code: 'CD', label: "Congo", phone: '243' },
  { shippingArea: "area5", flag: '🇨🇫', code: 'CF', label: "Central African Republic", phone: '236' },
  { shippingArea: "area5", flag: '🇨🇬', code: 'CG', label: "Congo", phone: '242' },
  { shippingArea: "area3", flag: '🇨🇭', code: 'CH', label: "Switzerland", phone: '41' },
  { shippingArea: "area5", flag: '🇨🇮', code: 'CI', label: "Côte D'Ivoire", phone: '225' },
  { shippingArea: "area3", flag: '🇨🇰', code: 'CK', label: "Cook Islands", phone: '682' },
  { shippingArea: "area5", flag: '🇨🇱', code: 'CL', label: "Chile", phone: '56' },
  { shippingArea: "area5", flag: '🇨🇲', code: 'CM', label: "Cameroon", phone: '237' },
  { shippingArea: "area1", flag: '🇨🇳', code: 'CN', label: "China", phone: '86' },
  { shippingArea: "area5", flag: '🇨🇴', code: 'CO', label: "Colombia", phone: '57' },
  { shippingArea: "area5", flag: '🇨🇷', code: 'CR', label: "Costa Rica", phone: '506' },
  { shippingArea: "area5", flag: '🇨🇺', code: 'CU', label: "Cuba", phone: '53' },
  { shippingArea: "area5", flag: '🇨🇻', code: 'CV', label: "Cape Verde", phone: '238' },
  { shippingArea: "area5", flag: '🇨🇼', code: 'CW', label: "Curaçao", phone: '599' },
  { shippingArea: "area5", flag: '🇨🇽', code: 'CX', label: "Christmas Island", phone: '61' },
  { shippingArea: "area3", flag: '🇨🇾', code: 'CY', label: "Cyprus", phone: '357' },
  { shippingArea: "area3", flag: '🇨🇿', code: 'CZ', label: "Czech Republic", phone: '420' },
  { shippingArea: "area3", flag: '🇩🇪', code: 'DE', label: "Germany", phone: '49' },
  { shippingArea: "area5", flag: '🇩🇯', code: 'DJ', label: "Djibouti", phone: '253' },
  { shippingArea: "area3", flag: '🇩🇰', code: 'DK', label: "Denmark", phone: '45' },
  { shippingArea: "area5", flag: '🇩🇲', code: 'DM', label: "Dominica", phone: '1-767' },
  { shippingArea: "area5", flag: '🇩🇴', code: 'DO', label: "Dominican Republic", phone: '1-809' },
  { shippingArea: "area5", flag: '🇩🇿', code: 'DZ', label: "Algeria", phone: '213' },
  { shippingArea: "area5", flag: '🇪🇨', code: 'EC', label: "Ecuador", phone: '593' },
  { shippingArea: "area3", flag: '🇪🇪', code: 'EE', label: "Estonia", phone: '372' },
  { shippingArea: "area5", flag: '🇪🇬', code: 'EG', label: "Egypt", phone: '20' },
  { shippingArea: "area5", flag: '🇪🇭', code: 'EH', label: "Western Sahara", phone: '212' },
  { shippingArea: "area5", flag: '🇪🇷', code: 'ER', label: "Eritrea", phone: '291' },
  { shippingArea: "area3", flag: '🇪🇸', code: 'ES', label: "Spain", phone: '34' },
  { shippingArea: "area5", flag: '🇪🇹', code: 'ET', label: "Ethiopia", phone: '251' },
  { shippingArea: "area3", flag: '🇫🇮', code: 'FI', label: "Finland", phone: '358' },
  { shippingArea: "area3", flag: '🇫🇯', code: 'FJ', label: "Fiji", phone: '679' },
  { shippingArea: "area5", flag: '🇫🇰', code: 'FK', label: "Falkland Islands (Malvinas)", phone: '500' },
  { shippingArea: "area5", flag: '🇫🇲', code: 'FM', label: "Micronesia", phone: '691' },
  { shippingArea: "area5", flag: '🇫🇴', code: 'FO', label: "Faroe Islands", phone: '298' },
  { shippingArea: "area3", flag: '🇫🇷', code: 'FR', label: "France", phone: '33' },
  { shippingArea: "area5", flag: '🇬🇦', code: 'GA', label: "Gabon", phone: '241' },
  { shippingArea: "area3", flag: '🇬🇧', code: 'GB', label: "United Kingdom", phone: '44' },
  { shippingArea: "area5", flag: '🇬🇩', code: 'GD', label: "Grenada", phone: '1-473' },
  { shippingArea: "area5", flag: '🇬🇪', code: 'GE', label: "Georgia", phone: '995' },
  { shippingArea: "area5", flag: '🇬🇫', code: 'GF', label: "French Guiana", phone: '594' },
  { shippingArea: "area3", flag: '🇬🇬', code: 'GG', label: "Guernsey", phone: '44' },
  { shippingArea: "area5", flag: '🇬🇭', code: 'GH', label: "Ghana", phone: '233' },
  { shippingArea: "area5", flag: '🇬🇮', code: 'GI', label: "Gibraltar", phone: '350' },
  { shippingArea: "area3", flag: '🇬🇱', code: 'GL', label: "Greenland", phone: '299' },
  { shippingArea: "area5", flag: '🇬🇲', code: 'GM', label: "Gambia", phone: '220' },
  { shippingArea: "area5", flag: '🇬🇳', code: 'GN', label: "Guinea", phone: '224' },
  { shippingArea: "area5", flag: '🇬🇵', code: 'GP', label: "Guadeloupe", phone: '590' },
  { shippingArea: "area5", flag: '🇬🇶', code: 'GQ', label: "Equatorial Guinea", phone: '240' },
  { shippingArea: "area3", flag: '🇬🇷', code: 'GR', label: "Greece", phone: '30' },
  { shippingArea: "area3", flag: '🇬🇸', code: 'GS', label: "South Georgia", phone: '500' },
  { shippingArea: "area5", flag: '🇬🇹', code: 'GT', label: "Guatemala", phone: '502' },
  { shippingArea: "area4", flag: '🇬🇺', code: 'GU', label: "Guam", phone: '1-671' },
  { shippingArea: "area5", flag: '🇬🇼', code: 'GW', label: "Guinea-Bissau", phone: '245' },
  { shippingArea: "area5", flag: '🇬🇾', code: 'GY', label: "Guyana", phone: '592' },
  { shippingArea: "area2", flag: '🇭🇰', code: 'HK', label: "Hong Kong", phone: '852' },
  { shippingArea: "area3", flag: '🇭🇲', code: 'HM', label: "Heard Island and Mcdonald Islands", phone: '672' },
  { shippingArea: "area5", flag: '🇭🇳', code: 'HN', label: "Honduras", phone: '504' },
  { shippingArea: "area3", flag: '🇭🇷', code: 'HR', label: "Croatia", phone: '385' },
  { shippingArea: "area3", flag: '🇭🇹', code: 'HT', label: "Haiti", phone: '509' },
  { shippingArea: "area3", flag: '🇭🇺', code: 'HU', label: "Hungary", phone: '36' },
  { shippingArea: "area2", flag: '🇮🇩', code: 'ID', label: "Indonesia", phone: '62' },
  { shippingArea: "area3", flag: '🇮🇪', code: 'IE', label: "Ireland", phone: '353' },
  { shippingArea: "area3", flag: '🇮🇱', code: 'IL', label: "Israel", phone: '972' },
  { shippingArea: "area3", flag: '🇮🇲', code: 'IM', label: "Isle of Man", phone: '44' },
  { shippingArea: "area2", flag: '🇮🇳', code: 'IN', label: "India", phone: '91' },
  { shippingArea: "area3", flag: '🇮🇴', code: 'IO', label: "British Indian Ocean Territory", phone: '246' },
  { shippingArea: "area3", flag: '🇮🇶', code: 'IQ', label: "Iraq", phone: '964' },
  { shippingArea: "area3", flag: '🇮🇷', code: 'IR', label: "Iran", phone: '98' },
  { shippingArea: "area3", flag: '🇮🇸', code: 'IS', label: "Iceland", phone: '354' },
  { shippingArea: "area3", flag: '🇮🇹', code: 'IT', label: "Italy", phone: '39' },
  { shippingArea: "area3", flag: '🇯🇪', code: 'JE', label: "Jersey", phone: '44' },
  { shippingArea: "area5", flag: '🇯🇲', code: 'JM', label: "Jamaica", phone: '1-876' },
  { shippingArea: "area3", flag: '🇯🇴', code: 'JO', label: "Jordan", phone: '962' },
  { shippingArea: "area1", flag: '🇯🇵', code: 'JP', label: "Japan", phone: '81' },
  { shippingArea: "area5", flag: '🇰🇪', code: 'KE', label: "Kenya", phone: '254' },
  // { shippingArea: "area5", flag: '🇰🇬', code:	'KG', label: "Kyrgyzstan", phone: '996' },
  // { shippingArea: "area5", flag: '🇰🇮', code:	'KI', label: "Kiribati", phone: '686' },
  // { shippingArea: "area5", flag: '🇰🇲', code:	'KM', label: "Comoros", phone: '269' },
  // { shippingArea: "area5", flag: '🇰🇳', code:	'KN', label: "Saint Kitts and Nevis", phone: '1-869' },
  { shippingArea: "area1", flag: '🇰🇵', code: 'KP', label: "North Korea", phone: '850' },
  { shippingArea: "area3", flag: '🇰🇼', code: 'KW', label: "Kuwait", phone: '965' },
  // { shippingArea: "area5", flag: '🇰🇾', code:	'KY', label: "Cayman Islands", phone: '1-345' },
  // { shippingArea: "area3", flag: '🇰🇿', code:	'KZ', label: "Kazakhstan", phone: '7' },
  { shippingArea: "area2", flag: '🇱🇦', code: 'LA', label: "Lao People's Democratic Republic", phone: '856' },
  { shippingArea: "area3", flag: '🇱🇧', code: 'LB', label: "Lebanon", phone: '961' },
  // { shippingArea: "area5", flag: '🇱🇨', code:	'LC', label: "Saint Lucia", phone: '1-758' },
  { shippingArea: "area3", flag: '🇱🇮', code: 'LI', label: "Liechtenstein", phone: '423' },
  // { shippingArea: "area2", flag: '🇱🇰', code:	'LK', label: "Sri Lanka", phone: '94' },
  // { shippingArea: "area3", flag: '🇱🇷', code:	'LR', label: "Liberia", phone: '231' },
  // { shippingArea: "area5", flag: '🇱🇸', code:	'LS', label: "Lesotho", phone: '266' },
  { shippingArea: "area3", flag: '🇱🇹', code: 'LT', label: "Lithuania", phone: '370' },
  { shippingArea: "area3", flag: '🇱🇺', code: 'LU', label: "Luxembourg", phone: '352' },
  { shippingArea: "area3", flag: '🇱🇻', code: 'LV', label: "Latvia", phone: '371' },
  // { shippingArea: "area5", flag: '🇱🇾', code:	'LY', label: "Libya", phone: '218' },
  { shippingArea: "area5", flag: '🇲🇦', code: 'MA', label: "Morocco", phone: '212' },
  { shippingArea: "area3", flag: '🇲🇨', code: 'MC', label: "Monaco", phone: '377' },
  // { shippingArea: "area5", flag: '🇲🇩', code:	'MD', label: "Moldova", phone: '373' },
  // { shippingArea: "area3", flag: '🇲🇪', code:	'ME', label: "Montenegro", phone: '382' },
  // { shippingArea: "area3", flag: '🇲🇫', code:	'MF', label: "Saint Martin (French Part)", phone: '590' },
  { shippingArea: "area5", flag: '🇲🇬', code: 'MG', label: "Madagascar", phone: '261' },
  // { shippingArea: "area5", flag: '🇲🇭', code:	'MH', label: "Marshall Islands", phone: '692' },
  { shippingArea: "area3", flag: '🇲🇰', code: 'MK', label: "Macedonia", phone: '389' },
  // { shippingArea: "area5", flag: '🇲🇱', code:	'ML', label: "Mali", phone: '223' },
  { shippingArea: "area2", flag: '🇲🇲', code: 'MM', label: "Myanmar", phone: '95' },
  { shippingArea: "area2", flag: '🇲🇳', code: 'MN', label: "Mongolia", phone: '976' },
  { shippingArea: "area2", flag: '🇲🇴', code: 'MO', label: "Macao", phone: '853' },
  // { shippingArea: "area5", flag: '🇲🇵', code:	'MP', label: "Northern Mariana Islands", phone: '1-670' },
  { shippingArea: "area5", flag: '🇲🇶', code: 'MQ', label: "Martinique", phone: '596' },
  // { shippingArea: "area5", flag: '🇲🇷', code:	'MR', label: "Mauritania", phone: '222' },
  // { shippingArea: "area3", flag: '🇲🇸', code:	'MS', label: "Montserrat", phone: '1-664' },
  { shippingArea: "area3", flag: '🇲🇹', code: 'MT', label: "Malta", phone: '356' },
  { shippingArea: "area5", flag: '🇲🇺', code: 'MU', label: "Mauritius", phone: '230' },
  { shippingArea: "area2", flag: '🇲🇻', code: 'MV', label: "Maldives", phone: '960' },
  // { shippingArea: "area5", flag: '🇲🇼', code:	'MW', label: "Malawi", phone: '265' },
  { shippingArea: "area3", flag: '🇲🇽', code: 'MX', label: "Mexico", phone: '52' },
  { shippingArea: "area2", flag: '🇲🇾', code: 'MY', label: "Malaysia", phone: '60' },
  // { shippingArea: "area5", flag: '🇲🇿', code:	'MZ', label: "Mozambique", phone: '258' },
  // { shippingArea: "area5", flag: '🇳🇦', code:	'NA', label: "Namibia", phone: '264' },
  { shippingArea: "area3", flag: '🇳🇨', code: 'NC', label: "New Caledonia", phone: '687' },
  // { shippingArea: "area5", flag: '🇳🇪', code:	'NE', label: "Niger", phone: '227' },
  // { shippingArea: "area5", flag: '🇳🇫', code:	'NF', label: "Norfolk Island", phone: '672' },
  { shippingArea: "area5", flag: '🇳🇬', code: 'NG', label: "Nigeria", phone: '234' },
  // { shippingArea: "area5", flag: '🇳🇮', code:	'NI', label: "Nicaragua", phone: '505' },
  { shippingArea: "area3", flag: '🇳🇱', code: 'NL', label: "Netherlands", phone: '31' },
  { shippingArea: "area3", flag: '🇳🇴', code: 'NO', label: "Norway", phone: '47' },
  { shippingArea: "area2", flag: '🇳🇵', code: 'NP', label: "Nepal", phone: '977' },
  // { shippingArea: "area5", flag: '🇳🇷', code:	'NR', label: "Nauru", phone: '674' },
  // { shippingArea: "area5", flag: '🇳🇺', code:	'NU', label: "Niue", phone: '683' },
  { shippingArea: "area3", flag: '🇳🇿', code: 'NZ', label: "New Zealand", phone: '64' },
  { shippingArea: "area3", flag: '🇴🇲', code: 'OM', label: "Oman", phone: '968' },
  { shippingArea: "area5", flag: '🇵🇦', code: 'PA', label: "Panama", phone: '507' },
  { shippingArea: "area5", flag: '🇵🇪', code: 'PE', label: "Peru", phone: '51' },
  // { shippingArea: "area3", flag: '🇵🇫', code:	'PF', label: "French Polynesia", phone: '689' },
  { shippingArea: "area3", flag: '🇵🇬', code: 'PG', label: "Papua New Guinea", phone: '675' },
  { shippingArea: "area2", flag: '🇵🇭', code: 'PH', label: "Philippines", phone: '63' },
  { shippingArea: "area2", flag: '🇵🇰', code: 'PK', label: "Pakistan", phone: '92' },
  { shippingArea: "area3", flag: '🇵🇱', code: 'PL', label: "Poland", phone: '48' },
  { shippingArea: "area3", flag: '🇵🇲', code: 'PM', label: "Saint Pierre and Miquelon", phone: '508' },
  // { shippingArea: "area5", flag: '🇵🇳', code:	'PN', label: "Pitcairn", phone: '870' },
  { shippingArea: "area4", flag: '🇵🇷', code: 'PR', label: "Puerto Rico", phone: '1' },
  // { shippingArea: "area5", flag: '🇵🇸', code:	'PS', label: "Palestinian Territory", phone: '970' },
  { shippingArea: "area3", flag: '🇵🇹', code: 'PT', label: "Portugal", phone: '351' },
  // { shippingArea: "area5", flag: '🇵🇼', code:	'PW', label: "Palau", phone: '680' },
  { shippingArea: "area5", flag: '🇵🇾', code: 'PY', label: "Paraguay", phone: '595' },
  { shippingArea: "area3", flag: '🇶🇦', code: 'QA', label: "Qatar", phone: '974' },
  { shippingArea: "area5", flag: '🇷🇪', code: 'RE', label: "Réunion", phone: '262' },
  { shippingArea: "area3", flag: '🇷🇴', code: 'RO', label: "Romania", phone: '40' },
  { shippingArea: "area3", flag: '🇷🇸', code: 'RS', label: "Serbia", phone: '381' },
  { shippingArea: "area3", flag: '🇷🇺', code: 'RU', label: "Russia", phone: '7' },
  { shippingArea: "area5", flag: '🇷🇼', code: 'RW', label: "Rwanda", phone: '250' },
  { shippingArea: "area3", flag: '🇸🇦', code: 'SA', label: "Saudi Arabia", phone: '966' },
  { shippingArea: "area1", flag: '🇰🇷', code: 'KR', label: "South Korea", phone: '82' },
  { shippingArea: "area3", flag: '🇸🇧', code: 'SB', label: "Solomon Islands", phone: '677' },
  // { shippingArea: "area3", flag: '🇸🇨', code:	'SC', label: "Seychelles", phone: '248' },
  { shippingArea: "area5", flag: '🇸🇩', code: 'SD', label: "Sudan", phone: '249' },
  { shippingArea: "area3", flag: '🇸🇪', code: 'SE', label: "Sweden", phone: '46' },
  { shippingArea: "area2", flag: '🇸🇬', code: 'SG', label: "Singapore", phone: '65' },
  // { shippingArea: "area3", flag: '🇸🇭', code:	'SH', label: "Saint Helena, Ascension and Tristan Da Cunha", phone: '290' },
  { shippingArea: "area3", flag: '🇸🇮', code: 'SI', label: "Slovenia", phone: '386' },
  // { shippingArea: "area3", flag: '🇸🇯', code:	'SJ', label: "Svalbard and Jan Mayen", phone: '47' },
  { shippingArea: "area3", flag: '🇸🇰', code: 'SK', label: "Slovakia", phone: '421' },
  { shippingArea: "area5", flag: '🇸🇱', code: 'SL', label: "Sierra Leone", phone: '232' },
  { shippingArea: "area3", flag: '🇸🇲', code: 'SM', label: "San Marino", phone: '378' },
  { shippingArea: "area5", flag: '🇸🇳', code: 'SN', label: "Senegal", phone: '221' },
  // { shippingArea: "area5", flag: '🇸🇴', code:	'SO', label: "Somalia", phone: '252' },
  // { shippingArea: "area5", flag: '🇸🇷', code:	'SR', label: "Suriname", phone: '597' },
  // { shippingArea: "area5", flag: '🇸🇸', code:	'SS', label: "South Sudan", phone: '211' },
  // { shippingArea: "area5", flag: '🇸🇹', code:	'ST', label: "Sao Tome and Principe", phone: '239' },
  { shippingArea: "area5", flag: '🇸🇻', code: 'SV', label: "El Salvador", phone: '503' },
  // { shippingArea: "area5", flag: '🇸🇽', code:	'SX', label: "Sint Maarten (Dutch Part)", phone: '1-721' },
  { shippingArea: "area3", flag: '🇸🇾', code: 'SY', label: "Syrian Arab Republic", phone: '963' },
  // { shippingArea: "area5", flag: '🇸🇿', code:	'SZ', label: "Swaziland", phone: '268' },
  // { shippingArea: "area5", flag: '🇹🇨', code:	'TC', label: "Turks and Caicos Islands", phone: '1-649' },
  // { shippingArea: "area5", flag: '🇹🇩', code:	'TD', label: "Chad", phone: '235' },
  // { shippingArea: "area5", flag: '🇹🇫', code:	'TF', label: "French Southern Territories", phone: '262' },
  { shippingArea: "area5", flag: '🇹🇬', code: 'TG', label: "Togo", phone: '228' },
  { shippingArea: "area2", flag: '🇹🇭', code: 'TH', label: "Thailand", phone: '66' },
  // { shippingArea: "area3", flag: '🇹🇯', code:	'TJ', label: "Tajikistan", phone: '992' },
  // { shippingArea: "area5", flag: '🇹🇰', code:	'TK', label: "Tokelau", phone: '690' },
  // { shippingArea: "area5", flag: '🇹🇱', code:	'TL', label: "Timor-Leste", phone: '670' },
  // { shippingArea: "area3", flag: '🇹🇲', code:	'TM', label: "Turkmenistan", phone: '993' },
  { shippingArea: "area5", flag: '🇹🇳', code: 'TN', label: "Tunisia", phone: '216' },
  // { shippingArea: "", flag: '🇹🇴', code:	'TO', label: "Tonga", phone: '676' },
  { shippingArea: "area3", flag: '🇹🇷', code: 'TR', label: "Turkey", phone: '90' },
  { shippingArea: "area5", flag: '🇹🇹', code: 'TT', label: "Trinidad and Tobago", phone: '1-868' },
  // { shippingArea: "area5", flag: '🇹🇻', code:	'TV', label: "Tuvalu", phone: '688' },
  { shippingArea: "area1", flag: '🇹🇼', code: 'TW', label: "Taiwan", phone: '886' },
  { shippingArea: "area5", flag: '🇹🇿', code: 'TZ', label: "Tanzania", phone: '255' },
  { shippingArea: "area5", flag: '🇺🇬', code: 'UG', label: "Uganda", phone: '256' },
  { shippingArea: "area3", flag: '🇦🇪', code: 'AE', label: "United Arab Emirates", phone: '971' },
  { shippingArea: "area3", flag: '🇺🇦', code: 'UA', label: "Ukraine", phone: '380' },
  { shippingArea: "area4", flag: '🇺🇸', code: 'US', label: "United States", phone: '1' },
  { shippingArea: "area5", flag: '🇺🇾', code: 'UZ', label: "Uruguay", phone: '598' },
  { shippingArea: "area3", flag: '🇺🇿', code: 'VA', label: "Uzbekistan", phone: '998' },
  { shippingArea: "area3", flag: '🇻🇦', code: 'VC', label: "Vatican City", phone: '379' },
  { shippingArea: "area5", flag: '🇻🇪', code: 'VG', label: "Venezuela", phone: '58' },
  { shippingArea: "area2", flag: '🇻🇳', code: 'VU', label: "Viet Nam", phone: '84' },
  // { shippingArea: "area5", flag: '🇻🇺', code:	'WF', label: "Vanuatu", phone: '678' },
  // { shippingArea: "area5", flag: '🇼🇫', code:	'WS', label: "Wallis and Futuna", phone: '681' },
  { shippingArea: "area3", flag: '🇼🇸', code: 'XK', label: "Samoa", phone: '685' },
  { shippingArea: "area5", flag: '🇿🇦', code: 'ZA', label: "South Africa", phone: '27' },
  // { shippingArea: "area5", flag: '🇿🇲', code:	'ZM', label: "Zambia", phone: '260' },
  { shippingArea: "area5", flag: '🇿🇼', code: 'ZW', label: "Zimbabwe", phone: '263' },
];

export const getCountryName = (_country_code: any) => {
  const selectedCountry = countries.find(country => country.code === _country_code)
  return selectedCountry?.label ?? "JP"
}

export const getShippingFee = (shipping_fee_name: any, province_code: any) => {
  if (!shipping_fee_name || !province_code) {
    return 1000
  } else {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return shipping_fees[`${shipping_fee_name}`][`${province_code}`]
  }
}

// weight -> KG 料金表参照先 -> https://www.post.japanpost.jp/int/charge/list/ems_all.html
export const internationalShippingFees = [
  { weight: 5, area1: 6400, area2: 8150, area3: 13000, area4: 15100, area5: 17100 },
  { weight: 10, area1: 10600, area2: 13350, area3: 23500, area4: 27100, area5: 29700 },
  { weight: 15, area1: 14600, area2: 18350, area3: 34000, area4: 39100, area5: 41700 },
  { weight: 20, area1: 18600, area2: 23350, area3: 44500, area4: 51100, area5: 53700 },
  { weight: 25, area1: 22600, area2: 28350, area3: 55000, area4: 63100, area5: 65700 },
  { weight: 30, area1: 26600, area2: 33350, area3: 65500, area4: 75100, area5: 77700 },
]

//出品者倉庫 -> Benly倉庫への配送料
export const vendorToBenlyShippingFees = [
  { weight: 5, area1: 1350, area2: 1350, area3: 1250, area4: 1150, area5: 1050, area6: 950, area7: 800, area8: 700, area9: 700, area10: 700, area11: 800, area12: 1100 },
  { weight: 10, area1: 1660, area2: 1660, area3: 1560, area4: 1460, area5: 1360, area6: 1260, area7: 1260, area8: 1260, area9: 1260, area10: 1260, area11: 1360, area12: 1660 },
  { weight: 15, area1: 2080, area2: 2080, area3: 1980, area4: 1880, area5: 1780, area6: 1680, area7: 1680, area8: 1680, area9: 1680, area10: 1680, area11: 1780, area12: 2080 },
  { weight: 20, area1: 2080, area2: 2080, area3: 1980, area4: 1880, area5: 1780, area6: 1680, area7: 1680, area8: 1680, area9: 1680, area10: 1680, area11: 1780, area12: 2080 },
  { weight: 25, area1: 2280, area2: 2280, area3: 2180, area4: 2080, area5: 1980, area6: 1880, area7: 1880, area8: 1880, area9: 1880, area10: 1880, area11: 1980, area12: 2280 },
  { weight: 30, area1: 2280, area2: 2280, area3: 2180, area4: 2080, area5: 1980, area6: 1880, area7: 1880, area8: 1880, area9: 1880, area10: 1880, area11: 1980, area12: 2280 },
]

export const getInternationalShippingFee = (_country_code: any, _item_weight: any) => {
  if (_item_weight <= 5) {
    _item_weight = 5
  }
  const selectedCountry = countries.find(country => country.code === _country_code)
  const shippingFees = internationalShippingFees.find(shippingFee => shippingFee.weight === _item_weight)
  const vendorBenlyFee = vendorToBenlyShippingFees.find(benlyShippingFee => benlyShippingFee.weight === _item_weight)
  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  const totalFee = shippingFees[selectedCountry.shippingArea] + vendorBenlyFee['area9'] //一旦出品者は全て関東なのでarea9
  return totalFee
}

export const shippingProviders = [
  { code: "sagawa", name: "佐川急便", url: "https://k2k.sagawa-exp.co.jp/p/sagawa/web/okurijoinput.jsp" },
  { code: "yupack", name: "ゆうパック", url: "https://trackings.post.japanpost.jp/services/srv/search/input" },
  { code: "yamato", name: "ヤマト運輸", url: "https://toi.kuronekoyamato.co.jp/cgi-bin/tneko" }
]
import { createContext, useState } from "react";

export const SortValueContext = createContext({})

export const SortValueProvider = ({
    children
}: any) => {
    const [isOnSorting, setIsOnSorting] = useState(false)
    const [sortValue, setSortValue] = useState({
        category1: {
            sneaker: false,
            alcohol: false,
            apparel: false,
            car: false,
            interior: false,
            art: false,
        },
        category2: {}
    })

    return (
        <SortValueContext.Provider
            value={{
                sortValue,
                setSortValue,
                isOnSorting,
                setIsOnSorting,
            }}
        >
            {children}
        </SortValueContext.Provider>
    )
}
import React, { useEffect, useState, useContext, useCallback } from 'react';
import { Button, Container, Dialog, List, ListItem, makeStyles, Toolbar, Typography, DialogContentText, DialogActions, CircularProgress, Link, withStyles, Backdrop, Select, InputLabel, FormControl } from '@material-ui/core';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { useParams, withRouter } from 'react-router-dom';
import { motion, useAnimation } from 'framer-motion';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Timer from '../components/Timer';
import { db, TicketStatusValue, auth, CloudFunctions } from "../utils/Firebase";
import { AuthContext } from "../contexts/Auth";
import { pageEnterVariants } from '../Animations/variants';
import { TitleView } from '../components/TitleView';
import common from "../redux/common/commonSlice";
import { useDispatch } from "react-redux";
import { blue } from '@material-ui/core/colors';
import PaymentMethodInput from '../components/detail/PaymentMethodInput';
import ShippingMethodInput from '../components/detail/ShippingMethodInput';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { notifySlackDroppOperation } from '../utils/Notify';
import { saveAs } from 'file-saver'
import ProductImagesView from '../components/common/ProductImagesView';
import { getCountryName } from '../utils/Shipping';
import { resetShippingMethod } from '../redux/shippingMethod/shippingMethodSlice';
import { Product, ProductVariant } from '../types/product';
import { ProcessionStatus, ShippingMethod } from '../types/common';
import { Ticket } from '../types/ticket';
import { useSelector } from '../redux/rootReducer';

const useStyles = makeStyles((theme) => ({
  image_wrapper: {
    position: 'relative',
  },
  display_images: {
    display: "flex",
    justifyContent: "center",
  },
  helper_wrapper: {
    position: "absolute",
    bottom: "20px",
    width: "100%",
    textAlign: "center",
  },
  image_helper_text: {
    textAlign: "center",
    background: "rgba(0,0,0,0.4)",
    color: "white",
    width: "auto",
    display: "inline-block",
    borderRadius: "15px",
    padding: "4px 10px",
  },
  line_images: {
    width: "50px",
    height: "50px",
    margin: "4px",
  },
  line_image: {
    width: "50px",
    display: "block",
  },
  test_bg: {
    position: 'absolute',
    zIndex: 9999,
    width: '100%',
    maxHeight: '630px',
    height: '100%',
    margin: '0 auto',
    top: '0',
  },
  bg_shadow: {
    position: 'absolute',
    backgroundColor: 'rgba(0,0,0,0.2)',
    zIndex: 9999,
    width: '100%',
    maxHeight: '630px',
    height: '100%',
    margin: '0 auto',
    top: '0',
  },
  on_entry_img: {
    width: '40%',
    position: "absolute",
    left: "10px",
    top: "10px",
  },
  winning_img: {
    width: '40%',
    position: "absolute",
    left: "10px",
    top: "10px",
  },
  images_wrapper: {
    maxWidth: "350px",
    width: "80%",
    margin: "0 auto",
  },
  image: {
    width: '100%',
    maxWidth: '350px',
    objectFit: 'contain'
  },
  download_button: {
    border: "none",
    backgroundColor: "#ff0000",
    color: "white",
    padding: "10px 16px",
    fontSize: "0.9rem",
    fontWeight: "bold",
    "&:hover": {
      cursor: "pointer",
    },
  },
  main_container: {
    marginTop: theme.spacing(9),
    paddingBottom: theme.spacing(8),
  },
  card: {
    marginTop: theme.spacing(2),
    height: "100%",
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  item_info: {
    marginTop: theme.spacing(6),
    display: "flex",
    color: '#ff0000',
    justifyContent: 'center',
    minHeight: '30px',
  },
  list_root: {
    marginTop: theme.spacing(0),
  },
  product_info_list: {
    display: 'flex',
    // justifyContent: 'space-between',
    flexFlow: "column",
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    textAlign: 'center',
  },
  product_info_list_title: {
    color: "gray",
    width: '50%',
  },
  product_info_list_text: {
    width: '50%',
  },
  vendor_url: {
    color: '#585858',
  },
  start: {
    fontWeight: 'bold'
  },
  form: {
    display: 'block',
    width: '100%',
    marginBottom: theme.spacing(1),
  },
  product_info_list_form: {
    width: '60%',
    margin: '16px auto',
  },
  description: {
    width: "100%",
    maxWidth: "1020px",
    position: 'relative',
    overflow: 'hidden',
    fontSize: '0.9rem',
    letterSpacing: '0.1rem',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(3),
  },
  desc_html: {
    lineHeight: "1.65",
    '& p': {
      margin: "0",
      padding: "0",
      '&.pi': {
        padding: "10px",
      },
    },
    '& ul': {
      paddingLeft: "1rem",
    },
    '& ol': {
      marginTop: "4px",
      paddingLeft: "1rem",
    }
  },
  share_icons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: 'center',
  },
  share_icon: {
    margin: "8px",
  },
  entry_button_wrap: {
    position: "fixed",
    width: "100%",
    left: "0",
    bottom: "0",
    textAlign: 'center',
    backgroundColor: '#fff',
    padding: "24px",
    boxSizing: "border-box",
    zIndex: 1300,
    [theme.breakpoints.down('sm')]: {
      padding: "16px",
    }
  },
  entry_button: {
    lineHeight: "30px",
    fontSize: '0.9rem',
    width: "100%",
    maxWidth: "1020px",
    margin: "0 auto",
    color: '#fff',
    backgroundColor: "#ff0000",
    fontWeight: 'bold',
    borderRadius: "0",
  },
  term_link: {
    color: 'black',
    fontWeight: 'bold',
  },
  cancel_button: {
    // borderRadius: '2.5em',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
    width: '60%',
    fontSize: '0.9rem',
    fontWeight: 'bold',
    backgroundColor: 'lightgray',
    borderColor: 'lightgray',
    color: 'white',
    borderRadius: "0",
    marginTop: theme.spacing(5),
    [theme.breakpoints.up('sm')]: {
      width: '45%',
    }
  },
  checkbox: {
    marginTop: theme.spacing(2),
  },
  limited_text: {
    width: "100%",
    textAlign: "center",
    color: "gray",
    fontSize: "0.85rem",
  },
  button_wrap: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  signin_button: {
    height: "50px",
    width: "300px",
    margin: "0px auto",
    marginTop: "20px",
    backgroundColor: "#ff0000",
    color: "white",
    fontWeight: "bold",
  },
  signup_button: {
    height: "50px",
    width: "300px",
    margin: "0px auto",
    marginTop: "20px",
    border: "1px solid gray",
    color: "gray",
    fontWeight: "bold",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  full_date_view: {
    display: "flex",
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: "center",
    color: "#ff0000",
  },
  full_date_title: {
    fontSize: "0.75rem",
    lineHeight: "1.5",
    marginBottom: "10px",
    letterSpacing: "1px",
  },
  full_date: {
    color: "#ff0000",
    textAlign: "center",
    lineHeight: "16px",
    fontSize: "2rem",
    fontFamily: "digital7-mono",
    marginBottom: theme.spacing(2)
  },
  full_date_times: {
    margin: "2px",
    letterSpacing: "1.5px",
  }
}));

const CustomDialogContent = withStyles(() => ({
  root: {
    flex: '1 1 auto',
    padding: '0px 22px',
    overflowY: 'auto',
    '&:first-child': {
      paddingTop: '25px',
    }
  },
}))(MuiDialogContent);

const CustomButton = withStyles({
  root: {
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
    },
    '&:focus': {
      boxShadow: 'none',
    }
  },
})(Button);

const DESCRIPTION_DELIMITER_STR = '###fold_below';

type Params = {
  product_id: string
}

const Detail = (props: any) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const elements = useElements();
  const stripe = useStripe();
  const { product_id } = useParams<Params>();
  const [myTicket, setMyTicket] = useState<Ticket | null>(null);
  const [shopLoading, setShopLoading] = useState(true);
  const [productInfo, setProductInfo] = useState<Product>();
  const [variation, setVariation] = useState<ProductVariant | null>(null);
  const [cancelDialogOpen, setCancelDialogOpen] = React.useState(false);
  const [entryProcess, setEntryProcess] = useState(1);
  const { currentUser } = useContext<any>(AuthContext);
  const controls = useAnimation();
  const shippingMethod = useSelector((state) => state.shippingMethod)
  const [states, setStates] = useState({
    selectedShippingMethodStr: "",
    isShippingMethodSave: false,
    shippingErrorMessage: "",
    shippingFee: 0,
    selectedPaymentMethodStr: "",
    isPaymentMethodSave: false,
    paymentErrorMessage: "",
    dialogOpen: false,
    entryDialogOpen: false,
    onChangeEntry: false,
    paymentMethods: [],
  })
  const [optionPrice, setOptionPrice] = useState(0)

  const resetStates = () => {
    setStates({
      selectedShippingMethodStr: "",
      isShippingMethodSave: false,
      shippingErrorMessage: "",
      shippingFee: 0,
      selectedPaymentMethodStr: "",
      isPaymentMethodSave: false,
      paymentErrorMessage: "",
      dialogOpen: false,
      entryDialogOpen: false,
      onChangeEntry: false,
      paymentMethods: [],
    })
  }

  useEffect(() => {
    if (window.location.pathname !== `/detail/${product_id}`) {
      props.history.push(`/detail/${product_id}`)
    }
  }, [])

  const [currentTeaserEmail, setCurrentTeaserEmail] = useState({})
  useEffect(() => {
    const getCurrentUser = async () => {
      if (currentUser) {
        await db.collection('teaser_emails').where('email', '==', currentUser.email).get().then(snapshot => {
          snapshot.forEach(doc => {
            let _current_teaser_emails = doc.data()
            _current_teaser_emails["id"] = doc.id
            setCurrentTeaserEmail(_current_teaser_emails)
          })
        })
      }
    }
    getCurrentUser()
  }, [currentUser])

  // オプション金額の一番低い金額がデフォルトの金額とする
  const calculateDefaultPrice = (product: any) => {
    let prices = product.variants.map((variant: any) => Number(variant.price))
    return Math.min(...prices)
  }

  // 基本データの取得
  useEffect(() => {
    let isMounted = true;

    const f = async () => {
      try {
        let query = db.collection("products").doc(product_id);
        const _product = await query.get();
        const product = _product.data();
        if (!product) return
        if (product.product_type !== '抽選') {
          props.history.push('/')
        }
        const checkedDescription = checkDescription(product.description)
        const productInfo: Product = {
          id: product.id,
          title: product.title,
          subtitle: checkedDescription.subtitle,
          price: calculateDefaultPrice(product),
          images: product.images.map((image: any) => {
            return image.src;
          }),
          deadline_date: product.deadline_date,
          release_date: product.release_date,
          options: product.options,
          description: checkedDescription.product_description,
          updated_at: new Date(product.updated_at),
          created_at: new Date(product.created_at),
          product_type: product.product_type,
          vendor: product.vendor,
          vendor_url: checkedDescription.vendor_url,
          variants: product.variants,
          notion_page_id: product.notion_page_id,
          // firestoreではjson文字列に変換されているのでjsonに変換
          payment_variants: product.payment_variants,
          payment_product_id: product.payment_product_id,
          level: product.level,
          is_authorization: product.is_authorization,
          shipping_fee_name: product.shipping_fee_name,
          showcase_date: product.showcase_date,
          image: product.image,
          owner: product.owner,
          draft_status: product.draft_status,
          auction_fee: product.auction_fee,
          insurance_fee_name: product.insurance_fee_name,
          hasWinImage: product.hasWinImage,
          winImageUrl: product.winImageUrl,
          current_bid_amount: product.current_bid_amount,
          bid_history: product.bid_history,
          expected_winning_bid_amount: product.expected_winning_bid_amount,
          authorization_limit: product.authorization_limit,
          is_banner_view: product.is_banner_view,
        }
        productInfo.hasWinImage = false

        if (isMounted) {
          setVariation(productInfo.variants[0]);
          setProductInfo(productInfo);
          setShopLoading(false);
        }
        controls.start("visible");

      } catch (e) {
        console.error(e);
        if (isMounted) {
          setShopLoading(false);
        }
        controls.start("visible");
      }
    }
    if (product_id) { f(); }

    return () => {
      isMounted = false;
    }

  }, [product_id]);

  // 所持チケット
  useEffect(() => {
    let isMounted = true;

    const f = async (user: any) => {
      if (!productInfo) return
      const mytickets = await db
        .collection("tickets")
        .where("product_id", "==", Number(productInfo.id))
        .where("uid", "==", user.uid).get();
      if (!mytickets.empty) {
        let _tmp = null;
        mytickets.forEach((myticket) => {
          _tmp = myticket;
        });
        if (!_tmp) return
        // @ts-expect-error TS(2531): Object is possibly 'null'.
        let id = _tmp.id;
        // @ts-expect-error TS(2531): Object is possibly 'null'.
        _tmp = await _tmp.data();
        if (isMounted) {
          setMyTicket({ ..._tmp, id });
        }
      }
    };

    if (productInfo) {
      auth.onAuthStateChanged((user) => {
        if (user && currentUser) f(user)
      })
    }

    return () => {
      isMounted = false;
    }

  }, [productInfo, currentUser]);

  // ステータス
  const [processionStatus, setProcessionStatus] = useState<ProcessionStatus | null>(null);
  useEffect(() => {
    let isMounted = true;
    if (!productInfo) return;
    // 公開前 -> notRelease
    // 公開中 -> processionable,processioned,
    // 公開後 -> outOfTerm,waitingPayment,lose,paid,waitingForResult

    // notRelase：リリース前
    if (new Date().getTime() < productInfo.release_date.seconds * 1000) {
      if (isMounted) {
        setProcessionStatus('notRelase');
      }
      return;
    }

    if (!myTicket) {
      // outOfTerm：期間外
      if (new Date().getTime() > productInfo.deadline_date.seconds * 1000) {
        if (isMounted) {
          setProcessionStatus('outOfTerm');
        }
        return;
      }
      // 抽選中・エントリー可
      if (isMounted) {
        setProcessionStatus('processionable');
      }
      return;
    }

    // waitingForResult: 終了後30分は結果表示を待機
    if (myTicket && new Date().getTime() > productInfo.deadline_date.seconds * 1000 && new Date().getTime() < productInfo.deadline_date.seconds * 1000 + 1800 * 1000) {
      if (isMounted) {
        setProcessionStatus('waitingForResult')
      }
      return
    }

    // waitingPayment：当選
    if (myTicket.status == TicketStatusValue["WIN"]) {
      if (isMounted) {
        setProcessionStatus('win');
      }
      return;
    }

    // 事前オーソリがない抽選 + 支払い待ち
    if (myTicket.status == TicketStatusValue["WAITING_PAYMENT"]) {
      if (isMounted) {
        productInfo.variants.forEach((variant: any) => {
          if (variant.title == myTicket.variation['1'] && isMounted) {
            setVariation(variant)
          }
        })
        setProcessionStatus('waitingPayment');
      }
      return;
    }

    // 追加決済
    if (myTicket.status == TicketStatusValue["ADDITIONAL_PAYMENT"]) {
      productInfo.variants.forEach((variant: any) => {
        if (variant.title == myTicket.variation['1'] && isMounted) {
          setOptionPrice(Number(variant.price) - Number(productInfo.price))
          setProcessionStatus('additionalPayment');
        }
      })
      return;
    }

    // lose: 落選(商品がアーカイブされた)
    if (myTicket.status == TicketStatusValue["LOSE"]) {
      if (isMounted) {
        setProcessionStatus('lose');
      }
      return;
    }

    // 支払い済み
    if (myTicket.status == TicketStatusValue["PAID"]) {
      if (isMounted) {
        setProcessionStatus('paid');
      }
      return;
    }

    // processioned：参加中 オーソリ商品
    if (isMounted) {
      setProcessionStatus('processioned')
    }

    return () => {
      isMounted = false;
    }

  }, [myTicket, productInfo]);


  const stripePayment = async () => {
    try {
      if (!stripe || !elements) { return }

      // 1.決済情報保存時のStripe Customer IDを取得
      let stripe_customer_id = ""
      // @ts-expect-error TS(2339): Property 'test_stripe_customer_id' does not exist ... Remove this comment to see the full error message
      if (!currentTeaserEmail?.stripe_customer_id && states.isPaymentMethodSave) {
        // if (!currentTeaserEmail?.test_stripe_customer_id && states.isPaymentMethodSave) {
        // 新規ユーザー カード保存
        const stripeCreateCustomer = CloudFunctions.httpsCallable("stripeCreateCustomer");
        // const stripeCreateCustomer = CloudFunctions.httpsCallable("testStripeCreateCustomer");
        let customer = await stripeCreateCustomer({ email: currentUser.email })
        stripe_customer_id = customer.data.id
      } else if (states.selectedPaymentMethodStr === '-1' && !states.isPaymentMethodSave) {
        // ゲスト支払い(既存 or 新規ユーザー）
        stripe_customer_id = ""
      } else {
        // 既存カード or 既存ユーザ新規カード
        // @ts-expect-error TS(2339): Property 'stripe_customer_id' does not exist ... Remove this comment to see the full error message
        stripe_customer_id = currentTeaserEmail.stripe_customer_id
        // stripe_customer_id = currentTeaserEmail.test_stripe_customer_id
      }

      // 2.peyment intent(支払い)の作成
      let stripeCreatePaymentIntent = CloudFunctions.httpsCallable("stripeCreatePaymentIntent");
      // let stripeCreatePaymentIntent = CloudFunctions.httpsCallable("testStripeCreatePaymentIntent");
      let _payment_amount = 0
      if (myTicket?.status === TicketStatusValue['ADDITIONAL_PAYMENT']) {
        _payment_amount = optionPrice
      } else {
        // @ts-expect-error TS(2531): Object is possibly 'null'.
        let product_amount = Number(variation.price)
        let shipping_fee = states.shippingFee ? Number(states.shippingFee) : 1000
        let insurance_fee = 0
        _payment_amount = product_amount + shipping_fee + insurance_fee
      }

      let res = await stripeCreatePaymentIntent({
        amount: _payment_amount,
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        description: `${productInfo.title}へのエントリーオーソリ. 配送料: ¥${states.shippingFee}, オプション(${variation.title}):  ¥${Number(productInfo.price) - Number(variation.price)}`,
        username: currentUser.email,
        customer_id: stripe_customer_id,
      })
      if (!res.data || !res.data.client_secret) {
        return { status: "fail", paymentIntent: null, errorMessage: "決済に失敗しました。支払い方法を変更いただくか時間をあけて再度お願い致します。" }
      }
      let clientSecret = res.data.client_secret

      // 3.payment_intent(支払い)の確定
      // @ts-expect-error TS(7015): Element implicitly has an 'any' type because index... Remove this comment to see the full error message
      let _payment_method = states.selectedPaymentMethodStr === '-1' ? { card: elements.getElement(CardElement) } : states.paymentMethods[states.selectedPaymentMethodStr].id
      const { error: stripeError, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: _payment_method,
      });

      // 4.支払い結果
      if (stripeError) {
        let _error_message
        switch (stripeError.code) {
          case "insufficient_funds":
            _error_message = "カード残高が不足しています。"
            break
          case "expired_card":
            _error_message = "カードの有効期限が切れています。"
            break
          case "incorrect_cvc":
            _error_message = "カードのセキュリティコードが間違っています。"
            break
          case "processing_error":
            _error_message = "カードの処理中にエラーが発生しました。しばらくしてからもう一度お試しください。"
            break
          default:
            _error_message = "カード決済に失敗しました。カードを変更いただくか時間をあけて再度お願い致します。"
            break
        }
        return { status: "fail", paymentIntent: null, errorMessage: _error_message }
      } else {
        // 新規Stripe決済が成功したらCustomer_idをFirestoreに保存
        // @ts-expect-error TS(2339): Property 'stripe_customer_id' does not exist ... Remove this comment to see the full error message
        if (states.isPaymentMethodSave && !currentTeaserEmail?.stripe_customer_id) {
          // if (states.isPaymentMethodSave && !currentTeaserEmail?.test_stripe_customer_id) {
          await db.collection('teaser_emails').where('email', "==", currentUser.email).get().then(snapshot => {
            snapshot.forEach(async (doc) => {
              await db.collection('teaser_emails').doc(doc.id).update({
                ...doc.data(),
                stripe_customer_id: stripe_customer_id,
                // test_stripe_customer_id: stripe_customer_id,
              })
            })
          })
        }
        return { status: "success", paymentIntent, }
      }
    } catch (e) {
      return { status: "fail", paymentIntent: null, errorMessage: "決済に失敗しました。支払い方法を変更いただくか時間をあけて再度お願い致します。" }
    }
  }

  // 応募アクション
  const handleEnter = async () => {
    setStates({ ...states, onChangeEntry: true, dialogOpen: false, entryDialogOpen: false })

    if (myTicket?.status === TicketStatusValue['ADDITIONAL_PAYMENT']) {
      // 配送先住所を整形
      let _shipping_info: ShippingMethod = {
        address1: "",
        address2: "",
        zipcode: "",
        province: "",
        province_code: "",
        city: "",
        first_name: "",
        second_name: "",
        first_name_kana: "",
        second_name_kana: "",
        country_code: "",
        country: "",
        phone: "",
      }

      if (states.selectedShippingMethodStr == "-1") {
        _shipping_info["address1"] = shippingMethod.address1
        _shipping_info["address2"] = shippingMethod.address2
        _shipping_info["zipcode"] = shippingMethod.zipcode
        _shipping_info["province"] = shippingMethod.province
        _shipping_info["province_code"] = shippingMethod.province_code
        _shipping_info["city"] = shippingMethod.city
        _shipping_info["first_name"] = shippingMethod.firstName
        _shipping_info["second_name"] = shippingMethod.secondName
        _shipping_info["first_name_kana"] = shippingMethod.firstNameKana
        _shipping_info["second_name_kana"] = shippingMethod.secondNameKana
        _shipping_info["country_code"] = shippingMethod.countryCode
        _shipping_info["country"] = getCountryName(shippingMethod.countryCode)
        _shipping_info["phone"] = shippingMethod.phone
      } else {
        // @ts-expect-error TS(2339): Property 'shipping_methods' does not exist on type... Remove this comment to see the full error message
        _shipping_info = currentTeaserEmail.shipping_methods[states.selectedShippingMethodStr]
      }

      // 1.決済
      let res = await stripePayment()
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      if (res.status === 'fail') {
        // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
        setStates({ ...states, paymentErrorMessage: res.errorMessage, onChangeEntry: false, dialogOpen: false })
        scrollTo({ top: 500, behavior: "smooth" })
        return
      }

      // 2.チケット更新
      let _newTicket = myTicket
      _newTicket['shipping_info'] = _shipping_info
      // @ts-expect-error TS(2322): Type 'PaymentIntent | null' is not assignable to t... Remove this comment to see the full error message
      _newTicket['additional_payment_intent'] = res.paymentIntent
      _newTicket['status'] = TicketStatusValue['WIN']
      try {
        await db.collection('tickets').doc(myTicket.id).update({
          // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          additional_payment_intent: res.paymentIntent,
          shipping_info: _shipping_info,
          status: TicketStatusValue['WIN'],
        })

        // 3.決済完了メール
        const sendSuccessAdditionalPayment = CloudFunctions.httpsCallable("sendSuccessAdditionalPayment");
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        await sendSuccessAdditionalPayment({ email: currentUser.email, product_name: productInfo.title })

        // 4.SLACKに決済確定通知
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        let noticeText = `${productInfo.title}の追加決済が完了しました。${res.paymentIntent.id}`
        await notifySlackDroppOperation(noticeText)
      } catch (e) {
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        let noticeText = `${productInfo.title}の追加決済が完了しましたがチケットの更新に失敗しました。${res.paymentIntent.id}`
        await notifySlackDroppOperation(noticeText)
      }
      setMyTicket(_newTicket);
      setStates({ ...states, onChangeEntry: false })
      setProcessionStatus("win")
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      props.history.push(`/detail/${productInfo.id}`)
      setEntryProcess(1)
      window.scrollTo(0, 0)
      dispatch(
        common.actions.fetchAlert({
          alerts: [{ message: "決済が完了しました。" }],
        })
      );

    } else if (myTicket?.status === TicketStatusValue['WAITING_PAYMENT']) {

      // 配送先住所を整形
      let _shipping_info = {}
      if (states.selectedShippingMethodStr == "-1") {
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        _shipping_info["address1"] = shippingMethod.address1
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        _shipping_info["address2"] = shippingMethod.address2
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        _shipping_info["zipcode"] = shippingMethod.zipcode
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        _shipping_info["province"] = shippingMethod.province
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        _shipping_info["province_code"] = shippingMethod.provinceCode
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        _shipping_info["city"] = shippingMethod.city
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        _shipping_info["first_name"] = shippingMethod.firstName
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        _shipping_info["second_name"] = shippingMethod.secondName
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        _shipping_info["first_name_kana"] = shippingMethod.firstNameKana
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        _shipping_info["second_name_kana"] = shippingMethod.secondNameKana
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        _shipping_info["country_code"] = shippingMethod.countryCode
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        _shipping_info["country"] = getCountryName(shippingMethod.countryCode)
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        _shipping_info["phone"] = shippingMethod.phone
      } else {
        // @ts-expect-error TS(2339): Property 'shipping_methods' does not exist on type... Remove this comment to see the full error message
        _shipping_info = currentTeaserEmail.shipping_methods[states.selectedShippingMethodStr]
      }

      // 1.決済
      let res = await stripePayment()
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      if (res.status === 'fail') {
        // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
        setStates({ ...states, paymentErrorMessage: res.errorMessage, onChangeEntry: false, dialogOpen: false })
        scrollTo({ top: 500, behavior: "smooth" })
        return
      }

      // 2.チケット更新
      let _newTicket = myTicket
      // @ts-expect-error TS(2740): Type '{}' is missing the following properties from... Remove this comment to see the full error message
      _newTicket['shipping_info'] = _shipping_info
      // @ts-expect-error TS(2322): Type 'PaymentIntent | null' is not assignable to t... Remove this comment to see the full error message
      _newTicket['payment_intent'] = res.paymentIntent
      _newTicket['status'] = TicketStatusValue['WIN']
      try {
        await db.collection('tickets').doc(myTicket.id).update({
          // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          payment_intent: res.paymentIntent,
          shipping_info: _shipping_info,
          status: TicketStatusValue['WIN'],
        })

        // 3.決済完了メール
        const sendSuccessLotteryPaymentMail = CloudFunctions.httpsCallable("sendSuccessLotteryPaymentMail");
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        await sendSuccessLotteryPaymentMail({ email: currentUser.email, product_name: productInfo.title })

        // 4.SLACKに決済確定通知
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        let noticeText = `${productInfo.title}の決済が完了しました。${res.paymentIntent.id}`
        await notifySlackDroppOperation(noticeText)
      } catch (e) {
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        let noticeText = `${productInfo.title}の決済が完了しましたがチケットの更新に失敗しました。${res.paymentIntent.id}`
        await notifySlackDroppOperation(noticeText)
      }
      setMyTicket(_newTicket);
      setStates({ ...states, onChangeEntry: false })
      setProcessionStatus("win")
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      props.history.push(`/detail/${productInfo.id}`)
      setEntryProcess(1)
      window.scrollTo(0, 0)
      dispatch(
        common.actions.fetchAlert({
          alerts: [{ message: "決済が完了しました。" }],
        })
      );

      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    } else if (productInfo.is_authorization) {
      // 配送先住所を整形
      let _shipping_info = {}
      if (states.selectedShippingMethodStr == "-1") {
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        _shipping_info["address1"] = shippingMethod.address1
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        _shipping_info["address2"] = shippingMethod.address2
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        _shipping_info["zipcode"] = shippingMethod.zipcode
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        _shipping_info["province"] = shippingMethod.province
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        _shipping_info["province_code"] = shippingMethod.provinceCode
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        _shipping_info["city"] = shippingMethod.city
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        _shipping_info["first_name"] = shippingMethod.firstName
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        _shipping_info["second_name"] = shippingMethod.secondName
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        _shipping_info["first_name_kana"] = shippingMethod.firstNameKana
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        _shipping_info["second_name_kana"] = shippingMethod.secondNameKana
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        _shipping_info["country_code"] = shippingMethod.countryCode
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        _shipping_info["country"] = getCountryName(shippingMethod.countryCode)
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        _shipping_info["phone"] = shippingMethod.phone
      } else {
        // @ts-expect-error TS(2339): Property 'shipping_methods' does not exist on type... Remove this comment to see the full error message
        _shipping_info = currentTeaserEmail.shipping_methods[states.selectedShippingMethodStr]
      }

      // ①Stripeオーソリ決済実行
      let res = await stripePayment()
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      if (res.status === 'fail') {
        // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
        setStates({ ...states, paymentErrorMessage: res.errorMessage, onChangeEntry: false, dialogOpen: false })
        scrollTo({ top: 500, behavior: "smooth" })
        return
      }

      // ②Ticket作成
      const createTicket = CloudFunctions.httpsCallable("createTicket");
      // @ts-expect-error TS(2322): Type 'HttpsCallableResult' is not assignable to ty... Remove this comment to see the full error message
      res = await createTicket({
        product_id,
        // @ts-expect-error TS(2531): Object is possibly 'null'.
        variation: { 1: variation.title },
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        payment_intent: res.paymentIntent,
        shipping_info: _shipping_info,
        email: currentUser.email
      });



      // ③エントリー完了メール送信
      const sendLotteryEntryMail = CloudFunctions.httpsCallable("sendLotteryEntryMail");
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      delete productInfo.updated_at
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      await sendLotteryEntryMail({ email: currentUser.email, product: productInfo, productPrice: Number(variation.price), shippingFee: Number(states.shippingFee) })

      // ④住所を保存の場合はTeaser_emailsに保存
      if (states.isShippingMethodSave) {
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        currentTeaserEmail["shipping_methods"] = currentTeaserEmail.shipping_methods?.length > 0 ? [...currentTeaserEmail.shipping_methods, _shipping_info] : [_shipping_info,]
        // @ts-expect-error TS(2339): Property 'id' does not exist on type '{}'.
        await db.collection('teaser_emails').doc(currentTeaserEmail.id).update({
          // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          shipping_methods: currentTeaserEmail["shipping_methods"]
        })
      }

      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      if (res.data.id) {
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        setMyTicket(res.data);
        setStates({ ...states, onChangeEntry: false })
        setProcessionStatus("processioned")
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        props.history.push(`/detail/${productInfo.id}`)
        setEntryProcess(1)
        window.scrollTo(0, 0)
        dispatch(
          common.actions.fetchAlert({
            alerts: [{ message: "エントリーが完了しました。" }],
          })
        );
        dispatch(resetShippingMethod())
      } else {
        setStates({ ...states, onChangeEntry: false })
      }

    } else {
      // 1.Ticket作成
      const createTicket = CloudFunctions.httpsCallable("createTicket");
      // const func = CloudFunctions.httpsCallable("testCreateTicket");
      let res = await createTicket({
        product_id,
        // @ts-expect-error TS(2531): Object is possibly 'null'.
        variation: { 1: variation.title },
        payment_intent: null,
        shipping_info: null,
        email: currentUser.email,
      });

      // 2.エントリー完了メール送信
      // const sendLotteryEntryMail = CloudFunctions.httpsCallable("sendLotteryEntryMail");
      // await sendLotteryEntryMail({ email: currentUser.email, product: productInfo })

      if (res.data.id) {
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        props.history.push(`/detail/${productInfo.id}`)
        dispatch(
          common.actions.fetchAlert({
            alerts: [{ message: "エントリーが完了しました。" }],
          })
        );
        setMyTicket(res.data);
        setStates({ ...states, onChangeEntry: false, entryDialogOpen: false })
      } else {
        console.error("createTicket", res)
      }
    }
  }

  // キャンセルアクション
  const handleCancel = async () => {
    setCancelDialogOpen(false)
    setStates({ ...states, onChangeEntry: true })
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    if (productInfo.is_authorization) {
      // ①Stripeオーソリ決済削除
      const stripeCancelAuthorization = CloudFunctions.httpsCallable("stripeCancelAuthorization");
      // const stripeCancelAuthorization = CloudFunctions.httpsCallable("testStripeCancelAuthorization");
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      await stripeCancelAuthorization({ payment_intent_id: myTicket.payment_intent.id })
    }
    // ②ticket削除
    const deleteTicket = CloudFunctions.httpsCallable("deleteTicket")
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    await deleteTicket({ id: myTicket.id })

    setMyTicket(null);
    resetStates()
    // setStates({...states, onChangeEntry: false })
    dispatch(
      common.actions.fetchAlert({
        alerts: [{ message: "エントリーをキャンセルしました。" }],
      })
    );
  }

  const handleEntryConfirmOpen = async () => {
    setCancelDialogOpen(true);
    return
  };

  const handleEntryConfirmClose = () => {
    setCancelDialogOpen(false);
  };

  const handleToSelectShippingMethod = () => {
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    props.history.push(`/detail/${productInfo.id}/shipping`)
    setEntryProcess(2)
    window.scrollTo(0, 0)
  }

  const ComingSoonDateView = (props: any) => {
    let toDoubleDigits = (num: any) => {
      num += "";
      if (num.length === 1) {
        num = "0" + num;
      }
      return num;
    };

    return (
      <div className={classes.full_date_view}>
        <Typography variant="subtitle2" className={classes.full_date_title}>Dropp開始日</Typography>
        <Typography variant="subtitle1" className={classes.full_date} style={{ marginBottom: "20px" }}>
          <span className={classes.full_date_times}>{toDoubleDigits(props.releaseDate.toDate().getMonth() + 1)}</span>.
          <span className={classes.full_date_times}>{toDoubleDigits(props.releaseDate.toDate().getDate())}</span>.
          <span className={classes.full_date_times}>{props.releaseDate.toDate().getFullYear()}</span>
        </Typography>
      </div>
    )
  }

  const EntryDialogView = () => {
    return <Dialog
      open={states.entryDialogOpen}
      aria-labelledby="draggable-dialog-title"
    >
      <CustomDialogContent>
        <DialogContentText style={{ color: 'black', marginBottom: '0' }}>
          抽選にエントリーします。<br></br>よろしいですか？
        </DialogContentText>
      </CustomDialogContent>
      <DialogActions>
        <Button onClick={() => setStates({ ...states, entryDialogOpen: false })}>いいえ</Button>
        <Button onClick={handleEnter} style={{ color: '#ff0000' }}>はい</Button>
      </DialogActions>
    </Dialog>;
  }

  const CancelDialogView = () => {
    return <Dialog
      open={cancelDialogOpen}
      aria-labelledby="draggable-dialog-title"
    >
      <CustomDialogContent>
        <DialogContentText style={{ color: 'black', marginBottom: '0' }}>
          抽選へのエントリーを取りやめます。<br></br>よろしいですか？
        </DialogContentText>
      </CustomDialogContent>
      <DialogActions>
        <Button onClick={handleEntryConfirmClose}>いいえ</Button>
        <Button onClick={handleCancel} style={{ color: '#ff0000' }}>はい</Button>
      </DialogActions>
    </Dialog>;
  }

  const handleWinImageDownLoad = async () => {
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    const response = await fetch(productInfo.winImageUrl);
    const image = await response.blob();
    saveAs(image, 'win_ticket.jpg');
  }

  // メイン画像
  const ImageSlideView = (props: any) => {
    const images = props.images ? props.images : [];

    if (images.length == 0) {
      return (<></>)
    } else {
      return (
        <>
          <Slider />
          <div className={classes.images_wrapper}>
            {(processionStatus === 'waitingPayment' || processionStatus === 'paid' || processionStatus === 'win') &&
              <>
                {productInfo?.hasWinImage ?
                  <>
                    <div style={{ textAlign: "center" }}>
                      <img className={classes.image} src={productInfo.winImageUrl} />
                    </div>
                    <div style={{ textAlign: "center", margin: "16px 0" }}>
                      <a className={classes.download_button} id="download" onClick={handleWinImageDownLoad}>画像をダウンロードする</a>
                    </div>
                  </>
                  :
                  <>
                    <ProductImagesView images={images} ticketValue="win" />
                  </>
                }
              </>
            }
            {(processionStatus === 'processioned' || processionStatus === 'waitingForResult') &&
              <ProductImagesView images={images} ticketValue="onEntry" />
            }
            {processionStatus === 'lose' &&
              <ProductImagesView images={images} ticketValue="lose" />
            }
            {(processionStatus === 'processionable' || processionStatus === 'outOfTerm' || processionStatus === 'notRelase') &&
              <ProductImagesView images={images} ticketValue="" />
            }
          </div>
        </>
      )
    }
  };

  // 説明テキスト部分
  const DescriptionView = (props: any) => {
    return (
      <Container className={classes.description}>
        <span className={classes.desc_html} dangerouslySetInnerHTML={{ __html: props.htmlString.replace(DESCRIPTION_DELIMITER_STR, "") }}></span>
        {/* キャンセルボタン */}
        {processionStatus === 'processioned' &&
          <Button className={classes.cancel_button} variant='outlined' onClick={() => { handleEntryConfirmOpen() }}>
            エントリーをやめる
          </Button>
        }
        {/* 参加ボタン */}
        {(processionStatus === 'processionable' && !states.onChangeEntry) &&
          <Toolbar className={classes.entry_button_wrap}>
            <Button disabled={states.onChangeEntry} className={classes.entry_button} onClick={() => {
              // @ts-expect-error TS(2532): Object is possibly 'undefined'.
              productInfo.is_authorization ? handleToSelectShippingMethod() : setStates({ ...states, entryDialogOpen: true })
            }}>
              抽選にエントリーする
            </Button>
          </Toolbar>
        }
        {processionStatus === 'waitingPayment' &&
          <Toolbar className={classes.entry_button_wrap}>
            <Button disabled={states.onChangeEntry} className={classes.entry_button} onClick={handleToSelectShippingMethod}>
              決済を確定する
            </Button>
          </Toolbar>
        }
        {processionStatus === 'additionalPayment' &&
          <Toolbar className={classes.entry_button_wrap}>
            <Button disabled={states.onChangeEntry} className={classes.entry_button} onClick={handleToSelectShippingMethod}>
              オプション費用の決済を始める
            </Button>
          </Toolbar>
        }
        <CancelDialogView />
        <EntryDialogView />
      </Container>
    )
  }

  const LimitedDescriptionView = (props: any) => {
    let text = props.htmlString.slice(0, 250) + "..."
    return (
      <Container className={classes.description}>
        <span className={classes.desc_html} dangerouslySetInnerHTML={{ __html: text }}></span>
      </Container>
    )
  }

  // バリエーション選択
  // @ts-expect-error TS(2554): Expected 2 arguments, but got 1.
  const handleVariationChange = useCallback((e) => {
    e.preventDefault();
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    setVariation(productInfo.variants[e.target.value]);
  });

  const setMeta = () => {
    const headData = document.head.children;
    const title = productInfo ? `${productInfo.title}エントリーページ` : '抽選エントリーページ';
    const description = productInfo ? `${productInfo.title}の抽選にエントリー` : 'Dropp商品へのエントリーはこちら';
    const keywords = productInfo ? `${productInfo.title} エントリー 抽選 Dropp` : 'エントリー 抽選 Dropp';

    for (let i = 0; i < headData.length; i++) {
      const nameVal = headData[i].getAttribute('name');
      if (nameVal !== null) {
        if (nameVal.indexOf('keywords') != -1) {
          headData[i].setAttribute('content', keywords);
        }
        if (nameVal.indexOf('description') != -1) {
          headData[i].setAttribute('content', description);
        }
        // OGP(twitter)の設定
        if (nameVal.indexOf('twitter:title') != -1) {
          headData[i].setAttribute('content', title);
        }
        if (nameVal.indexOf('twitter:description') != -1) {
          headData[i].setAttribute('content', description);
        }
      }
    }
  }

  const checkDescription = (description: any) => {
    let res = [description];
    let res2;
    let subtitle;
    let vendor_url;
    if (res[0].split('###vendor_url').length >= 2) {
      res2 = res[0].split('###vendor_url');
      vendor_url = res2[1].replace(/(<([^>]+)>)/gi, '').trim();
      res[0] = res2[0]
    }
    if (res[0].split('###subtitle').length >= 2) {
      res2 = res[0].split('###subtitle');
      subtitle = res2[1].replace(/(<([^>]+)>)/gi, '');
      res[0] = res2[0]
    }
    const desc = res[0];
    return {
      product_description: desc,
      subtitle: subtitle,
      vendor_url: vendor_url,
    }
  };

  const TwitterShare = () => {
    const twitterBaseUrl = "https://twitter.com/intent/tweet?url=https://dropp.jp&text=抽選も楽しめる次世代型オークションハウス&hashtags=Dropp";
    window.open(twitterBaseUrl, "_blank")
  }

  const InstagramShare = () => {
    const instagramBaseUrl = "https://www.instagram.com/dropp.jp/?hl=ja";
    window.open(instagramBaseUrl, "_blank")
  }

  const handleSignin = () => {
    props.history.push('/signin')
  }

  const handleSignup = () => {
    props.history.push('/signup/email')
  }

  setMeta();

  return (
    <React.Fragment key={product_id}>
      {!shopLoading &&
        <>
          <motion.div
            initial={{ opacity: 0, scale: 1.05 }}
            variants={pageEnterVariants}
            animate={controls}
            exit={{ opacity: 0, transition: { duration: 0.8 }, scale: 1.05 }}
          >
            {entryProcess === 1 &&
              <Container className={classes.main_container}>
                <div className={classes.card}>
                  <div className={classes.item_info}>
                    {processionStatus === 'notRelase' ? <ComingSoonDateView releaseDate={productInfo?.release_date} />
                      : <Timer variant="subtitle1" endDateTime={new Date().setTime(productInfo?.deadline_date.seconds * 1000)} />
                    }
                  </div>
                  <ImageSlideView images={productInfo?.images} />
                  <TitleView title={productInfo?.title} subtitle={productInfo?.subtitle}></TitleView>
                </div>
                <List className={classes.list_root}>
                  <ListItem className={classes.product_info_list}>
                    <Typography variant='subtitle2' className={classes.product_info_list_title}>価格 (税込み)</Typography>
                    <Typography variant='subtitle1' className={classes.product_info_list_text}>¥{String(productInfo?.price).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}</Typography>
                  </ListItem>
                  <ListItem className={classes.product_info_list}>
                    <Typography variant='subtitle2' className={classes.product_info_list_title}>購入方法</Typography>
                    <Typography variant='subtitle1' className={classes.product_info_list_text}>{productInfo?.product_type}</Typography>
                  </ListItem>
                  <ListItem className={classes.product_info_list}>
                    {processionStatus === 'notRelase' ?
                      <>
                        <Typography variant='subtitle2' className={classes.product_info_list_title}>エントリー開始日時</Typography>
                        <Typography variant='subtitle1' className={classes.product_info_list_text}>{new Date(productInfo?.release_date.seconds * 1000).toLocaleString("ja")}</Typography>
                      </> : <>
                        <Typography variant='subtitle2' className={classes.product_info_list_title}>エントリー締切日時</Typography>
                        <Typography variant='subtitle1' className={classes.product_info_list_text}>{new Date(productInfo?.deadline_date.seconds * 1000).toLocaleString("ja")}</Typography>
                      </>
                    }
                  </ListItem>
                  <ListItem className={classes.product_info_list}>
                    <Typography variant='subtitle2' className={classes.product_info_list_title}>販売元</Typography>
                    <Typography variant='subtitle1' className={classes.product_info_list_text}>
                      {productInfo?.vendor_url ?
                        <Link underline="always" className={classes.vendor_url} href={productInfo?.vendor_url} target="_blank" rel="noopener">
                          {productInfo?.vendor}
                        </Link>
                        : <>{productInfo?.vendor}</>
                      }
                    </Typography>
                  </ListItem>
                  {productInfo?.options && productInfo?.options.length > 0 && productInfo?.options[0].name != 'Title' &&
                    <>
                      {processionStatus === 'processionable' ?
                        <ListItem className={classes.product_info_list_form}>
                          <FormControl variant='outlined' className={classes.form}>
                            <InputLabel id="product_type">{productInfo.options[0].name}</InputLabel>
                            <Select
                              native
                              label={productInfo.options[0].name}
                              labelId="product_type"
                              style={{ width: '100%', height: '40px', borderRadius: '0' }}
                              onChange={handleVariationChange}
                            >
                              {productInfo.variants.map((variant: any, index: any) => (
                                <option value={index} key={index}>{variant.title}</option>
                              ))}
                            </Select>
                          </FormControl>
                        </ListItem>
                        :
                        <>
                          {myTicket &&
                            <ListItem className={classes.product_info_list}>
                              <Typography variant='subtitle2' className={classes.product_info_list_title}>選択中</Typography>
                              <Typography variant='subtitle1' className={classes.product_info_list_text}>{Object.values(myTicket.variation).join("/")}</Typography>
                            </ListItem>
                          }
                        </>
                      }
                    </>
                  }
                  <ListItem className={classes.share_icons}>
                    <Link onClick={TwitterShare}><TwitterIcon style={{ color: blue[500] }} className={classes.share_icon} /></Link>
                    <Link onClick={InstagramShare}><InstagramIcon color="secondary" className={classes.share_icon} /></Link>
                  </ListItem>
                </List>
                {(currentUser && currentUser.phoneNumber != null) ?
                  <>
                    <DescriptionView htmlString={productInfo?.description} />
                  </>
                  :
                  <>
                    <LimitedDescriptionView htmlString={productInfo?.description} />
                    <div className={classes.button_wrap}>
                      {!currentUser &&
                        <>
                          <Typography variant="subtitle1" className={classes.limited_text}>続きを読むにはアカウント登録が必要です。</Typography>
                          <CustomButton onClick={handleSignin} variant="contained" className={classes.signin_button}>ログイン</CustomButton>
                          <CustomButton onClick={handleSignup} variant="outlined" className={classes.signup_button}>アカウント作成</CustomButton>
                        </>
                      }
                      {(currentUser && currentUser.phoneNumber == null) &&
                        <>
                          <Typography variant="subtitle1" className={classes.limited_text}>続きを読むには電話番号登録が必要です。</Typography>
                          <CustomButton onClick={() => props.history.push('/signup/sms')} variant="contained" className={classes.signin_button}>電話番号認証</CustomButton>
                        </>
                      }
                    </div>
                  </>
                }
              </Container>
            }
            {entryProcess === 2 &&
              <ShippingMethodInput
                productInfo={productInfo}
                myTicket={myTicket}
                currentTeaserEmail={currentTeaserEmail}
                states={states} setStates={setStates}
                setEntryProcess={setEntryProcess}
                optionPrice={optionPrice}
                variation={variation}
              />}
            {entryProcess === 3 &&
              <PaymentMethodInput
                // @ts-expect-error TS(2322): Type 'Product | undefined' is not assignable to ty... Remove this comment to see the full error message
                productInfo={productInfo}
                myTicket={myTicket} setMyTicket={setMyTicket} currentTeaserEmail={currentTeaserEmail}
                states={states} setStates={setStates} setCurrentTeaserEmail={setCurrentTeaserEmail}
                setEntryProcess={setEntryProcess}
                handleEnter={handleEnter}
                optionPrice={optionPrice}
                variation={variation}
              />}
            <Backdrop className={classes.backdrop} open={states.onChangeEntry} >
              <CircularProgress color='inherit' />
            </Backdrop>
          </motion.div>
        </>
      }
    </React.Fragment >
  );
};

export default withRouter(Detail);
import React from "react";
import {
  Container,
  makeStyles,
  Typography,
  Box,
  Theme,
  createStyles,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import Copyright from "../components/Copyright";

const useStyles = makeStyles((theme: Theme) => createStyles({
  paper: {
    marginTop: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0 10px'
  },
  root: {
    margin: "0 auto",
    height: "100%",
    padding: '0',
    marginBottom: theme.spacing(8)
  },
  title: {
    fontSize: "1.3rem",
    lineHeight: "2em",
    marginTop: theme.spacing(12),
    fontWeight: 'bold',
    textAlign: 'center',
    width: '100%',
    color: 'grey',
  },
  box: {
    maxWidth: '670px',
    padding: '10px 20px',
    margin: "auto",
    marginBottom: theme.spacing(4)
  },
  dl_container: {
    marginTop: theme.spacing(3),
    '&:first-child': {
      marginTop: '0',
    }
  },
  subtitle: {
    fontSize: "1rem",
    fontWeight: 500,
    color: 'grey',
  },
  text: {
    fontSize: '1rem'
  },
}));

const Tokutei = () => {
  const classes = useStyles();

  const TokuteiText = () => {
    return (
      <>
        <div className={classes.dl_container}>
          <Typography variant="subtitle1" className={classes.subtitle}>サイト名</Typography>
          <Typography variant="subtitle2" className={classes.text}>Dropp</Typography>
        </div>
        <div className={classes.dl_container}>
          <Typography variant="subtitle1" className={classes.subtitle}>販売事業者</Typography>
          <Typography variant="subtitle2" className={classes.text}>株式会社 Dropp</Typography>
        </div>
        <div className={classes.dl_container}>
          <Typography variant="subtitle1" className={classes.subtitle}>運営統括責任者</Typography>
          <Typography variant="subtitle2" className={classes.text}>西方雄作</Typography>
        </div>
        <div className={classes.dl_container}>
          <Typography variant="subtitle1" className={classes.subtitle}>所在地</Typography>
          <Typography variant="subtitle2" className={classes.text}>〒150-0043 <br></br>東京都渋谷区道玄坂１丁目２−３ 渋谷フクラス 17階</Typography>
        </div>
        <div className={classes.dl_container}>
          <Typography variant="subtitle1" className={classes.subtitle}>メールアドレス</Typography>
          <Typography variant="subtitle2" className={classes.text}>info@dropp.jp</Typography>
        </div>
        <div className={classes.dl_container}>
          <Typography variant="subtitle1" className={classes.subtitle}>支払い方法</Typography>
          <Typography variant="subtitle2" className={classes.text}>クレジットカード決済 ( VISA / MASTER / JCB / AMEX / ダイナース )</Typography>
        </div>
        <div className={classes.dl_container}>
          <Typography variant="subtitle1" className={classes.subtitle}>支払い時期</Typography>
          <Typography variant="subtitle2" className={classes.text}>クレジットカード決済：抽選申込時・オークション入札時</Typography>
        </div>
        <div className={classes.dl_container}>
          <Typography variant="subtitle1" className={classes.subtitle}>申込みの有効期限</Typography>
          <Typography variant="subtitle2" className={classes.text}>抽選申込前まで・オークション入札前まで</Typography>
        </div>
        <div className={classes.dl_container}>
          <Typography variant="subtitle1" className={classes.subtitle}>商品の引渡し時期</Typography>
          <Typography variant="subtitle2" className={classes.text}>
            抽選結果の通知後１０営業日以内に発送<br />
            オークション落札後１０営業日以内に発送<br />
            先着購入後３営業日以内に発送
          </Typography>
        </div>
        <div className={classes.dl_container}>
          <Typography variant="subtitle1" className={classes.subtitle}>商品代金以外の必要料金の説明</Typography>
          <Typography variant="subtitle2" className={classes.text}>
            <ul style={{ paddingLeft: '1rem', marginTop: '0' }}>
              <li>郵便為替手数料</li>
              <li>送料</li>
              <li>クール郵送料</li>
              <li>ギフト包装料金</li>
            </ul>
          </Typography>
          <div className={classes.dl_container}>
            <Typography variant="subtitle1" className={classes.subtitle}>返品期限</Typography>
            <Typography variant="subtitle2" className={classes.text}>
              入札完了後又は抽選期間終了後のキャンセル、配送後のお客様都合での返品・交換は承っておりません。<br />
              入札完了又抽選期間終了前に、商品の注意事項等を必ずご確認ください。<br />
              商品の注意事項に記載のある事項以外の当社原因による商品の欠陥・不良につきましては、商品到着後5日以内にinfo@dropp.jpまでメールにてご連絡ください。追って返品に関してご連絡させていただきます。<br />
              なお、ご連絡なく返送された場合及びご使用になられた商品につきましては返金致しかねます。<br />
              ※いかなる場合でも代替品の提供・交換は行いません。<br />
            </Typography>
          </div>
        </div>
      </>
    )
  }

  return (
    <React.Fragment>
      <Container component="main" maxWidth="lg" className={classes.root}>
        <Typography variant="h4" component="h1" className={classes.title}>特定商取引法に基づく表示</Typography>
        <Box className={classes.paper}>
          <div className={classes.box}>
            <TokuteiText />
          </div>
        </Box>
      </Container>
      <Copyright />
    </React.Fragment>
  );
};

export default withRouter(Tokutei);

import React from 'react'
import { makeStyles, Container, Button, TableContainer, TableHead, TableRow, TableCell, TableBody, Paper, Table } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Product } from '../types/product';

const useStyles = makeStyles((theme) => ({
    main: {
        marginTop: theme.spacing(8),
    },
    search_input_wrapper: {
        display: "flex",
        alignItems: "center",
        marginBottom: "20px",
    },
    table_row: {
        '&:hover': {
            cursor: "pointer",
            backgroundColor: "lightgray",
        }
    },
    table_image: {
        width: "80px",
    },
    button: {
        borderRadius: "0",
        border: "1px solid #ff0000",
        color: "#ff0000",
        marginLeft: "20px",
    },
}))


const AdminProducts = () => {
    const classes = useStyles();
    const history = useHistory()
    // const [products, setProducts] = useState<Product[]>([])
    const products: Product[] = []

    return (
        <Container maxWidth="md">
            <Container className={classes.main} maxWidth="md">
                <div>
                    <Button
                        className={classes.button}
                        variant="outlined"
                    >
                        検索
                    </Button>
                </div>

                <div>
                    <TableContainer component={Paper}>
                        <Table aria-label="products table">
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>ID</TableCell>
                                    <TableCell>商品名</TableCell>
                                    <TableCell>開始日</TableCell>
                                    <TableCell>締切日</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {products.map(product => (
                                    <TableRow className={classes.table_row} key={product.id} onClick={() => history.push(`admin/products/${product.id}`)}>
                                        {product.images.length > 0 && <TableCell size='small'><img className={classes.table_image} src={product.images[0].src} /></TableCell>}
                                        <TableCell>{product.id}</TableCell>
                                        <TableCell>{product.title}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>

            </Container>
        </Container>
    )
}

export default AdminProducts;
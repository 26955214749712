import React, { memo, useState, useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  List,
  ListItem,
  makeStyles,
  MuiThemeProvider,
  Radio,
  RadioGroup,
  Typography,
  withStyles,
} from "@material-ui/core";
import { createMuiTheme } from '@material-ui/core';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { CloudFunctions, db, TicketStatusValue } from "../../utils/Firebase";
import { useParams, useHistory } from "react-router-dom";
import { AuthContext } from "../../contexts/Auth";
import Common from "../../redux/common/commonSlice";
import PaymentInfoView from "./PaymentInfoView";
import { formatedYen } from "../../utils/Common";
import { notifySlackDroppOperation } from "../../utils/Notify";
import { getCountryName } from "../../utils/Shipping";
import { Product } from "../../types/product";
import { PaymentMethod } from "@stripe/stripe-js";
import { useSelector } from "../../redux/rootReducer";

const cardElementOptions = {
  hidePostalCode: true,
  style: {
    base: {
    },
    invalid: {},
    complete: {},
  },
}

type CheckboxType = {
  checked: any,
  onChange: any
}
const CustomCheckBox = withStyles({
  root: {
    color: "#ff0000",
    '&$checked': {
      color: "#ff0000",
    },
  },
  checked: {},
})((props: CheckboxType) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
  input: {
    background: theme.palette.secondary.main,
    border: `1px solid white`,
    flex: 1,
    padding: '8px',
    '&[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  main_container: {
    marginTop: theme.spacing(12),
    paddingBottom: theme.spacing(8),
    maxWidth: "450px",
  },
  card: {
    padding: "20px",
    margin: "0 auto",
    marginTop: "10px",
    position: "relative"
  },
  product_info_wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
  },
  image_wrapper: {
    marginBottom: "16px",
  },
  image: {
    maxWidth: '100%',
    objectFit: 'contain'
  },
  product_title: {
    fontSize: "1rem",
    marginTop: theme.spacing(2),
  },
  lists: {
    paddingBottom: "12px",
    marginBottom: "30px",
  },
  list: {
    display: "flex",
    textAlign: "center",
    marginTop: theme.spacing(1),
  },
  list_title: {
    width: "50%",
  },
  list_text: {
    width: "50%",
  },
  list_auth_text: {
    width: "50%",
    color: "#ff0000",
  },
  bid_input_wrapper: {
    margin: "24px 0 10px",
    display: "flex",
    alignItems: "center",
  },
  bid_input_label: {
    textAlign: "center",
    width: "50%",
    lineHeight: "40px",
  },
  select_form_control: {
    width: "100%",
  },
  error_message: {
    fontWeight: "bold",
    color: "#ff0000",
  },
  card_wrapper: {
    minHeight: "100px",
    marginBottom: "50px",
  },
  credit_card: {
    border: "1px solid black",
    width: "100%",
    position: "relative",
    left: "10px",
    marginTop: "12px",
  },
  selected_credit_card: {
    border: "1px solid rgba(0, 0, 0, 0.38)",
    width: "340px",
    position: "relative",
    left: "10px",
    marginTop: "12px",
  },
  label: {
    width: "100%",
    display: "flex",
    alignItems: "justify-content",
  },
  add_button: {
    borderRadius: "20px",
    margin: "16px 0",
    border: "1px solid black",
  },
  card_element: {
    marginTop: "10px",
    marginBottom: "16px",
    border: "1px solid rgba(0, 0, 0, 0.38)",
    borderRadius: "0",
    padding: "15px",
    boxSizing: "border-box",
    minWidth: "100%",
  },
  selected_card_element: {
    marginTop: "10px",
    marginBottom: "16px",
    border: "1px solid black",
    borderRadius: "0",
    padding: "15px",
  },
  shipping_method_view: {
    marginBottom: "60px",
  },
  update_button: {
    color: "#ff0000",
    borderRadius: "0",
    border: "1px solid #ff0000",
  },
  shipping_method_wrap: {
    display: "flex",
    alignItems: "center",
    marginBottom: "0.5rem",
    width: "100%",
  },
  shipping_method_title: {
    color: "grey",
    width: "20%",
    verticalAlign: "middle"
  },
  shipping_method_text: {
    width: "70%",
    color: "grey",
  },
  shipping_method: {
    width: "100%",
    boxSizing: "border-box",
    marginBottom: "16px",
  },
  botton_wrapper: {
    display: "flex",
  },
  next_botton: {
    color: "red",
    marginLeft: "10px",
  },
  list_root: {
    marginBottom: "20px",
  },
  product_info_list: {
    display: "flex",
    textAlign: "center",
  },
  product_info_list_title: {
    width: "50%",
  },
  product_info_list_text: {
    width: "50%",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#000',
      main: '#000',
      dark: '#000',
      contrastText: '#fff',
    },
  },
});

type Props = {
  productInfo: Product,
  states: any,
  setStates: any,
  myTicket: any,
  setMyTicket: any,
  currentTeaserEmail: any,
  paymentStates: any,
  setPaymentStates: any,
}

type Params = {
  product_id: string
}
const PaymentView = memo((props: Props) => {
  const { productInfo, states, setStates, myTicket, setMyTicket, currentTeaserEmail, paymentStates, setPaymentStates } = props
  const { currentUser } = useContext<any>(AuthContext);
  const { product_id } = useParams<Params>();
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory()
  const [dialogOpen, setDialogOpen] = useState(false)
  const shippingMethod = useSelector((state) => state.shippingMethod)

  const elements = useElements();
  const stripe = useStripe();

  // 支払い用カードの取得
  const [paymentMethods, setPaymentMethods] = useState([])
  useEffect(() => {
    let isMounted = true;
    const getPaymentMehtod = async () => {
      if (currentTeaserEmail?.stripe_customer_id) {
        // if (currentTeaserEmail?.test_stripe_customer_id) {
        setPaymentStates({ ...paymentStates, onPaymentMethodsLoading: true })
        let stripeGetPaymentMethods = CloudFunctions.httpsCallable("stripeGetPaymentMethods");
        // let stripeGetPaymentMethods = CloudFunctions.httpsCallable("testStripeGetPaymentMethods");
        let customer_id = currentTeaserEmail.stripe_customer_id
        // let customer_id = currentTeaserEmail.test_stripe_customer_id
        try {
          let _payment_methods = await stripeGetPaymentMethods({ customer_id: customer_id })
          let selectedPaymentMethodStr = _payment_methods.data.data.length > 0 ? "0" : "-1"
          if (isMounted) {
            setPaymentMethods(_payment_methods.data.data)
            setPaymentStates({
              ...paymentStates,
              onPaymentMethodsLoading: false,
              selectedPaymentMethodStr: selectedPaymentMethodStr,
              isPaymentMethodSave: selectedPaymentMethodStr === '-1',
            })
          }
        } catch (e) {
          if (isMounted) {
            setPaymentStates({ ...paymentStates, onPaymentMethodsLoading: false, isPaymentMethodSave: true, selectedPaymentMethodStr: "-1" })
            setPaymentMethods([])
          }
        }
      } else {
        if (isMounted) {
          setPaymentStates({ ...paymentStates, onPaymentMethodsLoading: false, isPaymentMethodSave: true, selectedPaymentMethodStr: "-1" })
          setPaymentMethods([])
        }
      }
    }
    getPaymentMehtod()
    return () => {
      isMounted = false
    }
  }, [])

  const bidProcesFinishAndToAuctionTop = (_message: any) => {
    history.push(`/auction/${productInfo.id}`)
    setStates({ ...states, onBidProcess: false, selectedShippingMethodStr: "", bidProcess: 0 })
    dispatch(
      Common.actions.fetchAlert({
        alerts: [{ message: _message }],
      })
    );
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 100)
  }

  const handlePayment = async () => {
    setDialogOpen(false)
    setStates({ ...states, onBidProcess: true })

    // 1.決済を実行
    const paymentResult = await stripePayment()
    if (paymentResult?.status === 'failed') {
      setStates({ ...states, onBidProcess: false })
      setPaymentStates({ ...paymentStates, paymentErrorMessage: paymentResult.errorMessage })
      scrollTo({ top: 500, behavior: "smooth" })
      return
    }

    // 2.データを更新
    try {
      await db.collection('tickets').doc(myTicket.id).update({
        authorization_history: [...myTicket.authorization_history, paymentResult?.paymentIntent?.id],
        payment_intent: paymentResult?.paymentIntent,
        status: TicketStatusValue['WIN'],
      })

      // 決済完了メール
      const sendSuccessAuctionPayment = CloudFunctions.httpsCallable("sendSuccessAuctionPayment");
      await sendSuccessAuctionPayment({ email: currentUser.email, product_name: productInfo.title })

      let noticeText = `【オークション決済】: ${productInfo.title}の決済が完了しました。${paymentResult?.paymentIntent?.id}`
      await notifySlackDroppOperation(noticeText)

    } catch (e) {
      console.log('e', e)
      setStates({ ...states, onBidProcess: false })
      return
    }

    let newTicket = myTicket
    newTicket.status === TicketStatusValue['WIN']
    // 入札結果表示
    setMyTicket(newTicket);
    bidProcesFinishAndToAuctionTop("決済が完了いたしました。発送完了まで今しばらくお待ちください。")
  }

  const stripePayment = async () => {
    try {
      if (!stripe || !elements) { return }

      // 1.決済情報保存時のStripe Customer IDを取得
      let stripe_customer_id = ""
      if (paymentStates.isPaymentMethodSave && !currentTeaserEmail?.stripe_customer_id) {
        // if (paymentStates.isPaymentMethodSave && !currentTeaserEmail?.test_stripe_customer_id) {
        const stripeCreateCustomer = CloudFunctions.httpsCallable("stripeCreateCustomer");
        // const stripeCreateCustomer = CloudFunctions.httpsCallable("testStripeCreateCustomer");
        let customer = await stripeCreateCustomer({ email: currentUser.email })
        stripe_customer_id = customer.data.id
      } else if (!paymentStates.isPaymentMethodSave && paymentStates.selectedPaymentMethodStr === '-1') {
        stripe_customer_id = ""
      } else {
        stripe_customer_id = currentTeaserEmail.stripe_customer_id
        // stripe_customer_id = currentTeaserEmail.test_stripe_customer_id
      }

      // 2.peyment intent(支払い)の作成
      let stripeCreatePaymentIntent = CloudFunctions.httpsCallable("stripeCreatePaymentIntent");
      // let stripeCreatePaymentIntent = CloudFunctions.httpsCallable("testStripeCreatePaymentIntent");
      let win_amount = productInfo.current_bid_amount
      let dropp_fee = win_amount * productInfo.auction_fee / 100
      let shipping_fee = paymentStates.shippingFee
      let insurance_fee = paymentStates.insuranceFee ? paymentStates.insuranceFee : 0
      let authorization_amount = win_amount + dropp_fee + shipping_fee + insurance_fee
      if (productInfo.highest_bid_ticket && productInfo.highest_bid_ticket.uid === myTicket?.uid) {// 事前にオーソリしている分
        authorization_amount = authorization_amount - myTicket.authorization_amount
      }
      console.log('authorization amount : ', authorization_amount)
      let res = await stripeCreatePaymentIntent({
        amount: authorization_amount,
        description: `${productInfo.title}の落札後決済(事後決済 or 差額決済)`,
        username: currentUser.email,
        customer_id: stripe_customer_id,
      })
      let clientSecret = res.data.client_secret
      if (!clientSecret) return;

      // 3.payment_intent(支払い)の確定
      // @ts-ignore
      let _payment_method = paymentStates.selectedPaymentMethodStr === '-1' ? { card: elements.getElement(CardElement) } : paymentMethods[paymentStates.selectedPaymentMethodStr].id
      const { error: stripeError, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: _payment_method,
      });

      // 4.支払い結果
      if (stripeError) {
        let _error_message
        switch (stripeError.code) {
          case "insufficient_funds":
            _error_message = "カード残高が不足しています。"
            break
          case "expired_card":
            _error_message = "カードの有効期限が切れています。"
            break
          case "incorrect_cvc":
            _error_message = "カードのセキュリティコードが間違っています。"
            break
          case "processing_error":
            _error_message = "カードの処理中にエラーが発生しました。しばらくしてからもう一度お試しください。"
            break
          default:
            _error_message = "カード決済に失敗しました。カードを変更いただくか時間をあけて再度お願い致します。"
            break
        }
        return { status: "failed", paymentIntent: null, errorMessage: _error_message }
      } else {
        if (paymentStates.isPaymentMethodSave && !currentTeaserEmail?.stripe_customer_id) {
          await db.collection('teaser_emails').where('email', "==", currentUser.email).get().then(snapshot => {
            snapshot.forEach(async (doc) => {
              await db.collection('teaser_emails').doc(doc.id).update({
                ...doc.data(),
                stripe_customer_id: stripe_customer_id
                // test_stripe_customer_id: stripe_customer_id
              })
            })
          })
        }
        return { status: "success", paymentIntent, }
      }
    } catch (e) {
      console.log('error', e)
      return { status: "failed", paymentIntent: null, errorMessage: "決済に失敗しました。支払い方法を変更いただくか時間をあけて再度お願い致します。" }
    }
  }

  const CheckDialogView = () => {
    let _winAmount = productInfo.current_bid_amount
    let _dropp_fee = _winAmount * productInfo.auction_fee / 100
    let _shipping_fee = paymentStates.shippingFee
    let _insuranceFee = paymentStates.insuranceFee ? paymentStates.insuranceFee : 0
    let _totalPrice = Number(_winAmount) + Number(_shipping_fee) + Number(_dropp_fee) + Number(_insuranceFee) - (productInfo?.highest_bid_ticket?.authorization_amount ?? 0)
    return (
      <Dialog
        open={dialogOpen}
      >
        <DialogContent style={{ color: 'black', marginBottom: '0' }}>
          <div style={{ marginBottom: "30px" }}>
            <List>
              <ListItem className={classes.list}>
                <Typography variant='subtitle2' className={classes.list_title}>落札価格</Typography>
                <Typography variant='subtitle2' className={classes.list_text}>¥ {formatedYen(productInfo.current_bid_amount)}</Typography>
              </ListItem>
              <ListItem className={classes.list}>
                <Typography variant='subtitle2' className={classes.list_title}>手数料<br></br>(入札価格の{productInfo.auction_fee}%)</Typography>
                <Typography variant='subtitle2' className={classes.list_text}>¥ {formatedYen(productInfo.current_bid_amount * productInfo.auction_fee / 100)}</Typography>
              </ListItem>
              {paymentStates.insuranceFee > 0 &&
                <ListItem className={classes.list}>
                  <Typography variant='subtitle2' className={classes.list_title}>保険料</Typography>
                  <Typography variant='subtitle2' className={classes.list_text}>¥ {formatedYen(paymentStates.insuranceFee)}</Typography>
                </ListItem>
              }
              <ListItem className={classes.list}>
                <Typography variant='subtitle2' className={classes.list_title}>配送料</Typography>
                <Typography variant='subtitle2' className={classes.list_text}>¥ {formatedYen(paymentStates.shippingFee)}</Typography>
              </ListItem>
              {productInfo.is_authorization &&
                <ListItem className={classes.list}>
                  <Typography variant='subtitle2' className={classes.list_title}>事前オーソリ金額</Typography>
                  <Typography variant='subtitle2' className={classes.list_auth_text}>- ¥ {formatedYen(productInfo.highest_bid_ticket?.authorization_amount)}</Typography>
                </ListItem>
              }
              <ListItem className={classes.list}>
                <Typography variant='subtitle2' className={classes.list_title}>支払い金額</Typography>
                <Typography variant='subtitle2' className={classes.list_text}>¥ {formatedYen(_totalPrice)}</Typography>
              </ListItem>
            </List>
          </div>
          上記の内容で決済します。よろしいですか？<br></br>
        </DialogContent>
        <DialogActions>
          <Button variant="text" autoFocus onClick={() => setDialogOpen(false)}>いいえ</Button>
          <Button variant="text" onClick={handlePayment} style={{ color: '#ff0000' }}>決済する</Button>
        </DialogActions>
      </Dialog>
    )
  }

  const handleToCheck = () => {
    // 新規カード時にカードが入力されているか確認
    if (productInfo.is_authorization && !myTicket && paymentStates.selectedPaymentMethodStr === '-1' && !isNewCardInputed) {
      dispatch(
        Common.actions.fetchAlert({
          alerts: [{ message: "カード情報を入力してください。" }],
        })
      );
      setPaymentStates({ ...paymentStates, paymentErrorMessage: "カード情報を入力してください。" })
      return
      // 支払い方法が選択されているか
    } else if (productInfo.is_authorization && !myTicket && !paymentStates.selectedPaymentMethodStr) {
      dispatch(
        Common.actions.fetchAlert({
          alerts: [{ message: "お支払い方法を選択してください。" }],
        })
      );
      setPaymentStates({ ...paymentStates, paymentErrorMessage: "お支払い方法を選択してください。" })
      return
    }
    setDialogOpen(true)
  }

  const handleToAuctionDetail = () => {
    history.push(`/auction/${product_id}`)
    setStates({ ...states, bidProcess: 0 })
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 130)
  }

  const handleSelectCard = (e: any) => {
    setPaymentStates({
      ...paymentStates,
      paymentErrorMessage: e.target.value === '-1' ? paymentStates.paymentErrorMessage : "",
      isPaymentMethodSave: (e.target.value === '-1'),
      selectedPaymentMethodStr: e.target.value,
    })
  }

  // 支払いカード削除
  // const handleRemoveCard = async (_payment_method_id: any) => {
  //   setPaymentStates({ ...paymentStates, selectedPaymentMethodStr: "", onPaymentMethodsLoading: true })
  //   const stripeDetachPaymentMethod = CloudFunctions.httpsCallable("stripeDetachPaymentMethod");
  //   // const stripeDetachPaymentMethod = CloudFunctions.httpsCallable("testStripeDetachPaymentMethod");
  //   let res = await stripeDetachPaymentMethod({
  //     payment_method_id: _payment_method_id
  //   })
  //   let _new_payment_methods = []
  //   if (res.data.status === 'success') {
  //     _new_payment_methods = paymentMethods.filter(_payment_method => _payment_method.id !== _payment_method_id)
  //     setPaymentStates({ ...paymentStates, selectedPaymentMethodStr: "", onPaymentMethodsLoading: false, })
  //     setPaymentMethods(_new_payment_methods)
  //   }
  // }

  const PaymentMethodsView = () => {
    return (
      <>
        <FormControl fullWidth>
          <RadioGroup
            onChange={handleSelectCard}
            value={paymentStates.selectedPaymentMethodStr}
          >
            {paymentStates.onPaymentMethodsLoading ?
              <div style={{ height: "80px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <CircularProgress size={36} style={{ color: "grey" }} />
              </div>
              :
              <>
                {paymentMethods.map((_payment_method: PaymentMethod, index) => (
                  <FormControlLabel
                    key={index}
                    className={classes.credit_card}
                    value={index.toString()}
                    control={<Radio color="default" />}
                    label={
                      <div className={classes.label}>
                        <Typography variant="subtitle2" style={{ marginRight: "12px", width: "110px" }}>****{_payment_method.card?.last4}</Typography>
                        <Typography variant="subtitle2" style={{ marginRight: "8px", fontSize: "0.75rem", lineHeight: "24px", width: "50px" }}>有効期限</Typography>
                        <Typography variant="subtitle2" style={{ marginRight: "8px", width: "73px" }}>{_payment_method.card?.exp_month} / {_payment_method.card?.exp_year}</Typography>
                      </div>
                    }
                  />
                ))}
              </>
            }
            <FormControlLabel
              className={classes.credit_card}
              value="-1"
              control={<Radio color="default" />}
              label={
                <div className={classes.label}>
                  <Typography variant="subtitle2" style={{ marginRight: "12px" }}>新しいカードを使用する</Typography>
                </div>
              }
            />
          </RadioGroup>
        </FormControl>
      </>
    )
  }

  const SelectedShippingMethodView = () => {
    const isOversea = !(shippingMethod.countryCode === 'JP' || shippingMethod.countryCode === undefined)

    return (
      <div className={classes.shipping_method_view}>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <Typography variant="subtitle2" style={{ color: "grey", fontSize: "1rem", marginBottom: "8px" }}>配送先情報</Typography>
        </div>
        <dl className={classes.shipping_method_wrap}>
          <dt className={classes.shipping_method_title}>{isOversea ? "Name" : "お名前"}</dt>
          <dd className={classes.shipping_method_text}>{shippingMethod.secondName} {shippingMethod.firstName}</dd>
        </dl>
        {!isOversea && <dl className={classes.shipping_method_wrap}>
          <dt className={classes.shipping_method_title}>フリガナ</dt>
          <dd className={classes.shipping_method_text}>{states.secondnamekana} {states.firstnamekana}</dd>
        </dl>}
        <dl className={classes.shipping_method_wrap}>
          <dt className={classes.shipping_method_title}>{isOversea ? "Phone Number" : "電話番号"}</dt>
          <dd className={classes.shipping_method_text}>{shippingMethod.phone}</dd>
        </dl>
        <dl className={classes.shipping_method_wrap}>
          <dt className={classes.shipping_method_title}>{isOversea ? "Address" : "住所"}</dt>
          <dd className={classes.shipping_method_text}>
            {shippingMethod.zipcode}<br></br>
            {shippingMethod.province} {shippingMethod.address1}<br></br>
            {shippingMethod.address2} {isOversea && shippingMethod.city} {isOversea && getCountryName(shippingMethod.countryCode)}
          </dd>
        </dl>
      </div>
    )
  }

  const [isNewCardInputed, setIsNewCardInputed] = useState(false)
  const handleCardChange = (e: any) => {
    e.complete ? setIsNewCardInputed(true) : setIsNewCardInputed(false)
  }

  const handleCheckCard = (e: any) => {
    setPaymentStates({ ...paymentStates, isPaymentMethodSave: e.target.checked })
  }

  return (
    <Container className={classes.main_container}>
      <MuiThemeProvider theme={theme}>
        <div className={classes.card}>
          <PaymentInfoView productInfo={productInfo} shippingFee={paymentStates.shippingFee} paymentStates={paymentStates} />
          <div className={classes.card_wrapper}>
            <Typography style={{ color: "grey", fontSize: "1rem" }}>支払い方法 ※</Typography>
            <Typography variant="subtitle1" className={classes.error_message}>{paymentStates.paymentErrorMessage}</Typography>
            <PaymentMethodsView />
            {paymentStates.selectedPaymentMethodStr === '-1' &&
              <>
                <CardElement id="card_element" onChange={handleCardChange} className={paymentStates.selectedPaymentMethodStr === "-1" ? classes.selected_card_element : classes.card_element} options={cardElementOptions} />
                <FormControlLabel
                  disabled={paymentStates.selectedPaymentMethodStr !== "-1"}
                  control={<CustomCheckBox checked={paymentStates.selectedPaymentMethodStr === '-1' && paymentStates.isPaymentMethodSave} onChange={handleCheckCard} />}
                  label="このカードを次回以降も使用する"
                />
              </>
            }
          </div>
          {/* 配送先住所 */}
          <SelectedShippingMethodView />
          <div style={{ marginTop: "60px", display: "flex", justifyContent: "center", alignItems: "center", }}>
            <div className={classes.botton_wrapper}>
              <Button onClick={handleToAuctionDetail}>決済をキャンセル</Button>
              <Button className={classes.next_botton} onClick={handleToCheck}>確認へ</Button>
            </div>
          </div>
        </div>
        <CheckDialogView />
        <Backdrop className={classes.backdrop} open={states.onBidProcess} >
          <CircularProgress color='inherit' />
        </Backdrop>
      </MuiThemeProvider>
    </Container>
  )

});
export default PaymentView;

import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"

Sentry.init({
  // 本番環境でのみSentryを実行
  // dsn: "https://963f1f1d8caf42109d4962cf00a3a81d@o766805.ingest.sentry.io/5796274",
  dsn: process.env.NODE_ENV === "production" ? "https://963f1f1d8caf42109d4962cf00a3a81d@o766805.ingest.sentry.io/5796274" : '',
  integrations: [new BrowserTracing()],
  environment: process.env.NODE_ENV,
  tracesSampleRate: 0,
})

document.title = `Dropp`

const startApp = () => {
  ReactDOM.render(
    <App />
    , document.getElementById('root')
  )
}

// @ts-ignore
if (window.cordova) {
  document.addEventListener('deviceready', startApp, false)
} else {
  startApp()
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

// @ts-ignore
reportWebVitals()

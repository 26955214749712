import { Typography, makeStyles } from "@material-ui/core"
import UpDownDialog from "../../common/UpDownDialog"
import MainButton from "../../common/MainButton"

const useStyles = makeStyles(() => ({
    dialogTitle: {
        fontSize: '18px',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    bidWrapper: {
        padding: '40px 0'
    },
    selectInput: {
        width: '250px',
        height: '32px',
        borderRadius: '0',
    },
    bidInput: {
        height: '32px',
        borderRadius: '0',
        padding: '0 8px',
        border: '1px solid black',
    },
}))

const ShippedInfoInputView = ({
    product,
    open,
    setOpen,
    handleSendShippingInfo,
    register,
    errors
}: any) => {
    const classes = useStyles()

    return (
        <UpDownDialog open={open} setOpen={setOpen}>
            <div>
                <div>
                    <Typography variant='h1' className={classes.dialogTitle}>発送完了連絡</Typography>
                </div>
                {product &&
                    <div className={classes.bidWrapper}>

                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '32px' }}>
                            <div style={{ maxWidth: '200px' }}>
                                <img src={product.images[0].src} style={{ maxWidth: '200px' }} />
                            </div>
                            <div style={{ paddingLeft: '24px' }}>
                                <p>商品名: {product.title}</p>
                                <p>配送状況: 配送待ち</p>
                            </div>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '24px' }}>
                            <label htmlFor='shipping_provider' style={{ marginBottom: '8px' }}>配送業者</label>
                            <select
                                className={classes.selectInput}
                                id='shipping_provider'
                                {...register('shipping_provider', {
                                    required: "選択してください。"
                                })}
                            >
                                <option value={'yamato'}>ヤマト運輸</option>
                                <option value={'sagawa'}>佐川急便</option>
                                <option value={'yupack'}>ゆうパック</option>
                            </select>
                            <Typography style={{ color: '#f00', fontSize: '0.8rem', marginTop: '4px' }}>{errors.shipping_provider?.message}</Typography>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '24px' }}>
                            <label htmlFor='shipping_number' style={{ marginBottom: '8px' }}>配送番号(ハイフンなし)</label>
                            <input
                                className={classes.bidInput}
                                type='number'
                                id="shipping_number"
                                {...register('shipping_number', {
                                    required: {
                                        value: true,
                                        message: '入力をお願いします。'
                                    },
                                    minLength: {
                                        value: 10,
                                        message: '入力された数字が正しくありません。'
                                    },
                                    maxLength: {
                                        value: 12,
                                        message: '入力された数字が正しくありません。'
                                    }
                                })}
                            />
                            <Typography style={{ color: '#f00', fontSize: '0.8rem', marginTop: '4px' }}>{errors.shipping_number?.message}</Typography>
                        </div>

                        <MainButton text='発送完了連絡をする' onClick={handleSendShippingInfo} />
                    </div >
                }
            </div >
        </UpDownDialog>
    )
}

export default ShippedInfoInputView
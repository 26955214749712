import { Backdrop, Button, CircularProgress, Container, Checkbox, Dialog, DialogActions, DialogContent, List, ListItem, Divider, FormControlLabel, makeStyles, Typography, withStyles } from "@material-ui/core";
import { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import CartItemsView from "../components/cart/CartItemsView";
import CartPriceView from "../components/cart/CartPriceView";
import PaymentMethodInput from "../components/cart/PaymentMethodInput";
import ShippingMethodInput from "../components/cart/ShippingMethodInput";
import TermOfUseSlideView from "../components/common/TermOfUseSlideView";
import { AuthContext } from "../contexts/Auth";
import { CartContext } from "../contexts/Cart";
import { formatedYen } from "../utils/Common"
import { CloudFunctions, db, PaymentStatusValue, ShippingStatusValue } from "../utils/Firebase";
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useDispatch } from "react-redux";
import Common from "../redux/common/commonSlice";
import { notifySlackDroppOrder } from "../utils/Notify";
import { getCountryName, getShippingFee } from "../utils/Shipping";
import { resetShippingMethod } from "../redux/shippingMethod/shippingMethodSlice";
import { useSelector } from "../redux/rootReducer";
import { Order, OrderItem } from "../types/order";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "100px",
  },
  wrapper: {
    marginBottom: "50px",
  },
  wrapper_title: {
    color: "gray",
    fontSize: "1.1rem",
    fontWeight: "bold",
  },
  button_wrapper: {
    display: "flex",
    justifyContent: "center",
    margin: "50px 0",
    border: "0",
  },
  active_button: {
    color: "#ff0000",
  },
  negative_button: {
  },
  shipping_method_view: {
    marginBottom: "60px",
  },
  shipping_method_wrap: {
    display: "flex",
    alignItems: "center",
    marginBottom: "0.5rem",
    width: "100%",
  },
  shipping_method_title: {
    color: "grey",
    width: "20%",
    verticalAlign: "middle"
  },
  shipping_method_text: {
    width: "70%",
    color: "grey",
  },
  update_button: {
    color: "#ff0000",
    borderRadius: "0",
    border: "1px solid #ff0000",
  },
  list_root: {

  },
  product_info_list: {
    display: "flex",
  },
  product_info_list_title: {
    width: "50%",
    textAlign: "center"
  },
  product_info_list_text: {
    width: "50%",
    textAlign: "center"
  },
  confirm_item: {
    borderBottom: "1px solid lightgray",
    padding: "10px 0",
    display: "flex",
  },
  confirm_item_image: {
    width: "30%",
    display: "flex",
    alignItems: "center",
  },
  image: {
    width: "80%",
  },
  confirm_info_wrapper: {
    boxSizing: "border-box",
    width: "70%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  shippingScheduleText: {
    marginBottom: "16px",
  },
}))

type CheckboxType = {
  checked: boolean,
  onChange: () => void,
}
const CustomCheckBox = withStyles({
  root: {
    color: "#ff0000",
    '&$checked': {
      color: "#ff0000",
    },
  },
  checked: {},
})((props: CheckboxType) => <Checkbox color="default" {...props} />);

const CartPayment = () => {
  const classes = useStyles();
  const history = useHistory()
  const dispatch = useDispatch()
  const { myCart, isCartLoaded, getLatestInventoryQuantity } = useContext<any>(CartContext)
  const { teaserEmail, currentUser } = useContext<any>(AuthContext)
  const [paymentProcess, setPaymentProcess] = useState(1)
  const [paymentStates, setPaymentStates] = useState({
    selectedPaymentMethodStr: "",
    isPaymentMethodSave: false,
    isPaymentMethodSelectCompoeted: false,
    onChangeEntry: false,
    paymentMethods: [],
    errorMessage: "",
  })
  const [isCheckedTerms, setIsCheckedTerms] = useState(false)
  const [onShowTermsType, setOnShowTermsType] = useState("")
  const [onProcessiong, setOnProcessiong] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [termOfUseOpen, setTermOfUseOpen] = useState(false)
  const [shippingFeeTotal, setShippingFeeTotal] = useState(0)
  const [subTotal, setSubTotal] = useState(0)
  const elements = useElements();
  const stripe = useStripe();
  const shippingMethod = useSelector((state) => state.shippingMethod)

  useEffect(() => {
    // 支払い画面に直接アクセス and カート内に商品がない場合はトップページへ
    if (isCartLoaded && !myCart) {
      history.push('/')
    }
  }, [isCartLoaded, myCart])

  const stripePayment = async () => {
    try {
      if (!stripe || !elements) { return }

      // 1.決済情報保存時のStripe Customer IDを取得
      let stripe_customer_id = ""
      if (!teaserEmail?.stripe_customer_id && paymentStates.isPaymentMethodSave) {
      // if (!teaserEmail?.test_stripe_customer_id && paymentStates.isPaymentMethodSave) {
        // 新規ユーザー カード保存
        const stripeCreateCustomer = CloudFunctions.httpsCallable("stripeCreateCustomer");
        // const stripeCreateCustomer = CloudFunctions.httpsCallable("testStripeCreateCustomer");
        let customer = await stripeCreateCustomer({ email: currentUser.email })
        stripe_customer_id = customer.data.id
      } else if (paymentStates.selectedPaymentMethodStr === '-1' && !paymentStates.isPaymentMethodSave) {
        // ゲスト支払い(既存 or 新規ユーザー）
        stripe_customer_id = ""
      } else {
        // 既存カード or 既存ユーザ新規カード
        stripe_customer_id = teaserEmail.stripe_customer_id
        // stripe_customer_id = teaserEmail.test_stripe_customer_id
      }

      // 2.peyment intent(支払い)の作成
      let stripeCreatePaymentIntent = CloudFunctions.httpsCallable("stripeCreatePaymentIntent");
      // let stripeCreatePaymentIntent = CloudFunctions.httpsCallable("testStripeCreatePaymentIntent");
      let insurance_fee = 0
      let _payment_amount = subTotal + shippingFeeTotal + insurance_fee
      let res = await stripeCreatePaymentIntent({
        amount: _payment_amount,
        username: currentUser.email,
        customer_id: stripe_customer_id,
      })
      if (!res.data || !res.data.client_secret) {
        return { status: "failed", paymentIntent: null, errorMessage: "決済に失敗しました。支払い方法を変更いただくか時間をあけて再度お願い致します。" }
      }
      let clientSecret = res.data.client_secret

      // 3.payment_intent(支払い)の確定
      // @ts-expect-error TS(7015): Element implicitly has an 'any' type because index... Remove this comment to see the full error message
      let _payment_method = paymentStates.selectedPaymentMethodStr === '-1' ? { card: elements.getElement(CardElement) } : paymentStates.paymentMethods[paymentStates.selectedPaymentMethodStr].id
      const { error: stripeError, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: _payment_method,
      });

      // 4.支払い結果
      if (stripeError) {
        let _error_message
        switch (stripeError.code) {
          case "insufficient_funds":
            _error_message = "カード残高が不足しています。"
            break
          case "expired_card":
            _error_message = "カードの有効期限が切れています。"
            break
          case "incorrect_cvc":
            _error_message = "カードのセキュリティコードが間違っています。"
            break
          case "processing_error":
            _error_message = "カードの処理中にエラーが発生しました。しばらくしてからもう一度お試しください。"
            break
          default:
            _error_message = "カード決済に失敗しました。カードを変更いただくか時間をあけて再度お願い致します。"
            break
        }
        return { status: "failed", paymentIntent: null, errorMessage: _error_message }
      } else {
        // 新規Stripe決済が成功したらCustomer_idをFirestoreに保存
        if (paymentStates.isPaymentMethodSave && !teaserEmail?.stripe_customer_id) {
        // if (paymentStates.isPaymentMethodSave && !teaserEmail?.test_stripe_customer_id) {
          await db.collection('teaser_emails').where('email', "==", currentUser.email).get().then(snapshot => {
            snapshot.forEach(async (doc) => {
              await db.collection('teaser_emails').doc(doc.id).update({
                ...doc.data(),
                stripe_customer_id: stripe_customer_id,
                // test_stripe_customer_id: stripe_customer_id,
              })
            })
          })
        }
        return { status: "success", paymentIntent, }
      }
    } catch (e) {
      return { status: "failed", paymentIntent: null, errorMessage: "決済に失敗しました。支払い方法を変更いただくか時間をあけて再度お願い致します。" }
    }
  }

  const handlePayment = async () => {
    setDialogOpen(false)
    setOnProcessiong(true)
    let purchaseItems = myCart.items.filter((item: any) => item.latest_inventory_quantity !== 0)

    // 1.在庫分を確保(clound functions)
    try {
      let holdInventoryQuantity = CloudFunctions.httpsCallable('holdInventoryQuantity')
      let res = await holdInventoryQuantity({ items: purchaseItems })
      if (res.data.status === 'failed') throw new Error('購入に失敗しました。')
    } catch (e) {
      dispatch(
        Common.actions.fetchAlert({
          alerts: [{ message: `在庫数に変更があり購入に失敗しました。\n 購入数をご確認ください。` }],
        })
      );
      setOnProcessiong(false)
      await getLatestInventoryQuantity()
      window.scrollTo({ top: 0, behavior: "smooth" })
      return
    }

    // 2.Stripeで決済
    let res = await stripePayment()
    // 決済で失敗したら、在庫を元に戻す
    if (res?.status === 'failed') {
      try {
        let releaseInventoryQuantity = CloudFunctions.httpsCallable('releaseInventoryQuantity')
        await releaseInventoryQuantity({ items: purchaseItems })
      } catch (e) {
        // リリースに失敗したらSlackで通知
        let text = ""
        purchaseItems.forEach((item: any) => {
          text += `商品名: ${item.productInfo.title}\n オプション: ${item.variant.title}\n 数量: ${item.quantity > item.latest_inventory_quantity ? item.latest_inventory_quantity : item.quantity}`
        })
        await notifySlackDroppOrder(`<@U01TJ3U9NHM>\n 注文決済に失敗しましたが、在庫のリリースに失敗しました。\n${text}`)
      }
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      setPaymentStates(res.errorMessage)
      window.scrollTo({ top: window.scrollY - 150, behavior: "smooth" })
      setOnProcessiong(false)
      return
    }

    // 3.決済成功したら注文を作成
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    if (res.status === 'success') {
      let vendors: any = []
      let _items: OrderItem[] = purchaseItems.map((item: any) => {
        let _purchasedQuantity = (item.quantity > item.latest_inventory_quantity) ? item.latest_inventory_quantity : item.quantity
        let _subTotal = Number(item.variant.price) * _purchasedQuantity
        let _shippingFee = getShippingFee(item.productInfo.shipping_fee_name, shippingMethod.provinceCode) * _purchasedQuantity
        let _insuranceFee = 0
        vendors.push(item.productInfo.vendor)
        return {
          productInfo: item.productInfo,
          variant: item.variant,
          paymentStatus: PaymentStatusValue['AUTHORIZED'],
          shippingStatus: ShippingStatusValue['PENDING'],
          itemPrice: Number(item.variant.price),
          quantity: _purchasedQuantity,
          subTotal: _subTotal,
          shippingFee: _shippingFee,
          insuranceFee: _insuranceFee,
          total: _subTotal + _shippingFee + _insuranceFee,
          created_at: new Date(),
          updated_at: new Date(),
        }
      })
      try {
        const isOversea = !(shippingMethod.countryCode === 'JP' || shippingMethod.countryCode === undefined)
        let _order: Order = {
          id: '',
          items: _items,
          subTotal,
          shippingFee: shippingFeeTotal,
          total: subTotal + shippingFeeTotal,
          email: currentUser.email,
          phone: currentUser.phoneNumber,
          uid: currentUser.uid,
          paymentStatus: PaymentStatusValue['AUTHORIZED'],
          shippingStatus: ShippingStatusValue['PENDING'],
          shippingInfo: {
            first_name: shippingMethod.firstName,
            first_name_kana: shippingMethod.firstNameKana,
            second_name: shippingMethod.secondName,
            second_name_kana: shippingMethod.secondNameKana,
            address1: shippingMethod.address1,
            address2: shippingMethod.address2,
            zipcode: shippingMethod.zipcode,
            city: isOversea ? shippingMethod.city : "",
            province: shippingMethod.province,
            province_code: isOversea ? shippingMethod.provinceCode : "",
            phone: shippingMethod.phone,
            country_code: isOversea ? shippingMethod.countryCode : 'JP',
            country: isOversea ? getCountryName(shippingMethod.countryCode) : getCountryName("JP"),
          },
          // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          paymentInfo: res.paymentIntent,
          vendors,
          created_at: new Date(),
          updated_at: new Date(),
        }
        let createOrder = CloudFunctions.httpsCallable('createOrder')
        let createOrderRes = await createOrder({ order: _order })
        let text = ""
        createOrderRes.data.order.items.forEach((item: any) => {
          text += `\n商品名: ${item.productInfo.title}\n ${item.variant.title !== "Default Title" ? 'オプション: ' + item.variant.title : ""}\n 数量: ${item.quantity}\n`
        })
        await notifySlackDroppOrder(`<@U01TJ3U9NHM>\n新しい注文を受注しました。\n注文ID: ${createOrderRes.data.order.id}\n${text}`)
        history.push('/cart/thanks')
      } catch (e) {
        console.log('error', e)
        let releaseInventoryQuantity = CloudFunctions.httpsCallable('releaseInventoryQuantity')
        await releaseInventoryQuantity({ items: myCart.items })
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        await notifySlackDroppOrder(`<@U01TJ3U9NHM>\n注文の作成に失敗しました。${res.paymentIntent.id}\n エラーr: ${e}`)
        // 注文の作成に失敗したら1.在庫をリリース 2.オーソリ決済をキャンセル 3.Slackに通知
        dispatch(
          Common.actions.fetchAlert({
            alerts: [{ message: `商品の購入に失敗しました。大変申し訳ありませんが時間を空けて再度お試しください。` }],
          })
        );
        setOnProcessiong(false)
      }
    }
  }

  const handleCancel = () => {
    history.push('/')
  }

  const handleChangeShippingMethod = () => {
    dispatch(resetShippingMethod())
    setPaymentProcess(1)
  }

  const SelectedShippingMethodView = () => {
    const isOversea = !(shippingMethod.countryCode === 'JP' || shippingMethod.countryCode === undefined)

    return (
      <div className={classes.shipping_method_view}>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <Typography variant="subtitle2" style={{ color: "grey", fontSize: "1rem", marginBottom: "8px" }}>配送先情報</Typography>
          <Button variant="outlined" className={classes.update_button} onClick={handleChangeShippingMethod}>変更する</Button>
        </div>
        <dl className={classes.shipping_method_wrap}>
          <dt className={classes.shipping_method_title}>{isOversea ? "Name" : "お名前"}</dt>
          <dd className={classes.shipping_method_text}>{shippingMethod.secondName} {shippingMethod.firstName}</dd>
        </dl>
        {!isOversea && <dl className={classes.shipping_method_wrap}>
          <dt className={classes.shipping_method_title}>フリガナ</dt>
          <dd className={classes.shipping_method_text}>{shippingMethod.secondNameKana} {shippingMethod.firstNameKana}</dd>
        </dl>}
        <dl className={classes.shipping_method_wrap}>
          <dt className={classes.shipping_method_title}>{isOversea ? "Phone Number" : "電話番号"}</dt>
          <dd className={classes.shipping_method_text}>{shippingMethod.phone}</dd>
        </dl>
        <dl className={classes.shipping_method_wrap}>
          <dt className={classes.shipping_method_title}>{isOversea ? "Address" : "住所"}</dt>
          <dd className={classes.shipping_method_text}>
            {shippingMethod.zipcode}<br></br>
            {shippingMethod.province}{shippingMethod.address1}<br></br>
            {shippingMethod.address2} {isOversea && shippingMethod.city + ' ' + getCountryName(shippingMethod.countryCode)}
          </dd>
        </dl>
      </div>
    )
  }

  const handleTermsViewToggle = (e: any) => {
    setOnShowTermsType(e.target.innerHTML)
    setIsCheckedTerms(false)
    setTermOfUseOpen(true)
  }

  const handleTermsViewClose = () => {
    setTermOfUseOpen(false)
    setIsCheckedTerms(false)
  }

  const handleConfirmDialogOpen = () => {
    let _items = myCart.items.filter((item: any) => item.latest_inventory_quantity !== 0)
    if (_items.length == 0) {
      dispatch(
        Common.actions.fetchAlert({
          alerts: [{ message: `カート内に購入可能な商品がございません。` }],
        })
      );
      window.scrollTo({ top: 0, behavior: "smooth" })
      return
    }
    setDialogOpen(true)
  }

  const ConfirmDialogView = () => {
    let _items = myCart.items.filter((item: any) => item.latest_inventory_quantity !== 0)

    return (
      <Dialog
        open={dialogOpen}
      >
        <DialogContent style={{ color: 'black', marginBottom: '0' }}>
          <div>
            <div>
              {_items.map((item: any) => <div key={item.variant.id} className={classes.confirm_item}>
                <div className={classes.confirm_item_image}>
                  <img className={classes.image} src={item.productInfo.images[0]} />
                </div>
                <div className={classes.confirm_info_wrapper}>
                  <Typography variant="subtitle2" style={{ fontWeight: "bold" }}>{item.productInfo.title}</Typography>
                  {item.variant.title !== 'Default Title' && <Typography variant="subtitle2">{item.variant.title}</Typography>}
                  <Typography variant="subtitle2">¥{formatedYen(item.variant.price)}</Typography>
                  <Typography variant="subtitle2">数量: {item.quantity > item.latest_inventory_quantity ? item.latest_inventory_quantity : item.quantity}</Typography>
                </div>
              </div>)}
            </div>
            <List className={classes.list_root}>
              <ListItem className={classes.product_info_list}>
                <Typography variant='subtitle2' className={classes.product_info_list_title}>商品合計</Typography>
                <Typography variant='subtitle1' className={classes.product_info_list_text}> ¥{formatedYen(subTotal)}</Typography>
              </ListItem>
              <ListItem className={classes.product_info_list}>
                <Typography variant='subtitle2' className={classes.product_info_list_title}>配送料</Typography>
                <Typography variant='subtitle1' className={classes.product_info_list_text}> ¥{formatedYen(shippingFeeTotal)}</Typography>
              </ListItem>
              <Divider />
              <ListItem className={classes.product_info_list}>
                <Typography variant='subtitle2' className={classes.product_info_list_title}>合計</Typography>
                <Typography variant='subtitle1' className={classes.product_info_list_text}> ¥{formatedYen(subTotal + shippingFeeTotal)}</Typography>
              </ListItem>
            </List>
          </div><br></br>
          上記の内容で決済を実行します。<br />よろしいですか？<br></br>
        </DialogContent>
        <DialogActions>
          <Button variant="text" autoFocus onClick={() => setDialogOpen(false)}>いいえ</Button>
          <Button variant="text" onClick={handlePayment} id='click_button' style={{ color: '#ff0000' }}>実行する</Button>
        </DialogActions>
      </Dialog>
    );
  }

  if (!isCartLoaded) {
    return (<></>)
  } else if (myCart?.items?.length > 0) {
    return (
      <Container maxWidth="xs" className={classes.container}>
        {paymentProcess === 1 &&
          <div className={classes.wrapper}>
            <Typography variant="subtitle1" className={classes.wrapper_title}>注文商品</Typography>
            <Divider />
            <CartItemsView />
            <Typography variant="subtitle1" className={classes.wrapper_title}>配送先情報</Typography>
            <ShippingMethodInput handleToPayment={() => setPaymentProcess(2)} />
          </div>
        }
        {paymentProcess === 2 &&
          <>
            <div className={classes.wrapper}>
              <Typography variant="subtitle1" className={classes.wrapper_title}>注文商品</Typography>
              <Divider />
              <CartItemsView />
              <Divider />
              <CartPriceView subTotal={subTotal} setSubTotal={setSubTotal} shippingFeeTotal={shippingFeeTotal} setShippingFeeTotal={setShippingFeeTotal} />
            </div>
            <div className={classes.wrapper}>
              <Typography variant="subtitle1" className={classes.wrapper_title}>支払い方法</Typography>
              <PaymentMethodInput paymentStates={paymentStates} setPaymentStates={setPaymentStates} />
            </div>
            <SelectedShippingMethodView />
            <Typography variant="subtitle2" className={classes.shippingScheduleText}>※ご注文は３営業日以内の発送を予定しております。</Typography>
            {/* 規約同意 */}
            <FormControlLabel
              control={<CustomCheckBox onChange={() => setIsCheckedTerms(!isCheckedTerms)} checked={isCheckedTerms} />}
              label={
                <Typography variant="subtitle1">
                  <span onClick={handleTermsViewToggle} style={{ color: "red" }}>利用規約</span>
                  <span>に同意する</span>
                </Typography>
              }
            />
            <ConfirmDialogView />
            <TermOfUseSlideView
              termOfUseOpen={termOfUseOpen}
              handleTermsViewClose={handleTermsViewClose}
              onShowTermsType={onShowTermsType}
            />
            <div className={classes.button_wrapper}>
              <Button onClick={handleCancel}>購入をキャンセル</Button>
              <Button disabled={!isCheckedTerms} onClick={handleConfirmDialogOpen} className={classes.active_button}>確認へ</Button>
            </div>
            <Backdrop className={classes.backdrop} open={onProcessiong} >
              <CircularProgress color='inherit' />
            </Backdrop>
          </>
        }
      </Container>
    )
  } else {
    return (<></>)
  }
}

export default CartPayment;
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Divider, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, makeStyles } from "@material-ui/core"
import { useContext, useState } from "react"
import { AuthContext } from "../../contexts/Auth"
import { formatedYen, hasEntryViewPermissionVendors } from "../../utils/Common";
import BackdropLoading from '../../components/common/BackdropLoading'
import { CloudFunctions, db } from "../../utils/Firebase";
import { useDispatch } from "react-redux";
import Common from "../../redux/common/commonSlice";
import { Product } from "../../types/product";

const useStyles = makeStyles((theme) => ({
    main: {
        marginTop: theme.spacing(20),
        marginBottom: "300px",
    },
    search_input_wrapper: {
        display: "flex",
        alignItems: "center",
        marginBottom: "60px",
    },
    tableHeader: {
        whiteSpace: "nowrap",
    },
    tableRowLink: {
        textDecoration: 'underline',
        '&:hover': {
            cursor: 'pointer',
            color: 'gray'
        }
    },
    table_image: {
        width: "80px",
    },
    clickableRow: {
        whiteSpace: 'nowrap',
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: 'lightgray',
        }
    }
}))

const draftStatusValue = {
    'draft': "下書き中",
    'completeDraft': "下書き完了",
    'completeCreative': "クリエイティブ修正済み",
    'testing': "テスト適用",
    'active': "本番適用",
    'waitingFix': "本番適用後Fix待ち",
    'archived': "Dropp終了",
}

const ProductsTable = ({
    products,
    setProducts
}: any) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [showEntryFlg, setShowEntryFlg] = useState(false)
    const [productInfo, setProductInfo] = useState<Product | null>(null)
    const [isLoading, setIsLoading] = useState(false)
    const { teaserEmail } = useContext<any>(AuthContext)

    //商品でソートする
    const handleSortClick = (_date: any) => {
        setIsLoading(true)
        let _targetProducts: any = []
        let unKnownDateProducts: any = []
        products.forEach((_pro: any) => {
            if (_pro[`${_date}`]?.toDate() == null) {
                unKnownDateProducts.push(_pro)
            } else {
                _targetProducts.push(_pro)
            }
        })

        _targetProducts.sort((a: any, b: any) => {
            if (!a[_date]) {
                return -1
            } else {
                if (new Date(a[_date]).getTime() - new Date(b[_date]).getTime()) {
                    return 1
                } else {
                    return -1
                }
            }
        });

        setProducts(_targetProducts.concat(unKnownDateProducts))
        setTimeout(() => {
            setIsLoading(false)
        }, 500)
    }

    // 商品詳細ページを開く
    const handleProductPageOpen = (product_id: any) => {
        const bsaseUrl = 'https://dropp.jp/products/'
        window.open(bsaseUrl + product_id)
    }

    // 商品を削除
    const [deleteProduct, setDeleteProduct] = useState<Product | null>(null)
    const handleSetDeleteProduct = (_product: any) => {
        setDeleteProduct(_product)
        setDeleteConfirmFlg(true)
    }


    const [deleteConfirmFlg, setDeleteConfirmFlg] = useState(false)
    const DeleteProductConfirmView = () => {
        return (
            <Dialog open={deleteConfirmFlg}>
                <DialogContent>
                    <DialogContentText>
                        <Typography variant="subtitle2">
                            こちらの商品を削除しますか？
                        </Typography>
                    </DialogContentText>
                    <DialogActions>
                        <Button variant="outlined" onClick={closeDeleteCofirm}>やめる</Button>
                        <Button variant="outlined" style={{ color: "#ff0000" }} onClick={handleProductDelete}>削除する</Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        )
    }

    // 削除ダイアログを閉じる
    const closeDeleteCofirm = () => {
        setDeleteProduct(null)
        setDeleteConfirmFlg(false)
    }

    // 商品データを削除
    const handleProductDelete = async () => {
        if (!deleteProduct) throw new Error('削除する商品が選択されていません。')
        setIsLoading(true)
        setDeleteConfirmFlg(false)
        try {
            const deleteNotionPageFunc = CloudFunctions.httpsCallable('deleteNotionPage')
            if (deleteProduct.notion_page_id) await deleteNotionPageFunc({ pageId: deleteProduct.notion_page_id })
            const func3 = CloudFunctions.httpsCallable('deleteFirestoreProduct')
            await func3({ product_id: deleteProduct.id })

            const newProducts = products.filter((product: any) => product.id !== deleteProduct.id)
            setProducts(newProducts)

            setIsLoading(false)
            dispatch(
                Common.actions.fetchAlert({
                    alerts: [{ message: "商品の削除に成功しました。" }],
                })
            );
            setDeleteProduct(null)
        } catch (e) {
            console.log('error', e)
            setIsLoading(false)
            setDeleteProduct(null)
            dispatch(
                Common.actions.fetchAlert({
                    alerts: [{ message: "商品の削除に失敗しました。" }],
                })
            )
        }
    }

    // 抽選商品のエントリー状況を表示
    const getProductInfo = async (product: any) => {
        setIsLoading(true)
        let _product = {
            ...product,
            title: product.title,
            price: product.variants[0].price,
            totalEntryCount: 0,
            totalOverseaCount: 0,
            variants: {},
            isAuthorization: false,
        }

        if (product.product_type === '抽選') {
            await db.collection('tickets').where("product_id", "==", Number(product.id)).get().then(snapshot => {
                snapshot.forEach(ticket => {
                    let _ticket = ticket.data()
                    let _variant = _ticket.variation["1"]
                    if (_ticket.shipping_info?.country_code && _ticket.shipping_info?.country_code !== 'JP') {
                        _product.totalOverseaCount += 1
                    }
                    // バリエーションごとに加算
                    if (!_product.variants[_variant]) {
                        _product.variants[_variant] = 1;
                    } else {
                        _product.variants[_variant] += 1;
                    }
                    _product.totalEntryCount += 1;
                })
            })
        }
        if (product.product_type === 'オークション') {
            await db.collection('tickets').where("product_id", "==", Number(product.id)).get().then(snapshot => {
                console.log('入札チケットの数: ', snapshot.size)
                _product.bid_users = snapshot.size
            })
            await db.collection('reminders').where("product_id", "==", product.id).get().then(snapshot => {
                console.log('リマインダーの数: ', snapshot.size)
                _product.reminders = snapshot.size
            })

        }
        setProductInfo(_product)
        setShowEntryFlg(true)
        setIsLoading(false)
    }

    // オークション入札状況のダイアログ
    const AuctionInfoDialog = () => {
        if (!productInfo) return <></>
        let _current_amount = productInfo.bid_history?.length > 0 ? productInfo.current_bid_amount : 0
        let _highest_amount = productInfo.bid_history?.length > 0 ? productInfo.highest_bid_ticket?.bid_amount : 0
        let bid_length = productInfo.bid_history?.length > 0 ? productInfo.bid_history.length : 0
        return (
            <div>
                <Typography>商品名：{productInfo.title}</Typography>
                <Typography>価格：{formatedYen(Number(productInfo.price))}円</Typography>
                <Typography>ID：{productInfo.id}</Typography>
                <Typography>スタート価格：{formatedYen(Number(productInfo.price))}円</Typography>
                <Typography>現在価格：{formatedYen(_current_amount)}円</Typography>
                <Typography>最高入札額：{formatedYen(_highest_amount)}円</Typography>
                <Typography>入札数：{bid_length}入札</Typography>
                <Typography>入札者数：{productInfo.bid_users}人</Typography>
                <Typography>リマインダー設定：{productInfo.reminders}人</Typography>
            </div>
        )
    }

    // 抽選エントリー状況のダイアログ
    const LotteryInfoDialog = () => {
        if (!productInfo) return <></>
        return (
            <>
                <Typography>商品名：{productInfo.title}</Typography>
                <Typography>価格：{formatedYen(Number(productInfo.price))}円</Typography>
                <Typography>ID：{productInfo.id}</Typography>
                <Typography>出品者：{productInfo.vendor}</Typography>
                {productInfo.totalEntryCount && productInfo.totalEntryCount > 0 &&
                    <>
                        <Divider style={{ margin: "10px 0" }} />
                        <Typography variant="subtitle2">オプション別エントリー数</Typography>
                        {Object.keys(productInfo.variants).map(variant => {
                            return (
                                // @ts-ignore
                                <span className={classes.ticket_count}>{`${variant}`}：{`${productInfo.variants[`${variant}`]}`}人</span>
                            )
                        })}
                    </>
                }
                <Divider style={{ margin: "10px 0" }} />
                <Typography variant="subtitle2">合計エントリー数：{productInfo.totalEntryCount}人</Typography>
                <Typography variant="subtitle2">(うち海外エントリー数: {productInfo.totalOverseaCount}人)</Typography>
            </>
        )
    }


    // 在庫数を返却
    const quantityView = (_product: any) => {
        return <>
            {_product.variants.map((_variant: any, index: any) => (
                <p style={{ fontSize: '14px' }} key={index}>
                    {_variant.option1 === 'Default Title' ? '' : _variant.option1 + ": "} {_variant.inventory_quantity}個
                </p>
            ))
            }
        </>;
    }

    // エントリー・入札状況を表示するダイアログ
    const DialogView = () => {
        if (!productInfo) {
            return (<></>)
        }
        return <Dialog
            open={showEntryFlg}
            onClose={() => setShowEntryFlg(false)}
        >
            <DialogContent>
                <DialogContentText>
                    {productInfo.product_type === 'オークション' && <AuctionInfoDialog />}
                    {productInfo.product_type === '抽選' && <LotteryInfoDialog />}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setShowEntryFlg(false)} >閉じる</Button>
            </DialogActions>

        </Dialog>;
    }

    // エントリー履歴が見れる商品かどうか。見れるのはDroppとパームだけ
    const checkHasEntryViewPermission = (_product: any) => {
        if (_product.product_type !== '抽選') return false
        if (hasEntryViewPermissionVendors.includes(teaserEmail.vendor)) {
            let now = new Date().getTime() / 1000
            if (_product.release_date?.seconds < now) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    }

    return <>

        <div>
            <Typography variant="subtitle2">商品一覧</Typography>
            <TableContainer style={{ maxHeight: "700px" }} component={Paper}>
                <Table stickyHeader aria-label="products-table">
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableHeader}></TableCell>
                            <TableCell className={classes.tableHeader}>商品ID</TableCell>
                            <TableCell className={classes.tableHeader}>商品名</TableCell>
                            <TableCell className={classes.tableHeader}>ステータス</TableCell>
                            <TableCell className={classes.tableHeader}>金額</TableCell>
                            <TableCell className={classes.tableHeader}>在庫</TableCell>
                            <TableCell className={classes.tableHeader}>タイプ</TableCell>
                            <TableCell className={classes.tableHeader}>販売元</TableCell>
                            <TableCell className={classes.tableHeader}>落札予想価格</TableCell>
                            <TableCell className={classes.tableHeader}>スタート価格</TableCell>
                            <TableCell className={classes.tableHeader}>入札幅</TableCell>
                            <TableCell className={classes.tableHeader}>手数料</TableCell>
                            <TableCell className={classes.tableHeader}>オーソリ上限</TableCell>
                            <TableCell className={classes.clickableRow} onClick={() => handleSortClick('release_date')}>開始日</TableCell>
                            {teaserEmail.vendor === 'Dropp' && <TableCell className={classes.clickableRow} onClick={() => handleSortClick('deadline_date')}>Dropp終了日</TableCell>}
                            {teaserEmail.vendor === 'Dropp' && <TableCell className={classes.clickableRow} onClick={() => handleSortClick('created_at')}>作成日</TableCell>}
                            {teaserEmail.vendor === 'Dropp' && <TableCell className={classes.clickableRow} onClick={() => handleSortClick('updated_at')}>更新日</TableCell>}
                            {hasEntryViewPermissionVendors.includes(teaserEmail.vendor) && <TableCell className={classes.tableHeader}></TableCell>}
                            <TableCell className={classes.tableHeader}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {products.map((product: any) => <TableRow key={product.id}>
                            {product.images.length > 0 ?
                                <TableCell size='small'><img className={classes.table_image} src={product.images[0].src} /></TableCell>
                                :
                                <TableCell size='small' />
                            }
                            <TableCell className={classes.tableRowLink} onClick={() => handleProductPageOpen(product.id)}>{product.id}</TableCell>
                            <TableCell className={classes.tableRowLink} onClick={() => handleProductPageOpen(product.id)}>{product.title}</TableCell>
                            {/* @ts-ignore */}
                            <TableCell>{draftStatusValue[product.draft_status]}</TableCell>
                            <TableCell>¥ {formatedYen(product.variants[0].price)}</TableCell>
                            <TableCell>{quantityView(product)}</TableCell>
                            <TableCell>{product.product_type}</TableCell>
                            <TableCell>{product.vendor}</TableCell>
                            <TableCell>{product.expected_winning_bid_amount}</TableCell>
                            <TableCell>{product.product_type === 'オークション' ? product.variants[0].price : ''}</TableCell>
                            <TableCell>{product.bid_interval ? product.bid_interval : ''}</TableCell>
                            <TableCell>{product.product_type === 'オークション' ? product.auction_fee : ''}%</TableCell>
                            <TableCell>{product.authorization_limit ? product.authorization_limit : ''}</TableCell>
                            <TableCell>{product.release_date ? new Date(product.release_date.seconds * 1000).toLocaleString('ja') : "登録なし"}</TableCell>
                            {teaserEmail.vendor === 'Dropp' && <TableCell>{product.deadline_date ? new Date(product.deadline_date.seconds * 1000).toLocaleString('ja') : "登録なし"}</TableCell>}
                            {teaserEmail.vendor === 'Dropp' && <TableCell>{product.created_at ? new Date(product.created_at).toLocaleString('ja') : "登録なし"}</TableCell>}
                            {teaserEmail.vendor === 'Dropp' && <TableCell>{product.updated_at ? new Date(product.updated_at).toLocaleString('ja') : "登録なし"}</TableCell>}
                            {hasEntryViewPermissionVendors.includes(teaserEmail.vendor) && <TableCell>
                                <Button
                                    disabled={!checkHasEntryViewPermission(product)}
                                    style={checkHasEntryViewPermission(product) ? { color: "#ff0000" } : { color: 'gray' }}
                                    onClick={() => getProductInfo(product)} variant="text">エントリーを確認</Button>
                            </TableCell>}
                            <TableCell>
                                <Button
                                    disabled={teaserEmail.vendor !== 'Dropp' && (product.draft_status !== 'draft' && product.draft_status !== 'completeDraft' && product.draft_status !== 'completeCreative')}
                                    onClick={() => handleSetDeleteProduct(product)}
                                    style={!(teaserEmail.vendor !== 'Dropp' && (product.draft_status !== 'draft' && product.draft_status !== 'completeDraft' && product.draft_status !== 'completeCreative')) ? { color: "#ff0000" } : { color: 'gray' }} variant="text">削除</Button>
                            </TableCell>
                        </TableRow>)}
                    </TableBody>
                </Table>
            </TableContainer>
        </div >

        <DialogView />
        <DeleteProductConfirmView />
        <BackdropLoading open={isLoading} />
    </>;
}

export default ProductsTable
import React, { useEffect, useState } from "react";
import {
  Container,
  makeStyles,
} from "@material-ui/core";
import OneColumnProductsView from "./home/OneColumnProductsView";
import TwoColumnProductsView from "./home/TwoColumnProductsView";
import ThreeColumnProductsView from "./home/ThreeColumnProductsView";

const useStyles = makeStyles((theme) => ({
  cardGrid: {
    paddingRight: 0,
    paddingLeft: 0,
    width: "100%",
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(12),
  },
}));

const OnSaleProductsView = (props: any) => {
  const classes = useStyles();
  const { products } = props
  const [columnSize, setColumnSize] = useState(12)

  useEffect(() => {
    if (products.length >= 9) {
      setColumnSize(4)
    } else if (products.length >= 4) {
      setColumnSize(6)
    } else {
      setColumnSize(12)
    }
  }, [])

  return (
    <Container className={classes.cardGrid} maxWidth="md">
      {columnSize === 4 && <ThreeColumnProductsView products={products} />}
      {columnSize === 6 && <TwoColumnProductsView products={products} />}
      {columnSize === 12 && <OneColumnProductsView products={products} />}
    </Container>
  );

};
export default OnSaleProductsView;

import React, { useContext } from "react";
import { withRouter } from "react-router-dom";
import { AuthContext } from "../contexts/Auth";
import { CssBaseline, Grid, makeStyles, Container } from "@material-ui/core";
import { Link } from 'react-router-dom';
// import { analytics } from "../utils/Firebase";
import { TextField } from "../components/form/TextField";
import { useForm } from "react-hook-form";
import { validateEmail } from "../utils/validations/Email";
import { validatePassword } from "../utils/validations/password";
import { Button } from "../components/form/Button";
import { PageTitle } from "../components/common/PageTitle";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: "0 auto",
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    linkWrapper: {
        marginTop: '32px'
    }
}));

const SignIn = ({ history }: any) => {
    const { signin } = useContext<any>(AuthContext);
    const classes = useStyles();
    const { control, getValues, handleSubmit } = useForm()

    const handleSignIn = () => {
        const email = getValues('email')
        const password = getValues('password')
        // analytics.logEvent("CLICK_siginin", { name: "click_signin" })
        signin(email, password, history);
    };

    return (
        <React.Fragment>
            <CssBaseline />
            <Container component="main" maxWidth="xs" className={classes.root}>
                <PageTitle title="ログイン" />

                <form className={classes.form} onSubmit={handleSubmit(handleSignIn)}>
                    <TextField
                        control={control}
                        id='email'
                        name='email'
                        label='メールアドレス'
                        defaultValue=''
                        valudation={validateEmail}
                    />
                    <TextField
                        control={control}
                        id='password'
                        name='password'
                        label='パスワード'
                        defaultValue=''
                        valudation={validatePassword}
                        type="password"
                    />
                    <Button
                        text="ログイン"
                        onClick={handleSubmit}
                    />
                </form>

                <Grid container justify='space-around' className={classes.linkWrapper}>
                    <Grid item xs={12} sm={12}>
                        <span style={{ float: 'right' }}>
                            パスワードを忘れた方は
                            <Link to="/reset_password" replace>
                                こちら
                            </Link>
                        </span><br></br><br></br>
                        <span style={{ float: 'right' }}>
                            アカウントをお持ちでない方は
                            <Link to="/signup/email" replace>
                                こちら
                            </Link>
                        </span>
                    </Grid>
                </Grid>
            </Container>
        </React.Fragment>
    );
};

export default withRouter(SignIn);

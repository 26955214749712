import { Typography } from "@material-ui/core"
const AuthorizationText = () => {
  return (
    <>
      <Typography variant="h4" component="h1" style={{ fontSize: "1.3rem", lineHeight: "2em", textAlign: "center", color: "grey", width: "90%", margin: "60px auto 40px", }}>抽選応募時のクレジットカード決済引き落としについて</Typography>
      <div style={{ textAlign: 'left' }}>
        特定の商品に関しまして、抽選エントリー時にオーソリ（お客様のクレジットカードで決済可能か確認し、ご利用枠を確保する処理）をさせていただいております。<br></br>
        当選発表後に、当選のお客様は実売上処理を行いクレジットカード決済を行います。<br></br>
        落選のお客様にはキャンセル処理を行い、ご利用枠の確保をキャンセルいたします。<br></br><br></br>
        弊社でもキャンセル処理が行われたかの確認を取ることは可能でございますが、実際にいつ返金、キャンセル情報がお客様へ届くかなどは確認ができかねますのでお手数ですが、ご利用のクレジットカード会社へお問い合わせいただけますようお願い申し上げます。<br></br>
        キャンセル処理がクレジットカード会社へ通知されるタイムラグがありますので、翌日以降にご利用のクレジットカード会社へお問い合わせいただけますよう重ねてお願い申し上げます。
      </div>
    </>
  )
}
export default AuthorizationText;
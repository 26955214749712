import React from "react";
import {
  Container,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { useHistory } from 'react-router-dom';
import { formatedYen } from "../utils/Common";
import HoursTimer from "./common/HoursTimer";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const useStyles = makeStyles((theme: Theme) => createStyles({
  cardGrid: {
    padding: "10px",
    width: "100%",
    marginBottom: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(4),
    }
  },
  products_title: {
    fontWeight: "bold",
    fontSize: "1.3rem",
    padding: "8px 0px",
    margin: "0 auto",
    borderBottom: "1px solid black",
    letterSpacing: "3px",
    marginBottom: "30px",
    [theme.breakpoints.up('sm')]: {
      marginBottom: "50px",
    }
  },
  grid_item: {
    padding: "6px 20px",
    marginBottom: theme.spacing(9),
    display: "flex",
    flexDirection: "column",
    width: "90%",
    margin: "0 auto",
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(10),
      padding: "6px 32px",
    }
  },
  cardMedia: {
    width: "100%",
    height: 'auto',
  },
  item_info: {
    display: "flex",
    flexFlow: 'column',
    justifyContent: 'center',
    color: "#ff0000",
    marginBottom: "10px",
  },
  inventory_text: {
    height: "38px",
    lineHeight: "38px",
    marginBottom: "4px",
    textAlign: "center",
    fontSize: "1.4rem",
    letterSpacing: "2px",
    color: "#ff0000",
    fontFamily: "digital7-mono",
  },
  product_title: {
    fontSize: ".85rem",
    overflow: "hidden",
    maxHeight: "50px",
    lineHeight: "25px",
    color: "gray",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
    [theme.breakpoints.up('sm')]: {
      fontSize: "0.9rem",
    }
  },
  product_price: {
    fontSize: "0.85rem",
    fontWeight: "bold",
    [theme.breakpoints.up('sm')]: {
      fontSize: "0.9rem",
    }
  },
}));

const BuyNowProductsView = (props: any) => {
  const classes = useStyles();
  const history = useHistory();
  const { products } = props
  const viewItems = products

  const calcMinInventory = (_product: any) => {
    let arr = _product.variants.map((variant: any) => variant.inventory_quantity).filter((n: any) => n)
    return Math.min(...arr) === Infinity ? 0 : Math.min(...arr)
  }

  return (
    <Container className={classes.cardGrid} maxWidth="md">
      <Typography className={classes.products_title} variant="subtitle1">BUY NOW</Typography>
      <Grid container>
        {viewItems.map((product: any) => <Grid className={classes.grid_item} item key={product.id} xs={6} sm={4} md={4} onClick={() => history.push(`/onsale/${product.id}`)}>
          <div
            className={classes.item_info}
          >
            {product.product_type === '先着' ?
              <Typography variant="subtitle2" className={classes.inventory_text}> LAST {calcMinInventory(product)}</Typography>
              :
              <HoursTimer
                variant="subtitle1"
                product={product}
                endDateTime={new Date().setTime(
                  product.deadline_date.seconds * 1000
                )}
              />
            }
            <LazyLoadImage height={350} src={product.image} className={classes.cardMedia} />
          </div>
          <Typography className={classes.product_title}>
            {product.title}
          </Typography>
          <Typography className={classes.product_price}>
            {product.product_type === 'オークション' && <>¥ {String(product.current_bid_amount ? product.current_bid_amount : product.variants[0].price).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}</>}
            {product.product_type === '抽選' && <>¥ {formatedYen(product.variants[0].price)}</>}
            {product.product_type === 'nft' && <>¥ {formatedYen(product.variants[0].price)}</>}
            {product.product_type === '先着' && <>¥ {formatedYen(product.variants[0].price)}</>}
          </Typography>
        </Grid>)}
      </Grid>
    </Container>
  );

};
export default BuyNowProductsView;

import React, { memo } from "react";
import {
  Container,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useHistory } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Product } from "../types/product";

const useStyles = makeStyles((theme) => ({
  products_view: {
    padding: "10px",
    marginBottom: theme.spacing(5),
  },
  products_title: {
    fontWeight: "bold",
    fontSize: "1.3rem",
    padding: "8px 0px",
    margin: "0 auto",
    borderBottom: "1px solid black",
    letterSpacing: "3px",
  },
  grid_wrapper: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "16px",
    [theme.breakpoints.up('sm')]: {
      marginTop: "32px",
    }
  },
  grid_item: {
    padding: "20px 6px",
    [theme.breakpoints.up('sm')]: {
      padding: "20px 12px",
    }
  },
  cardMedia: {
    width: "100%",
    height: 'auto',
  },
  product_title: {
    marginTop: "6px",
    fontWeight: "bold",
    wordBreak: 'keep-all',
    overflowWrap: "break-word",
    fontSize: ".9rem",
  },
  archive_time: {
    color: "grey",
    fontSize: "1rem",
    textAlign: "center",
    fontFamily: "digital7-mono",
    letterSpacing: "2px",
    [theme.breakpoints.up('sm')]: {
      fontSize: "1.2rem",
    }
  },
}));

type Props = {
  products: Product[]
}

const ArchivedProductsView = memo((props: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const { products } = props
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec",]

  const toDoubleDigits = (num: any) => {
    num += "";
    if (num.length === 1) {
      num = "0" + num;
    }
    return num;
  };

  return (
    <Container className={classes.products_view} maxWidth="md">
      <Typography className={classes.products_title} variant="subtitle1">ARCHIVE</Typography>
      <div className={classes.grid_wrapper}>
        {products.map((product: any, index: any) => (
          <Grid
            className={classes.grid_item}
            key={index} item xs={4}
            onClick={() => {
              if (product.product_type === 'オークション') {
                history.push(`/auction/${product.id}`)
              } else if (product.product_type === '抽選') {
                history.push(`/detail/${product.id}`)
              } else if (product.product_type === '先着') {
                history.push(`/onsale/${product.id}`)
              }
            }}>
            <Typography variant="subtitle1" className={classes.archive_time}>
              {product.product_type === '先着' ?
                <>
                  <span>SOLD</span>
                </>
                :
                <>
                  <span>{months[product.deadline_date.toDate().getMonth()]}</span>.
                  <span>{toDoubleDigits(product.deadline_date.toDate().getDate())}</span>
                </>
              }
            </Typography>
            <LazyLoadImage height={350} src={product.image} className={classes.cardMedia} />
          </Grid>
        ))}
      </div>
    </Container>
  );
});
export default ArchivedProductsView;

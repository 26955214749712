import React, { useContext, useEffect } from "react";
import { AuthContext } from "../contexts/Auth";
import {
  Typography,
  makeStyles,
  Container,
} from "@material-ui/core";
import Copyright from "../components/Copyright";
import { useForm } from "react-hook-form";
import { TextField } from "../components/form/TextField";
import { validateEmail } from "../utils/validations/Email";
import { Button } from "../components/form/Button";
import { PageTitle } from "../components/common/PageTitle";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(8),
    minHeight: '94vh',
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  text: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4)
  }
}));

const ResetPassword = () => {
  const { resetPassword, currentUser } = useContext<any>(AuthContext);
  const classes = useStyles();
  const { control, getValues, setValue, handleSubmit, watch } = useForm()
  const watchEmail = watch('email')

  const handleResetPassowrd = () => {
    const email = getValues('email')
    resetPassword(email)
  };

  useEffect(() => {
    if (currentUser) setValue('email', currentUser.email)
  }, [currentUser]);

  const blurPartEmailString = (string: any) => {
    return string.replace(/[^@]{1,4}@[^@]{1,4}/, "***@***");
  }

  return (
    <>
      <Container component="main" maxWidth="xs" className={classes.root}>
        <PageTitle title="パスワードの再設定" />
        <form className={classes.form} onSubmit={handleSubmit(handleResetPassowrd)}>
          {watchEmail ?
            <>
              <Typography component='p'>
                現在登録されているメールアドレス：<b>{blurPartEmailString(watchEmail)}</b>
              </Typography>
              <Typography component='p' className={classes.text}>上記、現在登録されているメールアドレスに、再設定用のリンクをご案内いたします。こちらから再設定を行ってください。</Typography>
              <input type="hidden" value={watchEmail} name="email"></input>
            </>
            :

            <TextField
              control={control}
              id="email"
              name="email"
              label="メールアドレス"
              defaultValue={''}
              valudation={validateEmail}
            />
          }
          <Button
            text="送信する"
            onClick={handleSubmit}
          />
        </form>
      </Container>

      <Copyright />
    </>
  );
};

export default ResetPassword
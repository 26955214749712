import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  name: {
    wordBreak: 'keep-all',
  },
  title: {
    marginTop: theme.spacing(2),
    width: '80%',
    color: '#000',
    margin: '0 auto',
    textAlign: 'center',
    wordBreak: 'keep-all',
    lineHeight: '1.4rem',
    overflowWrap: "break-word",
    fontWeight: "bold",
  },
}));

export const TitleView = (props: any) => {
  const classes = useStyles();

  return (
    <div className={classes.name}>
      <Typography className={classes.title} style={props.style}>{props.title}</Typography>
      {props.subtitle && <Typography className={classes.title}>{props.subtitle}</Typography>}
    </div>
  )
};
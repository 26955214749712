import { Container, makeStyles, Typography } from "@material-ui/core"
import { useContext, useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import { AuthContext } from "../../contexts/Auth"


const useStyles = makeStyles(() => ({
  main: {
    marginBottom: "50px",
    width: '100%',
    padding: '0',
  },
  infoWrapper: {
    color: "grey",
    border: "1px solid lightgray",
    padding: "16px",
    boxSizing: "border-box",
    marginTop: "40px",
    width: '100%',
  },
  infoDescription: {
    textAlign: "center",
    fontWeight: "bold",
    marginTop: "30px",
    marginBottom: "30px",
  },
  linkText: {
    textAlign: "right",
  },
  link: {
    color: "#ff0000",
    textDecoration: "none",
  },
  withdrawal_wrapper: {
    marginTop: '40px',
    display: 'flex',
    justifyContent: 'end',
  },
  withdrawal: {
    color: '#ff0000',
    border: '1px solid #ff0000',
    padding: '8px 20px',
    '&:hover': {
      cursor: 'pointer',
    }
  }
}))

const AccountView = () => {
  const classes = useStyles()
  const history = useHistory()
  const { currentUser } = useContext<any>(AuthContext)
  const [formatedPhoneNumber, setFormatedPhoneNumber] = useState("")

  useEffect(() => {
    if (currentUser?.phoneNumber) {
      let phoneNumber = currentUser.phoneNumber
      phoneNumber = phoneNumber.replace('+81', '0')
      setFormatedPhoneNumber(phoneNumber.slice(0, 3) + '-' + phoneNumber.slice(3, 7) + '-' + phoneNumber.slice(7, 11))
    }
  }, [currentUser])

  const handleWithdrawal = () => {
    history.push('/mypage/withdrawal')
  }

  if (currentUser) {
    return (
      <Container className={classes.main} maxWidth="xs">
        <div className={classes.infoWrapper}>
          <Typography variant='subtitle2'>メールアドレス</Typography>
          <Typography variant='subtitle1' className={classes.infoDescription}>{currentUser.email}</Typography>
          <Typography variant='subtitle1' className={classes.linkText}>メールアドレス変更は<Link to="/mypage/change-email" className={classes.link}>こちら</Link></Typography>
        </div>
        <div className={classes.infoWrapper}>
          <Typography variant='subtitle2'>電話番号</Typography>
          <Typography variant='subtitle1' className={classes.infoDescription}>{formatedPhoneNumber ? formatedPhoneNumber : "電話番号認証が完了しておりません。"}</Typography>
          <Typography variant='subtitle1' className={classes.linkText}>電話番号変更は<Link to="/mypage/change-phone-number" className={classes.link}>こちら</Link></Typography>
        </div>
        <div className={classes.infoWrapper}>
          <Typography variant='subtitle2'>パスワード</Typography>
          <Typography variant='subtitle1' className={classes.infoDescription}>※※※※※※※</Typography>
          <Typography variant='subtitle1' className={classes.linkText}>パスワード変更は<Link to="/mypage/change-password" className={classes.link}>こちら</Link></Typography>
        </div>
        <div className={classes.withdrawal_wrapper}>
          <Typography variant='subtitle2' className={classes.withdrawal} onClick={handleWithdrawal}>退会はこちら</Typography>
        </div>
      </Container>
    )
  } else {
    return (<></>)
  }
}
export default AccountView;
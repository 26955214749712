import { createContext, useState } from "react";

export const TabValueContext = createContext({})

export const TabValueProvider = ({ children }: any) => {
  const [tabValue, setTabValue] = useState(0)
  const [scrollPositionY, setScrollPositionY] = useState(0)

  return (
    <TabValueContext.Provider
      value={{
        tabValue,
        setTabValue,
        scrollPositionY,
        setScrollPositionY
      }}
    >
      {children}
    </TabValueContext.Provider>
  )
}
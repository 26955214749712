import { createContext, useContext, useEffect, useState } from "react";
import { db, auth, createTimeStamp } from "../utils/Firebase";
import { AuthContext } from "./Auth";

export const CartContext = createContext({})

export const CartProvider = ({
  children
}: any) => {
  const { currentUser } = useContext<any>(AuthContext)
  const [myCart, setMyCart] = useState(null)
  const [isCartOpen, setIsCartOpen] = useState(false)
  const [isCartLoaded, setIsCartLoaded] = useState(false)

  // カートを取得
  useEffect(() => {
    let isMounted = true
    auth.onAuthStateChanged(user => {
      if (!user) {
        setIsCartLoaded(true)
        return
      }
      db.collection('carts').where('uid', '==', user.uid).get().then(snapshot => {
        if (snapshot.size === 0) {
          setIsCartLoaded(true)
          return
        }
        snapshot.forEach(doc => {
          let _myCart = doc.data()
          _myCart['id'] = doc.id
          if (isMounted) {
            // @ts-expect-error TS(2345): Argument of type 'DocumentData' is not assignable ... Remove this comment to see the full error message
            setMyCart(_myCart)
            if (isMounted) setIsCartLoaded(true)
          }
        })
      })
    })
    return (() => {
      isMounted = false
    })
  }, [])

  // カート内アイテムごとに最新の在庫数を取得
  const getLatestInventoryQuantity = () => {
    let isMounted = false
    if (isMounted) setIsCartLoaded(false)

    // @ts-expect-error TS(2531): Object is possibly 'null'.
    let product_ids = myCart.items.map((item: any) => item.productInfo.id)
    let formated_product_ids = Array.from(new Set(product_ids))

    // 最新の在庫情報を取得する
    for (const id of formated_product_ids) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      db.collection('products').doc(id.toString()).get().then(doc => {
        let _product = doc.data()
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        _product['id'] = doc.id
        // 商品のva
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        doc.data().variants.forEach((variant: any) => {
          // @ts-expect-error TS(2531): Object is possibly 'null'.
          myCart.items.forEach((item: any) => {
            if (item.variant.id === variant.id) {
              item['latest_inventory_quantity'] = variant.inventory_quantity
              // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
              setMyCart({ ...myCart, item })
            }
          })
        })
      })
    }

    if (isMounted) setIsCartLoaded(true)
  }

  const addCartItem = async (_item: any) => {

    if (!myCart) {
      await db.collection('carts').add({ uid: currentUser.uid, items: [_item] })
        .then(doc => {
          // @ts-expect-error TS(2345): Argument of type '{ id: string; uid: any; items: a... Remove this comment to see the full error message
          setMyCart({ id: doc.id, uid: currentUser.uid, items: [_item] })
        })
        .catch(e => {
          console.error('カートの作成に失敗しました。', e)
        })
      // @ts-expect-error TS(2339): Property 'items' does not exist on type 'never'.
    } else if (myCart.items.length > 0) {
      // 既存のカート内に同じ商品があれば数量を+１
      let hasSameVariant = false
      // @ts-expect-error TS(2339): Property 'items' does not exist on type 'never'.
      let _items = myCart.items.map((item: any) => {
        if (item.variant.id === _item.variant.id) {
          hasSameVariant = true
          return { ...item, quantity: item.quantity + 1 }
        } else {
          return item
        }
      })

      if (!hasSameVariant) _items.push(_item)

      const updated_at = createTimeStamp(new Date()) // 2023.03.12より追加。一定時間udpateがなければゴミデータとして削除して良い。
      // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
      await db.collection('carts').doc(myCart.id).update({ items: _items, updated_at })
        .then(() => {
          // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
          setMyCart({ ...myCart, items: _items, })
        })
        .catch(e => {
          console.error('カートの更新に失敗しました。', e)
        })

    }
  }

  // カート内の商品の個数をマイナス
  const removeCartItem = async (_item: any) => {
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    let _items = myCart.items.map((item: any) => {
      if (item.variant.id === _item.variant.id) {
        return { ...item, quantity: item.quantity - 1 }
      } else {
        return item
      }
    })
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    await db.collection('carts').doc(myCart.id).update({ items: _items })
      .then(() => {
        // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
        setMyCart({ ...myCart, items: _items })
      })
      .catch(e => {
        console.error('カートの更新に失敗しました。', e)
      })
  }

  // カート内の商品を削除
  const deleteCartItem = async (_item: any) => {
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    let _items = myCart.items.filter((item: any) => item.variant.id !== _item.variant.id)
    if (_items.length > 0) {
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      await db.collection('carts').doc(myCart.id).update({ items: _items })
        .then(() => {
          // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
          setMyCart({ ...myCart, items: _items })
        })
    } else {
      await deleteCart()
    }
  }

  const deleteCart = async () => {
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    await db.collection('carts').doc(myCart.id).delete()
      .then(() => {
        setMyCart(null)
      })
  }

  return (
    <CartContext.Provider
      value={{
        myCart,
        setMyCart,
        addCartItem,
        removeCartItem,
        deleteCartItem,
        deleteCart,
        isCartOpen,
        setIsCartOpen,
        isCartLoaded,
        getLatestInventoryQuantity,
      }}
    >
      {children}
    </CartContext.Provider>
  )
}
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Container, Typography, makeStyles } from "@material-ui/core";
import { AuthContext } from '../contexts/Auth';
import { db } from '../utils/Firebase';
import ArchivedProductsView from '../components/appraisal/ArchivedProductsView';
import DroppNowProductsView from '../components/appraisal/DroppNowProductsView';
import DraftProductsView from '../components/appraisal/DraftProductsView';
import RequireActionProductsView from '../components/appraisal/RequireActionProductsView';
import { Product } from '../types/product';

const useStyles = makeStyles(() => ({
    container: {
        marginTop: '70px',
        paddingBottom: '120px',
    },
    titleWrapper: {
        padding: '8px 0px 16px',
        position: 'fixed',
        left: '0',
        width: '100%',
        textAlign: 'center',
        backgroundColor: 'white',
        zIndex: 10
    },
    title: {
        fontSize: '16px',
        textAlign: 'center',
        fontWeight: "bold",
        letterSpacing: '1.2px',
    },
    noProductText: {
        textAlign: 'center',
        margin: '48px 0',
        fontSize: '14px',
    },
    contentWrapper: {
        paddingTop: '32px',
    },
    button: {
        backgroundColor: "#f00",
        borderRadius: '0',
        color: '#fff',
        width: '100%',
        position: "fixed",
        bottom: '0',
        left: '0',
        padding: '16px 0',
        fontWeight: 'bold',
        letterSpacing: '2px',
        fontSize: '16px',
    }
}))

type ProductStates = {
    requireProducts: Product[];
    droppNowProducts: Product[];
    draftProducts: Product[];
    archivedProducts: Product[];
}

export const Appraisal = () => {
    const classes = useStyles()
    const history = useHistory()
    const { currentUser, teaserEmail } = useContext<any>(AuthContext)
    const [productStates, setProductStates] = useState<ProductStates>({
        requireProducts: [], // 操作が必要な商品入札が入っている、発送待ち、入金待ち、
        droppNowProducts: [], // 出品中
        draftProducts: [], // 下書き
        archivedProducts: [],// 終了・完了・成立
    })

    // 一般ユーザー側
    const getMyAppraisalProduct = async () => {
        let _requireProducts: any = []
        let _droppNowProducts: any = []
        let _draftProducts: any = []
        let _archivedProducts: any = []
        await db.collection('products').where('user_id', '==', currentUser.uid).get().then(snapshot => {
            snapshot.forEach(doc => {
                let _product = doc.data()
                _product["id"] = doc.id
                if (_product.draft_status === 'draft') {
                    _draftProducts.push(_product)
                } else if (_product.draft_status === 'archived') {
                    _archivedProducts.push(_product)
                } else if (_product.draft_status === 'active') {
                    _droppNowProducts.push(_product)
                } else if (_product.draft_status === 'appraisalWatingSelectWinner' ||
                    _product.draft_status === 'appraisalWatingDeposit' ||
                    _product.draft_status === 'appraisalOnShipping' ||
                    _product.draft_status === 'appraisalWaitingShipping' ||
                    _product.draft_status === 'appraisalOnChecking' ||
                    _product.draft_status === 'appraisalOnPaying' ||
                    _product.draft_status === 'appraisalPaid' ||
                    _product.draft_status === 'appraisalOnReturning' ||
                    _product.draft_status === 'appraisalCancelled') {
                    _requireProducts.push(_product)
                }
            })
        })
        setProductStates({
            requireProducts: _requireProducts,
            droppNowProducts: _droppNowProducts,
            draftProducts: _draftProducts,
            archivedProducts: _archivedProducts,
        })
    }


    //買取業者側
    const getProductByBuyer = async () => {
        let _requireProducts: any = []
        let _droppNowProducts: any = []
        let _archivedProducts: any = []
        await db.collection('products').where('product_type', '==', '買取').get().then(snapshot => {
            snapshot.forEach(doc => {
                let _product = doc.data()
                _product["id"] = doc.id

                if (_product.draft_status === 'archived' || _product.draft_status === 'appraisalComplete') {
                    _archivedProducts.push(_product)
                } else if (_product.draft_status === 'active') {
                    _droppNowProducts.push(_product)
                } else if (_product.win_ticket?.uid === currentUser.uid) {
                    // if ((_product.draft_status === 'appraisalWatingSelectWinner' && _product.bid_history?.find(_ticket => _ticket.uid === currentUser.uid)) ||
                    if (_product.draft_status === 'appraisalWatingSelectWinner' ||
                        _product.draft_status === 'appraisalOnShipping' ||
                        _product.draft_status === 'appraisalWatingDeposit' ||
                        _product.draft_status === 'appraisalWaitingShipping' ||
                        _product.draft_status === 'appraisalOnChecking' ||
                        _product.draft_status === 'appraisalOnPaying' ||
                        _product.draft_status === 'appraisalPaid' ||
                        _product.draft_status === 'appraisalOnReturning' ||
                        _product.draft_status === 'appraisalCancelled') {
                        _requireProducts.push(_product)
                    }
                }
            })
        })
        setProductStates({
            requireProducts: _requireProducts,
            droppNowProducts: _droppNowProducts,
            draftProducts: [],
            archivedProducts: _archivedProducts,
        })
    }

    //商品を取得
    useEffect(() => {
        if (!teaserEmail || !currentUser?.uid) return
        const getProducts = async () => {
            teaserEmail?.is_buyer ? await getProductByBuyer() : await getMyAppraisalProduct()
        }
        if (teaserEmail) getProducts()
    }, [teaserEmail?.is_buyer, currentUser])

    return (
        <Container className={classes.container}>

            <div className={classes.titleWrapper}>
                <Typography variant='h1' className={classes.title}>査定</Typography>
            </div>

            <div className={classes.contentWrapper}>
                {teaserEmail?.is_buyer ? // 業者側
                    <>
                        {!(productStates.requireProducts.length > 0) && !(productStates.droppNowProducts.length > 0) && !(productStates.draftProducts.length > 0) &&
                            <Typography variant='h1' className={classes.noProductText}>
                                現在出品されている商品がございません。<br />

                            </Typography>}
                        {productStates.requireProducts.length > 0 &&
                            <RequireActionProductsView products={productStates.requireProducts} />
                        }

                        {productStates.droppNowProducts.length > 0 &&
                            <DroppNowProductsView products={productStates.droppNowProducts} />
                        }
                        {productStates.archivedProducts.length > 0 &&
                            <ArchivedProductsView products={productStates.archivedProducts} />
                        }
                    </>
                    :
                    // 出品者側
                    <>
                        {!(productStates.requireProducts.length > 0) && !(productStates.droppNowProducts.length > 0) && !(productStates.draftProducts.length > 0) &&
                            <Typography variant='h1' className={classes.noProductText}>
                                現在出品されている商品がございません。<br />

                            </Typography>}
                        {
                            productStates.requireProducts.length > 0 &&
                            <RequireActionProductsView products={productStates.requireProducts} />
                        }

                        {productStates.droppNowProducts.length > 0 &&
                            <DroppNowProductsView products={productStates.droppNowProducts} />
                        }
                        {productStates.draftProducts.length > 0 &&
                            <DraftProductsView products={productStates.draftProducts} />
                        }
                        {productStates.archivedProducts.length > 0 &&
                            <ArchivedProductsView products={productStates.archivedProducts} />
                        }
                    </>
                }

            </div>

            {!teaserEmail?.is_buyer && <Button onClick={() => history.push('/appraisal/new')} className={classes.button}>出品する</Button>}

        </Container >
    )
}

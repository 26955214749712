import React, { memo } from "react";
import {
  AppBar,
  createMuiTheme,
  makeStyles,
  MuiThemeProvider,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import AdminDrawerMenu from "./AdminDrawerMenu";

const useStyles = makeStyles((theme) => ({
  appbar: {
    paddingTop: "env(safe-area-inset-top)",
    boxShadow: "none !important",
    [theme.breakpoints.up(1020)]: {
      paddingLeft: 'calc(50% - 1020px/2);',
    }
  },
  toolbar: {
    position: "relative",
    maxWidth: '1020px',
    paddingRight: 0,
    boxSizing: "border-box",
  },
  title: {
    position: "absolute",
    left: 0,
    right: 0,
    width: '75%',
    margin: "auto",
  },
  adminTitle: {
    textDecoration: "none",
    color: "grey",
    fontSize: "1.3rem"
  },
}));

const theme = createMuiTheme({});

const AdminHeader = memo(() => {
  const classes = useStyles();

  return (
    <MuiThemeProvider theme={theme}>
      <AppBar className={classes.appbar} position="fixed" color="inherit">
        <Toolbar className={classes.toolbar}>
          <AdminDrawerMenu />
          <Typography className={classes.title} align="center" variant="h6">
            <Link className={classes.adminTitle} to="/admin">Dropp Admin</Link>
          </Typography>
        </Toolbar>
      </AppBar>
    </MuiThemeProvider>
  );
});

export default AdminHeader

import { makeStyles } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles(() => ({
    linkText: {
        width: '68px',
        display: 'flex',
        color: 'dimgray',
        "&:hover": {
            cursor: 'pointer',
        }
    }
}))

const BackLinkView = ({
    text,
    handleClick,
    styles
}: any) => {
    const classes = useStyles()

    return (
        <div>
            <span onClick={handleClick} className={classes.linkText} style={styles}>
                <ArrowBackIcon /> &nbsp;{text}
            </span>
        </div>
    )
}
export default BackLinkView
import React, { useEffect } from "react";
import {
    Button,
    Container,
    makeStyles,
    Typography,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import AdminProductsView from "../components/admin/AdminProductsView";
import { pageEnterVariants } from "../Animations/variants"
import { db } from "../utils/Firebase";
import { motion, useAnimation } from "framer-motion";
import Copyright from "../components/Copyright";
import AdminKpiView from "../components/admin/home/AdminKpiView";
import AdminArchivedProductsView from "../components/admin/AdminArchivedProductsView";
import { Product } from "../types/product";

const useStyles = makeStyles((theme) => ({
    container: {
        boxSizing: 'border-box',
        minHeight: '94vh',
        [theme.breakpoints.up('sm')]: {
            width: '70%',
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: theme.spacing(10),
        }
    }
}));


const AdminIndex = () => {
    const classes = useStyles();
    const controls = useAnimation();
    const [latestProducts, setLatestProducts] = React.useState(null);
    const [archivedProducts, setArchivedProducts] = React.useState([])

    const formatAsc = (_products: any) => {
        return _products.sort((a: any, b: any) => a.deadline_date > b.deadline_date ? 1 : -1);
    }

    // 表示データの取得
    useEffect(() => {
        let isMounted = true
        // Dropp中商品
        const _func = async () => {
            let _now = new Date();
            await db.collection("products")
                .where("showcase_date", "<", _now)
                .where("close", "==", false)
                .where("is_production", "==", true)
                .orderBy("showcase_date", "desc")
                .get()
                .then((response) => {
                    let time = new Date().getTime() / 1000
                    let _dropp_products: any = []
                    response.forEach((doc) => {
                        let temp = doc.data()
                        // 告知日を超えている ＋ 締切前はdropp中の商品
                        if (temp.product_type === '先着') {
                            console.log()
                        } else if (temp.release_date && time > temp.release_date.seconds && time < temp.deadline_date.seconds) {
                            _dropp_products.push(temp)
                        }
                    });
                    if (isMounted) {
                        setLatestProducts(formatAsc(_dropp_products))
                    }
                }
                );

            // 終了した商品
            db.collection("products")
                .where("deadline_date", "<", _now)
                .orderBy("deadline_date", "desc")
                .where("close", "==", true)
                .where("is_production", "==", true)
                .onSnapshot(snapshot => {
                    let _archivedProducts: any = []
                    snapshot.forEach((temp) => {
                        const _product = temp.data() as Product
                        if (_product.product_type === '先着') {
                            console.log()
                        } else {
                            _archivedProducts.push(_product)
                        }
                    })
                    setArchivedProducts(_archivedProducts)
                })
        }
        _func()

        return () => {
            isMounted = false;
        }
    }, []);

    useEffect(() => {
        if (latestProducts != null) {
            controls.start("visible")
        }
    }, [latestProducts]);

    const [archivedViewLimit, setArchivedViewLimit] = React.useState(10)
    const handleMoreProductsView = () => {
        setArchivedViewLimit(archivedViewLimit + 10)
    }

    return (
        <React.Fragment>
            <motion.div
                initial={{ opacity: 0, scale: 1.05 }}
                animate={controls}
                variants={pageEnterVariants}
                exit={{ opacity: 0, transition: { duration: 0.7 }, scale: 1.05 }}
            >
                <Container className={classes.container}>
                    <AdminKpiView />
                    <AdminProductsView products={latestProducts} />
                    <Typography variant="subtitle2" style={{ fontSize: "1.6rem", color: "gray", borderBottom: "1px solid black", padding: "6px", marginBottom: "20px" }}>Archvied</Typography>
                    <AdminArchivedProductsView products={archivedProducts.slice(0, archivedViewLimit)} />
                    <div style={{ display: "flex", justifyContent: "center", width: "100%", marginBottom: "80px" }}>
                        <Button onClick={handleMoreProductsView} style={{ marginTop: "20px", border: "1px solid black", borderRadius: "0" }}>さらに１０件を表示</Button>
                    </div>
                </Container>
                <Copyright />
            </motion.div>
        </React.Fragment>
    );
}

export default withRouter(AdminIndex);
import { Button, Container, makeStyles, Typography } from "@material-ui/core"
import { useState } from "react"
import { db } from "../utils/Firebase"

const useStyles = makeStyles(() => ({
    productWrapper: {
        borderBottom: '1px solid gray',
        padding: '12px 6px',
    }
}))

const AdminProductUpdate = () => {
    const classes = useStyles()
    const [products, setProducts] = useState([])

    const handleGetProducts = async () => {
        let _products: any = []
        await db.collection('products').where('product_type', '==', '先着').get().then(snapshot => {
            snapshot.forEach(doc => {
                _products.push(doc.data())
            })
        })
        setProducts(_products)
    }

    const updateProduct = async (_product: any, type: any) => {
        db.collection('products').doc(_product.id.toString()).update({
            tags: [type],
        })
        console.log(_product.title, '更新', type)
    }
    return (
        // @ts-expect-error TS(2769): No overload matches this call.
        <Container styles={{ marginTop: '100px' }}>
            <div>
                <Button onClick={handleGetProducts}>商品を取得する</Button>
                {products.map((product) => (
                    // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
                    <div key={product.id} className={classes.productWrapper}>
                        {/* @ts-expect-error */}
                        <Typography>{product.title}</Typography>
                        {/* @ts-expect-error */}
                        {product.tags.map((tag: any) => <Typography>タグ: {tag}</Typography>)}
                        <Button onClick={() => updateProduct(product, 'sneaker')}>スニーカー</Button>
                        <Button onClick={() => updateProduct(product, 'alcohol')}>アルコール</Button>
                        <Button onClick={() => updateProduct(product, 'apparel')}>アパレル</Button>
                        <Button onClick={() => updateProduct(product, 'car')}>クルマ</Button>
                        <Button onClick={() => updateProduct(product, 'interior')}>インテリア</Button>
                        <Button onClick={() => updateProduct(product, 'art')}>アート</Button>
                    </div>
                ))}
            </div>
        </Container>
    );
}

export default AdminProductUpdate
import React, { useContext, useEffect, useState } from "react";
import {
    Container,
    createStyles,
    makeStyles,
    MuiThemeProvider,
    Tab,
    Tabs,
    Theme,
    Toolbar,
    withStyles,
} from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { pageEnterVariants } from "../Animations/variants"
import { db } from "../utils/Firebase";
import { motion, useAnimation } from "framer-motion";
import Copyright from "../components/Copyright";
import NextProductsView from "../components/NextProductsView";
import ArchivedProductsView from "../components/ArchivedProductsView";
import OnSaleProductsView from "../components/OnSaleProductsView";
import { TabValueContext } from "../contexts/TabValue";
import NewOnDroppProductsView from "../components/NewOnDroppProductsView";
import FeaturedProductsView from "../components/home/FeaturedProductsView";
import BuyNowProductsView from "../components/BuyNowProductsView";
import { SortValueContext } from "../contexts/Sort";
import { Product } from "../types/product";
import HomeArchivedView from "../components/home/HomeArchivedView";
import AuctionArchivedView from "../components/home/AuctionArchivedView";
import LotteryArchivedView from "../components/home/LotteryArchivedView";

const useStyles = makeStyles((theme: Theme) => createStyles({
    title: {
        fontWeight: 'bold',
        marginTop: theme.spacing(2),
    },
    tab_bar: {
        margin: '70px auto',
        display: 'flex',
        justifyContent: 'center',
        position: "fixed",
        width: "100%",
        backgroundColor: "white",
        paddingLeft: "0",
        height: "70px",
        zIndex: 99,
    },
    container: {
        paddingLeft: "0",
        paddingRight: "0",
        paddingTop: theme.spacing(18),
        boxSizing: 'border-box',
        minHeight: '94vh',
        [theme.breakpoints.up('sm')]: {
            width: '70%',
        },
    },
    coming_soon_wrapper: {
        width: "100%",
        textAlign: "center",
        marginBottom: "30px",
    },
    coming_soon_img: {
        width: "85%",
        maxWidth: "320px",
    }
}));

const theme = createMuiTheme({
    shadows: ["none", '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
    palette: {
        secondary: {
            main: "#212121",
        }
    }
});

const StyledTab = withStyles((theme) => ({
    root: {
        color: 'grey',
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(14),
        marginRight: theme.spacing(0),
        marginLeft: theme.spacing(0),
        letterSpacing: "2px",
        textTransform: "none",
        width: "124px",
        '&:focus': {
            opacity: 1,
            color: 'black',
            fontWeight: "bold",
        },
        '&.Mui-selected': {
            color: 'black',
            fontWeight: "bold",
        },
        minWidth: 0,
    },
}))((props: { style?: any, label: string }) => <Tab {...props} />);

const TabPanel = (props: any) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`wrapped-tabpanel-${index}`}
            aria-labelledby={`wrapped-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </div>
    );
}

type FeatureProducts = {
    allProducts: Product[],
    lotteryProducts: Product[],
    auctionProducts: Product[],
}

const Home = (props: any) => {
    const classes = useStyles();
    const controls = useAnimation();
    const { tabValue, setTabValue } = useContext<any>(TabValueContext)
    const { isOnSorting, sortValue } = useContext<any>(SortValueContext)
    const { bannerProducts } = props

    // タブをスクロールと共に表示・非表示を切り替える
    useEffect(() => {
        let previousPositionY = 0
        const bannerViewHeight = bannerProducts.length > 0 ? 40 : 0
        const tab = document.getElementById('tab')

        const scrollAction = () => {
            if (!tab) return
            tab.style.transition = "all 0.5s ease-out"
            // モバイルのトップの跳ね返り分を考慮(50px以下の時はタブを表示する)
            tab.style.marginTop = (window.scrollY > 50 && window.scrollY > previousPositionY) ? "0px" : `${70 + bannerViewHeight}px`
            previousPositionY = window.scrollY
        };

        window.addEventListener("scroll", scrollAction, {
            capture: false,
            passive: true,
        });

        return () => {
            window.removeEventListener("scroll", scrollAction);
        };
    }, [bannerProducts])

    const handleTabChange = (event: any, newTabValue: any) => {
        window.scrollTo(0, 0);
        setTabValue(newTabValue);
    }

    const [onProducts, setOnProducts] = useState({
        nextLotteryProducts: [],
        nextAuctionProducts: [],
        nextSaleProducts: [],
        nextProducts: [],
        onLotteryProducts: [],
        onAuctionProducts: [],
        onSaleProducts: [],
        onDroppProducts: [],
        newOnDroppProducts: []
    })

    const [featuredProducts, setFeaturedProducts] = useState<FeatureProducts>({
        allProducts: [],
        lotteryProducts: [],
        auctionProducts: [],
    })

    const [buyNowProducts, setBuyNowProducts] = useState({
        featuredProducts: [],
        onDroppProducts: [],
        nextProducts: [],
        archivedProducts: [],
    })

    const formatAsc = (_products: any, formateDateText: any) => {
        return _products.sort((a: any, b: any) => a[formateDateText] > b[formateDateText] ? 1 : -1);
    }

    const formatDesc = (_products: any, formateDateText: any) => {
        return _products.sort((a: any, b: any) => a[formateDateText] > b[formateDateText] ? -1 : 1);
    }

    let on_sale_products: any = []
    let next_sale_products: any = []
    let sale_feature_products: any = []

    // 表示データの取得
    // @ts-expect-error TS(2345): Argument of type '() => Promise<() => void>' is no... Remove this comment to see the full error message
    useEffect(async () => {
        let isMounted = true;
        let _now = new Date();
        db.collection("products")
            .where("showcase_date", "<", _now)
            .where("close", "==", false)
            .where("is_production", "==", true)
            .orderBy("showcase_date", "desc")
            .onSnapshot((response) => {
                let now = new Date().getTime() / 1000;

                //注目商品
                // 1.; is_featured, 2.; Accending, release_date;
                let all_feature_products: any = [];
                let lottery_feature_products: any = [];
                let auction_feature_products: any = [];

                //カミスン商品
                let next_lottery_products: any = [];
                let next_auction_products: any = [];
                let next_products: any = [];

                // Dropp中商品
                let on_lottery_products: any = [];
                let on_auction_products: any = [];
                let on_dropp_products: any = [];

                response.forEach((doc) => {
                    let temp = doc.data();

                    // 注目商品は通常の一覧には表示しない
                    if (temp.is_featured && now < temp.deadline_date.seconds) {
                        if (temp.product_type === '抽選') {
                            lottery_feature_products.push(temp)
                        } else if (temp.product_type === 'オークション') {
                            auction_feature_products.push(temp)
                        } else if (temp.product_type === '先着') {
                            sale_feature_products.push(temp)
                        }
                        all_feature_products.push(temp)
                        // カミスン
                    } else if (now < temp.release_date.seconds) {
                        if (temp.product_type === '抽選') {
                            next_lottery_products.push(temp);
                        } else if (temp.product_type === 'オークション') {
                            next_auction_products.push(temp);
                        } else if (temp.product_type === '先着') {
                            next_sale_products.push(temp);
                        }
                        next_products.push(temp);
                        // Dropp中
                    } else if (now > temp.release_date.seconds && now < temp.deadline_date.seconds) {
                        if (temp.product_type === '抽選') {
                            on_lottery_products.push(temp);
                        } else if (temp.product_type === 'オークション') {
                            on_auction_products.push(temp);
                        } else if (temp.product_type === '先着') {
                            on_sale_products.push(temp);
                        }
                        // BuyNowの商品はトップの商品一覧には並べない
                        if (temp.product_type !== '先着') {
                            on_dropp_products.push(temp);
                        }
                    }
                });
                if (isMounted) {
                    setOnProducts({
                        ...onProducts,
                        //カミスン商品
                        nextLotteryProducts: next_lottery_products,
                        nextAuctionProducts: next_auction_products,
                        nextSaleProducts: next_sale_products,
                        nextProducts: next_products,
                        // Dropp中商品
                        onLotteryProducts: formatAsc(on_lottery_products, 'deadline_date'),
                        onAuctionProducts: on_auction_products,
                        onSaleProducts: on_sale_products,
                        onDroppProducts: formatAsc(on_dropp_products, 'deadline_date'),
                    });
                    setFeaturedProducts({
                        allProducts: all_feature_products,
                        lotteryProducts: lottery_feature_products,
                        auctionProducts: auction_feature_products,
                    })
                }
            }
            );

        // アーカイブ商品
        db.collection("products")
            .where("release_date", "<", _now)
            .where("close", "==", true)
            .where("is_production", "==", true)
            .where('product_type', '==', '先着')
            .onSnapshot((response) => {
                let archivedBuyNowProducts: any = [];
                response.forEach((doc) => {
                    let temp = doc.data();
                    archivedBuyNowProducts.push(temp);
                });

                if (isMounted) {
                    setBuyNowProducts({
                        featuredProducts: sale_feature_products,
                        onDroppProducts: on_sale_products,
                        nextProducts: next_sale_products,
                        archivedProducts: formatDesc(archivedBuyNowProducts, 'release_date'),
                    })
                    controls.start("visible")
                }
            }
            )
        return () => { isMounted = false }
    }, [])

    const [sortedBuyNowProducts, setSortedBuyNowProducts] = useState({
        featuredProducts: [],
        onDroppProducts: [],
        nextProducts: [],
        archivedProducts: []
    })

    // ソート
    useEffect(() => {
        //選択されているカテゴリーを整形
        let searchTags: any = []
        Object.keys(sortValue).forEach((categoryLevels) => {
            Object.keys(sortValue[categoryLevels]).forEach(category => {
                if (sortValue[categoryLevels][category]) {
                    searchTags.push(category.toString())
                }
            })
        })

        //何も選択されていない時はすべての商品を返す
        if (searchTags.length === 0) {
            setSortedBuyNowProducts(buyNowProducts)
            return
        }
        let states = {
            featuredProducts: [],
            onDroppProducts: [],
            nextProducts: [],
            archivedProducts: [],
        }
        for (const _products of Object.keys(buyNowProducts)) {
            let targetProducts: any = []
            // 選択されているタグを持っている商品だけ表示
            // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            buyNowProducts[_products].forEach((product: any) => {
                product.tags.some((el: any) => {
                    if (searchTags.includes(el)) {
                        targetProducts.push(product)
                    }
                })
            })
            // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            states[_products] = targetProducts
        }

        setSortedBuyNowProducts(states)

    }, [buyNowProducts, sortValue])

    return (
        <React.Fragment>
            <MuiThemeProvider theme={theme}>
                {/* ページ遷移の時一瞬崩れてしまうため記載 */}
                {location.pathname === '/' &&
                    // @ts-expect-error TS(2769): No overload matches this call.
                    <Toolbar
                        position='static'
                        id="tab"
                        className={classes.tab_bar}
                        style={bannerProducts?.length > 0 ? { marginTop: "110px" } : { marginTop: "70px" }}
                    >

                        <Tabs
                            value={tabValue}
                            onChange={handleTabChange}
                            variant="scrollable"
                            textColor="secondary"
                            TabIndicatorProps={{ style: { display: "none" } }}
                            aria-label="home tabs"
                        >
                            <StyledTab style={{ width: "85px" }} label="ホーム" />
                            <StyledTab label="オークション" />
                            <StyledTab style={{ width: "70px" }} label="抽選" />
                            <StyledTab label="Buy Now" />
                        </Tabs>
                    </Toolbar>
                }

            </MuiThemeProvider>
            <motion.div
                initial={{ opacity: 0, scale: 1 }}
                animate={controls}
                variants={pageEnterVariants}
                exit={{ opacity: 0, transition: { duration: 0.5 }, scale: 1 }}
            >
                <Container className={classes.container} style={bannerProducts?.length ? { paddingTop: "180px" } : {}}  >
                    <TabPanel index={0} value={tabValue}>
                        <div style={{ marginTop: "0px" }}>
                            {(featuredProducts.allProducts?.length == 0 || onProducts.onDroppProducts?.length == 0 || onProducts.nextProducts?.length == 0) &&
                                <>
                                    {featuredProducts.allProducts?.length > 0 && <FeaturedProductsView products={featuredProducts.allProducts} />}
                                    {onProducts.onDroppProducts?.length > 0 && <NewOnDroppProductsView products={onProducts.onDroppProducts} tabValue={tabValue} />}
                                    {onProducts.onSaleProducts?.length > 0 && <BuyNowProductsView products={onProducts.onSaleProducts} tabValue={tabValue} />}
                                    {onProducts.nextProducts.length > 0 && <NextProductsView products={onProducts.nextProducts} type="nextProducts" />}
                                </>
                            }
                            <HomeArchivedView />
                        </div>
                    </TabPanel>
                    <TabPanel index={1} value={tabValue}>
                        <div style={{ marginTop: "30px" }}>
                            {(featuredProducts.auctionProducts?.length == 0 || onProducts.onAuctionProducts?.length == 0 || onProducts.nextAuctionProducts?.length == 0) &&
                                <>
                                    {featuredProducts.auctionProducts?.length > 0 && <FeaturedProductsView products={featuredProducts.auctionProducts} />}
                                    {onProducts.onAuctionProducts?.length > 0 && <NewOnDroppProductsView products={onProducts.onAuctionProducts} tabValue={tabValue} />}
                                    {onProducts.nextAuctionProducts?.length > 0 && <NextProductsView products={onProducts.nextAuctionProducts} type="nextProducts" />}
                                </>
                            }
                            <AuctionArchivedView />
                            {/* {archivedProducts.archivedAuctionProducts?.length > 0 && <ArchivedProductsView products={archivedProducts.archivedAuctionProducts} type="archivedProducts" />} */}
                        </div>
                    </TabPanel>
                    <TabPanel index={2} value={tabValue}>
                        {(featuredProducts.lotteryProducts?.length == 0 || onProducts.onLotteryProducts?.length == 0 || onProducts.nextAuctionProducts?.length == 0) &&
                            <>
                                {featuredProducts.lotteryProducts?.length > 0 && <FeaturedProductsView products={featuredProducts.lotteryProducts} />}
                                {onProducts.onLotteryProducts?.length > 0 && <NewOnDroppProductsView products={onProducts.onLotteryProducts} tabValue={tabValue} />}
                                {onProducts.nextLotteryProducts?.length > 0 && <NextProductsView products={onProducts.nextLotteryProducts} type="nextProducts" />}
                            </>
                        }
                        <LotteryArchivedView />
                        {/* {archivedProducts.archivedLotteryProducts?.length > 0 && <ArchivedProductsView products={archivedProducts.archivedLotteryProducts} type="archivedProducts" />} */}
                    </TabPanel>
                    {isOnSorting ? <></> :
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ ease: "easeOut", duration: 1 }}
                            exit={{ opacity: 0, transition: { duration: 0.5 } }}
                        >
                            <TabPanel index={3} value={tabValue}>
                                {(sortedBuyNowProducts.featuredProducts?.length == 0 || sortedBuyNowProducts.onDroppProducts?.length == 0 || sortedBuyNowProducts.nextProducts?.length == 0) &&
                                    <>
                                        {sortedBuyNowProducts.featuredProducts?.length > 0 && <FeaturedProductsView products={sortedBuyNowProducts.featuredProducts} />}
                                        {sortedBuyNowProducts.onDroppProducts?.length > 0 && <OnSaleProductsView products={sortedBuyNowProducts.onDroppProducts} tabValue={tabValue} />}
                                        {sortedBuyNowProducts.nextProducts?.length > 0 && <NextProductsView products={sortedBuyNowProducts.nextProducts} type="nextProducts" />}
                                    </>
                                }
                                {sortedBuyNowProducts.archivedProducts?.length > 0 && <ArchivedProductsView products={sortedBuyNowProducts.archivedProducts} />}
                            </TabPanel>
                        </motion.div>
                    }
                </Container>
                <Copyright />
            </motion.div >
        </React.Fragment >
    );
}

export default withRouter(Home);